import Constants from '@app/utils/constants';
import { useMemo } from 'react';
import useChannelPartner from '../useChannelPartner';

export function useIsUberfreight(includeUberfreightModal = false) {
  const { name } = useChannelPartner();

  return useMemo(() => {
    if (!name) {
      return false;
    }

    return (
      name === Constants.ChannelPartners.UBER_FREIGHT_CHANNEL_PARTNER_NAME ||
      (includeUberfreightModal &&
        name === Constants.ChannelPartners.UBER_FREIGHT_INTERMODAL_CHANNEL_PARTNER_NAME)
    );
  }, [name, includeUberfreightModal]);
}
