import { ELDProvider } from '@app/@types/eld-connect.types';
import AxleLogo from '@app/assets/images/png/axle-logo.png';
import IconCreditLimit from '@app/assets/images/svg/telematics-connect-icon-credit-limit.svg';
import IconFleetSync from '@app/assets/images/svg/telematics-connect-icon-fleet-sync.svg';
import IconFraud from '@app/assets/images/svg/telematics-connect-icon-fraud.svg';
import IconSmartDiscount from '@app/assets/images/svg/telematics-connect-icon-smart-discount.svg';
import {
  useConnectTelematicsOAuth,
  useTelematicsProvider,
} from '@app/hooks/query/useConnectTelematics';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import useProduct from '@app/hooks/useProduct';
import { Alert, Button, useMediaQuery, useTheme } from '@mui/material';
import { ReactElement } from 'react';
import TelematicsCard from './TelematicsCard';

interface TelematicsIntergrationOptionsProps {
  onConnect: (provider: ELDProvider) => void;
  providers: ELDProvider[] | undefined;
}

const BenefitCard = ({ icon, text }: { icon: string; text: string }) => {
  return (
    <div className="align-center flex h-32 w-36 flex-col justify-center gap-2 rounded-2xl border bg-white p-4 shadow-lg md:h-36 md:w-40">
      <img src={icon} alt={text} className="mx-auto h-16 w-16" />
      <div className="mx-auto w-28 text-center text-sm font-semibold">{text}</div>
    </div>
  );
};

export const Benefits = ({ isPrepaid }: { isPrepaid: boolean }) => (
  <div className="grid max-h-max min-h-max min-w-max max-w-max grid-cols-2 gap-4">
    <BenefitCard
      icon={IconCreditLimit}
      text={isPrepaid ? 'Pathway to credit*' : 'Higher credit limit*'}
    />
    <BenefitCard icon={IconFleetSync} text="Automatic fleet sync" />
    <BenefitCard icon={IconSmartDiscount} text="Smart discount offers" />
    <BenefitCard icon={IconFraud} text="Enhanced fraud protection" />
  </div>
);

const TelematicsIntergrationOptions = ({
  onConnect,
  providers,
}: TelematicsIntergrationOptionsProps): ReactElement => {
  const [unifiedTelematicsConnect] = useFeatureFlags('unified_telematics_connect');

  const { mutate } = useTelematicsProvider();
  const { trigger, isMutating } = useConnectTelematicsOAuth(undefined, mutate);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isPrepaid] = useProduct('prepaid');

  if (!providers) {
    return (
      <Alert severity="error" className="mb-4">
        We are unable to get supported providers. Please contact support if the issue persists.
      </Alert>
    );
  }
  if (!unifiedTelematicsConnect) {
    return (
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
        {providers.map((provider) => (
          <TelematicsCard key={provider.id} provider={provider} onClick={onConnect} />
        ))}
      </div>
    );
  }

  return (
    <div
      className="bg-telematics-connect-bg-mobile md:bg-telematics-connect-bg-desktop flex w-full flex-row
    gap-14 rounded-md bg-white bg-cover bg-no-repeat px-2 py-12 md:justify-evenly md:px-16"
    >
      <div className="my-auto flex flex-col items-center gap-6 md:items-start">
        <div className="mb-6 text-center text-3xl font-semibold md:text-start md:text-4xl">
          Get more benefits when you connect Telematics
        </div>

        {isMobile && <Benefits isPrepaid={isPrepaid} />}

        <Button size="small" fullWidth onClick={trigger} disabled={isMutating}>
          Connect
        </Button>

        <div className="flex flex-row gap-2 text-sm">
          Powered by
          <img src={AxleLogo} alt="Axle logo" className="h-5" />
        </div>
      </div>

      {!isMobile && <Benefits isPrepaid={isPrepaid} />}
    </div>
  );
};

export default TelematicsIntergrationOptions;
