import Pagination from '@atob-developers/shared/src/components/Pagination';
import Table from '@atob-developers/shared/src/components/Table';
import { Row } from '@atob-developers/shared/src/components/Table.types';
import { ReactElement, ReactNode, useState } from 'react';

interface TableRendererProps<T extends Row> {
  data: T[];
  columns: ReactNode[];
  rowToUIMapper: (data: T) => ReactElement;
  rowsPerPage: number;
  handleRowClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, key: string) => void;
}

const TableRenderer = <T extends Row>({
  data,
  columns,
  rowToUIMapper,
  rowsPerPage,
  handleRowClick,
}: TableRendererProps<T>): ReactElement => {
  const [page, setPage] = useState(1);

  const sliceData = (data: T[] = [], page: number, rowsPerPage: number) => {
    return data.slice((page - 1) * rowsPerPage, page * rowsPerPage);
  };

  return (
    <div className="flex flex-col gap-6 md:gap-8">
      <Table
        data={sliceData(data, page, rowsPerPage)}
        columns={columns}
        rowDataToUIMapper={rowToUIMapper}
        handleRowClick={handleRowClick}
      />
      <Pagination
        key={Math.ceil(data.length / rowsPerPage)}
        pages={Math.ceil(data.length / rowsPerPage)}
        currentPage={page}
        onPageChange={setPage}
      />
    </div>
  );
};

export default TableRenderer;
