import { PayrollDailySummaryData } from '@app/@types/payroll.types';
import useChartJSStyling from '@app/hooks/useChartJSStyling';
import useWindowWidth from '@app/hooks/useWindowWidth';
import { getLabelsFontSize } from '@app/utils/charts';
import { FormatCurrency } from '@atob-developers/shared/src/utils/formatters';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
} from 'chart.js';
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
);

interface PayrollTransactionsChartProps {
  transactions: PayrollDailySummaryData[];
}

const PayrollTransactionsChart = ({ transactions }: PayrollTransactionsChartProps) => {
  const [data, setData] = useState<unknown>(null);
  const { width } = useWindowWidth();

  useChartJSStyling();

  useEffect(() => {
    const transactedAt = transactions.map((transaction) => new Date(transaction.payment_date));
    const netAmount = transactions.map((transaction) => transaction.amount_cents);

    setData({
      labels: transactedAt,
      datasets: [
        {
          label: 'Amount Paid',
          data: netAmount,
          backgroundColor: 'hsl(153, 92%, 19%, 20%)',
          borderColor: 'hsl(153, 92%, 19%, 50%)',
          borderWidth: 1,
          hoverBackgroundColor: 'hsl(153, 92%, 19%, 50%)',
          hoverBorderColor: 'hsl(153, 92%, 19%, 50%)',
        },
      ],
    });
  }, [transactions]);

  const format_amount = (value: string | number) =>
    FormatCurrency({ value, options: { fromCents: true } });

  const startOfMonth = dayjs().startOf('month');
  const endOfDay = dayjs().endOf('day');

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        type: 'time' as any,
        time: {
          unit: 'day',
        },
        min: startOfMonth.valueOf(),
        max: endOfDay.valueOf(),
      },
      y: {
        title: {
          display: true,
          text: 'Amount Paid',
          fontStyle: 'bold',
          fontSize: 14,
        },
        beginAtZero: true,
        ticks: {
          callback: (value: string | number) => format_amount(value),
        },
        grid: {
          display: false,
        },
      },
    },
    layout: {
      padding: {
        top: 20,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        labels: {
          title: {
            font: {
              size: getLabelsFontSize(width),
            },
          },
        },
        display: 'auto',
        align: 'top',
        anchor: 'end',
        formatter: (value: string) => format_amount(value),
      },
    },
  };

  if (data === null) {
    return null;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <Bar data={data as any} options={options as any} />;
};

export default PayrollTransactionsChart;
