import { useStripe } from '@stripe/react-stripe-js';
import { useSubmitStripePayment } from './use-submit-stripe-payment';

export default function useSubmitCardPayment(
  onSuccess: () => void,
): [() => void, boolean, string | null] {
  const stripe = useStripe();
  return useSubmitStripePayment(onSuccess, (secret) => stripe?.confirmCardPayment(secret), [
    'succeeded',
  ]);
}
