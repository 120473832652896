import Modal from '@atob-developers/shared/src/components/Modal';
import { ReactElement } from 'react';
import { SecurityDepositOfferDetails } from './SecurityDepositOfferDetails';
import { SecurityDepositPaymentFailed } from './SecurityDepositPaymentFailed';
import { SecurityDepositPaymentPending } from './SecurityDepositPaymentPending';

export type SecurityDeposit = {
  status: string;
  amount_cents: number;
  credit_limit_cents: number;
  payment_method_type: 'ach' | 'debit';
};

export const SecurityDepositOfferModal = ({
  open,
  securityDeposit,
  onClose,
}: {
  open: boolean;
  securityDeposit: SecurityDeposit;
  onClose: () => void;
}): ReactElement => {
  type Screen = 'offer' | 'deposit_pending' | 'deposit_failed' | 'unavailable';

  let screen: Screen;
  if (
    securityDeposit?.status === 'deposit_pending' ||
    securityDeposit?.status === 'deposit_paid_pending_hold'
  ) {
    screen = 'deposit_pending';
  } else if (securityDeposit?.status === 'deposit_failed') {
    screen = 'deposit_failed';
  } else if (securityDeposit?.status === 'created') {
    screen = 'offer';
  } else {
    screen = 'unavailable';
  }

  let child = null;

  if (screen === 'offer') {
    child = <SecurityDepositOfferDetails securityDeposit={securityDeposit} onClose={onClose} />;
  } else if (screen === 'deposit_pending') {
    child = <SecurityDepositPaymentPending securityDeposit={securityDeposit} onClose={onClose} />;
  } else if (screen === 'deposit_failed') {
    child = <SecurityDepositPaymentFailed onClose={onClose} />;
  }

  return (
    <Modal open={open} toggle={onClose}>
      {child}
    </Modal>
  );
};

export default SecurityDepositOfferModal;
