import useChannelPartner from '@app/hooks/useChannelPartner';
import { ReactElement } from 'react';
import Plaid from '../Plaid';
import { ErrorBanner } from './Designs/ErrorBanner';

export const Step2ConnectBankAccountBanner = ({
  setError,
  onSuccessCallback,
}: {
  setError: (error: string) => void;
  onSuccessCallback: () => void;
}): ReactElement => {
  const { supportPhoneNumber } = useChannelPartner();

  return (
    <ErrorBanner
      customActionButton={
        <Plaid
          setError={() =>
            setError(
              'There was an error connecting to your bank account. Please contact support for assistance.',
            )
          }
          buttonProps={{
            primary: true,
          }}
          text="Connect Bank Account"
          onSuccessCallback={onSuccessCallback}
          onLinkingCallback={() => {}}
        />
      }
    >
      <div className="font-semibold">STEP 2: Securely connect your bank account to activate</div>
      <div>
        This will be your backup payment method. Have questions? Call us 24/7 at{' '}
        {supportPhoneNumber}.
      </div>
    </ErrorBanner>
  );
};

export default Step2ConnectBankAccountBanner;
