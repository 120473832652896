import CardSectionTitle from '@app/components/Cards/CardSectionTitle';
import CardRow from '@app/components/PriceDetailRow/CardRow';
import logger from '@app/utils/datadog-logger';
import Switch from '@atob-developers/shared/src/components/Switch';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { ReactElement, useState } from 'react';
import CardUnlockSecurityModal from './CardUnlockSecurityModal';

interface AssignSectionCardAuthProps {
  cardSecurityEnabled: boolean;
  cardId: string | number;
  readonly: boolean;
}

const AssignSectionCardAuth = ({
  cardSecurityEnabled,
  cardId,
  readonly,
}: AssignSectionCardAuthProps): ReactElement => {
  const [loading, setLoading] = useState(false);
  const [cardSecurityToggleState, setCardSecurityToggleState] = useState(cardSecurityEnabled);
  const [showModal, setShowModal] = useState(false);

  const { addToast } = useToasts();

  const toggleSMSAuthHandler = () => {
    setLoading(true);
    logger.info('Toggling SMS Auth', { cardId });
    axios
      .put(`/cards/${cardId}`, {
        card: { card_security_enabled: !cardSecurityToggleState },
      })
      .then(() => {
        const newState = cardSecurityToggleState ? 'deactivated' : 'activated';
        logger.info('Toggled SMS Auth', { cardId, newState });
        addToast(`Card unlock security has been ${newState}`, {
          appearance: 'success',
        });
        setCardSecurityToggleState(!cardSecurityToggleState);
      })
      .catch(() => {
        const newState = cardSecurityToggleState ? 'deactivated' : 'activated';
        logger.error('Error toggling SMS Auth', { cardId, newState });
        addToast(`Card unlock security could not be ${newState}`, {
          appearance: 'error',
        });
        setCardSecurityToggleState(cardSecurityToggleState);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const enable = () => {
    if (!cardSecurityToggleState) {
      logger.info('Opening SMS Auth Modal', { cardId });
      setShowModal(true);
    } else {
      toggleSMSAuthHandler();
    }
  };

  const confirm = () => {
    toggleSMSAuthHandler();
    logger.info('Closing SMS Auth Modal after confirmation', { cardId });
    setShowModal(false);
  };

  const reset = () => {
    logger.info('Closing SMS Auth Modal without confirmation', { cardId });
    setShowModal(false);
  };

  return (
    <div>
      <CardRow>
        <div className="flex-col">
          <div className="flex items-center gap-4">
            <CardSectionTitle text="Card Unlock Security" />
            <FontAwesomeIcon
              icon={faCircleInfo}
              className="cursor-pointer"
              onClick={() => setShowModal((prev) => !prev)}
            />
          </div>
          <div className="flex items-center text-sm font-normal text-gray-600 lg:hidden xl:flex">
            Ensures card can only be used by a verified driver.
          </div>
        </div>
        <div className="flex h-[70%] cursor-not-allowed items-center justify-start">
          <Switch
            canChange={!readonly && !loading}
            disabledText={
              'When a Card has a Tag, this property can only be edited on the Tag itself.'
            }
            enabled={cardSecurityToggleState}
            setEnabled={enable}
          />
        </div>
      </CardRow>
      <CardUnlockSecurityModal
        cardSecurityEnabled={cardSecurityToggleState}
        open={showModal}
        loading={loading}
        confirm={confirm}
        reset={reset}
      />
    </div>
  );
};

export default AssignSectionCardAuth;
