import useFeatureFlags from '@app/hooks/useFeatureFlags';
import useProduct from '@app/hooks/useProduct';
import { faBadgeDollar, faGasPump } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MarketingPopup from './components/MarketingPopup';

const TAPetroPopup = (): JSX.Element | null => {
  const [ta_petro_marketing] = useFeatureFlags('ta_petro_marketing');
  const [perks] = useProduct('perks');

  const navigate = useNavigate();

  if (!ta_petro_marketing || !perks) {
    return null;
  }

  return (
    <MarketingPopup
      storageKey="ta_petro_popup"
      heading="Your AtoB Fuel Card just got a lot better"
      headerImg="/images/ta_petro_banner.png"
      body={
        <div className="w-3xl flex flex-col text-black">
          <div>
            <p>Discounts and tools that make a real difference for your business.</p>
          </div>
          <div className="my-4 grid gap-8 md:grid-cols-2">
            <div className="mb-6 flex space-x-4">
              <FontAwesomeIcon icon={faBadgeDollar} size="2x" />
              <div>
                <div className="font-semibold">Discounts on average of 42¢ off/gal</div>
                <div>
                  You can now save on average 42¢/gal on commercial Diesel at{' '}
                  <strong>
                    <em>TA Petro</em>
                  </strong>
                  , TA Express, Rutter&apos;s, Kwik Trip, RaceTrac, and more.
                </div>
              </div>
            </div>
            <div>
              <div className="flex space-x-4 ">
                <FontAwesomeIcon icon={faGasPump} size="2x" />
                <div>
                  <div className="font-semibold">AtoB FuelMap</div>
                  <div>
                    We will locate the cheapest fuel and highest discounts along your next journey.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      footer={(dismiss) => (
        <div className="flex flex-row justify-end space-x-4">
          <Button
            color="secondary"
            size="medium"
            onClick={() => {
              dismiss();
            }}
          >
            Dismiss
          </Button>
          <Button
            color="primary"
            size="medium"
            onClick={() => {
              navigate('/perks');
              dismiss();
            }}
          >
            Find a TA Petro
          </Button>
        </div>
      )}
    />
  );
};

export default TAPetroPopup;
