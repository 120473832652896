import axios from 'axios';

export function isRetryableError(error: unknown): boolean {
  if (axios.isAxiosError(error)) {
    return error?.response?.status === 403 && error?.response?.data?.reason === 'mfa_required';
  }

  return false;
}

export function isOTPError(error: unknown): boolean {
  if (axios.isAxiosError(error)) {
    return error?.response?.status === 403 && error?.response?.data?.reason === 'mfa_required';
  }

  return false;
}
