import { useExtendMobileHeaderContextMenu } from '@app/contexts/MobileNavHeaderMenuContextComponent';
import { Button } from '@mui/material';
import { ReactElement, useMemo, useState } from 'react';
import { ExportType } from '../exports';
import DefaultExport from './DefaultExport';

type DefaultExportProps = {
  selectedFilters: Record<string, unknown>;
  exportType: ExportType;
  buttonText: string;
};

const DefaultExportButton = ({
  selectedFilters,
  exportType,
  buttonText = 'Export',
}: DefaultExportProps): ReactElement => {
  const [openModal, toggleModal] = useState(false);

  const menuItem = useMemo(
    () => ({
      value: buttonText,
      onClick: () => toggleModal(true),
      disabled: () => false,
      order: 2,
    }),
    [buttonText],
  );

  useExtendMobileHeaderContextMenu(menuItem);

  return (
    <>
      <Button
        color="secondary"
        size="small"
        className="hidden lg:block"
        onClick={() => toggleModal(true)}
      >
        {buttonText}
      </Button>
      <DefaultExport
        open={openModal}
        onClose={() => toggleModal(false)}
        key={openModal.toString()}
        selectedFilters={selectedFilters}
        exportType={exportType}
      />
    </>
  );
};

export default DefaultExportButton;
