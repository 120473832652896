import classNames from 'classnames';
import { ReactElement } from 'react';
import { Step, StepStatus, CustomStep } from '../steps.types';

export const isCustom = (step: Step): step is CustomStep => step.status === StepStatus.CUSTOM;

export const Custom = ({ step }: { step: CustomStep }): ReactElement => {
  const { id, title, body, href, renderIcon, disableIcon } = step;
  return (
    <a
      data-testid={`custom-${id}`}
      href={href}
      className={classNames('group flex w-full items-center', {
        'cursor-default': !href,
      })}
    >
      <span className="flex items-center px-6 py-4 text-sm font-medium">
        {!disableIcon && (
          <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-green-600 group-hover:bg-green-800">
            {renderIcon ? renderIcon(id) : id}
          </span>
        )}
        <div className={classNames({ 'ml-4': !disableIcon }, 'flex flex-col text-sm font-medium')}>
          {title}
          {body}
        </div>
      </span>
    </a>
  );
};
