import { Loading } from '@app/components/layout';
import useAuth from '@app/hooks/useAuth';
import { FC, useEffect } from 'react';

const Logout: FC = () => {
  const { logout } = useAuth();

  useEffect(() => {
    logout({
      logoutParams: {
        returnTo: '',
      },
    });
  }, [logout]);

  return <Loading />;
};

export default Logout;
