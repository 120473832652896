import { EndpointResponse } from '@app/@types/api.types';
import { FuelCardAccountData } from '@app/@types/fuel-card-account.types';
import Header from '@app/components/Navigation/Header';
import { Tile, TileContainer } from '@app/components/OverviewTiles/Tile';
import TilesContainer from '@app/components/OverviewTiles/TilesContainer';
import { PeriodHeader } from '@app/components/PrepaidAccountOverview/HeadingCard';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import PageContentWrapper from '@app/components/wrappers/PageContentWrapper';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import { formatDayOfWeek } from '@app/utils/formatDate';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { SectionTitle } from '../AccountOverview/CreditOverviewCard';

const BENEFIT_DETAILS = [
  {
    title: 'Save an Average of 50¢/gal',
    description:
      'Enjoy unmatched savings at truck stops like TA, Petro, TA Express, 7-Eleven, and Speedway.',
  },
  {
    title: 'Build Business Credit',
    description:
      'We report to Experian every month to help you build a strong credit history, even if you qualify for the prepaid fuel card.',
  },
  {
    title: 'Zero Fees',
    description: 'No setup, card, software, or maintenance fees. Pure savings.',
  },
  {
    title: 'Instant Payments',
    description: 'Avoid late fees with seamless Wallet integration for fuel card balance payments.',
  },
  {
    title: 'Mastercard Acceptance',
    description: 'Pay at 99% of truck stops, enjoying widespread convenience.',
  },
  {
    title: 'Full Control',
    description: 'Set transaction limits, operational hours, and manage fleet cards effortlessly.',
  },
  {
    title: 'Enhanced Security',
    description:
      "Protect your transactions with SMS Unlock that let's your drivers unlock their card at the time of use.",
  },
  {
    title: 'Risk-Free Credit Application',
    description:
      'Apply for credit without affecting your credit score. If not approved, we offer a credit-building prepaid card.',
  },
];
const FuelProgramDetail = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isLoading: isFetching, data: fuelCardAccountData } = useSWR<
    EndpointResponse<FuelCardAccountData>
  >({ url: '/fuel_card_account?savings_breakdown=true' }, apiGetFetcher);

  return (
    <PageContentWrapper
      header={
        <Header
          title={
            <>
              {t('program_name', 'AtoB')} Program
              <div className="inline-block pl-2">
                <Chip label="Enrolled" color="success" />
              </div>
            </>
          }
          onBack={() => navigate('/')}
        />
      }
      className="gap-6 pt-0 md:gap-6"
    >
      <div className="relative">
        {isFetching && <SpinnerBoundary />}
        <SavingsOverview
          periodEndDate={fuelCardAccountData?.data.current_period_end_date}
          periodStartDate={fuelCardAccountData?.data.current_period_start_date}
          currentPeriodDiscountAmount={
            fuelCardAccountData?.data.savings_breakdown?.current_period || '$0.00'
          }
          lastPeriodDiscountAmount={
            fuelCardAccountData?.data.savings_breakdown?.last_period || '$0.00'
          }
          last30DaysDiscountAmount={
            fuelCardAccountData?.data.savings_breakdown?.last_30_days || '$0.00'
          }
          lifetimeDiscountAmount={fuelCardAccountData?.data.savings_breakdown?.lifetime || '$0.00'}
        />
      </div>
      <RoundedCard className="p-6">
        <h2 className="mb-6 pb-4 pt-8 text-center text-3xl font-bold text-gray-800">
          Benefits with your {t('program_name', 'AtoB')} Fuel Card
        </h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          {BENEFIT_DETAILS.map((benefit, index) => (
            <div className="bg-brown-200 flex flex-row gap-4 rounded-lg p-4 py-8" key={index}>
              <div className="flex items-center justify-center">
                <FontAwesomeIcon icon={faCheckCircle} className="text-green-500" size="2x" />
              </div>
              <div className="flex flex-col justify-center gap-1">
                <span className="font-semibold text-gray-900">{benefit.title}</span>
                <span className="text-sm text-gray-700">{benefit.description}</span>
              </div>
            </div>
          ))}
        </div>
      </RoundedCard>
    </PageContentWrapper>
  );
};

const SavingsOverview = ({
  periodEndDate,
  periodStartDate,
  currentPeriodDiscountAmount,
  lastPeriodDiscountAmount,
  last30DaysDiscountAmount,
  lifetimeDiscountAmount,
}: {
  periodEndDate?: string;
  periodStartDate?: string;
  currentPeriodDiscountAmount: string;
  lastPeriodDiscountAmount: string;
  last30DaysDiscountAmount: string;
  lifetimeDiscountAmount: string;
}): ReactElement => {
  const periodStartDayOfWeek = periodStartDate ? formatDayOfWeek(new Date(periodStartDate)) : null;

  const savingsData = [
    { period: 'Last Period', amount: lastPeriodDiscountAmount },
    { period: 'Last 30 days', amount: last30DaysDiscountAmount },
    { period: 'All Time', amount: lifetimeDiscountAmount },
  ];

  return (
    <div className="flex flex-col gap-6 md:flex-row">
      <TilesContainer
        header={
          <PeriodHeader
            hideSettingsButton
            periodStartDate={periodStartDate}
            periodEndDate={periodEndDate}
          />
        }
      >
        <Tile
          title="Total Savings"
          value={currentPeriodDiscountAmount?.replace('-', '') || '$0.00'}
          tooltip={`Total discounts this period (starting ${periodStartDayOfWeek} at 12:00AM, Pacific time), excluding any transactions that have yet to settle.`}
        />
      </TilesContainer>
      <TilesContainer header={<SectionTitle>Past Savings</SectionTitle>}>
        <TileContainer>
          <div className="mt-2 flex flex-col gap-2 text-sm">
            {savingsData.map(({ period, amount }) => (
              <div className="flex justify-between" key={period}>
                <span className="text-gray-600">{period}</span>
                <span className="text-gray-900">{amount}</span>
              </div>
            ))}
          </div>
        </TileContainer>
      </TilesContainer>
    </div>
  );
};

export default FuelProgramDetail;
