import { ReactElement, ReactNode } from 'react';

export default function TooltipMessage({
  children,
  classes = 'top-0 left-20',
}: {
  children: ReactNode;
  classes?: string;
}): ReactElement {
  return (
    <div
      className={`${classes} absolute flex w-40 justify-center rounded bg-white p-2 text-sm shadow-md`}
    >
      {children}
    </div>
  );
}
