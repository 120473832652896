import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import type { AutoTopUp, AutoTopUpState } from '../../Prepaid.types';

dayjs.extend(utc);

const getDayjsDate = (date: string): Date => {
  const isInvalidDate =
    dayjs(date.replaceAll('-', '/')).utc().toDate().toString() === 'Invalid Date';

  if (isInvalidDate) {
    return dayjs(date).toDate();
  }

  return dayjs(date.replaceAll('-', '/')).utc().toDate();
};

const calculateStartDay = (start_time: string): Date => {
  if (!start_time) {
    return dayjs().add(1, 'day').toDate();
  }

  return getDayjsDate(start_time);
};

const DEFAULT_AMOUNT = '';
const DEFAULT_BALANCE = '';
const DEFAULT_CADENCE_SCHEDULE = 'weekly';
const DEFAULT_FREQUENCY = 'cadence';

export function getAutoTopUpState(topUp: AutoTopUp): AutoTopUpState {
  return {
    id: topUp?.id || null,
    deleted_at: topUp?.deleted_at || null,
    frequency: topUp?.frequency || DEFAULT_FREQUENCY,
    amount: topUp?.amount?.replace('$', '') || DEFAULT_AMOUNT,
    balance: topUp?.balance_threshold?.replace('$', '') || DEFAULT_BALANCE,
    cadence_schedule: topUp?.cadence_schedule || DEFAULT_CADENCE_SCHEDULE,
    startingOn: calculateStartDay(topUp?.start_time?.toString()),
    paymentMethodId: topUp?.payment_method_id || null,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    error_message: topUp?.error_message || null,
  };
}
