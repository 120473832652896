export const formatPhoneNumber = (value: string) => {
  if (!value) return value;

  let phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength > 10) {
    phoneNumber = phoneNumber.slice(0, 10);
  }

  switch (true) {
    case phoneNumberLength < 4:
      return phoneNumber;
    case phoneNumberLength < 7:
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    default:
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(
        6,
        phoneNumber.length,
      )}`;
  }
};

export const PHONE_REGEX = /^\(?([0-9]{3})\)?[ ]?([0-9]{3})[-]?([0-9]{4})$/;

export enum PHONE_ERROR_MESSAGE {
  INVALID_LENGTH = 'Must be 10 digits!',
  INVALID_FORMAT = 'Phone is not in valid format. Example: (555) 555-5555',
}
