import { VirtualCardInfoModal } from '@app/components/Modals/VirtualCardsModals/VirtualCardsCreateModal';
import { VirtualCardsSuccessModalBody } from '@app/components/Modals/VirtualCardsModals/VirtualCardsSuccessModal';
import { Loading } from '@app/components/layout';
import { useExtendMobileHeaderContextMenu } from '@app/contexts/MobileNavHeaderMenuContextComponent';
import CustomerContext from '@app/contexts/customerContext';
import { useIssuingDetails } from '@app/hooks/useIssuingDetails';
import useProduct from '@app/hooks/useProduct';
import Modal from '@atob-developers/shared/src/components/Modal';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { CardOrderAddress } from './CardOrderAddress';
import { CardOrderQuantity } from './CardOrderQuantity';
import { CardOrderSelectType } from './CardOrderSelectType';
import { CardOrderShipping } from './CardOrderShipping';
import { CardOrderSuccessPhysical } from './CardOrderSuccessPhysical';
import { CardOrderSummary } from './CardOrderSummary';
import { CardOrderUnavailable } from './CardOrderUnavailable';
import type { AddressType } from '@app/@types/customer.types';

export type DeliveryMethodResponse = {
  id: string;
  attributes: {
    user_facing_name: string;
    method: string;
    description: string;
  };
};

export type CardRequestParams = {
  number_of_cards: number;
  card_type: CardType;
  shipping_address: AddressType;
  delivery_method_id: string;
};

export type CardType = 'physical' | 'virtual';

export type CardOrderConstraints = {
  shippable_address: AddressType;
  fee_waived: boolean;
  max_cards_allowed_physical: number;
  max_cards_allowed_virtual: number;
  virtual_cards_allowed: boolean;
};

type Money = {
  cents: number;
  currency_iso: string;
};

export type CardOrderFeeDetails = {
  per_card_fee: Money;
  shipping_fee: Money;
  total_fee: Money;
  shipping_fee_options: { [key: string]: Money };
};

const CardOrderModal = ({
  disabled,
  open,
  onClose,
}: {
  disabled: boolean;
  open: boolean;
  onClose: () => void;
}): ReactElement => {
  const { addToast } = useToasts();
  const [orderConstraints, setOrderConstraints] = useState<CardOrderConstraints>();

  const [isPrepaid] = useProduct('prepaid');

  useEffect(() => {
    axios
      .get('/card_requests/new')
      .then((response) => {
        setOrderConstraints(response.data);
        setParams((prevParams) => ({
          ...prevParams,
          shipping_address: response.data.shippable_address,
        }));
      })
      .catch((error: AxiosError) => {
        addToast(error.message, { appearance: 'error' });
      });
  }, [addToast]);

  useEffect(() => {
    if (orderConstraints?.virtual_cards_allowed == false) {
      setScreen('quantity');
    }
  }, [orderConstraints?.virtual_cards_allowed]);

  const [params, setParams] = useState<CardRequestParams>({
    card_type: 'physical',
    number_of_cards: 1,
    shipping_address: { address1: '', city: '', state: '', zip: '' },
    delivery_method_id: '',
  });

  const showCosts: boolean = !orderConstraints?.fee_waived && params.card_type === 'physical';

  type Screen =
    | 'select'
    | 'quantity'
    | 'address'
    | 'shipping'
    | 'summary'
    | 'success_physical'
    | 'success_virtual'
    | 'info_virtual'
    | 'unavailable';

  const [screen, setScreen] = useState<Screen>(disabled ? 'unavailable' : 'select');

  const screenSequencePhysical: Screen[] = [
    'select',
    'quantity',
    'address',
    'shipping',
    'summary',
    'success_physical',
  ];

  const screenSequenceVirtual: Screen[] = [
    'select',
    'info_virtual',
    'quantity',
    'summary',
    'success_virtual',
  ];

  const getNextScreen: (_: Screen) => () => void = (currentScreen: Screen) => {
    const sequence =
      params.card_type === 'physical' ? screenSequencePhysical : screenSequenceVirtual;
    const currentIndex = sequence.indexOf(currentScreen);
    return () => setScreen(sequence[currentIndex + 1]);
  };

  const getPreviousScreen: (_: Screen) => () => void = (currentScreen: Screen) => {
    const sequence =
      params.card_type === 'physical' ? screenSequencePhysical : screenSequenceVirtual;
    const currentIndex = sequence.indexOf(currentScreen);
    return () => setScreen(sequence[currentIndex - 1]);
  };

  const render = () => {
    if (!orderConstraints) return <Loading />;

    switch (screen) {
      case 'select':
        return (
          <CardOrderSelectType
            params={params}
            setParams={setParams}
            orderConstraints={orderConstraints}
            onNext={getNextScreen('select')}
            closeModal={onClose}
          />
        );
      case 'quantity':
        return (
          <CardOrderQuantity
            params={params}
            setParams={setParams}
            orderConstraints={orderConstraints}
            onNext={getNextScreen('quantity')}
            onBack={getPreviousScreen('quantity')}
            closeModal={onClose}
          />
        );
      case 'address':
        return (
          <CardOrderAddress
            params={params}
            setParams={setParams}
            onNext={getNextScreen('address')}
            onBack={getPreviousScreen('address')}
            closeModal={onClose}
          />
        );
      case 'shipping':
        return (
          <CardOrderShipping
            params={params}
            setParams={setParams}
            orderConstraints={orderConstraints}
            onNext={getNextScreen('shipping')}
            onBack={getPreviousScreen('shipping')}
            closeModal={onClose}
          />
        );
      case 'summary':
        return (
          <CardOrderSummary
            params={params}
            showCosts={showCosts}
            isPrepaid={isPrepaid}
            onNext={getNextScreen('summary')}
            onBack={getPreviousScreen('summary')}
            closeModal={onClose}
          />
        );
      case 'success_physical':
        return <CardOrderSuccessPhysical closeModal={onClose} />;
      case 'success_virtual':
        return <VirtualCardsSuccessModalBody />;
      case 'info_virtual':
        return (
          <VirtualCardInfoModal
            onNext={getNextScreen('info_virtual')}
            onBack={getPreviousScreen('info_virtual')}
            onClose={onClose}
          />
        );
      case 'unavailable':
        return <CardOrderUnavailable closeModal={onClose} />;
    }
  };

  return (
    <Modal open={open} toggle={onClose}>
      {render()}
    </Modal>
  );
};

const OrderCardsButton = ({
  buttonText = 'Order Cards',
}: {
  buttonText?: string;
}): ReactElement => {
  const [openModal, toggleModal] = useState(false);

  const { issuingOnboardingIncomplete } = useIssuingDetails();
  const { customer } = useContext(CustomerContext);

  const disabled: boolean =
    issuingOnboardingIncomplete ||
    !!customer.suspended_at ||
    customer.cardholder_status != 'active';

  const menuItem = useMemo(
    () => ({
      value: 'Order Cards',
      onClick: () => toggleModal(true),
      disabled: () => disabled,
      order: 1,
    }),
    [disabled],
  );
  useExtendMobileHeaderContextMenu(menuItem);

  const theme = useTheme();
  const fullWidth = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <>
      <Button size="small" onClick={() => toggleModal(true)} fullWidth={fullWidth}>
        {buttonText}
      </Button>
      <CardOrderModal disabled={disabled} open={openModal} onClose={() => toggleModal(false)} />
    </>
  );
};

export default OrderCardsButton;
