import { ReactElement } from 'react';

export default function Skeleton(): ReactElement {
  return (
    <div className="border-1 rounded-sm bg-white p-4 pb-12">
      <div className="justify-left m-3 flex h-full animate-pulse flex-row items-center">
        <div className="w-full grow flex-col space-y-4">
          <div className="h-6 flex-1 rounded-sm bg-gray-300" />
          <div className="h-6 rounded-sm bg-gray-300" />
          <div className="h-6 rounded-sm bg-gray-300" />
          <div className="h-6 w-6/12 rounded-sm bg-gray-300" />
        </div>
      </div>
    </div>
  );
}
