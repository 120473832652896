import { EndpointResponse } from '@app/@types/api.types';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import axios from 'axios';
import useSWR, { SWRResponse, mutate } from 'swr';

export type DayOfWeek =
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday'
  | 'Sunday';

export const daysOfWeek: DayOfWeek[] = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export type OperationalPeriodsData = {
  [key in DayOfWeek]: [number, number];
};

export const useOperationalPeriods = (
  resourceType: 'Customer' | 'Tag',
  resourceId?: number,
): SWRResponse<EndpointResponse<OperationalPeriodsData>> => {
  return useSWR<EndpointResponse<OperationalPeriodsData>>(
    {
      url: `/operational_periods`,
      params: {
        resource_type: resourceType,
        resource_id: resourceId,
      },
    },
    apiGetFetcher,
  );
};

export const setOperationalPeriods = async (
  resourceType: 'Customer' | 'Tag',
  payload: OperationalPeriodsData,
  resourceId?: number,
): Promise<OperationalPeriodsData> => {
  const response = await axios.put<{ errors?: string[] } & OperationalPeriodsData>(
    `/operational_periods`,
    {
      resource_type: resourceType,
      resource_id: resourceId,
      operational_periods: payload,
    },
  );

  mutate(
    {
      url: `/operational_periods`,
      params: {
        resource_type: resourceType,
        resource_id: resourceId,
      },
    },
    response.data,
  );

  if (response.status !== 204) {
    if (response.data?.errors) {
      throw new Error(`Failed to set operational hours. ${response.data.errors[0]}`);
    } else {
      throw new Error(`Failed to set operational hours.`);
    }
  }

  return response.data;
};
