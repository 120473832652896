import { TruckType } from '@app/@types/telematics.types';
import { faCircle, faLocationArrow, faStore, faTruck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import classNames from 'classnames';
import { Coords } from 'google-map-react';
import { truckIsIdle } from '../../utils/Telematics/TelematicsMapsUtils';

interface RenderPrimaryMarkerProps {
  name: string;
  vin: string;
  onClick?: () => void;
  isMatchedVehicle?: boolean;
}

interface VehicleLabelProps {
  name: string;
  vin: string;
}

export const VehicleLabel = ({ name, vin }: VehicleLabelProps) => {
  return (
    <div>
      <div className="text-center text-sm font-bold">{name}</div>
      <div className="text-center text-xs">{vin}</div>
    </div>
  );
};

const MapLabel = ({ name, vin, onClick, isMatchedVehicle }: RenderPrimaryMarkerProps) => {
  return (
    <div
      className={classNames('map-marker-text border-2 shadow-md', {
        'border-atob-green': isMatchedVehicle,
      })}
      onClick={onClick}
    >
      <VehicleLabel name={name} vin={vin} />
    </div>
  );
};

interface DriverMarkerProps extends Coords {
  selectTruck: (truck: TruckType, setError: ((e: unknown) => void) | null) => void;
  truck: TruckType;
}

const DriverMarker = ({ selectTruck, truck }: DriverMarkerProps) => {
  const isIdle = truckIsIdle(truck);

  return (
    <>
      <FontAwesomeIcon
        style={{
          transform: `rotate(${(truck.location?.bearing || 45) - 45}deg)`,
          left: '-7px',
          top: '-9px',
        }}
        icon={isIdle ? faCircle : faLocationArrow}
        className={`${isIdle ? 'location-circle' : 'location-arrow'}`}
      />
      <MapLabel name={truck.name} vin={truck.vin} onClick={() => selectTruck(truck, null)} />
    </>
  );
};

export type VehicleLocationType = {
  vin: string;
  latitude: string;
  longitude: string;
  bearing: number;
  name: string;
  isMatchedVehicle?: boolean;
};

interface VehicleMarkerProps extends Coords {
  location: VehicleLocationType;
  isMatchedVehicle: boolean;
}

interface ClusterMarkerProps extends Coords {
  pointCount: number;
  key: number;
}

const SimpleVehicleMarker = ({ location, isMatchedVehicle }: VehicleMarkerProps) => {
  return (
    <>
      <FontAwesomeIcon
        style={{
          transform: `rotate(${location.bearing - 45}deg)`,
          left: '-7px',
          top: '-9px',
        }}
        icon={faCircle}
        className={classNames('location-circle', { 'location-circle-primary': isMatchedVehicle })}
        data-testid={isMatchedVehicle ? 'matched-vehicle-marker' : 'vehicle-marker'}
      />
      <MapLabel name={location.name} vin={location.vin} isMatchedVehicle={isMatchedVehicle} />
    </>
  );
};

const ClusterMarker = ({ pointCount, key }: ClusterMarkerProps) => {
  return (
    <div
      key={key}
      style={{
        position: 'absolute',
        left: 'calc(50% - 10px)',
        top: 'calc(50% - 10px)',
        zIndex: 1,
      }}
    >
      <div className="rounded-full border border-2 border-gray-300 bg-white px-4 py-1 text-xs font-bold shadow-lg">
        {pointCount}
      </div>
    </div>
  );
};

const MerchantMarker = (_: Coords) => {
  return (
    <Tooltip title="Merchant Location">
      <div className="color-white -ml-6 -mt-6 flex h-12 w-12 items-center justify-center rounded-full border-4 border-white bg-black">
        <FontAwesomeIcon
          icon={faStore}
          data-testid="merchant-marker"
          className="z-4 absolute text-lg text-white"
        />
      </div>
    </Tooltip>
  );
};

const VehicleMarker = (_: Coords) => {
  return (
    <Tooltip title="Vehicle Location">
      <div className="color-white -ml-6 -mt-6 flex h-12 w-12 items-center justify-center rounded-full border-4 border-white bg-black">
        <FontAwesomeIcon
          icon={faTruck}
          data-testid="vehicle-marker"
          className="z-4 absolute text-lg text-white"
        />
      </div>
    </Tooltip>
  );
};

const MerchantAndVehicleMarker = (_: Coords) => {
  return (
    <Tooltip title="The vehicle was located at the merchant.">
      <div className="color-white -ml-6 -mt-6 flex h-12 w-24 flex-row items-center justify-center gap-2 rounded-full border-4 border-white bg-black">
        <FontAwesomeIcon
          icon={faStore}
          data-testid="merchant-marker"
          className="z-4 text-lg text-white"
        />
        <FontAwesomeIcon
          icon={faTruck}
          data-testid="vehicle-marker"
          className="z-4 text-lg text-white"
        />
      </div>
    </Tooltip>
  );
};

export {
  ClusterMarker,
  DriverMarker,
  MerchantAndVehicleMarker,
  MerchantMarker,
  MapLabel as RenderPrimaryMarker,
  SimpleVehicleMarker,
  VehicleMarker,
};
