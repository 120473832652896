import { faMoneyBillTransfer } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useState, ReactElement } from 'react';
import EditRecipientModal from './PayNow/EditRecipientModal';
import TransferSelector, { transferButtonClasses } from './TransferSelector';
import {
  RecipientData,
  recipientDataToNewRecipient,
  RecipientToEdit,
  WithdrawFeeData,
  WithdrawMethod,
} from './transfer.types';

export default function WithdrawMethods({
  withdrawMethodFees,
  selectedWithdrawMethod,
  setSelectedWithdrawMethod,
  loading,
  recipient,
  editRecipient,
}: {
  withdrawMethodFees: WithdrawFeeData[];
  selectedWithdrawMethod: WithdrawFeeData | null;
  setSelectedWithdrawMethod: (selectedWithdrawMethod: WithdrawFeeData) => void;
  loading: boolean;
  recipient: RecipientData;
  editRecipient: (recipient: RecipientToEdit) => Promise<void>;
}): ReactElement | null {
  const [editingRecipient, setEditingRecipient] = useState(false);
  const selectTransferType = (transferType: WithdrawMethod) => {
    setSelectedWithdrawMethod(
      withdrawMethodFees.find((method) => method.type === transferType) as WithdrawFeeData,
    );
  };

  if (loading) {
    return (
      <div className="my-3 flex h-full animate-pulse items-center">
        <div className="h-24 flex-1 rounded-sm bg-gray-100" />
        <div className="ml-3 h-24 flex-1 rounded-sm bg-gray-100" />
      </div>
    );
  }

  if (withdrawMethodFees.length === 0) {
    return null;
  }

  return (
    <>
      <div className="mb-2 text-sm font-medium text-gray-700">Select transfer method</div>
      <TransferSelector
        transferMethods={withdrawMethodFees}
        transferType={selectedWithdrawMethod?.type || 'ach'}
        selectTransferType={selectTransferType}
      >
        {(transferMethod: WithdrawMethod, error: string) => {
          if (transferMethod === 'us_domestic_wire' && error === 'NO_ADDRESS_FOUND') {
            return (
              <button
                type="button"
                className={classNames(transferButtonClasses(false), 'flex-auto flex-col')}
                onClick={() => setEditingRecipient(true)}
              >
                <FontAwesomeIcon icon={faMoneyBillTransfer} />
                <div>Wire Transfer</div>
                <div className="text-sm font-light">Address needed</div>
                <div className="text-sm font-light">Click to add</div>
              </button>
            );
          }
          return null;
        }}
      </TransferSelector>
      <EditRecipientModal
        open={editingRecipient}
        reset={() => setEditingRecipient(false)}
        recipient={recipientDataToNewRecipient(recipient)}
        onEditRecipient={editRecipient}
      />
    </>
  );
}
