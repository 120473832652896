import { EndpointResponse } from '@app/@types/api.types';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import useSWR, { SWRResponse } from 'swr';

export type LineItemDetails = {
  purchase_type: 'unavailable' | 'fuel' | 'non_fuel' | 'fuel_and_non_fuel';
  fuel_amount_cents: number;
  non_fuel_amount_cents: number;
  line_items: LineItem[];
  line_items_ready: boolean;
};

type LineItem = {
  description: string;
  amount_cents: number;
};

export const useLineItemsQuery = (
  id?: number,
): SWRResponse<EndpointResponse<LineItemDetails>, Error> => {
  return useSWR<EndpointResponse<LineItemDetails>>(
    !!id && { url: `/transactions/${id}/transaction_details` },
    apiGetFetcher,
  );
};
