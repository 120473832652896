import { BankAccountPaymentMethodCombined } from '@app/@types/bankAccount.types';
import { DebitCardPaymentMethodCombined } from '@app/@types/debitCard.types';
import { capitalize } from 'lodash-es';
import { ReactElement } from 'react';

export const renderDebitCard = (item: DebitCardPaymentMethodCombined): ReactElement => {
  return (
    <div>
      {capitalize(item.payment_method_detail.brand)} •••• {item.payment_method_detail.last_four}
    </div>
  );
};

export const renderBankAccount = (item: BankAccountPaymentMethodCombined): ReactElement => {
  return (
    <div>
      {item.payment_method_detail.institution_name} ••••{item.payment_method_detail.mask}
    </div>
  );
};
