import { DailyTransactionSummaryData } from '@app/@types/transaction.types';
import { DatePeriodControl } from '@app/components/elements';
import TransactionsChart from '@app/components/elements/Chart/TransactionsChart';
import { CircularProgress } from '@mui/material';
import { ReactElement, Suspense } from 'react';

export default function SpendChart({
  transactions,
  allowViewTransactions,
  startAt,
  setStartAt,
  endAt,
  setEndAt,
  periodLength,
  setPeriodLength,
}: {
  transactions: DailyTransactionSummaryData[];
  allowViewTransactions: boolean;
  startAt: string;
  setStartAt: (startAt: string) => void;
  endAt: string;
  setEndAt: (endAt: string) => void;
  periodLength: number;
  setPeriodLength: (periodLength: number) => void;
}): ReactElement {
  if (!transactions || transactions.length === 0) {
    return <div data-testid="spendchart-nullstate" />;
  }
  return (
    <>
      <Suspense fallback={<CircularProgress />}>
        <TransactionsChart
          transactions={transactions}
          allowViewTransactions={allowViewTransactions}
        />
      </Suspense>
      <div className="mt-6 text-center">
        <DatePeriodControl
          startAt={startAt}
          setStartAt={setStartAt}
          endAt={endAt}
          setEndAt={setEndAt}
          periodLength={periodLength}
          setPeriodLength={setPeriodLength}
        />
      </div>
    </>
  );
}
