export type FuelListingsResponse = {
  data: FuelListing[];
};

export type ComputeRouteWithListingsResponse = {
  data: {
    fuel_listings: FuelListingsResponse;
    route_coordinates: Coordinates[];
    route_distance_meters: number;
  };
};

export type FuelListing = {
  id: string;
  attributes: {
    site_id: number;
    fuel_prices: {
      data: FuelPrice[];
    };
    location: FuelListingLocation;
    claim_metadata: null;
    discount_type: string;
    has_high_flow_diesel_lanes: boolean;
    is_claimable: boolean;
    logo_url: string | null;
  };
};

export type FuelGrade = 'REGULAR' | 'MIDGRADE' | 'PREMIUM' | 'DIESEL' | 'TRUCK DIESEL';

export type FuelPrice = {
  id: string;
  attributes: {
    discount_per_gallon_cents: number;
    fuel_grade: FuelGrade;
    fuel_sign_time: string | null;
    fuel_sign_price_cents: number;
    high_flow: boolean;
    id: string;
    effective_cost_per_gallon_cents: number;
    discount_type: 'RETAIL_MINUS' | 'COST_PLUS';
    tax_exemption_per_gallon_cents: number;
  };
};

export type FuelListingLocation = {
  address1: string;
  coordinates: Coordinates;
  countryCode: string;
  locality: string;
  name: string;
  postCode: string;
  region: string;
};

export type Coordinates = {
  latitude: number;
  longitude: number;
};

export type MyLocation = {
  location_type: 'my_location' | 'map_area' | 'company_location';
  place_id: 'current_location' | 'map_location' | 'company_location' | 'empty';
  formatted_address: string;
  address_components: [];
  description: string;
  structured_formatting: {
    main_text: string;
    secondary_text: '';
  };
  geometry: {
    location: {
      lat: () => number;
      lng: () => number;
    };
  };
} & PlaceType;

export interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
  place_id: string;
}

interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
}

export type SortType = 'discountedPrice' | 'distance' | 'recommended';

export enum PriceListingType {
  GUARANTEED_DISCOUNT_VALID_PRICE = 'guaranteed-discount-valid-price',
  GUARANTEED_DISCOUNT_INVALID_PRICE = 'guaranteed-discount-invalid-price',
  GUARANTEED_PRICE_VALID_PRICE = 'guaranteed-price-valid-price',
  GUARANTEED_PRICE_INVALID_PRICE = 'guaranteed-price-invalid-price',
  NO_DISCOUNT_VALID_PRICE = 'no-discount-valid-price',
  NO_DISCOUNT_INVALID_PRICE = 'no-discount-invalid-price',
}
