import AtobAppIcon from '@app/assets/images/svg/atob_app_icon.svg';
import { ProductNudgeBanner } from '@app/components/Banner/Designs/ProductNudgeBanner';
import FuelListings from '@app/components/FuelListings/FuelListings';
import MobileNavHeader from '@app/components/Navigation/MobileNavHeader';
import useIsDeviceAppEligible from '@app/components/popups/components/useIsDeviceAppEligible';
import useChannelPartner from '@app/hooks/useChannelPartner';
import useCustomer from '@app/hooks/useCustomer';
import useIsNativeApp from '@app/hooks/useIsNativeApp';
import { getEnvironment } from '@app/utils/environment';
import { ReactElement } from 'react';
import FleetTypePopup from './FleetTypePopup';

export const FuelFinder = () => {
  const customer = useCustomer();
  const { VITE_GOOGLE_API_KEY, VITE_MAP_ID_FUEL_FINDER } = getEnvironment();
  const { partnerName } = useChannelPartner();
  return (
    <>
      <div className="h-mobile-screen w-full md:sticky md:top-0 md:h-screen">
        <FleetTypePopup customer={customer} />
        <FuelListings
          MAP_ID={VITE_MAP_ID_FUEL_FINDER as string}
          GOOGLE_MAPS_API_KEY={VITE_GOOGLE_API_KEY as string}
          partnerName={partnerName}
          defaultSettings={{
            isOTRCustomer:
              customer.fleet_type === 'heavy' ||
              customer.fleet_type === 'heavy_assumed' ||
              customer.fleet_type === 'mixed' ||
              customer.fleet_type === 'mixed_assumed',
            customerAddress: customer.company_address,
          }}
        />
      </div>
    </>
  );
};

export default function FuelMapPage(): ReactElement | null {
  const [deviceType] = useIsDeviceAppEligible();
  const { isNativeApp } = useIsNativeApp();
  if (isNativeApp) {
    return null;
  }

  return (
    <>
      <MobileNavHeader title={'Fuel Map'} />
      {(deviceType === 'android' || deviceType === 'ios') && (
        <div className="px-3 md:px-0">
          <ProductNudgeBanner
            iconImg={<img alt="AtoB App Icon" src={AtobAppIcon} />}
            variant="pro_tip"
            nonDismissable={true}
            onClick={() => (window.location.href = 'https://atob-app.app.link/')}
            bannerKey="mweb_app_fuelfinder"
            actionText="Open the AtoB App"
          >
            Fuel Map is better in the app! View by location, get exclusive discounts, and manage
            your entire account, only in the AtoB app.
          </ProductNudgeBanner>
        </div>
      )}
      <FuelFinder />
    </>
  );
}
