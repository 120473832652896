import useChannelPartner from '@app/hooks/useChannelPartner';
import useWindowWidth from '@app/hooks/useWindowWidth';
import possessive from '@app/utils/possessive';
import {
  faFileArrowUp,
  faEnvelopeOpenDollar,
  faClock,
  faCircleDollar,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import axios from 'axios';
import classNames from 'classnames';
import { ReactElement, ReactNode } from 'react';

const Benefit = ({
  title,
  reason,
  children,
}: {
  title: string;
  reason: string;
  children: ReactNode;
}) => {
  return (
    <div className="xs:mb-0 mb-8 max-w-[400px] last:mb-0 sm:mb-4">
      {children}
      <h2 className="text-black0 my-3 text-[24px] font-[400] leading-6 sm:my-4 sm:text-[24px]">
        {title}
      </h2>
      <p className="text-grey6 text-[16px] font-[400] sm:text-[18px]">{reason}</p>
    </div>
  );
};

export default function PayrollMonetizationBenefits({
  onClick,
}: {
  onClick: () => void;
}): ReactElement {
  const { isMobile } = useWindowWidth();
  const { partnerName } = useChannelPartner();
  const iconSize = isMobile ? '2x' : '3x';

  const handleFreeTrialStart = () => {
    onClick();
    axios.post('/subscriptions', { product: 'payroll' });
  };

  return (
    <div>
      <div className="max-w-72 rounded-md bg-white p-8 shadow-md">
        <div className="flex flex-col items-center">
          <h2
            className={classNames('text-atob-green text-center text-[32px] font-[700]', {
              'mb-8': !isMobile,
            })}
          >
            Benefits of {partnerName && possessive(partnerName)} Instant Payroll
          </h2>
          {isMobile && (
            <div className="my-4 flex flex-col items-center">
              <div className="mb-4 w-full">
                <Button size="medium" onClick={handleFreeTrialStart}>
                  1st month FREE
                </Button>
              </div>
            </div>
          )}
          <div className="xs:grid xs:grid-rows-2 xs:grid-cols-2 xs:gap-x-8 my-4 sm:gap-y-4 md:gap-y-8">
            <Benefit
              title="Instant transfers 24/7"
              reason="Pay your drivers as fast as 30 minutes; 24/7 with no limitations"
            >
              <FontAwesomeIcon icon={faClock} size={iconSize} className="text-atob-green" />
            </Benefit>
            <Benefit
              title="Simple, transparent pricing"
              reason="Choose between pay-as-you-go or our $25 subscription plan"
            >
              <FontAwesomeIcon icon={faCircleDollar} size={iconSize} className="text-atob-green" />
            </Benefit>
            <Benefit
              title="Simplify payroll calculations"
              reason="Custom fleet-specific calculations and itemizations; 1099 tax forms included"
            >
              <FontAwesomeIcon
                icon={faEnvelopeOpenDollar}
                size={iconSize}
                className="text-atob-green"
              />
            </Benefit>
            <Benefit
              title="Automated 1099 taxes"
              reason="Automatically create and file 1099 tax filings"
            >
              <FontAwesomeIcon icon={faFileArrowUp} size={iconSize} className="text-atob-green" />
            </Benefit>
          </div>
          {!isMobile && (
            <div className="flex flex-col items-center">
              <Button size="medium" onClick={handleFreeTrialStart}>
                1st month FREE
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
