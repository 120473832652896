import DebitCardModal from '@app/components/PaymentMethods/DebitCardModal';
import Plaid from '@app/components/Plaid';
import useModal from '@app/hooks/useModal';
import { faWallet } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { ReactElement } from 'react';

interface NoPaymentsSectionProps {
  setError: (e: Error) => void;
  handleRefreshPaymentMethods: () => void;
  onLinkingCallback: (phase: string) => void;
}

const NoPaymentsSection = ({
  setError,
  handleRefreshPaymentMethods,
  onLinkingCallback,
}: NoPaymentsSectionProps): ReactElement => {
  const [showDebitCardModal, setShowDebitCardModal] = useModal();
  return (
    <div className="flex h-full flex-col items-center justify-center">
      <FontAwesomeIcon icon={faWallet} size={'3x'} />
      <p className="text-black1 mb-4 mt-8 text-xl font-normal tracking-[0.01em]">
        No payment methods added
      </p>
      <p className="text-grey6 mb-10 text-base font-normal tracking-[0.01em]">
        Choose payment options
      </p>
      <div className="flex w-full max-w-[400px] flex-wrap justify-center gap-4">
        <div className="w-full max-w-[180px]">
          <Button size="small" onClick={() => setShowDebitCardModal()}>
            Connect Debit Card
          </Button>
        </div>
        <Plaid
          setError={setError}
          buttonProps={{
            className:
              'px-4 py-3 border border-transparent rounded-full text-sm leading-4 font-medium bg-atob-green text-white',
            primary: true,
          }}
          text="Connect Bank Account"
          onSuccessCallback={handleRefreshPaymentMethods}
          onLinkingCallback={onLinkingCallback}
        />
      </div>
      <div className="level-right flex-col items-end">
        <DebitCardModal isActive={showDebitCardModal} setIsActive={setShowDebitCardModal} />
      </div>
    </div>
  );
};

export default NoPaymentsSection;
