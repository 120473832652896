import { statesArray } from '@app/constants/states';
import {
  DropdownFormElement,
  InputFormElement,
} from '@atob-developers/shared/src/@types/form.types';
import { DataItemType } from '@atob-developers/shared/src/components/DataItem';
import FormElement from '@atob-developers/shared/src/components/FormElement';
import classNames from 'classnames';
import { ReactElement, Dispatch, SetStateAction } from 'react';

interface StateOptions {
  id: string | number;
  option: string;
}

const getCityStateZip = (isMobile: boolean) => {
  const city = {
    label: '',
    placeholder: 'City',
    type: DataItemType.TEXT,
    key: 'city',
    basis: isMobile ? 'full' : '2/4',
  };
  const state = {
    label: '',
    placeholder: 'State',
    type: DataItemType.DROPDOWN,
    key: 'state',
    options: statesArray.map((state, idx) => {
      return { id: idx, option: state };
    }),
    basis: isMobile ? '2/4' : '1/4',
  };
  const zip = {
    label: '',
    placeholder: 'Zip',
    type: DataItemType.TEXT,
    key: 'zip',
    basis: isMobile ? '2/4' : '1/4',
  };

  return isMobile ? [city, [state, zip]] : [[city, state, zip]];
};

const addressRows = (label: string, isMobile: boolean) => [
  {
    label,
    placeholder: 'Address Line #1',
    type: DataItemType.TEXT,
    key: 'address1',
    required: true,
  },
  {
    label: '',
    placeholder: 'Address Line #2 (optional)',
    type: DataItemType.TEXT,
    key: 'address2',
  },
  ...getCityStateZip(isMobile),
];

interface AddressFormElementProps {
  rowElement:
    | InputFormElement
    | InputFormElement[]
    | DropdownFormElement<StateOptions>
    | DropdownFormElement<StateOptions>[];
  values: Record<string, string | null>;
  errors: Record<string, string | undefined>;
  setValues: React.Dispatch<React.SetStateAction<unknown>>;
  setErrors: (erros: Record<string, string | undefined>) => void;
  isMobile: boolean;
}

const AddressFormElement = ({
  rowElement,
  values,
  errors,
  setValues,
  setErrors,
  isMobile,
}: AddressFormElementProps) => {
  const row = !Array.isArray(rowElement) ? [rowElement] : rowElement;

  return (
    <div className="flex flex-row">
      {row.map((item, idx) => (
        <div
          key={`container-${idx}`}
          className={classNames('mr-2', {
            'basis-full': !item?.basis && row.length === 1,
            [`basis-${item?.basis}`]: !!item?.basis,
            'flex': item.type === DataItemType.DROPDOWN,
            'w-full': isMobile && item.type !== DataItemType.DROPDOWN,
          })}
        >
          <FormElement
            key={`company-address-arr-${item.key}`}
            element={{
              ...item,
              key: item.key,
              renderSelectedItem: (item) => <div key={item.id}>{item.option}</div>,
              children: (item) => <div key={item.id}>{item.option}</div>,
              blankState: 'State',
            }}
            value={
              item.type === DataItemType.DROPDOWN
                ? (item as DropdownFormElement<StateOptions>)?.options?.find(
                    (i) => i.option === values?.[item.key],
                  ) || ''
                : values?.[item.key] || ''
            }
            error={errors[item.key]}
            handleOnChange={(val) => {
              setErrors({ ...errors, [item.key]: undefined });
              setValues({
                ...values,
                [item.key]:
                  item.type === DataItemType.DROPDOWN ? (val as StateOptions).option : val,
              });
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default function AddressInput({
  label,
  isMobile,
  addressValues,
  setAddressValues,
  errors,
  setErrors,
}: {
  label: string;
  isMobile: boolean;
  addressValues: Record<string, string>;
  setAddressValues: Dispatch<SetStateAction<unknown>>;
  errors: Record<string, string>;
  setErrors: (errors: Record<string, string | undefined>) => void;
}): ReactElement {
  return (
    <div className="flex flex-col">
      {addressRows(label, isMobile).map((item, idx) => (
        <AddressFormElement
          rowElement={item}
          key={`container-${idx}`}
          values={addressValues}
          errors={errors}
          setErrors={setErrors}
          setValues={setAddressValues}
          isMobile={isMobile}
        />
      ))}
    </div>
  );
}

export type Address = {
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
};
