import CustomerContext from '@app/contexts/customerContext';
import StripeContext from '@app/contexts/stripeContext';
import * as Sentry from '@sentry/react';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentIntent, PaymentIntentResult } from '@stripe/stripe-js';
import axios from 'axios';
import { useContext, useState } from 'react';

import stripeErrorHandler from './stripe-error-handler';

export function useSubmitStripePayment(
  onSuccess: () => void,
  confirmStripePayment: (clientSecret: string) => Promise<PaymentIntentResult> | undefined,
  successStatuses: PaymentIntent.Status[],
): [() => void, boolean, string | null] {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const { clientSecret: stripeClientSecret } = useContext(StripeContext);
  const { customer } = useContext(CustomerContext);
  const [error, setError] = useState<string | null>(null);

  const submitPayment = async () => {
    try {
      const paymentIntentResponse = await confirmStripePayment(stripeClientSecret);
      if (paymentIntentResponse === undefined) {
        return;
      }
      const { paymentIntent, error: paymentError } = paymentIntentResponse;

      const hasError = stripeErrorHandler(setError, paymentError);
      if (hasError) {
        return;
      }

      const status = paymentIntent?.status;
      if (status && successStatuses.includes(status)) {
        onSuccess();
      } else {
        setError('Payment failed, please try again.');
      }

      setLoading(false);
    } catch (e: unknown) {
      setLoading(false);
      Sentry.captureException(e, {
        user: {
          request_id: axios.isAxiosError(e) ? e?.response?.headers?.['x-request-id'] : '',
          customer_email: customer?.owner_email ?? '',
        },
      });
    }
  };

  const onSubmitPayment = async () => {
    if (loading) {
      return;
    }

    setError(null);
    setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    submitPayment();
  };

  return [onSubmitPayment, loading, error];
}
