import { CustomerData } from '@app/@types/customer.types';
import { useEffect } from 'react';

export default function useDelighted({ customer }: { customer: CustomerData | null }): void {
  useEffect(() => {
    if (!window.delighted) {
      return;
    }

    if (customer?.channel_partner?.name === 'uberfreight') {
      // Do not show Delighted for UF partners
      return;
    }

    const delightedInstance =
      customer?.channel_partner?.name === 'otr_solutions'
        ? window.otr_solutions_delighted
        : window.delighted;

    // adding user identity to Delighted
    // Only show to customers who have already made a transaction
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (customer?.current_week_spend?.cents > 0) {
      delightedInstance.survey({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        name: customer.owner_name,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        email: customer.owner_email,
        properties: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          customerId: customer.id,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          companyName: customer.company_name,
          sourceUrl: window.location.href,
        },
      });
    }
  }, [customer]);
}
