import logger from '@app/utils/datadog-logger';
import { captureException } from '@sentry/react';
import axios from 'axios';
import { isProduction } from '../environment';

/**
 * Handles errors returned from promises. Pairs well with useAsync.
 *
 * ```tsx
 * const { error } = useAsync(
 *   async () => throw new Error('my error')
 * )
 *
 * handleError(error) // If in development, prints to console
 * ```
 **/
export function handleError(error: unknown): unknown {
  if (!error) return null;

  // If this is a cancelled promise, leave
  if (axios.isCancel(error)) return null;

  let isIgnoreableHttpError = false;

  // In production, we don't want Sentry to capture exceptions for HTTP errors or common
  // Axios problems, such as dropped connections, connection aborts, connection timeouts,
  // etc. These kinds of errors are low signal in Sentry and should be logged in a different
  // system, such as Datadog.
  if (axios.isAxiosError(error)) {
    isIgnoreableHttpError = [404, 422].some((code) => error.response?.status === code);
  }

  if (isProduction()) {
    // If this isn't a common HTTP error, log it in Sentry
    if (!isIgnoreableHttpError) captureException(error);

    logger.error('Handle error called', {
      error: error,
    });
  }

  return error;
}
