import { Loading } from '@app/components/layout';
import CustomerContext from '@app/contexts/customerContext';
import { useConnectTelematicsOAuthToken } from '@app/hooks/query/useConnectTelematics';
import * as Sentry from '@sentry/react';
import { ReactElement, useContext } from 'react';
import { useParams } from 'react-router-dom';

const TELEMATICS_CONFIGURATION_PAGE = '/integrations';

const HandleTelematicsAuthCallback = (): ReactElement => {
  const { providerid } = useParams();
  const { customer } = useContext(CustomerContext);
  const { data: migratedOAuthData, error: migratedOAuthError } = useConnectTelematicsOAuthToken(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    providerid,
    window.location.search,
    true,
  );
  if (migratedOAuthData) {
    const { status } = migratedOAuthData;
    window.location.href = `${TELEMATICS_CONFIGURATION_PAGE}?status=${status}`;
  }
  if (migratedOAuthError) {
    Sentry.captureException(migratedOAuthError.message, {
      user: {
        customer_email: customer.owner_email ?? '',
      },
    });
    window.location.href = `${TELEMATICS_CONFIGURATION_PAGE}?status=not%20connected`;
  }

  return <Loading />;
};

export default HandleTelematicsAuthCallback;
