import { CustomerFeatureFlag } from '@app/@types/customer.types';

const featureFlagIsEnabled = (
  desiredFlagId: string,
  featureFlags?: CustomerFeatureFlag[],
): boolean => {
  if (!featureFlags) {
    return false;
  }
  return featureFlags.some((flag: CustomerFeatureFlag) => {
    return flag.id === desiredFlagId && flag?.enabled;
  });
};

export default featureFlagIsEnabled;
