import { DriverData } from '@app/@types/driver.types';
import DefaultPrompt from '@app/components/Prompt/DefaultPrompt';

import triggerResendPhoneVerification from '@app/components/tables/DriverTable/DriverTableUtils';
import { Button } from '@mui/material';
import { ReactElement, useState } from 'react';

interface PreviewDriverSidebarPhoneButtonProps {
  driver: DriverData;
}

const textMessageSentSuccessfully = 'Verification text message successfully sent!';

const PreviewDriverSidebarPhoneButton = ({
  driver,
}: PreviewDriverSidebarPhoneButtonProps): ReactElement | null => {
  const [showSentTextMessage, setShowSentTextMessage] = useState(false);

  if (!driver.phone || driver.phone_confirmed) {
    return null;
  }

  return showSentTextMessage ? (
    <DefaultPrompt
      error={false}
      clickHandler={() => setShowSentTextMessage(false)}
      message={textMessageSentSuccessfully}
    />
  ) : (
    <Button
      size="small"
      onClick={() =>
        triggerResendPhoneVerification((driver as unknown as DriverData).id, () =>
          setShowSentTextMessage(true),
        )
      }
    >
      Send Text Verification
    </Button>
  );
};

export default PreviewDriverSidebarPhoneButton;
