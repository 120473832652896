import classNames from 'classnames';
import { ForwardedRef, PropsWithChildren, forwardRef } from 'react';

const Card = forwardRef(function CardElement(
  {
    children,
    radius,
    noPadding = false,
  }: PropsWithChildren<{ radius?: 's' | 'm' | 'l'; noPadding?: boolean }>,
  ref?: ForwardedRef<HTMLDivElement>,
) {
  const RADIUS_SIZE = {
    s: 'rounded',
    m: 'rounded-lg',
    l: 'rounded-xl',
  };
  return (
    <div
      ref={ref}
      className={classNames(
        'rounded-card relative inline-block h-full w-full min-w-fit bg-white',
        RADIUS_SIZE[radius || 'm'],
        { 'p-[16px]': !noPadding },
      )}
    >
      {children}
    </div>
  );
});

export default Card;
