import { faCircleExclamation, faX } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { ReactElement, ReactNode } from 'react';

interface DefaultPromptProps {
  clickHandler: () => void;
  message: string;
  error?: boolean;
  icon?: ReactNode;
}

export default function DefaultPrompt({
  clickHandler,
  message,
  error = false,
  icon,
}: DefaultPromptProps): ReactElement {
  const iconColor = error ? 'text-dark-red' : 'text-atob-green';
  return (
    <div
      className={classnames(
        'flex w-full items-center justify-between rounded-md border-[1px] p-3',
        {
          'border-atob-green': !error,
          'border-red1': error,
        },
      )}
    >
      <div className="flex items-center">
        {icon ?? <FontAwesomeIcon className={iconColor} icon={faCircleExclamation} />}
        <p className="pl-2 text-sm font-normal">{message}</p>
      </div>
      <FontAwesomeIcon onClick={() => clickHandler()} icon={faX} className="cursor-pointer" />
    </div>
  );
}
