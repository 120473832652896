import { BankAccountPaymentMethodCombined } from '@app/@types/bankAccount.types';
import { DebitCardPaymentMethodCombined } from '@app/@types/debitCard.types';
import DebitCardModal from '@app/components/PaymentMethods/DebitCardModal';
import Ellipsis from '@app/components/elements/Ellipsis';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import useProduct from '@app/hooks/useProduct';
import RadioGroup from '@atob-developers/shared/src/components/RadioButton';
import { faBank, faMoneyBillTransfer } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import * as Sentry from '@sentry/react';
import classNames from 'classnames';
import { capitalize } from 'lodash-es';
import { ReactElement, useState } from 'react';
import Plaid from '../../../../components/Plaid';
import AddRecipientModal from './PayNow/AddRecipientModal';
import { AddRecipientAccount, RecipientData, RecipientToCreate } from './transfer.types';

type TransferType = 'ach' | 'debit' | 'wire';
type AccountSelection = 'inbound' | 'outbound';

const accountSelectorButtonClasses = (active: boolean) =>
  classNames(
    {
      'outline-none ring-offset-2 border-gray-900 border-2': active,
      'border-gray-300 border': !active,
    },
    'flex flex-col items-center w-1/2 m-1',
    'sm:mr-2 inline-flex items-center px-4 py-2',
    'shadow-sm text-base rounded-md',
    'font-medium text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50',
  );

const AccountSelector = ({
  accountType,
  setAccountSelectorType,
}: {
  accountType: AccountSelection;
  setAccountSelectorType: (type: AccountSelection) => void;
}): ReactElement => {
  return (
    <div className="mb-4 flex">
      <button
        type="button"
        className={accountSelectorButtonClasses(accountType === 'inbound')}
        onClick={() => setAccountSelectorType('inbound')}
      >
        <FontAwesomeIcon className="mr-2" icon={faBank} />
        <div
          className={classNames({
            'font-bold': accountType === 'inbound',
          })}
        >
          Your Accounts
        </div>
        <div className="text-sm font-light">These are accounts that you own</div>
      </button>
      <button
        type="button"
        className={accountSelectorButtonClasses(accountType === 'outbound')}
        onClick={() => setAccountSelectorType('outbound')}
      >
        <FontAwesomeIcon className="mr-2" icon={faMoneyBillTransfer} />
        <div
          className={classNames({
            'font-bold': accountType === 'outbound',
          })}
        >
          Others' Accounts
        </div>
        <div className="text-sm font-light">These are owned by someone else</div>
      </button>
    </div>
  );
};

const AddInternalAccount = ({
  overallError,
  setOverallError,
  fetchPaymentMethods,
  transferType,
}: {
  overallError: string | null;
  setOverallError: (error: string) => void;
  fetchPaymentMethods: (isInbound: boolean) => Promise<void>;
  transferType: TransferType | null;
}): ReactElement => {
  const [loading, setLoading] = useState(false);
  const [debitModalActive, setDebitModalActive] = useState(false);

  if (loading && !overallError) {
    return (
      <div>
        <div className="text-sm text-gray-600">
          Finishing linking your account
          <Ellipsis />
        </div>
        <div className="my-3 flex h-full animate-pulse items-center">
          <div className="h-8 flex-1 rounded-sm bg-gray-100" />
        </div>
      </div>
    );
  }

  if (transferType === 'debit') {
    return (
      <div>
        <Button
          onClick={(e) => {
            e.preventDefault();
            setDebitModalActive(true);
          }}
          className="mb-2 border border-transparent bg-gray-500 text-sm font-medium uppercase leading-4 text-white"
          size={'small'}
        >
          Add new debit card
        </Button>
        <DebitCardModal
          isActive={debitModalActive}
          setIsActive={setDebitModalActive}
          onSuccess={async () => {
            await fetchPaymentMethods(true);
            setDebitModalActive(false);
          }}
        />
      </div>
    );
  }

  return (
    <>
      <div className="my-2">
        <Plaid
          setError={(err) => {
            Sentry.captureException(err);
            setOverallError(
              'Something went wrong while connecting to your bank account. Please try again.',
            );
          }}
          buttonProps={{
            className:
              'mb-2 border border-transparent bg-gray-500 text-sm leading-4 font-medium text-white uppercase',
            size: 'small',
          }}
          text="Add new bank account"
          onLinkingCallback={() => {
            setLoading(true);
          }}
          onSuccessCallback={async () => {
            setLoading(false);
            await fetchPaymentMethods(true);
          }}
        />
      </div>
      <div className="my-2 ml-1 text-xs text-gray-700">
        Newly linked accounts will take up to 5 minutes to activate.
      </div>
    </>
  );
};

const AddRecipientAccountDetails = ({
  addRecipientBankAccount,
}: {
  addRecipientBankAccount: AddRecipientAccount;
}): ReactElement => {
  const [addRecipientModalActive, setAddRecipientModalActive] = useState(false);
  return (
    <>
      <div
        onClick={() => setAddRecipientModalActive(true)}
        className="text-atob-green flex w-full cursor-pointer border border-transparent text-sm font-medium leading-4"
      >
        + Add recipient account
      </div>
      <AddRecipientModal
        open={addRecipientModalActive}
        onAddRecipient={addRecipientBankAccount}
        reset={() => setAddRecipientModalActive(false)}
      />
    </>
  );
};

interface WalletAccountFundsProps {
  bankAccounts: BankAccountPaymentMethodCombined[];
  loadingAccounts: boolean;
  selectedAccountId: string | null;
  setSelectedAccount: (accountType: 'inbound' | 'outbound', id: string | null) => void;
  overallError: string | null;
  setOverallError: (error: string) => void;
  flow: 'inbound' | 'outbound';
  recipientAccounts?: RecipientData[];
  addRecipientAccount?: AddRecipientAccount;
  fetchPaymentMethods: (isInbound: boolean) => Promise<void>;
  transferType?: 'ach' | 'debit' | 'wire';
  debitCards?: DebitCardPaymentMethodCombined[];
  selectedDebitCardId: string | null;
  setSelectedDebitCardId?: (id: string | null) => void;
}

function WalletAccountFundsOld({
  bankAccounts,
  loadingAccounts,
  selectedAccountId,
  setSelectedAccount,
  overallError,
  setOverallError,
  flow,
  recipientAccounts,
  addRecipientAccount,
  fetchPaymentMethods,
  transferType,
  debitCards,
  selectedDebitCardId,
  setSelectedDebitCardId,
}: WalletAccountFundsProps): ReactElement {
  const [accountSelectorType, setAccountSelectorType] = useState<AccountSelection>('inbound');
  const renderAccountSelector = flow === 'outbound';
  const inboundAccounts = accountSelectorType === 'inbound';
  const isDebit = transferType === 'debit';
  const [ACH_ENABLED] = useProduct('treasury_ach_debit');

  const debitCardsData = debitCards?.map((debitCard) => {
    return {
      ...debitCard,
      id: debitCard.id,
      checked: selectedDebitCardId === debitCard.id,
      label: `${capitalize(debitCard.brand)} ••••${debitCard.last_four}`,
      value: debitCard.id,
      name: `${capitalize(debitCard.brand)} ••••${debitCard.last_four}`,
    };
  });

  const activeBankAccountData = bankAccounts
    .filter((account) => account.state === 'active')
    .map((account) => ({
      ...account,
      id: account.id,
      checked: selectedAccountId === account.id,
      label: `${account.name} ••••${account.mask}`,
      value: account.id,
      name: `${account.name} `,
    }));

  const pendingBankAccountData = bankAccounts
    .filter((account) => account.state === 'pending')
    .map((account) => ({
      ...account,
      id: account.id,
      checked: selectedAccountId === account.id,
      label: `${account.name} ••••${account.mask}`,
      value: account.id,
      name: `${account.name} `,
    }));

  const activeAccounts = inboundAccounts
    ? activeBankAccountData
    : recipientAccounts?.map((account) => {
        const mask = account.payment_details?.bank_account?.mask;
        const label = account.display_name
          ? account.display_name
          : `${account.payment_details.bank_account.institution_name} ••••${mask}`;
        return {
          ...account,
          id: account.id,
          checked: selectedAccountId === account.id,
          label,
          value: account.id,
          name: `${account.billing_details.name} `,
        };
      });

  const addRecipientBankAccount = async (recipient: RecipientToCreate) => {
    setSelectedAccount('outbound', null);
    addRecipientAccount && (await addRecipientAccount(recipient));
  };
  const AccountsToSelect = () => {
    const [debitModalActive, setDebitModalActive] = useState(false);

    if (isDebit) {
      const hasUpdateRequired =
        debitCardsData?.find((debitCard) => debitCard.id === selectedDebitCardId)
          ?.update_required === true;

      return (
        <>
          <RadioGroup
            key={selectedDebitCardId}
            data={debitCardsData || []}
            onSelect={(id) => {
              setSelectedDebitCardId && setSelectedDebitCardId(id);
            }}
          />
          {hasUpdateRequired && (
            <div className="my-2 rounded border border-gray-200 p-2 text-sm">
              <b>Important: </b>
              This card needs to be confirmed before it can be used to add funds to your wallet.
              <div className="my-3 flex justify-center font-bold">
                <div>
                  <Button
                    size={'small'}
                    color={'primary'}
                    onClick={() => setDebitModalActive(true)}
                  >
                    Confirm card details
                  </Button>
                </div>
              </div>
              <DebitCardModal
                title="Confirm card details"
                isActive={debitModalActive}
                setIsActive={setDebitModalActive}
                onSuccess={async () => {
                  await fetchPaymentMethods(true);
                  setDebitModalActive(false);
                }}
              />
            </div>
          )}
        </>
      );
    }
    return (
      <RadioGroup
        key={selectedAccountId}
        data={activeAccounts || []}
        onSelect={(id) => {
          setSelectedAccount(accountSelectorType, id);
        }}
      />
    );
  };

  if (transferType === 'wire' || (transferType === 'ach' && !ACH_ENABLED)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return null;
  }

  return (
    <>
      {renderAccountSelector && (
        <AccountSelector
          accountType={accountSelectorType}
          setAccountSelectorType={(selection: AccountSelection) => {
            setSelectedAccount(selection, null);
            setAccountSelectorType(selection);
          }}
        />
      )}
      {loadingAccounts ? (
        <div className="text-atob-green text-sm">
          Retrieving accounts
          <Ellipsis />
        </div>
      ) : (
        <div className="flex w-full flex-col flex-wrap">
          <div className="mb-2 text-sm font-medium text-gray-700">
            Select {flow === 'inbound' ? 'Source ' : 'Destination'} Account
          </div>
          <AccountsToSelect />
          {inboundAccounts &&
            pendingBankAccountData.length > 0 &&
            pendingBankAccountData.map((account) => (
              <div key={account.id}>
                <Plaid
                  setError={() => {
                    setOverallError(
                      'There was a problem completing your bank verification. Please refresh the page and try again.',
                    );
                  }}
                  buttonProps={{
                    className:
                      'mb-3 border border-transparent text-sm font-medium bg-white text-atob-green',
                    size: 'small',
                  }}
                  text={`Complete Bank Verification for ${account.name} →`}
                  pendingBankAccountId={account.bank_account_id}
                  onSuccessCallback={() => fetchPaymentMethods(true)}
                  onLinkingCallback={() => {}}
                />
              </div>
            ))}
          {inboundAccounts && transferType ? (
            <AddInternalAccount
              overallError={overallError}
              setOverallError={setOverallError}
              fetchPaymentMethods={fetchPaymentMethods}
              transferType={transferType}
            />
          ) : (
            <AddRecipientAccountDetails addRecipientBankAccount={addRecipientBankAccount} />
          )}
        </div>
      )}
    </>
  );
}

function WalletAccountFundsNew({
  bankAccounts,
  loadingAccounts,
  selectedAccountId,
  setSelectedAccount,
  overallError,
  setOverallError,
  flow,
  recipientAccounts,
  addRecipientAccount,
  fetchPaymentMethods,
  transferType,
  debitCards,
  selectedDebitCardId,
  setSelectedDebitCardId,
}: WalletAccountFundsProps): ReactElement {
  const isDebit = transferType === 'debit';
  const [ACH_ENABLED] = useProduct('treasury_ach_debit');

  const debitCardsData = debitCards?.map((debitCard) => {
    return {
      ...debitCard,
      id: debitCard.id,
      checked: selectedDebitCardId === debitCard.id,
      label: `${capitalize(debitCard.brand)} ••••${debitCard.last_four}`,
      value: debitCard.id,
      name: `${capitalize(debitCard.brand)} ••••${debitCard.last_four}`,
    };
  });

  const activeBankAccountData = bankAccounts
    .filter((account) => account.state === 'active')
    .map((account) => ({
      account: account,
      id: account.id,
      checked: selectedAccountId === account.id,
      label: `${account.name} ••••${account.mask}`,
      value: account.id,
      name: `${account.name} `,
      direction: 'inbound',
    }));

  const pendingBankAccountData = bankAccounts
    .filter((account) => account.state === 'pending')
    .map((account) => ({
      account: account,
      id: account.id,
      checked: selectedAccountId === account.id,
      label: `${account.name} ••••${account.mask}`,
      value: account.id,
      name: `${account.name} `,
      direction: 'inbound',
    }));

  const recipientAccountsData = recipientAccounts?.map((account) => {
    const mask = account.payment_details?.bank_account?.mask;
    const label = account.display_name
      ? account.display_name
      : `${account.payment_details.bank_account.institution_name} ••••${mask}`;
    return {
      account: account,
      id: account.id,
      checked: selectedAccountId === account.id,
      label,
      value: account.id,
      name: `${account.billing_details.name} `,
      direction: 'outbound',
    };
  });

  let selectableAccounts: {
    account: BankAccountPaymentMethodCombined | RecipientData;
    direction: string;
    id: string;
    checked: boolean;
    label: string;
    value: string;
    name: string;
  }[];
  if (flow === 'inbound') {
    // Show only the inbound accounts
    selectableAccounts = [...activeBankAccountData];
  } else {
    // Show both inbound and outbound accounts
    selectableAccounts = [...activeBankAccountData, ...(recipientAccountsData || [])];
  }

  const addRecipientBankAccount = async (recipient: RecipientToCreate) => {
    setSelectedAccount('outbound', null);
    addRecipientAccount && (await addRecipientAccount(recipient));
  };
  const AccountsToSelect = () => {
    const [debitModalActive, setDebitModalActive] = useState(false);

    if (isDebit) {
      const hasUpdateRequired =
        debitCardsData?.find((debitCard) => debitCard.id === selectedDebitCardId)
          ?.update_required === true;

      return (
        <>
          <RadioGroup
            key={selectedDebitCardId}
            data={debitCardsData || []}
            onSelect={(id) => {
              setSelectedDebitCardId && setSelectedDebitCardId(id);
            }}
          />
          {hasUpdateRequired && (
            <div className="my-2 rounded border border-gray-200 p-2 text-sm">
              <b>Important: </b>
              This card needs to be confirmed before it can be used to add funds to your wallet.
              <div className="my-3 flex justify-center font-bold">
                <div>
                  <Button
                    size={'small'}
                    color={'primary'}
                    onClick={() => setDebitModalActive(true)}
                  >
                    Confirm card details
                  </Button>
                </div>
              </div>
              <DebitCardModal
                title="Confirm card details"
                isActive={debitModalActive}
                setIsActive={setDebitModalActive}
                onSuccess={async () => {
                  await fetchPaymentMethods(true);
                  setDebitModalActive(false);
                }}
              />
            </div>
          )}
        </>
      );
    }
    return (
      <RadioGroup
        key={selectedAccountId}
        data={selectableAccounts || []}
        onSelect={(id) => {
          const account = selectableAccounts.find((account) => account.id === id)!;
          setSelectedAccount(account.direction as 'inbound' | 'outbound', id);
        }}
      />
    );
  };

  if (transferType === 'wire' || (transferType === 'ach' && !ACH_ENABLED)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return null;
  }

  return (
    <>
      {loadingAccounts ? (
        <div className="text-atob-green text-sm">
          Retrieving accounts
          <Ellipsis />
        </div>
      ) : (
        <div className="flex w-full flex-col flex-wrap">
          <div className="mb-2 text-sm font-medium text-gray-700">
            Select {flow === 'inbound' ? 'Source ' : 'Destination'} Account
          </div>
          <AccountsToSelect />
          {pendingBankAccountData.length > 0 &&
            pendingBankAccountData.map((pendingAccount) => (
              <div key={pendingAccount.id}>
                <Plaid
                  setError={() => {
                    setOverallError(
                      'There was a problem completing your bank verification. Please refresh the page and try again.',
                    );
                  }}
                  buttonProps={{
                    className:
                      'mb-3 border border-transparent text-sm font-medium bg-white text-atob-green',
                    size: 'small',
                  }}
                  text={`Complete Bank Verification for ${pendingAccount.name} →`}
                  pendingBankAccountId={pendingAccount.account.bank_account_id}
                  onSuccessCallback={() => fetchPaymentMethods(true)}
                  onLinkingCallback={() => {}}
                />
              </div>
            ))}
          {transferType ? (
            <AddInternalAccount
              overallError={overallError}
              setOverallError={setOverallError}
              fetchPaymentMethods={fetchPaymentMethods}
              transferType={transferType}
            />
          ) : (
            <AddRecipientAccountDetails addRecipientBankAccount={addRecipientBankAccount} />
          )}
        </div>
      )}
    </>
  );
}

function WalletAccountFunds(props: WalletAccountFundsProps) {
  const [experimentEnabled] = useFeatureFlags('wallet_account_funds_v2');
  return experimentEnabled ? (
    <WalletAccountFundsNew {...props} />
  ) : (
    <WalletAccountFundsOld {...props} />
  );
}

export default WalletAccountFunds;
