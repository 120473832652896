import { FuelCardAccountDiscount } from '@app/@types/fuel-card-account.types';
import useThemeConfiguration from '@app/app/useThemeConfiguration';
import useProduct from '@app/hooks/useProduct';
import { FindFuelPrimaryAction } from '@app/pages/AccountOverview/PrimaryActions';
import { PeriodSavingsTile, PrepaidAvailableToSpendTile } from '@app/pages/AccountOverview/Tiles';
import { formatDateNoYear } from '@app/utils/formatDate';
import useIsAllowedToFund from '@app/utils/funding/useIsAllowedToFund';
import { faCog } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import SavingsModal from '../Modals/AccountOverviewModals/SavingsModal';
import CollapsibleTilesContainer from '../OverviewTiles/CollapsibleTilesContainer';
import AutoTopUpSidebar from '../Prepaid/AutoTopUps/AutoTopUpSidebar';
import { getMonthDate } from '../Prepaid/AutoTopUps/ScheduleAutoTopUp';
import { useAutoTopUpData } from '../Prepaid/AutoTopUps/use-auto-topup-data';
import { AutoTopUpState } from '../Prepaid/Prepaid.types';

interface HeadingCardProps {
  periodAvailableAmount: string;
  currentWeekSpend: string;
  discountMetadata?: FuelCardAccountDiscount;
  discountAmount: string;
  potentialDiscountAmount: string;
  pendingSpendAmount: string;
  lifetimeDiscountAmount: string;
  lifetimeAverageDiscountPerGallon: string;
  autoTopUpSidebarOpen: boolean;
  setAutoTopUpSidebarOpen: (isOpen: boolean) => void;
  periodEndDate?: string;
  periodStartDate?: string;
}

const getNextTopUpDate = (autoTopUp: AutoTopUpState) => {
  if (autoTopUp.cadence_schedule === 'weekly') {
    return `every ${dayjs(autoTopUp.startingOn).format('dddd')}`;
  }
  return `on ${getMonthDate(autoTopUp.startingOn)}`;
};

const AutoTopUpDetails = ({ autoTopUp }: { autoTopUp: AutoTopUpState }): ReactElement => {
  const { frequency } = autoTopUp;

  if (frequency === 'cadence') {
    return (
      <div>
        <div className="font-semibold">Auto-Deposit enabled</div>
        <div>
          <span>
            ${autoTopUp.amount} {getNextTopUpDate(autoTopUp)}
          </span>
          <span className="ml-1 underline">Edit</span>
        </div>
      </div>
    );
  }

  if (frequency === 'balance') {
    return (
      <div>
        <div className="font-semibold">Auto-Deposit enabled</div>
        <div>
          <span>
            ${autoTopUp.amount} if balance is below ${autoTopUp.balance}
          </span>
          <span className="ml-1 underline">Edit</span>
        </div>
      </div>
    );
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return null;
};

export const ConfigureAutoTopUp = ({
  setAutoTopUpSidebarOpen,
}: {
  setAutoTopUpSidebarOpen: (isOpen: boolean) => void;
}): ReactElement | null => {
  const { autoTopUp, hasAutoTopUp, isLoading, autoTopUpError } = useAutoTopUpData();
  const { allowMoneyDeposit } = useThemeConfiguration();
  const allowedToFund = useIsAllowedToFund();

  if (autoTopUpError || isLoading || !allowMoneyDeposit) {
    return null;
  }

  return hasAutoTopUp ? (
    <div onClick={() => setAutoTopUpSidebarOpen(true)} className="cursor-pointer text-sm">
      <AutoTopUpDetails autoTopUp={autoTopUp} />
    </div>
  ) : (
    <Button disabled={!allowedToFund} onClick={() => setAutoTopUpSidebarOpen(true)}>
      Enable Auto-Deposit
    </Button>
  );
};

export const PeriodHeader = ({
  periodStartDate,
  periodEndDate,
  hideSettingsButton,
}: {
  periodStartDate?: string;
  periodEndDate?: string;
  hideSettingsButton?: boolean;
}) => {
  const configuration = useThemeConfiguration();

  const showPeriod = periodStartDate && periodEndDate;

  return (
    <div className="flex justify-between border-b-[1px] border-gray-100 px-4 py-[10px] text-sm font-semibold md:px-6">
      {showPeriod && (
        <p className="mr-3">
          Current Period: {formatDateNoYear(new Date(periodStartDate))} -{' '}
          {formatDateNoYear(new Date(periodEndDate))}
        </p>
      )}
      {!hideSettingsButton && !configuration.showAppNav && (
        <Link to="/settings">
          <div className="text-primary-600 flex h-full items-center gap-2 align-middle">
            <FontAwesomeIcon icon={faCog} size="lg" />
            <span className="hidden md:block">Settings</span>
          </div>
        </Link>
      )}
    </div>
  );
};

export default function HeadingCard({
  periodAvailableAmount,
  currentWeekSpend,
  discountMetadata,
  discountAmount,
  lifetimeDiscountAmount,
  lifetimeAverageDiscountPerGallon,
  autoTopUpSidebarOpen,
  setAutoTopUpSidebarOpen,
  potentialDiscountAmount,
  pendingSpendAmount,
  periodStartDate,
  periodEndDate,
}: HeadingCardProps): ReactElement {
  const [showSavingsModal, setShowSavingsModal] = useState<boolean>(false);
  const { autoTopUp, isLoading, autoTopUpError, refreshAutoTopUp } = useAutoTopUpData();
  const { showPrepaidAvailableToSpend, extractPeriodSavingsTile } = useThemeConfiguration();

  const modalToggle = () => setShowSavingsModal(!showSavingsModal);
  const [fuelFinder] = useProduct('fuel_finder');

  const primaryAction = fuelFinder && !extractPeriodSavingsTile && <FindFuelPrimaryAction />;

  return (
    <>
      <CollapsibleTilesContainer
        classes={{ gridContainer: 'grid-cols-1 md:grid-cols-2' }}
        header={<PeriodHeader periodStartDate={periodStartDate} periodEndDate={periodEndDate} />}
        collapsedPrimaryAction={primaryAction}
      >
        {showPrepaidAvailableToSpend && (
          <PrepaidAvailableToSpendTile
            availableAmount={periodAvailableAmount}
            setAutoTopUpSidebarOpen={setAutoTopUpSidebarOpen}
            currentWeekSpend={currentWeekSpend}
            periodStartDay="Monday"
            pendingSpendAmount={pendingSpendAmount}
          />
        )}
        <PeriodSavingsTile
          discountAmount={discountAmount}
          onDetailsClick={modalToggle}
          potentialDiscountAmount={potentialDiscountAmount}
        />
        {/* )} */}
        {showSavingsModal && (
          <SavingsModal
            showSavingsModal={showSavingsModal}
            modalToggle={modalToggle}
            lifetimeDiscountAmount={lifetimeDiscountAmount}
            lifetimeAverageDiscountPerGallon={lifetimeAverageDiscountPerGallon}
            discountMetadata={discountMetadata}
          />
        )}
      </CollapsibleTilesContainer>
      <AutoTopUpSidebar
        autoTopUp={autoTopUp}
        isLoading={isLoading}
        autoTopUpError={autoTopUpError}
        refreshAutoTopUp={refreshAutoTopUp}
        isOpen={autoTopUpSidebarOpen}
        toggle={() => {
          setAutoTopUpSidebarOpen(false);
          setTimeout(() => {
            refreshAutoTopUp();
          }, 1000);
        }}
      />
    </>
  );
}
