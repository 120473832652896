import { EndpointResponse } from '@app/@types/api.types';
import CardDesign from '@app/assets/images/svg/card_design.svg';
import { PromotionResponse } from '@app/components/Prepaid/SetupActivation/SetupPromotionsModal';
import { ACCOUNT_SETUP_FEE_DESCRIPTION } from '@app/constants/unlimited';
import useShippingAddress from '@app/hooks/use-shipping-address';
import useCustomer from '@app/hooks/useCustomer';
import { FetcherKey, apiPostFetcher, getFetcher } from '@app/utils/data/fetchers';
import logger from '@app/utils/datadog-logger';
import { guardAxiosError } from '@app/utils/error/guards';
import { FormatCurrency } from '@atob-developers/shared/src/utils/formatters';
import { faCube } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress } from '@mui/material';
import { AxiosError } from 'axios';
import { ReactElement } from 'react';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import { AddFundsForm } from '../Wallet/WalletOverview/TransferFunds/AddFundsModal';
import WalletPaymentMethods from '../Wallet/WalletOverview/TransferFunds/WalletPaymentMethods';
import OnboardingForm from './OnboardingForm';
import OnboardingRightUpsell from './OnboardingRightUpsell';
import { useOnboardingGuide } from './useOnboardingGuide';

export default function OnboardingSetupFeeStep(): ReactElement | null {
  const nextStep = useOnboardingGuide();
  const customer = useCustomer();
  const shippingAddress = useShippingAddress(customer);
  const { data: promotionsResponse, isLoading: isAllLoading } = useSWR<
    EndpointResponse<PromotionResponse[]>
  >(
    {
      url: '/treasury/account_promotions?account_setup_fee=true',
    },
    getFetcher,
    {
      async onSuccess(data) {
        const promotions = data.data;
        if (promotions.length == 0) {
          nextStep();
        }
        if (promotions.length == 1) {
          await selectPromotion({ account_promotion_id: promotions[0].id });
        }
      },
    },
  );

  const { trigger: selectPromotion } = useSWRMutation<
    EndpointResponse<unknown>,
    AxiosError,
    FetcherKey,
    { account_promotion_id: string }
  >(
    {
      url: '/treasury/account_promotions/accept',
    },
    apiPostFetcher,
    {
      onError: (e) => {
        if (guardAxiosError(e)) {
          logger.error('Error accepting promotion', e);
        }
      },
    },
  );

  if (isAllLoading) {
    return (
      <OnboardingForm>
        <h1 className="text-2xl font-semibold">Order your cards</h1>
        <p className="text-base font-normal">
          To get your AtoB Unlimited fuel cards shipped, make a payment by adding the one-time
          $35.00 setup fee amount to your Wallet:
        </p>
        <CircularProgress />
      </OnboardingForm>
    );
  }

  const setupPromotions =
    promotionsResponse?.data
      ?.map((promotion: PromotionResponse) => ({
        id: promotion.id,
        fee_amount_cents: promotion.attributes.details.fee_amount_cents,
        cost: FormatCurrency({
          value: promotion.attributes.details.fee_amount_cents,
          options: { fromCents: true },
        }),
        description: 'One-time account setup fee',
        discount_cents: promotion.attributes.details.discount_boost_per_gallon_cents,
        discount_description: promotion.attributes.details.discount_description,
        premium_included: promotion.attributes.details.premium_included,
      }))
      .sort((a, b) => {
        return a.fee_amount_cents > b.fee_amount_cents ? 1 : -1;
      }) || [];

  const depositAmount = setupPromotions[0]?.fee_amount_cents;

  return (
    <OnboardingForm>
      <h1 className="text-2xl font-semibold">Order your cards</h1>
      <p className="text-base font-normal">
        To get your AtoB Unlimited fuel cards shipped, make a payment by adding the one-time $35.00
        setup fee amount to your Wallet:
      </p>
      <div className="flex items-center px-6">
        <FontAwesomeIcon icon={faCube} size="2x" className="scale-75 pr-6 text-black" />
        <div>
          <div className="text-base">Your cards will be shipped to:</div>
          <div className="text-base">{shippingAddress}</div>
        </div>
      </div>
      <WalletPaymentMethods>
        {({ inboundBankAccounts, loading, fetchPaymentMethods, debitCards }) => (
          <AddFundsForm
            debitCards={debitCards}
            bankAccounts={inboundBankAccounts}
            reset={() => {}}
            onSuccessfulPayment={nextStep}
            loadingAccounts={loading}
            fetchPaymentMethods={fetchPaymentMethods}
            routingNumber={null}
            accountNumber={null}
            customerCompanyName={customer.company_name}
            customerCompanyAddress={customer.company_address}
            minimumDepositAmountCents={depositAmount}
            initialAmount={depositAmount}
            initialDescription={ACCOUNT_SETUP_FEE_DESCRIPTION}
            scenario={'account_setup_fee'}
          />
        )}
      </WalletPaymentMethods>
    </OnboardingForm>
  );
}

export function OnboardingSetupFeeUpsell(): ReactElement {
  return (
    <OnboardingRightUpsell>
      <img src={CardDesign} width="400" className="-rotate-12" />
    </OnboardingRightUpsell>
  );
}
