import useChannelPartner from '@app/hooks/useChannelPartner';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import { ReactElement } from 'react';
import Plaid from '../Plaid';
import { ErrorBanner } from './Designs/ErrorBanner';

export const ConnectBankAccountBanner = ({
  setError,
  onSuccessCallback,
}: {
  setError: (error: string) => void;
  onSuccessCallback: () => void;
}): ReactElement => {
  const { supportPhoneNumber } = useChannelPartner();
  const [suppressConnectBankAccountBanner] = useFeatureFlags(
    'suppress_connect_bank_account_banner',
  );

  if (suppressConnectBankAccountBanner) {
    return <></>;
  }

  return (
    <ErrorBanner
      customActionButton={
        <Plaid
          setError={() =>
            setError(
              'There was an error connecting to your bank account. Please contact support for assistance.',
            )
          }
          buttonProps={{
            primary: true,
          }}
          text="Connect Bank Account"
          onSuccessCallback={onSuccessCallback}
          onLinkingCallback={() => {}}
        />
      }
    >
      <div className="font-semibold">Securely connect your bank account to activate</div>
      <div>Have questions? Call us 24/7 at {supportPhoneNumber}.</div>
    </ErrorBanner>
  );
};

export default ConnectBankAccountBanner;
