import { SettingsPageEntityType } from '@app/constants/settings';
import { ReactElement } from 'react';
import DefaultPrompt from './DefaultPrompt';

interface SettingsPageUpdateInfoPromptProps {
  clickHandler: () => void;
  type: SettingsPageEntityType;
}

const phoneInfoUpdateMessage = 'Check your phone for a text confirming your phone number change';
const emailInfoUpdateMessage = 'Check your email for a link to confirm your email address change';
const addressInfoUpdateMessage = 'Successfully updated your address!';
const companyPreferredInfoUpdateMessage = 'Successfully updated your preferred name!';
const fleetTypeInfoUpdateMessage = 'Successfully updated your fleet type!';
const otpBackupUpdateMessage = 'Successfully updated app login backup email';

export default function SettingsPageUpdateInfoPrompt({
  clickHandler,
  type,
}: SettingsPageUpdateInfoPromptProps): ReactElement {
  let message;
  if (type === SettingsPageEntityType.PHONE_NUMBER) {
    message = phoneInfoUpdateMessage;
  } else if (type === SettingsPageEntityType.EMAIL_ADDRESS) {
    message = emailInfoUpdateMessage;
  } else if (type === SettingsPageEntityType.CUSTOMER_COMPANY_PREFERRED_NAME) {
    message = companyPreferredInfoUpdateMessage;
  } else if (type === SettingsPageEntityType.FLEET_TYPE) {
    message = fleetTypeInfoUpdateMessage;
  } else if (type === SettingsPageEntityType.OTP_BACKUP_EMAIL) {
    message = otpBackupUpdateMessage;
  } else {
    message = addressInfoUpdateMessage;
  }

  return (
    <div className="mb-4">
      <DefaultPrompt clickHandler={clickHandler} message={message} error={false} />
    </div>
  );
}
