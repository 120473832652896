import useThemeConfiguration from '@app/app/useThemeConfiguration';
import AmountApplicationBanner from '@app/components/Banner/AmountApplicationBanner';
import AtoBSavingsBanner from '@app/components/Banner/AtoBSavingsBanner';
import CashbackPromotionBanner from '@app/components/Banner/CashbackPromotionBanner';
import CreditEnablementNetNewBanner from '@app/components/Banner/CreditEnablementNetNewBanner';
import EngagedPromotionBanner from '@app/components/Banner/EngagedPromotionBanner';
import EngagedRebateBanner from '@app/components/Banner/EngagedRebateBanner';
import FundboxOfferBanner from '@app/components/Banner/FundboxOfferBanner';
import IFTANudgeBanner from '@app/components/Banner/IFTANudgeBanner';
import SecurityDepositOfferBanner from '@app/components/Banner/SecurityDepositOfferBanner';
import TAPetroBanner from '@app/components/Banner/TAPetroBanner';
import TelematicsNudgeBanner from '@app/components/Banner/TelematicsNudgeBanner';
import { EmbeddedNavigationElement } from '@app/components/Navigation/EmbeddedNav/EmbeddedNavigationElement';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import SpendAnalysis from '@app/components/SpendAnalysis/SpendAnalysis';
import { TotalSpend } from '@app/components/UberFreight/TotalSpend';
import { PageContentWrapper } from '@app/components/wrappers/PageContentWrapper';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import useProduct from '@app/hooks/useProduct';
import classNames from 'classnames';
import { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import AccountOverviewTransactions from './AccountOverviewTransactions';
import { FuelProgramCards } from './FuelProgramCards';
import LoadpayDriverAppOnboarding from './LoadpayDriverAppOnboading';

export const SectionTitle = ({
  title,
  className,
}: {
  title: string;
  className?: string;
}): ReactElement => (
  <div
    className={classNames(
      'border-b border-gray-100 p-4 text-xl font-semibold leading-7 tracking-tight',
      className,
    )}
  >
    {title}
  </div>
);

export default function AccountOverviewShared({
  header,
  overviewCards,
  onboarding,
}: {
  header?: ReactElement;
  overviewCards?: ReactElement | false;
  onboarding: ReactNode;
}): ReactElement {
  const { showSpendAnalysisOnDashboard, showFuelProgramsOnDashboard, showTotalSpend } =
    useThemeConfiguration();
  const [uberFreightTotalSpendTracker] = useFeatureFlags('uber_freight_total_spend_tracker');

  const [showCardsInfo, showBillingInfo, showSecurityDpeositsBanner] = useProduct(
    'cards',
    'billing',
    'security_deposits',
  );
  const { t } = useTranslation();

  return (
    <PageContentWrapper header={header} className="!gap-4">
      {onboarding}
      <LoadpayDriverAppOnboarding />
      <IFTANudgeBanner />
      <TelematicsNudgeBanner />
      <AmountApplicationBanner />
      <TAPetroBanner />
      <FundboxOfferBanner />
      <CashbackPromotionBanner />
      {showSecurityDpeositsBanner && <SecurityDepositOfferBanner />}
      <EngagedPromotionBanner />
      <EngagedRebateBanner />
      <CreditEnablementNetNewBanner />
      <AtoBSavingsBanner />

      {overviewCards}

      {showTotalSpend && uberFreightTotalSpendTracker && <TotalSpend />}

      <EmbeddedNavigationElement />

      {showCardsInfo && (
        <div className="triumph:bg-transparent triumph:rounded-none rounded-lg bg-white">
          <SectionTitle
            title={t('dashboard.card_transactions.title')}
            className="triumph:border-none triumph:px-0 triumph:sm:pb-0 p-4"
          />
          <div className="triumph:px-0 px-4 pb-4">
            <AccountOverviewTransactions />
          </div>
        </div>
      )}

      {showBillingInfo && showSpendAnalysisOnDashboard && (
        <RoundedCard>
          <SectionTitle title="Spend Analysis" />
          <SpendAnalysis allowViewTransactions={false} />
        </RoundedCard>
      )}

      {showFuelProgramsOnDashboard && <FuelProgramCards />}
    </PageContentWrapper>
  );
}
