import { TpayAccountPaymentMethod } from '@app/@types/payments.types';
import { handleMakePaymentMethodPrimary } from '@app/components/PaymentMethods/PaymentMethodUtils';
import { Spacer } from '@app/components/elements';
import { ErrorNotification } from '@app/components/layout';
import SideBar, { SideBarBody } from '@atob-developers/shared/src/components/SideBar';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Chip } from '@mui/material';
import { ReactElement, useState } from 'react';

export const TpayAccountSidebar = ({
  open,
  toggle,
  data,
  handleRefreshPaymentMethods,
}: {
  open: boolean;
  toggle: () => void;
  data: TpayAccountPaymentMethod | null;
  handleRefreshPaymentMethods: () => void;
}): ReactElement => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const makePrimaryPaymentMethod = async () => {
    try {
      await handleMakePaymentMethodPrimary(data!.id);
      handleRefreshPaymentMethods();
    } catch (e) {
      setError('There was a problem making this payment method primary.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <SideBar title={'LoadPay Account'} open={open} toggle={toggle}>
      <SideBarBody>
        {data?.is_primary && (
          <div className="mb-6">
            <Chip label="Primary" color="success" />
          </div>
        )}
        {error && <ErrorNotification error={error} />}
        <Spacer />
        {loading ? (
          <div className="mt-3 text-sm">
            <FontAwesomeIcon icon={faSpinnerThird} className="h-6 animate-spin" />
          </div>
        ) : (
          !data?.is_primary && (
            <Button onClick={makePrimaryPaymentMethod} color="secondary" size="medium">
              Make Primary Payment Method
            </Button>
          )
        )}
      </SideBarBody>
    </SideBar>
  );
};
