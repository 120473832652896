import Constants from '@app/utils/constants';
import { useEffect, useState } from 'react';

interface WindowWidth {
  width: number;
  isSmallScreen: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

export default function useWindowWidth(): WindowWidth {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return {
    width,
    isSmallScreen: width <= Constants.SMALL_SCREEN_BREAKPOINT,
    isMobile: width <= Constants.MOBILE_BREAKPOINT,
    isTablet: width > Constants.MOBILE_BREAKPOINT && width <= Constants.TABLET_BREAKPOINT,
    isDesktop: width > Constants.TABLET_BREAKPOINT,
  };
}
