import { ReactElement } from 'react';

export default function StickyButtonWrapper({
  children,
}: {
  children: ReactElement;
}): ReactElement {
  return (
    <div className="fixed bottom-0 left-0 z-10 mt-20 flex w-full border-t border-gray-100 bg-white p-4 md:hidden">
      {children}
    </div>
  );
}
