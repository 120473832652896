import { BaseProvider, LightTheme } from 'baseui';

import { Button, SIZE } from 'baseui/button';
import { Heading, HeadingLevel } from 'baseui/heading';
import { ParagraphMedium } from 'baseui/typography';
import { ReactElement } from 'react';

import { useNavigate } from 'react-router-dom';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
const engine = new Styletron();

const UberFreightConversionSorryPage = (): ReactElement => {
  const navigate = useNavigate();
  const onClickGoToDashboard = () => {
    navigate('/');
  };

  const styleString = `
    .mTFF {
      font-family: MoveText !important;
    }
  `;

  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={LightTheme}>
        <style>{styleString}</style>

        <div
          style={{ paddingLeft: '24px', paddingRight: '24px', fontFamily: 'MoveText !important' }}
        >
          <HeadingLevel>
            <Heading className="mTFF" style={{ marginBottom: '32px' }}>
              Unfortunately, you are not eligible for the Uber Freight Carrier Card
            </Heading>
          </HeadingLevel>
          <ParagraphMedium className="mTFF" style={{ marginBottom: '32px' }}>
            Thank you for your interest. Our partners at AtoB cannot approve your business for the
            Uber Freight Carrier Card at this time.
          </ParagraphMedium>
          <Button
            onClick={onClickGoToDashboard}
            size={SIZE.large}
            className="mTFF"
            style={{ width: '100%', borderRadius: '0px' }}
          >
            Close
          </Button>
        </div>
      </BaseProvider>
    </StyletronProvider>
  );
};

export default UberFreightConversionSorryPage;
