import { DriverData } from '@app/@types/driver.types';
import { DriverSidebarType } from '@app/components/Sidebars/DriverSidebar/DriverSidebar';
import EditDriverSidebar from '@app/components/Sidebars/DriverSidebar/EditDriverSidebar';
import PreviewDriverSidebar from '@app/components/Sidebars/DriverSidebar/PreviewDriverSidebar';
import { ReactElement } from 'react';

interface DriverSidebarContentProps {
  sidebarType: DriverSidebarType;
  driver: DriverData;
  setOpen: (val: boolean) => void;
  updateSidebarType: (sidebarType: DriverSidebarType) => void;
  enableStreamlinedDriverAppOnboarding: boolean;
  onDriversUpdate: (updatedDriver: DriverData) => void;
}

const DriverSidebarContent = ({
  sidebarType,
  driver,
  setOpen,
  updateSidebarType,
  enableStreamlinedDriverAppOnboarding,
  onDriversUpdate,
}: DriverSidebarContentProps): ReactElement => {
  switch (sidebarType) {
    case DriverSidebarType.READ:
      return (
        <PreviewDriverSidebar
          driver={driver}
          onDriverUpdated={onDriversUpdate}
          setOpen={setOpen}
          onEditClick={() => updateSidebarType(DriverSidebarType.EDIT)}
          enableStreamlinedDriverAppOnboarding={enableStreamlinedDriverAppOnboarding}
        />
      );
    case DriverSidebarType.EDIT:
      return (
        <EditDriverSidebar
          driver={driver}
          setOpen={() => {
            updateSidebarType(DriverSidebarType.READ);
          }}
          onDriversUpdate={onDriversUpdate}
          enableStreamlinedDriverAppOnboarding={enableStreamlinedDriverAppOnboarding}
        />
      );
    default:
      return null as never;
  }
};

export default DriverSidebarContent;
