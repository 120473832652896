import { ELDProvider } from '@app/@types/eld-connect.types';
import { useDisconnectTelematics } from '@app/hooks/query/useConnectTelematics';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { ReactElement } from 'react';
import ConnectionModalFooter from './ConnectionModalFooter';

interface TelematicsDisconnectELDModalProps {
  provider: ELDProvider;
  closeModal: () => void;
}

const TelematicsDisconnectELDModal = ({
  provider,
  closeModal,
}: TelematicsDisconnectELDModalProps): ReactElement => {
  const { disconnectTelematics, isDisconnecting } = useDisconnectTelematics(provider);

  const clickHandler = async () => {
    await disconnectTelematics();
    closeModal();
    window.location.href = window.location.pathname;
    return;
  };

  return (
    <Dialog open={true} onClose={closeModal}>
      <DialogTitle>{`Disconnect from ${provider.displayName}`}</DialogTitle>
      <DialogContent>Are you sure you want to disconnect from this provider?</DialogContent>
      <ConnectionModalFooter
        closeModal={closeModal}
        isLoading={isDisconnecting}
        clickHandler={clickHandler}
      />
    </Dialog>
  );
};

export default TelematicsDisconnectELDModal;
