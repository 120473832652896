import { CustomerData, CustomerFeatureFlag } from '@app/@types/customer.types';
import { UserData } from '@app/@types/users.types';
import { useCustomerQuery } from '@app/hooks/query/useCustomerQuery';
import { useUserQuery } from '@app/hooks/query/useUserQuery';
import featureFlagIsEnabled from '@app/utils/feature-flags';
import i18n from 'i18next';
import { createContext, useCallback, useMemo } from 'react';

export type CustomerContextType = {
  customer: CustomerData;
  user: UserData;

  featureFlags: CustomerFeatureFlag[];
  isFeatureFlagEnabled: (featureId: string) => boolean;

  isLoading: boolean;

  isUserLoading: boolean;

  customerError?: Error;
  userError?: unknown;
};

const CustomerContext = createContext<CustomerContextType>({
  customer: {} as CustomerData,
  featureFlags: [],

  user: {} as UserData,
  isUserLoading: false,

  isFeatureFlagEnabled: (_) => {
    return false;
  },

  isLoading: true,
});

export default CustomerContext;

export function useCustomerContextState(): CustomerContextType {
  const {
    data: customerData,
    isLoading: isCustomerLoading,
    error: customerError,
  } = useCustomerQuery();

  const {
    data: userData,
    error: userError,
    isLoading: isUserLoading,
  } = useUserQuery(isCustomerLoading);

  const customerRecord = useMemo(() => {
    if (!customerData || !customerData?.data) {
      return {} as CustomerData;
    }

    const customerRecord = {
      ...customerData.data,
    };
    if (customerRecord.treasury == null) {
      customerRecord.treasury = {
        status: 'not_enrolled',
        has_inbound_transfer_completed: false,
        minimum_deposit_amount: {
          cents: 2500,
          currency_iso: 'USD',
        },
      };
    }

    // set translation context
    if (customerRecord.channel_partner) {
      // Extract the base language without the channel partner extension
      const baseLanguage = i18n.language.split('-x-')[0];
      const newLanguage = `${baseLanguage}-x-${customerRecord.channel_partner.name.replace(
        /_/,
        '-',
      )}`;

      if (i18n.language !== newLanguage) {
        i18n.changeLanguage(newLanguage);
      }
    }
    return customerRecord;
  }, [customerData]);

  const userRecord = useMemo(() => {
    if (!userData) {
      return {} as UserData;
    }
    return userData.data;
  }, [userData]);

  const isFeatureFlagEnabled = useCallback(
    (desiredFlagId: string): boolean => {
      return featureFlagIsEnabled(
        desiredFlagId,
        customerData?.data?.enabled_exposed_features ?? [],
      );
    },
    [customerData],
  );

  const context = useMemo(
    () => ({
      customer: customerRecord,
      user: userRecord,
      featureFlags: customerRecord?.enabled_exposed_features ?? [],
      isFeatureFlagEnabled,
      isLoading: isCustomerLoading,
      isUserLoading: isUserLoading,
      customerError,
      userError,
    }),
    [
      customerError,
      customerRecord,
      isCustomerLoading,
      isFeatureFlagEnabled,
      isUserLoading,
      userError,
      userRecord,
    ],
  );

  return context;
}
