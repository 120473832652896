import classNames from 'classnames';
import { ReactElement, ReactNode } from 'react';
import { isComplete, Complete } from './StepViews/CompleteStep';
import { isCurrent, Current } from './StepViews/CurrentStep';
import { isCustom, Custom } from './StepViews/CustomStep';
import { isPlaceholder, Placeholder } from './StepViews/PlaceholderStep';
import { isUpcoming, Upcoming } from './StepViews/UpcomingStep';
import { Step } from './steps.types';

const ArrowSeparator = (): ReactElement => (
  <>
    <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
      <svg
        className="h-full w-full text-gray-300"
        viewBox="0 0 22 80"
        fill="none"
        preserveAspectRatio="none"
      >
        <path
          d="M0 -2L20 40L0 82"
          vectorEffect="non-scaling-stroke"
          stroke="currentcolor"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  </>
);

export default function Steps({
  steps,
  hideArrowSeparator,
  title,
  children,
}: {
  steps: Step[];
  hideArrowSeparator?: boolean;
  title?: string;
  children?: ReactNode;
}): ReactElement | null {
  if (steps.length === 0) {
    return null;
  }

  return (
    <nav className="rounded-md border border-gray-300" aria-label="Progress">
      {title && <h2 className="mx-6 mt-2 text-lg font-bold text-gray-700">{title}</h2>}
      <ol className="divide-y divide-gray-300 md:flex md:divide-y-0">
        {steps.map((step, i) => (
          <li
            key={step.id}
            className={classNames('relative md:flex md:flex-1', {
              'hidden md:visible': isPlaceholder(step),
            })}
          >
            {isUpcoming(step) && <Upcoming step={step} />}
            {isCurrent(step) && <Current step={step} />}
            {isComplete(step) && <Complete step={step} />}
            {isCustom(step) && <Custom step={step} />}
            {isPlaceholder(step) && <Placeholder step={step} />}

            {!hideArrowSeparator && i !== steps.length - 1 && <ArrowSeparator />}
          </li>
        ))}
      </ol>
      {children && <div className="w-full border-t border-t-gray-300">{children}</div>}
    </nav>
  );
}
