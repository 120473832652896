import React, { ReactElement, Fragment, ReactNode } from 'react';

export default function FilterGroup({ children }: { children: ReactNode }): ReactElement {
  const childrenToRender = nonEmptyChildren(children);

  return (
    <div className="block">
      <div className="mx-2 flex items-center space-x-2 pt-1" aria-label="Filters">
        <div className={`flex items-center space-x-2`}>
          {childrenToRender.map((child, index) => {
            return <Fragment key={index}>{child}</Fragment>;
          })}
        </div>
      </div>
    </div>
  );
}

const nonEmptyChildren = (children: React.ReactNode) => {
  return React.Children.toArray(children).filter((child) => child !== null);
};
