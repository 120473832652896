import { EndpointResponse } from '@app/@types/api.types';
import { FetcherKey, apiPostFetcher } from '@app/utils/data/fetchers';
import { AxiosError } from 'axios';
import useSWRMutation, { SWRMutationResponse } from 'swr/mutation';

export type ConfirmCreditEnablementMutationParam = Record<string, never>;

export type ConfirmCreditEnablementMutationResponse = {
  result: string;
};

export function useConfirmCreditEnablementNetNewMutation(): SWRMutationResponse<
  EndpointResponse<ConfirmCreditEnablementMutationResponse>,
  AxiosError,
  FetcherKey,
  ConfirmCreditEnablementMutationParam
> {
  return useSWRMutation<
    EndpointResponse<ConfirmCreditEnablementMutationResponse>,
    AxiosError,
    FetcherKey,
    ConfirmCreditEnablementMutationParam
  >(
    {
      url: '/customer/enroll_credit_enablement_net_new',
    },
    apiPostFetcher,
  );
}
