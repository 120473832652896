import { EndpointResponse, PaginatedEndpointResponse } from '@app/@types/api.types';
import axios from 'axios';
import { deserialize } from 'deserialize-json-api';

type ParametrizedFetcherKey = {
  url: string;
  params?: Record<string, string>;
};

export type FetcherKey = ParametrizedFetcherKey | string;

/**
 * `arg` is what SWR mutation fetcher uses internally, so we have to keep it like this.
 */
export type MutationArg<T> = { arg: T };

type ApiResponseType<T> = EndpointResponse<T> | PaginatedEndpointResponse<T>;

export const normalizeFetcherKey = (fetcherKey: FetcherKey): ParametrizedFetcherKey => {
  return typeof fetcherKey === 'string' ? { url: fetcherKey } : fetcherKey;
};

/**
 * SWR fetcher to be used for bare bone http GET requests.
 */
export const getFetcher = async <T>(fetcherKey: FetcherKey): Promise<T> => {
  const { url, params } = normalizeFetcherKey(fetcherKey);
  const res = await axios.get<T>(url, { params });
  return res.data;
};

/**
 * SWR fetcher to be used for bare bone http GET requests and a 'blob' response type.
 */
export const getBlobFetcher = async <T>(fetcherKey: FetcherKey): Promise<T> => {
  const { url, params } = normalizeFetcherKey(fetcherKey);
  const res = await axios.get<T>(url, { responseType: 'blob', params });
  return res.data;
};

/**
 * SWR fetcher to be used for bare bone http POST requests with no body
 */
export const noArgPostFetcher = async <T>(fetcherKey: FetcherKey): Promise<T> => {
  const { url, params } = normalizeFetcherKey(fetcherKey);
  const res = await axios.post<T>(url, { ...params });
  return res.data;
};

/**
 * SWR fetcher to be used for bare bone http POST requests.
 */
export const postFetcher = async <T>(
  fetcherKey: FetcherKey,
  { arg }: MutationArg<unknown>,
): Promise<T> => {
  const { url, params } = normalizeFetcherKey(fetcherKey);
  const res = await axios.post<T>(url, arg, { params });
  return res.data;
};

/**
 * SWR fetcher to be used for bare bone http PUT requests.
 */
export const putFetcher = async <T>(
  fetcherKey: FetcherKey,
  { arg }: MutationArg<unknown>,
): Promise<T> => {
  const { url, params } = normalizeFetcherKey(fetcherKey);
  const res = await axios.put<T>(url, arg, { params });
  return res.data;
};

/**
 * SWR fetcher to be used for http GET requests with JSON API deserialization.
 *
 * To get the returned object, access the `data` field of the returned {@link EndpointResponse}.
 * If returning a paginated response, access the `data` field of the returned {@link PaginatedEndpointResponse} to get
 * the fetched collection. Pagination metadata is available in the `meta` field of the returned
 * {@link PaginatedEndpointResponse}.
 *
 * Note that this `data` fields of {@link EndpointResponse} and {@link PaginatedEndpointResponse} are different than
 * the `data` field returned by `useSWR`, which is of either {@link EndpointResponse} type that is returned by our
 * JSON API backend.
 *
 * Single object usage example:
 *
 * ```
 * const {data: objectResponse, error, isLoading} =
 *  useSWR<EndpointResponse<SomeObject>>({url: '/object'}, apiGetFetcher);
 * const myObject = objectResponse && objectResponse.data;
 * ```
 * Paginated response usage example:
 * ```
 * const {data: collectionResponse, error, isLoading} =
 *  useSWR<PaginatedEndpointResponse<SomeObject>>({url: '/collection'}, apiGetFetcher);
 * const myCollection: MyObject[] = collectionResponse && collectionResponse.data;
 * const {pagination} = collectionResponse && collectionResponse.meta;
 * ```
 *
 * Error handling is done via a Promise error, and should be dealt with via the `error` field returned by `useSWR`.
 *
 * @param T `EndpointResponse<T>` or `PaginatedEndpointResponse<T>`, where `T` is the object data type.
 * @returns `{data, meta?},` where `data` will have the fetched JSON API object or collection, and `meta` will have
 * pagination information
 */
export const apiGetFetcher = async <R extends ApiResponseType<unknown>>(
  fetcherKey: FetcherKey,
): Promise<R> => {
  const { url, params } = normalizeFetcherKey(fetcherKey);
  const res = await axios.get<R>(url, { params });
  const des = deserialize(res.data) as R;
  return des;
};

/**
 * SWR fetcher to be used for http POST requests with JSON API deserialization.
 *
 * To get the returned object, access the `data` field of the returned {@link EndpointResponse}.
 * If returning a paginated response, access the `data` field of the returned {@link PaginatedEndpointResponse} to get
 * the fetched collection. Pagination metadata is available in the `meta` field of the returned
 * {@link PaginatedEndpointResponse}.
 *
 * Note that this `data` fields of {@link EndpointResponse} and {@link PaginatedEndpointResponse} are different than
 * the `data` field returned by `useSWRMutation`, which is of either {@link EndpointResponse} type that is returned by
 * our JSON API backend.
 *
 * Single object usage example:
 *
 * ```
 * const {data: objectResponse, error, isLoading, trigger} =
 *  useSWRMutation<EndpointResponse<SomeObject>>({url: '/object'}, apiPostFetcher, { populateCache: true });
 * trigger(postData);
 * const myObject = objectResponse && objectResponse.data;
 * ```
 * Paginated response usage example:
 * ```
 * const {data: collectionResponse, error, isLoading, trigger()} =
 *  useSWR<PaginatedEndpointResponse<SomeObject>>({url: '/collection'}, apiPostFetcher, { populateCache: true });
 * trigger(postData);
 * const myCollection: MyObject[] = collectionResponse && collectionResponse.data;
 * const {pagination} = collectionResponse && collectionResponse.meta;
 * ```
 *
 * Error handling is done via a Promise error, and should be dealt with via the `error` field returned by `useSWR`.
 *
 * @param T `EndpointResponse<T>` or `PaginatedEndpointResponse<T>`, where `T` is the object data type.
 * @see {@link https://swr.vercel.app/docs/mutation#useswrmutation}
 * @returns `{data, meta?},` where `data` will have the fetched JSON API object or collection, and `meta` will have
 * pagination information
 */
export const apiPostFetcher = async <T>(
  fetcherKey: FetcherKey,
  { arg }: MutationArg<unknown>,
): Promise<T> => {
  const { url, params } = normalizeFetcherKey(fetcherKey);
  const res = await axios.post<EndpointResponse<T>>(url, arg, { params });
  return deserialize(res.data) as T;
};

export const apiPostHeadersOnly = async <T>(
  fetcherKey: FetcherKey,
  { arg }: MutationArg<unknown>,
): Promise<T> => {
  const { url, params } = normalizeFetcherKey(fetcherKey);
  const res = await axios.post<EndpointResponse<T>>(url, arg, { params });
  return res.headers as T;
};

/**
 * SWR fetcher to be used for http PUT requests with JSON API deserialization.
 *
 * To get the returned object, access the `data` field of the returned {@link EndpointResponse}.
 * If returning a paginated response, access the `data` field of the returned {@link PaginatedEndpointResponse} to get
 * the fetched collection. Pagination metadata is available in the `meta` field of the returned
 * {@link PaginatedEndpointResponse}.
 *
 * Note that this `data` fields of {@link EndpointResponse} and {@link PaginatedEndpointResponse} are different than
 * the `data` field returned by `useSWRMutation`, which is of either {@link EndpointResponse} type that is returned by
 * our JSON API backend.
 *
 * Single object usage example:
 *
 * ```
 * const {data: objectResponse, error, isLoading, trigger} =
 *  useSWRMutation<EndpointResponse<SomeObject>>({url: '/object'}, apiPutFetcher, { populateCache: true });
 * trigger(putData);
 * const myObject = objectResponse && objectResponse.data;
 * ```
 * Paginated response usage example:
 * ```
 * const {data: collectionResponse, error, isLoading, trigger()} =
 *  useSWR<PaginatedEndpointResponse<SomeObject>>({url: '/collection'}, apiPutFetcher, { populateCache: true });
 * trigger(putData);
 * const myCollection: MyObject[] = collectionResponse && collectionResponse.data;
 * const {pagination} = collectionResponse && collectionResponse.meta;
 * ```
 *
 * Error handling is done via a Promise error, and should be dealt with via the `error` field returned by `useSWR`.
 *
 * @param T `EndpointResponse<T>` or `PaginatedEndpointResponse<T>`, where `T` is the object data type.
 * @see {@link https://swr.vercel.app/docs/mutation#useswrmutation}
 * @returns `{data, meta?},` where `data` will have the fetched JSON API object or collection, and `meta` will have
 * pagination information
 */
export const apiPutFetcher = async <T>(
  fetcherKey: FetcherKey,
  { arg }: MutationArg<unknown>,
): Promise<T> => {
  const { url, params } = normalizeFetcherKey(fetcherKey);
  const res = await axios.put<EndpointResponse<T>>(url, arg, { params });
  return deserialize(res.data) as T;
};

/**
 * SWR fetcher to be used for http DELETE requests with JSON API deserialization.
 *
 * To get the returned object, access the `data` field of the returned {@link EndpointResponse}.
 * If returning a paginated response, access the `data` field of the returned {@link PaginatedEndpointResponse} to get
 * the fetched collection. Pagination metadata is available in the `meta` field of the returned
 * {@link PaginatedEndpointResponse}.
 *
 * Note that this `data` fields of {@link EndpointResponse} and {@link PaginatedEndpointResponse} are different than
 * the `data` field returned by `useSWRMutation`, which is of either {@link EndpointResponse} type that is returned by
 * our JSON API backend.
 *
 * Single object usage example:
 *
 * ```
 * const {data: objectResponse, error, isLoading, trigger} =
 *  useSWRMutation<EndpointResponse<SomeObject>>({url: '/object'}, apiPutFetcher, { populateCache: true });
 * trigger();
 * // DELETE normally returns the deleted object
 * const myDeletedObject = objectResponse && objectResponse.data;
 * ```
 * Paginated response usage example:
 * ```
 * const {data: collectionResponse, error, isLoading, trigger()} =
 *  useSWR<PaginatedEndpointResponse<SomeObject>>({url: '/collection'}, apiPutFetcher, { populateCache: true });
 * trigger();
 * const myDeletedCollection: MyObject[] = collectionResponse && collectionResponse.data;
 * const {pagination} = collectionResponse && collectionResponse.meta;
 * ```
 *
 * Error handling is done via a Promise error, and should be dealt with via the `error` field returned by `useSWR`.
 *
 * @param T `EndpointResponse<T>` or `PaginatedEndpointResponse<T>`, where `T` is the object data type.
 * @see {@link https://swr.vercel.app/docs/mutation#useswrmutation}
 * @returns `{data, meta?},` where `data` will have the fetched JSON API object or collection, and `meta` will have
 * pagination information
 */
export const apiDeleteFetcher = async <T>(fetcherKey: FetcherKey): Promise<T> => {
  const { url } = normalizeFetcherKey(fetcherKey);
  const res = await axios.delete<EndpointResponse<T>>(url);
  return deserialize(res.data) as T;
};

/// ==============================================================================================================
/// Functions below this shold not be used and shoul be deprecated
/// ==============================================================================================================

/**
 * @deprecated do not use - callsites should handle error from useSWR
 */
export const sendRequest = async <T>(
  url: string,
  { arg }: { arg: Record<string, string> },
): Promise<T> => {
  const response = await axios.post<T>(url, arg);
  return deserialize(response.data);
};

/**
 * @deprecated do not use - callsites should handle error from useSWR
 */
export const safeSendRequest = <T>(
  catchError: (error: Error) => void,
): ((url: string, { arg }: { arg: Record<string, string> }) => Promise<T | Error>) => {
  return async (url, { arg }) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    catchError(undefined);
    try {
      return await sendRequest<T>(url, { arg });
    } catch (error) {
      const result = axios.isAxiosError(error)
        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          new Error(error.response.data.error)
        : (error as Error);
      catchError(result);
      return Promise.resolve(result);
    }
  };
};

/**
 * @deprecated do not use - callsites should handle error from useSWR
 */
export const sendPutRequest = async <T>(
  url: string,
  { arg }: { arg: Record<string, string> },
): Promise<T> => {
  const response = await axios.put<T>(url, arg);
  return deserialize(response.data);
};

/**
 * @deprecated do not use - callsites should handle error from useSWR
 */
export const safeSendPutRequest = <T>(
  catchError: (error: Error) => void,
): ((url: string, { arg }: { arg: Record<string, string> }) => Promise<T>) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return async (url, { arg }) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    catchError(undefined);
    try {
      return await sendPutRequest<T>(url, { arg });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        catchError(new Error(error.response.data.error));
      }
      catchError(error as Error);
      return undefined;
    }
  };
};
