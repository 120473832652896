import { CustomerData } from '@app/@types/customer.types';
import { UpcomingPayment } from '@app/@types/fuel-card-account.types';
import {
  FormatCurrency,
  FormatCurrencyMultiplyBy,
} from '@atob-developers/shared/src/utils/formatters';
import { getCurrencyFromCents } from '@atob-developers/shared/src/utils/formatters/CurrencyFormat';
import { isNil } from 'lodash-es';

export type AmountDueProps = {
  upcomingPayment: UpcomingPayment | undefined;
  customer: CustomerData;
  useArrearsDue?: boolean;
};

function parseArrearsDueString(arrearsDue: string): number {
  const [beforeDecimal, afterDecimal] = arrearsDue.split('.');
  return Number(beforeDecimal + afterDecimal);
}

export default function amountDueConverter({
  upcomingPayment,
  customer,
  useArrearsDue = false,
}: AmountDueProps): {
  hasAmountDueCents: boolean;
  amountDueCents: number | null;
  amountDueFormatted: string | null;
  isNegativeAmountDue: boolean;
} {
  let accountBalanceCents = null;
  let accountBalanceFormatted = null;

  if (useArrearsDue && Number(customer?.arrears_due) > 0) {
    accountBalanceCents = parseArrearsDueString(customer?.arrears_due);
    accountBalanceFormatted = getCurrencyFromCents(accountBalanceCents);
  } else if (customer?.account_balance && !isNil(customer.account_balance.cents)) {
    accountBalanceCents = customer.account_balance.cents * -1;
    accountBalanceFormatted = FormatCurrencyMultiplyBy({
      value: customer.account_balance.cents,
      multiplyValue: -1,
      options: {
        fromCents: true,
        precision: 2,
        separator: '',
      },
    });
  }

  let amountDueCents = null;
  let amountDueFormatted = null;

  if (upcomingPayment && upcomingPayment.balance) {
    amountDueCents = upcomingPayment.balance.cents;
    amountDueFormatted = FormatCurrency({
      value: upcomingPayment.balance.cents,
    });
  } else if (!isNil(accountBalanceCents)) {
    amountDueCents = accountBalanceCents;
    amountDueFormatted = accountBalanceFormatted;
  }

  const hasAmountDueCents = amountDueCents !== null;
  const isNegativeAmountDue = !!amountDueCents && amountDueCents <= 0;

  return { hasAmountDueCents, amountDueCents, amountDueFormatted, isNegativeAmountDue };
}
