/* eslint-disable no-use-before-define */
import TelematicsMapContext from '@app/contexts/telematicsMapContext';
import React, { useContext, useState } from 'react';
import Dropdown from './Dropdown';
import TruckDetailContainer from './TruckDetailContainer';
import { TruckSelectorContainerProps } from './TruckSelectorContainer';

type TruckSelectorContainerMobileProps = TruckSelectorContainerProps;

const TruckSelectorContainerMobile: React.FC<TruckSelectorContainerMobileProps> = ({
  selectTruck,
  setError,
}) => {
  const { trucks, activeTruck, setActiveTruck } = useContext(TelematicsMapContext);
  const [showDetail, setShowDetails] = useState<boolean>(activeTruck !== null);
  const truckDetailsWrapperClass = showDetail
    ? 'truck-detail-container-wrapper-show'
    : 'truck-detail-container-wrapper-hide';

  const clearSelectionHandler = () => {
    setShowDetails(false);
    setActiveTruck(null);
  };

  const truckSelectorContainerStyles = {
    top: 'unset',
    bottom: '0',
  };

  return (
    <div style={truckSelectorContainerStyles} className="truck-selector-container-mobile">
      {activeTruck !== null && (
        <div className="truck-selector-container-button-wrapper">
          <button
            type="button"
            className="button is-primary truck-selector-container-button"
            onClick={() => setShowDetails(!showDetail)}
          >
            {showDetail ? 'Hide Details' : 'Show Details'}
          </button>
          <button
            type="button"
            className="button is-danger truck-selector-container-button"
            onClick={() => clearSelectionHandler()}
          >
            Clear Selection
          </button>
        </div>
      )}
      <Dropdown
        classes={showDetail ? 'truck-selector-container-dropdown' : ''}
        selectTruck={selectTruck}
        trucks={trucks}
        selectedTruck={activeTruck}
        setError={setError}
      />
      <div className={truckDetailsWrapperClass}>{showDetail && <TruckDetailContainer />}</div>
    </div>
  );
};

export default TruckSelectorContainerMobile;
