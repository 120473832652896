import { StripeError } from '@stripe/stripe-js';

const CARD_ERROR = 'card_error';
const VALIDATION_ERROR = 'validation_error';
const INSUFFICIENT_FUNDS = 'insufficient_funds';
const DO_NOT_HONOR = 'do_not_honor';
const REQUIRES_PAYMENT_METHOD = 'requires_payment_method';

const checkForCardError = (
  setError: (error: string) => void,
  paymentError: StripeError | undefined,
): boolean => {
  if (paymentError?.type === CARD_ERROR || paymentError?.type === VALIDATION_ERROR) {
    if (paymentError?.decline_code === INSUFFICIENT_FUNDS) {
      setError('Your card has insufficient funds. Please try another card.');
      return true;
    }

    if (paymentError?.decline_code === DO_NOT_HONOR) {
      setError(
        'Your card has been declined; please try another card. For more information about this transaction, please reach out to your bank.',
      );
      return true;
    }

    const paymentIntent = paymentError?.payment_intent;
    const status = paymentIntent?.status;
    if (status === REQUIRES_PAYMENT_METHOD) {
      setError(
        `There was an error processing your payment, please verify the debit card and try again. Note: credit cards and prepaid cards are not accepted.`,
      );

      return true;
    }
  }

  return false;
};

export default checkForCardError;
