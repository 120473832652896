import { ChargeEventData } from '@app/@types/charge_events.types';
import { panMapToBounds } from '@app/utils/Telematics/TelematicsMapsUtils';
import { getEnvironment } from '@app/utils/environment';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import GoogleMap from 'google-maps-react-markers';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import {
  MerchantAndVehicleMarker,
  MerchantMarker,
  VehicleMarker,
} from '../../components/Maps/MapMarkers';
import { TelematicsStatus } from './TelematicsStatus';
import { relativeTimestamp } from './utils';

const TOOLTIP_TEXT =
  'Vehicle location at the time of the transaction is compared to merchant location to verify legitimacy of the transaction. ' +
  'If the vehicle is not within a reasonable distance of the merchant (factoring in speed and direction of travel), ' +
  'the transaction will be flagged as suspicious.';

const MapUnavailable = ({ chargeEvent }: { chargeEvent: ChargeEventData }): ReactElement => {
  return (
    <div className="flex flex-col gap-2 rounded-lg bg-white p-6 md:h-96">
      <div className="flex flex-row gap-4">
        <div className="flex flex-col gap-4">
          <div className="flex flex-row">
            <div className="mr-2 font-semibold">Vehicle Location Check</div>
            <div className="items-end text-gray-300">
              <Tooltip title={TOOLTIP_TEXT}>
                <div>
                  <FontAwesomeIcon icon={faInfoCircle} />
                </div>
              </Tooltip>
            </div>
          </div>
          <div className="flex flex-row gap-4">
            <div className="text-sm">
              <span>No data available</span>
            </div>
          </div>
        </div>

        <div className="flex flex-1 items-start justify-end">
          <TelematicsStatus chargeEvent={chargeEvent} unavailable />
        </div>
      </div>
      <div className="relative mt-4 hidden h-64 items-end justify-center overflow-hidden rounded-lg bg-slate-100 align-bottom md:flex">
        <img src="/images/charge_event_map_unavailable.svg" alt="map unavailable" />
        <div className="absolute bottom-0 top-0 mx-auto my-auto h-10 rounded border border-gray-200 bg-white p-2 px-16">
          No data available
        </div>
      </div>
    </div>
  );
};

const ChargeEventVehicleLocation = ({
  chargeEvent,
}: {
  chargeEvent: ChargeEventData;
}): ReactElement => {
  const merchantPos = useMemo(
    () =>
      chargeEvent?.merchant_location && {
        lat: chargeEvent.merchant_location?.latitude,
        lng: chargeEvent.merchant_location?.longitude,
      },
    [chargeEvent],
  );

  const vehiclePos = useMemo(
    () =>
      chargeEvent?.vehicle_location && {
        lat: chargeEvent.vehicle_location.latitude,
        lng: chargeEvent.vehicle_location.longitude,
      },
    [chargeEvent],
  );

  const { VITE_GOOGLE_API_KEY, VITE_MAP_ID_FRAUD_HIGHLIGHT } = getEnvironment();

  const [googleMap, setGoogleMap] = useState<google.maps.Map | null>(null);
  const [mapsApi, setMapsApi] = useState<typeof google.maps | null>(null);

  useEffect(() => {
    if (chargeEvent && googleMap && mapsApi && !!merchantPos && !!vehiclePos) {
      panMapToBounds(googleMap, mapsApi, [merchantPos, vehiclePos].filter(Boolean));
    }
  }, [chargeEvent, googleMap, mapsApi, merchantPos, vehiclePos]);

  if (!chargeEvent || !chargeEvent.merchant_location || !merchantPos) {
    return <MapUnavailable chargeEvent={chargeEvent} />;
  }

  const telematicsDataAvailable = chargeEvent?.vehicle_location;

  const suspicious = telematicsDataAvailable && chargeEvent.vehicle_location?.suspicious_location;

  const distance = chargeEvent?.vehicle_location?.distance_from_merchant_miles;
  const distanceAvailable = distance !== null && distance !== undefined;
  const distanceDisplay =
    distanceAvailable && (distance < 0.1 ? 'Less than 0.1 miles' : `${distance.toFixed(1)} miles`);

  const resultDisplay = telematicsDataAvailable
    ? `${distanceDisplay} from the merchant, ${
        chargeEvent.vehicle_location?.captured_at &&
        relativeTimestamp(chargeEvent.vehicle_location.captured_at, chargeEvent.authorized_at)
      }`
    : 'No vehicle data available';

  const markers = [];
  if (distanceAvailable && distance < 0.1) {
    markers.push(<MerchantAndVehicleMarker key="0" lat={merchantPos.lat} lng={merchantPos.lng} />);
  } else {
    markers.push(<MerchantMarker key="1" lat={merchantPos.lat} lng={merchantPos.lng} />);
    if (vehiclePos) {
      markers.push(<VehicleMarker key="2" lat={vehiclePos.lat} lng={vehiclePos.lng} />);
    }
  }

  return (
    <div className="flex flex-col gap-2 rounded-lg bg-white p-6 md:h-96">
      <div className="flex flex-row gap-4">
        <div className="flex flex-col gap-4">
          <div className="flex flex-row">
            <div className="mr-2 font-semibold">Vehicle Location Check</div>
            <div className="items-end text-gray-300">
              <Tooltip title={TOOLTIP_TEXT}>
                <div>
                  <FontAwesomeIcon icon={faInfoCircle} />
                </div>
              </Tooltip>
            </div>
          </div>
          <div className="flex flex-row gap-4">
            <div className="text-[12px]">
              <span className="font-semibold">Result: </span>
              {resultDisplay}
            </div>
          </div>
        </div>

        <div className="flex flex-1 items-start justify-end">
          {telematicsDataAvailable ? (
            suspicious ? (
              <TelematicsStatus suspicious />
            ) : (
              <TelematicsStatus passed />
            )
          ) : (
            <TelematicsStatus chargeEvent={chargeEvent} unavailable />
          )}
        </div>
      </div>
      <div className="mt-4 hidden h-64 min-w-fit overflow-hidden rounded-lg bg-slate-100 md:block">
        <GoogleMap
          apiKey={String(VITE_GOOGLE_API_KEY)}
          defaultCenter={merchantPos}
          defaultZoom={1}
          onGoogleApiLoaded={({
            map,
            maps,
          }: {
            map: google.maps.Map;
            maps: typeof google.maps;
          }) => {
            setGoogleMap(map);
            setMapsApi(maps);
          }}
          options={{
            zoomControlOptions: {
              position: mapsApi?.ControlPosition.RIGHT_CENTER || 0,
            },
            mapId: String(VITE_MAP_ID_FRAUD_HIGHLIGHT),
            disableDefaultUI: true,
            scaleControl: true,
            zoomControl: true,
            fullscreenControl: true,
            clickableIcons: false,
          }}
        >
          {markers}
        </GoogleMap>
      </div>
    </div>
  );
};

export default ChargeEventVehicleLocation;
