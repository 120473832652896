import { SortBy } from '@app/hooks/paging/types';
import { useCallback, useReducer } from 'react';
import {
  UseSortReducerReturn,
  SortReducerAction,
  SET_SORT,
  TOGGLE_SORT,
} from './useSortReducer.types';

function sortReducer(state: SortBy, action: SortReducerAction): SortBy {
  if (!action.sort?.field) {
    throw new Error('No field provided to action.');
  }

  const directions: Array<'asc' | 'desc'> = ['asc', 'desc'];
  const indexOf = directions.indexOf(state?.order ?? 'asc');

  switch (action.type) {
    case TOGGLE_SORT:
      // If we're not sorting by anything, or the sort field is different, start sorting.
      if (!state.field || action.sort.field !== state.field) {
        return { field: action.sort.field, order: directions[0] };
        // If we're already sorting by this field, toggle the sort direction
      } else if (action.sort.field === state.field) {
        // If we're done with the directions, return to null.
        if (indexOf >= directions.length - 1) {
          return { field: null, order: null };
        }

        return { field: action.sort.field, order: directions[indexOf + 1] };
      }

      break;
    case SET_SORT: {
      return { ...action.sort };
    }
  }

  return state;
}

export const useSortReducer = (initialState?: SortBy): UseSortReducerReturn => {
  // Intentionally kept like this to provide defaults for null values.
  initialState ||= { field: null, order: null };

  const [state, dispatch] = useReducer(sortReducer, initialState);

  const setSort = useCallback((sort: SortBy) => dispatch({ type: SET_SORT, sort }), []);
  const toggleSort = useCallback(
    (field: string) => dispatch({ type: TOGGLE_SORT, sort: { field, order: null } }),
    [],
  );

  return { sort: { ...state }, toggleSort, setSort };
};
