import { Plan, ProductSubscriptionResponse } from '@app/@types/subscriptions.types';
import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import { CustomerProduct } from '@app/constants/customerProducts';
import useChannelPartner from '@app/hooks/useChannelPartner';
import AtoBPlusBanner from '@app/pages/Settings/Plans/AtoBPlusBanner';
import ChangePlan from '@app/pages/Settings/Plans/ChangePlan';
import {
  ProductSubscription,
  convertToProductSubscription,
  getDetails,
} from '@app/pages/Settings/Plans/PlansOverview';
import WelcomeToAtoBPremium from '@app/pages/Settings/Plans/WelcomeToAtoBPremium';
import { CustomTooltip } from '@atob-developers/shared/src/components/Tooltip';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import axios from 'axios';
import { deserialize } from 'deserialize-json-api';
import { ReactElement, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import ProductCard from './ProductCard';

export default function PlansOverview({ products }: { products: CustomerProduct[] }): ReactElement {
  const { planId } = useParams();
  const productTypeToChangePlan = planId ?? null;
  const showAtoBPlusBanner =
    products.includes('promote_atob_plus') && products.includes('settings');
  const [productSubscriptions, setProductSubscriptions] = useState<ProductSubscription[]>([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { forcePremium } = useChannelPartner();
  const isConfirmed = location.pathname.includes('confirmed');

  const loadPlans = () => {
    setLoading(true);
    axios
      .get('/subscriptions')
      .then((res: { data: ProductSubscriptionResponse[] }) => {
        const subscriptions = deserialize(res.data).data as ProductSubscriptionResponse[];
        setProductSubscriptions(
          subscriptions
            .filter((s) => s.data.attributes.product !== 'payroll')
            .map(convertToProductSubscription),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadPlans();
  }, []);

  if (isConfirmed) {
    return <WelcomeToAtoBPremium />;
  }

  if (productTypeToChangePlan) {
    const currentProduct = productSubscriptions.find(
      (product) => product.type === productTypeToChangePlan,
    );

    if (currentProduct) {
      const currentPlan: Plan = {
        current: true,
        type: currentProduct.type,
        name: currentProduct.plan,
        amount: currentProduct.cost,
        valueProps: '',
      };

      return <ChangePlan currentPlan={currentPlan} />;
    }
  }

  return (
    <div>
      {showAtoBPlusBanner && <AtoBPlusBanner />}
      {loading && <SpinnerBoundary />}
      {productSubscriptions.map((product) => (
        <div key={product.type + product.plan} className="my-4">
          <ProductCard
            key={product.cost}
            product={product}
            editable={!forcePremium || product.type !== 'atob_plus_premium'}
          >
            {(Detail) => (
              <>
                {getDetails(product).map(
                  (detail: {
                    type: string;
                    value: string | number;
                    TooltipContent?: () => ReactElement;
                    tooltipHeader?: string;
                  }) => {
                    const { TooltipContent } = detail;
                    return (
                      <div key={detail.type} className="flex items-center">
                        <Detail {...detail} />
                        <span className="ml-1" />
                        {detail.TooltipContent && (
                          <CustomTooltip icon={faInfoCircle}>
                            <div className="px-3 py-3 text-center text-sm font-medium text-gray-700">
                              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                              {/* @ts-expect-error */}
                              <TooltipContent />
                            </div>
                          </CustomTooltip>
                        )}
                      </div>
                    );
                  },
                )}
              </>
            )}
          </ProductCard>
        </div>
      ))}
    </div>
  );
}
