import { ReactElement } from 'react';

export default function ReferralYourReferralsCard({
  referralsSummary,
  isPrepaid,
}: {
  referralsSummary?: {
    referrals?: {
      id: string;
      signed_up_at: number;
      gallons_bought: number;
      target_gallons: number;
      company_name: string;
      credit: {
        currency_iso: string;
        cents: number;
      };
    }[];
  };
  isPrepaid: boolean;
}): ReactElement {
  return (
    <div className="rounded bg-white p-4 shadow-md">
      <div className="">
        <p className="text-lg font-bold">Your Referrals</p>
        {!referralsSummary?.referrals?.length ? (
          <p className="mt-2 text-base">No referrals to show.</p>
        ) : (
          <table className="table-auto">
            <thead>
              <tr>
                <th>Company Name</th>
                <th>Gallons Purchased</th>
                <th>Signed Up</th>
                {!isPrepaid && <th>Credits Earned</th>}
              </tr>
            </thead>
            <tbody>
              {referralsSummary.referrals &&
                referralsSummary.referrals.map((referral) => {
                  const signedUpDateTime = new Date(referral.signed_up_at).toDateString();

                  const gallonsRatio = referral.gallons_bought / referral.target_gallons;

                  const gallonsProgressText = `${referral.gallons_bought} / ${referral.target_gallons}`;

                  return (
                    <tr key={referral.id} data-testid="table-row">
                      <td className="pivoted">
                        <div className="tdBefore">Company Name</div>
                        <span className="tdAfter">{referral.company_name}</span>
                      </td>
                      <td className="pivoted">
                        <div className="tdBefore">Gallons Purchased</div>
                        <span className="tdAfter">
                          <progress
                            className="progress mb-0"
                            value={referral.gallons_bought}
                            max={referral.target_gallons}
                          >
                            {`${gallonsRatio}%`}
                          </progress>
                          <div className="is-size-7 has-text-right">{gallonsProgressText}</div>
                        </span>
                      </td>
                      <td className="pivoted">
                        <div className="tdBefore">Signed Up</div>
                        <span className="tdAfter">{signedUpDateTime}</span>
                      </td>
                      {!isPrepaid && (
                        <td className="pivoted">
                          <div className="tdBefore">Credits Earned</div>
                          <span className="tdAfter">
                            {`${referral.credit.currency_iso === 'USD' ? '$' : ''}${
                              referral.credit.cents / 100
                            }`}
                          </span>
                        </td>
                      )}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}
