import NoTransactions from '@app/assets/images/svg/no-transactions.svg';
import TransactionsCard from '@app/components/TransactionsCard/TransactionsCard';
import { ErrorNotification } from '@app/components/layout';
import useChargeEvents from '@app/hooks/useChargeEvents';
import EmptyTable from '@atob-developers/shared/src/components/EmptyTable';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import utc from 'dayjs/plugin/utc';
import { ReactElement } from 'react';

dayjs.extend(isoWeek);
dayjs.extend(utc);

const PERIOD_LENGTH = 7;
const END_AT = dayjs().endOf('day');
const START_AT = dayjs(END_AT).subtract(PERIOD_LENGTH, 'days').startOf('day');

export default function CardSpecificTransactions({ cardID }: { cardID: number }): ReactElement {
  const {
    data: chargeEvents,
    error,
    isLoading,
  } = useChargeEvents('/charge_events', {
    'page': 1,
    'by_card_ids': [cardID.toString()],
    'filter_authorized_at[from]': START_AT.unix().toString(),
    'filter_authorized_at[to]': END_AT.unix().toString(),
    'all': false,
    'per': 7,
  });

  if (error) {
    return <ErrorNotification error={error} generic />;
  }

  return isLoading || (chargeEvents?.data ?? []).length > 0 ? (
    <TransactionsCard transactions={chargeEvents?.data ?? []} isLoading={isLoading} />
  ) : (
    <EmptyTable
      imgComponent={<img src={NoTransactions} alt="" />}
      title="No recent transactions found"
      subtitle="Transactions from the last 7 days will be displayed here"
    />
  );
}
