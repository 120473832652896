import useFeatureFlags from '@app/hooks/useFeatureFlags';
import { Button, Dialog, DialogContent } from '@mui/material';
import { ReactElement, useState } from 'react';
import { NewFeatureBanner } from './Designs/NewFeatureBanner';

const AmountApplicationBanner = (): ReactElement | null => {
  const [showModal, setShowModal] = useState(false);
  const [showAmountBanner] = useFeatureFlags('amount_rollout');

  return showAmountBanner ? (
    <>
      <NewFeatureBanner
        onClick={() => setShowModal(true)}
        actionText="Get Started"
        bannerKey="amount-application-banner"
        variant="new_product"
      >
        <div>
          Launching our new Line of Credit solution to help you grow your business. Receive approval
          for up to $100k in credit with best-in-market financing rates.
        </div>
      </NewFeatureBanner>

      <Dialog open={showModal} onClose={() => setShowModal(false)}>
        <DialogContent>
          <div className="flex items-center bg-white p-2 pb-8">
            <div className="flex flex-col gap-2">
              <div className="text-xl font-bold text-gray-900">
                Flexible financing built specifically for your fleet
              </div>
              <div className="text-md text-gray-900">
                Get access to funds when you need them – supercharging your business’s growth and
                ensuring your longevity. Fill out the 5 min application below to receive your
                financing offer.
              </div>
              <div className="text-md flex flex-col gap-2">
                <div>📈 12-24 month lines up to $100k</div>
                <div>🔐 Funds available in AtoB wallet within 2 days of approval</div>
                <div>💵 Best in-market rates for financing</div>
                <div>✔️ Only pay for funds your fleet uses</div>
              </div>
            </div>
          </div>
          <Button
            className="w-full"
            size="large"
            color="primary"
            href={'https://secure.fundation.com/AtoB/Home'}
            target="_blank"
          >
            Apply Now
          </Button>
        </DialogContent>
      </Dialog>
    </>
  ) : null;
};

export default AmountApplicationBanner;
