import { PaginatedEndpointResponse } from '@app/@types/api.types';
import { ChargeEventData } from '@app/@types/charge_events.types';
import { ChargeEventQueryParams } from '@app/contexts/ChargeEventsContextComponent';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import useSWR, { SWRResponse } from 'swr';

export default function useChargeEvents(
  url: '/charge_events' | '/charge_events/suspicious',
  chargeEventsParams: Partial<ChargeEventQueryParams> = {},
): SWRResponse<PaginatedEndpointResponse<ChargeEventData>, Error> {
  const queryParams = {
    all: false,
    transactor_name: true,
    include: url === '/charge_events' ? 'tag' : '',
    ...chargeEventsParams,
  };

  return useSWR<PaginatedEndpointResponse<ChargeEventData>>(
    {
      url,
      params: queryParams,
      key: url,
    },
    apiGetFetcher,
  );
}
