import { CustomerData } from '@app/@types/customer.types';
import useModal from '@app/hooks/useModal';
import { ReactElement } from 'react';
import useScheduledBillingPayments from '../../pages/AccountOverview/useScheduledBillingPayments';
import PaymentModalWithEstimates from '../PaymentModal/PaymentModalWithEstimates/PaymentModalWithEstimates';
import { ErrorBanner } from './Designs/ErrorBanner';

const OverdueBanner = ({
  arrearsDue,
  customer,
  hasTreasury,
}: {
  arrearsDue: string;
  ownerEmail: string;
  customer: CustomerData;
  hasTreasury: boolean;
}): ReactElement => {
  const [isModalActive, toggleModal] = useModal();
  const { data: upcomingPayment } = useScheduledBillingPayments();

  return (
    <ErrorBanner onClick={() => toggleModal()} actionText="Pay now" data-testid="overdue-banner">
      <span className="font-semibold">Account Suspended:</span> Please pay off your overdue balance
      of ${arrearsDue} to reactivate your account.
      <PaymentModalWithEstimates
        isModalActive={isModalActive}
        customer={customer}
        upcomingPayment={upcomingPayment}
        walletAvailable={hasTreasury}
        closeModal={toggleModal}
      />
    </ErrorBanner>
  );
};

export default OverdueBanner;
