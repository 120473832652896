import { EndpointResponse } from '@app/@types/api.types';
import { getFetcher } from '@app/utils/data/fetchers';
import useSWR, { SWRResponse } from 'swr';

export type ActivationRetentionPromoCopyData = {
  tagline: string;
  body: string;
};

export function useActivationRetentionPromoCopyQuery(): SWRResponse<
  EndpointResponse<ActivationRetentionPromoCopyData>
> {
  return useSWR<EndpointResponse<ActivationRetentionPromoCopyData>>(
    {
      url: '/treasury/account_promotions/activation_retention_promo_copy',
    },
    getFetcher,
  );
}
