import { FlattenedPayrollData } from '@app/@types/payroll.types';
import Ellipsis from '@app/components/elements/Ellipsis';
import { sumItemizationCategory, sumPayrollPayment } from '@app/utils/payroll-itemization';
import { FormatCurrency } from '@atob-developers/shared/src/utils/formatters';
import { Button } from '@mui/material';
import { capitalize } from 'lodash-es';
import { ReactElement } from 'react';
import { PaymentCalculation } from './PaymentCalculation';
import { ItemizationCategories } from './PaymentItemization';

const PaymentConfirmationCalculations = ({
  driver,
  feesInfo,
  paymentDetails,
}: {
  driver: FlattenedPayrollData;
  feesInfo: { amount_in_cents: number };
  paymentDetails: PaymentCalculation;
}): ReactElement => {
  return (
    <div className="flex flex-col">
      <div>
        <h1 className="mb-6 text-center text-lg font-semibold">
          Paying {driver.first_name} {driver.last_name}
        </h1>

        <div className="flex flex-col divide-y-2">
          <div className="flex justify-between pb-2">
            <span>Gross Pay Total</span>
            <span>
              {FormatCurrency({
                value: paymentDetails.amount_cents,
                options: { fromCents: true },
              })}
            </span>
          </div>
          {ItemizationCategories.map((category) => {
            const itemsInCategory = paymentDetails.itemizations.filter(
              (item) => item.category === category,
            );
            if (itemsInCategory.length === 0 || sumItemizationCategory(itemsInCategory) === 0) {
              return null;
            }
            return (
              <div key={category} className="flex justify-between pb-2 pt-4">
                <span className="ml-8">{category}</span>
                <span>
                  {FormatCurrency({
                    value: sumItemizationCategory(itemsInCategory),
                  })}
                </span>
              </div>
            );
          })}
          {feesInfo?.amount_in_cents !== 0 && (
            <div className="flex justify-between pb-2 pt-4">
              <span className="ml-8">Instant Pay Fee</span>
              <span>
                {FormatCurrency({
                  value: feesInfo?.amount_in_cents ?? 0,
                  options: { fromCents: true },
                })}
              </span>
            </div>
          )}
          <div className="flex justify-between pt-4 text-lg font-bold">
            <span>Total Payment</span>
            <span>
              {FormatCurrency({
                value: sumPayrollPayment(paymentDetails) + (feesInfo?.amount_in_cents ?? 0),
              })}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const PaymentConfirmationDetails = ({
  paymentDetails,
  speed,
  cardDescription,
  lastFour,
}: {
  paymentDetails: PaymentCalculation;
  speed: string;
  cardDescription: string | null;
  lastFour: string | null;
}): ReactElement => {
  return (
    <div>
      <div className="mb-4 md:flex md:justify-between">
        <div className="font-bold">Payment Description:</div>
        <div className="md:text-right">{paymentDetails?.description || ''}</div>
      </div>
      <div className="mb-4 md:flex md:justify-between">
        <div className="font-bold">Payment Speed:</div>
        <div className="md:text-right">{speed}</div>
      </div>
      <div className="mb-4 md:flex md:justify-between">
        <div className="font-bold">Payment Method:</div>
        <div className="md:text-right">
          {cardDescription && capitalize(cardDescription)} ending in {lastFour}
        </div>
      </div>
    </div>
  );
};

const PaymentConfirmationFooter = ({
  loading,
  onSubmitPayment,
  resetPage,
}: {
  loading: boolean;
  onSubmitPayment: () => void;
  resetPage: () => void;
}): ReactElement => {
  return (
    <div className="my-10 flex w-full justify-end">
      <div className="flex gap-2">
        <Button color="secondary" size="medium" onClick={resetPage}>
          Cancel
        </Button>
        <Button size="medium" disabled={loading} onClick={onSubmitPayment}>
          {loading ? (
            <span className="flex w-[64px] justify-start">
              Sending
              <Ellipsis />
            </span>
          ) : (
            <span className="w-max">Confirm &amp; Pay</span>
          )}
        </Button>
      </div>
    </div>
  );
};
export { PaymentConfirmationCalculations, PaymentConfirmationDetails, PaymentConfirmationFooter };
