import { InputLabel, MenuItem, Select } from '@mui/material';
import { ReactElement } from 'react';
import { DropdownFormElement, InputFormElement, WithId } from '../@types/form.types';
import { DataItemType } from './DataItem';
import Input from './Input';

interface FormElementProps<T> {
  element: InputFormElement | DropdownFormElement<T>;
  value: T | string | number | boolean;
  handleOnChange: (val: string | T | null) => void;
  required?: boolean;
  error?: string;
  warning?: string;
  disabled?: boolean;
  readOnly?: boolean;
}

const FormElement = <T,>({
  element,
  value,
  handleOnChange,
  required = false,
  error,
  warning,
  disabled,
  readOnly = false,
}: FormElementProps<T>): ReactElement => {
  switch (element.type) {
    case DataItemType.DROPDOWN:
      return (
        <>
          {element.label && (
            <InputLabel className="block text-sm font-medium text-gray-700">
              {element.label}
            </InputLabel>
          )}
          <Select
            className="my-2 h-[38px]"
            value={(value as WithId<T>).id}
            error={error != null}
            onChange={(e) => {
              const item = (element as DropdownFormElement<T>).options.find(
                (opt) => opt.id === e.target.value,
              );
              item && handleOnChange(item);
            }}
            displayEmpty
            renderValue={(value) => {
              const item = (element as DropdownFormElement<T>).options.find(
                (opt) => opt.id === value,
              );
              if (!item) {
                return (
                  <span className="text-grey17 font-normal">
                    {(element as DropdownFormElement<T>).blankState || 'Options'}
                  </span>
                );
              }
              return (element as DropdownFormElement<T>).children(item);
            }}
          >
            {(element as DropdownFormElement<T>).options.map((option) => (
              <MenuItem
                classes={{ root: 'flex flex-col items-start' }}
                key={option.id}
                value={option.id}
              >
                {(element as DropdownFormElement<T>).children(option)}
              </MenuItem>
            ))}
          </Select>
        </>
      );
    default:
      return (
        <Input
          label={element.label}
          inputId={element.key}
          value={(value as string) || ''}
          inputType={(element as InputFormElement)?.inputType || 'text'}
          placeholder={(element as InputFormElement)?.placeholder || ''}
          required={required}
          onChange={(event) => handleOnChange(event.target.value)}
          error={error}
          warning={warning}
          disabled={disabled}
          readOnly={readOnly}
        />
      );
  }
};

export default FormElement;
