import { getFetcher } from '@app/utils/data/fetchers';
import { MobileFriendlyTooltip } from '@atob-developers/shared/src/components/MobileFriendlyTooltip';
import { CurrencyFormat } from '@atob-developers/shared/src/utils/formatters';
import { convertCentsToDollars } from '@atob-developers/shared/src/utils/formatters/CurrencyFormat';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Skeleton } from '@mui/material';
import { ReactElement } from 'react';
import useSWRImmutable from 'swr/immutable';

type SecurityDepositSummary = {
  paid_amount_cents: number;
};

export const SecurityDepositDetails = (): ReactElement => {
  const { data: depositSummary, isLoading: loading } = useSWRImmutable<SecurityDepositSummary>(
    { url: `/security_deposits/summary` },
    getFetcher,
  );

  return (
    <div className="flex w-full flex-col justify-between">
      <h2 className="text-grey leading-12 text-sm font-bold uppercase">
        Security Deposit
        <MobileFriendlyTooltip
          className="pl-1"
          title="You can contact support to refund your security deposit."
        >
          <FontAwesomeIcon icon={faCircleInfo} className=" text-gray-500" />
        </MobileFriendlyTooltip>
      </h2>
      {loading ? (
        <Skeleton />
      ) : (
        <div className="text-atob-green text-[40px] font-normal">
          {depositSummary?.paid_amount_cents
            ? CurrencyFormat({
                value: convertCentsToDollars({ value: depositSummary.paid_amount_cents }),
                onlyWholeNumber: true,
              })
            : '-'}
        </div>
      )}
    </div>
  );
};
