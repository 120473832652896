import useCustomer from '@app/hooks/useCustomer';
import { ReactElement, useCallback, useRef, useState } from 'react';
import OnboardingHeader from './OnboardingHeader';
import OnboardingLayout from './OnboardingLayout';
import { STEP_MAP } from './OnboardingStepMap';
import { OnboardingWizardContext } from './OnboardingWizardContext';

export default function OnboardingWizard(): ReactElement | null {
  const { interstitials } = useCustomer();
  const staticSteps = useRef((interstitials?.steps ?? []).map((step) => STEP_MAP[step.name]));
  const [stepNumber, setStepNumber] = useState(0);
  const nextStep = useCallback(() => {
    setStepNumber((s) => {
      for (let i = s + 1; i < staticSteps.current.length; i++) {
        if (interstitials?.steps[i].status === false) {
          return i;
        }
      }
      return staticSteps.current.length - 1;
    });
  }, [interstitials?.steps]);

  if (interstitials == null) {
    return null;
  }

  const step = staticSteps.current[stepNumber];
  const RightContent = step.stepComponent;
  const Upsell = step.upsellComponent;

  return (
    <OnboardingWizardContext.Provider value={nextStep}>
      <OnboardingLayout
        header={
          <OnboardingHeader
            hideProgress={step.hideProgress}
            currentStep={stepNumber}
            totalSteps={staticSteps.current.filter((step) => !step.hideProgress).length}
          />
        }
        rightContent={<Upsell />}
        leftContent={<RightContent />}
      />
    </OnboardingWizardContext.Provider>
  );
}
