import {
  CustomerOnboardingEventName,
  useCreateCustomerOnboardingEvent,
} from '@app/hooks/query/useCustomerOnboardingEvents';
import { useSendAppInviteLinkText } from '@app/hooks/query/useSendAppInviteLinkText';
import useCustomer from '@app/hooks/useCustomer';
import { Button } from '@mui/material';
import { ReactElement, useState } from 'react';
import InputMask from 'react-input-mask';
import OnboardingForm from './OnboardingForm';
import OnboardingRightUpsell from './OnboardingRightUpsell';

import { useOnboardingGuide } from './useOnboardingGuide';

export default function OnboardingAppAdminDownloadStep(): ReactElement | null {
  const nextStep = useOnboardingGuide();
  const { owner_phone } = useCustomer();
  const [phoneNumber, setPhoneNumber] = useState(owner_phone.replace('+1', '').replace(/\D/g, ''));
  const { data, trigger: sendAppInviteLinkText, isMutating, reset } = useSendAppInviteLinkText();
  const { trigger: createCustomerOnboardingEvent } = useCreateCustomerOnboardingEvent({
    reloadCustomer: false,
  });

  const cleanNumber = (phoneNumber: string) => {
    return phoneNumber.replace(/\D/g, '');
  };

  const submitPhoneNumber = async () => {
    reset();
    sendAppInviteLinkText({ driver: { phone: phoneNumber } });
  };
  if (data) {
    return (
      <OnboardingForm>
        <h1 className="text-2xl font-semibold">Download the AtoB app</h1>
        <img className="h-24" src="/images/phone-eclipse.svg" />
        <p className="text-base font-normal">
          You'll receive a text with download instructions shortly.
        </p>
        <p>
          Log in as a Fleet Manager using your email and password to manage your account on the go
          and get the best deals on discounts!
        </p>
        <Button
          color="primary"
          onClick={() => {
            createCustomerOnboardingEvent({
              customer_onboarding_event: {
                name: CustomerOnboardingEventName.CUSTOMER_OWNER_APP_DOWNLOAD,
              },
            });
            nextStep();
          }}
        >
          Next
        </Button>
      </OnboardingForm>
    );
  }
  return (
    <OnboardingForm>
      <h1 className="text-2xl font-semibold">Download the AtoB app</h1>
      <p className="text-base font-normal">What phone do you want to use the AtoB app on?</p>
      <div className="mt-4 flex h-[56px] items-center rounded-lg border-[1px] border-solid border-gray-200 bg-white">
        <InputMask
          mask="(999) 999-9999"
          maskChar=""
          value={phoneNumber}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            reset();

            const phoneNumber = cleanNumber(e.target.value);
            setPhoneNumber(phoneNumber);
          }}
        >
          <input
            value={phoneNumber}
            className="w-full px-2 text-base placeholder-gray-500 focus-visible:outline-none"
            placeholder="Phone number"
          />
        </InputMask>
      </div>
      <Button
        disabled={data ? false : isMutating || phoneNumber.length != 10}
        autoFocus
        fullWidth
        onClick={submitPhoneNumber}
        color="primary"
      >
        {data != null ? 'Done' : isMutating ? 'Sending link...' : 'Receive download link'}
      </Button>
    </OnboardingForm>
  );
}

export function OnboardingAppAdminDownloadUpsell(): ReactElement {
  return (
    <OnboardingRightUpsell>
      <img src="/images/fleet-manager-app-promo.png" height={588} width={569} />
    </OnboardingRightUpsell>
  );
}
