import { SpendTierProduct } from './customerProducts';

export const MAX_CUSTOMER_SPEND_TIER = 2;

export function spendTierToInt(spendTier: SpendTierProduct): number {
  switch (spendTier) {
    case 'spend_tier_0':
      return 0;
    case 'spend_tier_1':
      return 1;
    case 'spend_tier_2':
      return 2;
    case 'spend_tier_3':
      return 3;
  }
}
