import Button from '@atob-developers/shared/src/components/Button';
import Card from '@atob-developers/shared/src/components/Card';
import Searchbar from '@atob-developers/shared/src/components/Searchbar';
import { ClickAwayListener, Popover } from '@mui/material';
import { ChangeEvent, ReactElement } from 'react';
import { DropdownCardProps, DropdownSearchCardProps } from './DropdownCard.types';

export function DropdownCard({
  show,
  children,
  close,
  noPadding,
  anchor,
}: DropdownCardProps): ReactElement {
  return (
    <Popover open={show} anchorEl={anchor}>
      <ClickAwayListener
        onClickAway={() => {
          close && close();
        }}
      >
        <Card noPadding={noPadding}>{children}</Card>
      </ClickAwayListener>
    </Popover>
  );
}

export const DropdownSearchCard = ({
  name,
  searchPlaceholder,
  onSearch,
  onSubmit,
  show,
  inputValue,
  close,
  children,
  buttonChildren,
  anchor,
}: DropdownSearchCardProps) => (
  <DropdownCard show={show} close={close} anchor={anchor}>
    <div className="py-4 md:px-4">
      <p className="text-grey3 pb-6 text-lg font-semibold leading-6">Assign to</p>

      <Searchbar
        placeholder={searchPlaceholder || 'Search'}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          onSearch(e.target.value);
        }}
        name={name}
        value={inputValue}
      />
      {children}
      <Button primary onClick={() => onSubmit()}>
        {buttonChildren || `Add new ${name.toLowerCase()}`}
      </Button>
    </div>
  </DropdownCard>
);

export default DropdownCard;
