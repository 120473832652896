import { createContext } from 'react';

export type PlaidContextType = {
  linkToken: string | null;
  plaidError: boolean;
  setError: (error: Error) => void;
  onSuccessCallback: () => void;
  onLinkingCallback: (phase: string) => void;
  flow?: string;
};

const PlaidContext = createContext<PlaidContextType>({
  linkToken: null,
  plaidError: false,
  setError: () => {},
  onSuccessCallback: () => {},
  onLinkingCallback: () => {},
});

export default PlaidContext;
