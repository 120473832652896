import { useCustomerSpendQuery } from '@app/hooks/query/useCustomerSpendQuery';
import { FormatCurrency } from '@atob-developers/shared/src/utils/formatters';
import { ReactElement, useState } from 'react';
import RoundedCard from '../RoundedCard/RoundedCard';
import { SameDayPayoutModal } from './SameDayPayoutModal';

const getFullMonthName = (date: Date) => {
  return date.toLocaleString('en-US', { month: 'long' });
};

const MonthSpend = ({
  year,
  month,
  isCurrentMonth,
}: {
  year: number;
  month: number;
  isCurrentMonth: boolean;
}) => {
  const after = new Date(Date.UTC(year, month, 1, 8)).toISOString();
  const before = new Date(Date.UTC(year, month + 1, 1, 8)).toISOString();

  const { data, error } = useCustomerSpendQuery(after, before);

  let content;

  if (error) {
    content = <p className="mt-1">Failed to load</p>;
  } else if (!data) {
    content = <p className="mt-1 h-8 w-20 animate-pulse rounded-lg bg-gray-300"></p>;
  } else {
    content = (
      <p className="mt-1 text-2xl font-semibold">
        {FormatCurrency({ value: data.period.settled_spend.cents })}
      </p>
    );
  }

  return (
    <div>
      <p className="text-gray-500">
        {getFullMonthName(new Date(year, month))} {isCurrentMonth && '(this month)'}
      </p>
      {content}
    </div>
  );
};

export const TotalSpend = (): ReactElement => {
  const [sameDayPayoutModelOpen, setSameDayPayoutModelOpen] = useState(false);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const beginningOfMonths = [
    [currentYear, currentMonth],
    [currentYear, currentMonth - 1],
    [currentYear, currentMonth - 2],
  ];

  return (
    <RoundedCard>
      <div className="p-6 text-sm">
        <p className="text-xl font-semibold">Unlock same-day payouts</p>
        <p className="mt-2 text-gray-500">
          Spend $3,500 per truck per month and get same-day payouts for the subsequent month.
          Eligibility is determined based on FMCSA data.{' '}
          <a onClick={() => setSameDayPayoutModelOpen(true)} className="underline">
            Learn more
          </a>
        </p>
        <div className="mt-6">
          <p className="font-semibold">Total Spend</p>
          <div className="mt-3 flex flex-wrap gap-[30px]">
            {beginningOfMonths.map((element, index) => {
              const [year, month] = element;
              return (
                <MonthSpend key={index} year={year} month={month} isCurrentMonth={index === 0} />
              );
            })}
          </div>
        </div>
      </div>
      <SameDayPayoutModal
        open={sameDayPayoutModelOpen}
        onClose={() => setSameDayPayoutModelOpen(false)}
      />
    </RoundedCard>
  );
};
