import { Entity, PaginatedEndpointResponse } from '@app/@types/api.types';
import { DebitCardPaymentMethodCombined } from '@app/@types/debitCard.types';
import { DriverData } from '@app/@types/driver.types';
import {
  FlattenedPayrollData,
  PayrollDriver,
  PayrollDriverState,
  PayrollCalculationBackendEnums,
  PayrollCalculation,
  PayrollTransactionDeserializedData,
  PayrollTransactionData,
} from '@app/@types/payroll.types';
import axios from 'axios';
import { deserialize } from 'deserialize-json-api';
import { createContext, useCallback, useState } from 'react';

function flattenDriverDetails(driver: PayrollDriver): FlattenedPayrollData {
  const driverId = `${driver.id}`;
  const payrollData = driver.payroll;
  const calculation_method = payrollData?.last_successful_payout?.calculation_method;
  const flattened = {
    ...driver,
    id: driverId,
    tax_classification: payrollData?.tax_classification,
    last_paid_amount: payrollData?.last_successful_payout?.amount,
    last_paid_date: payrollData?.last_successful_payout?.date,
    last_paid_calculation_method: calculation_method
      ? mapFromCalculationBackendEnum(calculation_method as PayrollCalculationBackendEnums)
      : undefined,
    status: payrollData?.status || 'not_enrolled',
    amount_to_pay_cents: '',
    payout_account: driver.payroll?.default_payout_account,
  };

  delete flattened.payroll;

  return flattened;
}

export function mapDriverDataToDriverState(drivers: PayrollDriver[]): PayrollDriverState {
  return drivers
    .filter((driver) => driver.name && driver.name !== '' && driver.name !== null)
    .sort((a, b) => ((a.name?.toLowerCase() ?? '') > (b.name?.toLowerCase() ?? '') ? -1 : 1))
    .reduce((acc, driver) => {
      const driverId = `${driver.id}`;
      acc[driverId] = {
        ...flattenDriverDetails(driver),
      };
      return acc;
    }, {} as PayrollDriverState);
}

export const mapTransactionstoState = (
  data: PayrollTransactionDeserializedData[],
): PayrollTransactionData[] => {
  return data.map((transaction: PayrollTransactionDeserializedData) => {
    const card_detail = transaction.payout_account?.payout_detail;

    const payment_card = card_detail ? `${card_detail.brand} .... ${card_detail.last_four}` : '';

    return {
      id: transaction.id,
      driverId: transaction.driver?.id.toString(),
      amount: transaction.amount,
      payment_date: transaction.payment_timestamp,
      name: transaction.driver?.name ?? '',
      tax_classification: transaction.driver?.payroll?.tax_classification ?? '',
      payment_card: payment_card,
      payment_method: transaction.method,
      payment_status: transaction.payment_status,
      description: transaction.description,
      fees: transaction.fees,
      error_message: transaction.error_message,
      itemizations: (transaction?.itemizations || []).map((itemEntity) => itemEntity.data),
    };
  });
};
export function mapFromCalculationBackendEnum(
  calculation_method: PayrollCalculationBackendEnums,
): PayrollCalculation {
  switch (calculation_method) {
    case 'calculation_flat_amount_payroll':
      return 'Flat Amount Payroll';
    case 'calculation_flat_amount_other':
      return 'Flat Amount Other';
    case 'calculation_per_mile':
      return 'Pay per Mile';
    case 'calculation_hourly':
      return 'Hourly';
    case 'calculation_load':
      return 'Percentage of Load';
  }
}

export type PayrollContextType = {
  cards: DebitCardPaymentMethodCombined[];
  setCards: (cards: DebitCardPaymentMethodCombined[]) => void;
  driverStateIds: string[];
  driverState: PayrollDriverState;
  loadDriverState: (driverState: PayrollDriverState) => void;
  addDriverToState: (driver: PayrollDriver) => void;
  updateDriverInState: (
    id: string,
    driverDetails: Partial<FlattenedPayrollData>,
    callback?: (updatedState: PayrollDriverState) => void,
  ) => void;
  currentDriver: Partial<FlattenedPayrollData>;
  updateCurrentDriverToPerformActionOn: (id: string) => void;
  resetCurrentDriver: () => void;
  loaded: boolean;
  driverData: {
    fetch: () => Promise<void>;
    loading: boolean;
    error: string | null;
  };
};

const PayrollContext = createContext<PayrollContextType>({
  cards: [],
  setCards: () => {},
  driverStateIds: [],
  addDriverToState: () => {},
  updateDriverInState: () => {},
  driverState: {},
  loadDriverState: () => {},
  currentDriver: {} as FlattenedPayrollData,
  updateCurrentDriverToPerformActionOn: () => {},
  resetCurrentDriver: () => {},
  loaded: false,
  driverData: {
    fetch: () => {
      return Promise.resolve();
    },
    loading: false,
    error: '',
  },
});

export default PayrollContext;

export function useInitialPayrollProviderState(): PayrollContextType {
  const [driverState, setDriverState] = useState<PayrollDriverState>({});
  const [currentDriverId, setCurrentDriverId] = useState<string | null>(null);
  const currentDriver = currentDriverId ? driverState[currentDriverId] : {};
  const [cards, setCards] = useState<DebitCardPaymentMethodCombined[]>([]);
  const [loaded, setLoaded] = useState(false);

  const loadDriverState = (newDriverState: PayrollDriverState) => {
    setLoaded(true);
    setDriverState(newDriverState);
  };

  const addDriverToState = (driver: PayrollDriver) => {
    const driverId = `${driver.id}`;

    setDriverState({
      ...driverState,
      [driverId]: {
        ...flattenDriverDetails(driver),
      },
    });
  };

  const updateDriverInState = (
    id: string,
    newDetails: Partial<FlattenedPayrollData>,
    callback?: (updatedState: PayrollDriverState) => void,
  ) => {
    const previousCurrentDriverId = currentDriverId;
    setCurrentDriverId(null);
    const currentDriverState = driverState[id];
    const updatedState = {
      ...driverState,
      [id]: { ...currentDriverState, ...newDetails },
    };
    setDriverState(updatedState);
    setCurrentDriverId(previousCurrentDriverId);

    if (callback) {
      callback(updatedState);
    }
  };

  const [loadingDriverData, setLoadingDriverData] = useState(false);
  const [driverDataError, setDriverDataError] = useState('');

  const fetchDriverData = useCallback(async () => {
    setLoadingDriverData(true);
    try {
      const [activeDriversResponse, archivedDriversResponse] = await Promise.all([
        axios.get<PaginatedEndpointResponse<Entity<DriverData>>>('/drivers', {
          params: {
            include: 'payroll.default_payout_account.payout_detail',
            per: 2000,
            archived: false,
            all: true,
          },
        }),
        axios.get<PaginatedEndpointResponse<Entity<DriverData>>>('/drivers', {
          params: {
            include: 'payroll.default_payout_account.payout_detail',
            per: 2000,
            archived: true,
            all: true,
          },
        }),
      ]);
      const { data: activeDrivers } = deserialize(activeDriversResponse.data);
      const { data: archivedDrivers } = deserialize(archivedDriversResponse.data);
      const payrollDrivers = mapDriverDataToDriverState([...activeDrivers, ...archivedDrivers]);
      loadDriverState(payrollDrivers);

      setLoadingDriverData(false);
    } catch (e: unknown) {
      const errorMessage = e instanceof Error && e.message;
      setDriverDataError(errorMessage || 'There was an error loading driver data.');
    }
  }, []);

  return {
    cards,
    setCards,
    driverData: {
      fetch: fetchDriverData,
      loading: loadingDriverData,
      error: driverDataError,
    },
    driverStateIds: Object.keys(driverState),
    driverState,
    loadDriverState,
    addDriverToState,
    updateDriverInState,
    currentDriver,
    updateCurrentDriverToPerformActionOn: (driverId: string) => {
      setCurrentDriverId(driverId);
    },
    resetCurrentDriver: () => {
      setCurrentDriverId(null);
    },
    loaded,
  };
}
