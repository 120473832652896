/* eslint-disable @cspell/spellchecker */

import { EndpointResponse } from '@app/@types/api.types';
import {
  ComputeRouteWithListingsResponse,
  Coordinates,
  FuelGrade,
  FuelListing,
  MyLocation,
  PlaceType,
  SortType,
} from '@app/@types/fuel_listings.types';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import { getFetcher, noArgPostFetcher } from '@app/utils/data/fetchers';
import useScript from '@atob-developers/shared/src/hooks/useScript';
import { FormControl, Skeleton } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';
import DiscountNetworkSelect from './DiscountNetworkSelect';
import { OriginEndAdornments, OriginStartAdornments } from './FuelListingAdornments';
import FuelListingDetail from './FuelListingDetail';
import './FuelListings.css';
import FuelListingsList from './FuelListingsList';
import FuelListingsMap from './FuelListingsMap';
import GoogleAutocomplete from './GoogleAutocomplete';
import GradeSelect from './GradeSelect';
import RadiusSelect, { RadiusOption } from './RadiusSelect';
import SearchMoreButtonFixed from './SearchMoreButtonFixed';
import SortSelect from './SortSelect';
import { useSortFuelListings } from './useSortFuelListings';

const DUMMY_MY_LOCATION: MyLocation = {
  location_type: 'my_location',
  address_components: [],
  place_id: 'current_location',
  formatted_address: 'Current Location',
  description: 'Current Location',
  structured_formatting: {
    main_text: 'Current Location',
    secondary_text: '',
  },
  geometry: {
    location: {
      lat: () => 0,
      lng: () => 0,
    },
  },
};

const EMPTY_LOCATION: MyLocation = {
  location_type: 'my_location',
  place_id: 'empty',
  address_components: [],
  formatted_address: '',
  description: '',
  structured_formatting: {
    main_text: '',
    secondary_text: '',
  },
  geometry: {
    location: {
      lat: () => 0,
      lng: () => 0,
    },
  },
};

const mapLocationGenerator = (coordinates: Coordinates): MyLocation => ({
  location_type: 'map_area',
  place_id: 'map_location',
  formatted_address: 'Current Map Area',
  description: 'Current Map Area',
  address_components: [],
  structured_formatting: {
    main_text: 'Current Map Area',
    secondary_text: '',
  },
  geometry: {
    location: {
      lat: () => coordinates.latitude,
      lng: () => coordinates.longitude,
    },
  },
});

const MILES_TO_METERS = 1.609 * 1000;

const FuelListings = ({
  GOOGLE_MAPS_API_KEY,
  MAP_ID,
  defaultSettings,
}: {
  GOOGLE_MAPS_API_KEY: string;
  MAP_ID: string | undefined;
  partnerName: string | null;
  defaultSettings: {
    isOTRCustomer?: boolean;
    customerAddress: {
      address1: string;
      address2?: string;
      city: string;
      state: string;
      zip: string;
    };
  };
}) => {
  const [defaultToRecommended] = useFeatureFlags('fuel_map_default_recommended_sort');
  const [gMap, setGMap] = useState<google.maps.Map | null>(null);
  const [bounds, setBounds] = useState<{
    top_left_longitude: number;
    top_left_latitude: number;
    bottom_right_longitude: number;
    bottom_right_latitude: number;
  } | null>(null);
  const [placesService, setPlacesService] = useState<google.maps.places.PlacesService | null>(null);

  const [mapCenterCoords, setMapCenterCoords] = useState<Coordinates>();
  const [originAddressIsEligibleForMarker, setOriginAddressIsEligibleForMarker] = useState(true);
  const [destinationCoords, setDestinationCoords] = useState<Coordinates>();
  const [userLocation, setUserLocation] = useState<Coordinates>();
  const [addressCoords, setAddressCoords] = useState<Coordinates>();
  const [address, setAddress] = useState<PlaceType | MyLocation>(DUMMY_MY_LOCATION);
  const [destinationAddress, setDestinationAddress] = useState<PlaceType | MyLocation>(
    EMPTY_LOCATION,
  );
  const [selectedGrade, setSelectedGrade] = useState<FuelGrade | 'TRUCK DIESEL'>(
    defaultSettings?.isOTRCustomer ? 'TRUCK DIESEL' : 'REGULAR',
  );
  const [selectedRadius, setSelectedRadius] = useState<RadiusOption>(
    defaultSettings?.isOTRCustomer ? 100 : 5,
  );
  const [selectedSort, setSelectedSort] = useState<SortType>(
    defaultToRecommended ? 'recommended' : 'discountedPrice',
  );
  const [filterHighFlowDieselLanes, setFilterHighFlowDieselLanes] = useState(
    defaultSettings?.isOTRCustomer ?? false,
  );
  const [routingDisplayRadius, setRoutingDisplayRadius] = useState<number | null>(null);
  const [selectedFuelListingId, setSelectedFuelListingId] = useState<string | null>(null);
  const [fitBounds, setFitBounds] = useState(true);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [routing, setRouting] = useState(false);
  const [discountOnly, setDiscountOnly] = useState(true);

  const gmapScriptStatus = useScript(
    `https://maps.googleapis.com/maps/api/js?libraries=geometry,places&key=${GOOGLE_MAPS_API_KEY}&v=weekly&callback=Function.prototype`,
  );

  // Preload
  useSWR({ url: '/drivers/counts' }, getFetcher);

  useEffect(() => {
    // Allows for the OTR specific settings to be changed if the customer declares they are OTR via the FleetTypePopup.
    setSelectedGrade(defaultSettings?.isOTRCustomer ? 'TRUCK DIESEL' : 'REGULAR');
    setFilterHighFlowDieselLanes(defaultSettings?.isOTRCustomer ?? false);
    setSelectedRadius(defaultSettings?.isOTRCustomer ? 100 : 5);
  }, [defaultSettings?.isOTRCustomer]);

  const hasSetCurLocDefaultRef = useRef(false);
  const [needToCheckCustomerAddress, setNeedToCheckCustomerAddress] = useState(false);
  useEffect(() => {
    if (!hasSetCurLocDefaultRef.current) {
      hasSetCurLocDefaultRef.current = true;
      const params = new URLSearchParams(window.location.search);
      if (params.has('address')) {
        setNeedToCheckCustomerAddress(true);
        return;
      }

      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setAddressCoords({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setMapCenterCoords({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setAddress({
            location_type: 'my_location',
            place_id: 'current_location',
            formatted_address: 'Current Location',
            description: 'Current Location',
            structured_formatting: {
              main_text: 'Current Location',
              secondary_text: '',
            },
            geometry: {
              location: {
                lat: () => position.coords.latitude,
                lng: () => position.coords.longitude,
              },
            },
          });
        },
        (_) => {
          setNeedToCheckCustomerAddress(true);
        },
      );
    }
  }, [filterHighFlowDieselLanes, routing, selectedRadius, setNeedToCheckCustomerAddress]);

  // We are updating state here but a state update function
  // is guaranteed to be stable
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (needToCheckCustomerAddress === false || gmapScriptStatus !== 'ready') {
      return;
    }
    setNeedToCheckCustomerAddress(false);

    const params = new URLSearchParams(window.location.search);
    let address;
    if (params.has('address')) {
      address = decodeURIComponent(params.get('address') ?? '');
    } else {
      address = `${defaultSettings.customerAddress.address1} ${
        defaultSettings.customerAddress.address2 ?? ''
      } ${defaultSettings.customerAddress.city} ${defaultSettings.customerAddress.state} ${
        defaultSettings.customerAddress.zip
      }`;
    }

    const service = new google.maps.Geocoder();
    void service.geocode(
      {
        address,
      },
      (places, status) => {
        if (status === google.maps.GeocoderStatus.OK && places && places.length > 0) {
          const place = places[0];
          setAddressCoords({
            latitude: place.geometry?.location?.lat(),
            longitude: place.geometry?.location?.lng(),
          });
          setMapCenterCoords({
            latitude: place.geometry?.location?.lat(),
            longitude: place.geometry?.location?.lng(),
          });
          setAddress({
            location_type: 'my_location',
            place_id: 'current_location',
            formatted_address: 'Current Location',
            description: place.formatted_address,
            structured_formatting: {
              main_text: place.formatted_address,
              secondary_text: '',
            },
            geometry: {
              location: {
                lat: () => place.geometry.location.lat(),
                lng: () => place.geometry.location.lng(),
              },
            },
          });
        }
      },
    );
  });

  const { data: fuelRadiusData, isLoading: radiusIsLoading } = useSWRImmutable<
    EndpointResponse<FuelListing[]>
  >(
    !routing &&
      addressCoords &&
      address.place_id != 'map_location' && {
        url: '/fuel_listings',
        params: {
          latitude: addressCoords?.latitude,
          longitude: addressCoords?.longitude,
          radius: selectedRadius * MILES_TO_METERS,
          filter_on_high_flow_diesel_lanes: filterHighFlowDieselLanes,
        },
      },
    getFetcher,
  );

  const { data: fuelBoundsData, isLoading: boundsIsLoading } = useSWRImmutable<
    EndpointResponse<FuelListing[]>
  >(
    !routing &&
      bounds &&
      address.place_id === 'map_location' && {
        url: '/fuel_listings/bounding_box',
        params: {
          ...bounds,
          filter_on_high_flow_diesel_lanes: filterHighFlowDieselLanes,
        },
      },
    getFetcher,
  );

  const { data: routeData, isLoading: routeIsLoading } =
    useSWRImmutable<ComputeRouteWithListingsResponse>(
      routing &&
        addressCoords &&
        destinationCoords && {
          url: '/fuel_listings/compute_route_with_listings',
          params: {
            origin: {
              latitude: addressCoords.latitude,
              longitude: addressCoords.longitude,
            },
            destination: {
              latitude: destinationCoords.latitude,
              longitude: destinationCoords.longitude,
            },
            filter_on_high_flow_diesel_lanes: filterHighFlowDieselLanes,
          },
        },
      noArgPostFetcher,
    );

  // If anything is loading, then we are loading
  // If everything is undefined (like at the start) then we are loading
  // If everything is undefined and we are in routing mode
  // and not all of the coordinates are set, then we are waiting.
  const isFetching =
    radiusIsLoading ||
    boundsIsLoading ||
    routeIsLoading ||
    (routeData == null &&
      fuelBoundsData == null &&
      fuelRadiusData == null &&
      !(routing && (addressCoords == null || destinationCoords == null)));

  const onSelectGrade = (grade: FuelGrade | 'TRUCK DIESEL') => {
    setSelectedGrade(grade);
    setFilterHighFlowDieselLanes(grade === 'TRUCK DIESEL');
  };

  const onSelectRadius = (radius: RadiusOption) => {
    setSelectedRadius(radius);
  };

  const { filteredFuelData, routeCoordinates, defaultRouteRadius } = useSortFuelListings({
    fuelRadiusData,
    fuelBoundsData,
    routing,
    selectedSort,
    selectedGrade,
    addressCoords,
    discountOnly,
    routeData,
    routingDisplayRadius,
  });

  function onSelectFuelListing(fuelListing: FuelListing | null) {
    if (!fuelListing) {
      setSelectedFuelListingId(null);
      setShowMoreButton(false);
      if (!routing && addressCoords != null) {
        gMap?.panTo(new google.maps.LatLng(addressCoords.latitude, addressCoords.longitude));
      }
      return;
    }

    setMapCenterCoords({
      latitude: fuelListing.attributes.location.coordinates.latitude,
      longitude: fuelListing.attributes.location.coordinates.longitude,
    });
    setSelectedFuelListingId(fuelListing.id);
    setShowMoreButton(false);
    gMap?.panTo(
      new google.maps.LatLng(
        fuelListing.attributes.location.coordinates.latitude,
        fuelListing.attributes.location.coordinates.longitude,
      ),
    );
  }

  return (
    <div className="flex h-full flex-grow flex-row bg-white">
      <div className="border-grey16 flex w-full sm:w-1/2 sm:border-r md:w-[55%] xl:w-2/5">
        <div className="flex w-full flex-col gap-4">
          {selectedFuelListingId !== null ? (
            <FuelListingDetail
              isFetching={isFetching}
              originLocation={addressCoords}
              fuelListing={filteredFuelData.find((fuelListing) => {
                return fuelListing.id === selectedFuelListingId;
              })}
              fuelGrade={selectedGrade}
              onSelect={onSelectFuelListing}
            />
          ) : (
            <>
              <div className="border-grey16 hidden border-b px-6 py-4 text-base font-semibold md:block">
                Fuel Map
              </div>
              <FormControl className="gap-2 px-6 pt-4 md:pt-0">
                <div className="space-y-2">
                  <GoogleAutocomplete
                    placesService={placesService}
                    autocompleteValue={address}
                    currentLocation={
                      userLocation
                        ? {
                            location_type: 'my_location',
                            place_id: 'current_location',
                            formatted_address: 'Current Location',
                            address_components: [],
                            description: 'Current Location',
                            structured_formatting: {
                              main_text: 'Current Location',
                              secondary_text: '',
                            },
                            geometry: {
                              location: {
                                lat: () => userLocation.latitude,
                                lng: () => userLocation.longitude,
                              },
                            },
                          }
                        : undefined
                    }
                    selectPlace={(
                      place: google.maps.places.PlaceResult | MyLocation,
                      autocompleteAddress,
                    ) => {
                      const latitude = place?.geometry?.location?.lat();
                      const longitude = place?.geometry?.location?.lng();

                      if (place.place_id === 'current_location') {
                        setOriginAddressIsEligibleForMarker(true);
                      } else {
                        setOriginAddressIsEligibleForMarker(
                          place?.address_components?.some((component) =>
                            component.types.some(
                              (sub_component) => sub_component === 'street_number',
                            ),
                          ) ?? false,
                        );
                      }
                      setAddress(autocompleteAddress);
                      setSelectedFuelListingId(null);

                      if (!latitude || !longitude) {
                        setAddressCoords(undefined);
                        setMapCenterCoords(undefined);
                      } else {
                        setShowMoreButton(false);
                        setFitBounds(true);
                        setAddressCoords({ latitude, longitude });
                        setMapCenterCoords({ latitude, longitude });
                      }
                    }}
                    startAdornment={<OriginStartAdornments routing={routing} />}
                    endAdornment={
                      <OriginEndAdornments
                        isDisabled={!placesService || isFetching}
                        routing={routing}
                        onCancelRoutingClick={() => {
                          setRouting(false);
                          setDestinationCoords(undefined);
                          setDestinationAddress(EMPTY_LOCATION);
                        }}
                        onRoutingClick={() => {
                          setRouting(true);
                          setRoutingDisplayRadius(null);
                        }}
                      />
                    }
                  />
                  {routing && (
                    <GoogleAutocomplete
                      label="Destination"
                      placesService={placesService}
                      autocompleteValue={destinationAddress}
                      selectPlace={(
                        place: google.maps.places.PlaceResult | MyLocation,
                        autocompleteAddress,
                      ) => {
                        const latitude = place?.geometry?.location?.lat();
                        const longitude = place?.geometry?.location?.lng();

                        if (!latitude || !longitude) {
                          setDestinationCoords(undefined);
                        } else {
                          setDestinationCoords({ latitude, longitude });
                          setDestinationAddress(autocompleteAddress);
                        }
                      }}
                      startAdornment={<OriginStartAdornments routing={routing} destination />}
                      endAdornment={
                        <OriginEndAdornments
                          isDisabled={!placesService || isFetching}
                          routing={routing}
                          destination
                          onCancelRoutingClick={() => {
                            const oldAddress = { ...address };
                            const oldAddressCoords =
                              addressCoords != null ? { ...addressCoords } : undefined;
                            const oldDestination = { ...destinationAddress };
                            const oldDestinationCoords =
                              destinationCoords != null ? { ...destinationCoords } : undefined;
                            setDestinationCoords(oldAddressCoords);
                            setDestinationAddress(oldAddress);
                            setAddress(oldDestination);
                            setAddressCoords(oldDestinationCoords);
                          }}
                          onRoutingClick={() => {
                            setRouting(true);
                            setRoutingDisplayRadius(null);
                          }}
                        />
                      }
                    />
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex w-full flex-col gap-2 md:flex-row">
                    <DiscountNetworkSelect
                      disabled={!placesService || isFetching || routing}
                      selected={routing ? false : discountOnly}
                      onSelectDiscountNetwork={setDiscountOnly}
                    />
                    {/* <BrandFilterSelect
                      onDiscountToggle={setDiscountOnly}
                      discountOnly={discountOnly}
                      onBrandsSelect={setBrandIDs}
                      selectedBrandIDs={brandIDs}
                      isDisabled={!placesService || isFetching}
                    /> */}
                    <GradeSelect
                      disabled={!placesService || isFetching}
                      selectedGrade={selectedGrade}
                      onSelectGrade={onSelectGrade}
                    />
                    {!routing && (
                      <RadiusSelect
                        disabled={
                          !placesService || isFetching || address.place_id === 'map_location'
                        }
                        selectedRadius={selectedRadius}
                        onSelectRadius={onSelectRadius}
                      />
                    )}
                  </div>
                  <div className="flex w-full justify-between">
                    <div>
                      {!isFetching ? (
                        <>
                          <p className="text-sm font-medium">Results</p>
                          <p className="text-xs font-normal">
                            {filteredFuelData.length} {routing ? 'Cheapest ' : ''} Merchants
                          </p>
                        </>
                      ) : (
                        <>
                          <Skeleton className="w-16 text-sm" />
                          <Skeleton className="w-20 text-xs" />
                        </>
                      )}
                    </div>
                    <div className="flex">
                      <SortSelect
                        disabled={!placesService || isFetching || routing}
                        selectedSort={routing ? 'discountedPrice' : selectedSort}
                        onSelectSort={setSelectedSort}
                      />
                    </div>
                  </div>
                </div>
              </FormControl>
              <FuelListingsList
                isFetching={isFetching}
                originLocation={addressCoords}
                fuelListings={filteredFuelData}
                selectedGrade={selectedGrade}
                onSelect={onSelectFuelListing}
                showMore={
                  routing
                    ? () => {
                        setFitBounds(true);
                        setShowMoreButton(false);
                        let newRadius: number = 0;
                        if (routing && addressCoords != null) {
                          newRadius = (routingDisplayRadius || defaultRouteRadius || 0) / 2;
                        }
                        setRoutingDisplayRadius(newRadius);
                      }
                    : null
                }
              />
            </>
          )}
        </div>
      </div>
      <div className="hidden w-0 sm:relative sm:block sm:w-1/2 md:w-[45%] xl:w-3/5">
        <FuelListingsMap
          GOOGLE_MAPS_API_KEY={GOOGLE_MAPS_API_KEY}
          MAP_ID={MAP_ID}
          status={gmapScriptStatus}
          onMapLoad={(map: google.maps.Map) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const placesService = new (window as any).google.maps.places.PlacesService(map);
            setPlacesService(placesService);
            setGMap(map);
          }}
          onMapChange={({ latitude, longitude }) => {
            if (routing) return;
            if (!latitude || !longitude) {
              setMapCenterCoords(undefined);
            } else {
              setMapCenterCoords({ latitude, longitude });
            }

            setSelectedFuelListingId(null);
            setShowMoreButton(true);
          }}
          fitBounds={fitBounds}
          onSelect={onSelectFuelListing}
          selectedFuelListingId={selectedFuelListingId}
          selectedGrade={selectedGrade}
          mapCenterCoords={mapCenterCoords}
          isFetching={isFetching}
          fuelListings={filteredFuelData}
          routeCoordinates={routeCoordinates}
          showCenterMarker={destinationCoords === undefined && originAddressIsEligibleForMarker}
          addressCoords={addressCoords}
        />
        {showMoreButton && !routing && (
          <div className="absolute left-1/2 top-8 -translate-x-1/2">
            <SearchMoreButtonFixed
              onFetchMore={() => {
                const bounds = gMap?.getBounds();
                if (bounds) {
                  setBounds({
                    top_left_longitude: bounds.getNorthEast().lng(),
                    top_left_latitude: bounds.getNorthEast().lat(),
                    bottom_right_longitude: bounds.getSouthWest().lng(),
                    bottom_right_latitude: bounds.getSouthWest().lat(),
                  });
                }
                setOriginAddressIsEligibleForMarker(false);
                mapCenterCoords && setAddress(mapLocationGenerator(mapCenterCoords));
                setAddressCoords(undefined);
                setFitBounds(false);
                setShowMoreButton(false);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FuelListings;
