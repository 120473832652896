import { InputType } from '../components/constants';
import { formatPhoneNumber } from './PhoneUtils';

export const formatInput = (value: string, inputType?: InputType) => {
  switch (inputType) {
    case 'tel':
      return formatPhoneNumber(value);
    default:
      return value;
  }
};
