import CardHeader from '@app/components/CardDetails/CardHeader';
import EditSpendLimitsModal from '@app/components/Modals/CardModals/EditSpendLimitsModal';
import PriceDetailRow from '@app/components/PriceDetailRow/PriceDetailRow';
import { AssignTagSidebar } from '@app/components/Sidebars/AssignTagSidebar/AssignTagSidebar';
import TagBadge from '@app/components/TagBadge/TagBadge';
import CardSpendingLimitsContext from '@app/contexts/cardSpendingLimitsContext';
import useProduct from '@app/hooks/useProduct';
import Card from '@atob-developers/shared/src/components/Card';
import { CustomTooltip } from '@atob-developers/shared/src/components/Tooltip';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { faBars } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, IconButton, Menu, MenuItem } from '@mui/material';
import axios from 'axios';
import { ReactElement, useMemo, useState } from 'react';
import EditSpendLimitsButton from './EditSpendLimitsButton';
import TagTooltip from './TagTooltip';

import type { CardData } from '@app/@types/card.types';
import type { WithResourceTag } from '@app/@types/tag.types';

const SpendingLimitsTag = ({
  data,
  showTagSidebar,
  removeTag,
}: {
  data: CardData & WithResourceTag;
  allowSetSpendLimit: boolean;
  removeTag: () => void;
  showTagSidebar: () => void;
}): ReactElement | null => {
  const [hasResourceTags] = useProduct('resource_tags');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  if (!hasResourceTags) {
    return null;
  }

  return (
    <>
      <div className="flex justify-end gap-3 px-2">
        <TagBadge
          tag={{
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            name: data.resource_tag.tag_name,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            tag_id: data.resource_tag.tag_id.toString(),
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            resource_tag_id: data.resource_tag.id.toString(),
          }}
          truncate={false}
        />
        <CustomTooltip>
          <TagTooltip />
        </CustomTooltip>
        <IconButton
          size="extra-small"
          onClick={(e) => setAnchorEl(e.currentTarget)}
          style={{ borderRadius: 0 }}
        >
          <FontAwesomeIcon icon={faBars} className="h-5 w-5" />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem
            onClick={() => {
              showTagSidebar();
              setAnchorEl(null);
            }}
          >
            Edit Tag
          </MenuItem>
          <MenuItem
            onClick={() => {
              removeTag();
              setAnchorEl(null);
            }}
          >
            Remove Tag
          </MenuItem>
        </Menu>
      </div>
    </>
  );
};

const SpendingLimitsCardContent = ({
  data,
  allowSetSpendLimit,
  refreshCard,
}: {
  data: CardData & WithResourceTag;
  allowSetSpendLimit: boolean;
  refreshCard: () => void;
}): ReactElement => {
  const { addToast } = useToasts();
  const [hasTags] = useProduct('resource_tags');
  const [showAssignTag, setShowAssignTag] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [perTransactionLimit, setPerTransactionLimit] = useState<string>(
    data?.per_transaction_limit?.split('.')[0] ?? '',
  );
  const [dailyTransactionLimit, setDailyTransactionLimit] = useState<string>(
    data?.daily_spend_limit?.split('.')[0] ?? '',
  );
  const [weeklyTransactionLimit, setWeeklyTransactionLimit] = useState<string>(
    data?.weekly_spend_limit?.split('.')[0] ?? '',
  );
  const showEditSpendLimitsButton = allowSetSpendLimit && !data.resource_tag;

  const spendingLimitValue = useMemo(
    () => ({
      perTransactionLimit,
      dailyTransactionLimit,
      weeklyTransactionLimit,
      setPerTransactionLimit,
      setDailyTransactionLimit,
      setWeeklyTransactionLimit,
    }),
    [dailyTransactionLimit, perTransactionLimit, weeklyTransactionLimit],
  );
  return (
    <CardSpendingLimitsContext.Provider value={spendingLimitValue}>
      <div className="flex flex-col gap-2 px-2 pb-4 pt-2">
        <PriceDetailRow
          title="Per transaction"
          balance={perTransactionLimit ? `$${perTransactionLimit}` : '-'}
        />
        <PriceDetailRow
          title="Daily"
          balance={dailyTransactionLimit ? `$${dailyTransactionLimit}` : '-'}
        />
        <PriceDetailRow
          title="Weekly"
          balance={weeklyTransactionLimit ? `$${weeklyTransactionLimit}` : '-'}
        />
      </div>
      {data.resource_tag && (
        <>
          <SpendingLimitsTag
            {...{ data, allowSetSpendLimit }}
            showTagSidebar={() => setShowAssignTag(true)}
            removeTag={() => {
              axios
                .delete(`/resource_tags/bulk`, {
                  data: {
                    resource_tags: {
                      resource_tag_ids: [data.resource_tag?.id],
                      resource_type: 'Card',
                    },
                  },
                })
                .then(() => {
                  refreshCard();
                  setShowAssignTag(false);
                });
            }}
          />
        </>
      )}
      {showEditSpendLimitsButton && (
        <>
          <div className="sm:mb-2 sm:mt-1">
            <EditSpendLimitsButton onClick={() => setShowModal(true)} />
            {hasTags && (
              <Button
                className="mt-2"
                color="secondary"
                size="small"
                onClick={() => setShowAssignTag(true)}
                fullWidth
              >
                Add Tag
              </Button>
            )}
          </div>
          <EditSpendLimitsModal card={data} open={showModal} toggle={() => setShowModal(false)} />
        </>
      )}
      {showAssignTag && (
        <AssignTagSidebar
          open={showAssignTag}
          setOpen={setShowAssignTag}
          onSaveTagClick={(tagID: number) => {
            return axios
              .post('/resource_tags/bulk', {
                resource_tags: {
                  tag_id: tagID,
                  resource_ids: [data.id],
                  resource_type: 'Card',
                },
              })
              .then(() => {
                refreshCard();
                setShowAssignTag(false);
              })
              .catch(() => {
                refreshCard();
                addToast('Error assigning tags', {
                  appearance: 'error',
                });
              });
          }}
          selectedID={data.resource_tag?.tag_id ?? null}
        />
      )}
    </CardSpendingLimitsContext.Provider>
  );
};

const SpendingLimitsCard = ({
  data,
  allowSetSpendLimit,
  refreshCard,
}: {
  data: CardData;
  allowSetSpendLimit: boolean;
  refreshCard: () => void;
}): ReactElement => {
  return (
    <>
      <div className="triumph:hidden block sm:hidden">
        <CardHeader title="Spend limits" />
        <SpendingLimitsCardContent
          data={data}
          allowSetSpendLimit={allowSetSpendLimit}
          refreshCard={refreshCard}
        />
      </div>
      <div className="triumph:block hidden h-full sm:block">
        <Card>
          <CardHeader title="Spend limits" />
          <SpendingLimitsCardContent
            data={data}
            allowSetSpendLimit={allowSetSpendLimit}
            refreshCard={refreshCard}
          />
        </Card>
      </div>
    </>
  );
};

export default SpendingLimitsCard;
