import { BankAccountPaymentMethodCombined } from '@app/@types/bankAccount.types';
import { DebitCardPaymentMethodCombined } from '@app/@types/debitCard.types';
import { TpayAccountPaymentMethod } from '@app/@types/payments.types';
import DebitCardModal from '@app/components/PaymentMethods/DebitCardModal';
import { BankAccountSidebar } from '@app/components/Sidebars/PaymentMethodSidebars/BankAccountSidebar';
import { DebitCardSidebar } from '@app/components/Sidebars/PaymentMethodSidebars/DebitCardSidebar';
import { useExtendMobileHeaderContextMenu } from '@app/contexts/MobileNavHeaderMenuContextComponent';
import { PaymentHistoryContext } from '@app/contexts/paymentHistoryContext';
import { Button } from '@mui/material';
import { capitalize } from 'lodash-es';
import { ReactElement, useContext, useMemo, useState } from 'react';
import Plaid from '../../components/Plaid';
import {
  BankAccountListItem,
  DebitCardListItem,
  TpayAccountListItem,
  WalletListItem,
} from './PaymentMethod';
import { WalletPaymentMethodInfo } from './PaymentMethods';
import { TpayAccountSidebar } from './TpayAccountSidebar';
import WalletSidebar from './WalletSection';

interface PaymentMethodSectionProps {
  bankAccounts?: BankAccountPaymentMethodCombined[];
  debitCards?: DebitCardPaymentMethodCombined[];
  setError: (e: Error) => void;
  wallet?: WalletPaymentMethodInfo;
  handleRefreshPaymentMethods: () => void;
  onLinkingCallback: (phase: string) => void;
  tpayAccount: TpayAccountPaymentMethod | null;
}

const PaymentMethodSection = ({
  bankAccounts,
  debitCards,
  setError,
  handleRefreshPaymentMethods,
  onLinkingCallback,
  wallet,
  tpayAccount,
}: PaymentMethodSectionProps): ReactElement => {
  const { setType, defaultShowModal } = useContext(PaymentHistoryContext);
  const [showDebitCardModal, setShowDebitCardModal] = useState(defaultShowModal === 'debit_card');

  const [activeSidebar, setActiveSidebar] = useState<
    | {
        type: 'bankAccount';
        data: BankAccountPaymentMethodCombined;
      }
    | {
        type: 'debitCard';
        data: DebitCardPaymentMethodCombined;
      }
    | {
        type: 'wallet';
        data: WalletPaymentMethodInfo;
      }
    | {
        type: 'tpayAccount';
        data: TpayAccountPaymentMethod;
      }
    | null
  >(null);

  const menuItem = useMemo(
    () => ({
      value: 'Connect Debit Card',
      onClick: () => setShowDebitCardModal(true),
      disabled: () => false,
      order: 2,
    }),
    [],
  );

  useExtendMobileHeaderContextMenu(menuItem);

  const handleBankAccountClick = (data: BankAccountPaymentMethodCombined) => {
    setActiveSidebar({
      type: 'bankAccount',
      data: data,
    });
    setType({
      accountType: 'ach_charge',
      accountMask: data.mask,
      institutionName: null,
      id: data.id,
    });
  };

  const handleDebitCardClick = (data: DebitCardPaymentMethodCombined) => {
    setActiveSidebar({
      type: 'debitCard',
      data: data,
    });
    setType({
      accountType: 'debit_card',
      accountMask: data.last_four,
      institutionName: null,
      id: data.id,
    });
  };

  const handleWalletClick = (data: WalletPaymentMethodInfo) => {
    setActiveSidebar({
      type: 'wallet',
      data: data,
    });
  };

  const handleTpayAccountClick = (data: TpayAccountPaymentMethod) => {
    setActiveSidebar({
      type: 'tpayAccount',
      data: data,
    });
  };

  const refreshPaymentMethods = () => {
    handleRefreshPaymentMethods();
    closeSidebar();
  };

  const closeSidebar = () => {
    setActiveSidebar(null);
  };

  return (
    <div className="flex flex-col gap-6 md:gap-8">
      <div className="flex gap-4">
        <Plaid
          setError={setError}
          buttonProps={{
            primary: true,
            size: 'small',
          }}
          text="Add Bank Account"
          onSuccessCallback={refreshPaymentMethods}
          onLinkingCallback={onLinkingCallback}
        />
        <Button size="small" onClick={() => setShowDebitCardModal(true)}>
          Add Debit Card
        </Button>
      </div>
      <div className="flex w-full flex-wrap gap-6">
        {tpayAccount && (
          <TpayAccountListItem
            data={tpayAccount}
            openHandler={handleTpayAccountClick}
            key={tpayAccount.id}
          />
        )}
        {wallet && <WalletListItem data={wallet} openHandler={handleWalletClick} key={wallet.id} />}
        {bankAccounts?.map((bankAccount) => (
          <BankAccountListItem
            data={bankAccount}
            openHandler={handleBankAccountClick}
            key={bankAccount.id}
          />
        ))}
        {debitCards?.map((debitCard) => (
          <DebitCardListItem
            data={debitCard}
            openHandler={handleDebitCardClick}
            key={debitCard.id}
          />
        ))}
      </div>
      {activeSidebar && activeSidebar.type === 'bankAccount' && (
        <BankAccountSidebar
          title={activeSidebar.data?.name}
          open={!!activeSidebar.data?.id}
          setOpen={closeSidebar}
          account={activeSidebar.data}
          showRemoveBankButton={
            !!bankAccounts && bankAccounts.length > 0 && !activeSidebar.data?.is_primary
          }
          handleRefreshPaymentMethods={refreshPaymentMethods}
          onSuccessCallback={refreshPaymentMethods}
          onLinkingCallback={onLinkingCallback}
        />
      )}
      {activeSidebar && activeSidebar.type === 'debitCard' && (
        <DebitCardSidebar
          title={activeSidebar.data?.brand && `${capitalize(activeSidebar.data.brand)} Debit Card`}
          open={!!activeSidebar.data?.id}
          setOpen={closeSidebar}
          card={activeSidebar.data}
          handleRefreshPaymentMethods={refreshPaymentMethods}
        />
      )}
      {activeSidebar && activeSidebar.type === 'wallet' && (
        <WalletSidebar
          open={true}
          toggle={closeSidebar}
          title="Wallet"
          data={activeSidebar.data}
          handleRefreshPaymentMethods={refreshPaymentMethods}
        />
      )}
      {activeSidebar && activeSidebar.type === 'tpayAccount' && (
        <TpayAccountSidebar
          data={activeSidebar.data}
          toggle={closeSidebar}
          open={!!activeSidebar?.data?.id}
          handleRefreshPaymentMethods={refreshPaymentMethods}
        />
      )}
      <div className="level-right flex-col items-end">
        <DebitCardModal isActive={showDebitCardModal} setIsActive={setShowDebitCardModal} />
      </div>
    </div>
  );
};

export default PaymentMethodSection;
