import { Pagination, CircularProgress } from '@mui/material';
import { GridValidRowModel } from '@mui/x-data-grid-pro';
import { ResponsiveTableProps } from './ResponsiveTable';

export default function MobileListView<T extends GridValidRowModel>({
  data,
  mobileItemRenderer,
  onRowClick,
  onPageChange,
  paginationMeta,
  loading,
}: ResponsiveTableProps<T>) {
  if (!loading && paginationMeta?.pagination?.total_pages === 0) {
    return <div className="m-8 flex justify-center text-sm text-gray-500">Nothing to show</div>;
  }

  if (loading) {
    return (
      <div className="m-4 flex justify-center" data-testid="spinner">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-col space-y-2">
        {data?.map((rowData, rowIndex) => (
          <div key={rowIndex} onClick={() => onRowClick?.(rowData)}>
            {mobileItemRenderer({ row: rowData })}
          </div>
        ))}
      </div>
      {paginationMeta?.pagination && (
        <div className="flex justify-center pb-6">
          <Pagination
            count={paginationMeta?.pagination?.total_pages || 0}
            page={paginationMeta?.pagination?.current_page || 1}
            onChange={(_, page) => onPageChange?.(page)}
          />
        </div>
      )}
    </div>
  );
}
