import { PaymentMethodCombined } from '@app/@types/payments.types';
import { PayrollDriverState } from '@app/@types/payroll.types';
import { SequentialStep, StepStatus, Steps } from '@app/components/layout/Steps';
import { ReactElement, useContext, useMemo } from 'react';
import { isDebitCard } from '../../components/PaymentMethods/PaymentMethodUtils';
import PayrollContext from './payroll-context';

enum WelcomeStep {
  STEP_ONE = '01',
  STEP_TWO = '02',
  STEP_THREE = '03',
  STEP_FOUR = '04',
  COMPLETE = 'complete',
}

const steps: SequentialStep[] = [
  {
    id: WelcomeStep.STEP_ONE,
    name: 'Classify your drivers as 1099 or W2',
    status: StepStatus.UPCOMING,
    description:
      'We are only supporting 1099 workers right now. Add any driver not already imported below.',
  },
  {
    id: WelcomeStep.STEP_TWO,
    name: 'Invite drivers to DriverPay',
    status: StepStatus.UPCOMING,
    description:
      // Emails will come from AtoB's email address, so we keep the explicit branding
      // eslint-disable-next-line no-explicit-atob-mentions
      'Your drivers will get a text or email from AtoB asking them to set up their payroll account.',
  },
  {
    id: WelcomeStep.STEP_THREE,
    name: `Select a debit card below as payment source`,
    status: StepStatus.UPCOMING,
    description:
      'Connect your business debit card as the payment source. Choose the card which has the maximum daily spend limit.',
  },
  {
    id: WelcomeStep.STEP_FOUR,
    name: `Pay your drivers instantly`,
    status: StepStatus.UPCOMING,
    description:
      'Once the driver account is active, add an amount and hit Pay Now to send money within minutes!',
  },
];

const getStepToTake = (
  paymentSource: PaymentMethodCombined | null,
  driverState: PayrollDriverState,
): WelcomeStep => {
  const everyDriver = Object.keys(driverState).map((key) => driverState[key]);

  const doesNotHaveClassifiedDrivers = everyDriver.every(
    (driver) => driver.tax_classification === null,
  );

  if (doesNotHaveClassifiedDrivers) {
    return WelcomeStep.STEP_ONE;
  }

  const doesNotHaveInvitedDrivers = everyDriver.every((driver) => driver.status === 'not_enrolled');

  if (doesNotHaveInvitedDrivers) {
    return WelcomeStep.STEP_TWO;
  }
  const hasNotSentAPayment = everyDriver.every((driver) => !driver.last_paid_amount);

  const doesNotHaveCard = !paymentSource || !isDebitCard(paymentSource);
  if (doesNotHaveCard && hasNotSentAPayment) {
    return WelcomeStep.STEP_THREE;
  }

  if (hasNotSentAPayment) {
    return WelcomeStep.STEP_FOUR;
  }

  return WelcomeStep.COMPLETE;
};

const makeRenderedSteps = (id: string) => {
  let currentStepFound = false;
  return steps.map((step): SequentialStep => {
    if (step.id === id) {
      currentStepFound = true;
      return { ...step, status: StepStatus.CURRENT };
    }

    if (!currentStepFound) {
      return { ...step, status: StepStatus.COMPLETE };
    }

    return step;
  });
};

export default function Welcome({
  paymentSource,
}: {
  paymentSource: PaymentMethodCombined | null;
}): ReactElement | null {
  const { driverState, loaded } = useContext(PayrollContext);
  const stepToTake = useMemo(
    () => getStepToTake(paymentSource, driverState),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paymentSource, driverState, loaded],
  );

  if (!loaded) {
    return null;
  }

  if (stepToTake === WelcomeStep.COMPLETE) {
    return null;
  }

  return (
    <div className="mb-10">
      <Steps hideArrowSeparator steps={makeRenderedSteps(stepToTake)} />
    </div>
  );
}
