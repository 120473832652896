import { SettingsCurrentValue } from '@app/components/Cards/Settings/SettingsCurrentValue';
import { SettingsPageEntityType } from '@app/constants/settings';
import { SETTINGS_FORM_DATA, SETTINGS_ROW_OPTIONS } from '@app/constants/settingsRow';
import { IAddressProps } from '@app/pages/Settings/SettingsPageRow';
import {
  DropdownFormElement,
  InputFormElement,
} from '@atob-developers/shared/src/@types/form.types';
import { DataItemType } from '@atob-developers/shared/src/components/DataItem';
import FormElement from '@atob-developers/shared/src/components/FormElement';
import classNames from 'classnames';
import React, { ReactElement } from 'react';

interface StateOptions {
  id: string | number;
  option: string;
}
const renderSelectedItem = (item: StateOptions) => {
  return <div key={item.id}>{item.option}</div>;
};

interface AddressFormElementProps {
  rowElement:
    | InputFormElement
    | InputFormElement[]
    | DropdownFormElement<StateOptions>
    | DropdownFormElement<StateOptions>[];
  values: Record<string, string | null>;
  errors: Record<string, string | undefined>;
  setValues: React.Dispatch<React.SetStateAction<unknown>>;
  setErrors: (erros: Record<string, string | undefined>) => void;
}

const AddressFormElement = ({
  rowElement,
  values,
  errors,
  setValues,
  setErrors,
}: AddressFormElementProps) => {
  const row = !Array.isArray(rowElement) ? [rowElement] : rowElement;

  return (
    <div className="flex flex-row gap-3">
      {row.map((item, idx) => (
        <div
          key={`container-${idx}`}
          className={classNames('', {
            'basis-full': !item?.basis && row.length === 1,
          })}
        >
          <FormElement
            key={`company-address-arr-${item.key}`}
            element={{
              ...item,
              key: item.key,
              renderSelectedItem: renderSelectedItem,
              children: renderSelectedItem,
            }}
            value={
              item.type === DataItemType.DROPDOWN
                ? (item as DropdownFormElement<StateOptions>)?.options?.find(
                    (i) => i.option === values?.[item.key],
                  ) || ''
                : values?.[item.key] || ''
            }
            error={errors[item.key]}
            handleOnChange={(val) => {
              setErrors({ ...errors, [item.key]: undefined });
              setValues({
                ...values,
                [item.key]:
                  item.type === DataItemType.DROPDOWN ? (val as StateOptions).option : val,
              });
            }}
          />
        </div>
      ))}
    </div>
  );
};

interface AddressFormProps {
  type: SettingsPageEntityType.COMPANY_ADDRESS | SettingsPageEntityType.CARD_SHIPPING_ADDRESS;
  values: Record<string, string | null>;
  errors: Record<string, string | undefined>;
  setValues: (values: Record<string, string | null>) => void;
  setErrors: (erros: Record<string, string | undefined>) => void;
  currentAddress: IAddressProps;
}

export const AddressForm = ({
  type,
  values,
  errors,
  setErrors,
  setValues,
  currentAddress,
}: AddressFormProps): ReactElement => {
  const { company_address } = SETTINGS_FORM_DATA;

  return (
    <div className="overflow-visible">
      <div className="bg-grey14 xs:block order-1 my-6 hidden flex-none flex-grow-0 space-y-1.5 self-stretch rounded-lg p-5">
        <p className="flex items-center text-xs font-normal uppercase not-italic leading-6 text-gray-600">
          {`current ${SETTINGS_ROW_OPTIONS[type].title}`}
        </p>
        <div className="flex items-center text-base font-semibold not-italic leading-6 text-gray-900">
          <SettingsCurrentValue type={type} value={currentAddress} />
        </div>
      </div>
      <div className="flex flex-col">
        {company_address.map((item, idx) => (
          <AddressFormElement
            rowElement={item}
            key={`container-${idx}`}
            values={values}
            errors={errors}
            setErrors={setErrors}
            setValues={setValues as React.Dispatch<React.SetStateAction<unknown>>}
          />
        ))}
      </div>
    </div>
  );
};
