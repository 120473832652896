import { ReactElement, ReactNode } from 'react';

const MobileListItem = ({ children }: { children: ReactNode }): ReactElement => {
  return (
    <div className="rounded-card border-grey5 rounded-md border bg-white p-4 text-sm ">
      {children}
    </div>
  );
};

export { MobileListItem };
