import Constants from '@app/utils/constants';
import { Chip, ChipTypeMap } from '@mui/material';
import { ReactElement } from 'react';

type UiFriendlyStatusTypes =
  | 'ACTIVE'
  | 'PENDING'
  | 'UPDATE REQUIRED'
  | 'NOT ENROLLED'
  | 'UNKNOWN'
  | 'ARCHIVED';

type StatusTypes =
  | 'invited'
  | 'enrolled'
  | 'not_enrolled'
  | 'update_required'
  | 'unknown'
  | 'archived'
  | null
  | undefined;

const getPayrollEmployeeStatusName = (
  status: StatusTypes,
  taxClassification?: string | null,
): [{ color: ChipTypeMap['props']['color'] }, UiFriendlyStatusTypes] => {
  if (status === 'archived') {
    return [{ color: 'default' }, 'ARCHIVED'];
  }

  const isW2Employee = taxClassification === Constants.PayrollEnums.W2;
  if (isW2Employee) {
    return [{ color: 'default' }, 'NOT ENROLLED'];
  }

  switch (status) {
    case Constants.PayrollEnums.ENROLLED:
      return [{ color: 'success' }, 'ACTIVE'];
    case Constants.PayrollEnums.INVITED:
      return [{ color: 'warning' }, 'PENDING'];
    case Constants.PayrollEnums.UPDATE_REQUIRED:
      return [{ color: 'error' }, 'UPDATE REQUIRED'];
    default:
      return [{ color: 'default' }, 'NOT ENROLLED'];
  }
};

const StatusBadge = ({
  status,
  taxClassification,
}: {
  status: StatusTypes;
  taxClassification: string | undefined | null;
}): ReactElement => {
  const [{ color }, label] = getPayrollEmployeeStatusName(status, taxClassification);
  return <Chip label={label} color={color} size="small" />;
};

export default StatusBadge;
