import { MerchantData } from '@app/@types/spend_restriction.types';
import { ReactElement } from 'react';
export const MerchantRestrictionList = ({
  merchants,
  removeMerchantRestriction,
  emptyMessage,
}: {
  merchants: MerchantData[];
  removeMerchantRestriction: (merchantID: string) => void;
  emptyMessage: string;
}): ReactElement => {
  return (
    <div>
      {merchants?.length === 0 && <p className="mb-5 text-[14px] text-gray-500">{emptyMessage}</p>}
      <div className="mb-4 text-sm text-gray-700">
        {merchants?.map((m) => (
          <div className="mt-2 flex flex-row" key={m.id}>
            <div className="basis-4/5">
              <p>{m.name}</p>
              <p className="text-[13px] text-gray-500">{m.display_address}</p>
            </div>
            <button
              className="flex basis-1/5 items-center no-underline hover:underline"
              onClick={() => removeMerchantRestriction(m.id)}
            >
              Remove
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
