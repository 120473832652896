import { ELDProvider } from '@app/@types/eld-connect.types';
import { ReactElement } from 'react';
import ConnectFormModal from './ConnectFormModal';
import ConnectOauthModal from './ConnectOauthModal';

interface ConnectModalProps {
  provider: ELDProvider;
  closeModal: () => void;
}

const ConnectModal = (props: ConnectModalProps): ReactElement => {
  switch (props.provider.auth_flow) {
    case 'form':
      return <ConnectFormModal {...props} />;
    case 'oauth':
      return <ConnectOauthModal {...props} />;
  }
};

export default ConnectModal;
