import { ReactElement, ReactNode } from 'react';

export default function OnboardingRightUpsell({
  children,
}: {
  children: ReactNode;
}): ReactElement | null {
  return (
    <div
      className={
        'bg-onboarding-wavy fixed right-0 top-20 hidden h-[calc(100%-5rem)] w-1/2 bg-[rgba(255,255,255,0.5)] bg-opacity-50 bg-blend-lighten md:block'
      }
    >
      <div className="flex h-full w-full items-center justify-center">{children}</div>
    </div>
  );
}
