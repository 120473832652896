import Header from '@app/components/Navigation/Header';
import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import PageContentWrapper from '@app/components/wrappers/PageContentWrapper';
import { useFleetioConnectionQuery } from '@app/hooks/query/useFleetioConnectionQuery';
import { ReactElement, useEffect, useState } from 'react';
import FleetioConnectForm from './FleetioConnectForm';
import FleetioConnected from './FleetioConnected';

export default function Fleetio(): ReactElement {
  const [fleetioConnection, setFleetioConnection] = useState<{
    account_token: string;
  } | null>(null);

  const { isLoading, data, fleetioConnected } = useFleetioConnectionQuery();

  useEffect(() => {
    if (data) {
      setFleetioConnection(data);
    }
  }, [data]);

  return (
    <PageContentWrapper header={<Header title="Fleetio" />}>
      {isLoading && <SpinnerBoundary />}
      {!isLoading && (
        <>
          {fleetioConnected ? (
            <FleetioConnected
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              setFleetioConnection={setFleetioConnection}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              accountToken={fleetioConnection?.account_token}
            />
          ) : (
            <FleetioConnectForm
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              setFleetioConnection={setFleetioConnection}
            />
          )}
        </>
      )}
    </PageContentWrapper>
  );
}
