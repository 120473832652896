import useCustomer from '@app/hooks/useCustomer';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { groupBy } from 'lodash-es';
import { ReactElement } from 'react';
import OnboardingForm from './OnboardingForm';
import { STEP_MAP } from './OnboardingStepMap';
import { useOnboardingGuide } from './useOnboardingGuide';

export default function OnboardingBankStep(): ReactElement | null {
  const nextStep = useOnboardingGuide();
  const { interstitials } = useCustomer();

  if (interstitials == null) {
    return null;
  }

  const [bigTitle, ...littleTitles] = interstitials.title;

  const groups = groupBy(interstitials.steps, ({ grouping }) => grouping);

  return (
    <OnboardingForm centered fullWidth>
      <h1 className="text-2xl font-semibold tracking-[-.48px]">{bigTitle}</h1>
      {littleTitles.map((t) => (
        <p key={t} className="text-base tracking-[-.48px]">
          {t}
        </p>
      ))}
      <div className="max-w-1/3 flex flex-col rounded-xl border px-4 md:w-1/3">
        {Object.values(groups)
          .filter(
            (group) => group && STEP_MAP[group[0].name] && STEP_MAP[group[0].name].blurb != null,
          )
          .map((group, idx) => (
            <div className="flex flex-row items-center gap-4 border-b py-4 last:border-0" key={idx}>
              <FontAwesomeIcon
                icon={faCircleCheck}
                size="lg"
                className={
                  group?.every((item) => item.status) ? 'text-atob-green' : 'text-gray-300'
                }
              />
              {STEP_MAP[(group as Array<{ name: string }>)[0].name].blurb}
            </div>
          ))}
      </div>
      <Button color="primary" onClick={nextStep}>
        Get Started
      </Button>
    </OnboardingForm>
  );
}
