import { EndpointResponse } from '@app/@types/api.types';
import { FuelCardAccountData } from '@app/@types/fuel-card-account.types';
import { usePaymentMethodsQuery } from '@app/hooks/query/usePaymentMethodsQuery';
import useProduct from '@app/hooks/useProduct';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import { ReactElement } from 'react';
import useSWR from 'swr';
import {
  getPaymentMethodName,
  isPrimary,
} from '../../components/PaymentMethods/PaymentMethodUtils';
import SpinnerBoundary from '../../components/Spinner/SpinnerBoundary';
import { ErrorNotification } from '../../components/layout';
import BalanceCard from './BalanceCard';
import BusinessCreditReportNotification from './BusinessCreditReportNotification';
import CreditOverviewCard from './CreditOverviewCard';
import useScheduledBillingPayments from './useScheduledBillingPayments';

import type { CustomerData, Treasury } from '@app/@types/customer.types';

interface AccountOverviewCardsProps {
  hasWallet: boolean;
  companyName: string;
  treasury: Treasury | null;
  customer: CustomerData;
}

const DEFAULT_AMOUNT = '$0.00';

const CreditAccountOverviewCards = ({
  hasWallet,
  companyName,
  treasury,
  customer,
}: AccountOverviewCardsProps): ReactElement => {
  const {
    isLoading: isFetching,
    error: fuelCardError,
    data: fuelCardAccountData,
    mutate: fetchFuelCardAccountData,
  } = useSWR<EndpointResponse<FuelCardAccountData>>({ url: '/fuel_card_account' }, apiGetFetcher);

  const [hasSettingsProduct] = useProduct('settings');

  const { error: scheduledBillingPaymentsError, data: upcomingPayment } =
    useScheduledBillingPayments();

  const { error: paymentMethodsError, data: paymentMethods } = usePaymentMethodsQuery();

  const primaryPaymentMethod = paymentMethods?.data.find(isPrimary);
  const primaryPaymentMethodName = primaryPaymentMethod
    ? getPaymentMethodName(primaryPaymentMethod)
    : undefined;

  if (fuelCardError || scheduledBillingPaymentsError || paymentMethodsError) {
    return (
      <ErrorNotification
        error={fuelCardError || scheduledBillingPaymentsError || paymentMethodsError}
        generic
      />
    );
  }

  return (
    <div className="relative flex flex-col space-y-6">
      {hasSettingsProduct && <BusinessCreditReportNotification />}
      <div className="relative">
        {isFetching && <SpinnerBoundary />}
        <CreditOverviewCard
          periodSpentLimit={fuelCardAccountData?.data.period_spend_limit || DEFAULT_AMOUNT}
          periodAvailableAmount={
            fuelCardAccountData?.data.period_available_amount || DEFAULT_AMOUNT
          }
          potentialDiscountAmount={
            fuelCardAccountData?.data.potential_discount_amount || DEFAULT_AMOUNT
          }
          currentWeekSpend={fuelCardAccountData?.data.period_spend_amount || DEFAULT_AMOUNT}
          pendingSpendAmount={fuelCardAccountData?.data.pending_spend_amount || DEFAULT_AMOUNT}
          periodEndDate={fuelCardAccountData?.data.current_period_end_date || ''}
          periodStartDate={fuelCardAccountData?.data.current_period_start_date || ''}
          discountMetadata={fuelCardAccountData?.data.discount}
          discountAmount={fuelCardAccountData?.data.discount_amount || ''}
          lifetimeDiscountAmount={fuelCardAccountData?.data.lifetime_discount_amount || ''}
          lifetimeAverageDiscountPerGallon={
            fuelCardAccountData?.data.lifetime_average_discount_per_gallon || ''
          }
          fetchFuelCardAccountData={fetchFuelCardAccountData}
          companyName={companyName}
          treasury={treasury}
        />
      </div>
      <div className="relative">
        {isFetching && <SpinnerBoundary />}
        <BalanceCard
          scheduledPaymentAmount={
            fuelCardAccountData?.data.scheduled_payment_amount || DEFAULT_AMOUNT
          }
          paymentMethod={primaryPaymentMethodName}
          upcomingPaymentDate={fuelCardAccountData?.data.scheduled_payment_date ?? ''}
          upcomingPaymentStartDate={
            fuelCardAccountData?.data.scheduled_payment_period_start_date ?? ''
          }
          upcomingPaymentEndDate={fuelCardAccountData?.data.scheduled_payment_period_end_date ?? ''}
          upcomingPayment={upcomingPayment}
          accountBalance={fuelCardAccountData?.data.account_balance || DEFAULT_AMOUNT}
          hasWallet={hasWallet}
          netTerms={fuelCardAccountData?.data.net_terms || ''}
          customer={customer}
        />
      </div>
    </div>
  );
};

export default CreditAccountOverviewCards;
