import { DriverData } from '@app/@types/driver.types';
import ArchiveDriverModal from '@app/pages/Payroll/Table/ArchiveDriverModal';
import { SideBarFooter } from '@atob-developers/shared/src/components/SideBar';
import { Button } from '@mui/material';
import { ReactElement, useState } from 'react';
import PreviewDriverSidebarBody from './PreviewDriverSidebarBody';

interface PreviewDriverSidebarProps {
  setOpen: (val: boolean) => void;
  driver: DriverData;
  onDriverUpdated: (updatedDriver: DriverData) => void;
  onEditClick: () => void;
  enableStreamlinedDriverAppOnboarding: boolean;
}

const PreviewDriverSidebar = ({
  driver,
  setOpen,
  onDriverUpdated,
  onEditClick,
  enableStreamlinedDriverAppOnboarding,
}: PreviewDriverSidebarProps): ReactElement => {
  const [archiveModalActive, setArchiveModalActive] = useState(false);

  return (
    <>
      <ArchiveDriverModal
        isActive={archiveModalActive}
        reset={() => {
          setArchiveModalActive(false);
          setOpen(false);
        }}
        driver={driver}
        onArchiveSuccess={onDriverUpdated}
      />
      <PreviewDriverSidebarBody
        driver={driver}
        enableStreamlinedDriverAppOnboarding={enableStreamlinedDriverAppOnboarding}
      />
      {driver.archived_at === null && (
        <SideBarFooter>
          <Button size="small" onClick={() => onEditClick()}>
            Edit Driver
          </Button>
          <Button color="secondary" size="small" onClick={() => setArchiveModalActive(true)}>
            Remove Driver
          </Button>
        </SideBarFooter>
      )}
    </>
  );
};

export default PreviewDriverSidebar;
