import Constants from '@app/utils/constants';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ReactElement } from 'react';

type uiFriendlyStatusTypes =
  | 'Active'
  | 'Awaiting driver action'
  | 'Driver update required'
  | 'Not enrolled'
  | 'W2 not supported'
  | 'Unknown';

const getPayrollEmployeeStatusName = (
  status: string | undefined | null,
  taxClassification?: string,
): uiFriendlyStatusTypes => {
  const isW2Employee = taxClassification === Constants.PayrollEnums.W2;
  if (isW2Employee) {
    return 'W2 not supported';
  }

  switch (status) {
    case Constants.PayrollEnums.ENROLLED:
      return 'Active';
    case Constants.PayrollEnums.INVITED:
      return 'Awaiting driver action';
    case Constants.PayrollEnums.UPDATE_REQUIRED:
      return 'Driver update required';
    case Constants.PayrollEnums.NOT_ENROLLED:
      return 'Not enrolled';
    default:
      return 'Unknown';
  }
};

const StatusPill = ({
  isMobile,
  status,
  taxClassification,
}: {
  isMobile: boolean;
  status: string | undefined | null;
  taxClassification: string | undefined | null;
}): ReactElement => {
  const isW2Employee = taxClassification === Constants.PayrollEnums.W2;
  if (isW2Employee) {
    return (
      <div
        className={classNames(
          'inline-flex py-1 text-center font-medium text-gray-400',
          {
            'w-48 justify-center': !isMobile,
          },
          {
            'text-left': isMobile,
          },
        )}
      >
        W2 not supported
      </div>
    );
  }

  const statusClass = classNames('inline-flex justify-center text-center w-50 py-1 font-medium', {
    'text-black': status === Constants.PayrollEnums.ENROLLED,
    'text-yellow-500':
      status === Constants.PayrollEnums.INVITED ||
      status === Constants.PayrollEnums.UPDATE_REQUIRED,
    'text-gray-400': status === Constants.PayrollEnums.NOT_ENROLLED,
  });

  const employeeIsEnrolled = status === Constants.PayrollEnums.ENROLLED;
  return (
    <div className={classNames(statusClass, { 'text-left': isMobile })}>
      {employeeIsEnrolled && (
        <span className="mr-1 text-green-600">
          <FontAwesomeIcon className="fa-1x" icon={faCheckCircle} />
        </span>
      )}
      {getPayrollEmployeeStatusName(status)}
    </div>
  );
};

export default StatusPill;
