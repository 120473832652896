import { Loading } from '@app/components/layout';
import axios from 'axios';
import { ReactElement, useEffect } from 'react';
import { useParams } from 'react-router-dom';

export function SwitchPartners(): ReactElement {
  const { channelPartnerName, offerUuid } = useParams();

  useEffect(() => {
    axios
      .put(`/customer/switch_channel_partner`, {
        channel_partner: channelPartnerName,
        offer_uuid: offerUuid,
      })
      .then(() => {
        window.location.href = '/switch-partners-success'; // we need a hard refresh instead of navigate
      });
  }, [channelPartnerName, offerUuid]);
  return <Loading />;
}
