import { CustomerData } from '@app/@types/customer.types';
import useScript from '@atob-developers/shared/src/hooks/useScript';
import * as Sentry from '@sentry/react';
import { useEffect, useRef } from 'react';

export default function useZendeskChat({ customer }: { customer: CustomerData | null }): void {
  const zendeskChatTimeout = useRef<NodeJS.Timeout>();

  const zendeskScriptLoadStatus = useScript(
    'https://static.zdassets.com/ekr/snippet.js?key=37277037-6b7e-44bd-b07f-9701c906fbd3',
    { id: 'ze-snippet' },
  );

  useEffect(() => {
    if (!window.zE || zendeskScriptLoadStatus !== 'ready') {
      return;
    }

    if (zendeskChatTimeout.current) {
      clearTimeout(zendeskChatTimeout.current);
      zendeskChatTimeout.current = undefined;
    }

    if (!customer) {
      // If the customer hasn't successfully loaded after some time, show the zendesk chat support automatically

      zendeskChatTimeout.current = setTimeout(() => {
        try {
          window.zE('webWidget', 'show');
        } catch (error) {
          Sentry.captureException(error);
        }
      }, 8000);
    } else {
      try {
        window.zE('webWidget', 'updateSettings', {
          webWidget: {
            zIndex: 110,
          },
        });
        window.zE('webWidget', 'identify', {
          name: customer.owner_name,
          email: customer.owner_email,
          organization: customer.company_name,
        });
        window.zE('webWidget', 'show');
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  }, [customer, zendeskScriptLoadStatus]);
}
