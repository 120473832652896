import { CustomerProduct } from '@app/constants/customerProducts';
import useCustomer from './useCustomer';

export default function useProduct(...keys: CustomerProduct[]): boolean[] {
  const { products } = useCustomer();

  if (!products) {
    return Array(keys.length).fill(false);
  }

  return keys.map((key) => products.includes(key));
}
