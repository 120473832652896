import { PaymentHistoryTab } from '@app/interfaces/payment-history';

export enum PaymentHistoryTabs {
  FUEL_CARD_BALANCE = 'fuel_card_balance',
  MONTHLY_SUBSCRIPTION = 'monthly_subscription',
}

export const PAYMENT_HISTORY_TABS: PaymentHistoryTab[] = [
  {
    name: 'Fuel Card Balance',
    tab: PaymentHistoryTabs.FUEL_CARD_BALANCE,
  },
  {
    name: 'Monthly Subscription',
    tab: PaymentHistoryTabs.MONTHLY_SUBSCRIPTION,
  },
];
