import { CustomerData } from '@app/@types/customer.types';
import { guardAxiosError } from '@app/utils/error/guards';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { LoadingButton } from '@mui/lab';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { capitalize } from 'lodash-es';
import { ReactElement, useState } from 'react';
import { ErrorBanner } from './Designs/ErrorBanner';

export const getStripeLink = async (): Promise<string> => {
  const { data: stripeVerificationLinkData } = await axios.get('/byoc/id_verification_link');
  const { id_verification_link } = stripeVerificationLinkData.data;

  return id_verification_link;
};

export const IssuingOnboardingBanner = ({ customer }: { customer: CustomerData }): ReactElement => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  const enroll = async () => {
    setLoading(true);

    try {
      await axios.post('/byoc/enroll');
      const stripeLink = await getStripeLink();
      window.location.href = stripeLink;
    } catch (e: unknown) {
      if (guardAxiosError(e) && e.response?.status === 404) {
        addToast('Only the Account Owner can fill this out.', { appearance: 'error' });
      } else {
        Sentry.captureException(e);
        addToast('Something went wrong, please try again later.', { appearance: 'error' });
      }
    }

    setLoading(false);
  };

  const onboardingErrors =
    customer.issuing?.onboarding_errors?.map(({ code, reason }) => ({
      code: code
        .split('_')
        .map((word) => capitalize(word))
        .join(' '),
      reason,
    })) || [];

  const isInactivitySuspension = customer.issuing?.update_required_reason === 'rejected.inactivity';

  const errorList = [...new Set(onboardingErrors.map(({ reason }) => reason))].slice(0, 2);

  const kycLearnMore = (
    <>
      Learn more about{' '}
      <a
        className="cursor-pointer underline"
        target="_blank"
        href="https://plaid.com/resources/banking/what-is-kyc/"
        rel="noreferrer"
      >
        U.S. KYC
      </a>
      .
    </>
  );

  const subtitle = (
    <div className="mt-3">
      Your account will be automatically reactivated once these KYC requirements are completed.
      Complete this to minimize business disruption. {kycLearnMore}
    </div>
  );

  const descriptionErrors = (
    <div className="mt-3">
      {subtitle} The following information needs an update:
      <ul className="flex list-disc flex-col pl-5">
        {errorList.map((reason) => {
          return <li key={reason}>{reason}</li>;
        })}
      </ul>
    </div>
  );

  const getStartedButton = isInactivitySuspension ? null : (
    <LoadingButton type="button" onClick={enroll} loading={loading} color="primary">
      <span>Get Started</span>
    </LoadingButton>
  );

  let bannerContent = null;
  if (isInactivitySuspension) {
    bannerContent = <>Please contact support to get your account re-activated</>;
  } else if (errorList.length > 0) {
    bannerContent = (
      <>
        Please provide more information.
        {descriptionErrors}
      </>
    );
  } else {
    bannerContent = (
      <>Please complete KYC requirements to reactivate your account. {kycLearnMore}</>
    );
  }

  return (
    <ErrorBanner customActionButton={getStartedButton}>
      <span className="font-semibold">Account Suspended: </span>
      {bannerContent}
    </ErrorBanner>
  );
};

export default IssuingOnboardingBanner;
