import { WorkspaceProduct } from '@app/constants/customerProducts';
import { WithId } from '@atob-developers/shared/src/@types/form.types';

export const userRoles: Record<string, WithId<Record<string, string>>> = {
  workspace_owner: {
    name: 'Owner',
    id: 'owner',
    description:
      'Owners can manage all users, cards, vehicles, and tags. ' +
      'They can manage billing, and subscriptions',
  },
  workspace_card_admin: {
    name: 'Card Admin',
    id: 'card_admin',
    description: 'Users who can add users, order cards, set spend limits, and manage tags',
  },
  workspace_card_manager: {
    name: 'Card Manager',
    id: 'card_manager',
    description: 'Users who can manage drivers, vehicles, and order cards',
  },
  workspace_bookkeeper: {
    name: 'Bookkeeper',
    id: 'bookkeeper',
    description: 'Users who can manage billing, subscriptions',
  },
  workspace_notifications_only: {
    name: 'Notifications Only',
    id: 'notifications_only',
    description: 'Users who only receive notifications about card activity',
  },
};

export function getUserRoleKeyToName(
  workspaceProducts: WorkspaceProduct[],
): Record<string, WithId<Record<string, string>>> {
  const finalRoles: Record<string, WithId<Record<string, string>>> = {};
  workspaceProducts.forEach((workspaceProduct) => {
    finalRoles[workspaceProduct] = userRoles[workspaceProduct];
  });
  return finalRoles;
}
