import useThemeConfiguration from '@app/app/useThemeConfiguration';
import dayjs from 'dayjs';
import { ReactElement } from 'react';
import useLocalStorage from '../popups/components/useLocalStorage';
import PathNotificationModal from './PathNotificationModal';
import type { SpendPromotion } from './PathToCredit';

const SPEND_PROMOTION_KEY = 'spend_promotion';

type StoredPromotionData = {
  lastCheckedDate: number | null;
  numberFromLastCheck: number | null;
};
const lastCheckedBaseData: StoredPromotionData = {
  lastCheckedDate: null,
  numberFromLastCheck: null,
};

export default function ProgressNotification({
  spendPromotion,
}: {
  spendPromotion: SpendPromotion;
}): ReactElement {
  const { target_weeks_met } = spendPromotion;
  const [storedPromotionData, setStoredPromotionData] = useLocalStorage<StoredPromotionData>(
    SPEND_PROMOTION_KEY,
    lastCheckedBaseData,
  );
  const { hideDismissableBanners } = useThemeConfiguration();

  const { lastCheckedDate, numberFromLastCheck } = storedPromotionData;
  const dateToCheck = lastCheckedDate ? dayjs.unix(lastCheckedDate) : dayjs().unix();

  // Check every day
  const now = dayjs();
  const isLastCheckExpired = now.diff(dateToCheck, 'day') > 0;

  const setNewStoredPromotionData = () => {
    setStoredPromotionData({
      lastCheckedDate: now.unix(),
      numberFromLastCheck: target_weeks_met,
    });
  };

  if (!isLastCheckExpired || hideDismissableBanners) {
    // no need to show a notification, bail out
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return null;
  }

  const isStartingFromScratch = target_weeks_met === 0;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  if (isStartingFromScratch && numberFromLastCheck > target_weeks_met) {
    return (
      <PathNotificationModal
        modalType="reset"
        onClose={() => {
          setNewStoredPromotionData();
        }}
        spendPromotion={spendPromotion}
      />
    );
  }
  if (numberFromLastCheck !== null && numberFromLastCheck < target_weeks_met) {
    return (
      <PathNotificationModal
        modalType="progress"
        onClose={() => {
          setNewStoredPromotionData();
        }}
        spendPromotion={spendPromotion}
      />
    );
  }

  if (lastCheckedDate === null) {
    // Brand new session
    setNewStoredPromotionData();
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return null;
}
