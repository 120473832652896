import logger from '@app/utils/datadog-logger';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

export type RadiusOption = 5 | 30 | 50 | 100 | 200;

export default function RadiusSelect({
  disabled,
  selectedRadius,
  onSelectRadius,
}: {
  disabled: boolean;
  selectedRadius: RadiusOption;
  onSelectRadius: (radius: RadiusOption) => void;
}) {
  const handleChange = (event: SelectChangeEvent<RadiusOption>) => {
    const radius = event.target.value as RadiusOption;
    logger.info('fuel-listing-filter', {
      key: 'fuel_radius',
      radius: radius,
    });
    onSelectRadius(radius);
  };

  return (
    <FormControl className="flex-1" disabled={disabled}>
      <InputLabel className="sr-only">Search Radius</InputLabel>
      <Select
        size="small"
        value={selectedRadius}
        onChange={handleChange}
        renderValue={(value) => `${value} mi`}
      >
        {[5, 30, 50, 100, 200].map((radius) => (
          <MenuItem key={radius} value={radius}>
            <ListItemText>{radius} mi</ListItemText>
            {selectedRadius === radius && (
              <ListItemIcon>
                <FontAwesomeIcon icon={faCheck} />
              </ListItemIcon>
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
