import CurrencyFormat, {
  convertDollarsToCents,
  convertCentsToDollars,
} from '@atob-developers/shared/src/utils/formatters/CurrencyFormat';
import classNames from 'classnames';
import { ReactElement, useEffect, useState } from 'react';

export const PAYOUT_LIMIT_CENTS = 999900;
export const PAYOUT_LIMIT_DOLLARS = convertCentsToDollars({
  value: PAYOUT_LIMIT_CENTS,
});
export const FORMATTED_LIMIT = CurrencyFormat({
  value: PAYOUT_LIMIT_DOLLARS,
});

const centsToDollarsInString = (value: number): string => {
  const valueInDollars = convertCentsToDollars({
    value,
  }).value;
  return value ? valueInDollars.toString() : '';
};
export default function PayAmountInput({
  amountToPayDriver,
  setAmountToPayDriver,
}: {
  amountToPayDriver: number;
  setAmountToPayDriver: (amount: number) => void;
}): ReactElement {
  const [warning, setWarning] = useState<string>('');

  const [amount, setAmount] = useState(centsToDollarsInString(amountToPayDriver));

  useEffect(() => {
    //After payment completes, this will reset the input value
    setAmount(centsToDollarsInString(amountToPayDriver));
  }, [amountToPayDriver, setAmount]);

  const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const { value } = e.target;
    const isNotANumber = Number.isNaN(parseFloat(value));
    const isNotEmpty = value !== '';

    //Prevents invalid characters like text
    if (isNotANumber && isNotEmpty) {
      return;
    }

    const amountToPayDriverInCents = convertDollarsToCents({ value: parseFloat(value) })
      .value as number;

    //Prevents input past payout limit
    if (amountToPayDriverInCents >= PAYOUT_LIMIT_CENTS) {
      setWarning && setWarning(`Must be less than ${FORMATTED_LIMIT}`);
      return;
    }
    setWarning && setWarning('');

    setAmount(value);
    setAmountToPayDriver(amountToPayDriverInCents);
  };

  return (
    <div>
      <label htmlFor={`amount-to-pay-driver`} className="block font-medium text-gray-700">
        <span className="text-md mt-3px flex items-baseline">
          $
          <input
            type="number"
            value={amount}
            onChange={onChangeAmount}
            name={`amount-to-pay-driver`}
            id={`amount-to-pay-driver`}
            className={classNames(
              `sm:text-md ml-2 block h-10 w-full rounded-md border border-gray-300 pl-1 text-right focus:border-green-500 focus:ring-green-500`,
            )}
            placeholder="0.00"
          />
        </span>
      </label>
      {warning && <div className="text-sm font-[400] text-yellow-500">{warning}</div>}
    </div>
  );
}
