import { DebitCardPaymentMethodCombined } from '@app/@types/debitCard.types';
import Ellipsis from '@app/components/elements/Ellipsis';
import RadioGroup from '@atob-developers/shared/src/components/RadioButton';
import axios from 'axios';
import { capitalize } from 'lodash-es';
import { ReactElement, useEffect } from 'react';
import AddPayoutCard from './AddPayoutCard';

export default function WalletDebitFunds({
  debitCards,
  addDebitCard,
  loadingDebitCards,
  selectedDebitCardId,
  setSelectedDebitCardId,
  setOverallError,
  addNewCardSelected,
  setAddNewCardSelected,
}: {
  debitCards: DebitCardPaymentMethodCombined[];
  addDebitCard: (token: string) => void;
  loadingDebitCards: boolean;
  selectedDebitCardId: string | null;
  setSelectedDebitCardId: (id: string) => void;
  setOverallError: (error: string) => void;
  addNewCardSelected: boolean;
  setAddNewCardSelected: (addNewCardSelected: boolean) => void;
}): ReactElement {
  useEffect(() => {
    if (selectedDebitCardId || addNewCardSelected) {
      return;
    }

    setSelectedDebitCardId(debitCards[0]?.id);
  }, [debitCards, selectedDebitCardId, setSelectedDebitCardId, addNewCardSelected]);

  const addCardRequest = async (token: string) => {
    setAddNewCardSelected(false);
    try {
      await addDebitCard(token);
    } catch (e: unknown) {
      const error =
        axios.isAxiosError(e) && e.response?.data.errors?.length > 0
          ? `${e.response?.data.errors[0]}`
          : 'There was an issue adding your card.';
      setAddNewCardSelected(false);
      setOverallError(error);
    }
  };

  const debitCardsToRender = debitCards
    .map((card) => ({
      id: card.id,
      checked: !addNewCardSelected && selectedDebitCardId === card.id,
      label: `${capitalize(card.brand)} ••••${card.last_four}`,
      value: card.id,
      name: `${capitalize(card.brand)} `,
    }))
    .concat({
      id: 'add-card',
      checked: addNewCardSelected,
      label: 'Add a new card',
      value: 'add-card',
      name: 'add-card',
    });

  const handleDebitCardSelection = (id: string) => {
    if (id === 'add-card') {
      setAddNewCardSelected(true);
      return;
    }
    setAddNewCardSelected(false);
    setSelectedDebitCardId(id);
  };

  return (
    <>
      {loadingDebitCards ? (
        <div className="text-atob-green text-sm">
          Retrieving cards
          <Ellipsis />
        </div>
      ) : (
        <div className="flex w-full flex-col flex-wrap">
          <div className="mb-2 text-sm font-medium text-gray-700">Select debit card</div>
          <RadioGroup data={debitCardsToRender} onSelect={handleDebitCardSelection} />
        </div>
      )}
      {addNewCardSelected && (
        <AddPayoutCard
          addCardRequest={addCardRequest}
          onCancel={() => {
            setAddNewCardSelected(false);
          }}
          onSuccess={() => {}}
        />
      )}
    </>
  );
}
