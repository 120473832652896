import { DeviceData } from '@app/@types/device.types';
import { useCallback, useReducer, useState } from 'react';

const SET_DEVICE = 'set-device' as const;
const SET_DEVICE_PROPERTY = 'set-device-property' as const;
const SET_ERRORS = 'set-errors' as const;

export type DeviceState = Partial<DeviceData> & {
  // If the ID is null, we're creating a new device
  id: number | null;
  name: string;
  errors: string[];
};

type DeviceAction =
  | {
      type: typeof SET_DEVICE_PROPERTY;
      payload: {
        key: keyof DeviceState;
        value: string;
      };
    }
  | {
      type: typeof SET_DEVICE;
      payload: Partial<DeviceState>;
    }
  | {
      type: typeof SET_ERRORS;
      payload: string[];
    };

function deviceReducer(state: DeviceState, action: DeviceAction): DeviceState {
  switch (action.type) {
    case SET_DEVICE_PROPERTY: {
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    }
    case SET_DEVICE: {
      return {
        ...state,
        ...action.payload,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        id: action.payload.id || null,
        errors: [],
      };
    }
    case SET_ERRORS: {
      return {
        ...state,
        errors: action.payload,
      };
    }
  }
}

export type DeviceReducer = {
  device: DeviceState;
  setDevice: (device?: Partial<DeviceState>) => void;
  setDeviceProperty: (key: keyof DeviceData, value: string | null) => void;
  editing: boolean;
  toggleEditing: () => void;
  setEditing: (editing: boolean) => void;
  setErrors: (errors: string[]) => void;
};

export type DeviceReducerProps = Partial<
  DeviceState & {
    editing: boolean;
  }
>;

export function useDeviceFormReducer(props: DeviceReducerProps = {}): DeviceReducer {
  // props could be null and default argument values only work on undefined
  props ||= {};

  const { editing: initialEditing = false, ...device } = props;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const [state, dispatch] = useReducer(deviceReducer, {
    ...device,
    id: device.id || null,
    name: device.name || '',
  });

  const [editing, setEditing] = useState(initialEditing);

  const setDevice = useCallback((device: Partial<DeviceState>) => {
    device ||= {};
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    dispatch({ type: SET_DEVICE, payload: device });
  }, []);

  const setDeviceProperty = useCallback((key: keyof DeviceData, value: string | null) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    dispatch({ type: SET_DEVICE_PROPERTY, payload: { key, value } });
  }, []);

  const setErrors = useCallback((errors: string[]) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    dispatch({ type: SET_ERRORS, payload: errors });
  }, []);

  const setEditingCallback = useCallback(
    (value: boolean) => {
      setEditing(value);
      // If we're no longer editing this device, set the device back to its original state
      const { editing: _, ...device } = props;
      if (!value) setDevice(device);
    },
    [setDevice, props],
  );

  const toggleEditing = useCallback(
    () => setEditingCallback(!editing),
    [setEditingCallback, editing],
  );

  return {
    device: state,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    setDevice,
    setDeviceProperty,
    editing,
    toggleEditing,
    setEditing: setEditingCallback,
    setErrors,
  };
}
