import { Heading, HeadingLevel } from 'baseui/heading';
import { ParagraphMedium } from 'baseui/typography';
import { ReactElement } from 'react';

const SoundsGoodView = (): ReactElement => {
  const styleString = `
    .mTFF {
      font-family: MoveText !important;
    }
  `;

  return (
    <div>
      <style>{styleString}</style>
      <HeadingLevel>
        <Heading style={{ marginBottom: '32px', paddingLeft: '24px', paddingRight: '24px' }}>
          We will not transfer your account
        </Heading>
      </HeadingLevel>
      <ParagraphMedium style={{ marginBottom: '32px', paddingLeft: '24px', paddingRight: '24px' }}>
        We will not transfer your account and we will not enroll you for the Uber Freight Carrier
        Card.
      </ParagraphMedium>
      <ParagraphMedium style={{ paddingLeft: '24px', paddingRight: '24px' }}>
        If you change your mind, please reach out to ufcarriercard@atob.com.
      </ParagraphMedium>
    </div>
  );
};

export default SoundsGoodView;
