import classNames from 'classnames';
import { ReactElement, ForwardedRef, PropsWithChildren, ReactNode, forwardRef } from 'react';

type PaymentDetailItemProps = {
  text: string;
  error?: string | ReactNode;
};

export type PaymentDetailItemButtonProps = {
  onClick: () => void;
  text?: string;
  error?: string | React.ReactNode;
  className?: string;
};

export const PaymentDetailItemButton = ({
  onClick,
  text,
  className,
}: PaymentDetailItemButtonProps): ReactElement | null => {
  if (!onClick || !text) {
    return null;
  }

  return (
    <p
      data-testid="PaymentDetailItemButton"
      className={classNames('cursor-pointer font-medium', className)}
      onClick={onClick}
    >
      {text}
    </p>
  );
};

const ErrorText = ({ error }: { error?: string | ReactNode }): ReactElement | null => {
  if (!error) {
    return null;
  }

  return (
    <p data-testid="PaymentDetailItem-Error" className="text-sm font-medium text-red-600 ">
      {error}
    </p>
  );
};

const PaymentDetailItem = (
  { text, children, error }: PropsWithChildren<PaymentDetailItemProps>,
  ref?: ForwardedRef<HTMLDivElement>,
): ReactElement => (
  <div
    className="flex items-center justify-between gap-4 text-base font-medium text-gray-700"
    ref={ref}
  >
    <div className="flex-1">
      <p>{text}</p>
    </div>
    <div className="flex flex-col gap-px text-right font-semibold">
      {children}
      <ErrorText error={error} />
    </div>
  </div>
);

export default forwardRef(PaymentDetailItem);
