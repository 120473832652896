import { Completed, Downloading, Error } from '@app/components/Exporting/Screens';
import { ReactElement } from 'react';
import { ExportJobStatus } from './useExportJob';

export default function DownloadStatus({
  downloadState,
  downloadLink,
  onClose,
}: {
  downloadState: ExportJobStatus;
  downloadLink: string | null;
  onClose: () => void;
}): ReactElement {
  switch (downloadState) {
    case 'loading':
      return <Downloading />;
    case 'completed':
      if (!downloadLink) {
        return <Error onClose={onClose} />;
      }
      return <Completed downloadLink={downloadLink} onClose={onClose} />;
    case 'error':
      return <Error onClose={onClose} />;
  }
}
