import { CustomerData } from '@app/@types/customer.types';
import { CustomerProduct } from '@app/constants/customerProducts';
import SettingsPage, { SettingsTab } from '@app/pages/Settings/SettingsPage';
import { ReactElement } from 'react';

export default function Settings({
  products,
  customer,
  initialTab,
  contentOverride,
}: {
  products: CustomerProduct[];
  customer: CustomerData;
  initialTab?: SettingsTab;
  contentOverride?: ReactElement;
}): ReactElement {
  return (
    <SettingsPage
      products={products}
      customer={customer}
      initialTab={initialTab}
      contentOverride={contentOverride}
    />
  );
}
