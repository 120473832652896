import useChannelPartner from '@app/hooks/useChannelPartner';
import { ReactElement } from 'react';

export default function CardLegalTerms({
  payroll = false,
  setShowTerms,
}: {
  payroll?: boolean;
  setShowTerms: (shouldShow: boolean) => void;
}): ReactElement {
  const { partnerName, hasChannelPartner } = useChannelPartner();
  const terms = payroll
    ? `Your driver payroll will be deducted from the bank account linked to this debit card`
    : // AtoB is the one charging the card, but we include the partner's name for recognition
      // eslint-disable-next-line no-explicit-atob-mentions
      `By providing your card information, you allow AtoB${
        hasChannelPartner ? ` on behalf of ${partnerName}` : ''
      } to charge your card for future payments`;
  return (
    <div className="mt-4 text-xs text-gray-600">
      {terms} in accordance with
      <a onClick={() => setShowTerms(true)} className="ml-1 text-xs text-gray-600 underline">
        these terms
      </a>
      .
    </div>
  );
}
