import { Drawer } from '@mui/material';
import classNames from 'classnames';
import {
  createContext,
  HTMLAttributes,
  PropsWithChildren,
  ReactNode,
  useContext,
  useMemo,
} from 'react';
import { ModalCloseButton } from './Modal';

type SideBarProps = {
  open: boolean;
  toggle: () => void;
  title: ReactNode;
  preventClickOutside?: boolean;
} & Omit<HTMLAttributes<HTMLDivElement>, 'title'>;

/**
 * The way the sidebar is built was keeping in mind that we would always need a
 * X button on the title to close the sidebar, which is why that was abstracted
 * away into the `SideBarTitle` component which would require a `title` and a
 * callback to call when the X was clicked.
 *
 *
 * This callback would the same that was passed to the root `SideBar` component
 * as `Dialog.onClose` requires it as well. This would imply that we'd either have
 * to pass the `toggle` function to two components, the main `SideBar` component and
 * the `SideBarTitle` component which would be a hassle.
 *
 * Instead, what we do is take the props of title and toggle on the root level of
 * `SideBar` and use that to pass the two values down to the title using this context
 */
const SideBarContext = createContext<Pick<SideBarProps, 'title' | 'toggle'>>({
  title: '',
  toggle: () => {},
});

export default function SideBar({
  open,
  toggle,
  children,
  title,
  preventClickOutside = false,
}: SideBarProps) {
  const sidebarContextVal = useMemo(() => ({ toggle, title }), [toggle, title]);
  return (
    <SideBarContext.Provider value={sidebarContextVal}>
      <Drawer
        anchor="right"
        open={open}
        classes={{
          root: classNames('fixed inset-0 overflow-hidden z-side-drawer'),
        }}
        onClose={() => !preventClickOutside && toggle()}
      >
        <div className="z-side-drawer pointer-events-auto h-full w-screen max-w-full sm:max-w-md">
          <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
            {children}
          </div>
        </div>
      </Drawer>
    </SideBarContext.Provider>
  );
}

export const SideBarFooter = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <div className="flex flex-shrink-0 items-center justify-end gap-4 p-4 py-6">{children}</div>
  );
};

export const SideBarTitle = () => {
  const { toggle, title } = useContext(SideBarContext);
  return (
    <div className="px-4 pb-2 pt-6 sm:px-6">
      <div className="flex items-end justify-between">
        <h2 className="text-lg font-bold text-gray-700 md:text-xl">{title}</h2>
        <div className="ml-3 flex h-7 items-center">
          <ModalCloseButton onClick={toggle}></ModalCloseButton>
        </div>
      </div>
    </div>
  );
};

export const SideBarBody = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <div className="h-0 flex-1 overflow-y-auto">
      <SideBarTitle />
      <div className="flex flex-1 flex-col justify-between">
        <div className="divide-y divide-gray-200 px-6">
          <div className="pb-8 pt-4">{children}</div>
        </div>
      </div>
    </div>
  );
};
