import { DriverData } from '@app/@types/driver.types';
import DriverSidebarContent from '@app/components/Sidebars/DriverSidebar/DriverSidebarContent';
import { SidebarWrapper } from '@app/components/wrappers/SidebarWrapper';
import { ReactElement } from 'react';

export enum DriverSidebarType {
  READ = 'read',
  EDIT = 'edit',
}

export interface DriverSidebarProps {
  driver: DriverData;
  open: boolean;
  setOpen: (open: boolean) => void;
  sidebarType: DriverSidebarType;
  updateSidebarType: (sidebarType: DriverSidebarType) => void;
  enableStreamlinedDriverAppOnboarding: boolean;
  onDriversUpdate: (updatedDriver: DriverData) => void;
}

const getSidebarTitle = (type: DriverSidebarType, driver: DriverData) => {
  switch (type) {
    case DriverSidebarType.READ:
      return `${driver?.name}`;
    case DriverSidebarType.EDIT:
      return 'Edit Driver';
    default:
      return 'New Driver';
  }
};

export const DriverSidebar = ({
  driver,
  open,
  setOpen,
  updateSidebarType,
  sidebarType,
  enableStreamlinedDriverAppOnboarding,
  onDriversUpdate,
}: DriverSidebarProps): ReactElement => {
  return (
    <SidebarWrapper
      title={getSidebarTitle(sidebarType, driver)}
      open={open}
      setOpen={(val: boolean) =>
        sidebarType === DriverSidebarType.EDIT
          ? updateSidebarType(DriverSidebarType.READ)
          : setOpen(val)
      }
    >
      <DriverSidebarContent
        sidebarType={sidebarType}
        driver={driver}
        setOpen={setOpen}
        updateSidebarType={updateSidebarType}
        enableStreamlinedDriverAppOnboarding={enableStreamlinedDriverAppOnboarding}
        onDriversUpdate={onDriversUpdate}
      />
    </SidebarWrapper>
  );
};
