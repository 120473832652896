import { PaginatedEndpointResponse } from '@app/@types/api.types';
import { UpcomingPayment } from '@app/@types/fuel-card-account.types';
import { DailyTransactionSummaryData } from '@app/@types/transaction.types';
import axios from 'axios';
import { deserialize } from 'deserialize-json-api';

export const getTransactionSummary = async ({
  startAt,
  endAt,
  cardId = undefined,
}: {
  startAt: string;
  endAt: string;
  cardId?: string | number;
}): Promise<DailyTransactionSummaryData[]> => {
  const params = [`transaction_date[since]=${startAt}`, `transaction_date[until]=${endAt}`];

  if (cardId) {
    params.push(`card_id=${cardId}`);
  }

  const response = await axios.get<PaginatedEndpointResponse<DailyTransactionSummaryData>>(
    `/daily_transaction_summary?${params.join('&')}`,
  );
  const { data: deserializedDailyTransactionSummary } = deserialize(response.data);

  return deserializedDailyTransactionSummary;
};

export const getScheduledBillingPayments = async (): Promise<UpcomingPayment> => {
  const response = await axios.get('/billing/payments/scheduled');

  const { data: deserializedBillingPaymentsScheduled } = deserialize(response.data);

  let upcomingScheduledPayment = null;

  if (deserializedBillingPaymentsScheduled.length > 1) {
    const sortedScheduledPayments = [...deserializedBillingPaymentsScheduled].sort(
      (a: { pay_at: string }, b: { pay_at: string }) =>
        a['pay_at'] > b['pay_at'] ? 1 : b['pay_at'] > a['pay_at'] ? -1 : 0,
    );
    [upcomingScheduledPayment] = sortedScheduledPayments;
  }

  if (deserializedBillingPaymentsScheduled.length === 1) {
    [upcomingScheduledPayment] = deserializedBillingPaymentsScheduled;
  }

  return upcomingScheduledPayment;
};
