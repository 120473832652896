import Header from '@app/components/Navigation/Header';
import { Loading } from '@app/components/layout';
import ConfirmActionDialog from '@app/components/layout/ConfirmActionDialog';
import PageContentWrapper from '@app/components/wrappers/PageContentWrapper';
import { useExtendMobileHeaderContextMenu } from '@app/contexts/MobileNavHeaderMenuContextComponent';
import {
  getUpdateRestrictionParams,
  parseRestrictionResponse,
  useRestrictions,
  useUpdateRestrictions,
} from '@app/hooks/query/useRestrictions';
import { useDeleteTag, useTag, useUpdateTag } from '@app/hooks/query/useTags';
import constants from '@app/utils/constants';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { LoadingButton } from '@mui/lab';
import * as Sentry from '@sentry/react';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { ReactElement, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { SaveButton } from './SaveButton';
import SpendControlForm from './SpendControlForm';
import { FormValues } from './policy.types';

interface EditTagsPageProps {
  allowSetSpendLimit: boolean;
  allowRestrictions: boolean;
  allowTelematics: boolean;
  spendTier: number;
}
const TelematicsOptions = constants.TelematicsOptions;

const EditTagsPage = ({
  spendTier,
  allowTelematics,
  allowSetSpendLimit,
  allowRestrictions,
}: EditTagsPageProps): ReactElement => {
  const [openUnsavedDialog, setOpenUnsavedDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);

  const navigate = useNavigate();
  const navigateBack = () => navigate(-1);

  const { addToast } = useToasts();
  const { id: tagId } = useParams();

  const { data: tagData, isLoading: isLoadingTag } = useTag(tagId);
  const { data: restrictionData, isLoading: isLoadingRestrictions } = useRestrictions(tagId);

  const tag = tagData?.data;
  const restrictions =
    restrictionData && restrictionData.data && parseRestrictionResponse(restrictionData.data);

  const { trigger: updateTag, error: tagError } = useUpdateTag(tagId);
  const {
    trigger: deleteTag,
    isMutating: deleteTagInProgress,
    error: deleteTagError,
  } = useDeleteTag(tagId);

  const { updateRestrictions, error: restrictionsError } = useUpdateRestrictions();

  const errors = [tagError, restrictionsError, deleteTagError];

  if (errors.some((e) => !!e)) {
    errors.forEach((error) => {
      Sentry.captureException(error, {
        extra: { tag_id: tagId },
      });
    });
    addToast('There was an error saving the configuration. Please refresh and try again later', {
      appearance: 'error',
    });
  }

  const validationSchema = Yup.object().shape({
    policy: Yup.object({
      name: Yup.string().required('Please name your tag'),
      description: Yup.string(),
      per_transaction_limit: Yup.number(),
      daily_spend_limit: Yup.number(),
      weekly_spend_limit: Yup.number(),
      telematics_setting: Yup.mixed().oneOf(TelematicsOptions.map((o) => o.id)),
      card_security_enabled: Yup.boolean(),
    }),
    restrictions: Yup.object({
      afdOnly: Yup.boolean(),
      selectedCategoryIds: Yup.array(),
      merchantWhitelist: Yup.array(),
      merchantBlacklist: Yup.array(),
    }),
  });

  const onSubmit = async (tagValues: FormValues, props: FormikHelpers<FormValues>) => {
    const tag = await updateTag({
      tag: {
        ...tagValues.policy,
      },
    });
    if (!tag) {
      return;
    }
    const restrictionParams = getUpdateRestrictionParams(
      tagValues.restrictions,
      tag?.data,
      spendTier,
    );
    updateRestrictions(restrictionParams);
    props.setTouched({});
    props.setSubmitting(false);
  };

  const initialValues = useMemo(
    () => ({
      policy: {
        name: tag?.name || '',
        description: tag?.description || '',
        per_transaction_limit: tag?.per_transaction_limit || '',
        daily_spend_limit: tag?.daily_spend_limit || '',
        weekly_spend_limit: tag?.weekly_spend_limit || '',
        telematics_setting: tag?.telematics_setting || 'off',
        card_security_enabled: tag?.card_security_enabled || false,
      },
      restrictions: {
        afdOnly: restrictions?.afdOnly || false,
        selectedCategoryIds: restrictions?.selectedCategoryIds || [],
        merchantWhitelist: restrictions?.merchantWhitelist || [],
        merchantBlacklist: restrictions?.merchantBlacklist || [],
      },
    }),
    [tag, restrictions],
  );
  const deleteButton = useMemo(
    () => ({
      value: 'Delete Tag',
      onClick: () => setDeleteDialog(true),
      disabled: () => isLoadingTag || isLoadingRestrictions,
      order: 1,
    }),
    [isLoadingRestrictions, isLoadingTag],
  );

  useExtendMobileHeaderContextMenu(deleteButton);

  if (isLoadingTag || isLoadingRestrictions) {
    return (
      <PageContentWrapper header={<Header title="Edit Tag" onBack={() => navigateBack()} />}>
        <Loading />
      </PageContentWrapper>
    );
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {(props: FormikProps<FormValues>) => (
        <Form>
          <ConfirmActionDialog
            title={'Unsaved changes'}
            open={openUnsavedDialog}
            handleConfirm={navigateBack}
            handleCancel={() => setOpenUnsavedDialog(false)}
          >
            Leaving this page will discard any unsaved changes. Do you want to continue?
          </ConfirmActionDialog>
          <ConfirmActionDialog
            title={'Confirm delete'}
            open={deleteDialog}
            handleConfirm={() => {
              deleteTag();
              navigateBack();
            }}
            handleCancel={() => setDeleteDialog(false)}
          >
            Are you sure you want to delete the tag <b>{tag?.name}</b>?
          </ConfirmActionDialog>
          <PageContentWrapper
            header={
              <Header
                title="Edit Tag"
                onBack={() =>
                  Object.values(props.touched).length === 0
                    ? navigateBack()
                    : setOpenUnsavedDialog(true)
                }
                rightContent={
                  <div className="flex items-center justify-end gap-2">
                    <LoadingButton
                      color="secondary"
                      size="small"
                      loading={deleteTagInProgress}
                      onClick={() => setDeleteDialog(true)}
                    >
                      <span>Delete</span>
                    </LoadingButton>
                  </div>
                }
              />
            }
          >
            <SpendControlForm
              allowTelematics={allowTelematics}
              allowSetSpendLimit={allowSetSpendLimit}
              allowRestrictions={allowRestrictions}
              spendTier={spendTier}
              values={props.values}
              setFieldValue={props.setFieldValue}
              errors={props.errors}
              touched={props.touched}
              saveButton={
                <SaveButton
                  disabled={!(props.isValid && props.dirty)}
                  isSubmitting={props.isSubmitting}
                  onSubmit={props.submitForm}
                />
              }
              tagId={tagId ? parseInt(tagId) : undefined}
            />
          </PageContentWrapper>
        </Form>
      )}
    </Formik>
  );
};

export default EditTagsPage;
