import { CustomerData } from '@app/@types/customer.types';
import { FlexOfferResponse, OfferState } from '@app/@types/promotion.types';
import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import useShippingAddress from '@app/hooks/use-shipping-address';
import ApiEndpoints from '@app/utils/data/apiEndpoints';
import { guardAxiosError } from '@app/utils/error/guards';
import Modal from '@atob-developers/shared/src/components/Modal';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import axios from 'axios';
import { deserialize } from 'deserialize-json-api';
import { ReactElement, useEffect, useState } from 'react';
import UnlimitedToFlexMigrationAcceptOffer from './AcceptOffer';
import UnlimitedToFlexMigrationBankAccountConnected from './BankAccountConnected';
import UnlimitedToFlexMigrationConfirmAddress from './ConfirmAddress';
import UnlimitedToFlexMigrationConfirmOffer from './ConfirmOffer';
import UnlimitedToFlexMigrationConnectBankAccount from './ConnectBankAccount';
import UnlimitedToFlexMigrationOfferAccepted from './OfferAccepted';

type Screen =
  | 'accept-offer'
  | 'link-account'
  | 'account-linked'
  | 'confirm-address'
  | 'confirm-offer'
  | 'payment-processing';

const flexOfferToScreenMap: Record<OfferState, Screen> = {
  created: 'accept-offer',
  accepted: 'link-account',
  account_linked: 'account-linked',
  account_linked_failed: 'account-linked',
  payment_processing: 'payment-processing',
  payment_succeeded: 'payment-processing',
  payment_failed: 'payment-processing',
  migrated: 'payment-processing',
  rejected: 'payment-processing',
};

const UnlimitedToFlexMigration = ({
  closeModal,
  customer,
  flexOffer,
  setFlexOffer,
  setLoading,
}: {
  closeModal: () => void;
  customer: CustomerData;
  flexOffer: FlexOfferResponse;
  setFlexOffer: React.Dispatch<React.SetStateAction<FlexOfferResponse>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}): ReactElement | null => {
  const shippingAddress = useShippingAddress(customer);
  const [currentScreen, setCurrentScreen] = useState<Screen>('accept-offer');
  const [bankAccount, setBankAccount] = useState({ attributes: { name: 'Loading', mask: '0000' } });
  const cardsOrdered = customer.any_cards_delivered || customer.cardholder_status === 'active';
  const { addToast } = useToasts();

  useEffect(() => {
    setCurrentScreen(flexOfferToScreenMap[flexOffer.workflow_state]);
  }, [flexOffer]);

  useEffect(() => {
    async function fetchBankAccount() {
      setLoading(true);
      axios
        .get(ApiEndpoints.PAYMENTS_ENDPOINTS.PRIMARY_PAYMENT_METHOD_ENDPOINT)
        .then(({ data: res }) => {
          const paymentMethodDetail = deserialize(res.data).relationships.payment_method_detail
            .data;
          if (paymentMethodDetail && paymentMethodDetail.type === 'bank_account') {
            axios.get('/bank_accounts/' + paymentMethodDetail.id).then(({ data: bankAccount }) => {
              setBankAccount(bankAccount.data);
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (
      flexOffer.workflow_state === 'account_linked' ||
      flexOffer.workflow_state === 'account_linked_failed'
    ) {
      fetchBankAccount();
    }
  }, [flexOffer.workflow_state, setLoading]);

  const acceptOffer = async () => {
    setLoading(true);
    const offerResponse = await axios.post('/flex_offers/accept_offer', {
      customer_id: customer.id,
    });
    const { data: offer } = deserialize(offerResponse.data);
    setFlexOffer(offer);
    setLoading(false);
  };

  const accountLinked = async () => {
    setLoading(true);
    const offerResponse = await axios.post('/flex_offers/account_linked', {
      customer_id: customer.id,
    });
    const { data: offer } = deserialize(offerResponse.data);
    setFlexOffer(offer);
    setLoading(false);
  };

  const confirmOffer = async () => {
    try {
      setLoading(true);
      const offerResponse = await axios.post('/flex_offers/confirm', { customer_id: customer.id });
      const { data: offer } = deserialize(offerResponse.data);
      setFlexOffer(offer);
    } catch (e) {
      if (guardAxiosError(e)) {
        addToast(
          'Something went wrong! Please try again or contact support if the issue persists',
          { appearance: 'error' },
        );
      }
    } finally {
      setLoading(false);
    }
  };

  switch (currentScreen) {
    case 'accept-offer':
      return (
        <UnlimitedToFlexMigrationAcceptOffer
          creditLimit={flexOffer.credit_limit}
          activationFee={flexOffer.fee_cents / 100}
          onNext={acceptOffer}
          onClose={closeModal}
        />
      );
    case 'link-account':
      return (
        <UnlimitedToFlexMigrationConnectBankAccount
          onNext={accountLinked}
          onBack={() => {
            setCurrentScreen('accept-offer');
          }}
          onClose={closeModal}
        />
      );
    case 'account-linked':
      return (
        <UnlimitedToFlexMigrationBankAccountConnected
          connectionStatus={flexOffer.workflow_state === 'account_linked'}
          bankName={bankAccount.attributes?.name}
          accountLast4={bankAccount.attributes?.mask}
          balanceRequirement={flexOffer.bank_balance_required_cents / 100}
          onClose={closeModal}
          onNext={() => {
            if (cardsOrdered) {
              setCurrentScreen('confirm-offer');
            } else {
              setCurrentScreen('confirm-address');
            }
          }}
          onBack={() => {
            setCurrentScreen('link-account');
          }}
        />
      );
    case 'confirm-address':
      return (
        <UnlimitedToFlexMigrationConfirmAddress
          shippingAddress={shippingAddress}
          onNext={() => {
            setCurrentScreen('confirm-offer');
          }}
          onBack={() => {
            setCurrentScreen('account-linked');
          }}
          onClose={closeModal}
        />
      );
    case 'confirm-offer':
      return (
        <UnlimitedToFlexMigrationConfirmOffer
          creditLimit={flexOffer.credit_limit}
          activationFee={flexOffer.fee_cents / 100}
          onNext={confirmOffer}
          onBack={() => {
            if (cardsOrdered) {
              setCurrentScreen('account-linked');
            } else {
              setCurrentScreen('confirm-address');
            }
          }}
          onClose={closeModal}
        />
      );
    case 'payment-processing':
      return <UnlimitedToFlexMigrationOfferAccepted onDone={closeModal} />;
    default:
      return null;
  }
};

const UnlimitedToFlexMigrationModal = ({
  isOpen,
  toggle,
  customer,
  flexOffer,
  setFlexOffer,
}: {
  isOpen: boolean;
  toggle: () => void;
  customer: CustomerData;
  flexOffer: FlexOfferResponse | null;
  setFlexOffer: React.Dispatch<React.SetStateAction<FlexOfferResponse>>;
}): ReactElement | null => {
  const [loading, setLoading] = useState<boolean>(false);

  if (flexOffer === null) return null;

  return (
    <Modal open={isOpen} toggle={toggle}>
      {loading && (
        <div className="w-full">
          <SpinnerBoundary />
        </div>
      )}
      <UnlimitedToFlexMigration
        closeModal={toggle}
        customer={customer}
        flexOffer={flexOffer}
        setFlexOffer={setFlexOffer}
        setLoading={setLoading}
      />
    </Modal>
  );
};

export default UnlimitedToFlexMigrationModal;
