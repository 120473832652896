import { ReactElement } from 'react';

interface CardHeaderProps {
  title: string;
}

const CardHeader = ({ title }: CardHeaderProps): ReactElement => {
  return (
    <div className="mb-1 space-y-2">
      <div className="text-sm font-medium uppercase not-italic text-black">{title}</div>
    </div>
  );
};

export default CardHeader;
