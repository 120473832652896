import { SettingsPageEntityType } from '@app/constants/settings';
import { SETTINGS_ROW_OPTIONS } from '@app/constants/settingsRow';
import { IRowProps } from '@app/pages/Settings/SettingsPageRow';
import { Button } from '@mui/material';
import { ReactElement } from 'react';
import { SettingsCurrentValue } from './SettingsCurrentValue';

export const SettingsMobileDropdown = ({
  type,
  onClickUpdate,
  value,
  disableUpdate,
}: IRowProps): ReactElement => {
  return (
    <>
      <div className="xs:basis-10/12 flex basis-0 flex-col  ">
        <p className="order-none mb-2 flex flex-none flex-grow-0 items-center self-stretch text-sm font-normal not-italic leading-6 text-gray-900">
          {SETTINGS_ROW_OPTIONS[type].title}
        </p>
        {type === SettingsPageEntityType.EMAIL_ADDRESS ||
        type === SettingsPageEntityType.PHONE_NUMBER ? (
          <p className="order-1 flex flex-none flex-grow-0 items-center self-stretch text-sm font-semibold not-italic leading-6 text-gray-900">
            <SettingsCurrentValue type={type} value={value} />
          </p>
        ) : (
          <div className="space-y-4">
            <SettingsCurrentValue type={type} value={value} />
          </div>
        )}
      </div>
      {!disableUpdate && (
        <div className=" w-full sm:w-fit ">
          <Button color="secondary" size="medium" onClick={onClickUpdate}>
            Update
          </Button>
        </div>
      )}
    </>
  );
};
