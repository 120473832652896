import { useContext, useMemo } from 'react';
import { AddToast, ToastContext } from '../components/ToastProvider';

export const useToasts = (): { addToast: AddToast } => {
  const addToast = useContext(ToastContext);

  return useMemo(
    () => ({
      addToast,
    }),
    [addToast],
  );
};
