import { Field, ErrorMessage } from 'formik';

interface PasswordInputFieldProps {
  label?: string;
  id: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  autocomplete?: string;
  dataTestId?: string;
}

const PasswordInputField = ({
  label = '',
  id,
  required = false,
  disabled = false,
  className = '',
  autocomplete = 'off',
  dataTestId = '',
}: PasswordInputFieldProps) => (
  <div className="field">
    <label className="label" htmlFor={id}>
      {label}
      {required && <span className="has-text-danger ml-1">*</span>}
    </label>
    <div className="control">
      <Field
        id={id}
        name={id}
        disabled={disabled}
        className={`${className} input border-gray-200`}
        autoComplete={autocomplete}
        data-testid={dataTestId}
        type="password"
      />
      <ErrorMessage name={id} component="p" className="help is-danger" />
    </div>
  </div>
);

export default PasswordInputField;
