import currency from 'currency.js';
import { useEffect, useState } from 'react';

export default function useAmountCents(
  initialAmount: string,
  onSetAmount: (amount: string) => void,
): [number, (value: string) => void] {
  const initialAmountInCents = currency(currency(initialAmount).value).multiply(100).value;

  const [amountToAddCents, setAmountToAddCents] = useState(initialAmountInCents);
  useEffect(() => {
    setAmountToAddCents(initialAmountInCents);
  }, [initialAmountInCents]);

  const setAmount = (value: string) => {
    const isNotANumber = Number.isNaN(parseFloat(value));
    const isNotEmpty = value !== '';

    if (isNotANumber && isNotEmpty) {
      return;
    }
    onSetAmount(value);
    setAmountToAddCents(convertAmountToCents(value));
  };

  return [amountToAddCents, setAmount];
}

export const convertAmountToCents = (amount: string): number => {
  const { value: amountToPayInCents } = currency(currency(amount).value).multiply(100);

  const amountToChargeCents = Number.isNaN(amountToPayInCents)
    ? '0'
    : amountToPayInCents.toString();

  return parseFloat(amountToChargeCents);
};
