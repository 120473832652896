import { PaginationMeta } from '@app/@types/api.types';
import { Spacer } from '@app/components/elements';
import { ErrorNotification } from '@app/components/layout';
import UnderlinedTabs from '@atob-developers/shared/src/components/UnderlinedTabs';
import { ReactElement, useState } from 'react';
import WalletCreditsTable from './WalletTransactions/WalletCreditsTable';
import WalletTransactionsTable from './WalletTransactions/WalletTransactionsTable';
import type { WalletCredit, WalletTransaction } from './Overview';
import type { GoToPageAction } from '@app/hooks/paging/types';

const WALLET_TAB: WalletTab[] = [
  {
    name: 'Transactions',
    tab: 'transactions',
  },
  {
    name: 'Cashback',
    tab: 'credits',
  },
];

type WalletTab = {
  name: 'Transactions' | 'Cashback';
  tab: 'transactions' | 'credits';
};

export default function WalletTransactions({
  transactionsPage,
  transactionsError,
  metadata,
  goToPage,
  credits,
}: {
  transactionsPage: WalletTransaction[];
  transactionsError: unknown;
  metadata: PaginationMeta | undefined;
  goToPage: GoToPageAction;
  credits: WalletCredit[];
}): ReactElement {
  const [currentTab, setCurrentTab] = useState(WALLET_TAB[0].tab);
  const showTransactions = currentTab === 'transactions';

  if (transactionsError) {
    return (
      <ErrorNotification error="We're having issues loading your transactions data. Please try again; if the issue persists, please contact support." />
    );
  }

  const TransactionsUI = () => {
    if (!showTransactions) {
      return null;
    }

    if (metadata == null || metadata?.pagination.total_count === 0) {
      return <NoTransactions />;
    }

    return (
      <WalletTransactionsTable
        transactionsPage={transactionsPage}
        metadata={metadata}
        onPageIndexChange={(pageIndex: number) => goToPage(pageIndex)}
      />
    );
  };

  const CreditsUI = () => {
    if (showTransactions) {
      return null;
    }

    return <WalletCreditsTable credits={credits} />;
  };

  return (
    <div>
      <h1 className="mb-6 font-[18px] font-medium">Wallet Transactions</h1>
      {credits.length > 0 && (
        <UnderlinedTabs
          selectedTab={currentTab}
          handleTabChange={(tab) => setCurrentTab(tab)}
          tabs={WALLET_TAB}
        />
      )}
      <Spacer />
      <TransactionsUI />
      <CreditsUI />
    </div>
  );
}

const NoTransactions = (): ReactElement => {
  return (
    <div className="flex h-64 w-full flex-col items-center justify-center">
      <img className="w-24 md:w-40" src="/card.svg" alt="Card" />
      <div className="mt-4 flex max-w-[300px] flex-col items-center text-center">
        <h2 className="text-[18px] font-semibold text-gray-500">No transactions found.</h2>
        <p className="text-[14px] text-gray-500">
          Transactions will display here when you start using AtoB Wallet.
        </p>
      </div>
    </div>
  );
};
