import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSWRConfig } from 'swr';

interface PaymentStatusContentProps {
  title: string;
  icon: IconProp;
  message?: string;
  iconClassName?: string;
  showButton?: boolean;
}

const PaymentStatusContent = ({
  title,
  icon,
  message,
  iconClassName,
  showButton = false,
}: PaymentStatusContentProps): ReactElement => {
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();
  return (
    <div className="w-full space-y-10 bg-white px-6 py-6 sm:px-8">
      <div className="flex h-full w-full flex-col items-center justify-center text-center">
        <FontAwesomeIcon
          className={`min-w-10 my-4 h-10 w-10 ${iconClassName ? iconClassName : ''}`}
          icon={icon}
        />
        <p className="text-black0 mb-4 text-2xl font-bold">{title}</p>
        <p className="text-center text-base font-medium text-gray-500">{message}</p>
        {showButton && (
          <div className="mb-6 mt-8 max-w-[162px]">
            <Button
              size="small"
              onClick={() => {
                mutate(
                  (_) => true, // which cache keys are updated
                  undefined, // update cache data to `undefined`
                  { revalidate: true }, // revalidate
                );
                navigate('/');
              }}
              data-testid="InfoModal-Button"
            >
              Back to Overview
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentStatusContent;
