import useCustomerOnboardingEvents, {
  CustomerOnboardingEventName,
} from '@app/hooks/query/useCustomerOnboardingEvents';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import { useShouldBeInOnboarding } from '../onboarding/useHasCompletedOnboarding';

export default function useIsAllowedToFund() {
  const [mustPayPreshipmentActivationFee] = useFeatureFlags('unlimited_preshipment_activation_fee');
  const shouldBeInOnboarding = useShouldBeInOnboarding();
  const customerOnboardingEvents = useCustomerOnboardingEvents();
  return (
    !shouldBeInOnboarding ||
    !mustPayPreshipmentActivationFee ||
    customerOnboardingEvents.has(CustomerOnboardingEventName.CUSTOMER_PAID_SETUP_FEE)
  );
}
