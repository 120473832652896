import Header from '@app/components/Navigation/Header';
import PageContentWrapper from '@app/components/wrappers/PageContentWrapper';
import UnderlinedTabs from '@atob-developers/shared/src/components/UnderlinedTabs';
import { ReactElement } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DriversTab } from './components/DriversTab';
import { VehiclesTab } from './components/VehiclesTab';

type FleetTab = 'drivers' | 'vehicles';

const FLEET_TABS: { tab: FleetTab; name: string }[] = [
  {
    tab: 'drivers',
    name: 'Drivers',
  },
  {
    tab: 'vehicles',
    name: 'Vehicles',
  },
];

export const Fleet = (): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();

  const tab = (location.pathname.replace('/fleet', '').split('/').pop() as FleetTab) || 'drivers';

  return (
    <PageContentWrapper
      header={<Header title={'Fleet'} />}
      baseClassName="gap-4 md:gap-4"
      pageTabs={
        <UnderlinedTabs
          selectedTab={tab}
          handleTabChange={(newTab) => {
            navigate(`/fleet/${newTab}`);
          }}
          tabs={FLEET_TABS}
        />
      }
    >
      {tab === 'drivers' ? <DriversTab /> : null}
      {tab === 'vehicles' ? <VehiclesTab /> : null}
    </PageContentWrapper>
  );
};
