import useThemeConfiguration from '@app/app/useThemeConfiguration';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, matchRoutes, RouteObject, useNavigate } from 'react-router-dom';

const NavigationLink = ({ href, children }: { href: string; children: React.ReactNode }) => {
  const navigate = useNavigate();
  const navigateAndPreventDefault = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    navigate(href);
  };

  return (
    <Link underline="always" color="inherit" href={href} onClick={navigateAndPreventDefault}>
      {children}
    </Link>
  );
};

const StyledBreadcrumbs = ({ children }: { children: React.ReactNode }) => (
  <Breadcrumbs aria-label="breadcrumb" classes={{ root: 'text-xs' }}>
    {children}
  </Breadcrumbs>
);

const HomeBreadcrumbLink = () => {
  const { t } = useTranslation();

  return <NavigationLink href="/">{t('program_name', 'AtoB')} Network</NavigationLink>;
};
const TransactionBreadcrumbLink = () => (
  <NavigationLink href="/transactions">Transactions</NavigationLink>
);

const TransactionsBreadcrumbs = () => (
  <StyledBreadcrumbs>
    <HomeBreadcrumbLink />
    <Typography color="text.primary" fontWeight={600} fontSize={'inherit'}>
      Transactions
    </Typography>
  </StyledBreadcrumbs>
);

const CardsBreadcrumbLink = () => <NavigationLink href="/cards">Cards</NavigationLink>;

const TransactionDetailsBreadcrumbs = () => (
  <StyledBreadcrumbs>
    <HomeBreadcrumbLink />
    <TransactionBreadcrumbLink />
    <Typography color="text.primary" fontWeight={600} fontSize={'inherit'}>
      Transaction Details
    </Typography>
  </StyledBreadcrumbs>
);

const CardsBreadcrumbs = () => (
  <StyledBreadcrumbs>
    <HomeBreadcrumbLink />
    <Typography color="text.primary" fontWeight={600} fontSize={'inherit'}>
      Cards
    </Typography>
  </StyledBreadcrumbs>
);

const CardDetailsBreadcrumbs = () => (
  <StyledBreadcrumbs>
    <HomeBreadcrumbLink />
    <CardsBreadcrumbLink />
    <Typography color="text.primary" fontWeight={600} fontSize={'inherit'}>
      Card Details
    </Typography>
  </StyledBreadcrumbs>
);

const SettingsBreadcrumbs = () => (
  <StyledBreadcrumbs>
    <HomeBreadcrumbLink />
    <Typography color="text.primary" fontWeight={600} fontSize={'inherit'}>
      Settings
    </Typography>
  </StyledBreadcrumbs>
);

const FuelMapBreadcrumbs = () => (
  <StyledBreadcrumbs>
    <HomeBreadcrumbLink />
    <Typography color="text.primary" fontWeight={600} fontSize={'inherit'}>
      Fuel Map
    </Typography>
  </StyledBreadcrumbs>
);

const FuelProgramBreadcrumbs = () => {
  const { t } = useTranslation();

  return (
    <StyledBreadcrumbs>
      <HomeBreadcrumbLink />
      <Typography color="text.primary" fontWeight={600} fontSize={'inherit'}>
        {t('program_name', 'AtoB')} Program
      </Typography>
    </StyledBreadcrumbs>
  );
};

const FleetBreadcrumbs = () => (
  <StyledBreadcrumbs>
    <HomeBreadcrumbLink />
    <Typography color="text.primary" fontWeight={600} fontSize={'inherit'}>
      Fleet
    </Typography>
  </StyledBreadcrumbs>
);

const BillingBreadcrumbs = () => (
  <StyledBreadcrumbs>
    <HomeBreadcrumbLink />
    <Typography color="text.primary" fontWeight={600} fontSize={'inherit'}>
      Billing & Statements
    </Typography>
  </StyledBreadcrumbs>
);

const routes: RouteObject[] = [
  { path: '/transactions/:id', element: <TransactionDetailsBreadcrumbs /> },
  { path: '/transactions', element: <TransactionsBreadcrumbs /> },
  { path: '/cards/:id', element: <CardDetailsBreadcrumbs /> },
  { path: '/cards', element: <CardsBreadcrumbs /> },
  { path: '/settings/*', element: <SettingsBreadcrumbs /> },
  { path: '/fuel-map', element: <FuelMapBreadcrumbs /> },
  { path: '/fuel-programs', element: <FuelProgramBreadcrumbs /> },
  { path: '/fleet/*', element: <FleetBreadcrumbs /> },
  { path: '/billing/*', element: <BillingBreadcrumbs /> },
  { path: '/', element: null },
];

export const DynamicBreadcrumbs = () => {
  const { showAppNav } = useThemeConfiguration();
  const location = useLocation();

  if (showAppNav) {
    return null;
  }

  const match = matchRoutes(routes, location);

  // If a matching route is found and it has an element, return that element to be rendered
  if (match && match.length > 0 && match[0].route.element) {
    return <div className="mb-6">{match[0].route.element}</div>;
  }

  return null;
};
