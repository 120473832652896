import { AcceptedAccountPromotionsResponse } from '@app/@types/promotion.types';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import useSWR, { SWRResponse } from 'swr';

export const useAcceptedAccountPromotionsQuery = (
  scope: 'path_to_credit' | 'account_setup_fee' | null,
): SWRResponse<AcceptedAccountPromotionsResponse, Error> => {
  const params: {
    include: 'account_promotion';
    path_to_credit?: boolean;
    account_setup_fee?: boolean;
  } = { include: 'account_promotion' };
  if (scope === 'path_to_credit') {
    params.path_to_credit = true;
  } else if (scope === 'account_setup_fee') {
    params.account_setup_fee = true;
  }
  return useSWR<AcceptedAccountPromotionsResponse>(
    {
      url: '/treasury/account_promotions/accepted_promotions',
      params: params,
    },
    apiGetFetcher,
  );
};
