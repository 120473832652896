import { Switch } from '@mui/material';
import { useState } from 'react';
import { CustomTooltip } from './Tooltip';

interface ToggleProps {
  enabled: boolean;
  setEnabled: () => void;
  canChange?: boolean;
  disabledText?: string;
}

const Toggle = ({ enabled, setEnabled, canChange = true, disabledText }: ToggleProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const enableSwitch = () => {
    if (!canChange) {
      setShowTooltip(!showTooltip);
      return;
    }
    setEnabled();
  };

  const switchEl = <Switch checked={enabled} onChange={enableSwitch} disabled={!canChange} />;

  if (canChange || !disabledText) {
    return switchEl;
  }

  return (
    <CustomTooltip target={<span>{switchEl}</span>} align="bottom-end">
      {disabledText}
    </CustomTooltip>
  );
};

export default Toggle;
