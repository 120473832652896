import useThemeConfiguration from '@app/app/useThemeConfiguration';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Breakpoint,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { ReactElement } from 'react';
import useLocalStorage from './useLocalStorage';

const MarketingPopup = ({
  storageKey,
  headerImg,
  heading,
  body,
  maxWidth,
  footer,
}: {
  storageKey: string;
  headerImg?: string;
  heading: string;
  body: string | ReactElement;
  maxWidth?: Breakpoint;
  footer?: (dismiss: () => void) => ReactElement;
}): ReactElement | null => {
  const [dismissed, setDismissed] = useLocalStorage(storageKey, false);
  const { hideDismissableBanners } = useThemeConfiguration();

  if (dismissed || hideDismissableBanners) {
    return null;
  }

  const dismiss = () => {
    setDismissed(true);
  };

  const closeIcon = (
    <IconButton className="absolute right-4 top-4" onClick={dismiss}>
      <FontAwesomeIcon icon={faXmark} className="h-5 w-5" />
    </IconButton>
  );

  return (
    <Dialog open={true} onClose={() => setDismissed(true)} maxWidth={maxWidth ?? 'lg'}>
      {headerImg ? (
        <>
          <div className="flex flex-row items-center">
            <img src={headerImg} alt="popup-header" className="w-full" />
            <div className="absolute right-0 top-0">{closeIcon}</div>
          </div>
          <DialogTitle>
            <span>{heading}</span>
          </DialogTitle>
        </>
      ) : (
        <div>
          <DialogTitle className="flex flex-row items-center justify-between">
            <span>{heading}</span>
            {closeIcon}
          </DialogTitle>
        </div>
      )}
      <DialogContent>
        <DialogContentText>{body}</DialogContentText>
      </DialogContent>
      {footer && (
        <DialogActions>
          <div className="flex w-full flex-col justify-end gap-2 md:flex-row">
            {footer(dismiss)}
          </div>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default MarketingPopup;
