import { SortBy } from '@app/hooks/paging/types';

export const TOGGLE_SORT = 'toggle-sort' as const;
export const SET_SORT = 'set-sort' as const;

export type SortActionTypes = typeof TOGGLE_SORT | typeof SET_SORT;
export type SortReducerAction = {
  type: SortActionTypes;
  sort: SortBy;
};

export type UseSortReducerReturn = {
  sort: SortBy;
  toggleSort: (field: string) => void;
  setSort: (sort: SortBy) => void;
};

export type SortReducerDispatch = (
  action: SortActionTypes,
  field: string,
  order?: 'asc' | 'desc',
) => void;

// These properties are drilled five layers deep.
// TODO: Move to a React.Context once ban on Context is lifted.
export type SortablePropDrill = {
  sort: SortBy;
  toggleSort: (field: string) => void;
};
