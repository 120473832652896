import { FuelPrice, PriceListingType } from '@app/@types/fuel_listings.types';
import { getIsValidPrice } from '@app/utils/fuel-listings';

export const usePriceListingType = (price: FuelPrice) => {
  const { discount_type, discount_per_gallon_cents } = price.attributes;

  const isGuaranteedDiscount = discount_type === 'RETAIL_MINUS';
  const isFuelSignTimeLessThanDay = getIsValidPrice(price);

  if (discount_per_gallon_cents === 0 && isFuelSignTimeLessThanDay) {
    return PriceListingType.NO_DISCOUNT_VALID_PRICE;
  }

  if (discount_per_gallon_cents === 0 && !isFuelSignTimeLessThanDay) {
    return PriceListingType.NO_DISCOUNT_INVALID_PRICE;
  }

  if (isGuaranteedDiscount && isFuelSignTimeLessThanDay) {
    return PriceListingType.GUARANTEED_DISCOUNT_VALID_PRICE;
  }

  if (isGuaranteedDiscount && !isFuelSignTimeLessThanDay) {
    return PriceListingType.GUARANTEED_DISCOUNT_INVALID_PRICE;
  }

  if (!isGuaranteedDiscount && isFuelSignTimeLessThanDay) {
    return PriceListingType.GUARANTEED_PRICE_VALID_PRICE;
  }

  if (!isGuaranteedDiscount && !isFuelSignTimeLessThanDay) {
    return PriceListingType.GUARANTEED_PRICE_INVALID_PRICE;
  }

  return null;
};
