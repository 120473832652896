import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputHTMLAttributes } from 'react';

type SearchbarProps = {
  name: string;
} & InputHTMLAttributes<HTMLInputElement>;

export default function Searchbar({
  placeholder,
  onChange,
  name,
  value,
  'aria-label': ariaLabel,
}: SearchbarProps) {
  return (
    <div className="flex h-[40px] items-center rounded-lg border-[1px] border-solid border-gray-200 bg-white">
      <FontAwesomeIcon color="#676D7C" className="fill-slate-50 pl-4" icon={faMagnifyingGlass} />
      <input
        aria-label={ariaLabel}
        data-testid="search-bar"
        name={name}
        onChange={onChange}
        value={value}
        className="w-full px-2 text-sm placeholder-gray-500 focus-visible:outline-none"
        placeholder={placeholder || 'Search'}
      />
    </div>
  );
}
