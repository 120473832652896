import { AccountLimitsData } from '@app/@types/account_limits.types';
import { CreditLimitIncreaseRequestButton } from '@app/components/Modals/CreditLimitIncreaseRequest/CreditLimitIncreaseRequestModal';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import { ReactElement } from 'react';
import useSWR from 'swr';

export const AccountLimits = ({ isPrepaid }: { isPrepaid: boolean }): ReactElement => {
  const [isCliButtonEnabled] = useFeatureFlags('cli_requests_enabled');
  const { data: accountLimits, isLoading: loading } = useSWR<AccountLimitsData>({
    url: `/account_limits`,
  });

  return (
    <div>
      {loading && <SpinnerBoundary />}
      {!loading && (
        <div className="flex flex-col gap-4">
          {!isPrepaid && (
            <>
              <RoundedCard className="flex flex-wrap items-center justify-between gap-4 p-4 text-sm">
                <div>
                  <p className="font-semibold">Weekly Credit Limit</p>
                  <p>{accountLimits?.weekly_credit_limit}</p>
                </div>
                {isCliButtonEnabled && <CreditLimitIncreaseRequestButton />}
              </RoundedCard>
              <RoundedCard className="p-4 text-sm">
                <p className="font-semibold">Net Term</p>
                <p>{accountLimits?.net_terms}</p>
              </RoundedCard>
            </>
          )}
          <RoundedCard className="p-4 text-sm">
            <p className="font-semibold">Spend Categories</p>
            {accountLimits?.spend_categories.map((category, index) => (
              <p key={index}>{category}</p>
            ))}
          </RoundedCard>
          <RoundedCard className="p-4 text-sm">
            <p className="font-semibold">Account Balance</p>
            <p>{accountLimits?.account_balance}</p>
          </RoundedCard>
        </div>
      )}
    </div>
  );
};
