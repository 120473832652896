import { ChargeEventData } from '@app/@types/charge_events.types';
import useThemeConfiguration from '@app/app/useThemeConfiguration';
import CustomerContext from '@app/contexts/customerContext';
import { useLineItemsQuery } from '@app/hooks/query/useLineItemsQuery';
import useProduct from '@app/hooks/useProduct';
import { IconDefinition, faOctagonExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress } from '@mui/material';
import { PropsWithChildren, ReactElement, Suspense, useContext } from 'react';
import { ChargeEventDetailsBar } from './ChargeEventDetailsBar';
import { ChargeEventDriverDetails } from './ChargeEventDriverDetails';
import { ChargeEventFuelLevelDetails } from './ChargeEventFuelLevelDetails';
import ChargeEventFuelLevelsChart from './ChargeEventFuelLevelsChart';
import ChargeEventPurchaseTypeDetails from './ChargeEventPurchaseTypeDetails';
import { ChargeEventTransactionDetails } from './ChargeEventTransactionDetails';
import { ChargeEventUpsellPremium, ChargeEventUpsellTelematics } from './ChargeEventUpsell';
import { ChargeEventVehicleDetails } from './ChargeEventVehicleDetails';
import ChargeEventVehicleLocation from './ChargeEventVehicleLocation';

const BannerWidget = ({
  icon,
  headStyle,
  children,
}: PropsWithChildren<{
  icon: IconDefinition;
  headStyle: string;
}>): ReactElement => {
  return (
    <div className="flex flex-row bg-white">
      <div
        className={`min-h-14 align-center flex w-14 items-center justify-center text-sm ${headStyle}`}
      >
        <FontAwesomeIcon icon={icon} className="h-6 w-6 p-4 text-white" />
      </div>
      <div className="align-center min-h-14 flex items-center justify-center px-4 text-center">
        {children}
      </div>
    </div>
  );
};

const ChargeEventTransactionBanner = ({
  chargeEvent,
}: {
  chargeEvent?: ChargeEventData;
}): ReactElement | null => {
  if (!chargeEvent?.suspicious_activities_summary) {
    return null;
  }

  // Temporary copy change to suspicious activities summary header when obsolete data
  // is the reason provided. We don't want to explicitly call it a suspicious activity
  // since we currently do not show this reason in the Suspicious Activities tab
  const bannerHeader = chargeEvent.suspicious_activities_summary.includes(
    'the vehicle has not been in operation for an extended time',
  )
    ? 'Obsolete Vehicle Data: '
    : 'Suspicious Activities Detected: ';

  return (
    <BannerWidget icon={faOctagonExclamation} headStyle="bg-dark-orange">
      <span>
        <span className="font-semibold">{bannerHeader}</span>
        {chargeEvent.suspicious_activities_summary}
      </span>
    </BannerWidget>
  );
};

const ChargeEventTelematics = ({ chargeEvent }: { chargeEvent: ChargeEventData }): ReactElement => {
  return (
    <div className="rounded-card flex flex-col gap-2 rounded-lg bg-white p-4 pt-6 md:p-6">
      <div className="mb-4 flex flex-row gap-4">
        <div className="flex flex-row">
          <div className="text-base font-semibold">Telematics Fraud Protection</div>

          <div className="text-blue1 border-blue1 ml-4 flex h-6 items-center rounded-md border px-2.5 text-xs">
            Premium
          </div>
        </div>
      </div>

      <div className="grid gap-2 lg:grid-cols-2">
        <div className="min-w-fit flex-1 rounded-lg border">
          <ChargeEventVehicleLocation chargeEvent={chargeEvent} />
        </div>
        <div className="min-w-fit flex-1 rounded-lg border">
          <Suspense fallback={<CircularProgress />}>
            <ChargeEventFuelLevelsChart chargeEvent={chargeEvent} />
          </Suspense>
        </div>
      </div>
    </div>
  );
};

const TelematicsMapAndFuelLevels = ({
  hasPremiumProduct,
  telematicsDataAvailable,
  chargeEvent,
}: {
  hasPremiumProduct: boolean;
  telematicsDataAvailable: boolean;
  chargeEvent: ChargeEventData;
}): ReactElement | null => {
  const { supportsLocalStorage, showPremiumUpsell } = useThemeConfiguration();

  if (hasPremiumProduct && telematicsDataAvailable) {
    return <ChargeEventTelematics chargeEvent={chargeEvent} />;
  }

  if (!supportsLocalStorage) {
    return null;
  }

  if (hasPremiumProduct && !telematicsDataAvailable) {
    return <ChargeEventUpsellTelematics />;
  }

  if (!showPremiumUpsell) {
    return null;
  }

  return <ChargeEventUpsellPremium />;
};

export const ChargeEventDetails = ({
  chargeEvent,
}: {
  chargeEvent: ChargeEventData;
}): ReactElement => {
  const transactionId = chargeEvent?.charge_eventable_id;

  const { customer } = useContext(CustomerContext);
  const [hasPremiumProduct] = useProduct('premium');

  const { data: lineItemsResponse } = useLineItemsQuery(transactionId);
  const lineItemsData = lineItemsResponse?.data;

  const telematicsDataAvailable = customer.telematics_enabled;

  return (
    <div className="flex flex-col gap-4 text-[14px]">
      <div className="flex flex-col gap-2">
        <ChargeEventTransactionBanner chargeEvent={chargeEvent} />
      </div>

      {/* Overall info area */}
      <ChargeEventDetailsBar chargeEvent={chargeEvent} />

      <TelematicsMapAndFuelLevels
        hasPremiumProduct={hasPremiumProduct}
        chargeEvent={chargeEvent}
        telematicsDataAvailable={telematicsDataAvailable}
      />

      {/* Details area */}
      <div className="rounded-card flex flex-col gap-3 rounded-lg bg-white pb-4">
        <ChargeEventTransactionDetails chargeEvent={chargeEvent} />
        <ChargeEventPurchaseTypeDetails chargeEvent={chargeEvent} lineItemsData={lineItemsData} />
        <ChargeEventDriverDetails chargeEvent={chargeEvent} />
        <ChargeEventVehicleDetails chargeEvent={chargeEvent} />
        <ChargeEventFuelLevelDetails chargeEvent={chargeEvent} />
      </div>
    </div>
  );
};
