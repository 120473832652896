import { FeesInfo, PaymentMethodCombined } from '@app/@types/payments.types';
import StripeContext from '@app/contexts/stripeContext';
import { useContext, useEffect } from 'react';

import useStripeIntent from './use-stripe-intent';

type FieldType<T> = string | number | boolean | T | string[] | number[] | T[] | null;
export type PaymentIntentOptions = { [key: string]: FieldType<PaymentIntentOptions> };

/**
 * Payment intent that accepts both debit cards and bank accounts as payment sources.
 */
export const usePaymentIntent = (
  paymentIntentConfirmed: boolean,
  beforeFeesAmount: number,
  fees: FeesInfo | null,
  type: string,
  paymentMethod: PaymentMethodCombined,
  options?: PaymentIntentOptions,
): { error: string | null; loading: boolean } => {
  const {
    resetPaymentIntent,
    loadPaymentIntent,
    idempotencyKey,
    generateIdempotencyKey,
    loadingIntent,
  } = useContext(StripeContext);

  useEffect(() => {
    generateIdempotencyKey();

    if (paymentIntentConfirmed) {
      loadPaymentIntent();
    } else {
      resetPaymentIntent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentIntentConfirmed]);

  const shouldReturnEarly = !idempotencyKey || !paymentIntentConfirmed || !paymentMethod?.id;
  const payload = {
    intent: {
      idempotency_key: idempotencyKey,
      type,
      amount: beforeFeesAmount + (fees?.amount_in_cents ?? 0),
      payment_method_id: paymentMethod?.id,
      ...options,
      ...feeInfoToParameters(fees),
    },
  };

  const { error } = useStripeIntent(shouldReturnEarly, payload);

  return {
    error: error,
    loading: loadingIntent,
  };
};

const feeInfoToParameters = (fees: FeesInfo | null): Record<string, number> => {
  if (fees === null) {
    return {};
  }

  return {
    application_fee_in_cents: fees.amount_in_cents,
  };
};
