import Constants from '@app/utils/constants';
import { Chart as ChartJS } from 'chart.js';
import { useLayoutEffect } from 'react';
import useChannelPartner from './useChannelPartner';

export default function useChartJSStyling() {
  const { partnerName } = useChannelPartner();

  useLayoutEffect(() => {
    // Sets the default font family for ChartJS charts based on our white-labeling.
    ChartJS.defaults.font.family =
      partnerName === Constants.ChannelPartners.UBER_FREIGHT_CHANNEL_PARTNER_NAME
        ? 'MoveText'
        : 'Inter';
  }, [partnerName]);
}
