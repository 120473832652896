import { EndpointResponse } from '@app/@types/api.types';
import { TagData } from '@app/@types/tag.types';
import {
  FetcherKey,
  apiDeleteFetcher,
  apiGetFetcher,
  apiPostFetcher,
  apiPutFetcher,
} from '@app/utils/data/fetchers';
import { AxiosError } from 'axios';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

const ALL_TAGS_KEY = {
  url: '/tags/',
  params: {
    'all': 'true',
    'sort[]': 'name:asc',
  },
};

export function useTags() {
  return useSWR<EndpointResponse<TagData[]>>(ALL_TAGS_KEY, apiGetFetcher);
}

export function useTag(tagId?: string) {
  return useSWR<EndpointResponse<TagData>>(!!tagId && `/tags/${tagId}`, apiGetFetcher);
}

export function useCreateTag() {
  return useSWRMutation<
    EndpointResponse<TagData>,
    AxiosError,
    FetcherKey,
    { tag: Partial<TagData> },
    EndpointResponse<TagData[]>
  >(ALL_TAGS_KEY, apiPostFetcher, {
    populateCache: (updatedTag, allTags) => {
      const filteredTags = allTags?.data.filter((tag) => tag.id !== updatedTag?.data?.id);
      return {
        data: [...(filteredTags || []), updatedTag.data].sort((t1, t2) =>
          // We do the same sorting here as 'sort[]': 'name:asc',
          t1.name.localeCompare(t2.name),
        ),
      };
    },
    revalidate: false,
  });
}

export function useUpdateTag(tagId?: string) {
  return useSWRMutation<
    EndpointResponse<TagData>,
    AxiosError,
    FetcherKey,
    { tag: Partial<TagData> }
  >(`/tags/${tagId}`, apiPutFetcher, { populateCache: true });
}

export function useDeleteTag(tagId?: string) {
  return useSWRMutation<EndpointResponse<TagData>, AxiosError, FetcherKey>(
    `/tags/${tagId}`,
    apiDeleteFetcher,
  );
}
