import { ReactElement } from 'react';

export default function TermsAndConditions({ onClick }: { onClick: () => void }): ReactElement {
  return (
    <div>
      <h3 className="text-md mb-2 font-bold">
        Automatic and Recurring Card Payment Authorization Form
      </h3>

      <div className="text-xs">
        <p className="mb-4">
          {/* eslint-disable no-explicit-atob-mentions */}
          {/* We use AtoB explicitly since we are the entity providing the fuel card */}
          This authorization allows CELegans Labs, Inc. d/b/a AtoB (&quot;AtoB&quot;) to debit your
          linked card to pay amounts charged to your AtoB Account. We will use the card payment
          system to debit the card you provided in accordance with our Terms and Conditions. Our
          debits will be for the total amount due unless we notify you otherwise or you revoke this
          authorization. A receipt will be emailed to you and the charge will appear on the bank
          statement linked with your card. You agree that no prior notification will be provided of
          each recurring debit.
        </p>
        <p className="mb-4">
          The person providing the authorization certifies that he or she is an authorized
          representative of the Company (as defined in the Terms and Conditions) and that this
          person has the authority to authorize recurring card payments on the Company&apos;s
          behalf.
        </p>
        <p className="mb-4">
          The Company understands that this authorization will remain in effect until it is canceled
          in writing, and agrees to notify AtoB in writing of any changes in its account information
          or termination of this authorization at least 15 days prior to the next invoice due date.
          If the payment dates fall on a weekend or holiday, Company understands that the payments
          may be executed on the next business day. Company understands that because these are
          electronic transactions, these funds may be withdrawn from its account as soon as the due
          date and that it will have limited time to report and dispute errors.
        </p>

        <p className="mb-4">
          Company has certified that the provided business card is enabled for debit transactions,
          and agrees to reimburse AtoB for all penalties and fees incurred as a result of
          Company&apos;s bank rejecting debits or credits as a result of the card not being properly
          configured for automatic and recurring transactions. Both parties agree to be bound by the
          card network Rules as they pertain to these transactions. Company acknowledges that the
          origination of the automatic card transactions must comply with the provisions of U.S.
          law. Company agrees not to dispute these scheduled transactions with its bank provided the
          transactions correspond to the terms indicated on this authorization form.
        </p>
        {/* eslint-enable no-explicit-atob-mentions */}
        <button
          type="button"
          className={`text-md hover:bg-grey-300 focus:ring-green-900" mt-2
              inline-flex items-center rounded border
              border-gray-700 bg-white px-4 py-2
              font-medium text-black focus:outline-none
              focus:ring-2 focus:ring-offset-2
            `}
          data-testid="add-payroll-driver"
          onClick={onClick}
        >
          Go back
        </button>
      </div>
    </div>
  );
}
