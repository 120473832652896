import { ReactElement } from 'react';
import { Step, StepStatus, PlaceholderStep } from '../steps.types';

export const isPlaceholder = (step: Step): step is PlaceholderStep =>
  step.status === StepStatus.PLACEHOLDER;

export const Placeholder = ({ step }: { step: PlaceholderStep }): ReactElement => {
  const { id } = step;
  return (
    <a data-testid={`placeholder-${id}`} className="group flex w-full cursor-default">
      <span className="flex items-center px-6 py-4 text-sm font-medium" />
    </a>
  );
};
