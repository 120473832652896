import { getFetcher } from '@app/utils/data/fetchers';
import useSWR, { SWRResponse } from 'swr';

type AstraOnboarding = {
  onboarding_status: string;
  business_profile_id: string;
};

export const useAstraOnboardingStatusQuery = (): SWRResponse<AstraOnboarding> => {
  return useSWR<AstraOnboarding>({ url: '/treasury/astra/onboarding' }, getFetcher);
};
