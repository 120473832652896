import { useExtendMobileHeaderContextMenu } from '@app/contexts/MobileNavHeaderMenuContextComponent';
import useIsAllowedToFund from '@app/utils/funding/useIsAllowedToFund';
import { Button } from '@mui/material';
import axios from 'axios';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import AddFundsModal, { AddFundsScenario } from './AddFundsModal';
import type { AddressType } from '@app/@types/customer.types';

interface AddFundsProps {
  onAddFunds: () => void;
  customerCompanyName: string;
  customerCompanyAddress: AddressType;
  accountNumber?: string | null;
  routingNumber?: string | null;
}

export default function AddFunds({
  onAddFunds,
  accountNumber,
  routingNumber,
  customerCompanyName,
  customerCompanyAddress,
  buttonProps,
}: AddFundsProps & {
  buttonProps?: {
    disabled?: boolean;
    text?: string;
    className?: string;
    primary?: boolean;
    dark?: boolean;
    fullWidth?: boolean;
  };
}): ReactElement {
  const allowedToFund = useIsAllowedToFund();
  const [addFundsModalActive, setAddFundsModalActive] = useState(false);
  const menuItem = useMemo(
    () => ({
      value: buttonProps?.text || 'Add Funds',
      onClick: () => setAddFundsModalActive(true),
      order: 1,
      disabled: () => buttonProps?.disabled || !allowedToFund,
    }),
    [allowedToFund, buttonProps?.disabled, buttonProps?.text],
  );

  useExtendMobileHeaderContextMenu(menuItem);

  return (
    <>
      <Button
        color={
          !buttonProps?.disabled && !buttonProps?.primary && !buttonProps?.dark
            ? 'secondary'
            : 'primary'
        }
        disabled={buttonProps?.disabled || !allowedToFund}
        fullWidth={buttonProps?.fullWidth}
        onClick={() => setAddFundsModalActive(true)}
      >
        {buttonProps?.text || 'Add Funds'}
      </Button>
      <AddFundsModalDataWrapper
        onAddFunds={onAddFunds}
        accountNumber={accountNumber}
        routingNumber={routingNumber}
        customerCompanyName={customerCompanyName}
        customerCompanyAddress={customerCompanyAddress}
        modalActive={addFundsModalActive}
        setModalActive={setAddFundsModalActive}
        scenario={'add_funds'}
      />
    </>
  );
}

export const AddFundsModalDataWrapper = ({
  onAddFunds,
  accountNumber,
  routingNumber,
  customerCompanyName,
  customerCompanyAddress,
  modalActive,
  setModalActive,
  minimumDepositAmountCents,
  initialAmount,
  initialDescription,
  scenario,
}: AddFundsProps & {
  modalActive: boolean;
  setModalActive: (_: boolean) => void;
  minimumDepositAmountCents?: number;
  initialAmount?: number;
  initialDescription?: string;
  scenario: AddFundsScenario;
}): ReactElement => {
  const [[accountNum, routingNum], setAccountAndRoutingNumber] = useState([
    accountNumber,
    routingNumber,
  ]);

  async function fetchAccountDetails() {
    const financialAccountResponse = await axios.get('/treasury/financial_account', {
      params: { expand_account_number: true },
    });

    const { account_number, routing_number } = financialAccountResponse.data.data.attributes;
    setAccountAndRoutingNumber([account_number, routing_number]);
  }

  useEffect(() => {
    if (!accountNum || !routingNum) {
      fetchAccountDetails();
    }
  }, [accountNum, routingNum]);

  return (
    <AddFundsModal
      open={modalActive}
      reset={() => setModalActive(false)}
      onAddFunds={onAddFunds}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      accountNumber={accountNum}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      routingNumber={routingNum}
      customerCompanyName={customerCompanyName}
      customerCompanyAddress={customerCompanyAddress}
      minimumDepositAmountCents={minimumDepositAmountCents}
      initialAmount={initialAmount}
      initialDescription={initialDescription}
      scenario={scenario}
    />
  );
};
