import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCircleXmark, faUserCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { HTMLAttributes } from 'react';

type DropdownItemProps = {
  name: string;
  selected?: boolean;
  onRemove?: () => void;
  icon?: IconDefinition;
} & HTMLAttributes<HTMLDivElement>;

const DropdownItem = ({ name, onClick, selected, onRemove, icon }: DropdownItemProps) => (
  <div
    className={classNames('hover:bg-green8 flex w-full gap-3 rounded-3xl px-4 py-3', {
      'bg-[#F8F8F8]': !!onRemove,
      'text-atob-green': selected,
      'text-grey2': !selected,
    })}
    onClick={!onRemove ? onClick : undefined}
  >
    <FontAwesomeIcon icon={icon ?? faUserCircle} className={'h-6 w-6 self-center'} />
    <p
      className={classNames('w-full self-center text-base leading-5', {
        'cursor-pointer': !!onClick,
      })}
      onClick={onClick}
    >
      {name}
    </p>
    {!!onRemove && (
      <FontAwesomeIcon
        icon={faCircleXmark}
        className="text-icon-grey h-6 w-6 cursor-pointer"
        onClick={onRemove}
      />
    )}
  </div>
);

export default DropdownItem;
