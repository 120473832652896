import DropdownCard from '@app/components/DropdownCard/DropdownCard';
import Modal from '@atob-developers/shared/src/components/Modal';
import { useMediaQuery, useTheme } from '@mui/material';
import classNames from 'classnames';
import { PropsWithChildren, ReactElement } from 'react';

interface FloatingDropdownProps {
  open: boolean;
  toggle: (open: boolean) => void;
  title: string;
  showRight?: boolean;
  anchorEl: Element | null;
}

export const MobileFloatingDropdown = ({
  title,
  open,
  toggle,
  children,
}: PropsWithChildren<FloatingDropdownProps>): ReactElement => (
  <Modal open={open} toggle={() => toggle(false)}>
    <div className="max-h-[400px] overflow-y-scroll">
      <div className="divide-y-[1px] divide-gray-200">
        <p className="p-3 text-center text-sm font-semibold text-gray-700">{title}</p>
        {children}
      </div>
    </div>
  </Modal>
);

export const DesktopFloatingDropdown = ({
  open,
  toggle,
  children,
  anchorEl,
}: PropsWithChildren<FloatingDropdownProps>): ReactElement => {
  return (
    <DropdownCard
      noPadding
      anchor={anchorEl}
      show={open}
      close={() => toggle(!open)}
      className={classNames(
        'absolute mx-2 mb-0 mt-11 flex max-h-[400px] w-full max-w-sm flex-col overflow-y-scroll rounded bg-white shadow-md',
        {
          'hidden md:flex': open,
          'hidden': !open,
        },
      )}
    >
      <div className="divide-blue0 divide-y-[1px]">{children}</div>
    </DropdownCard>
  );
};

const FloatingDropdown = ({
  open,
  toggle,
  title,
  children,
  showRight,
  anchorEl,
}: PropsWithChildren<FloatingDropdownProps>): ReactElement => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <>
      {isDesktop ? (
        <DesktopFloatingDropdown
          open={open}
          toggle={toggle}
          showRight={showRight}
          title={title}
          anchorEl={anchorEl}
        >
          {children}
        </DesktopFloatingDropdown>
      ) : (
        <MobileFloatingDropdown title={title} open={open} toggle={toggle} anchorEl={anchorEl}>
          {children}
        </MobileFloatingDropdown>
      )}
    </>
  );
};

export default FloatingDropdown;
