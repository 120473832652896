import { ComponentType, Fragment } from 'react';
import OnboardingAppAdminDownloadStep, {
  OnboardingAppAdminDownloadUpsell,
} from './OnboardingAppAdminDownloadStep';
import OnboardingAppNotifStep, { OnboardingAppNotifUpsell } from './OnboardingAppNotifStep';
import OnboardingBankStep, { OnboardingBankUpsell } from './OnboardingBankStep';
import OnboardingCompletedStep from './OnboardingCompletedStep';
import OnboardingInviteDriversStep, {
  OnboardingInviteDriversUpsell,
} from './OnboardingInviteDriversStep';
import OnboardingSetupFeeStep, { OnboardingSetupFeeUpsell } from './OnboardingSetupFeeStep';
import OnboardingTelematicsStep, { OnboardingTelematicsUpsell } from './OnboardingTelematicsStep';
import OnboardingWelcomeStep from './OnboardingWelcomeStep';

export type OnboardingStep = {
  stepComponent: ComponentType;
  upsellComponent: ComponentType;
  hideProgress?: true;
  blurb: string | null;
};

export const STEP_MAP: { [key: string]: OnboardingStep } = {
  welcome: {
    stepComponent: OnboardingWelcomeStep,
    upsellComponent: Fragment,
    hideProgress: true,
    blurb: null,
  },
  setup_fee: {
    stepComponent: OnboardingSetupFeeStep,
    upsellComponent: OnboardingSetupFeeUpsell,
    blurb: 'Get your cards shipped',
  },
  bank_account: {
    stepComponent: OnboardingBankStep,
    upsellComponent: OnboardingBankUpsell,
    blurb: 'Connect a bank account',
  },
  telematics: {
    stepComponent: OnboardingTelematicsStep,
    upsellComponent: OnboardingTelematicsUpsell,
    blurb: 'Sync your telematics',
  },
  app_notification: {
    stepComponent: OnboardingAppNotifStep,
    upsellComponent: OnboardingAppNotifUpsell,
    blurb: 'Setup discount notifications',
  },
  app_download: {
    stepComponent: OnboardingAppAdminDownloadStep,
    upsellComponent: OnboardingAppAdminDownloadUpsell,
    blurb: 'Download the AtoB App',
  },
  invite_drivers: {
    stepComponent: OnboardingInviteDriversStep,
    upsellComponent: OnboardingInviteDriversUpsell,
    blurb: 'Invite your drivers',
  },
  complete_onboarding: {
    stepComponent: OnboardingCompletedStep,
    upsellComponent: Fragment,
    blurb: null,
    hideProgress: true,
  },
};
