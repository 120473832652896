import axios from 'axios';
import { useState } from 'react';
import type { WithdrawFeeData, WithdrawMethod } from './transfer.types';

type FeeConfiguration = {
  cents: number;
  percentage: number;
};

type FeeResponse = {
  data: Record<WithdrawMethod, FeeConfiguration>;
};

export default function useWithdrawFees(): {
  selectedAccountId: string | null;
  setSelectedAccountId: (selectedAccountId: string) => void;
  withdrawMethodFees: WithdrawFeeData[];
  loadingFees: boolean;
  selectAccountIdAndGetFees: (transferType: 'inbound' | 'outbound', accountId: string) => void;
  selectedTransferType: 'inbound' | 'outbound' | null;
  reset: () => void;
} {
  const [selectedAccountId, setSelectedAccountId] = useState<string | null>(null);
  const [withdrawMethodFees, setWithdrawMethodFees] = useState<WithdrawFeeData[]>([]);
  const [loadingFees, setLoadingFees] = useState(false);
  const [selectedTransferType, setSelectedTransferType] = useState<'inbound' | 'outbound' | null>(
    null,
  );

  const selectAccountIdAndGetFees = async (
    transferType: 'inbound' | 'outbound',
    accountId: string,
  ): Promise<void> => {
    setSelectedAccountId(accountId);
    setSelectedTransferType(transferType);
    setWithdrawMethodFees([]);

    if (!accountId) {
      return;
    }

    setLoadingFees(true);
    try {
      const feeResponse = await axios.get<FeeResponse>(
        `/treasury/financial_account/fees?${
          transferType === 'inbound' ? 'payment_method_id' : 'recipient_id'
        }=${accountId}`,
      );
      const { data: feeMap } = feeResponse.data;

      const feeKeys = Object.keys(feeMap) as WithdrawMethod[];
      const fees = feeKeys.map((entry) => {
        const { cents: fee_amount, percentage: fee_percentage } = feeMap[entry];
        return { fee_amount, fee_percentage, type: entry };
      });
      // For outbound accounts, we are assuming that if the results from /fees comes back without
      // a us_domestic_wire, it's because the account is missing an address. So we show it as an option
      // for the user to update.
      const addAddressOption =
        transferType === 'outbound' &&
        fees.find((fee) => fee.type === 'us_domestic_wire') === undefined;
      const withdrawMethods = addAddressOption
        ? [
            ...fees,
            {
              fee_amount: 0,
              fee_percentage: 0,
              type: 'us_domestic_wire' as WithdrawMethod,
              error: 'NO_ADDRESS_FOUND',
            },
          ]
        : fees;

      setWithdrawMethodFees(withdrawMethods);
    } catch (e) {
      setSelectedAccountId(accountId);
      setWithdrawMethodFees([]);
    }
    setLoadingFees(false);
  };

  const reset = () => {
    setSelectedAccountId(null);
    setWithdrawMethodFees([]);
    setLoadingFees(false);
  };

  return {
    selectedAccountId,
    setSelectedAccountId,
    withdrawMethodFees,
    loadingFees,
    selectAccountIdAndGetFees,
    selectedTransferType,
    reset,
  };
}
