import { CustomerData } from '@app/@types/customer.types';
import { CustomerProduct } from '@app/constants/customerProducts';
import FuelProgramDetail from '@app/pages/FuelProgramDetail/FuelProgramDetail';
import { AccountLimits } from '@app/pages/LoadPay/AccountLimits';
import { Billing } from '@app/pages/LoadPay/Billing';
import { Fleet } from '@app/pages/LoadPay/Fleet';
import FuelMap from '@app/pages/LoadPay/FuelMap';
import PlansOverview from '@app/pages/LoadPay/components/PlansOverview';
import { Settings } from '@app/pages/Settings';
import { Navigate, Route } from 'react-router-dom';
import { RouteWithRequires } from '../AppRoutes';

export const getLoadPayRoutes = (
  customer: CustomerData,
  products: CustomerProduct[],
): RouteWithRequires[] => {
  if (
    !customer.channel_partner ||
    !['triumph', 'loadpay'].includes(customer.channel_partner.name)
  ) {
    return [];
  } else {
    // Routes go here
    return [
      {
        route: <Route path="/fuel-map" element={<FuelMap />} />,
        requires: ['perks'],
      },
      {
        route: <Route path="fuel-programs" element={<FuelProgramDetail />} />,
        requires: ['fuel_card'],
      },
      {
        route: <Route path="/perks" element={<Navigate replace to="/fuel-map" />} />,
        requires: [],
      },
      {
        route: <Route path="fleet/*" element={<Fleet />} />,
        requires: ['drivers', 'vehicles'],
      },
      {
        route: <Route path="/billing/statements" element={<Billing />} />,
        requires: ['billing'],
      },
      {
        route: <Route path="/billing/payment-history" element={<Billing />} />,
        requires: ['billing'],
      },
      {
        route: <Route path="/billing/payment-methods" element={<Billing />} />,
        requires: ['billing'],
      },
      {
        route: (
          <Route path="/settings" element={<Navigate replace to="/settings/notifications" />} />
        ),
        requires: [],
      },
      {
        route: (
          <Route
            path="/settings/account-limits"
            element={
              <Settings
                products={products}
                customer={customer}
                initialTab={'account-limits'}
                contentOverride={<AccountLimits isPrepaid={products.includes('prepaid')} />}
              />
            }
          />
        ),
        requires: ['settings'],
      },
      {
        route: (
          <Route
            path="/settings/plans"
            element={
              <Settings
                products={products}
                customer={customer}
                initialTab={'plans'}
                contentOverride={<PlansOverview products={products} />}
              />
            }
          />
        ),
        requires: ['settings'],
      },
    ];
  }
};
