import { ErrorBanner } from '@app/components/Banner/Designs/ErrorBanner';
import CustomerContext from '@app/contexts/customerContext';
import useChannelPartner from '@app/hooks/useChannelPartner';
import { ReactElement, useContext } from 'react';

export default function PayrollSuspendedBanner(): ReactElement | null {
  const { customer } = useContext(CustomerContext);
  const isSuspended = customer.suspended_at;
  const { supportEmailAddress, supportPhoneNumber } = useChannelPartner();

  if (!isSuspended) {
    return null;
  }

  return (
    <div className="mb-6">
      <ErrorBanner>
        <div className="font-semibold">Your payroll account has been suspended.</div>
        <div>
          Please email customer support at {supportEmailAddress} or call us at {supportPhoneNumber}{' '}
          for assistance.
        </div>
      </ErrorBanner>
    </div>
  );
}
