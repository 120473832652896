import { Button, SIZE, KIND } from 'baseui/button';
import { Checkbox } from 'baseui/checkbox';
import { Heading, HeadingLevel } from 'baseui/heading';
import { ListItem, ListItemLabel } from 'baseui/list';
import { ParagraphMedium } from 'baseui/typography';
import { ReactElement, useState } from 'react';

import { useLocation } from 'react-router-dom';

const DESCRIPTION_TEXT_HEALTHY =
  'To get all the benefits of the Uber Freight Carrier Card, simply transfer your AtoB account over. Would you like to do that?';
const DESCRIPTION_TEXT_UNHEALTHY =
  'To get all the benefits of the Uber Freight Carrier Card, you’ll need to transfer your AtoB account over. Get started by confirming your interest, and the AtoB Team will reach out to you over email within 24 hours.';
const POSITIVE_BUTTON_TEXT_HEALTHY = 'Yes, transfer account';
const POSITIVE_BUTTON_TEXT_UNHEALTHY = "Yes, I'm interested";
const NEGATIVE_BUTTON_TEXT_HEALTHY = 'No, do not transfer account now';
const NEGATIVE_BUTTON_TEXT_UNHEALTHY = 'No, I’m not interested';

// Define the prop types for HealthyView
type HealthyViewProps = {
  onPositiveButtonClick: () => void;
  onNegativeButtonClick: () => void;
};

const LandingView = ({
  onPositiveButtonClick,
  onNegativeButtonClick,
}: HealthyViewProps): ReactElement => {
  const [checked, setChecked] = useState(false);
  const location = useLocation();
  const showHealthyState = location.pathname.endsWith('healthy');
  const styleString = `
    .mTFF {
      font-family: MoveText !important;
    }
  `;
  const listItemPadding = {
    Content: {
      style: () => ({
        paddingBottom: '16px',
        paddingTop: '16px',
      }),
    },
  };
  return (
    <div>
      <style>{styleString}</style>
      <HeadingLevel>
        <Heading
          className="mTFF"
          styleLevel={4}
          style={{ marginBottom: '20.5px', paddingLeft: '24px', paddingRight: '24px' }}
        >
          Do you want to convert your existing AtoB fuel card account?
        </Heading>
      </HeadingLevel>
      <ParagraphMedium
        className="mTFF"
        style={{ marginBottom: '36px', paddingLeft: '24px', paddingRight: '24px' }}
      >
        {showHealthyState ? DESCRIPTION_TEXT_HEALTHY : DESCRIPTION_TEXT_UNHEALTHY}
      </ParagraphMedium>

      <div className="mTFF" style={{ paddingLeft: '24px', paddingRight: '24px' }}>
        <div style={{ marginBottom: '24px' }}>
          <Checkbox checked={checked} onChange={() => setChecked(!checked)}>
            I have read the details of this account transfer at the bottom of this page
          </Checkbox>
        </div>

        <div style={{ marginBottom: '8px' }}>
          <Button
            onClick={onPositiveButtonClick}
            disabled={!checked}
            size={SIZE.large}
            style={{ width: '100%', borderRadius: '0px' }}
          >
            {showHealthyState ? POSITIVE_BUTTON_TEXT_HEALTHY : POSITIVE_BUTTON_TEXT_UNHEALTHY}
          </Button>
        </div>
        <div style={{ marginBottom: '48px' }}>
          <Button
            onClick={onNegativeButtonClick}
            size={SIZE.large}
            kind={KIND.secondary}
            style={{ width: '100%', borderRadius: '0px' }}
          >
            {showHealthyState ? NEGATIVE_BUTTON_TEXT_HEALTHY : NEGATIVE_BUTTON_TEXT_UNHEALTHY}
          </Button>
        </div>
      </div>

      <HeadingLevel>
        <Heading
          className="mTFF"
          styleLevel={5}
          style={{ marginBottom: '28.5px', paddingLeft: '24px', paddingRight: '24px' }}
        >
          What a transfer means for you
        </Heading>
      </HeadingLevel>

      <ul className="mTFF" style={{ paddingLeft: '10px', paddingRight: '12px' }}>
        <ListItem overrides={listItemPadding} key="1">
          <ListItemLabel description="You’ll save up to 84¢ per gallon with your Uber Freight Carrier Card instead of the current flat 2-3¢ discount with AtoB. Use the Fuel Finder in the Uber Freight app to see discounts nearby.">
            Better fuel discounts
          </ListItemLabel>
        </ListItem>

        <ListItem overrides={listItemPadding} key="2">
          <ListItemLabel description="Get dedicated support at +1 831 704-9242 or ufcarriercard@atob.com.">
            New ways to contact support
          </ListItemLabel>
        </ListItem>

        <ListItem overrides={listItemPadding} key="3">
          <ListItemLabel description="If you are an AtoB Premium customer, you’ll be downgraded to the Basic tier and lose all premium features except SMS Unlock.">
            Reduced access to premium features
          </ListItemLabel>
        </ListItem>

        <ListItem overrides={listItemPadding} key="4">
          <ListItemLabel description="You’ll no longer manage spend in AtoB, and instead you’ll manage from within Uber Freight app or the web portal.">
            Manage spend all in one place
          </ListItemLabel>
        </ListItem>
      </ul>

      <ParagraphMedium
        className="mTFF"
        style={{
          marginTop: '24px',
          marginBottom: '24px',
          paddingLeft: '24px',
          paddingRight: '24px',
        }}
      >
        If you have other questions, please reach out to ufcarriercard@atob.com.
      </ParagraphMedium>
    </div>
  );
};

export default LandingView;
