import useWindowWidth from '@app/hooks/useWindowWidth';
import { faCreditCard, faDollarCircle, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ReactElement, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProductSubscription } from './PlansOverview';

const getTitle = (type: string): string => {
  if (type === 'atob_plus_premium') {
    return 'Fuel Card';
  }
  if (type === 'payroll') {
    return 'Payroll';
  }

  return '';
};

const getPlan = (plan: ProductSubscription['plan']): string => {
  if (plan === 'basic') {
    return 'Basic Plan';
  }
  if (plan === 'monthly') {
    return 'Monthly Plan';
  }
  if (plan === 'premium') {
    return 'Premium Plan';
  }

  return '';
};

const ProductButton = ({
  children,
  onClick,
  className,
}: {
  children: ReactNode;
  onClick?: () => void;
  className?: string;
}): ReactElement => (
  <button
    onClick={onClick}
    className={classNames(
      'bg-ds-cool-gray-100 flex items-center justify-center rounded-[4px] px-12 py-2 text-[16px] font-semibold',
      className,
    )}
  >
    {children}
  </button>
);

const Detail = ({ type, value }: { type: string; value: string | number }): ReactElement => (
  <div className="flex text-[16px] font-medium">
    <div className="mr-[2px] text-gray-700">{type}:</div>
    <div className="text-black">{value}</div>
  </div>
);

const getIcon = (product: ProductSubscription): IconDefinition => {
  if (product.type === 'atob_plus_premium') {
    return faCreditCard;
  }

  if (product.type === 'payroll') {
    return faDollarCircle;
  }

  return faCreditCard;
};

export default function ProductCard({
  product,
  editable,
  children,
}: {
  product: ProductSubscription;
  editable: boolean;
  children: (
    Detail: ({ type, value }: { type: string; value: string | number }) => ReactElement,
  ) => ReactElement;
}): ReactElement {
  const { isMobile } = useWindowWidth();
  const navigate = useNavigate();

  if (isMobile) {
    return (
      <div className="flex rounded-lg bg-white">
        <div className="flex w-full flex-col">
          <div className="flex w-full flex-col justify-between border-b border-gray-200">
            <div className="flex w-full flex-col p-4 pt-4">
              <div className="mb-4 flex flex-col">
                <div className="flex items-center">
                  <div className="bg-green8 mr-4 flex h-8 w-8 items-center justify-center rounded-full">
                    <div>
                      <FontAwesomeIcon
                        icon={getIcon(product)}
                        className="text-atob-green scale-90"
                      />
                    </div>
                  </div>
                  <h2 className="mb-1 text-[24px] font-bold">{getTitle(product.type)}</h2>
                </div>
                <b className="mt-3">{getPlan(product.plan)}</b>
                <div className="font-[500]">{product.cost}</div>
              </div>
              {editable && (
                <div className="flex">
                  <ProductButton
                    onClick={() => navigate(`/settings/plans/${product.type}`)}
                    className="flex-1"
                  >
                    Change Plan
                  </ProductButton>
                </div>
              )}
            </div>
          </div>
          <div className="w-full px-6 py-4">{children(Detail)}</div>
        </div>
      </div>
    );
  }
  return (
    <div className="flex rounded-lg bg-white py-[24px] pl-[32px]">
      <div className="flex w-full">
        <div className="bg-green8 mr-4 mt-2 flex h-16 w-[4.3rem] items-center justify-center rounded-full">
          <div>
            <FontAwesomeIcon icon={getIcon(product)} className="text-atob-green scale-125" />
          </div>
        </div>
        <div className="flex w-full flex-col">
          <div className="flex w-full justify-between border-b border-gray-200">
            <div className="mb-4 flex flex-col">
              <h2 className="mb-1 text-[24px] font-bold">{getTitle(product.type)}</h2>
              <b>{getPlan(product.plan)}</b>
              <div>{product.cost}</div>
            </div>
            {editable && (
              <div className="mr-6">
                <ProductButton onClick={() => navigate(`/settings/plans/${product.type}`)}>
                  Change Plan
                </ProductButton>
              </div>
            )}
          </div>
          <div className="mt-4 w-full">{children(Detail)}</div>
        </div>
      </div>
    </div>
  );
}
