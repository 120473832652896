import { forwardRef } from 'react';

type SVGRProps = {
  fill?: string;
  title?: string;
  titleId?: string;
  className?: string;
};

export const SavingIcon = forwardRef<SVGSVGElement, SVGRProps>(
  ({ fill = 'currentColor', ...rest }, ref) => {
    return (
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby={rest['titleId']}
        {...rest}
        ref={ref}
      >
        <g id="Saving">
          <g id="Union">
            <path
              d="M9.5249 2.49992H4.50693L8.07804 1.31488C8.60221 1.14094 9.16815 1.42486 9.3421 1.94904L9.5249 2.49992Z"
              fill={fill}
            />
            <path
              d="M2 3.49992C1.44772 3.49992 1 3.94764 1 4.49992V9.99992C1 10.5522 1.44772 10.9999 2 10.9999H10C10.5523 10.9999 11 10.5522 11 9.99992V4.49992C11 3.94764 10.5523 3.49992 10 3.49992H2Z"
              fill={fill}
            />
          </g>
        </g>
      </svg>
    );
  },
);
