import { ChargeEventData } from '@app/@types/charge_events.types';
import { TransactionsFilters } from '@app/constants/transactions';
import Card from '@atob-developers/shared/src/components/Card';
import { Button } from '@mui/material';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import TransactionsList from '../../pages/Transactions/TransactionsList';

interface TransactionsCardProps {
  transactions: ChargeEventData[];
  isLoading: boolean;
}

export default function TransactionsCard({
  transactions,
  isLoading,
}: TransactionsCardProps): ReactElement {
  const navigate = useNavigate();
  const cardId = transactions[0]?.card_id;
  const viewAllTransactionsUrl = cardId ? `/transactions?by_card_ids[]=${cardId}` : '/transactions';

  return (
    <Card>
      <div className="flex flex-col">
        <p className="pb-8 text-lg font-semibold">Recent Transactions</p>
        <TransactionsList
          currentTab={TransactionsFilters.ALL}
          rows={transactions}
          isLoading={isLoading}
          pageNavPrefix="/transactions/"
        />
      </div>
      {transactions.length > 0 && (
        <div className="mt-8 flex w-full justify-center">
          <Button color="secondary" size="medium" onClick={() => navigate(viewAllTransactionsUrl)}>
            View All Transactions
          </Button>
        </div>
      )}
    </Card>
  );
}
