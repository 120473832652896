import { MerchantData } from '@app/@types/spend_restriction.types';
import Header from '@app/components/Navigation/Header';
import ConfirmActionDialog from '@app/components/layout/ConfirmActionDialog';
import PageContentWrapper from '@app/components/wrappers/PageContentWrapper';
import StickyButtonWrapper from '@app/components/wrappers/StickyButtonWrapper';
import {
  getUpdateRestrictionParams,
  useUpdateRestrictions,
} from '@app/hooks/query/useRestrictions';
import { useCreateTag } from '@app/hooks/query/useTags';
import constants from '@app/utils/constants';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { LoadingButton } from '@mui/lab';
import * as Sentry from '@sentry/react';
import { Form, Formik } from 'formik';
import { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import SpendControlForm from './SpendControlForm';
import { FormValues } from './policy.types';

interface CreateTagsPageProps {
  allowSetSpendLimit: boolean;
  allowRestrictions: boolean;
  allowTelematics: boolean;
  spendTier: number;
}
const TelematicsOptions = constants.TelematicsOptions;

const CreateTagsPage = ({
  spendTier,
  allowTelematics,
  allowSetSpendLimit,
  allowRestrictions,
}: CreateTagsPageProps): ReactElement => {
  const [openUnsavedDialog, setOpenUnsavedDialog] = useState(false);
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const { trigger: createTag, error: tagError } = useCreateTag();
  const navigateBack = () => navigate('/tags');
  const { updateRestrictions, error: restrictionsError } = useUpdateRestrictions();
  const errors = [tagError, restrictionsError];

  if (errors.some((e) => !!e)) {
    errors.forEach((error) => {
      Sentry.captureException(error);
    });
    addToast('There was an error saving the configuration. Please refresh and try again later', {
      appearance: 'error',
    });
  }

  const validationSchema = Yup.object().shape({
    policy: Yup.object({
      name: Yup.string().required('Please name your tag'),
      description: Yup.string(),
      per_transaction_limit: Yup.number(),
      daily_spend_limit: Yup.number(),
      weekly_spend_limit: Yup.number(),
      telematics_setting: Yup.mixed().oneOf(TelematicsOptions.map((o) => o.id)),
      card_security_enabled: Yup.boolean(),
    }),
    restrictions: Yup.object({
      afdOnly: Yup.boolean(),
      selectedCategoryIds: Yup.array(),
      merchantWhitelist: Yup.array(),
      merchantBlacklist: Yup.array(),
    }),
  });

  const onSubmit = async (tagValues: FormValues) => {
    const tag = await createTag({ tag: tagValues.policy });
    const restrictionParams = getUpdateRestrictionParams(
      tagValues.restrictions,
      tag?.data,
      spendTier,
    );
    updateRestrictions(restrictionParams);
    navigateBack();
  };

  const initialValues = {
    policy: {
      name: '',
      description: '',
      per_transaction_limit: '',
      daily_spend_limit: '',
      weekly_spend_limit: '',
      telematics_setting: TelematicsOptions[0].id,
      card_security_enabled: false,
    },
    restrictions: {
      afdOnly: false,
      selectedCategoryIds: [] as string[],
      merchantWhitelist: [] as MerchantData[],
      merchantBlacklist: [] as MerchantData[],
    },
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ values, setFieldValue, errors, touched, isSubmitting, submitForm, isValid, dirty }) => (
        <Form>
          <ConfirmActionDialog
            title={'Unsaved changes'}
            open={openUnsavedDialog}
            handleConfirm={navigateBack}
            handleCancel={() => setOpenUnsavedDialog(false)}
          >
            Leaving this page will discard any unsaved changes. Do you want to continue?
          </ConfirmActionDialog>
          <PageContentWrapper
            header={
              <Header
                title="Create Tag"
                onBack={() =>
                  Object.values(touched).length === 0 ? navigateBack() : setOpenUnsavedDialog(true)
                }
                mobileStickyButton={
                  <StickyButtonWrapper>
                    <LoadingButton
                      fullWidth={true}
                      loading={isSubmitting}
                      type="submit"
                      size="small"
                      onClick={submitForm}
                      disabled={!(isValid && dirty)}
                    >
                      <span>Save</span>
                    </LoadingButton>
                  </StickyButtonWrapper>
                }
                rightContent={
                  <div className="flex items-center justify-end gap-2">
                    <LoadingButton
                      loading={isSubmitting}
                      type="submit"
                      size="small"
                      onClick={submitForm}
                      disabled={!(isValid && dirty)}
                    >
                      <span>Save</span>
                    </LoadingButton>
                  </div>
                }
              />
            }
          >
            <SpendControlForm
              allowTelematics={allowTelematics}
              allowSetSpendLimit={allowSetSpendLimit}
              allowRestrictions={allowRestrictions}
              spendTier={spendTier}
              values={values}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
            />
          </PageContentWrapper>
        </Form>
      )}
    </Formik>
  );
};

export default CreateTagsPage;
