import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useMessagePassing = (
  propagateClicksToHost: boolean,
  propagatePathChangeToHost: boolean,
) => {
  const location = useLocation();

  useEffect(() => {
    const handleClick = () => {
      window.parent.postMessage({ event: 'iframe-click' }, '*'); // Replace '*' with the host uri once we store it on the channel partner configuration
    };

    if (propagateClicksToHost) {
      document.addEventListener('click', handleClick);
    }

    return () => {
      if (propagateClicksToHost) {
        document.removeEventListener('click', handleClick);
      }
    };
  }, [propagateClicksToHost]);

  useEffect(() => {
    if (!propagatePathChangeToHost) return;

    const queryParams = location.search || ''; // Fallback to empty string if undefined
    const pathWithQueryParams = location.pathname + queryParams;
    window.parent.postMessage({ event: 'iframe-path-change', path: pathWithQueryParams }, '*'); // Replace '*' with the host uri once we store it on the channel partner configuration
  }, [location, propagatePathChangeToHost]);
};
