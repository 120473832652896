import { VehicleData } from '@app/@types/vehicle.types';
import VehicleForm from '@app/components/Vehicles/VehicleForm';
import {
  useVehicleFormReducer,
  VehicleReducer,
} from '@app/components/Vehicles/useVehicleFormReducer';
import ArchiveVehicleModal from '@app/pages/Vehicles/Modals/ArchiveVehicleModal';
import RestoreVehicleModal from '@app/pages/Vehicles/Modals/RestoreVehicleModal';
import { apiPutFetcher } from '@app/utils/data/fetchers';
import DefaultSideBar, {
  SideBarBody,
  SideBarFooter,
} from '@atob-developers/shared/src/components/SideBar';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import axios from 'axios';
import { ReactElement, useEffect, useState } from 'react';
import useSWRMutation from 'swr/mutation';

type VehicleSidebarProps = {
  vehicle: VehicleData | null;
  hide: () => void;
  refresh: () => void;
};

export const VehicleSidebar = (props: VehicleSidebarProps): ReactElement => {
  const { vehicle, hide } = props;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const reducer = useVehicleFormReducer(vehicle);
  const { setVehicle, editing, setEditing } = reducer;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if ((vehicle?.id || null) !== reducer.vehicle?.id) setVehicle(vehicle);
    // When there's no vehicle, we close the sidebar. Set the editing mode to readonly.
    if (!vehicle && editing) setEditing(false);
  }, [vehicle, editing, setEditing, setVehicle, reducer]);

  return (
    <DefaultSideBar
      title={<VehicleSidebarTitle {...{ reducer, ...props }} />}
      open={!!vehicle}
      toggle={() => hide()}
    >
      <SideBarBody>
        <VehicleForm {...{ reducer }} />
      </SideBarBody>
      <SideBarFooter>
        {editing ? (
          <VehicleEditFooter {...{ reducer, ...props }} />
        ) : (
          <VehiclePreviewFooter {...{ reducer, ...props }} />
        )}
      </SideBarFooter>
    </DefaultSideBar>
  );
};

function VehicleSidebarTitle({ reducer }: VehicleSidebarElementProps): ReactElement {
  const { editing, vehicle } = reducer;
  return <div>{editing ? 'Edit Vehicle' : vehicle.name}</div>;
}

type VehicleSidebarElementProps = VehicleSidebarProps & {
  reducer: VehicleReducer;
};

function VehiclePreviewFooter({
  vehicle,
  reducer,
  hide,
  refresh,
}: VehicleSidebarElementProps): ReactElement {
  const [archiveModalActive, setArchiveModalActive] = useState(false);
  const [restoreModalActive, setRestoreModalActive] = useState(false);
  const { toggleEditing } = reducer;

  const createReset = (setModalActive: (active: boolean) => void) => {
    return () => {
      setModalActive(false);
      hide();
      refresh();
    };
  };

  return (
    <>
      <Button size="small" onClick={() => toggleEditing()}>
        Edit Vehicle
      </Button>
      {vehicle && (
        <>
          <ArchiveVehicleModal
            isActive={archiveModalActive}
            reset={createReset(setArchiveModalActive)}
            vehicle={vehicle}
          />
          <RestoreVehicleModal
            isActive={restoreModalActive}
            reset={createReset(setRestoreModalActive)}
            vehicle={vehicle}
          />
        </>
      )}
      <ArchiveOrRestoreVehicleButton
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        vehicle={vehicle}
        setArchiveModalActive={setArchiveModalActive}
        setRestoreModalActive={setRestoreModalActive}
      />
    </>
  );
}

function ArchiveOrRestoreVehicleButton({
  vehicle,
  setArchiveModalActive,
  setRestoreModalActive,
}: {
  vehicle: VehicleData;
  setArchiveModalActive: (state: boolean) => void;
  setRestoreModalActive: (state: boolean) => void;
}): ReactElement {
  if (vehicle?.archived_at === null) {
    return (
      <Button color="secondary" size="small" onClick={() => setArchiveModalActive(true)}>
        Archive Vehicle
      </Button>
    );
  } else {
    return (
      <Button color="secondary" size="small" onClick={() => setRestoreModalActive(true)}>
        Restore Vehicle
      </Button>
    );
  }
}

function VehicleEditFooter({ refresh, hide, reducer }: VehicleSidebarElementProps) {
  const { vehicle, setEditing, setErrors } = reducer;
  const { trigger, isMutating: isLoading } = useSWRMutation(
    {
      url: `/vehicles/${vehicle.id}`,
    },
    apiPutFetcher,
    {
      onError: (e) => {
        if (axios.isAxiosError(e)) {
          setErrors(e.response?.data.errors || []);
        }
      },
      onSuccess: () => {
        setErrors([]);
        setEditing(false);
        hide();
        refresh();
      },
    },
  );

  return (
    <>
      <Button color="alert" size="small" onClick={() => setEditing(false)}>
        Discard Changes
      </Button>
      <LoadingButton size="small" onClick={() => trigger({ vehicle })} loading={isLoading}>
        <span>Save Changes</span>
      </LoadingButton>
    </>
  );
}
