/* eslint-disable no-nested-ternary */
import { PaginatedEndpointResponse } from '@app/@types/api.types';
import { StatementDataV1 } from '@app/@types/statements.types';
import { FilterDropdownDate } from '@app/components/Filters/FilterDropdownDate';
import FilterGroup from '@app/components/Filters/FilterGroup';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import { ErrorNotification } from '@app/components/layout';
import { DEFAULT_PAGE_SIZE } from '@app/hooks/paging/types';
import { useUrlState } from '@app/hooks/useUrlState';
import { PreviousStatements } from '@app/pages/BillingStatements/BillingStatements';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import { ReactElement } from 'react';
import useSWR from 'swr';

export function Statements(): ReactElement {
  const [statementParams, setStatementParams] = useUrlState<{
    'filter_due_at[from]': string | null;
    'filter_due_at[to]': string | null;
    'page': number;
  }>({ 'filter_due_at[from]': null, 'filter_due_at[to]': null, 'page': 1 });
  const { data, error, isLoading } = useSWR<PaginatedEndpointResponse<StatementDataV1>>(
    {
      url: `/accounting/statements`,
      params: {
        per: DEFAULT_PAGE_SIZE,
        all: false,
        ...statementParams,
      },
    },
    apiGetFetcher,
  );
  if (error) {
    return (
      <ErrorNotification error="We're having issues loading your statements data. Please try again or if the issue persists, contact support." />
    );
  }

  return (
    <RoundedCard breakpoint="md">
      <FilterGroup>
        <FilterDropdownDate
          onDateRangeChange={(fromDate, toDate) => {
            setStatementParams((prevParams) => ({
              ...prevParams,
              'filter_due_at[from]': fromDate,
              'filter_due_at[to]': toDate,
            }));
          }}
          fromDate={statementParams['filter_due_at[from]']}
          toDate={statementParams['filter_due_at[to]']}
        />
      </FilterGroup>
      <PreviousStatements
        statements={data}
        loading={isLoading}
        setPage={(page) => setStatementParams((p) => ({ ...p, page }))}
      />
    </RoundedCard>
  );
}
