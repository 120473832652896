import {
  faRoute,
  faXmark,
  faSwap,
  faMagnifyingGlass,
  faCircleDot,
  faLocationDot,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, InputAdornment } from '@mui/material';
import classNames from 'classnames';
import { ReactElement } from 'react';

export function OriginEndAdornments({
  routing,
  onCancelRoutingClick,
  onRoutingClick,
  isDisabled,
  destination = false,
}: {
  destination?: boolean;
  routing: boolean;
  isDisabled: boolean;
  onCancelRoutingClick: () => void;
  onRoutingClick: () => void;
}): ReactElement | null {
  return (
    <InputAdornment position="end" classes={{ root: classNames('h-auto', routing && 'mr-3') }}>
      {routing ? (
        <Button
          className="p-0"
          size="small"
          onClick={onCancelRoutingClick}
          color="tertiary"
          endIcon={<FontAwesomeIcon icon={destination ? faSwap : faXmark} fixedWidth />}
        />
      ) : (
        <Button
          size="small"
          color="primary"
          disabled={isDisabled}
          onClick={onRoutingClick}
          endIcon={<FontAwesomeIcon icon={faRoute} />}
        >
          Route
        </Button>
      )}
    </InputAdornment>
  );
}

export function OriginStartAdornments({
  routing,
  destination = false,
}: {
  routing: boolean;
  destination?: boolean;
}): ReactElement {
  return (
    <InputAdornment position="start">
      {routing ? (
        <FontAwesomeIcon
          icon={destination ? faLocationDot : faCircleDot}
          className="text-secondary"
          size="lg"
        />
      ) : (
        <FontAwesomeIcon icon={faMagnifyingGlass} className="text-secondary" size="lg" />
      )}
    </InputAdornment>
  );
}
