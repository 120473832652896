import { UserCustomerData, UserCustomersResponseType } from '@app/@types/user_customer.types';
import {
  Backdrop,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import axios from 'axios';
import { deserialize } from 'deserialize-json-api';
import { ReactElement, useEffect, useState } from 'react';

const AccountOverviewHeader = ({ title }: { title: string }): ReactElement => {
  const [customers, setCustomers] = useState([]);
  const [currentCustomer, setCurrentCustomer] = useState('');
  const [startSwitching, setStartSwitching] = useState(false);

  useEffect(() => {
    const fetchCustomers = async () => {
      const customersData = await axios.get<UserCustomersResponseType>('/customers');
      const customers = deserialize(customersData.data).data;
      const current = customers.find((customer: UserCustomerData) => customer.current);
      setCurrentCustomer(current.uuid);
      setCustomers(customers);
    };

    fetchCustomers();
  }, []);

  const handleChange = async (event: SelectChangeEvent) => {
    const selectedCustomer = event.target.value;
    if (selectedCustomer !== currentCustomer) {
      setStartSwitching(true);
      // Call API to safely set cookie
      await axios.post<unknown>('/customers/switch', {
        uuid: selectedCustomer,
      });
      // No need to handle error. If the response is 404, we will still refresh the page to load the latest customer.
      window.location.reload();
    }
  };

  if (customers.length <= 1) {
    return <h1 className="text-2xl font-bold leading-[29px]">{title}</h1>;
  }

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={startSwitching}
      >
        <CircularProgress />
      </Backdrop>

      <FormControl>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={currentCustomer}
          onChange={handleChange}
          variant="standard"
          disableUnderline
          className="w-fit bg-transparent"
          MenuProps={{
            sx: {
              '& .MuiMenu-paper	': {
                maxHeight: '50vh',
              },
            },
          }}
          inputProps={{
            sx: {
              paddingY: 0,
            },
          }}
          sx={{
            '& .MuiSelect-select.MuiSelect-standard': {
              fontSize: '1.5rem',
              lineHeight: '29px',
              fontWeight: 700,
              minHeight: 0,
              backgroundColor: 'transparent',
              boxShadow: '0',
              paddingTop: 0,
              paddingBottom: 0,
              paddingInlineStart: 0,
            },
            '& .MuiSelect-select:focus': {
              backgroundColor: 'transparent',
            },
          }}
        >
          {customers.map((item: UserCustomerData) => (
            <MenuItem key={item.uuid} value={item.uuid}>
              {item.company_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default AccountOverviewHeader;
