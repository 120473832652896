import logger from '@app/utils/datadog-logger';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { Button } from '@mui/material';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { ReactElement } from 'react';
import TermsOfServiceHeader from '../TermsOfService/TermsOfServiceHeader';

export default function SignCombinedAgreement(): ReactElement {
  const { addToast } = useToasts();
  const submitTos = async () => {
    try {
      await axios.put('/customer/confirm_tos');
      window.location.reload();
    } catch (e: unknown) {
      Sentry.captureException(e);
      logger.error('Error while confirming ToS: ', {
        error: e,
      });
      addToast('Something went wrong, please try again later.', { appearance: 'error' });
    }
  };
  return (
    <div>
      <TermsOfServiceHeader />
      <div className="mt-8 flex w-full flex-col space-x-4 md:flex md:items-center">
        <Button color="primary" onClick={() => submitTos()}>
          {'Agree'}
        </Button>
      </div>
    </div>
  );
}
