import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { HTMLAttributes, ReactElement } from 'react';

export default function Spinner({ className }: HTMLAttributes<HTMLOrSVGElement>): ReactElement {
  return (
    <FontAwesomeIcon
      icon={faSpinnerThird}
      className={cx('text-grey6 h-[48px] w-[48px] animate-spin', className)}
    />
  );
}
