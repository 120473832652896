import useThemeConfiguration from '@app/app/useThemeConfiguration';
import { useIsUberfreight } from '@app/hooks/partners/useIsUberfreight';
import useIsNativeApp from '@app/hooks/useIsNativeApp';

export default function useIsDeviceAppEligible():
  | ['android' | 'ios' | 'desktop', false]
  | ['android' | 'ios', true] {
  const deviceType = /Android/i.test(navigator.userAgent)
    ? 'android'
    : /iPhone|iPod|iPad/i.test(navigator.userAgent)
    ? 'ios'
    : 'desktop';

  const isUFCusomter = useIsUberfreight();
  const { hideDismissableBanners } = useThemeConfiguration();
  const { isNativeApp } = useIsNativeApp();
  const shouldNotShow =
    isUFCusomter || isNativeApp || deviceType === 'desktop' || hideDismissableBanners;
  if (shouldNotShow) {
    return [deviceType, false];
  }
  return [deviceType, true];
}
