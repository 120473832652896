import { PaginatedEndpointResponse } from '@app/@types/api.types';
import { DriverData } from '@app/@types/driver.types';
import CreateDriverModal from '@app/components/Modals/DriverModals/CreateDriverModal';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import { ErrorNotification } from '@app/components/layout';
import { DEFAULT_PAGE_SIZE, SortBy } from '@app/hooks/paging/types';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import DriversList from '@app/pages/Drivers/DriversList';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, InputAdornment, TextField } from '@mui/material';
import { GridSortModel } from '@mui/x-data-grid-pro';
import { ReactElement, useState } from 'react';
import useSWR from 'swr';

export const DriversTab = (): ReactElement => {
  // Pagination is 1-indexed, not 0-indexed
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState<SortBy | null>(null);
  const [search, setSearch] = useState<string>('');

  const { data, error, isLoading, mutate } = useSWR<PaginatedEndpointResponse<DriverData>>(
    {
      url: '/drivers',
      params: {
        page: page,
        per: DEFAULT_PAGE_SIZE,
        all: false,
        sort: sort ? [`${sort.field}:${sort.order}`] : undefined,
        like: search.length > 0 ? search : null,
        archived_at: 'none',
      },
    },
    apiGetFetcher,
  );

  const [streamlinedDriverAppOnboarding, blocklistDriverAppOnboarding] = useFeatureFlags(
    'streamlined_driver_app_onboarding',
    'blocklist_driver_app_onboarding',
  );

  const [showDriverModal, setShowDriverModal] = useState<boolean>(false);
  const toggleDriverModal = () => setShowDriverModal(!showDriverModal);

  const enableStreamlinedDriverAppOnboarding =
    streamlinedDriverAppOnboarding && !blocklistDriverAppOnboarding;

  const onDriverUpdate = () => {
    mutate();
  };

  if (error != null) {
    return (
      <ErrorNotification error="We're having issues loading your driver data. Please try again or if the issue persists, contact support." />
    );
  }

  return (
    <>
      <CreateDriverModal
        open={showDriverModal}
        toggle={() => setShowDriverModal(!showDriverModal)}
        enableStreamlinedDriverAppOnboarding={enableStreamlinedDriverAppOnboarding}
        onDriversUpdate={onDriverUpdate}
      />
      <RoundedCard breakpoint="sm">
        <div className="my-4 flex items-center justify-between sm:mx-6">
          <Button color="primary" onClick={toggleDriverModal}>
            Add New Driver
          </Button>
          <TextField
            variant="outlined"
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faMagnifyingGlass}></FontAwesomeIcon>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <DriversList
          data={data?.data ?? []}
          paginationMeta={data?.meta}
          loading={isLoading}
          enableStreamlinedDriverAppOnboarding={enableStreamlinedDriverAppOnboarding}
          onDriversUpdate={onDriverUpdate}
          onPageIndexChange={setPage}
          onSortModelChange={(model: GridSortModel) => {
            const sortByModel = model && model[0];
            if (!sortByModel) {
              setSort(null);
              return;
            }
            const { field, sort: order } = sortByModel;
            setSort({ field, order });
          }}
        />
      </RoundedCard>
    </>
  );
};
