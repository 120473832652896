import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ReactElement, PropsWithChildren } from 'react';

export default function ConfirmActionDialog({
  open,
  handleConfirm,
  handleCancel,
  title,
  children,
}: PropsWithChildren<{
  open: boolean;
  handleConfirm: () => void;
  handleCancel: () => void;
  title: string;
}>): ReactElement {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{children}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size="small" onClick={handleConfirm}>
            Confirm
          </Button>
          <Button color="secondary" size="small" onClick={handleCancel} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
