import useCustomer from '@app/hooks/useCustomer';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import useProduct from '@app/hooks/useProduct';
import { ReactElement } from 'react';
import AtoBSavingsMarketingPopup from '../AtoBSavingsMarketingPopup';
import TAPetroPopup from '../TAPetroPopup';
import UnlimitedActivationPromotionPopup from '../UnlimitedActivationPromotionPopup';

const MarketingPopupRoot = (): ReactElement | null => {
  const customer = useCustomer();
  const [unlimited_activation_retention_promo] = useProduct('unlimited_activation_retention_promo');
  const [showAtoBSavingsBanner] = useFeatureFlags('atob_savings_banner');

  if (customer?.cardholder_status !== 'active') {
    return null;
  }
  return (
    <div>
      {unlimited_activation_retention_promo && <UnlimitedActivationPromotionPopup />}
      {showAtoBSavingsBanner && <AtoBSavingsMarketingPopup />}
      <TAPetroPopup />

      {/* Add more marketing popups here */}
    </div>
  );
};

export default MarketingPopupRoot;
