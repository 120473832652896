export type BUTTON_TYPES =
  | 'base'
  | 'primary'
  | 'secondary'
  | 'dark'
  | 'warning'
  | 'danger'
  | 'disabled'
  | 'outline'
  | 'silent'
  | 'loading';

type ButtonConfiguration = {
  [key in string]: string;
};

export const BUTTON_TEXT_COLORS: ButtonConfiguration = {
  base: 'text-atob-green',
  primary: 'text-white',
  secondary: 'text-atob-green',
  dark: 'text-white',
  disabled: 'text-gray-400',
  danger: 'text-red-400',
  warning: 'text-yellow-500',
  outline: 'text-grey3',
  silent: 'text-black-500',
};

export const BUTTON_HOVER_TEXT_COLORS: ButtonConfiguration = {
  base: 'hover:text-atob-green',
  primary: 'hover:text-gray-100',
  secondary: 'hover:text-ds-dark-green',
  dark: 'text-gray-100',
  disabled: 'hover:text-gray-500',
  danger: 'hover:text-red-500',
  warning: 'hover:text-yellow-600',
  outline: 'hover:text-gray-500',
  silent: 'hover:text-grey-600',
};

export const BUTTON_BG_COLORS: ButtonConfiguration = {
  base: 'bg-white',
  primary: 'bg-atob-green',
  secondary: 'bg-white',
  dark: 'bg-ds-lp-base',
  disabled: 'bg-gray-100',
  danger: 'bg-white',
  warning: 'bg-white',
  outline: 'bg-transparent',
  silent: 'bg-transparent',
};

export const BUTTON_HOVER_BG_COLORS: ButtonConfiguration = {
  base: 'hover:bg-gray-50',
  primary: 'hover:bg-atob-green',
  secondary: 'hover:bg-gray-50',
  disabled: 'hover:bg-gray-200',
  dark: 'bg-ds-lp-base',
  danger: 'hover:bg-red-50',
  warning: 'hover:bg-yellow-50',
  outline: 'hover:bg-transparent',
  silent: 'hover:bg-grey-50',
};

export const BUTTON_BORDER_COLORS: ButtonConfiguration = {
  base: 'border-gray-100',
  primary: 'border-atob-green',
  secondary: 'border-atob-green',
  dark: 'bg-ds-cool-gray-900',
  disabled: 'border-gray-100',
  danger: 'border-red-200',
  warning: 'border-yellow-100',
  outline: 'border-none shadow-none focus:ring-0 ',
  silent: 'border-none shadow-none focus:ring-0 ',
};

export type InputType = 'text' | 'email' | 'date' | 'password' | 'tel' | 'number';

export type INPUT_TYPES = 'base' | 'disabled' | 'error' | 'warning';

type InputConfiguration = {
  [key in string]: string;
};

export const INPUT_FOCUS_RING_COLORS: InputConfiguration = {
  base: 'focus:ring-black focus:border-black',
  error: 'focus:ring-dark-red focus:border-dark-red',
};

export const INPUT_BG_COLORS: InputConfiguration = {
  disabled: 'bg-light-grey',
  error: '',
};

export const INPUT_TEXT_COLORS: ButtonConfiguration = {
  disabled: 'text-gray-700',
  error: '',
};

export const INPUT_BORDER_COLOR: ButtonConfiguration = {
  disabled: 'border-gray-300',
  base: 'border-grey13',
  error: 'border-dark-red',
};

export type DIVIDER_TYPES = 'thin' | 'medium' | 'bold';

export const DIVIDER_BG_COLOR = {
  thin: 'bg-grey16',
  medium: 'bg-grey1',
  bold: 'bg-grey17',
};
