import { BankAccountPaymentMethodCombined } from '@app/@types/bankAccount.types';
import { DebitCardPaymentMethodCombined } from '@app/@types/debitCard.types';
import { PaymentModalTriggerType } from '@app/@types/payments.types';
import { createContext } from 'react';

export type DefaultShowModalType = 'debit_card' | null;

export type AccountDetailsType = {
  accountType: 'debit_card' | 'ach_charge' | null;
  accountMask: string;
  institutionName: string | null;
  // PaymentMethod ID
  id: string;
  // BankAccount ID
  bank_account_id?: string;
  trigger?: PaymentModalTriggerType;
};

export const PaymentHistoryContext = createContext<PaymentHistoryContextType>({
  type: null,
  setType: () => {},
  setError: () => {},
  handleDeletePaymentMethod: () => {},
  bankAccounts: null,
  setBankAccounts: () => {},
  debitCards: null,
  setDebitCards: () => {},
  defaultShowModal: null,
});

export type PaymentHistoryContextType = {
  type: AccountDetailsType | null;
  setType: (data: AccountDetailsType) => void;
  setError: (e: Error) => void;
  handleDeletePaymentMethod: () => void;
  bankAccounts: BankAccountPaymentMethodCombined[] | null;
  setBankAccounts: (bankAccounts: BankAccountPaymentMethodCombined[]) => void;
  debitCards: DebitCardPaymentMethodCombined[] | null;
  setDebitCards: (debitCards: DebitCardPaymentMethodCombined[]) => void;
  defaultShowModal: DefaultShowModalType;
};
