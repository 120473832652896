import { MyLocation, PlaceType } from '@app/@types/fuel_listings.types';
import { faLocationDot, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autocomplete } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { debounce } from '@mui/material/utils';
import classNames from 'classnames';
import { isEqual } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';

const autocompleteService = { current: null };

type DropdownOption = PlaceType | MyLocation;

export default function GoogleAutocomplete({
  placesService,
  selectPlace,
  autocompleteValue,
  currentLocation,
  label,
  startAdornment,
  endAdornment,
}: {
  placesService: google.maps.places.PlacesService | null;
  selectPlace: (
    place: google.maps.places.PlaceResult | MyLocation,
    dropdownOption: DropdownOption,
  ) => void;
  currentLocation?: MyLocation;
  label?: string;
  startAdornment?: React.ReactNode;
  autocompleteValue: DropdownOption;
  endAdornment?: React.ReactNode;
}) {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<readonly PlaceType[]>([]);

  const fetch = useMemo(
    () =>
      debounce((request: { input: string }, callback: (results?: readonly PlaceType[]) => void) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unnecessary-type-assertion
        (autocompleteService.current as any).getPlacePredictions(request, callback);
      }, 400),
    [],
  );

  useEffect(() => {
    let active = true;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!autocompleteService.current && (window as any).google) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      const opts: DropdownOption[] = [];
      if (currentLocation != null) {
        opts.push(currentLocation);
      }
      if (autocompleteValue && !isEqual(autocompleteValue, currentLocation)) {
        opts.push(autocompleteValue);
      }
      setOptions(opts);
      return undefined;
    }

    if (inputValue == 'Current Location') {
      if (currentLocation != null) {
        setOptions([currentLocation]);
      }
    } else {
      fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
        if (active) {
          let newOptions: readonly DropdownOption[] = [];
          if (autocompleteValue) {
            newOptions = [autocompleteValue];
          }
          if (results) {
            newOptions = [...newOptions, ...results];
          }
          setOptions(newOptions);
        }
      });
    }

    return () => {
      active = false;
    };
  }, [currentLocation, autocompleteValue, inputValue, fetch]);

  const onFocus = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event.target.select();
  };

  return (
    <Autocomplete
      size="medium"
      disableClearable
      disabled={!placesService}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={autocompleteValue}
      noOptionsText="No locations"
      onChange={(_, newValue: PlaceType | null) => {
        if (typeof newValue === 'string') return;
        setOptions(newValue ? [newValue, ...options] : options);
        if (newValue && newValue.place_id === 'current_location') {
          selectPlace(newValue, newValue);
          return;
        }

        if (newValue && placesService) {
          // eslint-disable-next-line @typescript-eslint/await-thenable
          placesService.getDetails(
            {
              placeId: newValue.place_id,
              fields: ['geometry', 'formatted_address', 'address_components'],
            },
            (place, status) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
              if (status === 'OK' && place) {
                selectPlace(place, newValue);
              }
            },
          );
        }
      }}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={label || 'Search'}
          fullWidth
          onFocus={onFocus}
          InputProps={{
            ...params.InputProps,
            startAdornment,
            endAdornment,
          }}
        />
      )}
      renderOption={({ className, ...restProps }, option) => {
        const selected = option.place_id === autocompleteValue.place_id;

        return (
          <li {...restProps} className={classNames(className, 'group')}>
            <Grid container gap={1} flexWrap="nowrap">
              <Grid item className="h-4 w-3">
                <FontAwesomeIcon icon={faLocationDot} />
              </Grid>
              <Grid item sx={{ flexGrow: 1, wordWrap: 'break-word' }}>
                <Box
                  component="span"
                  className="text-ds-cool-gray-900 group-hover:text-ds-cool-gray-500 text-sm font-medium"
                >
                  {option.structured_formatting.main_text}
                </Box>
                <Typography variant="body2" className="text-ds-cool-gray-400 text-xs">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
              {selected && (
                <Grid item className="h-4 w-3">
                  <FontAwesomeIcon icon={faCheck} className="text-ds-cool-gray-400" />
                </Grid>
              )}
            </Grid>
          </li>
        );
      }}
    />
  );
}
