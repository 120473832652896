import { ChargeEventData } from '@app/@types/charge_events.types';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  ReactElement,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

type MenuOption = {
  value: string;
  order: number;
  onClick: () => void;
  disabled: () => boolean;
};

type ButtonOption = {
  onClick: () => void;
  disabled: boolean;
  icon: IconProp;
};

type MobileNavHeaderMenuContextType = {
  menuOptions: MenuOption[];
  setMenuOptions: (val: MenuOption[]) => void;
  extendMenuOptions: (...val: MenuOption[]) => void;
  removeMenuOptions: (...val: MenuOption[]) => void;
  customNavButton: ButtonOption | null;
  setCustomNavButton: (val: ButtonOption | null) => void;
  customTitle: string | null;
  setCustomTitle: (val: string | null) => void;
  prevButton: ButtonOption | null;
  setPrevButton: (val: ButtonOption | null) => void;
  nextButton: ButtonOption | null;
  setNextButton: (val: ButtonOption | null) => void;
  mobileNavOpen: boolean;
  setMobileNavOpen: (val: boolean) => void;
};

export const MobileNavHeaderMenuContext = createContext<MobileNavHeaderMenuContextType>({
  menuOptions: [],
  setMenuOptions: () => {},
  extendMenuOptions: () => {},
  removeMenuOptions: () => {},
  customNavButton: null,
  setCustomNavButton: () => {},
  customTitle: null,
  setCustomTitle: () => {},
  prevButton: null,
  setPrevButton: () => {},
  nextButton: null,
  setNextButton: () => {},
  mobileNavOpen: false,
  setMobileNavOpen: () => {},
});

export function useSetPrevNavButton(button: ButtonOption): void {
  const { setPrevButton } = useContext(MobileNavHeaderMenuContext);
  useEffect(() => {
    setPrevButton(button);
    return () => setPrevButton(null);
  }, [button, setPrevButton]);
}

export function useSetNextNavButton(button: ButtonOption): void {
  const { setNextButton } = useContext(MobileNavHeaderMenuContext);
  useEffect(() => {
    setNextButton(button);
    return () => setNextButton(null);
  }, [button, setNextButton]);
}

export function useSetupMobileHeaderContextMenu(...menuOptions: MenuOption[]): void {
  const { setMenuOptions } = useContext(MobileNavHeaderMenuContext);
  useEffect(() => {
    setMenuOptions(menuOptions);
    return () => {
      setMenuOptions([]);
    };
    // We need to compare the options themselves, which should be stable.
    // Because this is a rest parameter, the menuOption varialbe is recreated every call site
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...menuOptions, setMenuOptions]);
}

export function useCustomNavButtonForPage(backNav: ButtonOption): void {
  const { setCustomNavButton } = useContext(MobileNavHeaderMenuContext);
  useEffect(() => {
    setCustomNavButton(backNav);
    return () => {
      setCustomNavButton(null);
    };
  }, [backNav, setCustomNavButton]);
}

export function useMobileNavCustomPageTitle(title: string): void {
  const { setCustomTitle } = useContext(MobileNavHeaderMenuContext);
  useEffect(() => {
    setCustomTitle(title);
    return () => {
      setCustomTitle(null);
    };
  }, [setCustomTitle, title]);
}

export function useExtendMobileHeaderContextMenu(...menuOptions: MenuOption[]): void {
  const { extendMenuOptions, removeMenuOptions } = useContext(MobileNavHeaderMenuContext);
  useEffect(() => {
    extendMenuOptions(...menuOptions);
    return () => {
      removeMenuOptions(...menuOptions);
    };
    // We need to compare the options themselves, which should be stable.
    // Because this is a rest parameter, the menuOption varialbe is recreated every call site
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extendMenuOptions, ...menuOptions, removeMenuOptions]);
}

export default function MobileNavHeaderMenuContextComponent({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  const [menuOptions, setMenuOptions] = useState<MenuOption[]>([]);
  const [customNavButton, setCustomNavButton] = useState<ButtonOption | null>(null);
  const [customTitle, setCustomTitle] = useState<string | null>(null);
  const [chargeEventList, setChargeEventList] = useState<ChargeEventData[]>([]);
  const [prevButton, setPrevButton] = useState<ButtonOption | null>(null);
  const [nextButton, setNextButton] = useState<ButtonOption | null>(null);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const extendMenuOptions = useCallback((...options: MenuOption[]) => {
    setMenuOptions((prev) => {
      return [
        ...prev.filter(
          (prevOption) =>
            options.find(
              (opt) => opt.order === prevOption.order && opt.value === prevOption.value,
            ) == null,
        ),
        ...options,
      ];
    });
  }, []);

  const removeMenuOptions = useCallback((...options: MenuOption[]) => {
    const removeSet = new Set([...options.map((opt) => opt.value)]);
    setMenuOptions((prev) => prev.filter((pOption) => !removeSet.has(pOption.value)));
  }, []);

  const contextVal = useMemo(
    () => ({
      menuOptions,
      setMenuOptions,
      extendMenuOptions,
      removeMenuOptions,
      customNavButton,
      setCustomNavButton,
      customTitle,
      setCustomTitle,
      chargeEventList,
      setChargeEventList,
      nextButton,
      setNextButton,
      prevButton,
      setPrevButton,
      mobileNavOpen,
      setMobileNavOpen,
    }),
    [
      menuOptions,
      extendMenuOptions,
      removeMenuOptions,
      customNavButton,
      customTitle,
      chargeEventList,
      nextButton,
      prevButton,
      mobileNavOpen,
    ],
  );

  return (
    <MobileNavHeaderMenuContext.Provider value={contextVal}>
      {children}
    </MobileNavHeaderMenuContext.Provider>
  );
}
