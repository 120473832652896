import {
  ActivationRetentionPromoCopyData,
  useActivationRetentionPromoCopyQuery,
} from '@app/hooks/query/useActivationRetentionPromoCopyQuery';
import { Button } from '@mui/material';
import { ReactElement } from 'react';
import MarketingPopup from './components/MarketingPopup';

const UnlimitedActivationPromotionPopup = (): ReactElement | null => {
  const { data, error, isLoading } = useActivationRetentionPromoCopyQuery();

  if (isLoading || error) {
    return null;
  }

  const { tagline, body } = data?.data as ActivationRetentionPromoCopyData;

  return (
    <MarketingPopup
      storageKey="unlimited-activation-retention-promotion-marketing-popup"
      heading={`Special Promotion: ${tagline}`}
      headerImg="/images/perks_banner.png" // Use the same image as perks since this will only be shown to a few users.
      body={body}
      footer={(dismiss) => (
        <>
          <Button
            size="small"
            color="secondary"
            onClick={() => {
              dismiss();
            }}
          >
            Dismiss
          </Button>
        </>
      )}
    />
  );
};

export default UnlimitedActivationPromotionPopup;
