import Divider from '@atob-developers/shared/src/components/Divider';
import { ModalFooter } from '@atob-developers/shared/src/components/Modal';
import { ReactElement } from 'react';

interface PaymentModalFooterProps {
  children: React.ReactNode;
}

const PaymentModalFooter = ({ children }: PaymentModalFooterProps): ReactElement => {
  return (
    <ModalFooter>
      <Divider className="mb-2 sm:hidden" />
      <div className="flex w-full justify-evenly gap-4 sm:justify-end">{children}</div>
    </ModalFooter>
  );
};

export default PaymentModalFooter;
