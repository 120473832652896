import { useMemo } from 'react';

declare global {
  interface Window {
    isNativeApp: boolean | undefined;
    ReactNativeWebView: {
      postMessage: (msg: string) => void;
    };
    platform: 'Android' | 'iOS';
  }
}

export default function useIsNativeApp():
  | { isNativeApp: true; platform: 'Android' | 'iOS'; postMessage: (msg: string) => void }
  | { isNativeApp: false } {
  return useMemo(
    () =>
      window.isNativeApp
        ? {
            isNativeApp: true,
            platform: window.platform,
            postMessage: (msg: string) => window.ReactNativeWebView.postMessage(msg),
          }
        : { isNativeApp: false },
    [],
  );
}
