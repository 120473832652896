import { ExportToCsv } from 'export-to-csv';

const exportCSV = ({
  csvData,
  title,
  onError,
}: {
  csvData: unknown[];
  title: string;
  onError?: () => void;
}): void | null => {
  const options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: false,
    showTitle: false,
    title,
    useTextFile: false,
    useBom: false,
    useKeysAsHeaders: true,
    filename: title.toLocaleLowerCase().replace(/\s/g, '_'),
  };
  const csvExporter = new ExportToCsv(options);
  if (!csvData || csvData.length === 0) {
    onError && onError();
    return null;
  }

  csvExporter.generateCsv(csvData);
};

export default exportCSV;
