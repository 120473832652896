import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { ReactElement } from 'react';

export default function UnlimitedToFlexMigrationOfferAccepted({
  onDone,
}: {
  onDone: () => void;
}): ReactElement {
  return (
    <div className="flex flex-col items-center p-14">
      <div className="mb-3 mt-8 text-green-600">
        <FontAwesomeIcon className="fa-3x" icon={faCircleCheck} />
      </div>
      <h1 className="mb-1 text-2xl font-bold">Credit Offer Accepted</h1>
      <p className="text-md mb-8 w-full text-center">
        We’ll email you when your fee is received and your credit line is available! Typically
        within 4-5 business days.
      </p>
      <Button className="w-1/2" color="primary" onClick={onDone}>
        Done
      </Button>
    </div>
  );
}
