import { BankAccountPaymentMethodCombined } from '@app/@types/bankAccount.types';
import { DebitCardPaymentMethodCombined } from '@app/@types/debitCard.types';
import { TpayAccountPaymentMethod } from '@app/@types/payments.types';
import useThemeConfiguration from '@app/app/useThemeConfiguration';
import VisaLogo from '@app/assets/images/png/visa_bw.png';
import HeaderLogo from '@app/assets/images/svg/header_logo.svg';
import MasterCardLogo from '@app/assets/images/svg/mastercard_logo.svg';
import { useIsUberfreight } from '@app/hooks/partners/useIsUberfreight';
import useChannelPartner from '@app/hooks/useChannelPartner';
import Card from '@atob-developers/shared/src/components/Card';
import { Button, Chip } from '@mui/material';
import { capitalize } from 'lodash-es';
import React from 'react';
import { WalletPaymentMethodInfo } from './PaymentMethods';

interface PaymentMethodCardProps {
  title: string;
  isPrimary: boolean;
  lastFour?: string;
  expiration?: string;
  brand?: string;
  balance?: string;
  onClick: () => void;
}

const PaymentMethodCard: React.FC<PaymentMethodCardProps> = ({
  title,
  isPrimary,
  lastFour,
  expiration,
  brand,
  balance,
  onClick,
}) => {
  const { showBackupPaymentMethodLabels } = useThemeConfiguration();

  let badge = null;

  if (showBackupPaymentMethodLabels && !isPrimary) {
    badge = <Chip label="Backup" color="success" />;
  } else if (isPrimary) {
    badge = <Chip label="Primary" color="success" />;
  }

  return (
    <div className="xs:basis-1/3 xs:basis-full h-[212px] w-full pr-0 md:max-w-[359px]">
      <Card>
        <div className="flex h-full flex-col justify-between">
          <div className="flex flex-row justify-between gap-2">
            <div className="flex flex-col text-sm">
              <div className="font-bold">{title}</div>
              {lastFour && <div className="flex grow items-end">•••• {lastFour}</div>}
              {balance && <div>${balance}</div>}
              {expiration && <div>Expiration {expiration}</div>}
            </div>
            <div className="w-fit">{badge}</div>
          </div>

          <div className="flex justify-between">
            <Button onClick={onClick} size="small" color="secondary">
              Manage
            </Button>
            {brand && (
              <div className="flex max-w-[76px] items-center">
                <img alt={'debit-card-logo'} src={brand} aria-hidden="true" />
              </div>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

interface DebitCardListItemProps {
  data: DebitCardPaymentMethodCombined;
  openHandler: (data: DebitCardPaymentMethodCombined) => void;
}

const DebitCardListItem: React.FC<DebitCardListItemProps> = ({ data, openHandler }) => {
  return (
    <PaymentMethodCard
      title={`${capitalize(data.brand)} Debit Card`}
      isPrimary={data.is_primary}
      lastFour={data.last_four}
      expiration={data.expires}
      brand={data.brand === 'visa' ? VisaLogo : MasterCardLogo}
      onClick={() => openHandler(data)}
    />
  );
};

interface BankAccountListItem {
  data: BankAccountPaymentMethodCombined;
  openHandler: (data: BankAccountPaymentMethodCombined) => void;
}

const BankAccountListItem: React.FC<BankAccountListItem> = ({ data, openHandler }) => {
  return (
    <PaymentMethodCard
      title="Bank Account"
      isPrimary={data.is_primary}
      lastFour={data.mask}
      onClick={() => openHandler(data)}
      balance={data.balance}
    />
  );
};

interface WalletListItem {
  data: WalletPaymentMethodInfo;
  openHandler: (data: WalletPaymentMethodInfo) => void;
}

const WalletListItem: React.FC<WalletListItem> = ({ data, openHandler }) => {
  const { partnerLogoUrl } = useChannelPartner();
  const isUF = useIsUberfreight(true);
  const title = isUF ? 'Uber Freight Wallet' : 'AtoB Wallet';
  const walletLogo = isUF ? partnerLogoUrl : HeaderLogo;
  return (
    <PaymentMethodCard
      title={title}
      isPrimary={data.is_primary}
      brand={walletLogo}
      onClick={() => openHandler(data)}
    />
  );
};

interface TpayAccountListItem {
  data: TpayAccountPaymentMethod;
  openHandler: (data: TpayAccountPaymentMethod) => void;
}

const TpayAccountListItem: React.FC<TpayAccountListItem> = ({ data, openHandler }) => {
  return (
    <PaymentMethodCard
      title="LoadPay Account"
      isPrimary={data.is_primary}
      onClick={() => openHandler(data)}
    />
  );
};

export { BankAccountListItem, DebitCardListItem, TpayAccountListItem, WalletListItem };
