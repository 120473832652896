import { createContext, Dispatch, SetStateAction, useState } from 'react';
import { v4 as uuid } from 'uuid';

export type StripeContextType = {
  clientSecret: string;
  setClientSecret: Dispatch<SetStateAction<string | null>>;
  idempotencyKey: string | null;
  generateIdempotencyKey: () => void;
  isPaymentIntentLoaded: boolean;
  loadPaymentIntent: () => void;
  resetPaymentIntent: () => void;
  loadingIntent: boolean;
  setLoadingIntent: (value: boolean) => void;
};

const StripeContext = createContext<StripeContextType>({
  clientSecret: '',
  setClientSecret: () => {},
  idempotencyKey: '',
  generateIdempotencyKey: () => {},
  isPaymentIntentLoaded: false,
  loadPaymentIntent: () => {},
  resetPaymentIntent: () => {},
  loadingIntent: false,
  setLoadingIntent: () => {},
});

export default StripeContext;

export function useStripeState(): StripeContextType {
  const [loadingIntent, setLoadingIntent] = useState(false);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [isPaymentIntentLoaded, setIsPaymentIntentLoaded] = useState(false);
  const [idempotencyKey, setIdempotencyKey] = useState<string | null>(null);
  const generateIdempotencyKey = () => {
    const idempotentKey = uuid();
    setIdempotencyKey(idempotentKey);
  };

  const loadPaymentIntent = () => setIsPaymentIntentLoaded(true);
  const resetPaymentIntent = () => setIsPaymentIntentLoaded(false);

  return {
    idempotencyKey,
    generateIdempotencyKey,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    clientSecret,
    setClientSecret,
    isPaymentIntentLoaded,
    loadPaymentIntent,
    resetPaymentIntent,
    loadingIntent,
    setLoadingIntent,
  };
}
