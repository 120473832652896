import Header from '@app/components/Navigation/Header';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import PageContentWrapper from '@app/components/wrappers/PageContentWrapper';
import UnderlinedTabs from '@atob-developers/shared/src/components/UnderlinedTabs';
import { ReactElement } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PaymentHistoryPage from '../PaymentHistory/PaymentHistoryPage';
import PaymentMethods from '../PaymentMethods/PaymentMethods';
import { Statements } from './components/Statements';

type BillingTab = 'statements' | 'payment-history' | 'payment-methods';

const BILLING_TABS: { tab: BillingTab; name: string }[] = [
  {
    tab: 'statements',
    name: 'Statements',
  },
  {
    tab: 'payment-history',
    name: 'Payment History',
  },
  {
    tab: 'payment-methods',
    name: 'Payment Methods',
  },
];

export const Billing = (): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();

  const tab =
    (location.pathname.replace('/billing', '').split('/').pop() as BillingTab) || 'statements';

  return (
    <PageContentWrapper
      header={<Header title={'Billing'} />}
      baseClassName="gap-4 md:gap-4"
      pageTabs={
        <UnderlinedTabs
          selectedTab={tab}
          handleTabChange={(newTab) => {
            navigate(`/billing/${newTab}`);
          }}
          tabs={BILLING_TABS}
        />
      }
    >
      {tab === 'statements' ? <Statements /> : null}
      {tab === 'payment-history' ? (
        <RoundedCard breakpoint="md">
          <PaymentHistoryPage withContentWrapper={false} showMonthlySubscription={false} />
        </RoundedCard>
      ) : null}
      {tab === 'payment-methods' ? <PaymentMethods /> : null}
    </PageContentWrapper>
  );
};
