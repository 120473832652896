import { Chip } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type FuelProgram = {
  name: string;
  status: 'enrolled' | 'not_enrolled';
  logo: string;
  description: ReactElement;
};

export const FuelProgramCards = (): ReactElement => {
  const { t } = useTranslation();

  const FUEL_PROGRAMS: FuelProgram[] = [
    {
      name: t('program_name', 'AtoB'),
      status: 'enrolled',
      logo: t('logo_url', '/images/loadpay-color.svg'),
      description: (
        <div>
          <strong>12¢ off</strong> per gallon at 7 Eleven
        </div>
      ),
    },
  ];
  return (
    <div>
      <div className="border-b border-gray-100 py-4 text-xl font-semibold leading-7 tracking-tight">
        Fuel Programs
      </div>
      <div className="grid grid-cols-1 gap-4 pb-4 md:grid-cols-2 lg:grid-cols-3">
        {FUEL_PROGRAMS.map((program) => (
          <Link to="/fuel-programs" key={program.name}>
            <div className="border-brown-300 mb-4 flex justify-between rounded-lg  border bg-white">
              <div className="flex justify-between px-4 py-4">
                <div className="mb-12 block">
                  <div className="mb-3 text-lg font-semibold">{program.name}</div>
                  <Chip
                    color="success"
                    size="small"
                    label={program.status === 'enrolled' ? 'Enrolled' : 'Not Enrolled'}
                    className="mb-6"
                  />
                  <div className="text-sm font-medium">{program.description}</div>
                </div>
              </div>
              <div className="bg-brown-200 flex flex-shrink-0 items-center justify-center rounded-r-lg p-8">
                <img src={program.logo} className="w-16" />
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};
