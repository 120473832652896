import CardDesign from '@app/assets/images/svg/card_design.svg';
import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import {
  CustomerOnboardingEventName,
  useCreateCustomerOnboardingEvent,
} from '@app/hooks/query/useCustomerOnboardingEvents';
import useChannelPartner from '@app/hooks/useChannelPartner';
import { Button } from '@mui/material';
import { ReactElement, useState } from 'react';
import OnboardingForm from './OnboardingForm';

export default function OnboardingCompletedStep(): ReactElement | null {
  const { trigger: loginTrigger } = useCreateCustomerOnboardingEvent({
    reloadCustomer: false,
  });
  const { trigger } = useCreateCustomerOnboardingEvent();
  const { partnerName } = useChannelPartner();
  const [showSpinner, setShowSpinner] = useState(false);
  if (showSpinner) {
    return <SpinnerBoundary />;
  }
  return (
    <OnboardingForm centered fullWidth noPadding>
      <div className="bg-onboarding-wavy w-full flex-1 bg-[rgba(255,255,255,0.5)] bg-opacity-50 bg-blend-lighten">
        <div className="mx-auto my-4 flex min-h-min w-3/4 max-w-4xl flex-col items-center gap-4 bg-white p-4  shadow-lg">
          <img src={CardDesign} alt="card-design" className="pt-10" />
          <div className="flex flex-col items-center gap-4 pb-10">
            <h1 className="text-center text-5xl font-semibold tracking-[-1.68px]">
              You're all set!
            </h1>
            <p className="text-center text-xl font-normal">
              When your {partnerName} card arrives in the mail, they’ll be auto-activated and ready
              for immediate use.
            </p>
            <Button
              size="large"
              color="primary"
              onClick={async () => {
                setShowSpinner(true);
                await loginTrigger({
                  customer_onboarding_event: {
                    name: CustomerOnboardingEventName.CUSTOMER_PORTAL_LOGIN,
                  },
                });
                trigger({
                  customer_onboarding_event: {
                    name: CustomerOnboardingEventName.CUSTOMER_ONBOARDING_COMPLETED,
                  },
                });
              }}
            >
              Go to dashboard
            </Button>
          </div>
        </div>
      </div>
    </OnboardingForm>
  );
}
