import PageContentWrapper from '@app/components/wrappers/PageContentWrapper';
import { ReactElement } from 'react';
import PaymentMethods from './PaymentMethods';
import PaymentMethodsPageHeader from './PaymentMethodsPageHeader';

export default function PaymentMethodsPage(): ReactElement {
  return (
    <PageContentWrapper header={<PaymentMethodsPageHeader />}>
      <PaymentMethods />
    </PageContentWrapper>
  );
}
