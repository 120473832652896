import { ReactElement, ReactNode } from 'react';
interface CardRowProps {
  children: ReactNode;
}

const CardRow = ({ children }: CardRowProps): ReactElement => {
  return <div className="relative flex items-center justify-between space-x-2">{children}</div>;
};

export default CardRow;
