import useLocalStorage from '@app/components/popups/components/useLocalStorage';
import { Button, Link } from '@mui/material';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

const UpsellTemplate = ({ children }: React.PropsWithChildren<unknown>): ReactElement => {
  return (
    <div className="rounded-card bg-telematics-grey relative hidden min-h-[13rem] flex-col gap-2 overflow-hidden rounded-lg p-4 pt-6 md:flex md:p-6">
      <div className="absolute bottom-0 right-0 top-0">
        <img src="/images/charge_event_vector_small.svg" alt="Small upsell vector" />
      </div>
      <div className="absolute bottom-0 right-0 top-0">
        <img src="/images/charge_event_vector_big.svg" alt="Big upsell vector" />
      </div>
      <div className="absolute bottom-0 right-0 top-0 my-auto h-40">
        <img src="/images/telematics_upsell.png" alt="telematics-upsell" className="h-44" />
      </div>

      {children}
    </div>
  );
};

export const ChargeEventUpsellTelematics = (): ReactElement | null => {
  const navigate = useNavigate();
  const [dismissed, setDismissed] = useLocalStorage('charge-event-upsell-telematics', false);

  if (dismissed) {
    return null;
  }

  return (
    <UpsellTemplate>
      <div className="mr-96 pr-20 text-xl font-medium">
        Connect your telematics provider and automate industry-leading checks against fuel theft.
      </div>

      <div className="py-3 text-xs">
        *Requires usage of a telematics provider that integrates with AtoB.
      </div>

      <div className="flex flex-row gap-4">
        <Button size="small" onClick={() => navigate('/integrations')}>
          Connect Telematics
        </Button>

        <Link component="button" variant="body2" onClick={() => setDismissed(true)}>
          Dismiss
        </Link>
      </div>
    </UpsellTemplate>
  );
};

export const ChargeEventUpsellPremium = (): ReactElement | null => {
  const navigate = useNavigate();
  const [dismissed, setDismissed] = useLocalStorage('charge-event-upsell-premium', false);

  if (dismissed) {
    return null;
  }

  return (
    <UpsellTemplate>
      <div className="mr-96 pr-20 text-xl font-medium">
        Upgrade to AtoB Premium and automate industry-leading checks against fuel theft.
      </div>

      <div className="py-3 text-xs">
        *Requires usage of a telematics provider that integrates with AtoB.
      </div>

      <div className="flex flex-row gap-4">
        <Button
          color="primary"
          onClick={() => navigate('/settings/plans/atob_plus_premium')}
          className="w-80"
        >
          Get Premium
        </Button>

        <Link component="button" variant="body2" onClick={() => setDismissed(true)}>
          Dismiss
        </Link>
      </div>
    </UpsellTemplate>
  );
};
