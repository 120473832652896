import { ReactElement } from 'react';

const DescriptionItem = ({
  text,
  link,
}: {
  text: string;
  link?: { text: string; url: string };
}): ReactElement => {
  return (
    <p className="text-ds-cool-gray text-left text-sm font-normal" data-testid="DescriptionItem">
      {text}{' '}
      {link && (
        <a href={link.url} className="text-ds-dark-green" data-testid="DescriptionItem-Link">
          {link.text}
        </a>
      )}
    </p>
  );
};

export default DescriptionItem;
