import { CustomerData } from '@app/@types/customer.types';
import stripeErrorHandler from '@app/components/StripeElements/stripe-error-handler';
import Constants from '@app/utils/constants';
import * as Sentry from '@sentry/react';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import axios, { AxiosError } from 'axios';
import { deserialize } from 'deserialize-json-api';
import { STRIPE_STATUS_SUCCEEDED } from './constants';
import { ModalType } from './enums';

export type ErrorState = {
  default?: boolean | null;
  message?: string | null;
};

export type SetFieldError = (field: string, errorMsg: string) => void;

const INITIAL_ERROR_MESSAGE = '';

export const INITIAL_ERROR_STATE: ErrorState = {
  default: false,
  message: INITIAL_ERROR_MESSAGE,
};

const handleTooManyRequestsError = (setOverallError: (val: ErrorState) => void) => {
  setOverallError({
    default: false,
    message: Constants.ErrorMessages.TOO_MANY_PLAID_REQUESTS,
  });
  setTimeout(() => {
    setOverallError(INITIAL_ERROR_STATE);
  }, 30000);
};

export const onFailure = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  err: AxiosError<any>,
  setOverallError: (val: ErrorState) => void,
  setFieldError?: SetFieldError,
): void => {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (err.response.status === 422) {
      const error = err.response?.data?.errors;

      if (error?.base) {
        setOverallError({ message: error.base[0], default: false });
      } else if (error?.amount) {
        setFieldError && setFieldError('amount', error.amount[0]);
      } else {
        setOverallError({ default: true });
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
    } else if (err.response.status === 429) {
      handleTooManyRequestsError(setOverallError);
    } else {
      setOverallError({ default: true });
    }
  } catch (e) {
    setOverallError({ default: true });
  }
};

export const submitACHPayment = (
  values: {
    amount: string;
    bankAccountId: string;
  },
  {
    onSuccess,
    setFieldError,
    setOverallError,
  }: {
    onSuccess: (customer: CustomerData) => void;
    setFieldError: SetFieldError;
    setOverallError: (val: ErrorState) => void;
  },
): void => {
  const { amount, bankAccountId } = values;

  axios
    .post('/ach_charges?include=customer', { ach_charge: { amount, bankAccountId } })
    .then(({ data }) => onSuccess(deserialize(data).data.customer))
    .catch((e) => onFailure(e, setOverallError, setFieldError));
};

export const submitWalletPayment = (
  values: {
    amount: string;
  },
  {
    onSuccess,
    setFieldError,
    setOverallError,
  }: {
    onSuccess: (val: CustomerData) => void;
    setFieldError: SetFieldError;
    setOverallError: (val: ErrorState) => void;
  },
): void => {
  const { amount } = values;
  axios
    .post('/ach_charges?include=customer', { ach_charge: { amount, use_wallet: true } })
    .then(({ data }) => onSuccess(deserialize(data).data.customer))
    .catch((e) => onFailure(e, setOverallError, setFieldError));
};

export const submitDebitCardPayment = ({
  stripe,
  elements,
  setModalType,
  clientSecret,
  setSubmitting,
  customer,
  setOverallError,
}: {
  stripe: Stripe;
  elements: StripeElements;
  setModalType: (value: ModalType) => void;
  clientSecret: string;
  setSubmitting: (value: boolean) => void;
  customer: CustomerData;
  setOverallError: (error: ErrorState) => void;
}): void => {
  setSubmitting(true);
  if (!stripe || !elements) {
    setModalType(ModalType.ERROR);
  }
  stripe
    .confirmCardPayment(clientSecret)
    .then(async (savedCardPaymentIntentResponse) => {
      const { paymentIntent, error: paymentError } = savedCardPaymentIntentResponse;
      const hasError = stripeErrorHandler(
        (err: string) => setOverallError({ default: false, message: err }),
        paymentError,
      );
      const status = paymentIntent?.status;
      if (hasError || status !== STRIPE_STATUS_SUCCEEDED) {
        setOverallError({ default: false, message: 'Payment failed, please try again.' });
        setModalType(ModalType.ERROR);
      } else {
        setModalType(ModalType.SUCCESS);
      }
      setSubmitting(false);
    })
    .catch((e: unknown) => {
      setSubmitting(false);
      setOverallError({ default: true });
      Sentry.captureException(e, {
        user: {
          request_id: axios.isAxiosError(e) ? e?.response?.headers?.['x-request-id'] : '',
          customer_email: customer?.owner_email ?? '',
        },
      });
    });
};
