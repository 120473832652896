import { ExportStart } from '@app/components/Exporting/Screens';
import { Dialog } from '@mui/material';
import dayjs from 'dayjs';
import dayjsObjectSupport from 'dayjs/plugin/objectSupport';

import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { ReactElement, useState } from 'react';
import DownloadStatus from '../DownloadStatus';
import { ExportType } from '../exports';
import { useExportJob } from '../useExportJob';

dayjs.extend(quarterOfYear);
dayjs.extend(dayjsObjectSupport);

const DefaultExport = ({
  open,
  onClose,
  selectedFilters,
  exportType,
}: {
  open: boolean;
  onClose: () => void;
  selectedFilters: Record<string, unknown>;
  exportType: ExportType;
}): ReactElement => {
  const [state, setState] = useState<'confirm' | 'downloading'>('confirm');

  const { downloadLink, downloadState, submitExportJob } = useExportJob(exportType, {
    ...selectedFilters,
  });

  const renderScreen = () => {
    switch (state) {
      case 'confirm':
        return (
          <ExportStart
            onContinue={() => {
              setState('downloading');
              submitExportJob();
            }}
            onClose={onClose}
          />
        );
      case 'downloading':
        return (
          <DownloadStatus
            downloadState={downloadState}
            downloadLink={downloadLink}
            onClose={onClose}
          />
        );
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      {renderScreen()}
    </Dialog>
  );
};

export default DefaultExport;
