import { DriverData } from '@app/@types/driver.types';
import { DRIVER_FORM_DATA } from '@app/constants/drivers';
import useProduct from '@app/hooks/useProduct';
import DataItem, { DataItemType } from '@atob-developers/shared/src/components/DataItem';
import { SideBarBody } from '@atob-developers/shared/src/components/SideBar';
import { faLink } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize } from 'lodash-es';
import { ReactElement } from 'react';
import PreviewDriverSidebarAccountStatus from './PreviewDriverSidebarAccountStatus';
import PreviewDriverSidebarPhone from './PreviewDriverSidebarPhone';
interface PreviewDriverSidebarBodyProps {
  driver: DriverData;
  enableStreamlinedDriverAppOnboarding: boolean;
}

const PreviewDriverSidebarBody = ({
  driver,
  enableStreamlinedDriverAppOnboarding,
}: PreviewDriverSidebarBodyProps): ReactElement => {
  const [allowSelfServePins] = useProduct('drivers_unlock_id');

  const itemContent = (path: string, content: string) => {
    if (path === 'account_status') {
      switch (content) {
        case 'account_created':
          return 'Logged In';
        case 'invited':
          return 'Ready to Use';
        case 'not_invited':
          return 'Not Invited';
      }
      return '-';
    }

    return content;
  };

  return (
    <SideBarBody>
      <div className="flex flex-col space-y-8 text-sm">
        {!!driver.synchronized && (
          <div className="bg-blue0 flex flex-row items-center gap-2 rounded-md p-3 italic">
            <FontAwesomeIcon icon={faLink} className="px-1 text-blue-500" />
            Synchronized via {capitalize(driver.source || '')}{' '}
          </div>
        )}
        {!!driver &&
          DRIVER_FORM_DATA.filter((data) => {
            return (
              (enableStreamlinedDriverAppOnboarding ? data : data.path !== 'account_status') &&
              (allowSelfServePins || data.path !== 'unlock_id') &&
              (!!driver.external_username || data.path !== 'external_username')
            );
          }).map((item, idx) => {
            if (item.path === 'phone') {
              return (
                <PreviewDriverSidebarPhone
                  key={`sidebar-item-${idx}`}
                  item={item}
                  driver={driver}
                />
              );
            } else if (item.path === 'account_status') {
              return (
                <PreviewDriverSidebarAccountStatus
                  key={`sidebar-item-${idx}`}
                  item={item}
                  driver={driver}
                />
              );
            }
            return (
              <DataItem
                key={`sidebar-item-${idx}`}
                item={{
                  ...item,
                  type: DataItemType.TEXT,
                  content:
                    item?.path && driver[item?.path]
                      ? itemContent(item?.path, driver[item?.path] || '')
                      : '-',
                }}
              />
            );
          })}
      </div>
    </SideBarBody>
  );
};

export default PreviewDriverSidebarBody;
