import { Coordinates, FuelGrade, FuelListing, FuelPrice } from '@app/@types/fuel_listings.types';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import { getIsValidPrice, getReadableDistanceFromFuelListing } from '@app/utils/fuel-listings';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronLeft,
  faUpload,
  faRoute,
  faLocationDot,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { startCase } from 'lodash-es';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FuelListingPriceWithDiscount from './FuelListingPriceWithDiscount';
import FuelListingShareStationModal from './FuelListingShareStationModal';
import { BrandLogo } from './Logos/BrandLogo';
dayjs.extend(relativeTime);

export interface FuelListingsListProps {
  isFetching: boolean;
  originLocation: Coordinates | undefined;
  fuelListing: FuelListing | undefined;
  fuelGrade: FuelGrade;
  onSelect: (fuelListing: FuelListing | null) => void;
}

const FuelListingDetailPrice = ({ price }: { price: FuelPrice }) => {
  const date = price.attributes.fuel_sign_time ?? '';

  const isGuaranteedDiscount = price.attributes.discount_type === 'RETAIL_MINUS';
  const isPriceValid = getIsValidPrice(price);
  return (
    <div className="flex flex-col gap-1 py-3">
      <div className="flex flex-row justify-between">
        <div className="min-w-0">
          <div className="text-ds-cool-gray-900 truncate text-ellipsis text-lg font-semibold capitalize leading-6">
            {price.attributes.high_flow && price.attributes.fuel_grade === 'DIESEL'
              ? 'Truck Diesel'
              : price.attributes.fuel_grade.toLowerCase()}
          </div>
          <p className="text-secondary flex w-full items-center gap-1 text-xs">
            {isPriceValid ? (
              <span className="truncate text-ellipsis">Updated {dayjs(date).fromNow()}</span>
            ) : isGuaranteedDiscount || price.attributes.discount_per_gallon_cents === 0 ? (
              <span className="truncate text-ellipsis">Price Unavailable</span>
            ) : null}
          </p>
        </div>
        <div className="flex flex-col items-end justify-center">
          <FuelListingPriceWithDiscount price={price} />
        </div>
      </div>
    </div>
  );
};

const FuelListingDetailPrices = ({ prices }: { prices: FuelPrice[] }) => {
  if (!prices.length) {
    return null;
  }

  // Pull truck diesel prices to the top.
  const displayPrices = prices.sort((a, _) => {
    return a.attributes.high_flow ? -1 : 1;
  });

  return (
    <div className="flex flex-col gap-2">
      <h3 className="text-secondary text-sm font-medium">Fuel Prices</h3>
      <div className="flex flex-col">
        {displayPrices.map((price: FuelPrice) => (
          <FuelListingDetailPrice price={price} key={price.id} />
        ))}
      </div>
    </div>
  );
};

const FuelListingDetailOverview = ({
  fuelListing,
  toggleModal,
}: {
  fuelListing: FuelListing;
  toggleModal: () => void;
}) => {
  const [shareStations] = useFeatureFlags('share_stations');
  return (
    <div className="flex flex-col gap-3 pb-4">
      <div className="flex flex-row gap-3 ">
        <div className="flex flex-col justify-start">
          <BrandLogo
            logoURL={fuelListing.attributes.logo_url}
            brand={fuelListing.attributes.location.name}
            size={{ width: 30, height: 30 }}
          />
        </div>
        <div className="flex flex-col gap-1">
          <span className="text-primary text-2xl font-semibold tracking-[-0.6px]">
            {fuelListing.attributes.location.name}
          </span>
        </div>
      </div>
      {shareStations && (
        <Button
          color="primary"
          size="small"
          onClick={toggleModal}
          startIcon={<FontAwesomeIcon icon={faUpload} className="size-4" />}
        >
          Share to drivers
        </Button>
      )}
    </div>
  );
};

const FuelListingDetailLocation = ({
  currentLocation,
  fuelListing,
}: {
  currentLocation: Coordinates | undefined;
  fuelListing: FuelListing;
}) => {
  const distance = useMemo(() => {
    if (currentLocation == null) {
      return null;
    }
    return getReadableDistanceFromFuelListing(currentLocation, fuelListing);
  }, [currentLocation, fuelListing]);
  return (
    <div className="flex flex-col gap-2 py-4">
      <h3 className="text-secondary text-sm font-medium">Location</h3>
      <div>
        {distance && (
          <p className="text-primary flex items-center gap-2 py-3 text-base font-medium">
            <FontAwesomeIcon fixedWidth size="lg" icon={faRoute} />
            {distance}
          </p>
        )}
        <p className="text-primary flex items-center gap-2 py-3 text-base font-medium underline">
          <FontAwesomeIcon fixedWidth size="lg" icon={faLocationDot} />
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://www.google.com/maps?q=${startCase(
              fuelListing.attributes.location.address1.toLowerCase(),
            )}, ${startCase(fuelListing.attributes.location.locality.toLowerCase())}, ${
              fuelListing.attributes.location.region
            } ${fuelListing.attributes.location.postCode}`}
          >
            {`${startCase(fuelListing.attributes.location.address1.toLowerCase())}, ${startCase(
              fuelListing.attributes.location.locality.toLowerCase(),
            )}, ${fuelListing.attributes.location.region} ${
              fuelListing.attributes.location.postCode
            }`}
          </a>
        </p>
      </div>
    </div>
  );
};

export default function FuelListingsDetail({
  originLocation,
  fuelListing,
  fuelGrade,
  onSelect,
}: FuelListingsListProps) {
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);
  const toggleModal = () => setModalOpen(!isModalOpen);
  const [shareStations] = useFeatureFlags('share_stations');

  if (!fuelListing) {
    return null;
  }

  return (
    <>
      <div className="border-grey16 flex flex-row items-center gap-2 border-b px-6 py-4 text-base font-semibold">
        <FontAwesomeIcon
          icon={faChevronLeft as IconProp}
          className="cursor-pointer"
          role="button"
          onClick={() => onSelect(null)}
          size="sm"
        />
        Station Details
      </div>

      <div className="flex flex-col overflow-auto px-6 py-4">
        <FuelListingDetailOverview fuelListing={fuelListing} toggleModal={toggleModal} />
        <FuelListingDetailLocation currentLocation={originLocation} fuelListing={fuelListing} />
        <FuelListingDetailPrices prices={fuelListing.attributes.fuel_prices.data} />
        <div className="text-sm font-semibold">
          Purchase with your {t('program_name', 'AtoB')} card to receive discounted prices.
        </div>
      </div>
      {shareStations && (
        <FuelListingShareStationModal
          open={isModalOpen}
          onClose={toggleModal}
          fuelListing={fuelListing}
          fuelGrade={fuelGrade}
          originLocation={originLocation}
        />
      )}
    </>
  );
}
