import Header from '@app/components/Navigation/Header';
import { PageContentWrapper } from '@app/components/wrappers/PageContentWrapper';
import { ReactElement } from 'react';
import AccountingExportContainer from './AccountingExportContainer';

export default function Accounting({
  resourceTagsEnabled,
}: {
  resourceTagsEnabled: boolean;
}): ReactElement {
  return (
    <PageContentWrapper header={<Header title="Accounting" />}>
      <div className="flex flex-col">
        <AccountingExportContainer resourceTagsEnabled={resourceTagsEnabled} />
      </div>
    </PageContentWrapper>
  );
}
