import { ChargeEventData } from '@app/@types/charge_events.types';
import { capitalize } from 'lodash-es';
import { ReactElement } from 'react';
import { DetailAlertType, DetailElement, DetailSection } from './DetailSection';
import {
  formatLocation,
  notAvailableUnlessPresent,
  relativeTimestamp,
  vehicleAssociationReason,
} from './utils';

export const ChargeEventVehicleDetails = ({
  chargeEvent,
}: {
  chargeEvent: ChargeEventData;
}): ReactElement => {
  const telematicsDataAvailable = chargeEvent?.vehicle_location;
  const suspicious_location = !!(
    telematicsDataAvailable && chargeEvent.vehicle_location?.suspicious_location
  );
  const isTimestampStale = !!(
    telematicsDataAvailable && chargeEvent.vehicle_location?.captured_at_stale
  );

  const distance = chargeEvent?.vehicle_location?.distance_from_merchant_miles;
  const speedMph = chargeEvent?.vehicle_location?.speed_mph;
  const distanceDisplay = notAvailableUnlessPresent(
    distance && (distance < 0.1 ? 'Less than 0.1 miles' : `${distance.toFixed(1)} miles`),
  );

  return (
    <DetailSection title="Vehicle">
      <div className="grid lg:grid-cols-2 lg:gap-24">
        <div>
          <DetailElement
            name="Vehicle"
            value={
              chargeEvent.vehicle_name ||
              chargeEvent.vehicle_vin ||
              'No vehicle assigned to this card'
            }
          />
          <DetailElement
            name="Identification Method"
            value={notAvailableUnlessPresent(
              vehicleAssociationReason(chargeEvent.vehicle_association_reason),
            )}
          />
          <DetailElement
            name="VIN (Telematics)"
            value={notAvailableUnlessPresent(chargeEvent.vehicle_vin)}
          />

          <DetailElement
            name="Vehicle Make"
            value={notAvailableUnlessPresent(chargeEvent.vehicle_make)}
          />
          <DetailElement
            name="Vehicle Model"
            value={notAvailableUnlessPresent(chargeEvent.vehicle_model)}
          />
          <DetailElement
            name="Vehicle Year"
            value={notAvailableUnlessPresent(chargeEvent.vehicle_year)}
          />
        </div>
        <div>
          <DetailElement
            name="Prompted Odometer"
            value={notAvailableUnlessPresent(chargeEvent.service_prompts_odometer)}
          />
          <DetailElement
            name="Telematics Odometer"
            value={notAvailableUnlessPresent(chargeEvent.telematics_odometer)}
          />
          <DetailElement
            name="Location"
            value={notAvailableUnlessPresent(
              chargeEvent?.vehicle_location && formatLocation(chargeEvent?.vehicle_location),
            )}
          />
          <DetailElement
            name="Timestamp"
            alert={isTimestampStale ? DetailAlertType.Warn : DetailAlertType.Default}
            tooltip={
              isTimestampStale &&
              'Vehicle location is potentially too stale to classify if suspicious'
            }
            value={notAvailableUnlessPresent(
              capitalize(
                (chargeEvent?.vehicle_location?.captured_at &&
                  relativeTimestamp(
                    chargeEvent.vehicle_location.captured_at,
                    chargeEvent.authorized_at,
                  )) ||
                  '',
              ),
            )}
          />
          <DetailElement
            name="Distance from Merchant"
            tooltip={suspicious_location && 'The vehicle was not close to the merchant location'}
            alert={suspicious_location ? DetailAlertType.Warn : DetailAlertType.Default}
            value={distanceDisplay}
          />
          <DetailElement
            name="Speed"
            tooltip={suspicious_location && 'The vehicle was moving at the time of action'}
            alert={
              speedMph !== undefined && speedMph > 0
                ? DetailAlertType.Warn
                : DetailAlertType.Default
            }
            value={notAvailableUnlessPresent(speedMph != null && `${speedMph} mph`)}
          />
        </div>
      </div>
    </DetailSection>
  );
};

export default ChargeEventVehicleDetails;
