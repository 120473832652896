import { ChargeEventData } from '@app/@types/charge_events.types';
import SavingsLogo from '@app/assets/images/svg/atob_savings.svg';
import { useIsUberfreight } from '@app/hooks/partners/useIsUberfreight';
import ChargeEventDetailPane from '@app/pages/TransactionDetail/ChargeEventDetailPane';
import { FormatCurrency } from '@atob-developers/shared/src/utils/formatters';
import { formatCurrencyDollarOrCents } from '@atob-developers/shared/src/utils/formatters/CurrencyFormat';
import { Link } from '@mui/material';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

export const ChargeEventSavingsInfo = ({
  chargeEvent,
}: {
  chargeEvent: ChargeEventData;
}): ReactElement | null => {
  const navigate = useNavigate();
  const isUF = useIsUberfreight();

  if (
    chargeEvent.auth_status === 'pending' ||
    chargeEvent.auth_status === 'reversed' ||
    !chargeEvent.auth_approved
  ) {
    return null;
  }

  if (chargeEvent.net_amount_cents < chargeEvent.gross_amount_cents) {
    return (
      <div className="bg-ds-light-green md:rounded-card flex flex-1 flex-col gap-2 rounded-lg p-4">
        <div className="text-ds-green flex items-center gap-1">
          <img src={SavingsLogo} /> AtoB Savings
        </div>
        <p className="text-ds-green text-2xl font-semibold leading-none">
          You saved {FormatCurrency({ value: chargeEvent.discount_amount_cents })}
        </p>
        {chargeEvent.discount_unit_price?.cents != null && (
          <p className="text-gray-500">
            {formatCurrencyDollarOrCents(chargeEvent.discount_unit_price?.cents)} per gal
          </p>
        )}
      </div>
    );
  }

  if (!chargeEvent.potential_savings) {
    return null;
  }

  const totalSavings = chargeEvent.potential_savings.total_potential_savings?.cents;
  const savingsCurrency = totalSavings ? formatCurrencyDollarOrCents(totalSavings) : null;

  return (
    <div className="md:rounded-card bg-yellow1 flex flex-1 flex-col divide-y divide-gray-200 rounded-lg md:flex-row md:divide-x md:divide-y-0">
      <ChargeEventDetailPane
        label={
          <div className="text-ds-green flex items-center gap-1 text-sm leading-none">
            <img src={SavingsLogo} className="h-[14px]" />{' '}
            <p className="text-sm leading-none">AtoB Savings</p>
          </div>
        }
        value={
          savingsCurrency && (
            <p className="text-2xl font-semibold leading-none">
              You could have saved {savingsCurrency}
            </p>
          )
        }
        subValue={
          chargeEvent.potential_savings.savings_amount_in_cents && (
            <span className="leading-none text-gray-500">
              {formatCurrencyDollarOrCents(chargeEvent.potential_savings.savings_amount_in_cents)}{' '}
              per gal
            </span>
          )
        }
      />
      <ChargeEventDetailPane
        label="Merchant"
        value={chargeEvent.potential_savings.station_name}
        subValue={
          <>
            <span className="uppercase leading-none text-gray-500">
              {chargeEvent.potential_savings.station_location_short}
            </span>
            <span className="leading-none text-gray-500">
              {chargeEvent.potential_savings.distance_in_miles} away{' '}
              {!isUF && (
                <Link
                  className="cursor-pointer"
                  onClick={() => {
                    navigate(
                      `/perks?address=${encodeURIComponent(
                        chargeEvent.potential_savings?.station_location ?? '',
                      )}`,
                    );
                  }}
                >
                  View on Fuel Map
                </Link>
              )}
            </span>
          </>
        }
      />
    </div>
  );
};
