import AddressInput, { Address } from '@app/components/elements/AddressInput';
import useWindowWidth from '@app/hooks/useWindowWidth';
import isNumeric from '@app/utils/is-numeric';
import { DataItemType } from '@atob-developers/shared/src/components/DataItem';
import FormElement from '@atob-developers/shared/src/components/FormElement';
import RadioGroup from '@atob-developers/shared/src/components/RadioButton';
import { ReactElement, Dispatch } from 'react';

type RecipientFormProps = {
  type: 'add' | 'edit';
  formId: string;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  errors: Record<string, string>;
  name: string;
  setName: (name: string) => void;
  nickname: string;
  setNickname: (nickname: string) => void;
  isBusinessAccount: boolean;
  setIsBusinessAccount: (isBusinessAccount: boolean) => void;
  routingNumber: string;
  setRoutingNumber: (routingNumber: string) => void;
  accountNumber: string;
  setAccountNumber: (accountNumber: string) => void;
  confirmedAccountNumber: string;
  setConfirmedAccountNumber: (confirmedAccountNumber: string) => void;
  addressValues: Address;
  setAddressValues: (addressValues: Address) => void;
  addressErrors: Record<string, string>;
  setAddressErrors: (addressErrors: Record<string, string | undefined>) => void;
  children: React.ReactNode;
};

export default function RecipientForm({
  type,
  formId,
  onSubmit,
  errors,
  name,
  setName,
  nickname,
  setNickname,
  isBusinessAccount,
  setIsBusinessAccount,
  routingNumber,
  setRoutingNumber,
  accountNumber,
  setAccountNumber,
  confirmedAccountNumber,
  setConfirmedAccountNumber,
  addressValues,
  setAddressValues,
  addressErrors,
  setAddressErrors,
  children,
}: RecipientFormProps): ReactElement {
  const { isMobile } = useWindowWidth();

  return (
    <form id={formId} onSubmit={onSubmit}>
      <FormElement
        required
        disabled={type === 'edit'}
        error={errors.name}
        element={{
          label: 'Account Holder Name',
          type: DataItemType.TEXT,
          key: 'name',
        }}
        handleOnChange={(value) => value !== null && setName(value)}
        value={name}
      />
      <FormElement
        element={{
          label: 'Nickname (optional)',
          placeholder: 'Nickname',
          type: DataItemType.TEXT,
          key: 'nickname',
        }}
        handleOnChange={(value) => value !== null && setNickname(value)}
        value={nickname}
      />
      <div className="mt-2 max-w-[200px]">
        <div className="mb-1 text-sm font-medium text-gray-700">Account Type</div>
        <RadioGroup
          data={[
            {
              label: 'Business',
              name: 'Business',
              value: 'business',
              id: 'business',
              checked: isBusinessAccount,
            },
            {
              label: 'Personal',
              name: 'Personal',
              value: 'personal',
              id: 'personal',
              checked: !isBusinessAccount,
            },
          ]}
          onSelect={(id) => {
            if (type === 'edit') {
              return;
            }
            setIsBusinessAccount(id === 'business');
          }}
          className="flex justify-between"
        />
      </div>
      {type === 'add' && (
        <>
          <FormElement
            required
            error={errors.routing}
            element={{
              label: 'Routing Number',
              type: DataItemType.TEXT,
              key: 'routing',
            }}
            handleOnChange={(value) => {
              if (value && !isNumeric(value)) {
                return;
              }
              value !== null && setRoutingNumber(value);
            }}
            value={routingNumber}
          />
          <FormElement
            required
            error={errors.account}
            element={{
              label: 'Account Number',
              type: DataItemType.TEXT,
              key: 'account',
            }}
            handleOnChange={(value) => {
              if (value && !isNumeric(value)) {
                return;
              }
              value !== null && setAccountNumber(value);
            }}
            value={accountNumber}
          />
        </>
      )}
      {(accountNumber !== '' || confirmedAccountNumber !== '') && (
        <FormElement
          required
          error={errors.confirmedAccount}
          element={{
            label: 'Confirm Account Number',
            type: DataItemType.TEXT,
            key: 'confirmAccount',
          }}
          handleOnChange={(value) => {
            if (value && !isNumeric(value)) {
              return;
            }
            value !== null && setConfirmedAccountNumber(value);
          }}
          value={confirmedAccountNumber}
        />
      )}

      <AddressInput
        isMobile={isMobile}
        label="Recipient Address (for Wire Transfers only)"
        addressValues={addressValues as Record<string, string>}
        setAddressValues={setAddressValues as Dispatch<unknown>}
        errors={addressErrors}
        setErrors={setAddressErrors}
      />
      <div className="mb-4 mt-8 flex w-full justify-end">{children}</div>
    </form>
  );
}
