import dayjs from 'dayjs';
import dayjsObjectSupport from 'dayjs/plugin/objectSupport';

dayjs.extend(dayjsObjectSupport);

export const beginningOfDay = {
  hour: 0,
  minute: 0,
  second: 0,
  millisecond: 0,
};

export const endOfDay = {
  hour: 23,
  minute: 59,
  second: 59,
  millisecond: 99,
};
