import Header from '@app/components/Navigation/Header';
import PageContentWrapper from '@app/components/wrappers/PageContentWrapper';
import useProduct from '@app/hooks/useProduct';
import { ReactElement } from 'react';
import FleetioIntegration from './Fleetio/FleetioIntegration';
import TelematicsIntegration from './Telematics/TelematicsIntegration';

const Integrations = (): ReactElement => {
  const [fleetioEnabled] = useProduct('fleetio');
  return (
    <div>
      <PageContentWrapper header={<Header title="Integrations" />}>
        <TelematicsIntegration />
        {fleetioEnabled && <FleetioIntegration />}
      </PageContentWrapper>
    </div>
  );
};

export default Integrations;
