import { ReactElement } from 'react';
import SpinnerBoundary from '../Spinner/SpinnerBoundary';

export default function Loading(): ReactElement {
  return (
    <section data-testid="loading">
      <SpinnerBoundary />
    </section>
  );
}
