import { Loading } from '@app/components/layout';
import axios from 'axios';
import { ReactElement, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export function SwitchCustomer(): ReactElement {
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    axios.post(`/customer/switch_customer`, { id }).finally(() => {
      window.location.href = '/';
    });
  }, [id, navigate]);
  return <Loading />;
}
