import { ReactElement } from 'react';
import Title from './Title';

const Cashback = ({ cashback }: { cashback: string }): ReactElement | null => {
  if (!cashback) {
    return null;
  }

  return (
    <div className="flex h-full flex-col justify-between">
      <Title title="Cashback this month" />
      <div className="mb-[6px] text-[24px] font-normal">{cashback}</div>
    </div>
  );
};

export default Cashback;
