import classNames from 'classnames';
import React, { ReactElement, ReactNode } from 'react';
const ActionButton = ({
  disabled,
  primary,
  children,
  onClick,
  className = '',
}: {
  disabled?: boolean;
  primary?: boolean;
  onClick?: (e: React.SyntheticEvent) => void;
  children: ReactNode;
  className?: string;
}): ReactElement => {
  const buttonClass = classNames(
    `inline-flex items-center justify-center py-1.5 text-sm font-medium rounded min-w-[7rem] ${className}`,
    {
      'cursor-not-allowed disabled text-gray-400 hover:bg-gray-200 bg-gray-100 px-2.5 ': disabled,
      'text-white bg-sky-600 focus:ring-sky-500 px-2.5 ': !disabled && primary,
      'text-gray-900 bg-white border ring-gray-900': !disabled && !primary,
    },
  );

  return (
    <button
      onClick={(...args) => {
        onClick && onClick(...args);
      }}
      disabled={disabled}
      type="button"
      className={buttonClass}
    >
      {children}
    </button>
  );
};

export default ActionButton;
