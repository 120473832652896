import { ChargeEventData } from '@app/@types/charge_events.types';
import { FormatCurrency } from '@atob-developers/shared/src/utils/formatters';
import { ReactElement } from 'react';
import { DetailAlertType, DetailElement, DetailSection } from './DetailSection';
import { notAvailableUnlessPresent } from './utils';

export const ChargeEventFuelLevelDetails = ({
  chargeEvent,
}: {
  chargeEvent: ChargeEventData;
}): ReactElement => {
  const { fuel_levels_for_transaction } = chargeEvent;

  const is_suspicious = fuel_levels_for_transaction?.is_suspicious;
  const gallonsMissing = parseFloat(
    fuel_levels_for_transaction?.purchase_difference_by_min_max || '0',
  );

  const excessAmount =
    fuel_levels_for_transaction &&
    fuel_levels_for_transaction.overcharge_amount &&
    FormatCurrency({
      value: fuel_levels_for_transaction?.overcharge_amount?.cents,
    });

  const gallonsMissingAdjusted = Math.max(gallonsMissing, 0);

  const tankCapacityDisplay = fuel_levels_for_transaction?.tank_capacity_gallons
    ? `${parseFloat(fuel_levels_for_transaction?.tank_capacity_gallons).toFixed(1)} gal`
    : null;

  const prePurchaseFuelDisplay = fuel_levels_for_transaction?.gallons_before_authorization_min
    ? `${parseFloat(fuel_levels_for_transaction?.gallons_before_authorization_min).toFixed(1)} gal`
    : null;

  const gallonsPurchasedDisplay = fuel_levels_for_transaction?.gallons_purchased
    ? `${parseFloat(fuel_levels_for_transaction?.gallons_purchased).toFixed(1)} gal`
    : null;

  const expectedFuelLevelDisplay = fuel_levels_for_transaction?.expected_fuel_level
    ? `${parseFloat(fuel_levels_for_transaction?.expected_fuel_level).toFixed(1)} gal`
    : null;

  const actualFuelLevelDisplay = fuel_levels_for_transaction?.gallons_after_authorization_max
    ? `${parseFloat(fuel_levels_for_transaction?.gallons_after_authorization_max).toFixed(1)} gal`
    : null;

  const excessGallonsDisplay = gallonsMissingAdjusted && `${gallonsMissingAdjusted.toFixed(1)} gal`;

  return (
    <DetailSection title="Fuel Level">
      <div className="grid lg:grid-cols-2 lg:gap-24">
        <div>
          <DetailElement
            name="Tank Capacity"
            value={notAvailableUnlessPresent(tankCapacityDisplay)}
          />
          <DetailElement
            name="Pre-Purchase Fuel Level"
            value={notAvailableUnlessPresent(prePurchaseFuelDisplay)}
          />
          <DetailElement
            name="Gallons Purchased"
            value={notAvailableUnlessPresent(gallonsPurchasedDisplay)}
          />
          <DetailElement
            name="Expected Fuel Level"
            value={notAvailableUnlessPresent(expectedFuelLevelDisplay)}
          />
          <DetailElement
            name="Actual Fuel Level"
            value={notAvailableUnlessPresent(actualFuelLevelDisplay)}
          />
        </div>
        <div>
          <DetailElement
            name="Excess Gallons"
            value={notAvailableUnlessPresent(excessGallonsDisplay)}
            alert={is_suspicious ? DetailAlertType.Warn : DetailAlertType.Default}
          />
          <DetailElement
            name="Excess Transaction Amount"
            value={notAvailableUnlessPresent(gallonsMissingAdjusted > 0 ? excessAmount : null)}
            alert={is_suspicious ? DetailAlertType.Warn : DetailAlertType.Default}
          />
        </div>
      </div>
    </DetailSection>
  );
};

export default ChargeEventFuelLevelDetails;
