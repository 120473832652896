import { formatDateNoYear } from '@app/utils/formatDate';
import { convertNumbersWithCurrency } from '@atob-developers/shared/src/utils/formatters/CurrencyFormat';
import { ReactElement } from 'react';

const ZERO = '$0.00';

export type UpcomingPaymentProps = {
  paymentMethod?: string;
  scheduledPaymentAmount: string;
  upcomingPaymentDate: string;
  upcomingPaymentStartDate: string;
  upcomingPaymentEndDate: string;
  netTerms: string | null;
  accountBalance: string;
  showNextDayPaymentCustomers: boolean;
};

// Customers with Net0 or Net1 terms are called "NextDayPayment" customers
// In their case, we want to show different text on specific conditions so they don't get confused.
function showNextDayPaymentText(
  netTerms: string | null,
  scheduledPaymentAmount: string,
  accountBalance: string,
): boolean {
  const lessThanNet7 = Number(netTerms) < 7;
  const upcomingPaymentZero = scheduledPaymentAmount === ZERO;
  const accountBalanceGreaterThanZero = convertNumbersWithCurrency(accountBalance) > 0;
  return lessThanNet7 && upcomingPaymentZero && accountBalanceGreaterThanZero;
}

const ScheduledPaymentText = ({
  paymentMethod,
  upcomingPaymentDate,
  scheduledPaymentAmount,
}: {
  paymentMethod: string;
  scheduledPaymentAmount: string;
  upcomingPaymentDate: string;
}): ReactElement => {
  return (
    <p>
      <span className="font-semibold text-black">Upcoming Payment: {scheduledPaymentAmount}</span>{' '}
      will be auto-processed from {paymentMethod} on{' '}
      {formatDateNoYear(new Date(upcomingPaymentDate))}
    </p>
  );
};

const UpcomingPayment = ({
  paymentMethod,
  scheduledPaymentAmount,
  upcomingPaymentDate,
  upcomingPaymentStartDate,
  upcomingPaymentEndDate,
  netTerms,
  accountBalance,
  showNextDayPaymentCustomers,
}: UpcomingPaymentProps): ReactElement => {
  let upcomingPaymentDescription = (
    <p className="font-semibold text-black">Upcoming Payment: {scheduledPaymentAmount}</p>
  );
  if (
    showNextDayPaymentCustomers &&
    showNextDayPaymentText(netTerms, scheduledPaymentAmount, accountBalance)
  ) {
    upcomingPaymentDescription = (
      <>
        <p className="font-semibold text-black">Upcoming Payment: Pending</p>
        <span>Your next payment will be scheduled when your statement posts.</span>
      </>
    );
  } else if (paymentMethod && upcomingPaymentDate) {
    upcomingPaymentDescription = (
      <ScheduledPaymentText
        paymentMethod={paymentMethod}
        upcomingPaymentDate={upcomingPaymentDate}
        scheduledPaymentAmount={scheduledPaymentAmount}
      />
    );
  }

  return (
    <div className="flex items-start">
      <div className="flex flex-col space-y-2 text-sm text-gray-500">
        {upcomingPaymentDescription}
        <p>
          Billing Period:{' '}
          {upcomingPaymentStartDate && upcomingPaymentEndDate
            ? `${formatDateNoYear(new Date(upcomingPaymentStartDate))} -
                ${formatDateNoYear(new Date(upcomingPaymentEndDate))}
              `
            : ''}
        </p>
      </div>
    </div>
  );
};

export default UpcomingPayment;
