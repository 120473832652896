import { Button } from '@mui/material';

interface SearchMoreButtonFixedProps {
  onFetchMore: () => void;
}

const SearchMoreButtonFixed = ({ onFetchMore }: SearchMoreButtonFixedProps) => {
  return (
    <Button
      onClick={onFetchMore}
      variant="contained"
      disableElevation={false}
      classes={{ root: 'bg-white text-black shadow-md' }}
    >
      Search this area
    </Button>
  );
};

export default SearchMoreButtonFixed;
