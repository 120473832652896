import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export default function TransactionDetailRedirect(): null {
  const { transactionId } = useParams<{ transactionId: string }>();
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/transactions/' + transactionId, { replace: true });
  });
  return null;
}
