import ConnectedProviderCard from './ConnectedProviderCard';
import withProviderCard, { ProviderCardProps } from './withProviderCard';

const ProviderCard = (props: ProviderCardProps) => {
  const DisconnectedCard = withProviderCard(null);
  const ConnectedCard = withProviderCard(ConnectedProviderCard);

  if (props.connected) {
    return <ConnectedCard {...props} buttonColor="secondary" buttonText="Disconnect" />;
  }

  return <DisconnectedCard {...props} buttonColor="primary" buttonText="Connect" />;
};

export default ProviderCard;
