import useThemeConfiguration from '@app/app/useThemeConfiguration';
import {
  ActivationRetentionPromoCopyData,
  useActivationRetentionPromoCopyQuery,
} from '@app/hooks/query/useActivationRetentionPromoCopyQuery';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import classNames from 'classnames';
import { ReactElement } from 'react';
import useLocalStorage from '../popups/components/useLocalStorage';

export default function PrepaidActivationPromotionBanner(): ReactElement | null {
  const { data, error, isLoading } = useActivationRetentionPromoCopyQuery();
  const [dismissed, setDismissed] = useLocalStorage('prepaid-activation-promotion-banner', false);
  const { hideDismissableBanners } = useThemeConfiguration();

  if (dismissed || isLoading || error || hideDismissableBanners) {
    return null;
  }

  const { tagline, body } = data?.data as ActivationRetentionPromoCopyData;

  return (
    <>
      <div>
        <div
          className={classNames(
            'bg-ds-cool-gray-900',
            'relative flex w-full items-center justify-between overflow-hidden bg-no-repeat',
          )}
        >
          <div className="flex flex-row items-center justify-between gap-x-2 px-[32px] py-[22px] text-base text-gray-100 md:w-full">
            <div className="space-y-2">
              <div>
                <b className="font-bold">Special Offer: </b>
                {tagline}
              </div>
              <div>{body}</div>
            </div>
            <IconButton color="primary" onClick={() => setDismissed(true)}>
              <FontAwesomeIcon icon={faXmark} />
            </IconButton>
          </div>
        </div>
      </div>
    </>
  );
}
