import PremiumLogo from '@app/assets/images/svg/atob-premium-logo-dark.svg';
import { IconLookup } from '@fortawesome/fontawesome-svg-core';
import {
  faShieldKeyhole,
  faSlidersUp,
  faRabbitRunning,
  faHandsHoldingHeart,
  IconDefinition,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, MutableRefObject, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const FeatureLink = ({
  icon,
  text,
  navigateToSection,
}: {
  icon: IconDefinition;
  text: string;
  navigateToSection: () => void;
}) => {
  return (
    <button
      className="hover:bg-ds-lp-bold flex max-w-[125px] flex-col items-center justify-center rounded-lg p-[12px] text-xs"
      onClick={navigateToSection}
    >
      <FontAwesomeIcon
        icon={icon as IconLookup}
        size="1x"
        fill="white"
        className="mb-[13px] h-[20px] w-[20px] cursor-pointer"
      />
      <p>{text}</p>
    </button>
  );
};

const FeaturesConfig = [
  {
    icon: faShieldKeyhole,
    text: 'Card Misuse Protection',
    getSection: (ref: MutableRefObject<HTMLDivElement>) => (
      <div
        ref={ref}
        className="mb-[50px] flex max-w-[950px] flex-col flex-col-reverse md:h-[463px] md:flex-row"
      >
        <div className="flex flex-1 flex-col items-center justify-center px-[20px] text-left">
          <p className="mb-[15px] text-3xl font-bold">Advanced protection from card misuse</p>
          <p className="text-ds-cool-gray-400 text-lg">
            Stay vigilant with Real-time Suspicious Activity Notifications and go beyond PINs with
            Card Unlock. AtoB Premium helps make fuel theft a thing of the past.
          </p>
        </div>
        <div className="bg-feature-2 mb-[40px] min-h-[463px] flex-1 bg-contain bg-center bg-no-repeat md:mt-0 md:w-[50%]" />
      </div>
    ),
  },
  {
    icon: faSlidersUp,
    text: 'Advanced Spend Controls',
    getSection: (ref: MutableRefObject<HTMLDivElement>) => (
      <div
        ref={ref}
        className="bg-ds-lp-bold mb-[50px] flex w-full flex-col-reverse items-center px-[20px] py-[110px] md:flex-col"
      >
        <div className="max-w-[750px] md:mb-[50px] md:text-center">
          <p className="mb-[15px] text-3xl font-bold">
            Total control with advanced card management
          </p>
          <p className="text-ds-cool-gray-400 text-lg">
            Whitelist merchants, set daily and weekly spend limits, and limit purchases by category.
            AtoB Premium puts fleet managers in control of where, when, and how cards are used.
          </p>
        </div>
        <div className="bg-feature-3-mobile md:bg-feature-3 h-[430px] w-full bg-contain bg-center bg-no-repeat md:h-[290px]" />
      </div>
    ),
  },
  {
    icon: faRabbitRunning,
    text: 'Simplified IFTA & Accounting',
    getSection: (ref: MutableRefObject<HTMLDivElement>) => (
      <div ref={ref} className="mb-[50px] flex max-w-[950px] flex-col md:h-[400px] md:flex-row">
        <div className="bg-feature-4 ml-[50px] mt-[40px] h-[275px] bg-contain bg-center bg-no-repeat md:ml-0 md:mt-0 md:h-full md:w-[50%] md:flex-1" />
        <div className="flex flex-1 flex-col justify-center px-[20px] text-left">
          <p className="mb-[15px] text-3xl font-bold">Simplify administrative tasks</p>
          <p className="text-ds-cool-gray-400 text-lg">
            Spend less time in Excel and easily track your spending. AtoB Premium makes it easy to
            track usage, prep IFTA reporting, and export files to Quickbooks &amp; other accounting
            softwares.
          </p>
        </div>
      </div>
    ),
  },
  {
    icon: faHandsHoldingHeart,
    text: 'Priority Support',
    getSection: (ref: MutableRefObject<HTMLDivElement>) => (
      <div
        ref={ref}
        className="mb-[50px] flex max-w-[950px] flex-col flex-col-reverse md:h-[413px] md:flex-row"
      >
        <div className="bg-ds-lp-bold flex flex-1 flex-col items-center justify-center px-[20px] py-[50px] text-center">
          <p className="mb-[15px] text-3xl font-bold">Upgrade to priority support</p>
          <p className="text-ds-cool-gray-400 text-lg">
            Make sure you always have real-time resolution to your issues with Priority Support.
            AtoB's 24/7 customer support is even better with Premium.
          </p>
        </div>
        <div className="bg-feature-5 min-h-[413px] flex-1 bg-cover bg-center bg-no-repeat md:w-[50%]" />
      </div>
    ),
  },
];

function GetPremiumButton({ onClick }: { onClick: () => void }): ReactElement {
  return (
    <button
      className="bg-ds-bright-green text-dark-blue flex cursor-pointer items-center justify-center rounded-full px-4 py-2 text-sm font-medium"
      onClick={onClick}
    >
      Get Premium
    </button>
  );
}

export default function PremiumFeatures(): ReactElement {
  const navigate = useNavigate();
  const onNavigateToComparison = () => navigate('/premium/compare');
  const onNavigateToPremium = () => navigate('/settings/plans/atob_plus_premium');
  const ref1 = useRef<HTMLDivElement | null>(null);
  const ref2 = useRef<HTMLDivElement | null>(null);
  const ref3 = useRef<HTMLDivElement | null>(null);
  const ref4 = useRef<HTMLDivElement | null>(null);
  const refs = [ref1, ref2, ref3, ref4];

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const scrollableSections: {
    ref: MutableRefObject<HTMLDivElement>;
    scrollIntoView: () => void;
  }[] = FeaturesConfig.map((_, i) => {
    return {
      ref: refs[i],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      scrollIntoView: () => refs[i].current.scrollIntoView({ block: 'center' }),
    };
  });

  // scroll to top of page
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="bg-ds-lp-base text-white">
      <div className="bg-lp-header-texture bg-ds-lp-base relative flex flex-col items-center bg-gradient-to-b from-transparent to-current bg-contain bg-repeat-x text-center">
        <img src={PremiumLogo} alt="AtoB Premium Logo" className="mb-[35px] mt-[100px] w-[220px]" />
        <p className="mb-[35px] text-5xl font-bold">Premium features for a premium fleet.</p>
        <GetPremiumButton onClick={onNavigateToPremium} />
        <p className="text-ds-cool-gray-400 mb-[25px] mt-[15px] text-sm">
          $6 per month, per active card
        </p>
        <a className="mb-[50px] underline" onClick={onNavigateToComparison}>
          Compare Plans
        </a>
      </div>

      <div className="border-ds-cool-gray-600 mx-auto my-[50px] flex max-w-[950px] flex-wrap justify-center border-y-2 py-[15px] md:gap-5">
        {FeaturesConfig.map((featureConfig, i) => {
          return (
            <FeatureLink
              key={featureConfig.text}
              icon={featureConfig.icon}
              text={featureConfig.text}
              navigateToSection={scrollableSections[i].scrollIntoView}
            />
          );
        })}
      </div>

      <div className="flex flex-col items-center gap-5">
        {FeaturesConfig.map((featureConfig, i) =>
          featureConfig.getSection(scrollableSections[i].ref),
        )}
      </div>

      <div className="relative flex flex-col items-center py-[100px] text-center">
        <p className="mb-[35px] text-5xl font-bold">Get Started with Premium.</p>
        <GetPremiumButton onClick={onNavigateToPremium} />
      </div>
    </div>
  );
}
