import { CustomerData } from '@app/@types/customer.types';
import { UpcomingPayment as UpcomingPaymentType } from '@app/@types/fuel-card-account.types';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import { useExtendMobileHeaderContextMenu } from '@app/contexts/MobileNavHeaderMenuContextComponent';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import { Button } from '@mui/material';
import { ReactElement, useMemo, useState } from 'react';
import PaymentModalWithEstimates from '../../components/PaymentModal/PaymentModalWithEstimates/PaymentModalWithEstimates';
import UpcomingPayment from './UpcomingPayment';

type BalanceCardInterface = {
  upcomingPayment: UpcomingPaymentType | undefined;
  hasWallet: boolean;
  paymentMethod?: string;
  scheduledPaymentAmount: string;
  upcomingPaymentDate: string;
  upcomingPaymentStartDate: string;
  upcomingPaymentEndDate: string;
  netTerms: string | null;
  accountBalance: string;
  customer: CustomerData;
};

const BalanceCard = ({
  scheduledPaymentAmount,
  paymentMethod,
  upcomingPaymentDate,
  upcomingPaymentStartDate,
  upcomingPaymentEndDate,
  upcomingPayment,
  accountBalance,
  hasWallet,
  netTerms,
  customer,
}: BalanceCardInterface): ReactElement => {
  const [paymentModalActive, setPaymentModalActive] = useState(false);
  const [showNextDayPaymentCustomers] = useFeatureFlags('next_day_payment_customers_enabled');
  const [PAYMENTS_EXPLAINABILITY] = useFeatureFlags('payments_explainability');

  const menuItem = useMemo(
    () => ({
      value: 'Pay Now',
      onClick: () => setPaymentModalActive(true),
      disabled: () => false,
      order: 1,
    }),
    [],
  );

  useExtendMobileHeaderContextMenu(menuItem);

  return (
    <>
      <PaymentModalWithEstimates
        isModalActive={paymentModalActive}
        customer={customer}
        upcomingPayment={upcomingPayment}
        walletAvailable={hasWallet}
        closeModal={() => setPaymentModalActive(false)}
      />
      <RoundedCard className="py-4">
        <div className="flex flex-col justify-between border-b border-gray-100 px-4 md:flex-row md:items-center md:gap-2 md:px-6 md:pb-4">
          <UpcomingPayment
            scheduledPaymentAmount={scheduledPaymentAmount}
            paymentMethod={paymentMethod}
            upcomingPaymentDate={upcomingPaymentDate}
            upcomingPaymentStartDate={upcomingPaymentStartDate}
            upcomingPaymentEndDate={upcomingPaymentEndDate}
            netTerms={netTerms}
            accountBalance={accountBalance}
            showNextDayPaymentCustomers={showNextDayPaymentCustomers}
          />
          <div className="my-6 h-fit w-full shrink-0 sm:w-fit md:my-0">
            <Button
              className="w-full sm:w-auto"
              size="small"
              onClick={() => setPaymentModalActive(true)}
              data-testid={
                PAYMENTS_EXPLAINABILITY ? 'p-expl-pay-now-button' : 'baseline-pay-now-button'
              }
            >
              Pay Now
            </Button>
          </div>
        </div>

        <div className="mt-4 px-4 text-sm font-semibold md:px-6">
          <p>Account Balance: {accountBalance}</p>
        </div>
      </RoundedCard>
    </>
  );
};

export default BalanceCard;
