import { FlattenedPayrollData, PayrollDriverState } from '@app/@types/payroll.types';
import { Spacer } from '@app/components/elements';
import useWindowWidth from '@app/hooks/useWindowWidth';
import Constants from '@app/utils/constants';
import UnderlinedTabs from '@atob-developers/shared/src/components/UnderlinedTabs';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleUser, faTruck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { capitalize } from 'lodash-es';
import React, { ReactElement, useState } from 'react';
import AddDriver from '../AddDriver';
import PayoutAccountBadge from './PayoutAccountBadge';
import StatusBadge from './StatusBadge';
import TableRenderer from './TableRenderer';

type DriverColumn = {
  key: string;
  title: string;
};

const DRIVER_COLUMNS = [
  {
    key: 'driver',
    title: 'Driver name',
  },
  {
    key: 'tax_classification',
    title: '1099/W2',
  },
  {
    key: 'debit_card',
    title: 'Debit Card',
  },
  {
    key: 'support_instant',
    title: 'Instant Payout',
  },
  {
    key: 'status',
    title: 'Status',
  },
];

type DriverData = FlattenedPayrollData & { key: string };

const getDebitCardText = (data: DriverData): string => {
  const { tax_classification, payout_account } = data;
  const isW2Employee = tax_classification === Constants.PayrollEnums.W2;
  const debit_card = payout_account?.payout_detail
    ? `${payout_account?.payout_detail.brand} .... ${payout_account?.payout_detail.last_four}`
    : '';
  const debitCardText = isW2Employee ? 'Not available' : capitalize(debit_card) || 'No card';

  return debitCardText;
};

const MobileRow = ({ data }: { data: DriverData }) => {
  const { name, tax_classification, status, payout_account, archived_at } = data;

  const isEnrolled = status === Constants.PayrollEnums.ENROLLED;
  const renderTaxClassificationInGrey = !isEnrolled && tax_classification !== null;

  const debitCardText = getDebitCardText(data);

  return (
    <div>
      <div className="mb-3 flex flex-wrap items-center ">
        <FontAwesomeIcon className="text-grey2" icon={faCircleUser as IconProp} />
        <div className="text-black0 pl-2 pr-3 text-sm font-medium">{name}</div>
      </div>
      <div className="mb-2 flex items-center justify-between">
        <div>
          <span className="text-grey7 mr-1 text-sm">Classification</span>
          <span
            className={classNames('text-sm font-[500]', {
              'text-black0': !renderTaxClassificationInGrey,
              'text-grey7': renderTaxClassificationInGrey,
            })}
          >
            {tax_classification ?? '-'}
          </span>
        </div>
      </div>
      <div className="mb-2 flex items-center justify-between">
        <div>
          <span className="text-grey7 mr-1 text-sm">Debit Card</span>
          <span className={classNames('text-black0 text-sm font-[500]')}>{debitCardText}</span>
        </div>
      </div>

      <div className="mb-2 flex items-center justify-between">
        <div>
          <span className="text-grey7 mr-1 text-sm">Instant Payout</span>
          <span className={classNames('text-black0 text-sm font-[500]')}>
            <PayoutAccountBadge payoutAccount={payout_account} />
          </span>
        </div>
      </div>

      <div>
        <StatusBadge
          status={archived_at !== null ? 'archived' : status}
          taxClassification={tax_classification}
        />
      </div>
    </div>
  );
};

const DesktopRow = ({ data }: { data: DriverData }) => {
  const { name, tax_classification, status, payout_account, archived_at } = data;
  const debitCardText = getDebitCardText(data);

  return (
    <>
      <div className="xs:basis-3/12 xs:mb-0 mb-4 flex flex-wrap items-center ">
        <FontAwesomeIcon className="text-grey2" icon={faCircleUser as IconProp} />
        <div className="text-black0 pl-2 pr-3 text-sm font-medium">{name}</div>
      </div>
      <div className="text-black0 xs:items-center xs:basis-3/12 flex flex-wrap items-start text-sm font-medium">
        {tax_classification ? capitalize(tax_classification) : 'Unselected'}
      </div>
      <div className="text-black0 xs:items-center xs:basis-3/12 flex flex-wrap items-start text-sm font-medium">
        {debitCardText}
      </div>
      <div className="text-black0 xs:items-center xs:basis-3/12 flex flex-wrap items-start text-sm font-medium">
        <PayoutAccountBadge payoutAccount={payout_account} />
      </div>
      <div className="text-black0 xs:items-center xs:basis-3/12 xs:mb-0 mb-2 flex flex-wrap items-start text-sm font-medium">
        <StatusBadge
          status={archived_at !== null ? 'archived' : status}
          taxClassification={tax_classification}
        />
      </div>
    </>
  );
};

const createRowsUI = (isMobile: boolean) =>
  function DriverUIRow(data: DriverData) {
    return (
      <React.Fragment key={data.id}>
        {isMobile ? <MobileRow data={data} /> : <DesktopRow data={data} />}
      </React.Fragment>
    );
  };

const createColumnsUI = (isMobile: boolean) =>
  DRIVER_COLUMNS.map((column: DriverColumn): ReactElement | null => {
    if (isMobile) {
      return null;
    }

    return (
      <div
        className={'text-black1 flex basis-3/12 flex-wrap justify-between text-sm font-medium'}
        key={column.key}
      >
        {column.title}
      </div>
    );
  });

const PayrollNoDrivers = (): ReactElement => (
  <div className="flex h-48 w-full items-center justify-center">
    <div className="flex flex-col items-center">
      <div>
        <FontAwesomeIcon icon={faTruck} size="3x" className="text-grey1" />
      </div>
      <div className="text-grey4 mb-4 text-[24px] font-[500]">
        No drivers have been added to payroll
      </div>

      <AddDriver showTextOnMobile />
    </div>
  </div>
);

type DriverTab = {
  name: 'Active' | 'Archived';
  tab: 'active' | 'archived';
};

const DRIVER_TABS: DriverTab[] = [
  {
    name: 'Active',
    tab: 'active',
  },
  {
    name: 'Archived',
    tab: 'archived',
  },
];

const PayrollDriversTable = ({
  driverStateIds,
  driverState,
  hasDrivers,
  setSelectedDriverId,
}: {
  driverStateIds: string[];
  driverState: PayrollDriverState;
  hasDrivers: boolean;
  setSelectedDriverId: (id: string) => void;
}): ReactElement => {
  const { isMobile } = useWindowWidth();
  const [currentTab, setCurrentTab] = useState(DRIVER_TABS[0].tab);
  const driverData: DriverData[] = driverStateIds.map((id) => ({ ...driverState[id], key: id }));
  const activeDriverData = driverData.filter((driver) => !driver.archived_at);
  const hasActiveDrivers = activeDriverData.length > 0;
  const archivedDriverData = driverData.filter((driver) => driver.archived_at);
  const hasArchivedDrivers = archivedDriverData.length > 0;
  const showArchived =
    (currentTab === 'archived' && hasArchivedDrivers) || (!hasActiveDrivers && hasArchivedDrivers);

  if (!hasDrivers) {
    return <PayrollNoDrivers />;
  }

  return (
    <>
      {archivedDriverData.length > 0 ? (
        <>
          <Spacer />
          <div className="relative">
            <UnderlinedTabs
              selectedTab={currentTab}
              handleTabChange={(tab) => setCurrentTab(tab)}
              tabs={DRIVER_TABS}
            />
            <div className="absolute right-0 top-2 flex items-center">
              {isMobile && <AddDriver />}
            </div>
          </div>
        </>
      ) : (
        <div className="mx-3 mt-10 flex items-center justify-end">{isMobile && <AddDriver />}</div>
      )}
      {!isMobile && <Spacer />}
      <TableRenderer
        data={showArchived ? archivedDriverData : activeDriverData}
        columns={createColumnsUI(isMobile)}
        rowToUIMapper={createRowsUI(isMobile)}
        rowsPerPage={50}
        handleRowClick={(_, driverId) => {
          setSelectedDriverId(driverId);
        }}
      />
    </>
  );
};

export default PayrollDriversTable;
