import { Entity, PaginatedEndpointResponse } from '@app/@types/api.types';
import { DriverData } from '@app/@types/driver.types';
import { PayrollDriverState } from '@app/@types/payroll.types';
import useDisplayUntilConfirmed, {
  createExpirationDate,
} from '@app/hooks/useDisplayUntilConfirmed';
import { mapDriverDataToDriverState } from '@app/pages/Payroll/payroll-context';
import axios from 'axios';
import { deserialize } from 'deserialize-json-api';
import { useEffect, useState } from 'react';

type ExternalDataOptions = {
  dataHandledOutsideOfHook: boolean;
  data: PayrollDriverState;
  dataLoaded: boolean;
};

export const usePayrollUpsellMarketing = (
  key: string,
  externalDataOptions?: ExternalDataOptions,
): [boolean, () => void] => {
  const {
    data = {},
    dataHandledOutsideOfHook = false,
    dataLoaded = false,
  } = externalDataOptions || {};
  const [drivers, setDrivers] = useState<PayrollDriverState>(dataHandledOutsideOfHook ? data : {});
  const [loadedDrivers, setLoadedDrivers] = useState(dataLoaded);

  const [checkIfConfirmed, handleConfirm] = useDisplayUntilConfirmed(key, {
    expiresAt: createExpirationDate(1, 'day'),
  });

  const hasConfirmed = checkIfConfirmed();
  const doNotRequestDriversFromAPI = dataHandledOutsideOfHook || loadedDrivers || hasConfirmed;

  useEffect(() => {
    if (doNotRequestDriversFromAPI) {
      return;
    }

    async function getDriverDataForPayrollBanner() {
      const driverData = await axios.get<PaginatedEndpointResponse<Entity<DriverData>>>(
        '/drivers?archived=false&per=400&include=payroll.default_payout_account.payout_detail&all=true',
      );
      const { data } = deserialize(driverData.data);
      const payrollDrivers = mapDriverDataToDriverState(data);
      setLoadedDrivers(true);
      setDrivers(payrollDrivers);
    }

    getDriverDataForPayrollBanner();
  }, [doNotRequestDriversFromAPI]);

  if (hasConfirmed) {
    // do not show marketing
    return [false, handleConfirm];
  }

  if (!loadedDrivers) {
    return [false, () => {}];
  }

  const everyDriver = Object.keys(drivers).map((key) => drivers[key]);
  const fleetManagerHasNotAddedDrivers = everyDriver.length === 0;

  // Show marketing if there are no drivers
  if (fleetManagerHasNotAddedDrivers) {
    return [true, handleConfirm];
  }

  const doesNotHaveClassifiedDrivers = everyDriver.every(
    (driver) => driver.tax_classification === null,
  );

  // Show marketing if there are drivers but none are classified
  return [doesNotHaveClassifiedDrivers, handleConfirm];
};
