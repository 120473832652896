import { PaginatedEndpointResponse } from '@app/@types/api.types';
import { CustomerData, Treasury } from '@app/@types/customer.types';
import MobileNavHeader from '@app/components/Navigation/MobileNavHeader';
import PrepaidOnboardingFlow from '@app/components/Prepaid/PrepaidOnboardingFlow';
import { ErrorNotification } from '@app/components/layout';
import Skeleton from '@app/components/layout/Skeleton';
import { PageContentWrapper } from '@app/components/wrappers/PageContentWrapper';
import { DEFAULT_PAGE_SIZE } from '@app/hooks/paging/types';
import { useSingleUrlState } from '@app/hooks/useUrlState';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import axios from 'axios';
import { deserialize } from 'deserialize-json-api';
import { ReactElement, useEffect, useState } from 'react';
import useSWR from 'swr';
import Onboarding from './Onboarding';
import WalletOverview from './WalletOverview';
import WalletTransactions from './WalletTransactions';

export const ZERO_BALANCE = '$0.00';

function shouldOnboard(customerTreasuryDetails: Treasury): boolean {
  return (
    customerTreasuryDetails?.status === 'not_enrolled' ||
    customerTreasuryDetails?.status === 'started'
  );
}

export type TransactionStatus = 'success' | 'failed' | 'pending' | 'canceled' | 'returned';

export type WalletTransaction = {
  amount: string;
  created_timestamp: number;
  status: TransactionStatus;
  receipt_url: string;
  description: string;
  direction: 'inbound' | 'outbound';
  source: string;
  destination: string;
  key: string;
  id: string;
  fee?: string;
};

export type WalletCredit = {
  amount: string;
  created_timestamp: number;
  status: TransactionStatus;
  key: string;
  id: string;
};

type FinancialAccountDetails = {
  accountNumber: string | null;
  routingNumber: string | null;
  balance: string | null;
  cashback: string | null;
};

const initialFinancialAccountDetails: FinancialAccountDetails = {
  accountNumber: null,
  routingNumber: null,
  balance: null,
  cashback: null,
};

export default function Overview({
  customerTreasuryDetails,
  customerCompanyName,
  isTrusted,
  customer,
}: {
  customerTreasuryDetails: Treasury;
  customerCompanyName: string;
  isTrusted: boolean;
  customer: CustomerData;
}): ReactElement {
  const [financialData, setFinancialData] = useState<FinancialAccountDetails>(
    initialFinancialAccountDetails,
  );
  const [credits, setCredits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);

  const [page, setPage] = useSingleUrlState<number>('page', 1);

  const {
    data,
    isLoading,
    error: transactionsError,
    mutate,
  } = useSWR<PaginatedEndpointResponse<WalletTransaction>>(
    {
      url: '/treasury/transactions',
      params: {
        per: DEFAULT_PAGE_SIZE,
        page,
        all: false,
      },
    },
    apiGetFetcher,
  );

  const fetchTransactions = () => {
    setPage(1);
    mutate();
  };

  const fetchCredits = async () => {
    const creditsResponse = await axios.get('/treasury/transactions/credits');
    const { data: creditsData } = deserialize(creditsResponse.data);
    setCredits(creditsData);
  };

  const fetchFinancialData = async () => {
    const financialAccountResponse = await axios.get('/treasury/financial_account', {
      params: { expand_account_number: true },
    });

    if (financialAccountResponse.data.data === null) {
      setFinancialData({
        accountNumber: null,
        routingNumber: null,
        balance: null,
        cashback: null,
      });
      return;
    }

    const { data: financialAccountData } = deserialize(financialAccountResponse.data);
    setFinancialData({
      accountNumber: financialAccountData.account_number,
      routingNumber: financialAccountData.routing_number,
      balance: financialAccountData.balance,
      cashback: financialAccountData.cashback,
    });
  };

  useEffect(() => {
    async function fetchTreasuryDetails() {
      setLoading(true);
      try {
        await Promise.all([fetchFinancialData(), fetchCredits()]);
      } catch (e) {
        setError(true);
      }

      setLoading(false);
    }

    fetchTreasuryDetails();
  }, []);

  if (shouldOnboard(customerTreasuryDetails)) {
    return (
      <>
        <MobileNavHeader title="Wallet" />
        <PageContentWrapper>
          <Onboarding customerTreasuryDetails={customerTreasuryDetails} />
        </PageContentWrapper>
      </>
    );
  }

  return (
    <>
      <MobileNavHeader title="Wallet" />
      <div className="m-4 md:mr-0"></div>
      <PrepaidOnboardingFlow />
      <PageContentWrapper>
        <h1 className="tw text-lg font-medium">Wallet Balance</h1>
        {error && (
          <ErrorNotification
            error={
              "We're having issues loading your Wallet details. Please try again; if the issue persists, please contact support."
            }
          />
        )}
        <WalletOverview
          {...financialData}
          loading={loading}
          onAddFunds={fetchTransactions}
          onWithdrawFunds={() => {
            fetchFinancialData();
            fetchTransactions();
          }}
          customerCompanyName={customerCompanyName}
          customerCompanyAddress={customer.company_address}
          status={customerTreasuryDetails?.status}
          isTrusted={isTrusted}
        />
        {!isLoading ? (
          <WalletTransactions
            transactionsPage={data?.data ?? []}
            transactionsError={transactionsError}
            metadata={data?.meta}
            goToPage={setPage}
            credits={credits}
          />
        ) : (
          <Skeleton />
        )}
      </PageContentWrapper>
    </>
  );
}
