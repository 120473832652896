import { createTheme } from '@mui/material';
import tw from 'twin.macro';
import defaultTheme from './defaultTheme';

export default createTheme({
  ...defaultTheme,
  shape: {
    ...tw`!rounded-none`,
  },
});
