import { getFetcher } from '@app/utils/data/fetchers';
import useSWR, { SWRResponse } from 'swr';

export type EmbeddedDashboardUrl = {
  url: string;
};

export const useEmbeddedDashboardUrl = (
  dashboard_id: string,
): SWRResponse<EmbeddedDashboardUrl, Error> => {
  const swrData = useSWR<EmbeddedDashboardUrl>(
    { url: `/metabase/dashboard_embedding/build_url`, params: { dashboard_id } },
    getFetcher,
  );

  return { ...swrData };
};
