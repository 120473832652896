import { CustomerData } from '@app/@types/customer.types';
import useCustomerOnboardingEvents, {
  CustomerOnboardingEventName,
} from '@app/hooks/query/useCustomerOnboardingEvents';
import useNoPaymentMethods from '@app/hooks/useNoPaymentMethods';
import { ReactElement, useState } from 'react';
import AuthenticateBankAccount from './AuthenticateBankAccount';
import BillingErrorBanner from './BillingErrorBanner';
import ConnectBankAccountBanner from './ConnectBankAccountBanner';
import DeactivatedBanner from './DeactivatedBanner';
import { ErrorBanner } from './Designs/ErrorBanner';
import OverdueBanner from './OverdueBanner';
import ReactivateCesOrUnlimitedBanner from './ReactivateCesOrUnlimitedBanner';
import Step1ConnectDebitCardBanner from './Step1ConnectDebitCardBanner';
import Step2ConnectBankAccountBanner from './Step2ConnectBankAccountBanner';

const BillingBannerElement = ({
  customer,
  isPrepaid,
  hasTreasury,
}: {
  customer: CustomerData;
  isPrepaid: boolean;
  hasTreasury: boolean;
}): ReactElement | null => {
  const onboardingEvents = useCustomerOnboardingEvents();
  const { noDebitCards, noBankAccounts, isLoading, refreshPaymentMethods } = useNoPaymentMethods(
    customer?.owner_email,
  );
  const [error, setError] = useState<string | null>();
  const [cesOrUnlimitedAccepted, setCesOrUnlimitedAccepted] = useState(false);

  if (isPrepaid || isLoading) {
    return null;
  }

  if (error) {
    return (
      <ErrorBanner>
        <div className="font-semibold">An error occurred</div>
        <div>{error}</div>
      </ErrorBanner>
    );
  }

  if (
    customer.is_overdue &&
    customer.cardholder_status &&
    customer.cardholder_status !== 'active'
  ) {
    return (
      <OverdueBanner
        customer={customer}
        ownerEmail={customer.owner_email}
        arrearsDue={customer.arrears_due}
        hasTreasury={hasTreasury}
      />
    );
  }

  const onlyDebitCardAllowed =
    !customer?.allowed_primary_payment_method_types?.includes('BankAccount');

  const showReactivateCesOrUnlimited =
    customer.cardholder_status != 'active' &&
    customer.should_get_ces_consent &&
    customer.ces_accepted_at == null &&
    !cesOrUnlimitedAccepted;

  if (onlyDebitCardAllowed) {
    if (noDebitCards && noBankAccounts) {
      return <Step1ConnectDebitCardBanner />;
    }

    if (noBankAccounts) {
      return (
        <Step2ConnectBankAccountBanner
          setError={setError}
          onSuccessCallback={() => {
            setError(null);
            refreshPaymentMethods();
          }}
        />
      );
    }

    // TODO: Remove this condition after preventing it to happen on payment-methods
    if (noDebitCards) {
      return <BillingErrorBanner />;
    }
  }

  if (
    noBankAccounts &&
    onboardingEvents.has(CustomerOnboardingEventName.PAYMENT_METHOD_CONNECTED)
  ) {
    return (
      <ConnectBankAccountBanner
        setError={setError}
        onSuccessCallback={() => {
          setError(null);
          refreshPaymentMethods();
        }}
      />
    );
  }

  if (customer.suspended_at) {
    return <DeactivatedBanner />;
  }

  if (showReactivateCesOrUnlimited) {
    return (
      <ReactivateCesOrUnlimitedBanner
        onSuccessCallback={() => {
          setCesOrUnlimitedAccepted(true);
        }}
      />
    );
  }

  return <AuthenticateBankAccount customerOwnerEmail={customer.owner_email} />;
};

export default BillingBannerElement;
