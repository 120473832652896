import { EndpointResponse } from '@app/@types/api.types';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import { faPartyHorn } from '@fortawesome/pro-regular-svg-icons';
import { ReactElement, useState } from 'react';
import useSWR from 'swr';
import SecurityDepositOfferModal, {
  SecurityDeposit,
} from '../Modals/SecurityDepositModals/SecurityDepositOfferModal';
import { NewFeatureBanner } from './Designs/NewFeatureBanner';

const SecurityDepositOfferBanner = (): ReactElement | null => {
  const { data: securityDeposit, isLoading: loading } = useSWR<EndpointResponse<SecurityDeposit>>(
    { url: `/security_deposits/offer` },
    apiGetFetcher,
  );

  const [modalOpen, setModalOpen] = useState(false);

  if (
    loading ||
    !securityDeposit?.data ||
    securityDeposit.data.status == 'deposit_paid' ||
    securityDeposit.data.status == 'deposit_paid_pending_hold'
  )
    return null;

  return (
    <>
      <SecurityDepositOfferModal
        securityDeposit={securityDeposit.data}
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      />

      <NewFeatureBanner
        onClick={() => setModalOpen(true)}
        actionText="Continue with Offer"
        bannerKey="security-deposit-offer-banner"
        variant="offer"
        nonDismissable={true}
        icon={faPartyHorn}
      >
        Congrats! You are qualified for a credit line by making a security deposit.
      </NewFeatureBanner>
    </>
  );
};

export default SecurityDepositOfferBanner;
