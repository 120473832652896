import { FetcherKey, apiPostHeadersOnly } from '@app/utils/data/fetchers';
import { AxiosError, AxiosResponseHeaders } from 'axios';
import useSWRMutation, { SWRMutationResponse } from 'swr/mutation';

export type CreateAndInviteDriverParam = {
  driver: { phone: string };
};

export function useSendAppInviteLinkText(): SWRMutationResponse<
  AxiosResponseHeaders,
  AxiosError,
  FetcherKey,
  CreateAndInviteDriverParam
> {
  return useSWRMutation<AxiosResponseHeaders, AxiosError, FetcherKey, CreateAndInviteDriverParam>(
    {
      url: '/drivers/send_admin_app_link',
    },
    apiPostHeadersOnly,
  );
}
