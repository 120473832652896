import { TagData } from '@app/@types/tag.types';

export type CustomerResourceType = TagData | 'Customer';

export type SpendRestrictionConfiguration = {
  daily_spend_limit?: number;
  weekly_spend_limit?: number;
  transaction_spend_limit?: number;
};

export type SpendRestriction = {
  configuration?: SpendRestrictionConfiguration;
  enabled: boolean;
  rule_type: SpendRestrictionRuleType;
  restricted_resource_id: string;
  restricted_resource_type: string;
  merchant_details?: { name: string; display_address: string };
};

export enum SpendRestrictionRuleType {
  ALLOW = 'allow',
  BLOCK = 'block',
}

export type RestrictionInfo = {
  afdOnly?: boolean;
  selectedCategoryIds: string[];
  merchantWhitelist: MerchantData[];
  merchantBlacklist: MerchantData[];
};

export type MerchantData = {
  id: string;
  name: string;
  display_address?: string;
};
