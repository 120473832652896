import { EndpointResponse } from '@app/@types/api.types';
import { CustomerData } from '@app/@types/customer.types';
import { FlexOfferResponse } from '@app/@types/promotion.types';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import useSWR, { SWRResponse } from 'swr';

export const useFlexOffersQuery = (
  customer: CustomerData,
): SWRResponse<EndpointResponse<FlexOfferResponse | null>, Error> => {
  return useSWR<EndpointResponse<FlexOfferResponse | null>>(
    {
      url: '/flex_offers',
      params: { customer_id: customer.id },
    },
    apiGetFetcher,
  );
};
