import { ReactElement } from 'react';

interface DividerProps {
  label?: string;
}

export default function Divider({ label }: DividerProps): ReactElement {
  return (
    <div className="relative my-4 flex">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="relative flex justify-center">
        {label && <span className="bg-white px-2 text-sm text-gray-500">{label} </span>}
      </div>
    </div>
  );
}
