import { ReactElement } from 'react';

export default function ReferralHowItWorksCard({
  isPrepaid,
}: {
  isPrepaid: boolean;
}): ReactElement {
  let target = null;
  let reward = null;
  if (isPrepaid) {
    // UL -> UL referral.
    target = 'purchased 500 gallons of fuel using our cards';
    reward = 'give you an extra 2c off per gallon as a promotional discount for the next 3 months';
  } else {
    // Flex -> UL referral.
    target = 'purchased 500 gallons of fuel using our cards';
    reward = 'apply a $50 credit on your next statement';
  }
  return (
    <div className="ml-4 mt-4 rounded">
      <div>
        <p className="text-lg font-bold">How it works</p>
        <div className="mt-2 text-base">
          <ol className="ml-5 list-decimal">
            <li className="mb-4">
              Share your referral link with another fleet owner using the buttons to the left.
            </li>
            {/* Referrals will sign up with AtoB and not through a partner so we mention AtoB explicitly */}
            {/* eslint-disable-next-line no-explicit-atob-mentions */}
            <li className="mb-4">They sign up on AtoB with your link and start using our cards.</li>
            <li>
              Once they&apos;ve {target}, we {reward}.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}
