import { TopUpTransaction } from '@app/components/PrepaidAccountOverview/RecentTopUps';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import { convertNumbersWithCurrency } from '@atob-developers/shared/src/utils/formatters/CurrencyFormat';
import useCustomer from '../useCustomer';

export default function useShippingDetails(recentTopups: TopUpTransaction[]): {
  cardsOrdered: boolean;
  cardsDelivered: boolean;
} {
  const [UNLIMITED_PRESHIPMENT_ACTIVATION_FEE] = useFeatureFlags(
    'unlimited_preshipment_activation_fee',
  );
  const customer = useCustomer();
  let sufficientTopups = false;

  if (recentTopups) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const requiredAmountToShip = customer.treasury.minimum_deposit_amount.cents;
    const totalTopup = recentTopups.reduce((acc, topup) => {
      // amount is in this form $1,000.00
      return acc + convertNumbersWithCurrency(topup.amount);
    }, 0);

    sufficientTopups = totalTopup >= requiredAmountToShip;
  }

  /**
   *
   * There is a delay between when a customer adds funds and when the cards are ordered, so we need a proxy
   * to determine whether the customer has successfuly ordered cards.
   *
   * For cardsOrdered to be true, the customer must:
   * 1. Be in the feature flag group, and
   * 2. Recent topups must be greater than their minimum deposit amount or the stripe cardholder status must be active
   *    Recent topups endpoint only queries for past 24 hours, so we can't rely on it as the sole determinant for if cards have been ordered.
   *    Stripe cardholder status will turn active after cards are ordered, typically 20 minutes (default queue SLA) after cumulative topup.
   *
   */
  const cardsOrdered =
    UNLIMITED_PRESHIPMENT_ACTIVATION_FEE && (sufficientTopups || customer.has_cards);
  const cardsDelivered = customer.any_cards_delivered;

  return { cardsOrdered: cardsOrdered, cardsDelivered: cardsDelivered };
}
