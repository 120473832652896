import { LogoutOptions, useAuth0, User } from '@auth0/auth0-react';
import axios from 'axios';
import { useEffect, useRef } from 'react';
import useOmniAuth from './useOmniAuth';

type AuthHooks = {
  logout: (options?: LogoutOptions) => void;
  getAccessTokenSilently: () => Promise<string>;
  user: User | undefined;
};

export default function useAuth(): AuthHooks {
  const { logout, getAccessTokenSilently, user } = useAuth0();
  const omniAuth = useOmniAuth();

  const hooks = omniAuth.provider
    ? {
        logout: omniAuth.logout,
        getAccessTokenSilently: async () => {
          return omniAuth.token;
        },
        user,
      }
    : { logout, getAccessTokenSilently, user };

  // Install common auth error handling.
  const axiosInterceptorRef = useRef(false);
  useEffect(() => {
    if (axiosInterceptorRef.current === false) {
      axiosInterceptorRef.current = true;
      axios.interceptors.response.use(
        function (response) {
          return response;
        },
        function (error) {
          if (axios.isAxiosError(error)) {
            const status = error.response?.status;
            const code = error.response?.data?.code;
            if (status === 401 && code === 'token_expired') {
              hooks.logout();
            }
          }

          return Promise.reject(error);
        },
      );
    }
  });

  return hooks;
}
