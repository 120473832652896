import { EndpointResponse, Entity, PaginatedEndpointResponse } from '@app/@types/api.types';
import { NotificationTypes } from '@app/@types/notification_setting.types';
import { TagData } from '@app/@types/tag.types';
import { UserData } from '@app/@types/users.types';
import { SidebarWrapper } from '@app/components/wrappers/SidebarWrapper';
import {
  getUpdateUserNotificationSettingsParams,
  useUpdateUserNotificationSettings,
  useUserNotificationSettings,
} from '@app/hooks/query/useNotificationSettings';
import { triggerResendPhoneVerification } from '@app/pages/Users/UsersUtil';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import { WithId } from '@atob-developers/shared/src/@types/form.types';
import DataItem, { DataItemType } from '@atob-developers/shared/src/components/DataItem';
import { SideBarBody, SideBarFooter } from '@atob-developers/shared/src/components/SideBar';
import { CustomTooltip } from '@atob-developers/shared/src/components/Tooltip';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { formatInput } from '@atob-developers/shared/src/utils/FormatUtils';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { Button, MenuItem, Select, TextField } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { ReactElement, useEffect, useRef, useState } from 'react';

import useSWR from 'swr';
import { NotificationsSection } from './NotificationsSection';

export interface UserSidebarProps {
  user: UserData;
  open: boolean;
  setOpen: (open: boolean) => void;
  rolesEntry: Record<string, WithId<Record<string, string>>>;
  fetchData: () => void;
  allowManageNotifications: boolean;
  workspaceAddUpdateUsers: boolean;
  workspaceRoles: boolean;
}

export const UserSidebar = ({
  user,
  open,
  setOpen,
  rolesEntry,
  fetchData,
  allowManageNotifications,
  workspaceAddUpdateUsers,
  workspaceRoles,
}: UserSidebarProps): ReactElement => {
  const { addToast } = useToasts();

  const [values, setValues] = useState<Record<string, string>>({
    role: user.role,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    name: user.name,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    phone: user.phone?.replace(/[() -]/g, ''),
  });
  const [sentVerificationLink, setSentVerificationLink] = useState(false);
  const {
    data: tags,
    isLoading: isTagsLoading,
    error: tagError,
  } = useSWR<PaginatedEndpointResponse<TagData>>({ url: '/tags?all=true' }, apiGetFetcher);
  const allResources = [
    {
      id: 'all',
      name: 'All tags',
    },
    ...((tags?.data ?? []).map((tag) => ({ id: tag.id.toString(), name: tag.name })) || []),
  ];

  const {
    data: userNotificationsData,
    error: userNotificationsError,
    isLoading,
  } = useUserNotificationSettings({ userId: user.id, shouldFetch: true });
  const [enabledNotifications, setEnabledNotifications] = useState<NotificationTypes[]>([]);
  const [selectedResources, setSelectedResources] = useState<string[]>([]);

  const userNotifications = userNotificationsData?.data;
  const showNotificationsOptions = values.role !== 'bookkeeper' && allowManageNotifications;

  const hasInitialSetRef = useRef(false);
  useEffect(() => {
    if (isLoading || hasInitialSetRef.current) return;
    hasInitialSetRef.current = true;
    const initialSelectedResources = Array.from(
      // Ensure we only have the unique values
      new Set(
        userNotifications
          ?.filter((n) => n.enabled)
          .map((n) => (n.resource_type === 'Customer' ? 'all' : n.resource_id.toString())),
      ),
    );

    const initialEnabledNotifications = Array.from(
      // Ensure we only have the unique values
      new Set(
        userNotifications?.filter((n) => n.enabled).map((n) => n.notification.notification_type),
      ),
    );
    setEnabledNotifications(initialEnabledNotifications);
    setSelectedResources(initialSelectedResources);
  }, [allowManageNotifications, isLoading, userNotifications]);
  const {
    updateUserNotificationSettings,
    updateSettingsInProgress: updateInProgress,
    error: updateError,
  } = useUpdateUserNotificationSettings();

  const roleItems: WithId<Record<string, string>>[] = Object.values(rolesEntry);
  const isNotificationsLoading = isLoading || updateInProgress;

  const update = (params: { user: Partial<UserData> }) => {
    axios
      .put<EndpointResponse<Entity<UserData>>>(`/users/${user.id}`, params)
      .then(() => {
        if (!showNotificationsOptions) {
          return;
        }

        const params = getUpdateUserNotificationSettingsParams(
          user,
          selectedResources,
          enabledNotifications,
          allResources,
        );
        updateUserNotificationSettings(params);
      })
      .then(() => {
        const phoneSuccessMsg =
          'To receive SMS notifications, the user must verify the phone number by clicking the SMS link sent to user’s phone.';
        const successMsg = `User profile has been updated. ${
          user.phone !== params.user.phone && !!params.user.phone ? phoneSuccessMsg : ''
        }`;
        addToast(successMsg, { appearance: 'success' });
        fetchData();
      })
      .catch(() => {
        addToast("There was an error saving this user's details. Please try again later", {
          appearance: 'error',
        });
      })
      .finally(() => setOpen(false));
  };

  const onSave = () => {
    const params = { user: values };
    update(params);
  };

  const onResendPhoneVerificationClick = (user: Partial<UserData>) => {
    setSentVerificationLink(true);
    triggerResendPhoneVerification(user)
      .then(() => {
        const successMsg =
          "Sent a verification link to user's phone number. It will start receiving messages when the user has clicked on the link.";
        addToast(successMsg, { appearance: 'success' });
      })
      .catch(() => {
        addToast(
          "There was an error resending verification text to user's phone number. Please try again later",
          {
            appearance: 'error',
          },
        );
      });
  };

  const toggleDisabled = () => {
    const params = { user: { disabled: (!user.disabled).toString() } };
    update(params);
  };

  return (
    <SidebarWrapper title="User" open={open} setOpen={(val: boolean) => setOpen(val)}>
      <SideBarBody>
        <div data-testid="user-sidebar-body" className="flex flex-col space-y-4 text-sm">
          <DataItem
            key="sidebar-item-0"
            item={{
              type: DataItemType.TEXT,
              label: 'Email',
              content: user.email,
              path: 'email',
            }}
          />
          <DataItem
            key="sidebar-item-1"
            item={{
              type: DataItemType.TEXT,
              label: 'Status',
              content: user.disabled ? 'Disabled' : 'Active',
              path: 'status',
            }}
          />
          <TextField
            fullWidth
            id="name"
            size="small"
            label="Name"
            value={values.name}
            onChange={(e) => setValues({ ...values, name: e.target.value })}
          />
          <div>
            <TextField
              fullWidth
              id="phone"
              label={
                <>
                  <span>Phone</span>
                  {!user.disabled && (!user.phone || !user.phone_verified_at) && (
                    <span className="ml-2">
                      <CustomTooltip icon={faExclamationCircle} iconClassName="text-red-500">
                        Entered phone number requires verification before it can start receiving
                        messages
                        {user.phone && !sentVerificationLink && (
                          <div className="mt-2">
                            <a
                              className="text-atob-green resend-phone-verification-link font-[700] underline"
                              onClick={() => {
                                onResendPhoneVerificationClick(user);
                              }}
                            >
                              Resend Verification Link
                            </a>
                          </div>
                        )}
                        {sentVerificationLink && (
                          <div className="mt-2">
                            <span className="text-atob-green">Verification link sent!</span>
                          </div>
                        )}
                      </CustomTooltip>
                    </span>
                  )}
                </>
              }
              size="small"
              value={formatInput(values.phone, 'tel')}
              onChange={(e) => setValues({ ...values, phone: e.target.value })}
            />
          </div>
          {workspaceRoles && (
            <div>
              <label>Role</label>
              <Select
                value={values.role}
                className="my-2"
                sx={{ width: '100%' }}
                onChange={(e) => {
                  setValues((prevValues) => ({ ...prevValues, role: e.target.value }));
                }}
                renderValue={(value) => {
                  const item = roleItems.find((item) => item.id === value);
                  if (!item) {
                    return 'Role';
                  }
                  return (
                    <div className="flex w-full items-center justify-between text-gray-900">
                      {item.name}
                    </div>
                  );
                }}
                disabled={false}
                displayEmpty={true}
              >
                {roleItems.map((item) => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                    classes={{ root: 'flex-col items-start' }}
                  >
                    <div className="flex cursor-pointer font-medium">{item.name}</div>
                    <div className="flex cursor-pointer text-xs text-gray-500">
                      {item.description}
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
          {showNotificationsOptions && (
            <NotificationsSection
              isLoading={isNotificationsLoading || isTagsLoading}
              allResources={allResources}
              userHasPhone={!!values.phone}
              error={userNotificationsError || updateError || (tagError as AxiosError)}
              selectedResources={selectedResources}
              enabledNotifications={enabledNotifications}
              setSelectedResources={setSelectedResources}
              setEnabledNotifications={setEnabledNotifications}
            />
          )}
        </div>
      </SideBarBody>
      <SideBarFooter>
        <div className="mb-10">
          {user.disabled && workspaceAddUpdateUsers && (
            <Button
              size="small"
              onClick={() => {
                toggleDisabled();
              }}
            >
              Enable Account
            </Button>
          )}
          {!user.disabled && (
            <Button
              color="alert"
              size="small"
              onClick={() => {
                toggleDisabled();
              }}
            >
              Disable Account
            </Button>
          )}
        </div>
        <div className="mb-10">
          <Button
            size="small"
            onClick={() => {
              onSave();
            }}
          >
            Save Changes
          </Button>
        </div>
      </SideBarFooter>
    </SidebarWrapper>
  );
};
