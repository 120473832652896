import { DriversCounts } from '@app/@types/driver.types';
import useThemeConfiguration from '@app/app/useThemeConfiguration';
import { useCreateDriver } from '@app/hooks/query/useCreateDriver';
import { getFetcher } from '@app/utils/data/fetchers';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, TextField } from '@mui/material';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import useSWR from 'swr';

type OnboardingState = 'initial' | 'invite_sent';

function LoadpayDriverAppOnboarding() {
  const [currentState, setCurrentState] = useState<OnboardingState>('initial');
  const { showDriverAppOnboarding } = useThemeConfiguration();

  const { data: driversCounts } = useSWR<DriversCounts>({ url: '/drivers/counts' }, getFetcher);

  if (!showDriverAppOnboarding || !driversCounts || driversCounts.total > 0) {
    return null;
  }

  let content = null;
  switch (currentState) {
    case 'initial':
      content = <InviteForm setCurrentState={setCurrentState} />;
      break;
    case 'invite_sent':
      content = (
        <div className="flex flex-col items-center justify-center gap-2 py-4">
          <div>
            <FontAwesomeIcon icon={faCheckCircle} size="2x" />
          </div>
          <div className="text-center text-base font-bold font-normal">
            Invite sent! Check your phone for an app download link.
          </div>
        </div>
      );
      break;
  }

  return <div className="bg-primary-600 rounded-lg p-4 text-white">{content}</div>;
}

const InviteForm = ({
  setCurrentState,
}: {
  setCurrentState: (state: OnboardingState) => void;
}): ReactElement => {
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState('');
  const { data, trigger: sendAppInviteLinkText, isMutating, reset } = useCreateDriver();

  const { addToast } = useToasts();

  const cleanNumber = (phoneNumber: string) => {
    return phoneNumber.replace(/\D/g, '');
  };

  const submitPhoneNumber = async () => {
    reset();
    sendAppInviteLinkText({
      driver: { phone: phoneNumber, first_name: 'Admin', last_name: 'Driver' },
    })
      .then(() => {
        setCurrentState('invite_sent');
      })
      .catch(() => {
        addToast('We were unable to send the invite. Please try again.', {
          appearance: 'error',
        });
      });
  };

  return !data ? (
    <div className="flex flex-col justify-between gap-4 lg:flex-row">
      <div className="flex flex-row items-center gap-4">
        <div className="flex-0">
          <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-white">
            <img src={t('logo_url', 'AtoB')} alt={`${t('program_name', 'AtoB')} driver app icon`} />
          </div>
        </div>
        <div className="flex-1">
          <p className="text-base font-normal">
            Use the <strong>{t('program_name', 'AtoB')} driver app</strong> for the steepest
            discounts. Get an invite to the app:
          </p>
        </div>
      </div>
      <div className="flex w-full flex-col  justify-center gap-2 md:w-auto md:flex-row">
        <div className="flex items-center">
          <InputMask
            disabled={isMutating}
            mask="(999) 999-9999"
            maskChar=""
            value={phoneNumber}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              reset();

              const phoneNumber = cleanNumber(e.target.value);
              setPhoneNumber(phoneNumber);
            }}
          >
            <TextField value={phoneNumber} placeholder="Phone number" />
          </InputMask>
        </div>
        <Button onClick={submitPhoneNumber} size="small" color="secondary">
          {data != null ? 'Done' : isMutating ? 'Sending link...' : 'Confirm'}
        </Button>
      </div>
    </div>
  ) : (
    <p className="text-base font-normal">
      Check your phone for an SMS with a link to download the AtoB app.
    </p>
  );
};

export default LoadpayDriverAppOnboarding;
