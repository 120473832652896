import { AssociationReason } from '@app/@types/charge_events.types';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { DetailElementValue, DetailStatus } from './DetailSection';

dayjs.extend(relativeTime);

export const hiddenUnlessPresent = (value?: DetailElementValue | null): DetailElementValue => {
  return value || DetailStatus.Hidden;
};

export const notAvailableUnlessPresent = (
  value?: DetailElementValue | null,
): DetailElementValue => {
  return value || DetailStatus.NotAvailable;
};

export const relativeTimestamp = (timestamp: string, baseTimestamp: string): string => {
  const date = new Date(timestamp);
  const baseDate = new Date(baseTimestamp);

  const formattedRelativeTimeDifference = dayjs(baseDate).to(date, true);

  return `${formattedRelativeTimeDifference} before transaction`;
};

export const formatLocation = ({
  latitude,
  longitude,
}: {
  latitude: number;
  longitude: number;
}): string => `${latitude.toFixed(5)}, ${longitude.toFixed(5)}`;

export const driverAssociationReason = (reason: AssociationReason): string => {
  switch (reason) {
    case 'card_unlocked':
      return "Driver's phone number";
    case 'card_assigned':
      return 'Card assignment';
    case 'telematics_vehicle_assigned':
      return 'Synced from telematics';
    case 'prompt_matched':
      return 'Unlock ID';
    default:
      return 'Unknown';
  }
};

export const vehicleAssociationReason = (reason: AssociationReason): string => {
  switch (reason) {
    case 'card_assigned':
      return 'Card assignment';
    case 'prompt_matched':
      return 'Vehicle ID';
    case 'telematics_driver_assigned':
      return 'Synced from telematics';
    default:
      return 'Unknown';
  }
};
