import Status from '@app/components/elements/Status';
import { ErrorNotification } from '@app/components/layout';
import Constants from '@app/utils/constants';
import ApiEndpoints from '@app/utils/data/apiEndpoints';
import { getEnvironment } from '@app/utils/environment';
import { guardError } from '@app/utils/error/guards';
import axios from 'axios';
import { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type QuickbooksCallbackResponse = {
  data: { status: string };
};

const disconnectFromQuickbooks = async (
  setIsConnected: (connected: boolean) => void,
  setError: (error: Error) => void,
  setLoading: (loading: boolean) => void,
) => {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    setError(null);
    setLoading(true);

    const response: QuickbooksCallbackResponse = await axios.post(
      ApiEndpoints.ACCOUNTING_ENDPOINTS.REMOVE_ENDPOINT,
    );
    const { data } = response;

    if (data.status === 'success') {
      setIsConnected(false);
    } else {
      setError(new Error('We were unable to disconnect your account. Please try again.'));
    }
    setLoading(false);
  } catch (e: unknown) {
    setLoading(false);

    if (!guardError(e)) {
      return;
    }

    setError(e);
  }
};

const handleQuickbooksAuthCallback = async (
  urlParamEntries: { [k: string]: string },
  setConnected: Dispatch<SetStateAction<boolean>>,
  setError: (error: Error) => void,
) => {
  try {
    const response: QuickbooksCallbackResponse = await axios.post(
      ApiEndpoints.ACCOUNTING_ENDPOINTS.CALLBACK_ENDPOINT,
      {
        oauth: urlParamEntries,
      },
    );

    const { data } = response;
    const { status } = data;

    if (status === 'success') {
      setConnected(true);
    } else {
      setError(new Error('We were unable to connect your account. Please try again.'));
    }
  } catch (e: unknown) {
    if (!guardError(e)) {
      return;
    }

    setError(e);
  }
};

const ConnectForm = () => {
  const { VITE_API_URL } = getEnvironment();

  return (
    <form
      className=""
      action={VITE_API_URL + ApiEndpoints.ACCOUNTING_ENDPOINTS.OAUTH_ENDPOINT}
      method="post"
    >
      <button
        type="submit"
        data-testid="connect-to-quickbooks"
        aria-label="Connect to Quickbooks"
        className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
      >
        Connect to QuickBooks
      </button>
    </form>
  );
};

export default function Quickbooks(): ReactElement {
  const location = useLocation();
  const navigate = useNavigate();

  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const urlParamEntries = Object.fromEntries(new URLSearchParams(location.search));
  const isFromRedirect = urlParamEntries.code !== undefined;

  useEffect(() => {
    const loadQuickbooksStatus = async () => {
      setIsLoading(true);
      try {
        const response: QuickbooksCallbackResponse = await axios.get(
          ApiEndpoints.ACCOUNTING_ENDPOINTS.STATUS_ENDPOINT,
        );
        const { data } = response;
        const { status } = data;
        setIsConnected(status === Constants.QuickbooksEnums.CONNECTED);

        if (isFromRedirect) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          await handleQuickbooksAuthCallback(urlParamEntries, setIsConnected, setError);
          navigate('/accounting/quickbooks');
        }
        setIsLoading(false);
      } catch (e: unknown) {
        setIsLoading(false);
        if (!guardError(e)) {
          return;
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        setError(e.message);
      }
    };

    loadQuickbooksStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="m-6">
      <h1 className="text-xl font-bold">Accounting Integration</h1>
      {error && <ErrorNotification error={error} />}
      <div>
        <p className="text-sm text-gray-600">
          <Status isConnected={isConnected} isLoading={isLoading} />
        </p>
      </div>
      <div className="mb-4 mt-8 flex max-w-md justify-between">
        <img className="w-48" src="/quickbooks.svg" alt="Quickbooks" />

        {!isLoading &&
          (isConnected ? (
            <button
              type="submit"
              data-testid="disconnect-from-quickbooks"
              aria-label="Disconnect QuickBooks"
              className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              onClick={() => disconnectFromQuickbooks(setIsConnected, setError, setIsLoading)}
            >
              Disconnect QuickBooks
            </button>
          ) : (
            <ConnectForm />
          ))}
      </div>
    </div>
  );
}
