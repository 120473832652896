import QuickbooksLogo from '@app/assets/images/png/quickbooks-logo.png';
import AccountingPreview from '@app/assets/images/svg/accounting-preview.svg';
import AccountingExportButton from '@app/components/Modals/AccountingModals/ExportAccountingDocumentsButton';
import Divider from '@atob-developers/shared/src/components/Divider';
import Modal from '@atob-developers/shared/src/components/Modal';
import { ReactElement, useState } from 'react';
import AccountingExport from './AccountingExport';

const AccountingExportContainer = ({
  resourceTagsEnabled,
}: {
  resourceTagsEnabled: boolean;
}): ReactElement => {
  const [openModal, toggleModal] = useState(false);
  return (
    <div className="flex flex-col items-center text-center sm:mx-5 lg:mx-40">
      <img src={AccountingPreview} alt="accounting-preview" />
      <p className="mb-2 mr-4 text-3xl font-bold">Export accounting transactions, to a CSV.</p>
      <p className="text-l">Everything you need to balance your books efficiently.</p>
      <div className="mt-4">
        <AccountingExportButton onClick={() => toggleModal(true)} />
        <Modal open={openModal} toggle={() => toggleModal(false)}>
          <AccountingExport
            closeModal={() => toggleModal(false)}
            resourceTagsEnabled={resourceTagsEnabled}
          />
        </Modal>
      </div>
      <Divider className="my-6 w-full" />
      <p className="mb-1 text-base">Works with your favorite accounting softwares like:</p>
      <div className="flex flex-row items-center">
        <img className="mr-2 w-8" src={QuickbooksLogo} alt="Quickbooks" />
        <p className="text-base font-bold">Quickbooks</p>
      </div>
    </div>
  );
};

export default AccountingExportContainer;
