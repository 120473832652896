import { ChargeEventData } from '@app/@types/charge_events.types';
import { ReactElement } from 'react';
import { DetailElement, DetailSection } from './DetailSection';
import { driverAssociationReason, hiddenUnlessPresent, notAvailableUnlessPresent } from './utils';

export const ChargeEventDriverDetails = ({
  chargeEvent,
}: {
  chargeEvent: ChargeEventData;
}): ReactElement => {
  return (
    <DetailSection title="Driver">
      <div className="grid lg:grid-cols-2 lg:gap-24">
        <div>
          <DetailElement
            name="Driver"
            value={chargeEvent.driver_name ?? 'No driver assigned to this card'}
          />
          <DetailElement
            name="Identification Method"
            value={notAvailableUnlessPresent(
              driverAssociationReason(chargeEvent.driver_association_reason),
            )}
          />

          {/* TODO(elchin): add a link for enabling card unlock */}
          <DetailElement
            name="Unlocked By"
            value={notAvailableUnlessPresent(chargeEvent.transactor_name)}
          />
          <DetailElement
            name="Unlocked Method"
            value={notAvailableUnlessPresent(chargeEvent.unlock_method)}
          />

          <DetailElement
            name="Prompted Vehicle ID"
            value={hiddenUnlessPresent(chargeEvent.service_prompt_vehicle_id)}
          />
          <DetailElement
            name="Prompted Unlock ID"
            value={hiddenUnlessPresent(chargeEvent.unlock_id)}
          />
        </div>
        <div>{/* Empty second column */}</div>
      </div>
    </DetailSection>
  );
};

export default ChargeEventDriverDetails;
