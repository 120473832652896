import {
  DeserializedMonthlySubscriptionData,
  DeserializedPaymentsData,
} from '@app/@types/payments.types';
import { PaymentHistoryTabs } from '@app/constants/payment-history';
import { PageInfo } from '@app/lib/paging/PageInfo';
import { ReactElement } from 'react';
import MonthlySubscription from './MonthlySubscription';
import PaymentList from './PaymentList';

interface PaymentHistoryContentProps {
  payments: PageInfo<DeserializedPaymentsData>;
  monthlySubscription: PageInfo<DeserializedMonthlySubscriptionData>;
  handlePaymentsPageChangeClick?: (updater: number) => void;
  handleSubscriptionsPageChangeClick?: (updater: number) => void;
  currentTab: PaymentHistoryTabs;
}
const PaymentHistoryContent = ({
  currentTab,
  payments,
  handlePaymentsPageChangeClick,
  handleSubscriptionsPageChangeClick,
  monthlySubscription,
}: PaymentHistoryContentProps): ReactElement | null => {
  switch (currentTab) {
    case PaymentHistoryTabs.FUEL_CARD_BALANCE: {
      return (
        <PaymentList payments={payments} handlePageChangeClick={handlePaymentsPageChangeClick} />
      );
    }
    case PaymentHistoryTabs.MONTHLY_SUBSCRIPTION: {
      return (
        <MonthlySubscription
          data={monthlySubscription}
          handlePageChangeClick={handleSubscriptionsPageChangeClick}
        />
      );
    }
    default:
      return null;
  }
};

export default PaymentHistoryContent;
