import { ELDProvider } from '@app/@types/eld-connect.types';
import { Dialog, DialogContent } from '@mui/material';
import { ReactElement } from 'react';
import ConnectModal from './ConnectModal';
import DisconnectModal from './DisconnectModal';

const TelematicsConnectionModal = ({
  provider,
  closeModal,
}: {
  provider: ELDProvider | null;
  closeModal: () => void;
}): ReactElement => {
  switch (provider?.connection_status) {
    case 'connected':
    case 'onboarding':
      return <DisconnectModal provider={provider} closeModal={closeModal} />;
    case 'disconnected':
      return <ConnectModal provider={provider} closeModal={closeModal} />;
  }

  return (
    <Dialog open={true} onClose={closeModal}>
      <DialogContent>
        We are having problems loading this provider data. Please contact support
      </DialogContent>
    </Dialog>
  );
};

export default TelematicsConnectionModal;
