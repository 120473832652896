import { ReactElement } from 'react';
import Title from './Title';

const WalletBalance = ({ balance }: { balance: string | null }): ReactElement => {
  return (
    <div className="flex flex-col justify-between">
      <Title title="Wallet Balance" />
      <div className="text-atob-green text-[40px] font-normal">
        {balance === null ? 'Pending' : balance}
      </div>
    </div>
  );
};

export default WalletBalance;
