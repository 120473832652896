import { EndpointResponse } from '@app/@types/api.types';
import { ProductSubscriptionResponse } from '@app/@types/subscriptions.types';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import useSWRImmutable from 'swr/immutable';

const PLANS_QUERY_KEY = {
  url: '/subscriptions/',
};
export default function useSubscriptions() {
  return useSWRImmutable<EndpointResponse<ProductSubscriptionResponse[]>>(
    PLANS_QUERY_KEY,
    apiGetFetcher,
  );
}
