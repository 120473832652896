import { ReactElement } from 'react';

export default function NotFound(): ReactElement {
  return (
    <section className="section">
      <div className="container">
        <h1 className="title">Page Not Found</h1>
        <p className="subtitle">The page you are looking for does not exist.</p>
      </div>
    </section>
  );
}
