import { ReactElement } from 'react';

interface ErrorMessageProps {
  text: string;
}

const ErrorMessage = ({ text }: ErrorMessageProps): ReactElement => {
  return <span className="text-dark-red text-xs">{text}</span>;
};

export default ErrorMessage;
