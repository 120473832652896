import { TRANSACTIONS_TABS, TransactionsFilters } from '@app/constants/transactions';
import UnderlinedTabs from '@atob-developers/shared/src/components/UnderlinedTabs';
import { ReactElement } from 'react';

type TransactionFiltersProps = {
  currentTab: TransactionsFilters;
  setCurrentTab: (tab: TransactionsFilters, authApproved: boolean | undefined) => void;
};

export default function TransactionFilters({
  currentTab,
  setCurrentTab,
}: TransactionFiltersProps): ReactElement {
  const authApproved = (tab: string) => {
    return tab === TransactionsFilters.ALL ? undefined : tab === TransactionsFilters.APPROVED;
  };

  return (
    <UnderlinedTabs
      selectedTab={currentTab}
      tabs={TRANSACTIONS_TABS}
      handleTabChange={(tab) => {
        setCurrentTab(tab as TransactionsFilters, authApproved(tab));
      }}
    />
  );
}
