/* eslint-disable jsx-a11y/label-has-associated-control */
import ApiEndpoints from '@app/utils/data/apiEndpoints';
import { emailSchema, phoneNumberSchema } from '@app/utils/validation/schema-validation';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { TextField } from '@mui/material';
import axios from 'axios';
import { ReactElement, SyntheticEvent, useState } from 'react';
import InputMask from 'react-input-mask';
import { LinkInvite, SocialInvite } from './Invites';

interface ReferralShareCardProps {
  referralLink: string;
  setError: (err: unknown) => void;
}

export default function ReferralShareCard(props: ReferralShareCardProps): ReactElement {
  const { referralLink, setError } = props;
  const { addToast } = useToasts();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState<boolean | null>(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState<boolean | null>(null);

  const onSubmitEmail = async (e: SyntheticEvent) => {
    e.preventDefault();

    try {
      emailSchema.validateSync({ email });
    } catch (err) {
      setEmailError(true);
      return;
    }

    try {
      await axios.post(ApiEndpoints.REFERRALS_ENDPOINTS.EMAIL_ENDPOINT, {
        recipient_email: email,
      });

      addToast(`Email sent to ${email}!`, {
        appearance: 'success',
      });
    } catch (err: unknown) {
      setError(err);
    }
  };

  const onEmailInput = (emailEvent: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEmailError(null);
    const target = emailEvent.target as HTMLInputElement;
    const emailInput = target.value;
    setEmail(emailInput);
  };

  const onPhoneInput = (phoneEvent: SyntheticEvent<HTMLInputElement>) => {
    setPhoneNumberError(false);
    const target = phoneEvent.target as HTMLInputElement;
    const phoneInput = target.value;
    setPhoneNumber(phoneInput);
  };

  const onSubmitPhoneNumber = async (e: SyntheticEvent) => {
    e.preventDefault();

    try {
      phoneNumberSchema.validateSync({ phoneNumber });
    } catch (err) {
      setPhoneNumberError(true);
      return;
    }

    try {
      await axios.post(ApiEndpoints.REFERRALS_ENDPOINTS.PHONE_ENDPOINT, {
        recipient_phone_number: phoneNumber,
      });

      addToast(`Text message sent to ${phoneNumber}!`, {
        appearance: 'success',
      });
    } catch (err: unknown) {
      setError(err);
    }
  };

  return (
    <div className="bg-white p-8 shadow-md">
      <div className="text-base font-medium">
        <p className="text-lg font-bold">You can share your personal referral link</p>

        <LinkInvite referralLink={referralLink} />
        <SocialInvite referralLink={referralLink} />

        <p className="mb-1 mt-5 text-lg font-bold">Or you can send them an email</p>

        <form onSubmit={onSubmitEmail}>
          <div className="flex items-center justify-between">
            <div>
              <TextField
                size="small"
                id="email"
                placeholder="friend@example.com"
                inputProps={{ 'data-testid': 'email-input' }}
                onChange={onEmailInput}
              />
            </div>
            <div>
              <button type="submit" className="button input-size" data-testid="send-email-invite">
                Send Invite
              </button>
            </div>
          </div>
          {emailError && <p className="text-sm text-red-700">This email address is invalid.</p>}
        </form>

        <p className="mb-1 mt-5 text-lg font-bold">Or a text</p>

        <form onSubmit={onSubmitPhoneNumber}>
          <div className="flex items-center justify-between">
            <div className="flex justify-between">
              <div>
                <InputMask mask="999-999-9999" placeholder="555-444-5555" onChange={onPhoneInput}>
                  <TextField size="small" inputProps={{ 'data-testid': 'phone-number-input' }} />
                </InputMask>
              </div>
            </div>
            <div className="control">
              <button type="submit" className="button input-size" data-testid="send-text-invite">
                Send Invite
              </button>
            </div>
          </div>
          {phoneNumberError && (
            <p className="text-sm text-red-700">This phone number is invalid.</p>
          )}
        </form>
      </div>
    </div>
  );
}
