import useCustomerOnboardingEvents, {
  CustomerOnboardingEventName,
} from '@app/hooks/query/useCustomerOnboardingEvents';
import useCustomer from '@app/hooks/useCustomer';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import dayjs from 'dayjs';

export const SHOW_AFTER_CREATED = dayjs('Thurs, 28 Mar 2024 21:00:00 GMT');

export function useHasCompletedOnboarding() {
  const [shouldBeInNewOnboarding] = useFeatureFlags('force_new_onboarding_flow');
  const { created_at } = useCustomer();
  const completedEvents = useCustomerOnboardingEvents();

  return (
    completedEvents.has(CustomerOnboardingEventName.CUSTOMER_ONBOARDING_COMPLETED) ||
    (dayjs(created_at).isBefore(SHOW_AFTER_CREATED) && !shouldBeInNewOnboarding)
  );
}

export function useShouldBeInOnboarding() {
  const { created_at } = useCustomer();
  const completedEvents = useCustomerOnboardingEvents();
  const [shouldBeInNewOnboarding] = useFeatureFlags('force_new_onboarding_flow');
  return (
    shouldBeInNewOnboarding ||
    (dayjs(created_at).isAfter(SHOW_AFTER_CREATED) &&
      !completedEvents.has(CustomerOnboardingEventName.CUSTOMER_ONBOARDING_COMPLETED))
  );
}
