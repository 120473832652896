import { createContext } from 'react';

export type PreferredCard = {
  id: string;
  driverId: number;
  lastFourDigits: string;
  cardSecurityEnabled: boolean;
  externalId?: number;
  isCardUnlocked: boolean;
};

export type SelectedCard =
  | {
      hasSelectedCard: true;
      cardInfo: PreferredCard;
    }
  | {
      hasSelectedCard: false;
    };

export const InAppSelectedCardContext = createContext<SelectedCard>({ hasSelectedCard: false });
