import { DriversCounts } from '@app/@types/driver.types';
import useThemeConfiguration from '@app/app/useThemeConfiguration';
import AtobAppIcon from '@app/assets/images/svg/atob_app_icon.svg';
import { useIsUberfreight } from '@app/hooks/partners/useIsUberfreight';
import useDisplayUntilConfirmed from '@app/hooks/useDisplayUntilConfirmed';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import { getFetcher } from '@app/utils/data/fetchers';
import { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { ProductNudgeBanner } from '../Banner/Designs/ProductNudgeBanner';
import DriverAppLearnMoreModal from './DriverAppLearnMoreModal';
import DriverInviteModal from './DriverInviteModal';

type DriverInviteBannerProps = {
  isDismissible: boolean;
  bannerKey: string;
  onClick?: () => void;
};
type DriverInviteBannerContentProps = {
  isDismissible: boolean;
  dismissBanner: () => void;
  onClick?: () => void;
};

const DriverInviteBannerContent = ({
  isDismissible,
  onClick,
}: DriverInviteBannerContentProps): ReactElement | null => {
  const navigate = useNavigate();

  // Prevent querying for driver counts if Cx isn't passing the appropriate FFs
  // or banner shouldn't be shown
  const { data: driversCounts, mutate: driverCountRefresh } = useSWR<DriversCounts>(
    { url: '/drivers/counts' },
    getFetcher,
  );
  const [showDriverInviteModal, setShowDriverInviteModal] = useState<boolean>(false);
  const [showDriverAppLearnMoreModal, setShowDriverAppLearnMoreModal] = useState<boolean>(false);

  const toggleDriverInviteModal = () => setShowDriverInviteModal(!showDriverInviteModal);
  const toggleDriverAppLearnMoreModal = () =>
    setShowDriverAppLearnMoreModal(!showDriverAppLearnMoreModal);

  const isInviteCountPlural = driversCounts?.pending_invites !== 1;

  return (driversCounts?.pending_invites ?? 0) > 0 ? (
    <ProductNudgeBanner
      bannerKey="driver_app_invite_banner"
      variant="pro_tip"
      iconImg={<img alt="AtoB App Icon" src={AtobAppIcon} />}
      actionText="Invite Drivers"
      onClick={isDismissible ? toggleDriverAppLearnMoreModal : toggleDriverInviteModal}
      linkText={isDismissible ? undefined : 'Learn More'}
      onLinkClick={isDismissible ? undefined : toggleDriverAppLearnMoreModal}
      nonDismissable={!isDismissible}
    >
      You have {driversCounts?.pending_invites + (isInviteCountPlural ? ' drivers' : ' driver')} who{' '}
      {isInviteCountPlural ? 'have' : 'has'} not downloaded the AtoB App, and{' '}
      {isInviteCountPlural ? 'are' : 'is'} not getting fuel discounts.
      <DriverInviteModal
        onClick={() => {
          driverCountRefresh();
          // Navigate to Drivers page
          navigate('/drivers');
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          onClick();
        }}
        reset={toggleDriverInviteModal}
        isActive={showDriverInviteModal}
      />
      <DriverAppLearnMoreModal
        reset={toggleDriverAppLearnMoreModal}
        isActive={showDriverAppLearnMoreModal}
        onDriverInvite={toggleDriverInviteModal}
      />
    </ProductNudgeBanner>
  ) : null;
};

export default function DriverInviteBanner({
  isDismissible,
  bannerKey,
  onClick,
}: DriverInviteBannerProps): ReactElement | null {
  const [streamlinedDriverAppOnboarding, blocklistDriverAppOnboarding] = useFeatureFlags(
    'streamlined_driver_app_onboarding',
    'blocklist_driver_app_onboarding',
  );
  const isUF = useIsUberfreight();
  const [isBannerDismissed, dismissBanner] = useDisplayUntilConfirmed(bannerKey);
  const { hideDismissableBanners } = useThemeConfiguration();
  const shouldShowBanner = (!isDismissible || !isBannerDismissed()) && !hideDismissableBanners;

  const enableStreamlinedDriverAppOnboarding =
    streamlinedDriverAppOnboarding && !blocklistDriverAppOnboarding;
  const bannerEnabled = !isUF && enableStreamlinedDriverAppOnboarding && shouldShowBanner;
  if (!bannerEnabled) {
    return null;
  }

  return (
    <DriverInviteBannerContent
      isDismissible={isDismissible}
      dismissBanner={dismissBanner}
      onClick={onClick}
    />
  );
}
