import {
  BankAccountPaymentMethodCombined,
  DestructuredBankAccountData,
} from '@app/@types/bankAccount.types';
import { usePaymentMethodsQuery } from '@app/hooks/query/usePaymentMethodsQuery';
import * as Sentry from '@sentry/react';
import { ReactElement, useEffect, useState } from 'react';
import { mapBankAndDebitCardData } from '../PaymentMethods/PaymentMethodUtils';
import BankAccountReauthenticateBanner from './BankAccountReauthenticateBanner';
import MicrodepositVerificationBanner from './MicrodepositVerificationBanner';

const AuthenticateBankAccount = ({
  customerOwnerEmail,
}: {
  customerOwnerEmail: string;
}): ReactElement | null => {
  const [bankNeedsMicroDepositVerification, setBankNeedsMicroDepositVerification] =
    useState<BankAccountPaymentMethodCombined | null>(null);
  const [successfullyLinkedBankAccount, setSuccessfullyLinkedBankAccount] = useState(false);
  const [primaryBankAccount, setPrimaryBankAccount] = useState<DestructuredBankAccountData | null>(
    null,
  );
  const [error, setError] = useState<string | null>(null);

  const { error: paymentMethodsError, data: paymentMethodsData } = usePaymentMethodsQuery();

  useEffect(() => {
    if (paymentMethodsData) {
      const paymentMethods = mapBankAndDebitCardData(paymentMethodsData.data || []);
      const { combinedPaymentMethods } = paymentMethods;
      const { bankAccounts } = combinedPaymentMethods;
      const accountNeedsMicrodepositVerification =
        bankAccounts.find((account: DestructuredBankAccountData) => {
          const hasMicrodeposit =
            account.microdeposits_sent_at !== null && account.microdeposits_sent_at !== undefined;
          return hasMicrodeposit && account.state === 'pending';
        }) || null;
      setBankNeedsMicroDepositVerification(accountNeedsMicrodepositVerification);
      const primaryBankAccount =
        bankAccounts.find((account: DestructuredBankAccountData) => {
          return account.is_primary;
        }) || null;
      setPrimaryBankAccount(primaryBankAccount);
    }

    if (paymentMethodsError) {
      Sentry.captureException(paymentMethodsError, {
        user: {
          request_id: paymentMethodsError?.response?.headers?.['x-request-id'],
          owner_email: customerOwnerEmail,
        },
      });
      setError('There was an issue getting your bank account data. Please contact support.');
    }
  }, [customerOwnerEmail, paymentMethodsData, paymentMethodsError]);

  if (bankNeedsMicroDepositVerification != null) {
    return (
      <MicrodepositVerificationBanner
        setSuccessfullyLinkedBankAccount={setSuccessfullyLinkedBankAccount}
        error={error}
        setError={setError}
        microDepositVerificationId={bankNeedsMicroDepositVerification.bank_account_id}
        successfullyLinkedBankAccount={successfullyLinkedBankAccount}
      />
    );
  } else if (primaryBankAccount !== null) {
    return (
      <BankAccountReauthenticateBanner
        primaryBankAccount={primaryBankAccount}
        setSuccessfullyLinkedBankAccount={setSuccessfullyLinkedBankAccount}
        successfullyLinkedBankAccount={successfullyLinkedBankAccount}
        error={error}
        setError={setError}
      />
    );
  }

  return null;
};

export default AuthenticateBankAccount;
