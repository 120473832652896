import { EndpointResponse } from '@app/@types/api.types';
import { CustomerData } from '@app/@types/customer.types';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import { SWRResponse } from 'swr';
import useSWRImmutable from 'swr/immutable';

export const CUSTOMER_INCLUDES =
  'card_shipping_address,channel_partner,company_address,treasury,treasury.financial_account,issuing,customer_onboarding_events';

// You can call mutate(CUSTOMER_QUERY_KEY) to refresh the data
// without needing to hard-refresh the page.
export const CUSTOMER_QUERY_KEY = {
  url: '/customer',
  params: {
    include: CUSTOMER_INCLUDES,
  },
};

/**
 * Fetches the customer.
 *
 * Do not use this directly - instead use `useCustomer()`
 */
export const useCustomerQuery = (): SWRResponse<EndpointResponse<CustomerData>, Error> => {
  return useSWRImmutable<EndpointResponse<CustomerData>>(CUSTOMER_QUERY_KEY, apiGetFetcher);
};
