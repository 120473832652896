interface ConnectedProviderCardProps {
  connectionStatus?: string;
  numberOfConnections?: number;
}

const ConnectedProviderCard = ({
  connectionStatus,
  numberOfConnections = 1,
}: ConnectedProviderCardProps) => {
  const accountsConnectedText =
    !numberOfConnections || numberOfConnections <= 1 ? 'Account Connected' : 'Accounts Connected';

  return (
    <>
      {connectionStatus === 'onboarding' && (
        <div className="mb-1.5 text-xs leading-3">
          <div className="mb-1 text-amber-600">Connecting telematics...</div>
          <div>This may take up to 24 hours</div>
        </div>
      )}
      {connectionStatus === 'connected' && (
        <div className="mb-1.5 text-xs leading-3">
          <span className="text-green-600">
            {numberOfConnections} {accountsConnectedText}
          </span>
        </div>
      )}
    </>
  );
};

export default ConnectedProviderCard;
