import dayjs, { ManipulateType } from 'dayjs';
import { useCallback } from 'react';
import useLocalStorageValues from './useLocalStorageValues';

const compareCurrentTimeToExpiration = (expiresAt: number) => {
  return dayjs().isAfter(dayjs(expiresAt));
};

export const createExpirationDate = (expiresIn: number, unit: ManipulateType): number => {
  return dayjs().add(expiresIn, unit).valueOf();
};

export default function useDisplayUntilConfirmed(
  localStorageValue: string,
  options?: {
    expiresAt?: number;
  },
): [() => boolean, () => void] {
  const [getLocalStorageValues, setLocalStorageValues] = useLocalStorageValues();
  const localStorageValues = getLocalStorageValues();

  const handleConfirm = () => {
    setLocalStorageValues({
      [localStorageValue]: options?.expiresAt || true,
    });
  };

  const checkIfConfirmed = useCallback(() => {
    const expirationDateFoundInLocalStorage =
      typeof localStorageValues[localStorageValue] === 'number';

    if (expirationDateFoundInLocalStorage) {
      const hasExpired = compareCurrentTimeToExpiration(
        localStorageValues[localStorageValue] as number,
      );

      if (hasExpired) {
        setLocalStorageValues({ [localStorageValue]: undefined });
        return false;
      }

      return true;
    }

    return localStorageValues[localStorageValue] === true;
  }, [localStorageValues, localStorageValue, setLocalStorageValues]);

  return [checkIfConfirmed, handleConfirm];
}
