import { CustomerData } from '@app/@types/customer.types';
import { compact } from 'lodash-es';

/**
 * Returns the shipping address for a customer, and uses the company address as fallback if no shipping address is set.
 */
export default function useShippingAddress(customer: CustomerData): string {
  if (customer.card_shipping_address && Object.keys(customer.card_shipping_address).length > 0) {
    const { address1, address2, city, state, zip } = customer.card_shipping_address;
    const address = compact([address1, address2, city, state, zip]).join(', ');

    if (address) {
      return address;
    }
  }

  if (customer.company_address && Object.keys(customer.company_address).length > 0) {
    // Use company address as fallback. We do this on the backend as well to ensure cards are always shipped.
    const { address1, address2, city, state, zip } = customer.company_address;
    return compact([address1, address2, city, state, zip]).join(', ');
  }

  return '';
}
