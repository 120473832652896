import { DataItemType } from '@atob-developers/shared/src/components/DataItem';
import {
  faEnvelope,
  faPhone,
  faLocationPin,
  faSignature,
  faMobileScreen,
  faTruck,
} from '@fortawesome/pro-regular-svg-icons';

import { statesArray } from './states';

export const SETTINGS_ROW_OPTIONS = {
  customer_email: {
    title: 'Email Address',
    subtitle: 'This is the email used to contact you about your AtoB account.',
    icon: faEnvelope,
  },
  customer_phone_number: {
    title: 'Phone Number',
    subtitle: 'This is the phone number used to contact you about your AtoB account.',
    icon: faPhone,
  },
  company_address: {
    title: 'Company Address',
    subtitle: 'This is the address associated with your company.',
    icon: faLocationPin,
  },
  card_shipping_address: {
    title: 'Card Shipping Address',
    subtitle: 'This is the address where you will get your cards shipped to.',
    icon: faLocationPin,
  },
  telematics_notifications_enabled: {
    title: 'Send SMS messages for suspicious activities',
    subtitle:
      'When this is enabled, you will receive a SMS message to your phone number each time we detect a transaction is suspicious.',
    icon: faMobileScreen,
  },
  customer_company_preferred_name: {
    title: 'Company Preferred Name',
    subtitle: 'The preferred name to be shown on the payroll message.',
    icon: faSignature,
  },
  fleet_type: {
    title: 'Fleet Type',
    subtitle: 'The type of vehicles used in your fleet.',
    icon: faTruck,
  },
  otp_backup_email: {
    title: 'App Login Backup Email',
    subtitle: 'If set, an email address that will receive all app login codes',
    icon: faMobileScreen,
  },
};

export const SETTINGS_FORM_DATA = {
  customer_phone_number: [
    {
      label: 'Phone Number',
      type: DataItemType.TEXT,
      inputType: 'tel',
      key: 'phone',
    },
  ],

  customer_email: [
    {
      label: 'New Email Address',
      placeholder: 'name@email.com',
      type: DataItemType.TEXT,
      key: 'email',
    },
  ],

  company_address: [
    {
      label: 'New Company Address',
      placeholder: 'Address Line One',
      type: DataItemType.TEXT,
      key: 'address1',
    },
    {
      label: '',
      placeholder: 'Address Line Two (optional)',
      type: DataItemType.TEXT,
      key: 'address2',
    },
    [
      {
        label: '',
        placeholder: 'City',
        type: DataItemType.TEXT,
        key: 'city',
        basis: '2/4',
      },
      {
        label: '',
        placeholder: 'State',
        blankState: 'State',
        type: DataItemType.DROPDOWN,
        key: 'state',
        options: statesArray.map((state, idx) => {
          return { id: idx, option: state };
        }),
        basis: '1/4',
      },
      {
        label: '',
        placeholder: 'Zip',
        type: DataItemType.TEXT,
        key: 'zip',
        basis: '1/4',
      },
    ],
  ],

  card_shipping_address: [
    {
      label: 'New Card Shipping Address',
      placeholder: 'Address Line One',
      type: DataItemType.TEXT,
      path: 'address1',
      key: 'address1',
    },
    {
      label: '',
      placeholder: 'Address Line Two (optional)',
      type: DataItemType.TEXT,
      path: 'address2',
      key: 'address2',
    },
    {
      label: '',

      placeholder: 'City',
      type: DataItemType.TEXT,
      path: 'city',
      key: 'city',
    },
    {
      label: '',
      placeholder: 'State',
      type: DataItemType.TEXT,
      path: 'state',
      key: 'state',
    },
    {
      label: '',
      placeholder: 'Zip',
      type: DataItemType.TEXT,
      path: 'zip',
      key: 'zip',
    },
  ],
  telematics_notifications_enabled: [
    {
      label: 'Telematics Notifications Enabled',
      type: DataItemType.TEXT,
      inputType: 'text',
      key: 'telematics',
    },
  ],
  customer_company_preferred_name: [
    {
      label: 'Company Preferred Name',
      type: DataItemType.TEXT,
      inputType: 'text',
      key: 'company_preferred_name',
    },
  ],

  fleet_type: [
    {
      label: 'Fleet Type',
      type: DataItemType.TEXT,
      inputType: 'text',
      key: 'fleet_type',
    },
  ],

  otp_backup_email: [
    {
      label: 'App Login Backup Email',
      placeholder: 'name@email.com (Leave blank to disable)',
      type: DataItemType.TEXT,
      inputType: 'text',
      key: 'otp_backup_email',
    },
  ],
};
