import { ReactElement, ReactNode } from 'react';

export default function DetailPane({
  label,
  value,
  subValue,
}: {
  label: ReactNode;
  value: ReactNode;
  subValue?: ReactNode;
}): ReactElement {
  return (
    <div className="flex flex-1 flex-col gap-2 p-6">
      <div className="text-sm leading-none text-gray-500">{label}</div>
      <div className="text-2xl font-semibold leading-none tracking-[-0.72px]">{value}</div>
      {!!subValue && subValue}
    </div>
  );
}
