import { useState } from 'react';

type ModalHook = [boolean, () => void];

export default function useModal(): ModalHook {
  const [isActive, setIsActive] = useState(false);

  function toggleModal() {
    setIsActive(!isActive);
  }

  return [isActive, toggleModal] as ModalHook;
}
