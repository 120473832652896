import { ReactElement } from 'react';

export enum StepStatus {
  // A future step, not yet reached
  UPCOMING,
  // The current step being completed
  CURRENT,
  // A past step, successfully finished
  COMPLETE,
  // An indeterminate step that allows custom styling of the title and text
  CUSTOM,
  // An empty step to hold a spot for future steps
  PLACEHOLDER,
}

type BaseStep = {
  id: string;
  status: StepStatus;
  href?: string;
  disableIcon?: boolean;
  description?: string;
};

export type CompleteStep = BaseStep & {
  status: StepStatus.COMPLETE;
  name: string;
  description?: string;
};

export type UpcomingStep = BaseStep & {
  status: StepStatus.UPCOMING;
  name: string;
  description?: string;
  required?: boolean;
  renderIcon?: (id: string) => ReactElement;
};

export type CurrentStep = BaseStep & {
  status: StepStatus.CURRENT;
  name: string;
  description?: string;
  required?: boolean;
  renderIcon?: (id: string) => ReactElement;
};

export type CustomStep = BaseStep & {
  status: StepStatus.CUSTOM;
  title: ReactElement;
  body: ReactElement;
  renderIcon?: (id: string) => ReactElement;
};

export type PlaceholderStep = BaseStep & {
  status: StepStatus.PLACEHOLDER;
};

export type SequentialStep = UpcomingStep | CurrentStep | CompleteStep;
export type Step = SequentialStep | CustomStep | PlaceholderStep;
