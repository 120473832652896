import useThemeConfiguration from '@app/app/useThemeConfiguration';
import EmbeddedNavSection from '@app/components/Navigation/EmbeddedNav/EmbeddedNav';
import { EMBEDDED_NAV_OPTIONS } from '@app/components/Navigation/NavBarItems';

export const EmbeddedNavigationElement = (): JSX.Element | null => {
  const { showAppNav } = useThemeConfiguration();

  if (showAppNav) {
    return null;
  }

  return (
    <div>
      <EmbeddedNavSection items={EMBEDDED_NAV_OPTIONS} />
    </div>
  );
};
