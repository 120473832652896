import classNames from 'classnames';
import { ReactElement, ReactNode } from 'react';

export default function OnboardingForm({
  centered,
  children,
  fullWidth,
  noPadding,
}: {
  centered?: boolean;
  fullWidth?: boolean;
  noPadding?: boolean;
  children: ReactNode;
}): ReactElement {
  return (
    <div
      className={classNames(
        'box-border flex w-full flex-1 flex-col items-stretch',
        centered && 'items-center',
        fullWidth ? '' : 'md:max-w-[50%]',
      )}
    >
      <div
        className={classNames(
          'flex flex-1 flex-col gap-4',
          centered && 'items-center',
          !noPadding && 'px-6 pt-8 md:px-20 md:pt-10',
        )}
      >
        {children}
      </div>
    </div>
  );
}
