import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import cx from 'classnames';
import { ReactElement, PropsWithChildren, ReactNode } from 'react';
export interface WarningBannersProps {
  icon?: IconProp;
  onClick?: () => void;
  actionText?: string;
  customActionButton?: ReactNode;
  rounded?: boolean;
}

export const WarningBanner = ({
  icon = faExclamationCircle,
  onClick,
  actionText,
  customActionButton,
  rounded = false,
  children,
}: PropsWithChildren<WarningBannersProps>): ReactElement => {
  return (
    <div
      className={cx(
        'w-full items-center justify-items-start bg-orange-200 px-4 py-5 text-sm font-normal',
        { 'rounded-md': rounded },
      )}
    >
      <div className="flex flex-row justify-start gap-6 md:items-center">
        <div className="flex h-12 w-12 justify-center align-middle">
          <div className="flex">
            <FontAwesomeIcon icon={icon} className="my-auto h-6 w-6" />
          </div>
        </div>
        <div className="flex flex-col justify-between gap-1 sm:flex-row md:w-full md:items-center">
          <div className="text-left">{children}</div>
          {actionText && onClick && (
            <Button
              size="small"
              variant="contained"
              onClick={onClick}
              className="mt-4 h-11 w-40 bg-white bg-opacity-90 hover:bg-white hover:bg-opacity-90 sm:mt-0"
            >
              <span className="text-black0">{actionText}</span>
            </Button>
          )}
          {customActionButton && <div className="mt-2 md:mt-0">{customActionButton}</div>}
        </div>
      </div>
    </div>
  );
};
