import { TransactionTab } from '@app/interfaces/transactions';

export enum TransactionsFilters {
  ALL = 'all',
  APPROVED = 'approved',
  DECLINED = 'declined',
}

export const TRANSACTIONS_TABS: TransactionTab[] = [
  {
    name: 'All Transactions',
    tab: TransactionsFilters.ALL,
  },
  {
    name: 'Approved',
    tab: TransactionsFilters.APPROVED,
  },
  {
    name: 'Declined',
    tab: TransactionsFilters.DECLINED,
  },
];
