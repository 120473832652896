import Constants from '@app/utils/constants';
// Ask Praveen if this is needed. If the Customer object will have connected status already,
// we wont need this.
const getStatusFromURLParameters = (): string | null => {
  const params = new URLSearchParams(window.location.search);
  return params.get('status');
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
const getHeaderMessage = (): string => {
  if (getStatusFromURLParameters() === Constants.ELDEnums.NOT_CONNECTED) {
    return 'There was an error connecting to your provider. Please try again or contact support.';
  }
};

export { getStatusFromURLParameters, getHeaderMessage };
