import { faCheckCircle, faXmarkCircle } from '@fortawesome/pro-regular-svg-icons';
import { ReactElement, useState } from 'react';
import PaymentStatusContent from '../PaymentErrors/PaymentStatusContent';
import LoadpayWalletEditPaymentDetails from './LoadpayWalletEditPaymentDetails';
import LoadpayWalletReviewPaymentDetails from './LoadpayWalletReviewPaymentDetails';

interface LoadpayWalletControllerProps {
  setShowPaymentSelection: (showPaymentSelection: boolean) => void;
  closeModal: () => void;
  paymentAmount: string;
  setPaymentAmount: (paymentAmount: string) => void;
  showAmountDue: boolean;
  setShowAmountDue: (showAmountDue: boolean) => void;
}

const LoadpayWalletController = ({
  closeModal,
  setShowPaymentSelection,
  paymentAmount,
  setPaymentAmount,
  showAmountDue,
  setShowAmountDue,
}: LoadpayWalletControllerProps): ReactElement | null => {
  const [paymentScreen, setPaymentScreen] = useState<'edit' | 'review' | 'success' | 'error'>(
    'edit',
  );

  const onNext = () => {
    setShowPaymentSelection(false);
    setPaymentScreen('review');
  };

  const onBack = () => {
    setShowPaymentSelection(true);
    setPaymentScreen('edit');
  };

  switch (paymentScreen) {
    case 'edit':
      return (
        <LoadpayWalletEditPaymentDetails
          onNext={onNext}
          closeModal={closeModal}
          paymentAmount={paymentAmount}
          setPaymentAmount={setPaymentAmount}
          showAmountDue={showAmountDue}
          setShowAmountDue={setShowAmountDue}
        />
      );
    case 'review':
      return (
        <LoadpayWalletReviewPaymentDetails
          setPaymentScreen={setPaymentScreen}
          onBack={onBack}
          paymentAmount={paymentAmount}
        />
      );
    case 'success':
      return (
        <PaymentStatusContent
          title="Success"
          icon={faCheckCircle}
          message="We've received your payment request. Please allow 1-2 minutes for this payment to reflect on your account and show on your Payment History page."
          iconClassName="text-atob-green"
          showButton
        />
      );
    case 'error':
      return (
        <PaymentStatusContent
          title="Error processing your Wallet Payment"
          icon={faXmarkCircle}
          message="There was an error processing your payment. Please contact support."
          iconClassName="text-red4"
        />
      );
    default:
      return null;
  }
};

export default LoadpayWalletController;
