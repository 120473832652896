import { getFetcher } from '@app/utils/data/fetchers';
import dayjs from 'dayjs';
import { deserialize } from 'deserialize-json-api';
import useSWR from 'swr';
import { getAutoTopUpState } from './utils/get-auto-topup-state';
import type { AutoTopUp, AutoTopUpState } from '../Prepaid.types';

const EMPTY_AUTO_TOP_UP: AutoTopUp = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  id: null,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  deleted_at: null,
  frequency: 'cadence' as 'cadence' | 'balance',
  enabled: false,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  payment_method_id: null,
  amount: '',
  cadence_schedule: 'weekly' as 'weekly' | 'monthly',
  start_time: dayjs().add(1, 'day').toDate(),
};

export const useAutoTopUpData = (): {
  autoTopUpError: unknown;
  isLoading: boolean;
  autoTopUp: AutoTopUpState;
  hasAutoTopUp: boolean;
  refreshAutoTopUp: () => void;
} => {
  const {
    error: autoTopUpError,
    data: autoTopUpResponse,
    isLoading,
    mutate: refreshAutoTopUp,
  } = useSWR<{ data: AutoTopUp }>('/treasury/auto_topups', getFetcher);
  let hasAutoTopUp = autoTopUpResponse?.data != null;

  let autoTopUp = EMPTY_AUTO_TOP_UP;
  if (hasAutoTopUp) {
    const deserializeATU = deserialize(autoTopUpResponse).data;
    if (deserializeATU.deleted_at === null) {
      autoTopUp = deserializeATU;
    } else {
      hasAutoTopUp = false;
    }
  }

  return {
    autoTopUpError,
    isLoading,
    autoTopUp: getAutoTopUpState(autoTopUp),
    hasAutoTopUp,
    refreshAutoTopUp,
  };
};
