import { PaginatedEndpointResponse } from '@app/@types/api.types';
import { BankAccountWithEstimateData } from '@app/@types/payments.types';
import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import ApiEndpoints from '@app/utils/data/apiEndpoints';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import logger from '@app/utils/datadog-logger';
import { faXmarkCircle } from '@fortawesome/pro-regular-svg-icons';
import { ReactElement } from 'react';
import useSWR from 'swr';
import PaymentStatusContent from '../PaymentErrors/PaymentStatusContent';
import BankTransferController from './BankTransferController';

export interface BankTransferWrapperProps {
  closeModal: () => void;
  setShowPaymentSelection: (showPaymentSelection: boolean) => void;
  paymentAmount: string;
  setPaymentAmount: (paymentAmount: string) => void;
  showAmountDue: boolean;
  setShowAmountDue: (showAmountDue: boolean) => void;
}

const BankTransferWrapper = (props: BankTransferWrapperProps): ReactElement => {
  const {
    error,
    data: bankAccountsWithEstimatesData,
    isLoading,
  } = useSWR<PaginatedEndpointResponse<BankAccountWithEstimateData>>(
    {
      url: ApiEndpoints.FUEL_CARD_PAYMENTS_ENDPOINTS.ESTIMATES_ENDPOINT,
    },
    apiGetFetcher,
  );

  if (isLoading) {
    logger.info('Initiate bank payment modal load');
    return (
      <div className="w-full">
        <SpinnerBoundary />
      </div>
    );
  }

  if (error) {
    logger.error('Loading bank modal failed', { error });
    return (
      <PaymentStatusContent
        title="Error loading your payment methods"
        icon={faXmarkCircle}
        message="There was an error loading your payment methods. Please try again or, if the issue persists, contact support."
      />
    );
  }

  return (
    <BankTransferController
      {...props}
      bankAccountsWithEstimates={bankAccountsWithEstimatesData?.data ?? []}
    />
  );
};

export default BankTransferWrapper;
