import { CustomerSpendData } from '@app/@types/customer.types';
import { SWRResponse } from 'swr';
import useSWRImmutable from 'swr/immutable';

/**
 * Fetches the customer spend data for a given period.
 */
export const useCustomerSpendQuery = (
  after?: string,
  before?: string,
): SWRResponse<CustomerSpendData> => {
  return useSWRImmutable<CustomerSpendData>({
    url: '/customer/spend',
    params: {
      before,
      after,
    },
  });
};
