import Header from '@app/components/Navigation/Header';
import PageContentWrapper from '@app/components/wrappers/PageContentWrapper';
import { ReactElement } from 'react';
import { FuelFinder } from '../FuelMap/FuelMap';

export default function FuelMap(): ReactElement {
  return (
    <PageContentWrapper header={<Header title={'Fuel Map'} />}>
      <FuelFinder />
    </PageContentWrapper>
  );
}
