import { ChargeEventData } from '@app/@types/charge_events.types';
import useChartJSStyling from '@app/hooks/useChartJSStyling';
import { faCircle, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import { BarElement, CategoryScale, Chart as ChartJS, ChartOptions, LinearScale } from 'chart.js';
import { ReactElement } from 'react';
import { Bar } from 'react-chartjs-2';
import { TelematicsStatus } from './TelematicsStatus';

const TOOLTIP_TEXT =
  'Fuel level readings from the telematics device may fluctuate. ' +
  'Therefore estimate for gallons added to tank are based on the most conservative ' +
  'measurement of fuel in tank before vs. after the transaction.';

ChartJS.register(CategoryScale, LinearScale, BarElement);

const DataUnavailableSvg = () => (
  <svg viewBox="0 0 452 86" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M457 30.3214L445.787 32.6413C434.96 34.9612 412.92 39.6011 390.88 30.3214C368.453 21.0416 346.413 -2.15785 324.373 0.162092C302.333 2.48204 280.293 30.3214 258.253 46.561C236.213 62.8006 213.787 67.4405 191.747 67.4405C169.707 67.4405 147.667 62.8006 125.627 55.8407C103.587 48.8809 81.5467 39.6011 59.12 32.6413C37.08 25.6815 15.04 21.0416 4.21335 18.7216L-7 16.4017V86H4.21335C15.04 86 37.08 86 59.12 86C81.5467 86 103.587 86 125.627 86C147.667 86 169.707 86 191.747 86C213.787 86 236.213 86 258.253 86C280.293 86 302.333 86 324.373 86C346.413 86 368.453 86 390.88 86C412.92 86 434.96 86 445.787 86H457V30.3214Z"
      fill="#EAEFF2"
    />
  </svg>
);

const DataUnavailableChart = ({ chargeEvent }: { chargeEvent: ChargeEventData }): ReactElement => {
  return (
    <div className="flex flex-col gap-2 rounded-lg bg-white p-6 md:h-96">
      <div className="flex flex-row gap-4">
        <div className="flex flex-col gap-4">
          <div className="flex flex-row">
            <div className="mr-2 font-semibold">Fuel Level Check</div>
            <div className="items-end text-gray-300">
              <Tooltip title={TOOLTIP_TEXT}>
                <div>
                  <FontAwesomeIcon icon={faInfoCircle} />
                </div>
              </Tooltip>
            </div>
          </div>
          <div className="flex flex-row gap-4">
            <div className="text-xs">
              <span>No fuel data available</span>
            </div>
          </div>
        </div>

        <div className="flex flex-1 items-start justify-end">
          <TelematicsStatus chargeEvent={chargeEvent} unavailable />
        </div>
      </div>
      <div className="relative mt-4 hidden h-64 items-end justify-center overflow-hidden rounded-lg bg-slate-100 align-bottom md:flex">
        <DataUnavailableSvg />
        <div className="absolute bottom-0 top-0 mx-auto my-auto h-10 rounded border border-gray-200 bg-white p-2 px-16">
          No data available
        </div>
      </div>
    </div>
  );
};

const ChargeEventFuelLevelsChart = ({
  chargeEvent,
}: {
  chargeEvent: ChargeEventData;
}): ReactElement => {
  useChartJSStyling();

  if (!chargeEvent) {
    return <DataUnavailableChart chargeEvent={chargeEvent} />;
  }

  const { fuel_levels_for_transaction } = chargeEvent;

  if (!fuel_levels_for_transaction) {
    return <DataUnavailableChart chargeEvent={chargeEvent} />;
  }

  const fuelDataAvailable = fuel_levels_for_transaction?.gallons_before_authorization_min;

  const is_suspicious = fuel_levels_for_transaction?.is_suspicious;
  const gallonsMissing = parseFloat(
    fuel_levels_for_transaction.purchase_difference_by_min_max || '0',
  );
  const gallonsPurchased = parseFloat(fuel_levels_for_transaction.gallons_purchased || '0');
  const gallonsAddedToTank = parseFloat(
    fuel_levels_for_transaction.expected_gallons_purchased_by_min_max || '0',
  );

  const resultDisplay = fuelDataAvailable
    ? gallonsMissing <= 0
      ? 'No excess gallons purchased'
      : `${gallonsMissing.toFixed(1)} excess gallons purchased`
    : 'No fuel data available';

  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    scales: {
      y: {
        // stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      x: {
        suggestedMax: Math.max(gallonsPurchased, gallonsAddedToTank) + 3,
        grid: {
          display: false,
        },
        stacked: true,
      },
    },
    plugins: {
      datalabels: {
        display: true,
        formatter: (value, context) => {
          // Internal gallons label - don't show this.
          if (context.dataset.label?.startsWith('[INTERNAL]')) {
            return '';
          }

          // Uppar bar label, galons purchase. We always show the number here.
          if (context.dataset.label === 'Gallons purchased') {
            return `${gallonsPurchased.toFixed(1)} gal`;
          }

          // We show the number here only if the value is available
          return fuelDataAvailable ? `${value.toFixed(1)} gal` : 'No data available';
        },
        align: 'end',
        anchor: 'end',
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: true,
        caretSize: 0,
        displayColors: false,
        filter: (tooltipItem) =>
          tooltipItem.dataset.label === 'Gallons purchased' && gallonsMissing > 0,
        callbacks: {
          label: () => resultDisplay,
          title: () => '',
        },
      },
    },
  };

  const labels = ['Gallons'];
  const datasets =
    gallonsMissing > 0
      ? [
          {
            label: '[INTERNAL] Gallons added to tank (Estimated)',
            data: [gallonsAddedToTank],
            borderWidth: 2,
            borderColor: '#182029',
            backgroundColor: ['#182029'],
            stack: 'Stack 0',
          },
          {
            label: 'Gallons purchased',
            data: [gallonsMissing],
            borderWidth: 2,
            borderColor: '#182029',
            backgroundColor: [gallonsMissing ? '#F5CA97' : '#182029'],
            stack: 'Stack 0',
          },
          {
            label: 'Gallons added to tank (Estimated)',
            data: [gallonsAddedToTank],
            backgroundColor: ['#98A8B2'],
            stack: 'Stack 1',
          },
        ]
      : [
          {
            label: 'Gallons purchased',
            data: [gallonsPurchased],
            borderWidth: 2,
            borderColor: '#182029',
            backgroundColor: ['#182029'],
            stack: 'Stack 0',
          },
          {
            label: fuelDataAvailable ? 'Gallons added to tank (Estimated)' : 'No data available',
            data: [fuelDataAvailable ? gallonsPurchased : 0],
            backgroundColor: ['#98A8B2'],
            stack: 'Stack 1',
          },
        ];

  return (
    <div className="flex flex-col gap-2 rounded-lg bg-white p-6 md:h-96">
      <div className="flex flex-row gap-4">
        <div className="flex flex-col gap-4">
          <div className="flex flex-row">
            <div className="mr-2 font-semibold">Fuel Level Check</div>
            <div className="items-end text-gray-300">
              <Tooltip title={TOOLTIP_TEXT}>
                <div>
                  <FontAwesomeIcon icon={faInfoCircle} />
                </div>
              </Tooltip>
            </div>
          </div>
          <div className="flex flex-row gap-4">
            <div className="text-[12px]">
              <span className="font-semibold">Result: </span>
              {resultDisplay}
            </div>
          </div>
        </div>

        <div className="flex flex-1 items-start justify-end">
          {fuelDataAvailable ? (
            is_suspicious ? (
              <TelematicsStatus suspicious />
            ) : (
              <TelematicsStatus passed />
            )
          ) : (
            <TelematicsStatus unavailable />
          )}
        </div>
      </div>

      {/* The chart */}
      <div className="mt-4 flex hidden flex-col rounded-lg bg-slate-100 pb-4 pt-6 md:block md:h-64 md:px-4">
        <div className="mx-2 flex flex-row gap-4 text-[12px] text-sm md:mb-4 md:mr-2">
          <div className="flex flex-row items-center">
            <FontAwesomeIcon icon={faCircle} className="mr-2 h-1.5 w-1.5" color="#182029" />
            Gallons purchased
          </div>
          <div className="flex flex-row items-center">
            <FontAwesomeIcon icon={faCircle} className="mr-2 h-1.5 w-1.5" color="#98A8B2" />
            Gallons added to the tank (Estimated)
          </div>
        </div>
        <div className="relative mr-2 md:w-auto">
          <Bar
            data={{
              labels,
              datasets,
            }}
            options={options}
          />
        </div>
      </div>
    </div>
  );
};

export default ChargeEventFuelLevelsChart;
