import { useIsUberfreight } from '@app/hooks/partners/useIsUberfreight';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import classNames from 'classnames';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

const PayWithAtoBModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}): ReactElement => {
  const navigate = useNavigate();
  const isUF = useIsUberfreight(true);

  const closeButton = (
    <IconButton className="absolute right-4 top-4" onClick={onClose}>
      <FontAwesomeIcon icon={faXmark} className="h-5 w-5" />
    </IconButton>
  );

  return (
    <Dialog maxWidth="xs" open={open} onClose={onClose}>
      <div className="flex flex-row items-center justify-center">
        <div
          className={classNames(
            isUF
              ? 'bg-onboarding-pay-with-uber-freight-header'
              : 'bg-onboarding-pay-with-atob-header',
            'h-[224px] w-[500px] bg-cover bg-center md:h-[300px]',
          )}
        />
        <div className="absolute right-0 top-0">{closeButton}</div>
      </div>
      <DialogTitle>
        <h3 className="text-xl font-bold md:text-2xl">
          Pay with {isUF ? 'Uber Freight Card' : 'AtoB'}
        </h3>
      </DialogTitle>
      <DialogContent>
        <div className="text-sm font-normal">
          To get discounts on fuel purchases, simply pay with your{' '}
          {isUF ? 'Uber Freight Card' : 'AtoB card'}. Get out there and give it a try, then come
          back to see how much you saved!
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          fullWidth
          onClick={() => {
            navigate('/transactions');
          }}
        >
          Check Transactions
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PayWithAtoBModal;
