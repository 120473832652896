import { PaginationMeta } from '@app/@types/api.types';

export type SortBy = {
  field: string | null;
  order: 'asc' | 'desc' | null | undefined;
};

export type SearchBy = {
  field?: string;
  value: string;
};

export type FilterBy = {
  field: string;
  op: 'equal' | 'not-equal';
  value: unknown;
};

export type GoToPageAction = (pageIndex: number) => void;
export type SortByAction = (sortBy: SortBy | null) => void;
export type SearchByAction = (searchBy: SearchBy | null) => void;
export type FilterByAction = (filterBy: FilterBy[]) => void;

export type Page<T extends Record<string, unknown>> = {
  index: number;
  data: T[];
};

export type Metadata = {
  total: number;
  pages: number;
  pageSize: number;
};

export type Paging<T extends Record<string, unknown>> = {
  /**
   * @deprecated The Page type is a deprecated concept. Use the `data` property instead to get access to the rows directly.
   */
  page: Page<T> | null;
  /**
   * @deprecated The `Metadata` type is a deprecated concept. Use `PaginationMeta` instead which is supported by TableV2.
   */
  metadata: Metadata | null;
  data: T[];
  paginationMeta: PaginationMeta | null;
  error: unknown;
  loading: boolean;
  goToPage: GoToPageAction;
  sortBy: SortByAction;
  searchBy: SearchByAction;
  filterBy: FilterByAction;
};

export default Paging;

export const DEFAULT_PAGE_SIZE = 25;
