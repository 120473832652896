import { EndpointResponse } from '@app/@types/api.types';
import { PlanResponse } from '@app/@types/subscriptions.types';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import useSWRImmutable from 'swr/immutable';

const PLANS_QUERY_KEY = {
  url: '/subscriptions/plans',
};
export default function usePlans() {
  return useSWRImmutable<EndpointResponse<PlanResponse[]>>(PLANS_QUERY_KEY, apiGetFetcher);
}
