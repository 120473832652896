import { PartnerLogo } from '@app/components/layout/PartnerLogo';
import { ReactElement, ReactNode } from 'react';

const AppViewOverrideContainer = ({ children }: { children: ReactNode }): ReactElement => {
  return (
    <div className="flex flex-col">
      <div className="px-6 pt-6 md:border-b-[1px] md:border-gray-300 md:pb-6 md:pl-20">
        <PartnerLogo className="block h-8 w-auto" />
      </div>

      <div className="p-6 md:mt-12 md:text-center">{children}</div>
    </div>
  );
};

export default AppViewOverrideContainer;
