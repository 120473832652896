import { DayOfWeek } from '@app/hooks/query/useOperationalPeriods';
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import dayjs from 'dayjs';
import { ReactElement } from 'react';
import {
  FULL_DAY_VALUE,
  MAXIMUM_INTERVALS,
  MENU_MAX_HEIGHT,
  TIME_INTERVAL_STEP,
} from './OperationalPeriods';

export const DISABLED_OPACITY = 0.5;
export const INITIAL_OPACITY = 1;

const formatMinutesAsTime = (minutes: number) => {
  const date = new Date();
  date.setHours(Math.floor(minutes / 60));
  date.setMinutes(minutes % 60);
  return dayjs(date).format('h:mm a');
};

type TimeSelectProps = {
  label: string;
  dayLabel: string;
  day: DayOfWeek;
  value: number | '';
  minimum?: number;
  onChange: (event: SelectChangeEvent<number>) => void;
  disabled?: boolean;
  maximum?: number;
};

export const TimeSelect = ({
  label,
  day: dayLabel,
  value,
  onChange,
  minimum,
  disabled,
  maximum = MAXIMUM_INTERVALS,
}: TimeSelectProps): ReactElement => {
  return (
    <FormControl>
      <InputLabel size="small">{label}</InputLabel>
      <Select
        value={value}
        label={label}
        onChange={onChange}
        name={`${dayLabel}`}
        MenuProps={{
          style: {
            maxHeight: MENU_MAX_HEIGHT,
          },
        }}
        disabled={disabled}
        placeholder="-"
        style={{ opacity: disabled ? DISABLED_OPACITY : INITIAL_OPACITY }}
        size="medium"
      >
        <MenuItem value={FULL_DAY_VALUE}>24 Hours</MenuItem>
        <Divider />
        {Array.from({ length: maximum }, (_, i) => i * TIME_INTERVAL_STEP).map((minute) => {
          if (minimum !== undefined && minute < minimum + 1) return null;

          return (
            <MenuItem key={minute} value={minute}>
              {formatMinutesAsTime(minute)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
