import dayjs from 'dayjs';
import { TopUpTransaction } from './RecentTopUps';

export function isTopUpWithinOneMinute(mostRecentTopUp: TopUpTransaction | null): boolean {
  if (!mostRecentTopUp) {
    return false;
  }

  const topUpTimestamp = dayjs.unix(mostRecentTopUp.created_timestamp);
  const now = dayjs();

  const timeDiffInMinutes = now.diff(topUpTimestamp, 'minute');

  return mostRecentTopUp.initiation_type === 'manual' && timeDiffInMinutes < 1;
}
