import useOmniAuth from '@app/hooks/useOmniAuth';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import axios from 'axios';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import Auth from './Auth';

const Auth0Strategy = ({ children }: { children: React.ReactNode }): ReactElement => {
  const domain = import.meta.env.VITE_AUTH0_DOMAIN;
  const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID;
  const audience = import.meta.env.VITE_AUTH0_AUDIENCE;
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    const returnTo = appState?.returnTo;
    if (returnTo) {
      navigate(returnTo);
    }
  };

  return (
    <Auth0Provider
      domain={String(domain)}
      clientId={String(clientId)}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: String(audience),
      }}
      useRefreshTokens
      useRefreshTokensFallback
      // eslint-disable-next-line @cspell/spellchecker
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
    >
      <Auth>{children}</Auth>
    </Auth0Provider>
  );
};

const OmniAuthStrategy = ({ children }: { children: React.ReactNode }): ReactElement => {
  const { token, provider } = useOmniAuth();

  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    // If token expires, automatically redirect user to log in
    setTimeout(() => {
      window.location.href = `${String(import.meta.env.VITE_API_URL)}/auth/${provider}/start`;
    }, 0);
  }

  return <>{children}</>;
};

const AuthProvider = ({ children }: { children: React.ReactNode }): ReactElement => {
  const { provider } = useOmniAuth();

  if (provider) {
    return <OmniAuthStrategy>{children}</OmniAuthStrategy>;
  }

  return <Auth0Strategy>{children}</Auth0Strategy>;
};

export default AuthProvider;
