import { PlaidParams, PlaidSuccessAndEventReturnType } from '@app/@types/plaid.types';
import CustomerContext from '@app/contexts/customerContext';
import PlaidContext from '@app/contexts/plaidContext';
import logger from '@app/utils/datadog-logger';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { useCallback, useContext } from 'react';
import {
  PlaidLinkOnSuccess,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOnEvent,
  PlaidLinkOnEventMetadata,
  PlaidLinkStableEvent,
} from 'react-plaid-link';

const noOp = () => {};

// onSuccess & onEvent callbacks for connecting to a new bank and pending bank account verification
const usePlaidConnectHandler = (): PlaidSuccessAndEventReturnType => {
  const { customer } = useContext(CustomerContext);
  const {
    setError = noOp,
    onSuccessCallback = noOp,
    onLinkingCallback = noOp,
    flow = undefined,
  } = useContext(PlaidContext);

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    (publicToken: string, metadata: PlaidLinkOnSuccessMetadata) => {
      const params: PlaidParams = {
        public_token: publicToken,
      };

      let action;

      const verificationStatusConditions = [
        'verification_expired',
        'verification_failed',
        'pending_automatic_verification',
      ];

      switch (metadata.accounts.length) {
        case 0:
          // Select Account is disabled: https://dashboard.plaid.com/link/account-select
          Sentry.captureMessage('Plaid - Select Account is disabled');
          break;
        case 1:
          // Customer selected account
          params.account_id = metadata.accounts[0].id;

          if (metadata.accounts[0].verification_status === 'pending_manual_verification') {
            action = 'initiate_microdeposits_flow';
          } else if (
            metadata.accounts[0].verification_status === 'pending_automatic_verification'
          ) {
            action = 'initiate_automated_microdeposits_flow';
          } else {
            action = 'link_bank_account';
          }

          if (
            verificationStatusConditions.indexOf(metadata.accounts[0].verification_status) !== -1
          ) {
            Sentry.withScope((scope) => {
              scope.setContext('plaid link', {
                customer: customer.id,
                account: metadata.accounts[0],
                institution: metadata.institution,
                link_session_id: metadata.link_session_id,
              });
              Sentry.captureMessage(
                `Plaid Link onSuccess: ${metadata.accounts[0].verification_status}`,
              );
            });
          }

          break;
        default:
          // Multiple Accounts is enabled: https://dashboard.plaid.com/link/account-select
          Sentry.captureMessage('Plaid - Multiple Accounts is enabled');
      }

      onLinkingCallback('START');

      axios
        .post(`/plaid/${action}`, params)
        .then(() => {
          onSuccessCallback();
        })
        .catch((e) => {
          const identifierContext = {
            user: {
              request_id: e?.response?.headers?.['x-request-id'],
              customer_email: customer.owner_email,
            },
          };
          if (e?.response?.data?.errors?.length !== 0) {
            e.response.data.errors.forEach((error: Record<string, unknown>) => {
              if (error.code === 'NO_AUTH_ACCOUNTS') {
                Sentry.captureException(e, identifierContext);
                setError(
                  new Error('There are no valid checking or savings account(s) associated.'),
                );
              } else {
                Sentry.captureException(e, identifierContext);
                setError(e);
              }
            });
          } else {
            Sentry.captureException(e, identifierContext);
            setError(e);
          }
        })
        .finally(() => onLinkingCallback('END'));
    },
    [onLinkingCallback, onSuccessCallback, setError, customer],
  );

  const onEvent = useCallback<PlaidLinkOnEvent>(
    (eventName: PlaidLinkStableEvent | string, metadata: PlaidLinkOnEventMetadata) => {
      logger.info('PlaidLink Event', {
        name: eventName,
        metadata: metadata,
        flow: flow,
        customer_id: customer.id,
        update_mode: false,
      });
      if (eventName === 'ERROR') {
        Sentry.withScope((scope) => {
          scope.setContext('plaid link', {
            customer: customer.id,
            eventName,
            error_type: metadata.error_type,
            error_code: metadata.error_code,
            error_message: metadata.error_message,
            institution: {
              name: metadata.institution_name,
              id: metadata.institution_id,
            },
          });
          Sentry.captureMessage(`Plaid Link onEvent: ${eventName}`);
        });
      }
    },
    [customer.id, flow],
  );

  return { onSuccess, onEvent };
};

// onSuccess & onEvent callbacks for connecting to a new bank and pending bank account verification
const usePlaidUpdateHandler = (updateBankAccountId: string): PlaidSuccessAndEventReturnType => {
  const { customer } = useContext(CustomerContext);
  const {
    onSuccessCallback = noOp,
    onLinkingCallback = noOp,
    setError = noOp,
    flow = undefined,
  } = useContext(PlaidContext);

  const onSuccess = useCallback<PlaidLinkOnSuccess>(() => {
    onLinkingCallback('START');
    axios
      .get('/plaid/balance', {
        params: { skip_limit: true, bank_account_id: updateBankAccountId },
      })
      .then(() => {
        onSuccessCallback();
      })
      .catch((e) => {
        Sentry.captureException(e, {
          user: {
            request_id: e?.response?.headers['x-request-id'],
            customer_email: customer.owner_email,
          },
        });

        // eslint-disable-next-line no-unused-expressions
        setError && setError(e);
      })
      .finally(() => onLinkingCallback('END'));
  }, [updateBankAccountId, onLinkingCallback, onSuccessCallback, setError, customer]);

  const onEvent = useCallback<PlaidLinkOnEvent>(
    (eventName: PlaidLinkStableEvent | string, metadata: PlaidLinkOnEventMetadata) => {
      logger.info('PlaidLink Event', {
        name: eventName,
        metadata: metadata,
        flow: flow,
        customer_id: customer.id,
        update_mode: true,
      });
      if (eventName === 'ERROR') {
        Sentry.withScope((scope) => {
          scope.setContext('PlaidLinkUpdate Error', {
            customer: customer.id,
            eventName,
            error_type: metadata.error_type,
            error_code: metadata.error_code,
            error_message: metadata.error_message,
            institution: {
              name: metadata.institution_name,
              id: metadata.institution_id,
            },
          });
          Sentry.captureMessage(`Plaid Link Update onEvent: ${eventName}`);
        });
      }
    },
    [customer, flow],
  );

  return { onSuccess, onEvent };
};

export { usePlaidConnectHandler, usePlaidUpdateHandler };
