import { ErrorNotification, Loading } from '@app/components/layout';
import PageContentWrapper from '@app/components/wrappers/PageContentWrapper';
import useError from '@app/hooks/useError';
import { SectionTitle } from '@app/pages/AccountOverview/AccountOverviewShared';
import ApiEndpoints from '@app/utils/data/apiEndpoints';
import axios from 'axios';
import { deserialize } from 'deserialize-json-api';
import { ReactElement, useEffect, useState } from 'react';
import ReferralHowItWorksCard from '../ReferralHowItWorksCard';
import ReferralShareCard from '../ReferralShareCard';
import ReferralYourReferralsCard from '../ReferralYourReferralsCard';

export default function ReferralsView({ isPrepaid }: { isPrepaid: boolean }): ReactElement {
  const [error, setError] = useError(() => false);
  const [referralsSummary, setReferralsSummary] = useState(null);
  const [referralLink, setReferralLink] = useState('');

  useEffect(() => {
    axios
      .get(ApiEndpoints.REFERRALS_ENDPOINTS.SUMMARY_ENDPOINT)
      .then((response) => {
        const { data } = deserialize(response.data);

        setReferralsSummary(data);
        setReferralLink(data.referral_link);
      })
      .catch((e) => setError(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return <ErrorNotification error={(error as Error).message} />;
  }

  if (!referralsSummary) {
    return <Loading />;
  }

  return (
    <PageContentWrapper>
      {/* Referrals won't sign up under the partner, so this is should refer to the AtoB brand */}
      {/* eslint-disable-next-line no-explicit-atob-mentions */}
      <SectionTitle
        title={`Refer AtoB to a friend and earn ${isPrepaid ? 'discounts' : 'statement credit'}!`}
      />
      <div>
        <div className="flex flex-col justify-between py-4 md:flex-row">
          <div className="w-full md:w-[50%]">
            <ReferralShareCard referralLink={referralLink} setError={setError} />
          </div>

          <div className="w-full md:w-[50%]">
            <ReferralHowItWorksCard isPrepaid={isPrepaid} />
          </div>
        </div>
        <div className="mb-6">
          <div className="w-full md:w-[50%]">
            <ReferralYourReferralsCard referralsSummary={referralsSummary} isPrepaid={isPrepaid} />
          </div>
        </div>
      </div>
    </PageContentWrapper>
  );
}
