import { EndpointResponse } from '@app/@types/api.types';
import { UserData } from '@app/@types/users.types';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import { SWRResponse } from 'swr';
import useSWRImmutable from 'swr/immutable';
/**
 * Fetches the customer.
 *
 * Do not use this directly - instead use `useCustomer()`
 */
export const useUserQuery = (skip = false): SWRResponse<EndpointResponse<UserData>> => {
  return useSWRImmutable<EndpointResponse<UserData>>(
    !skip
      ? {
          url: '/users/me/',
        }
      : null,
    apiGetFetcher,
  );
};
