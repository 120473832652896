import logger from '@app/utils/datadog-logger';
import Modal from '@atob-developers/shared/src/components/Modal';
import axios from 'axios';
import { LoadingSpinner } from 'baseui/button/styled-components';
import { ReactElement, useState, useEffect } from 'react';
import OneTimePassword from '../components/OneTimePassword/OneTimePassword';

export default function PreemptiveOTPValidator({
  children,
  onCloseWithoutVerification,
}: {
  children: ReactElement;
  onCloseWithoutVerification: () => void;
}): ReactElement {
  const [isOpen, setIsOpen] = useState(false);
  const [verificationNeeded, setVerificationNeeded] = useState<boolean | null>(null);

  useEffect(() => {
    const checkVerificationNeeded = async () => {
      try {
        const { data: response } = await axios.get('/security/mfa/is_verification_needed');
        setVerificationNeeded(response.verification_needed);
        if (response.verification_needed) {
          setIsOpen(true);
        }
      } catch (e: unknown) {
        logger.error('Error checking OTP verification: ', {
          error: e,
        });
        setVerificationNeeded(true);
        setIsOpen(true);
      }
    };

    checkVerificationNeeded();
  }, []);

  const toggleOpen = (isOpen: boolean) => {
    if (isOpen == false && verificationNeeded == true) {
      onCloseWithoutVerification();
    }
    setIsOpen(isOpen);
  };

  if (verificationNeeded === null) {
    return (
      <Modal open={true} toggle={() => {}}>
        <LoadingSpinner />
      </Modal>
    );
  } else if (verificationNeeded) {
    return (
      <OneTimePassword
        setIsOpen={toggleOpen}
        isOpen={isOpen}
        onSuccess={() => setVerificationNeeded(false)}
      />
    );
  } else {
    return children;
  }
}
