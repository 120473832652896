import NoTransactions from '@app/assets/images/svg/no-transactions.svg';
import IFTANudgeBanner from '@app/components/Banner/IFTANudgeBanner';
import ExportTransactionsContainer from '@app/components/Exporting/Transactions/ExportTransactionsContainer';
import Header from '@app/components/Navigation/Header';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import { ErrorNotification } from '@app/components/layout';
import PageContentWrapper from '@app/components/wrappers/PageContentWrapper';
import { TransactionsFilters } from '@app/constants/transactions';
import {
  ChargeEventQueryParams,
  ChargeEventsContext,
} from '@app/contexts/ChargeEventsContextComponent';
import useChargeEvents from '@app/hooks/useChargeEvents';
import useCustomer from '@app/hooks/useCustomer';
import EmptyTable from '@atob-developers/shared/src/components/EmptyTable';
import { ReactElement, useCallback, useContext } from 'react';
import TransactionFilters from './TransactionFilters';
import TransactionTabs from './TransactionTabs';
import TransactionsList from './TransactionsList';

function Transactions(): ReactElement {
  const { queryParams, setQueryParams } = useContext(ChargeEventsContext);
  const { data: chargeEvents, error, isLoading } = useChargeEvents('/charge_events', queryParams);
  const customer = useCustomer();

  const currentTab = queryParams['tab'] || TransactionsFilters.ALL;

  const handleFilter = useCallback(
    (params: Partial<ChargeEventQueryParams>) => {
      setQueryParams((prevParams) => ({ ...prevParams, ...params, page: 1 }));
    },
    [setQueryParams],
  );

  if (error) {
    return <ErrorNotification error={error?.message} />;
  }

  const data = chargeEvents?.data;

  let view;
  if (!isLoading && (!data || data.length === 0)) {
    view = (
      <RoundedCard breakpoint="md">
        <TransactionFilters selectedFilters={queryParams} setSelectedFilters={handleFilter} />
        <EmptyTable
          imgComponent={<img src={NoTransactions} alt="" />}
          title="No transactions found"
          subtitle={
            currentTab === TransactionsFilters.APPROVED
              ? 'Transactions will display here when you start using this card'
              : 'No declined transactions'
          }
        />
      </RoundedCard>
    );
  } else {
    view = (
      <RoundedCard breakpoint="md">
        <TransactionFilters selectedFilters={queryParams} setSelectedFilters={handleFilter} />
        <TransactionsList
          rows={chargeEvents?.data || []}
          meta={chargeEvents?.meta}
          isLoading={isLoading}
          currentTab={currentTab}
          handlePageChangeClick={(page) =>
            setQueryParams((prevParams) => ({ ...prevParams, page }))
          }
          cardSecurityEnabled={customer.card_security_enabled}
          pageNavPrefix=""
        />
      </RoundedCard>
    );
  }
  return (
    <PageContentWrapper
      header={
        <>
          <Header
            title={'Transactions'}
            rightContent={<ExportTransactionsContainer selectedFilters={queryParams} />}
          />
          <div className="px-4 md:px-0">
            <IFTANudgeBanner hideTransactionsBump />
          </div>
        </>
      }
      pageTabs={
        <TransactionTabs
          currentTab={currentTab}
          setCurrentTab={(tab, authApproved) =>
            handleFilter({ ...queryParams, tab, auth_approved: authApproved })
          }
        />
      }
    >
      {view}
    </PageContentWrapper>
  );
}

export default Transactions;
