import { FuelPrice, PriceListingType } from '@app/@types/fuel_listings.types';
import { formatFuelPrice } from '@app/utils/fuel-listings';
import { MobileFriendlyTooltip } from '@atob-developers/shared/src/components/MobileFriendlyTooltip';
import { SavingIcon } from '@atob-developers/shared/src/icons';
import { usePriceListingType } from './usePriceListingType';

export default function FuelListingPrice({ price }: { price: FuelPrice }) {
  const {
    discount_type,
    discount_per_gallon_cents,
    fuel_sign_price_cents,
    effective_cost_per_gallon_cents,
  } = price.attributes;

  const isGuaranteedDiscount = discount_type === 'RETAIL_MINUS';

  const effectiveCostPerGallon = formatFuelPrice(effective_cost_per_gallon_cents);
  const retailFuelPrice = formatFuelPrice(fuel_sign_price_cents);

  const priceListingType = usePriceListingType(price);

  const tooltip = (
    <MobileFriendlyTooltip
      arrow
      placement="top"
      title={
        isGuaranteedDiscount
          ? 'This is your confirmed cents-per-gallon discount applied to the retail pump price, which may differ from the price shown.'
          : "This is the confirmed discounted price you'll see on your AtoB statement, regardless of the retail price at the pump."
      }
    >
      <SavingIcon className="inline-block translate-y-[1px] transform align-baseline" />
    </MobileFriendlyTooltip>
  );

  if (priceListingType === PriceListingType.NO_DISCOUNT_VALID_PRICE) {
    return <p className="text-primary font-semibold leading-5">${retailFuelPrice}</p>;
  }

  if (priceListingType === PriceListingType.NO_DISCOUNT_INVALID_PRICE) {
    return <p className="text-primary font-semibold leading-5">--</p>;
  }

  if (priceListingType === PriceListingType.GUARANTEED_DISCOUNT_VALID_PRICE) {
    return (
      <>
        <p className="text-primary inline-flex items-baseline gap-1 font-semibold leading-5">
          <span className="text-secondary text-xs font-normal line-through">
            ${retailFuelPrice}
          </span>
          ${effectiveCostPerGallon}
        </p>
        <div className="text-accent-10 inline-flex items-baseline gap-1">
          {tooltip}
          <p className="text-xs">{discount_per_gallon_cents}¢ off</p>
        </div>
      </>
    );
  }

  if (priceListingType === PriceListingType.GUARANTEED_DISCOUNT_INVALID_PRICE) {
    return (
      <>
        <p className="text-primary font-semibold leading-5">--</p>
        <div className="text-accent-10 inline-flex items-baseline gap-1">
          {tooltip}
          <p className="text-xs">{discount_per_gallon_cents}¢ off</p>
        </div>
      </>
    );
  }

  if (priceListingType === PriceListingType.GUARANTEED_PRICE_VALID_PRICE) {
    return (
      <>
        <div className="text-accent-10 inline-flex items-baseline">
          <p className="text-secondary mr-1 align-text-bottom text-xs font-normal leading-4 line-through">
            ${retailFuelPrice}
          </p>
          {tooltip}
          <p className="ml-[2px] font-semibold leading-5">${effectiveCostPerGallon}</p>
        </div>
        <p className="text-primary text-xs">{discount_per_gallon_cents}¢ off</p>
      </>
    );
  }

  if (priceListingType === PriceListingType.GUARANTEED_PRICE_INVALID_PRICE) {
    return (
      <div className="text-accent-10 flex items-center gap-1">
        {tooltip}
        <p className="font-semibold leading-5">${effectiveCostPerGallon}</p>
      </div>
    );
  }

  return null;
}
