import FleetioLogo from '@app/assets/images/svg/fleetio-logo.svg';
import Modal, { ModalBodyContent, ModalFooter } from '@atob-developers/shared/src/components/Modal';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { Button, TextField } from '@mui/material';
import axios from 'axios';
import { ReactElement, useState } from 'react';

export default function FleetioConnected({
  accountToken,
  setFleetioConnection,
}: {
  accountToken: string;
  setFleetioConnection: React.Dispatch<
    React.SetStateAction<{
      account_token: string;
    }>
  >;
}): ReactElement {
  const [openModal, toggleModal] = useState(false);
  const { addToast } = useToasts();
  const disconnect = () => {
    axios.patch('/fleetio/disconnect').then(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      setFleetioConnection(null);
      addToast('Successfully disconnected Fleetio', {
        appearance: 'success',
      });
    });
  };
  return (
    <div className="flex w-full justify-center">
      <div className="w-[100%] rounded-md bg-white p-8 lg:w-[50%]">
        <div className="flex justify-center pb-12 pt-8">
          <img src={FleetioLogo} alt="Fleetio logo" />
        </div>
        <div className="flex justify-center pb-16">
          <div className="w-full max-w-[500px] flex-col">
            <label className="mb-2 text-base font-bold">Account Token</label>
            <TextField fullWidth size="small" disabled placeholder={accountToken} />
            <div className="flex w-full justify-center">
              <Button size="small" onClick={() => toggleModal(true)}>
                Disconnect
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Modal open={openModal} toggle={() => toggleModal(false)}>
        <ModalBodyContent>
          <div className="pb-4 text-center font-semibold">Disconnect Fleetio?</div>
          <div className="text-center">
            By disconnecting, fuel and vehicle data will no longer be synced between your Fleetio
            and AtoB accounts.
          </div>
        </ModalBodyContent>
        <ModalFooter className="flex space-x-4">
          <Button size="small" color="secondary" onClick={() => toggleModal(false)}>
            Cancel
          </Button>
          <Button
            size="small"
            onClick={() => {
              disconnect();
              toggleModal(false);
            }}
          >
            Disconnect
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
