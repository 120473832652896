import { useCallback, useMemo, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

type UrlField = string | string[] | boolean | number | null | undefined;
type UrlHash = Record<string, UrlField>;

export function useSingleUrlState<T extends UrlField>(
  key: string,
  initialState: T,
): [T, (state: T) => void] {
  const [fullState, setFullState] = useUrlState<Record<string, T>>({ [key]: initialState });

  const wrappedSetFullState = useCallback(
    (newState: T) => {
      setFullState({ [key]: newState });
    },
    [key, setFullState],
  );

  return [fullState[key], wrappedSetFullState];
}

// Hook meant as a replacement for useState if you also want to save the state as a URL query param
// This can only accept a key value pair with the value being either a string, boolean or array of string
// Referenced https://ahooks.js.org/hooks/use-url-state/
export function useUrlState<T extends UrlHash>(
  initialState: T,
): [T, (s: React.SetStateAction<T>) => void] {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const initialStateRef = useRef(
    typeof initialState === 'function' ? (initialState as () => T)() : initialState || {},
  );

  const queryFromUrl = useMemo(() => {
    return Array.from(searchParams.keys()).reduce(
      (acc, key) =>
        key.endsWith('[]')
          ? Object.assign(acc, {
              [key.replace('[]', '')]: searchParams.get(key)?.split(','),
            })
          : Object.assign(acc, {
              [key]: deserialize(searchParams.get(key) || ''),
            }),
      {},
    );
  }, [searchParams]);

  const targetQuery: T = useMemo(() => {
    return {
      ...initialStateRef.current,
      ...queryFromUrl,
    } as T;
  }, [queryFromUrl]);

  const stringify = useCallback((values: T): string => {
    return Object.entries(values)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return value.length > 0 ? `${key}[]=${value.join(',')}` : '';
        }
        if (value != null && value !== '') {
          value = encodeURIComponent(value as string);
          return `${key}=${value}`;
        }
        return '';
      })
      .filter((param) => param.length > 0)
      .join('&');
  }, []);

  const setState = useCallback(
    (s: React.SetStateAction<T>) => {
      const newQuery = typeof s === 'function' ? s(targetQuery) : s;
      navigate({
        search: stringify({ ...queryFromUrl, ...newQuery }),
      });
    },
    [navigate, queryFromUrl, stringify, targetQuery],
  );

  return [targetQuery, setState];
}

function deserialize(value: string) {
  if (value === 'true' || value === 'false') {
    return JSON.parse(value);
  }
  return value;
}
