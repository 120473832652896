import { BankAccountPaymentMethodCombined } from '@app/@types/bankAccount.types';
import { DebitCardPaymentMethodCombined } from '@app/@types/debitCard.types';
import { Address } from '@app/components/elements/AddressInput';
import { usePaymentMethodsQuery } from '@app/hooks/query/usePaymentMethodsQuery';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { deserialize } from 'deserialize-json-api';
import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { mapBankAndDebitCardData } from '../../../../components/PaymentMethods/PaymentMethodUtils';
import {
  AddRecipientAccount,
  RecipientData,
  RecipientToCreate,
  RecipientToEdit,
} from './transfer.types';

export const addRecipient = async (recipient: RecipientToCreate): Promise<RecipientData> => {
  const response = await axios.post('/treasury/recipients', {
    billing_details: {
      name: recipient.billing_details.name,
      address: {
        city: recipient.billing_details.address?.city,
        zip: recipient.billing_details.address?.zip,
        state: recipient.billing_details.address?.state,
        address1: recipient.billing_details.address?.address1,
        address2: recipient.billing_details.address?.address2,
      },
    },
    us_bank_account: {
      account_number: recipient.us_bank_account.account_number,
      routing_number: recipient.us_bank_account.routing_number,
      account_holder_type: recipient.us_bank_account.account_holder_type,
    },
    display_name: recipient.display_name,
  });

  return deserialize(response.data).data;
};

type RecipientsResponse = {
  data: {
    data: {
      id: string;
      type: string;
      attributes: {
        display_name: string;
        billing_details: {
          name: string;
          address: Address;
        };
        payment_details: {
          bank_account: {
            account_holder_type: string;
            institution_name: string;
            mask: string;
            supported_networks: string[];
          };
        };
      };
    }[];
  };
};

const fetchRecipients = () => {
  return axios.get<RecipientsResponse>('/treasury/recipients');
};

export default function WalletPaymentMethods({
  children,
}: {
  children: ({
    inboundBankAccounts,
    recipients,
    addRecipientAccount,
    debitCards,
    addDebitCard,
    loading,
    fetchPaymentMethods,
  }: {
    inboundBankAccounts: BankAccountPaymentMethodCombined[];
    recipients: RecipientData[];
    addRecipientAccount: AddRecipientAccount;
    debitCards: DebitCardPaymentMethodCombined[];
    addDebitCard: (token: string) => void;
    loading: boolean;
    fetchPaymentMethods: (isInbound: boolean) => Promise<void>;
    editRecipient: (recipient: RecipientToEdit) => Promise<void>;
  }) => ReactElement;
}): ReactElement {
  const [recipients, setRecipients] = useState<RecipientData[]>([]);

  const {
    isLoading: isPaymentMethodsLoading,
    error: paymentMethodsError,
    data: paymentMethodsData,
    mutate: requeryPaymentMethods,
  } = usePaymentMethodsQuery();

  const isPaymentMethodsInitialized = useRef(false);

  const fetchPaymentMethods = useCallback(
    async (isInbound: boolean) => {
      requeryPaymentMethods();
      if (!isInbound) {
        try {
          const [recipientResponse] = await Promise.all([
            fetchRecipients(),
            // TODO: add back in when backend is ready PAYRO-791
            // axios.get('/treasury/financial_account/external_account'),
          ]);
          const { data } = deserialize(recipientResponse.data);
          // const { data: debitCardData } = deserialize(externalAccountResponse.data);
          setRecipients(data);
        } catch (e: unknown) {
          Sentry.captureException(e);
        }
      }
    },
    [requeryPaymentMethods],
  );

  const { combinedPaymentMethods } = useMemo(
    () => mapBankAndDebitCardData(paymentMethodsData?.data || []),
    [paymentMethodsData?.data],
  );
  const inboundBankAccounts = combinedPaymentMethods.bankAccounts;
  const debitCards = combinedPaymentMethods.debitCards || [];

  useEffect(() => {
    if (paymentMethodsError && !isPaymentMethodsInitialized.current) {
      isPaymentMethodsInitialized.current = true;
      Sentry.captureException(paymentMethodsError);
    }
  }, [paymentMethodsData, paymentMethodsError]);

  useEffect(() => {
    fetchPaymentMethods(true);
    fetchPaymentMethods(false);
  }, [fetchPaymentMethods]);

  const addRecipientAccount = async (recipientToAdd: RecipientToCreate) => {
    const recipient = await addRecipient(recipientToAdd);
    setRecipients([...recipients, recipient]);
  };

  const addDebitCard = async (token: string) => {
    await axios.post('/treasury/financial_account/external_account', {
      token,
    });
    requeryPaymentMethods();
  };

  const editRecipient = async (recipient: RecipientToEdit) => {
    await axios.patch(`/treasury/recipients/${recipient.id}`, { ...recipient });
    await fetchPaymentMethods(false);
  };

  return children({
    inboundBankAccounts,
    recipients,
    addRecipientAccount,
    debitCards,
    addDebitCard,
    loading: isPaymentMethodsLoading,
    fetchPaymentMethods,
    editRecipient,
  });
}
