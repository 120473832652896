import { ReactElement } from 'react';
import Plaid from '../Plaid';
import { WarningBanner } from './Designs/WarningBanner';

const MicrodepositVerificationBanner = ({
  setSuccessfullyLinkedBankAccount,
  error,
  setError,
  microDepositVerificationId,
  successfullyLinkedBankAccount,
}: {
  setSuccessfullyLinkedBankAccount: (isAuthenticated: boolean) => void;
  error: string | null;
  setError: (error: string) => void;
  microDepositVerificationId: string;
  successfullyLinkedBankAccount: boolean;
}): ReactElement | null => {
  if (successfullyLinkedBankAccount) {
    return null;
  }

  if (error) {
    return (
      <WarningBanner>
        <div className="font-semibold">Your bank account is no longer connected</div>
        <div>{error}</div>
      </WarningBanner>
    );
  }

  return (
    <WarningBanner
      customActionButton={
        <Plaid
          setError={() =>
            setError(
              'There was an error connecting to your bank account. Please contact support for assistance.',
            )
          }
          buttonProps={{
            size: 'small',
            primary: true,
          }}
          text="Confirm MicroDeposits"
          onLinkingCallback={() => setSuccessfullyLinkedBankAccount(true)}
          onSuccessCallback={() => setSuccessfullyLinkedBankAccount(true)}
          pendingBankAccountId={microDepositVerificationId}
        />
      }
    >
      <div className="font-semibold">Your bank account needs microdeposit verification</div>
      <div>
        There was an error connecting to your bank account. Please contact support for assistance.
      </div>
    </WarningBanner>
  );
};

export default MicrodepositVerificationBanner;
