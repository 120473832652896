import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import { usePaymentMethodsQuery } from '@app/hooks/query/usePaymentMethodsQuery';
import logger from '@app/utils/datadog-logger';
import { faXmarkCircle } from '@fortawesome/pro-regular-svg-icons';
import { ReactElement } from 'react';
import PaymentStatusContent from '../PaymentErrors/PaymentStatusContent';
import DebitCardController from './DebitCardController';

export interface DebitCardWrapperProps {
  setShowPaymentSelection: (showPaymentSelection: boolean) => void;
  closeModal: () => void;
  paymentAmount: string;
  setPaymentAmount: (paymentAmount: string) => void;
  showAmountDue: boolean;
  setShowAmountDue: (showAmountDue: boolean) => void;
}

const DebitCardWrapper = (props: DebitCardWrapperProps): ReactElement => {
  const { error, isLoading, data: paymentMethods } = usePaymentMethodsQuery();

  if (isLoading) {
    logger.info('Initiate debit card modal load');
    return (
      <div className="w-full">
        <SpinnerBoundary />
      </div>
    );
  }

  if (error) {
    logger.error('Loading debit card modal failed', { error });
    return (
      <PaymentStatusContent
        title="Error loading your payment methods"
        icon={faXmarkCircle}
        message="There was an error loading your payment methods. Please try again or, if the issue persists, contact support."
      />
    );
  }

  return <DebitCardController {...props} paymentMethods={paymentMethods?.data || []} />;
};

export default DebitCardWrapper;
