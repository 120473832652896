import { CustomerData } from '@app/@types/customer.types';
import UpdateSettingsModal from '@app/components/Modals/SettingsModals/UpdateSettingsModal';
import { SettingsPageEntityType } from '@app/constants/settings';
import { CUSTOMER_QUERY_KEY } from '@app/hooks/query/useCustomerQuery';
import { ReactElement, useEffect, useState } from 'react';
import { mutate } from 'swr';

export default function FleetTypePopup({ customer }: { customer: CustomerData }): ReactElement {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!customer.confirmed_fleet_type) {
      setOpen(true);
    }
  }, [customer.confirmed_fleet_type]);

  return (
    <UpdateSettingsModal
      open={open}
      type={SettingsPageEntityType.FLEET_TYPE}
      value={'light'}
      toggle={() => setOpen(false)}
      setCustomer={() => {
        mutate(CUSTOMER_QUERY_KEY);
      }}
      customer={customer}
      setUpdateInfoPrompt={() => {}}
    />
  );
}
