import { EndpointResponse } from '@app/@types/api.types';
import { ELDProvider } from '@app/@types/eld-connect.types';
import { TelematicsConnectContext } from '@app/components/Telematics/TelematicsConnectContext';
import {
  ConnectFormRequestData,
  ConnectFormResponse,
} from '@app/pages/Telematics/TelematicsUtils/ConnectForm';
import {
  FetcherKey,
  getFetcher,
  safeSendPutRequest,
  safeSendRequest,
} from '@app/utils/data/fetchers';
import { AxiosError } from 'axios';
import { useContext, useState } from 'react';
import useSWR, { SWRResponse } from 'swr';
import useSWRMutation from 'swr/mutation';
import useIsNativeApp from '../useIsNativeApp';

type TelematicsProviderResponse = EndpointResponse<{
  providers: ELDProvider[];
  purchase_uploading: PurchaseUploadingState;
}>;

export type PurchaseUploadingState =
  | 'unavailable'
  | 'available'
  | 'connection_enabled'
  | 'connection_disabled';

export function useTelematicsProvider(): SWRResponse<TelematicsProviderResponse> {
  return useSWR<TelematicsProviderResponse>(
    {
      url: '/telematics/connections',
    },
    getFetcher,
    { shouldRetryOnError: false },
  );
}

type ConnectTelematicsOAuthData = {
  auth: string;
};

export function useConnectTelematicsOAuth(providerId?: string, onSuccess?: () => void) {
  const [showModal, closeModal] = useContext(TelematicsConnectContext);
  const url = providerId
    ? `/telematics/connections/${providerId}/oauth_connect`
    : `/telematics/connections/oauth_connect`;

  const nativeAppInfo = useIsNativeApp();

  const { trigger, isMutating } = useSWRMutation<
    ConnectTelematicsOAuthData,
    AxiosError,
    FetcherKey
  >(
    {
      url: url,
    },
    getFetcher,
  );
  const impl = async () => {
    if (onSuccess) {
      showModal(onSuccess);
    }
    if (nativeAppInfo.isNativeApp) {
      const data = await trigger();
      nativeAppInfo.postMessage('open-url,' + encodeURI(data.auth));
      return;
    }
    // Safari will not open new windows after a promise (the trigger call)
    // So we open the popup first with a blank page and then redirect it
    // to the auth URL once we get it
    const newWindow = popupCenter({ url: 'about:blank', w: 600, h: 600 });
    const data = await trigger();
    if (newWindow) {
      newWindow.location = data.auth;
    }

    const listener = (event: MessageEvent) => {
      if (!event.origin.includes('atob.com')) {
        return;
      }
      if (event.data == 'success') {
        onSuccess?.();
        newWindow?.close();
        closeModal();
      }
      window.removeEventListener('message', listener);
    };
    window.addEventListener('message', listener);
  };
  return { trigger: impl, isMutating };
}

const popupCenter = ({ url, w, h }: { url: string; w: number; h: number }) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    'AxleOAuth',
    `
    scrollbars=yes,
    width=${w / systemZoom}, 
    height=${h / systemZoom}, 
    top=${top}, 
    left=${left}
    `,
  );

  newWindow?.focus();
  return newWindow;
};

type ConnectTelematicsOAuthTokenData = {
  status: string;
};

type ConnectTelematicsOAuthTokenResponse = {
  data?: ConnectTelematicsOAuthTokenData;
} & Omit<SWRResponse<ConnectTelematicsOAuthTokenData>, 'data'>;

export function useConnectTelematicsOAuthToken(
  providerId: string,
  queryParams: string,
  shouldFetch: boolean,
): ConnectTelematicsOAuthTokenResponse {
  const { data, ...rest } = useSWR<ConnectTelematicsOAuthTokenData>(
    shouldFetch && {
      url: `/telematics/connections/${providerId}/oauth_callback${queryParams}`,
    },
    getFetcher,
    { shouldRetryOnError: false },
  );

  return {
    data: data as unknown as ConnectTelematicsOAuthTokenData,
    ...rest,
  };
}

type ConnectTelematicsResult = {
  connectTelematics: (params: ConnectFormRequestData) => Promise<ConnectFormResponse | Error>;
  isConnecting: boolean;
  error?: Error;
};

export function useConnectTelematics(provider: ELDProvider): ConnectTelematicsResult {
  const key = `/telematics/connections/${provider.id}/connect`;
  const [error, setError] = useState<Error>();

  const { trigger, isMutating } = useSWRMutation<ConnectFormResponse | Error>(
    key,
    // Catch and set the error since errors are thrown
    safeSendRequest(setError),
  );

  return {
    error,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    connectTelematics: async (params: ConnectFormRequestData) => await trigger(params),
    isConnecting: isMutating,
  };
}

type DisconnectTelematicsResult = {
  disconnectTelematics: () => Promise<ConnectFormResponse | undefined>;
  isDisconnecting: boolean;
  error?: Error;
};

export function useDisconnectTelematics(provider: ELDProvider): DisconnectTelematicsResult {
  const key = `/telematics/connections/${provider.id}/disconnect`;
  const [error, setError] = useState<Error>();

  const { trigger, isMutating } = useSWRMutation<ConnectFormResponse>(
    key,
    // Catch and set the error since errors are thrown
    safeSendPutRequest(setError),
  );

  return {
    error,
    disconnectTelematics: async () => await trigger(),
    isDisconnecting: isMutating,
  };
}

export default useConnectTelematics;
