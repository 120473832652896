import classNames from 'classnames';
import { DIVIDER_BG_COLOR, DIVIDER_TYPES } from './constants';

const Divider = ({
  thin = false,
  medium = false,
  bold = false,
  className,
}: {
  thin?: boolean;
  medium?: boolean;
  bold?: boolean;
  className?: string;
}) => {
  const colors: { [key in DIVIDER_TYPES]: boolean } = {
    thin,
    medium,
    bold,
  };

  const [dividerColorType] = Object.entries(colors).find(([, value]) => value) || ['medium'];

  return (
    <div
      className={classNames(
        'h-px w-full',
        DIVIDER_BG_COLOR[dividerColorType as DIVIDER_TYPES],
        className,
      )}
    />
  );
};

export default Divider;
