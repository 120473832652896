import { DebitCardPaymentMethodCombined } from '@app/@types/debitCard.types';
import PaymentDetailItem from '@app/components/PaymentModal/PaymentDetailSection/PaymentDetailItem/PaymentDetailItem';
import ErrorMessage from '@app/components/PaymentModal/PaymentErrors/ErrorMessage';
import ChangeAccountText from '@app/components/PaymentModal/components/ChangeText';
import FloatingDropdown from '@app/components/PaymentModal/components/FloatingDropdown';
import FloatingDropdownItem from '@app/components/PaymentModal/components/FloatingDropdownItem';
import { capitalize } from 'lodash-es';
import { ReactElement, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
interface BankAccountDetailItemProps {
  selectedDebitCard?: DebitCardPaymentMethodCombined;
  debitCards: DebitCardPaymentMethodCombined[];
  setSelectedDebitCard: (debitCard: DebitCardPaymentMethodCombined) => void;
  noDebitCardsErrors: string | null;
}

const BankAccountDetailItem = ({
  selectedDebitCard,
  debitCards,
  setSelectedDebitCard,
  noDebitCardsErrors,
}: BankAccountDetailItemProps): ReactElement => {
  const navigate = useNavigate();

  const navigateToPaymentMethods = () => {
    navigate('/billing/payment-methods?default_show_modal=debit_card');
  };

  const [showSelectDebitCardModal, setShowSelectDebitCardModal] = useState(false);
  const popoverRef = useRef(null);
  if (!selectedDebitCard) {
    return (
      <NoSelectedDebitCard
        noDebitCardsErrors={noDebitCardsErrors}
        navigateToPaymentMethods={navigateToPaymentMethods}
      />
    );
  }
  const { payment_method_detail: debitCardDetails } = selectedDebitCard;
  return (
    <>
      <FloatingDropdown
        open={showSelectDebitCardModal}
        toggle={() => setShowSelectDebitCardModal(false)}
        title="Debit Cards"
        anchorEl={popoverRef.current}
        showRight
      >
        {debitCards.map((debitCard: DebitCardPaymentMethodCombined) => {
          const { payment_method_detail: debitCardDetail } = debitCard;
          return (
            <FloatingDropdownItem
              selected={debitCardDetail.id === selectedDebitCard.payment_method_detail.id}
              text={`${capitalize(debitCardDetail.brand)} •••• ${debitCardDetail.last_four}`}
              paymentItemKey={debitCardDetail.id}
              key={debitCardDetail.id}
              onClick={() => {
                setSelectedDebitCard(debitCard);
                setShowSelectDebitCardModal(false);
              }}
            />
          );
        })}
        <FloatingDropdownItem
          paymentItemKey="add-a-new-card"
          selected={false}
          text="Add New Card"
          onClick={() => navigateToPaymentMethods()}
        />
      </FloatingDropdown>
      <PaymentDetailItem text="Debit Card">
        {capitalize(debitCardDetails.brand)} •••• {debitCardDetails.last_four}
        <ChangeAccountText ref={popoverRef} onClick={() => setShowSelectDebitCardModal(true)}>
          Change
        </ChangeAccountText>
      </PaymentDetailItem>
    </>
  );
};

const NoSelectedDebitCard = ({
  navigateToPaymentMethods,
  noDebitCardsErrors,
}: {
  navigateToPaymentMethods: () => void;
  noDebitCardsErrors: string | null;
}) => {
  return (
    <div className="max-full overflow-x-hidden">
      <PaymentDetailItem text="Debit Card">
        <span
          className="text-atob-green cursor-pointer font-medium"
          onClick={() => navigateToPaymentMethods()}
        >
          Add Debit Card
        </span>
      </PaymentDetailItem>
      {noDebitCardsErrors && (
        <div className="flex justify-end">
          <ErrorMessage text={noDebitCardsErrors} />
        </div>
      )}
    </div>
  );
};

export default BankAccountDetailItem;
