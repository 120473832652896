interface PlaidErrorButtonProps {
  className?: string;
}

const PlaidErrorButton = ({ className = '' }: PlaidErrorButtonProps) => {
  return (
    <button type="button" className={`button ${className} is-danger is-outlined`}>
      Plaid Link Error
    </button>
  );
};

export default PlaidErrorButton;
