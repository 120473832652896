import useThemeConfiguration from '@app/app/useThemeConfiguration';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

export default function AtoBPlusBanner(): ReactElement | null {
  const navigate = useNavigate();
  const onNavigateToPremium = () => navigate('/premium');
  const { hideAtoBPremiumBanner } = useThemeConfiguration();

  if (hideAtoBPremiumBanner) {
    return null;
  }

  return (
    <div className="bg-ds-cool-gray-900 bg-dot-cluster-texture bg-ds-cool-gray-900 relative flex w-full items-center justify-between overflow-hidden rounded-lg bg-[center_right_-95px] bg-no-repeat">
      <div className="flex flex-col justify-between px-[32px] py-[22px] md:w-full md:flex-row md:items-center md:gap-20">
        <div>
          <div className="font-medium text-white">
            AtoB <b className="text-ds-bright-green">Premium</b>. Your new superpowers.
          </div>
          <div className="text-gray-400">
            Reduce fuel theft and fraud. Advanced card spend controls. Accounting Reports.
          </div>
        </div>
        <div className="mt-4 md:mt-0">
          <button
            className="bg-ds-bright-green flex items-center justify-center rounded-[4px] px-4 py-2 text-base font-semibold"
            onClick={onNavigateToPremium}
          >
            Learn about Premium
          </button>
        </div>
      </div>
    </div>
  );
}
