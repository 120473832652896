import { PartnerLogo } from '@app/components/layout/PartnerLogo';
import useCustomer from '@app/hooks/useCustomer';
import { LinearProgress, styled, linearProgressClasses } from '@mui/material';
import classNames from 'classnames';
import { groupBy } from 'lodash-es';
import { ReactElement } from 'react';

type ProgressIndicator = { currentStep: number; totalSteps: number };

export default function OnboardingHeader({
  currentStep,
  totalSteps,
  hideProgress,
}: ProgressIndicator & { hideProgress?: true }): ReactElement {
  return (
    <div className="sticky top-0 z-10 flex h-20 w-full shrink-0 items-center bg-white shadow-md">
      <div
        className={classNames(
          'max-w-xs',
          !hideProgress && 'absolute ml-10 hidden md:block',
          hideProgress && 'mx-auto block md:absolute md:ml-10',
        )}
      >
        <PartnerLogo darkStyle aria-hidden="true" className="max-h-[72px]" />
      </div>
      {!hideProgress && (
        <div className="mx-auto">
          <OnboardingProgress currentStep={currentStep} totalSteps={totalSteps} />
        </div>
      )}
    </div>
  );
}

function OnboardingProgress({ currentStep }: ProgressIndicator): ReactElement | null {
  const { interstitials } = useCustomer();
  if (!interstitials) {
    return null;
  }
  const groups = groupBy(interstitials.steps ?? [], ({ grouping }) => grouping);
  const keys = Object.keys(groups);
  const stepGroup = interstitials.steps[currentStep].grouping;
  const groupingIdx = keys.indexOf(stepGroup);
  const visibleGroups = Object.keys(groups).length - 2;
  return (
    <div className="flex flex-col items-center">
      <div className="pb-1 text-xs">
        Step {groupingIdx} of {visibleGroups}
      </div>
      <BorderLinearProgress
        variant="determinate"
        value={100 * ((groupingIdx - 1) / visibleGroups)}
        sx={{ width: 100 }}
      />
    </div>
  );
}

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#E6E6E6',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
  },
}));
