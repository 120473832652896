/**
 * @deprecated Do not use - this is not a good practice
 */
export function chainBulkRequests<T>(
  requests: (() => Promise<T>)[],
  batchSize = 10,
  timeoutMs = 1000,
  // Optional callback function that gets called on each batch
  cb?: (response: T[]) => void,
): Promise<void | Awaited<T>[]> {
  return __chainBulkRequestsWithTimeoutFn(requests, batchSize, timeoutMs, timeout, cb);
}

export function __chainBulkRequestsWithTimeoutFn<T>(
  requests: (() => Promise<T>)[],
  batchSize = 10,
  timeoutMs = 1000,
  timeoutFn = timeout,
  // Optional callback function that gets called on each batch
  cb?: (response: T[]) => void,
): Promise<void | Awaited<T>[]> {
  const totalBatches = Math.ceil(requests.length / batchSize);
  return Array(totalBatches)
    .fill(0) // Need to fill array otherwise we cannot iterate/call reduce
    .reduce((acc, _, i) => {
      return acc.then((response: T[]) => {
        cb && cb(response);
        return Promise.all([
          ...response,
          ...requests.slice(i * batchSize, i * batchSize + batchSize).map((request) => request()),
          timeoutFn(timeoutMs),
        ]).then((responses: unknown[]) => responses.filter((r) => !!r));
      });
    }, Promise.resolve([]));
}

function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(() => resolve([]), ms));
}
