import { PaginatedEndpointResponse } from '@app/@types/api.types';
import { CardData } from '@app/@types/card.types';
import { WithDriver } from '@app/@types/driver.types';
import { WithResourceTag } from '@app/@types/tag.types';
import { WithVehicle } from '@app/@types/vehicle.types';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import { ErrorNotification } from '@app/components/layout';
import PageContentWrapper from '@app/components/wrappers/PageContentWrapper';
import { CARDS_FILTERS } from '@app/constants/cards';
import { DEFAULT_PAGE_SIZE } from '@app/hooks/paging/types';
import { useUrlState } from '@app/hooks/useUrlState';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import UnderlinedTabs from '@atob-developers/shared/src/components/UnderlinedTabs';
import { GridRowId, GridSortModel } from '@mui/x-data-grid-pro';
import { ReactElement, useState } from 'react';
import useSWR from 'swr';
import { CardQueryFilters } from './CardFilterTypes';
import CardsFilters from './CardsFilters';
import CardsList from './CardsList';
import CardsPageHeader from './CardsPageHeader';

const CARDS_TABS = Object.freeze([
  {
    name: 'All Cards',
    tab: CARDS_FILTERS.ALL,
  },
  {
    name: 'Active',
    tab: CARDS_FILTERS.ACTIVE,
  },
  {
    name: 'Inactive',
    tab: CARDS_FILTERS.INACTIVE,
  },
]);

export interface IUrlState {
  query: null | string;
  tab: CARDS_FILTERS;
  sidebar: null | string;
  tag: null | string;
  [entry: string]: null | string;
}

export default function CardsPage({
  cardRequestsEnabled,
  showTags,
}: {
  telematicsEnabled: boolean;
  cardRequestsEnabled: boolean;
  showTags: boolean;
}): ReactElement {
  const [queryState, setQueryState] = useUrlState<CardQueryFilters>({
    page: 1,
    driver_id: [],
    vehicle_id: [],
    tag_id: [],
    workflow_state: undefined,
    like: '',
    sort: undefined,
  });

  const [selectedCards, setSelectedCards] = useState<string[]>([]);

  const filteredTabs = CARDS_TABS.map((t) => ({
    name: t.name,
    tab: t.tab,
  }));

  const { data, error, isLoading, mutate } = useSWR<
    PaginatedEndpointResponse<CardData & WithDriver & WithVehicle & WithResourceTag>
  >(
    {
      url: '/cards',
      params: {
        include: 'driver,vehicle,resource_tag',
        per: DEFAULT_PAGE_SIZE,
        all: false,
        ...queryState,
      },
    },
    apiGetFetcher,
  );

  if (error) {
    return (
      <ErrorNotification error="We're having issues loading your cards data. Please try again or if the issue persists, contact support." />
    );
  }
  return (
    <PageContentWrapper
      header={
        <CardsPageHeader
          showSelfServeCardOrdering={cardRequestsEnabled}
          value={queryState.like}
          handleChange={(e) =>
            setQueryState((prev) => ({ ...prev, page: 1, like: e.target.value }))
          }
          selectedFilters={queryState}
        />
      }
      pageTabs={
        <UnderlinedTabs
          selectedTab={queryState.workflow_state || CARDS_FILTERS.ALL}
          tabs={filteredTabs}
          handleTabChange={(tab: CARDS_FILTERS) =>
            setQueryState((prev) => ({
              ...prev,
              workflow_state: tab == CARDS_FILTERS.ALL ? undefined : tab,
              page: 1,
            }))
          }
        />
      }
    >
      <RoundedCard breakpoint="md">
        <CardsFilters
          selectedFilters={queryState}
          setSelectedFilters={(v) => setQueryState((p) => ({ ...p, ...v, page: 1 }))}
        />

        <CardsList
          data={data?.data ?? []}
          paginationMeta={data?.meta}
          showTags={showTags}
          selectedCards={selectedCards}
          handleSelectCards={setSelectedCards as (cards: GridRowId[]) => void}
          fetchData={mutate}
          dataLoading={isLoading}
          onPageIndexChange={(page) => setQueryState((p) => ({ ...p, page }))}
          onSortModelChange={(model: GridSortModel) => {
            const sortByModel = model && model[0];
            setQueryState((p) => ({
              ...p,
              sort: sortByModel ? [`${sortByModel.field}:${sortByModel.sort}`] : undefined,
              page: 1,
            }));
          }}
        />
      </RoundedCard>
    </PageContentWrapper>
  );
}
