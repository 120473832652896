import { PaginatedEndpointResponse } from '@app/@types/api.types';
import { PaymentMethodCombined } from '@app/@types/payments.types';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import { SWRResponse } from 'swr';
import useSWRImmutable from 'swr/immutable';

// You can call mutate(PAYMENT_METHOD_QUERY_KEY) to refresh the data without needing to hard-refresh the page.
export const PAYMENT_METHOD_QUERY_KEY = {
  url: '/payments/payment_methods',
  params: {
    // this returns all payment methods (polymorphic), and also the financial_account of the customer_treasury_detail
    include: 'payment_method_detail,payment_method_detail.financial_account',
  },
};

export const usePaymentMethodsQuery = (): SWRResponse<
  PaginatedEndpointResponse<PaymentMethodCombined>
> => {
  return useSWRImmutable<PaginatedEndpointResponse<PaymentMethodCombined>>(
    PAYMENT_METHOD_QUERY_KEY,
    apiGetFetcher,
  );
};
