import { EndpointResponse } from '@app/@types/api.types';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import { formatDateNoDay } from '@app/utils/formatDate';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { StatusBanner } from '../../components/Banner/Designs/StatusBanner';

type CreditReport = {
  id: string;
  report_date: string;
};

export default function BusinessCreditReportNotification(): ReactElement | null {
  const [isBusinessCreditReportingEnabled] = useFeatureFlags('business_credit_reporting');

  const navigate = useNavigate();

  const { data: lastBusinessCreditReportData } = useSWR<EndpointResponse<CreditReport | null>>(
    {
      url: `/credit_reports/last`,
    },
    apiGetFetcher,
  );

  if (!isBusinessCreditReportingEnabled) return null;

  const showlastBusinessCreditReport =
    lastBusinessCreditReportData && lastBusinessCreditReportData.data;
  if (!showlastBusinessCreditReport) return null;

  const creditReport = lastBusinessCreditReportData.data;
  if (creditReport == null) {
    return null;
  }

  return (
    <StatusBanner
      icon={faCircleCheck}
      iconColor="green"
      bannerKey={`business_credit_report_${creditReport.id}`}
      actionText="View details"
      onClick={() => {
        navigate('/settings/business-credit-reporting');
      }}
    >
      Business credit reported to Experian for {formatDateNoDay(new Date(creditReport.report_date))}
    </StatusBanner>
  );
}
