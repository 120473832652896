import { faFacebookF, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, useState } from 'react';

interface InviteProps {
  referralLink: string;
}

export const LinkInvite = ({ referralLink }: InviteProps): ReactElement => {
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  const copyTextClickHandler = () => {
    navigator.clipboard.writeText(referralLink);
    setCopiedToClipboard(true);
    setTimeout(() => setCopiedToClipboard(false), 5000);
  };

  return (
    <div>
      <div className="flex w-full items-center">
        <input
          className="w-full rounded-md border border-gray-300 bg-white p-2 text-sm text-gray-600"
          type="text"
          placeholder="Referral Link"
          value={referralLink}
          readOnly
        />
        <div className="mx-4 w-[150px]">
          {copiedToClipboard && <span>Link Copied!</span>}
          {!copiedToClipboard && (
            <button type="button" onClick={() => copyTextClickHandler()}>
              Copy Link
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export const SocialInvite = ({ referralLink }: InviteProps): ReactElement => {
  // eslint-disable-next-line no-explicit-atob-mentions
  const referralText = encodeURIComponent(
    // Keep this in sync with sms/en.yml on the BE for the text message.
    `Hey, check out the AtoB fuel card. I’ve been using their card for my business and loving it for fuel discounts. If you sign up using this link ${referralLink}, you can get a promotional discount of 5c off per gallon for 3 months.`,
  );
  const facebookLink = `https://www.facebook.com/sharer.php?u=${referralLink}`;
  const linkedinLink = `https://www.linkedin.com/sharing/share-offsite/?url=${referralLink}`;
  // eslint-disable-next-line no-explicit-atob-mentions
  const twitterLink = `https://twitter.com/intent/tweet?text=${referralText}`;

  return (
    <div>
      <hr className="divider mb-5 mt-5" />

      <div className="flex w-full justify-around">
        <div className="border border-gray-100 px-12 py-4">
          <a href={facebookLink} target="_blank" rel="nofollow noreferrer">
            <button type="button" className="w-full">
              <FontAwesomeIcon icon={faFacebookF} className="fab fa-lg" />
            </button>
          </a>
        </div>
        <div className="border border-gray-100 px-12 py-4">
          <a href={linkedinLink} target="_blank" rel="nofollow noreferrer">
            <button type="button" className="w-full">
              <FontAwesomeIcon icon={faLinkedinIn} className="fab fa-lg" />
            </button>
          </a>
        </div>
        <div className="border border-gray-100 px-12 py-4">
          <a href={twitterLink} target="_blank" rel="nofollow noreferrer">
            <button type="button" className="w-full">
              <FontAwesomeIcon icon={faTwitter} className="fab fa-lg" />
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};
