import { FetcherKey, apiPostHeadersOnly } from '@app/utils/data/fetchers';
import { AxiosError, AxiosResponseHeaders } from 'axios';
import useSWRMutation, { SWRMutationResponse } from 'swr/mutation';

export type CreateDriverParam = {
  driver: { phone: string; first_name: string; last_name: string };
};

export function useCreateDriver(): SWRMutationResponse<
  AxiosResponseHeaders,
  AxiosError,
  FetcherKey,
  CreateDriverParam
> {
  return useSWRMutation<AxiosResponseHeaders, AxiosError, FetcherKey, CreateDriverParam>(
    {
      url: '/drivers',
    },
    apiPostHeadersOnly,
  );
}
