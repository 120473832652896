import PaymentDetailItem from '@app/components/PaymentModal/PaymentDetailSection/PaymentDetailItem/PaymentDetailItem';
import AmountItem from '@app/components/PaymentModal/components/AmountItem';
import EditPaymentFooter from '@app/components/PaymentModal/components/EditPaymentFooter';
import PaymentDetailsHeaderText from '@app/components/PaymentModal/components/PaymentDetailsHeaderText';
import Divider from '@atob-developers/shared/src/components/Divider';
import { ReactElement, useState } from 'react';

interface LoadpayWalletEditPaymentDetailsProps {
  closeModal: () => void;
  onNext: () => void;
  paymentAmount: string;
  setPaymentAmount: (value: string) => void;
  showAmountDue: boolean;
  setShowAmountDue: (showAmountDue: boolean) => void;
}

const LoadpayWalletEditPaymentDetails = ({
  onNext,
  closeModal,
  paymentAmount,
  setPaymentAmount,
  showAmountDue,
  setShowAmountDue,
}: LoadpayWalletEditPaymentDetailsProps): ReactElement | null => {
  const [error, setError] = useState<string | null>(null);

  return (
    <>
      <div className="w-full space-y-10 bg-white px-6 py-6 sm:px-8">
        <div className="flex flex-col gap-4">
          <PaymentDetailsHeaderText>Payment Details</PaymentDetailsHeaderText>
          <div className="flex flex-col gap-4">
            <Divider className="bg-gray-200" />
            <AmountItem
              amount={paymentAmount}
              setAmount={setPaymentAmount}
              setError={setError}
              error={error}
              showAmountDue={showAmountDue}
              setShowAmountDue={setShowAmountDue}
            />
            <Divider className="bg-gray-200" />
            <PaymentDetailItem text="Est. Processing Date">Instant</PaymentDetailItem>
          </div>
        </div>
      </div>
      <EditPaymentFooter onCancel={closeModal} onNext={onNext} />
    </>
  );
};

export default LoadpayWalletEditPaymentDetails;
