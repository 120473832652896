import classNames from 'classnames';
import { ReactElement } from 'react';
import { Step, StepStatus, UpcomingStep } from '../steps.types';

export const isUpcoming = (step: Step): step is UpcomingStep => step.status === StepStatus.UPCOMING;

export const Upcoming = ({ step }: { step: UpcomingStep }): ReactElement => {
  const { id, name, href, required, renderIcon, disableIcon } = step;
  return (
    <a
      data-testid={`upcoming-${id}`}
      href={href}
      className={classNames('group flex ', {
        'cursor-default': !href,
        'flex-col items-start': !!step.description,
        'items-center': !step.description,
      })}
    >
      <span className={classNames('flex items-center px-6 py-4 text-sm font-medium')}>
        {!disableIcon && (
          <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
            <span className="text-gray-500 group-hover:text-gray-900">
              {renderIcon ? renderIcon(id) : id}
            </span>
          </span>
        )}
        <div className={classNames({ 'ml-4': !disableIcon }, 'flex flex-col text-sm font-medium')}>
          {required && <span className="text-gray-500">Required</span>}
          <span className={required ? 'text-gray-900' : 'text-gray-500 group-hover:text-gray-900'}>
            {name}
          </span>
        </div>
      </span>

      {step.description && (
        <div className="text-grey7 hidden pb-4 pl-6 text-xs sm:block">{step.description}</div>
      )}
    </a>
  );
};
