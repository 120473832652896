import { TransactionsFilters } from '@app/constants/transactions';
import { DEFAULT_PAGE_SIZE } from '@app/hooks/paging/types';
import { useUrlState } from '@app/hooks/useUrlState';
import {
  ReactElement,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export type ChargeEventQueryParams = {
  'page': number;
  'tab': TransactionsFilters;
  'transactor_name': boolean;
  'include': string;
  'all': boolean;
  'per': number;
  'by_card_ids': string[];
  'by_driver_ids': string[];
  'by_vehicle_ids': string[];
  'by_tag_ids': string[];
  'filter_authorized_at[to]': string | null;
  'filter_authorized_at[from]': string | null;
  'auth_approved': boolean | undefined;
  'like_card_number_last_four': string | undefined;
  'by_suspicious_reasons': string[] | undefined;
};

type ChargeEventsContextType = {
  queryParams: ChargeEventQueryParams;
  nonURLQueryParams: ChargeEventQueryParams;
  setQueryParams: (val: React.SetStateAction<ChargeEventQueryParams>) => void;
  transactionId: string | null;
  setTransactionId: (val: string | null) => void;
};

export const DEFAULT_STATE = {
  'page': 1,
  'tab': TransactionsFilters.ALL,
  'transactor_name': true,
  'include': 'tag',
  'all': false,
  'per': DEFAULT_PAGE_SIZE,
  'by_card_ids': [],
  'by_driver_ids': [],
  'by_vehicle_ids': [],
  'by_tag_ids': [],
  'filter_authorized_at[to]': null,
  'filter_authorized_at[from]': null,
  'auth_approved': undefined,
  'like_card_number_last_four': undefined,
  'by_suspicious_reasons': undefined,
};

export const ChargeEventsContext = createContext<ChargeEventsContextType>({
  queryParams: DEFAULT_STATE,
  nonURLQueryParams: DEFAULT_STATE,
  setQueryParams: () => {},
  transactionId: null,
  setTransactionId: () => {},
});

export function useSetTransactionId(transationId?: string) {
  const { setTransactionId } = useContext(ChargeEventsContext);
  useEffect(() => {
    setTransactionId(transationId || null);
    return () => setTransactionId(null);
  }, [setTransactionId, transationId]);
}

export default function ChargeEventsContextComponent({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  const [transactionId, setTransactionId] = useState<string | null>(null);

  const [queryParams, setQueryParams] = useUrlState<ChargeEventQueryParams>(DEFAULT_STATE);
  const [nonURLQueryParams, setNonURLQueryParams] = useState<ChargeEventQueryParams>(queryParams);

  const setQueryParamsImpl = useCallback(
    (queryParams: React.SetStateAction<ChargeEventQueryParams>) => {
      setQueryParams(queryParams);
      setNonURLQueryParams(queryParams);
    },
    [setQueryParams],
  );
  const memoContext = useMemo(
    () => ({
      queryParams,
      nonURLQueryParams,
      setQueryParams: setQueryParamsImpl,
      transactionId,
      setTransactionId,
    }),
    [nonURLQueryParams, queryParams, setQueryParamsImpl, transactionId],
  );

  return (
    <ChargeEventsContext.Provider value={memoContext}>{children}</ChargeEventsContext.Provider>
  );
}
