import { IconDefinition, IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropsWithChildren, ReactElement } from 'react';
import { MobileFriendlyTooltip } from './MobileFriendlyTooltip';

export type TooltipAlignment =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

type TooltipProps = {
  title?: string;
  description?: string;
  open?: boolean;
  align?: TooltipAlignment;
  icon?: IconDefinition;
  iconClassName?: string;
  arrow?: boolean;
};

export function Tooltip({
  title,
  description,
  open,
  arrow,
  align = 'bottom-end',
  icon = faCircleInfo,
  iconClassName = '',
}: TooltipProps) {
  return (
    <MobileFriendlyTooltip
      open={open}
      placement={align}
      title={
        <div className="flex flex-col gap-[4px]">
          <p>{title}</p>
          {description && <p className="text-ds-cool-gray-300 font-normal">{description}</p>}
        </div>
      }
      arrow={arrow}
    >
      <FontAwesomeIcon icon={icon as IconProp} className={iconClassName} />
    </MobileFriendlyTooltip>
  );
}

export function CustomTooltip({
  children,
  open,
  arrow,
  align = 'bottom-end',
  icon = faCircleInfo,
  iconClassName = '',
  target,
}: PropsWithChildren<TooltipProps & { target?: ReactElement }>) {
  return (
    <MobileFriendlyTooltip open={open} placement={align} title={children} arrow={arrow}>
      {target ?? <FontAwesomeIcon icon={icon} className={iconClassName} />}
    </MobileFriendlyTooltip>
  );
}
