import { useMediaQuery, useTheme } from '@mui/material';
import { GridRowModel, GridValidRowModel } from '@mui/x-data-grid-pro';
import MobileListView from './MobileListView';
import TableV2, { TableV2Props } from './TableV2';

export type ResponsiveTableProps<T extends GridValidRowModel> = {
  mobileItemRenderer: React.FC<{ row: GridRowModel<T> }>;
} & TableV2Props<T>;

export default function ResponsiveTable<T extends GridValidRowModel>(
  props: ResponsiveTableProps<T>,
) {
  const theme = useTheme();
  const showMobileView = useMediaQuery(theme.breakpoints.down('md'));
  const isMdScreen = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <>
      {showMobileView ? <MobileListView {...props} /> : <TableV2 {...props} />}
      {isMdScreen && <div className="p-6" />}
    </>
  );
}
