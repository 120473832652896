import Header from '@app/components/Navigation/Header';
import DebitCardModal from '@app/components/PaymentMethods/DebitCardModal';
import useModal from '@app/hooks/useModal';
import { ReactElement } from 'react';

const PaymentMethodsPageHeader = (): ReactElement => {
  const [showDebitCardModal, setShowDebitCardModal] = useModal();

  return (
    <Header
      title="Payment Methods"
      rightContent={
        <div className="flex w-full max-w-[393px] justify-between">
          <div className="level-right flex-col items-end">
            <DebitCardModal isActive={showDebitCardModal} setIsActive={setShowDebitCardModal} />
          </div>
        </div>
      }
    />
  );
};

export default PaymentMethodsPageHeader;
