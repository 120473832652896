import * as Sentry from '@sentry/react';
import axios, { AxiosError } from 'axios';

function logToSentry(expectedException: string, thrownObject: unknown): void {
  Sentry.captureException(thrownObject, {
    tags: {
      expectedExceptionType: expectedException,
      receivedExceptionType: typeof thrownObject,
    },
  });
}

export function guardAxiosError(thrownObject: unknown): thrownObject is AxiosError {
  if (axios.isAxiosError(thrownObject)) {
    return true;
  }

  logToSentry('AxiosError', thrownObject);
  return false;
}

export function guardError(thrownObject: unknown): thrownObject is Error {
  if (thrownObject instanceof Error) {
    return true;
  }

  logToSentry('Error', thrownObject);
  return false;
}
