import { EndpointResponse } from '@app/@types/api.types';
import { PlaidResponse } from '@app/@types/plaid.types';
import getBodyForPlaidRequest from '@app/components/Plaid/PlaidUtils/PlaidUtils';
import {
  CustomerOnboardingEventName,
  useCreateCustomerOnboardingEvent,
} from '@app/hooks/query/useCustomerOnboardingEvents';
import useCustomer from '@app/hooks/useCustomer';
import useNoPaymentMethods from '@app/hooks/useNoPaymentMethods';
import { usePlaidConnectHandler } from '@app/hooks/usePlaidHandlers';
import axios from 'axios';
import { usePlaidLink } from 'react-plaid-link';
import useSWRImmutable from 'swr/immutable';
import { getEnvironment } from '../environment';

export default function usePlaidForOnboarding(
  { reloadCustomer }: { reloadCustomer: boolean } = { reloadCustomer: true },
) {
  const customer = useCustomer();
  const { refreshPaymentMethods, paymentMethods, isLoading } = useNoPaymentMethods(
    customer.owner_email,
  );
  const { onSuccess, onEvent } = usePlaidConnectHandler();
  const { data } = useSWRImmutable<PlaidResponse>('onboarding_plaid_token', (_) =>
    axios
      .post('plaid/create_link_token', getBodyForPlaidRequest({}))
      .then((res: EndpointResponse<PlaidResponse>) => res.data),
  );
  const { VITE_PLAID_ENV } = getEnvironment();
  const { trigger: createCustomerOnboardingEvent } = useCreateCustomerOnboardingEvent({
    reloadCustomer,
  });

  const { open: openPlaidModal, ready: isPlaidReady } = usePlaidLink({
    env: String(VITE_PLAID_ENV),
    onSuccess: async (token, meta) => {
      onSuccess(token, meta);

      refreshPaymentMethods();
      await createCustomerOnboardingEvent({
        customer_onboarding_event: {
          name: CustomerOnboardingEventName.PAYMENT_METHOD_CONNECTED,
        },
      });
    },
    onEvent,
    token: data?.link_token ?? null,
  });
  return { openPlaidModal, isPlaidReady: isPlaidReady && !isLoading, paymentMethods };
}
