import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ReactElement } from 'react';
import PrepaidAllSet from './PrepaidAllSet';
import { PrepaidStep } from './use-onboarding-steps';

export default function PrepaidSteps({
  title,
  steps,
  completions,
}: {
  title: string;
  steps: PrepaidStep[] | null;
  completions: {
    opt_in_complete: boolean;
    activation_complete: boolean;
    done: boolean;
  };
}): ReactElement | null {
  if (completions.done) {
    return null;
  }
  if (completions.opt_in_complete) {
    return <PrepaidAllSet optIn />;
  }

  if (completions.activation_complete) {
    return <PrepaidAllSet netNew />;
  }

  if (!steps) {
    return null;
  }

  const hasOnlyOneStep = steps.length === 1;
  return (
    <div className="bg-ds-cool-gray-900 mx-4 mb-6 rounded-xl px-5 py-5">
      <h2 className="mb-4 text-sm font-bold uppercase text-white">{title}</h2>
      <ol className="overflow-hidden">
        {steps.map((step, stepIdx) => (
          <li
            key={step.description}
            className={classNames(stepIdx !== steps.length - 1 ? 'pb-4' : '', 'relative')}
          >
            {step.status === 'complete' ? (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-[1px] bg-gray-300"
                    aria-hidden="true"
                  />
                ) : null}
                <a className="group relative flex items-center">
                  <span className="flex h-9 items-center">
                    <span className="bg-prepaid-completed-step flex h-8 w-8 items-center justify-center rounded-full">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col">
                    <span className="text-sm text-gray-500">{step.description}</span>
                  </span>
                </a>
              </>
            ) : step.status === 'current' ? (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-[1px] bg-gray-300"
                    aria-hidden="true"
                  />
                ) : null}
                <div>
                  <div className="group relative flex items-baseline" aria-current="step">
                    {!hasOnlyOneStep && (
                      <span className="flex h-9 items-center" aria-hidden="true">
                        <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-white">
                          <span className="">{stepIdx + 1}</span>
                        </span>
                      </span>
                    )}
                    <span
                      className={classNames('flex min-w-0 flex-col items-start', {
                        'ml-4': !hasOnlyOneStep,
                      })}
                    >
                      <div className="text-sm font-medium text-white">{step.description}</div>
                      {step.error && (
                        <div className="mt-2">
                          <div className="text-[14px] font-bold text-red-400">
                            {step.error.code}
                          </div>
                          <div className="font-sm text-[14px] text-red-400">
                            {step.error.reason}
                          </div>
                        </div>
                      )}
                      <div className="mt-3">{step.CTA && step.CTA()}</div>
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-[1px] bg-gray-300"
                    aria-hidden="true"
                  />
                ) : null}
                <div className="group relative flex items-center">
                  <span className="flex h-9 items-center" aria-hidden="true">
                    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-500 bg-gray-500 group-hover:border-gray-600">
                      <span className="text-black">{stepIdx + 1}</span>
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col">
                    <span className="text-sm text-gray-500">{step.description}</span>
                  </span>
                </div>
              </>
            )}
          </li>
        ))}
      </ol>
    </div>
  );
}
