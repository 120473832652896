import { getFetcher } from '@app/utils/data/fetchers';
import useSWR, { SWRResponse } from 'swr';

export type FleetioConnection = {
  account_token: string;
};

export const useFleetioConnectionQuery = (): SWRResponse<FleetioConnection, Error> & {
  fleetioConnected: boolean;
} => {
  const swrData = useSWR<FleetioConnection>({ url: `/fleetio` }, getFetcher);

  const fleetioConnected = swrData.data?.account_token !== undefined;

  return {
    ...swrData,
    fleetioConnected,
  };
};
