import { BankAccountPaymentMethodCombined } from '@app/@types/bankAccount.types';
import { handleMakePaymentMethodPrimary } from '@app/components/PaymentMethods/PaymentMethodUtils';
import RemovePaymentMethodModal from '@app/components/PaymentMethods/RemovePaymentMethodModal';
import UpdateLoginModal from '@app/components/PaymentMethods/UpdateLoginModal';
import Plaid from '@app/components/Plaid';
import BankAccountCanNotBePrimaryPrompt from '@app/components/Prompt/BankAccountCanNotBePrimaryPrompt';
import PaymentMethodsPrompt from '@app/components/Prompt/PaymentMethodsBankAccountPrompt';
import { SidebarWrapper } from '@app/components/wrappers/SidebarWrapper';
import CustomerContext from '@app/contexts/customerContext';
import { PaymentHistoryContext } from '@app/contexts/paymentHistoryContext';
import useModal from '@app/hooks/useModal';
import { MobileFriendlyTooltip } from '@atob-developers/shared/src/components/MobileFriendlyTooltip';
import { SideBarBody, SideBarFooter } from '@atob-developers/shared/src/components/SideBar';
import { faWarning } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Chip } from '@mui/material';
import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { ReactElement, useContext, useState } from 'react';

export enum BankAccountUpdateStates {
  ERROR = 'error',
  SUCCESSFUL = 'successful',
  NONE = 'none',
}
export interface BankAccountSidebarProps {
  title: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  account: BankAccountPaymentMethodCombined;
  showRemoveBankButton: boolean;
  handleRefreshPaymentMethods: () => void;
  onSuccessCallback: () => void;
  onLinkingCallback: (phase: string) => void;
}

export const BankAccountSidebar = ({
  title,
  open,
  setOpen,
  account,
  showRemoveBankButton,
  handleRefreshPaymentMethods,
  onSuccessCallback,
  onLinkingCallback,
}: BankAccountSidebarProps): ReactElement => {
  const { bankAccounts, debitCards, setBankAccounts, setDebitCards, setType } =
    useContext(PaymentHistoryContext);
  const [showUpdateLoginModal, setShowUpdateLoginModal] = useModal();
  const [showRemoveBankAccountModal, setShowRemoveBankAccountModal] = useModal();
  const [isPrimary, setIsPrimary] = useState(account?.is_primary ?? false);
  const [bankAccountUpdateState, setBankAccountUpdateState] = useState<BankAccountUpdateStates>(
    BankAccountUpdateStates.NONE,
  );
  const { customer } = useContext(CustomerContext);
  const allowed_primary = customer?.allowed_primary_payment_method_types?.includes('BankAccount');

  const [showBankAccountPrimaryPrompt, setShowBankAccountPrimaryPrompt] = useState(true);

  const updateBankAccounts = () => {
    const newBankAccounts = (bankAccounts || []).map((b) => {
      if (account.id === b.id) {
        return {
          ...b,
          is_primary: true,
        };
      } else if (b.is_primary) {
        return {
          ...b,
          is_primary: false,
        };
      } else {
        return b;
      }
    });

    setBankAccounts(newBankAccounts);
  };

  const updateDebitCards = () => {
    const newDebitCards = (debitCards || []).map((card) => {
      if (card.is_primary) {
        return {
          ...card,
          is_primary: false,
        };
      } else {
        return card;
      }
    });

    setDebitCards(newDebitCards);
  };

  const handleChecked = async () => {
    const response = await handleMakePaymentMethodPrimary(account.id);
    if ((response as AxiosResponse).status === 200) {
      setBankAccountUpdateState(BankAccountUpdateStates.SUCCESSFUL);
      setIsPrimary(!isPrimary);
      updateBankAccounts();
      updateDebitCards();
      handleRefreshPaymentMethods();
    } else {
      setBankAccountUpdateState(BankAccountUpdateStates.ERROR);
    }
  };

  const handleRemoveAccount = () => {
    setType({
      accountType: 'ach_charge',
      accountMask: account.mask,
      institutionName: account.institution_name,
      id: account.id,
      bank_account_id: account.bank_account_id,
      trigger: 'delete',
    });

    setShowRemoveBankAccountModal();
  };

  const lastUpdate = dayjs(account.balance_updated_at);
  const cannotUpdateBalance = lastUpdate.isValid() && dayjs().diff(lastUpdate, 'month') > 2;
  const cannotUpdateBalanceErrorText =
    'We could not synchronize the bank account balance. ' +
    'Please re-link the bank account by updating the bank login';

  return (
    <SidebarWrapper title={title} open={open} setOpen={setOpen}>
      <SideBarBody>
        {account && (
          <div className="flex flex-col space-y-6 text-sm">
            <div>{account.is_primary && <Chip label="Primary" color="success" />}</div>
            <div className="flex w-full">
              <div className="flex basis-6/12 flex-col">
                <p>Bank Name</p>
                <p className="pt-2 font-bold">{account.institution_name}</p>
              </div>
              <div className="flex basis-6/12 flex-col">
                <p>Account Ending in</p>
                <p className="pt-2 font-bold">{account.mask}</p>
              </div>
            </div>
            <div className="flex w-full">
              <div className="flex basis-6/12 flex-col">
                <p>Balance</p>
                <p className="pt-2 font-bold">${account.balance}</p>
              </div>
              <div className="flex basis-6/12 flex-col">
                <p>Last Updated</p>
                <p className="flex flex-row gap-2 pt-2 font-bold">
                  {account.balance_updated_at
                    ? dayjs(account.balance_updated_at).format('LL')
                    : 'Unavailable'}
                  {cannotUpdateBalance && (
                    <MobileFriendlyTooltip title={cannotUpdateBalanceErrorText}>
                      <FontAwesomeIcon icon={faWarning} className="text-red-500" />
                    </MobileFriendlyTooltip>
                  )}
                </p>
              </div>
            </div>
            <div>
              {allowed_primary
                ? !isPrimary && (
                    <Button onClick={handleChecked} color="secondary" size="medium">
                      Make Primary Payment Method
                    </Button>
                  )
                : showBankAccountPrimaryPrompt && (
                    <div className="pt-2">
                      <BankAccountCanNotBePrimaryPrompt
                        clickHandler={() => setShowBankAccountPrimaryPrompt(false)}
                      />
                    </div>
                  )}
            </div>
          </div>
        )}
        <div className="mt-3">
          <BankAccountUpdatePrompt
            status={bankAccountUpdateState}
            setBankAccountUpdateState={setBankAccountUpdateState}
          />
        </div>
        {!account.is_primary && account.state !== 'active' && (
          <div className="mt-3">
            <Plaid
              setError={() => {}}
              buttonProps={{
                className:
                  'cursor-pointer inline-flex items-center px-4 py-3 border text-sm leading-4 ' +
                  'font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 justify-center ' +
                  'text-center w-full shadow-md text-atob-green bg-white hover:bg-gray-50 border-gray-100 rounded-full',
                primary: true,
              }}
              text="Complete Bank Verification"
              pendingBankAccountId={account.bank_account_id}
              onSuccessCallback={onSuccessCallback}
              onLinkingCallback={onLinkingCallback}
            />
          </div>
        )}
      </SideBarBody>
      <UpdateLoginModal
        isActive={showUpdateLoginModal}
        setIsActive={setShowUpdateLoginModal}
        bankName={account.institution_name}
        mask={account.mask}
        bankAccountId={account.bank_account_id}
        handleRefreshPaymentMethods={handleRefreshPaymentMethods}
      />
      <RemovePaymentMethodModal
        isActive={showRemoveBankAccountModal}
        setIsActive={setShowRemoveBankAccountModal}
        message={`Do you want to delete your bank account ${account.institution_name} ending in
        ${account.mask}?`}
        buttonText="Remove Account"
        handleRefreshPaymentMethods={handleRefreshPaymentMethods}
        isPrimary={isPrimary}
      />
      <SideBarFooter>
        {showRemoveBankButton && (
          <Button color="alert" size="small" onClick={handleRemoveAccount}>
            Remove Bank
          </Button>
        )}
        <Button size="small" onClick={() => setShowUpdateLoginModal()}>
          Update Login
        </Button>
      </SideBarFooter>
    </SidebarWrapper>
  );
};

interface BankAccountUpdatePromptProps {
  status: BankAccountUpdateStates;
  setBankAccountUpdateState: (status: BankAccountUpdateStates) => void;
}

const BankAccountUpdatePrompt = ({
  status,
  setBankAccountUpdateState,
}: BankAccountUpdatePromptProps) => {
  if (status === BankAccountUpdateStates.NONE) {
    return null;
  } else if (status === BankAccountUpdateStates.ERROR) {
    return (
      <PaymentMethodsPrompt
        clickHandler={() => setBankAccountUpdateState(BankAccountUpdateStates.NONE)}
        error={true}
      />
    );
  }
  return (
    <PaymentMethodsPrompt
      clickHandler={() => setBankAccountUpdateState(BankAccountUpdateStates.NONE)}
      error={false}
    />
  );
};
