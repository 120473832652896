import MobileNavHomepageHeader from '@app/components/Navigation/MobileNavHomepageHeader';
import CustomerContext from '@app/contexts/customerContext';
import useProduct from '@app/hooks/useProduct';
import { useContext } from 'react';
import AccountOverviewHeader from './AccountOverviewHeader';
import AccountOverviewShared from './AccountOverviewShared';
import CreditAccountOnboarding from './CreditAccountOnboarding';
import CreditAccountOverviewCards from './CreditAccountOverviewCards';

export default function CreditAccountOverview() {
  const { customer } = useContext(CustomerContext);
  const [showWallet, showBillingInfo] = useProduct('treasury', 'billing');

  return (
    <AccountOverviewShared
      onboarding={<CreditAccountOnboarding />}
      header={
        <>
          <MobileNavHomepageHeader />
          <div className="px-4 first:pt-4 md:px-0 md:pt-0">
            <AccountOverviewHeader title={customer.company_name} />
          </div>
        </>
      }
      overviewCards={
        showBillingInfo && (
          <CreditAccountOverviewCards
            hasWallet={showWallet}
            companyName={customer.company_name}
            treasury={customer.treasury}
            customer={customer}
          />
        )
      }
    />
  );
}
