import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { ReactElement } from 'react';

interface EditSpendLimitsButtonProps {
  onClick: () => void;
}
const EditSpendLimitsButton = ({ onClick }: EditSpendLimitsButtonProps): ReactElement => {
  return (
    <Button
      fullWidth
      color="secondary"
      size="small"
      onClick={onClick}
      endIcon={<FontAwesomeIcon className="hidden sm:flex" icon={faPenToSquare} />}
    >
      Edit Spend Limit
    </Button>
  );
};

export default EditSpendLimitsButton;
