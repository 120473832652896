import { PaginatedEndpointResponse } from '@app/@types/api.types';
import { FetcherKey, normalizeFetcherKey } from '@app/utils/data/fetchers';
import axios from 'axios';
import { deserialize } from 'deserialize-json-api';
import useSWR, { SWRResponse } from 'swr';

import type { UpcomingPayment } from '@app/@types/fuel-card-account.types';

export default function useScheduledBillingPayments(): SWRResponse<UpcomingPayment> {
  return useSWR<UpcomingPayment>(
    { url: '/billing/payments/scheduled' },
    async <R extends PaginatedEndpointResponse<UpcomingPayment>>(
      fetcherKey: FetcherKey,
    ): Promise<UpcomingPayment> => {
      const { url, params } = normalizeFetcherKey(fetcherKey);
      const res = await axios.get<R>(url, { params });
      const des = deserialize(res.data) as R;
      const deserializedBillingPaymentsScheduled = des.data;
      const sortedScheduledPayments = [...deserializedBillingPaymentsScheduled].sort((a, b) =>
        a['pay_at'] > b['pay_at'] ? 1 : b['pay_at'] > a['pay_at'] ? -1 : 0,
      );
      return sortedScheduledPayments[0];
    },
  );
}
