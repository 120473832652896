import { DeviceData } from '@app/@types/device.types';
import { ErrorNotification } from '@app/components/layout';
import { ModalBodyContent } from '@atob-developers/shared/src/components/Modal';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import Modal from '@mui/material/Modal';
import axios, { AxiosError } from 'axios';
import { ReactElement, useState } from 'react';

interface ArchiveDeviceModalProps {
  isActive: boolean;
  reset: () => void;
  device: DeviceData;
}

export default function ArchiveDeviceModal({
  isActive,
  reset,
  device,
}: ArchiveDeviceModalProps): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { addToast } = useToasts();

  const deleteDevice = async () => {
    try {
      setIsLoading(true);
      await axios.delete(`/devices/${device.id}`);
      reset();
      addToast(`${device.name} successfully archived`, {
        appearance: 'success',
      });
    } catch (e: unknown) {
      setError((e as AxiosError).message);
    }

    setIsLoading(false);
  };

  return (
    <Modal open={isActive} onClose={reset}>
      <ModalBodyContent>
        <div className="absolute left-1/2 top-1/2 h-auto w-auto -translate-x-1/2 -translate-y-1/2 transform rounded-lg bg-white p-6">
          <div className="mb-6 flex flex-row items-center justify-between">
            <div className="text-xl font-bold text-gray-900">Remove {device.name}</div>
            <button
              type="button"
              className="bg-grey5 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full focus:outline-none"
              onClick={reset}
            >
              <FontAwesomeIcon icon={faXmark} size="1x" color="#676D7C" />
            </button>
          </div>

          {error && <ErrorNotification error={error} />}
          <div className="mt-4 flex w-full flex-col items-baseline justify-between">
            <div>
              <p className="mb-4">This will remove {device.name} from your list of devices.</p>
            </div>
            <div className="my-6 flex w-full justify-center">
              <LoadingButton size="small" loading={isLoading} onClick={deleteDevice}>
                <span>Remove {device.name ?? ''}</span>
              </LoadingButton>
            </div>
          </div>
        </div>
      </ModalBodyContent>
    </Modal>
  );
}
