import * as Sentry from '@sentry/react';
import { CaptureContext } from '@sentry/types';
import { useState } from 'react';

export default function useError(
  shouldLogToSentry: (err: unknown) => boolean,
  captureContext?: CaptureContext,
): [unknown, (err: unknown) => void] {
  const [error, setError] = useState<unknown>(null);

  const setErrorAndLog = (err: unknown) => {
    if (err && shouldLogToSentry(err)) {
      Sentry.captureException(err, captureContext);
    }

    setError(err);
  };

  return [error, setErrorAndLog];
}
