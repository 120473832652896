import { DateRange } from '@mui/lab';
import { Dialog } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import dayjsObjectSupport from 'dayjs/plugin/objectSupport';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { useFormik } from 'formik';
import { ReactElement, useState } from 'react';

import DownloadStatus from '../DownloadStatus';
import { ExportType } from '../exports';
import { useExportJob } from '../useExportJob';
import { ExportConfiguration } from './ExportTransactionsScreens';

dayjs.extend(quarterOfYear);
dayjs.extend(dayjsObjectSupport);

const ExportTransactions = ({
  open,
  onClose,
  selectedFilters,
  initialDateRange,
  initialTransactionType,
  hideFiltersNotice = false,
}: {
  open: boolean;
  onClose: () => void;
  selectedFilters: Record<string, unknown>;
  initialDateRange?: DateRange<Dayjs>;
  initialTransactionType?: 'transactions_generic' | 'transactions_ifta';
  hideFiltersNotice?: boolean;
}): ReactElement => {
  const [state, setState] = useState<'selectType' | 'downloading'>('selectType');

  const formik = useFormik({
    initialValues: {
      type: initialTransactionType ?? 'transactions_generic',
      date: initialDateRange ?? ([dayjs().startOf('quarter'), dayjs()] as DateRange<Dayjs>),
    },
    onSubmit: (_, { setSubmitting }) => {
      setSubmitting(false);
      setState('downloading');
      submitExportJob();
    },
  });

  const { downloadLink, downloadState, submitExportJob } = useExportJob(
    formik.values.type as ExportType,
    {
      start_time: formik.values.date[0] && formik.values.date[0].unix(),
      end_time: formik.values.date[1] && formik.values.date[1].unix(),
      ...selectedFilters,
    },
  );

  const renderScreen = () => {
    switch (state) {
      case 'selectType':
        return (
          <ExportConfiguration
            value={formik.values.type}
            onChange={(type) => formik.setFieldValue('type', type)}
            onContinue={() => formik.handleSubmit()}
            type={formik.values.type}
            date={formik.values.date}
            onDateChange={(date) => formik.setFieldValue('date', date)}
            onClose={onClose}
            hideFiltersNotice={hideFiltersNotice}
          />
        );
      case 'downloading':
        return (
          <DownloadStatus
            downloadState={downloadState}
            downloadLink={downloadLink}
            onClose={onClose}
          />
        );
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      {renderScreen()}
    </Dialog>
  );
};

export default ExportTransactions;
