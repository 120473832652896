import { useTelematicsProvider } from '@app/hooks/query/useConnectTelematics';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProductNudgeBanner } from './Designs/ProductNudgeBanner';

export default function TelematicsNudgeBanner(): ReactElement | null {
  const navigate = useNavigate();
  const { data, isLoading } = useTelematicsProvider();
  if (isLoading) {
    return null;
  }

  const providers = data?.data.providers;
  const connectedProvider = providers?.filter((provider) => provider.connected);
  if (connectedProvider && connectedProvider.length > 0) {
    return null;
  }

  return (
    <>
      <ProductNudgeBanner
        actionText="Connect now"
        bannerKey="integrations-home-nudge"
        variant="pro_tip"
        onClick={() => {
          navigate('/integrations');
        }}
      >
        Connect your telematics provider to automatically sync drivers and vehicles
      </ProductNudgeBanner>
    </>
  );
}
