import { ReactElement } from 'react';
import DefaultPrompt from './DefaultPrompt';

interface PaymentMethodsPromptProps {
  clickHandler: () => void;
  error?: boolean;
  customErrorMessage?: string;
}

const successMessage = 'Payment method information updated successfully!';
const defaultErrorMessage =
  'There was an error updating your payment method information. Please try again';

export default function PaymentMethodsBankAccountPrompt({
  clickHandler,
  error = false,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  customErrorMessage = null,
}: PaymentMethodsPromptProps): ReactElement {
  const errorMessage = customErrorMessage ?? defaultErrorMessage;
  return (
    <DefaultPrompt
      clickHandler={clickHandler}
      message={error ? errorMessage : successMessage}
      error={error}
    />
  );
}
