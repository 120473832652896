import { LoadingButton } from '@mui/lab';
import { ReactElement, useEffect, useState } from 'react';
export const SaveButton = ({
  isSubmitting,
  onSubmit,
  disabled,
}: {
  isSubmitting: boolean;
  disabled: boolean;
  onSubmit: () => void;
}): ReactElement => {
  const [updateRestrictionsInProgress, setUpdateRestrictionsInProgress] = useState(false);

  useEffect(() => {
    if (!isSubmitting) {
      setUpdateRestrictionsInProgress(false);
    }
  }, [isSubmitting]);

  return (
    <div className="flex justify-end p-4">
      <LoadingButton
        loading={updateRestrictionsInProgress}
        type="submit"
        size="small"
        disabled={disabled}
        onClick={() => {
          setUpdateRestrictionsInProgress(true);
          onSubmit();
        }}
      >
        <span>Save</span>
      </LoadingButton>
    </div>
  );
};
