import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';

// See https://mui.com/material-ui/about-the-lab/#typescript for context about this import
import type {} from '@mui/lab/themeAugmentation';
import { ThemeConfiguration } from './themeConfigurations';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    'xxs': true;
    'xs': true;
    'sm': true;
    'md': true;
    'lg': true;
    'xl': true;
    '2xl': true;
  }
}

// Disable variants in order to make the theme more strict according to our designs.
declare module '@mui/material/Button' {
  // `text` buttons are not allowed. Instead, use secondary and primary.
  // Contact the design team if you'd like to remove this restriction.
  interface ButtonPropsVariantOverrides {
    secondary: true;
  }

  interface ButtonPropsSizeOverrides {
    'extra-small': true;
  }

  interface ButtonPropsColorOverrides {
    primary: true;
    secondary: true;
    tertiary: true;
    alert: true;
    // TODO: Remove the comment after replacing all the buttons
    // warning: false;
    // info: false;
    // error: false;
    // success: false;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    primary: true;
    secondary: true;
    tertiary: true;
    alert: true;
    warning: false;
    info: false;
    error: false;
    success: false;
  }

  interface IconButtonPropsSizeOverrides {
    'extra-small': true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    black: true;
    white: true;
    orange: true;
    red: true;
    green: true;
    grey: true;
    // TODO: Remove the comment after replacing all chips
    // primary: false;
    // secondary: false;
    // warning: false;
    // info: false;
    // error: false;
    // success: false;
    // default: false;
  }
}

export default function ThemeProvider({
  children,
  themeConfiguration,
}: {
  children: React.ReactNode;
  themeConfiguration: ThemeConfiguration;
}) {
  return <MUIThemeProvider theme={themeConfiguration.muiTheme}>{children}</MUIThemeProvider>;
}
