import { beginningOfDay } from '@app/components/layout/DatePicker';
import {
  INPUT_BORDER_COLOR,
  INPUT_TEXT_COLORS,
} from '@atob-developers/shared/src/components/constants';
import { InputLabel, MenuItem, Select } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { ReactElement } from 'react';

const getDayOfWeek = (date: Date): string => {
  return `${dayjs(date.toString()).format('dddd')}s`;
};

export const getMonthDate = (date: Date): string => {
  const dateOfMonth = parseInt(dayjs(date.toString()).format('D'));
  const dateMod = dateOfMonth % 10;
  const suffix = dateMod === 1 ? 'st' : dateMod === 2 ? 'nd' : dateMod === 3 ? 'rd' : 'th';

  return `${
    dateOfMonth >= 29
      ? `the ${dateOfMonth}${suffix} (or the last day of the month)`
      : `the ${dateOfMonth}${suffix}`
  }`;
};

type CadenceOptions = 'weekly' | 'monthly';

export const ScheduleAutoTopUp = ({
  cadence,
  setCadence,
  startDate,
  setStartDate,
  errors,
  setErrors,
}: {
  cadence: string;
  setCadence: (cadence: CadenceOptions) => void;
  startDate: Date;
  setStartDate: (date: Date) => void;
  errors: Record<string, string>;
  setErrors: (errors: Record<string, string>) => void;
}): ReactElement => {
  const cadenceOptions: { id: CadenceOptions; label: string; explanation: string }[] = [
    { id: 'weekly', label: 'Weekly', explanation: 'week' },
    { id: 'monthly', label: 'Monthly', explanation: 'month' },
  ];
  const selectedOption = cadenceOptions.find((option) => option.id === cadence);
  const cadenceTime = cadence === 'weekly' ? getDayOfWeek(startDate) : getMonthDate(startDate);

  return (
    <>
      <InputLabel className="block text-sm font-medium text-gray-700">Frequency</InputLabel>
      <Select
        value={selectedOption?.id}
        className="my-2"
        fullWidth
        onChange={(e) => {
          if (e.target.value) {
            setCadence(e.target.value as CadenceOptions);
          }
        }}
        disabled={false}
      >
        {cadenceOptions.map((option) => (
          <MenuItem key={option.id} value={option.id} classes={{ root: 'flex-col items-start' }}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <label className="mb-2 block text-sm font-medium text-gray-700">Starting on</label>
      <DatePicker
        slotProps={{
          textField: {
            size: 'small',
            className: classNames(
              'shadow-sm w-full sm:text-sm rounded-md disabled:bg-light-grey border-1',
              INPUT_TEXT_COLORS['base'],
              INPUT_BORDER_COLOR['base'],
            ),
            inputProps: {
              className: 'block w-full sm:text-sm',
            },
          },
        }}
        value={dayjs(startDate)}
        onChange={(date: Dayjs | null): void => {
          if (dayjs().isAfter(date)) {
            setErrors({ ...errors, startingOn: 'Starting date must be in the future' });
            setTimeout(() => {
              setErrors({ ...errors, startingOn: '' });
            }, 1000);

            return;
          }
          setErrors({ ...errors, startingOn: '' });
          const dayJsObj = dayjs(date);
          dayJsObj.set(beginningOfDay);
          setStartDate(dayJsObj.toDate());
        }}
      />
      <div className="mt-2 text-sm font-medium text-gray-700">
        Deposits will be initiated every{' '}
        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          selectedOption.explanation
        }
        , on {cadenceTime}.
      </div>
      {errors.startingOn && (
        <div className="text-xs font-medium text-red-700">{errors.startingOn}</div>
      )}
    </>
  );
};
