import { EndpointResponse } from '@app/@types/api.types';
import { FetcherKey, apiPostFetcher } from '@app/utils/data/fetchers';
import { AxiosError } from 'axios';
import useSWRMutation, { SWRMutationResponse } from 'swr/mutation';

export type UserEventMutationParam = {
  eventName: string;
};

export function useUserEventMutation(
  eventName: string,
): SWRMutationResponse<
  EndpointResponse<Record<string, never>>,
  AxiosError,
  FetcherKey,
  UserEventMutationParam
> {
  return useSWRMutation<
    EndpointResponse<Record<string, never>>,
    AxiosError,
    FetcherKey,
    UserEventMutationParam
  >(
    {
      url: '/user_events',
      params: {
        event_name: eventName,
      },
    },
    apiPostFetcher,
  );
}
