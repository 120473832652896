import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autocomplete, AutocompleteProps, Chip, TextField } from '@mui/material';

type TagInputProps<
  T,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = Omit<AutocompleteProps<T, true, DisableClearable, FreeSolo>, 'renderInput'> & {
  renderInput?: (
    params: Parameters<AutocompleteProps<T, true, DisableClearable, FreeSolo>['renderInput']>[0],
  ) => React.ReactNode;
};

const TagInput = <
  T,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>(
  props: TagInputProps<T, DisableClearable, FreeSolo>,
) => {
  return (
    <Autocomplete
      {...props}
      multiple
      renderInput={(params) => <TextField {...params} placeholder={props.placeholder} />}
      renderTags={
        props.renderTags
          ? props.renderTags
          : (value, getTagProps) =>
              value.map((option, index) => {
                const { key, ...tagProps } = getTagProps({ index });
                return (
                  <Chip
                    color="white"
                    label={option as string}
                    key={key}
                    {...tagProps}
                    deleteIcon={<FontAwesomeIcon icon={faClose} />}
                  />
                );
              })
      }
    />
  );
};

export default TagInput;
