import logger from '@app/utils/datadog-logger';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

export default function DiscountNetworkSelect({
  disabled,
  selected,
  onSelectDiscountNetwork,
}: {
  disabled: boolean;
  selected: boolean;
  onSelectDiscountNetwork: (discountOnly: boolean) => void;
}) {
  const handleChange = (event: SelectChangeEvent) => {
    const discountOption = event.target.value;
    logger.info('fuel-listing-filter', {
      key: 'discounts',
      active: discountOption === 'discounts',
    });
    onSelectDiscountNetwork(discountOption === 'discounts');
  };

  return (
    <FormControl className="flex-1" disabled={disabled}>
      <InputLabel className="sr-only">AtoB Discount Network</InputLabel>
      <Select
        size="small"
        value={selected ? 'discounts' : 'all'}
        onChange={handleChange}
        renderValue={(value) => (value == 'discounts' ? 'AtoB Savings' : 'All Stations')}
      >
        <MenuItem value="discounts">
          <ListItemText>AtoB Savings Stations</ListItemText>
          {selected && (
            <ListItemIcon>
              <FontAwesomeIcon icon={faCheck} />
            </ListItemIcon>
          )}
        </MenuItem>
        <MenuItem value="all">
          <ListItemText>All Stations</ListItemText>
          {!selected && (
            <ListItemIcon>
              <FontAwesomeIcon icon={faCheck} />
            </ListItemIcon>
          )}
        </MenuItem>
      </Select>
    </FormControl>
  );
}
