import FleetioLogo from '@app/assets/images/svg/fleetio-logo.svg';
import InputField from '@app/components/elements/Fields/InputField';
import Divider from '@atob-developers/shared/src/components/Divider';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { LoadingButton } from '@mui/lab';
import axios, { AxiosError } from 'axios';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { ReactElement } from 'react';
import * as Yup from 'yup';

export default function FleetioConnectForm({
  setFleetioConnection,
}: {
  setFleetioConnection: React.Dispatch<
    React.SetStateAction<{
      account_token: string;
    }>
  >;
}): ReactElement {
  const { addToast } = useToasts();

  const validationSchema = Yup.object({
    account_token: Yup.string().required('Required'),
    api_key: Yup.string().required('Required'),
  });

  const onSubmit = (
    values: { account_token: string; api_key: string },
    {
      setSubmitting,
      setFieldError,
    }: FormikHelpers<{
      account_token: string;
      api_key: string;
    }>,
  ) => {
    axios
      .post(`/fleetio/connect`, {
        account_token: values.account_token,
        api_key: values.api_key,
      })
      .then(() => {
        setFleetioConnection({ account_token: values.account_token });
        addToast('Successfully connected to Fleetio', {
          appearance: 'success',
        });
      })
      .catch((error: AxiosError) => setFieldError('api_key', error.response?.data['error']))
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="flex w-full justify-center">
      <div className="w-[100%] rounded-md bg-white p-8 lg:w-[50%]">
        <div className="flex justify-center pb-12 pt-8">
          <img src={FleetioLogo} alt="Fleetio logo" />
        </div>
        <div className="flex justify-center pb-16">
          <Formik
            initialValues={{
              account_token: '',
              api_key: '',
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(
              props: FormikProps<{
                account_token: string;
                api_key: string;
              }>,
            ) => (
              <form onSubmit={props.handleSubmit} className="w-full max-w-[500px] flex-col">
                <div className="mb-4">
                  <InputField id="account_token" label="Account Token" placeholder="Paste token" />
                </div>
                <div className="mb-8">
                  <InputField id="api_key" label="API Key" placeholder="Paste key" />
                </div>
                <div className="flex w-full justify-center">
                  <LoadingButton color="primary" type="submit" loading={props.isSubmitting}>
                    <span>Connect</span>
                  </LoadingButton>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <Divider className="bg-gray-200" />
        <div className="pb-12 pt-12 text-center font-semibold text-gray-500">
          Watch this tutorial on how to connect
        </div>
        <div className="flex w-full justify-center pb-8">
          <iframe
            title="fleetio-tutorial"
            className="aspect-[9/6] w-[75%] lg:w-[60%]"
            src="https://www.loom.com/embed/570dc6da956e4fe6be2251a1eabe8a27"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}
