import { TruckType } from '@app/@types/telematics.types';
import { Coords } from 'google-map-react';
import { useEffect, useRef } from 'react';
import type { VehicleLocationType } from '../../pages/Integrations/Telematics/TelematicsMaps/TelematicsMapsComponents/MapMarkers';

const setMenuToFixedPosition = (position: string): void => {
  const menu = document.getElementById('navMenu');
  if (menu !== null) {
    menu.style.position = position;
  }
};

// Zoom levels: https://gis.stackexchange.com/questions/7430/what-ratio-scales-do-google-maps-zoom-levels-correspond-to/7443#7443
const MAX_ZOOM_LEVEL = 17;

const panMapToBounds = (
  map: google.maps.Map,
  mapsApi: typeof google.maps,
  points: Coords[],
): void => {
  const markers = points.map((vehicle: Coords) => new mapsApi.LatLng(vehicle.lat, vehicle.lng));

  if (markers.length === 1) {
    map.panTo(markers[0]);
    map.setZoom(14);
    return;
  }

  const bounds = new mapsApi.LatLngBounds();
  markers.forEach((marker) => bounds.extend(marker));
  map.fitBounds(bounds);

  mapsApi.event.addListenerOnce(map, 'bounds_changed', () => {
    // Add a listener to set the zoom level to the maximum allowed, so that more of the map features
    // are displayed. This listener is only triggered once, so it will not interfere with the user
    // changing the zoom level.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (map.getZoom() > MAX_ZOOM_LEVEL) {
      map.setZoom(MAX_ZOOM_LEVEL);
    }
  });
};

const truckIsIdle = (truck: TruckType): boolean => {
  const THIRTY_MINUTES = 1000 * 60 * 30;
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    truck.location.bearing === 0 ||
    new Date().getTime() - new Date(truck.last_seen).getTime() > THIRTY_MINUTES
  );
};

const MERCHANT_RADIUS_CIRCLE_COLOR = '#93c5fd';

// Draws a circle around the merchant to indicate the radius checked.
const drawMerchantRadiusCircle = (
  map: google.maps.Map,
  mapsApi: typeof google.maps,
  latitude: number,
  longitude: number,
  radius_checked_meters: number,
): google.maps.Circle => {
  return new mapsApi.Circle({
    strokeColor: MERCHANT_RADIUS_CIRCLE_COLOR,
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: MERCHANT_RADIUS_CIRCLE_COLOR,
    fillOpacity: 0.7,
    map,
    center: { lat: latitude, lng: longitude },
    radius: radius_checked_meters,
  });
};

export const useMerchantRadiusCircle = (
  map: google.maps.Map | undefined,
  mapsApi: typeof google.maps | undefined,
  position: Coords,
  radius_checked_meters: number,
): google.maps.Circle | undefined => {
  const circleRef = useRef<google.maps.Circle>();

  useEffect(() => {
    if (!map || !mapsApi) return;

    if (circleRef.current) {
      circleRef.current.setMap(null);
    }

    circleRef.current = new mapsApi.Circle({
      strokeColor: MERCHANT_RADIUS_CIRCLE_COLOR,
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: MERCHANT_RADIUS_CIRCLE_COLOR,
      fillOpacity: 0.7,
      map,
      center: position,
      radius: radius_checked_meters,
    });

    return () => {
      if (circleRef.current) {
        circleRef.current.setMap(null);
      }
    };
  }, [map, mapsApi, position, radius_checked_meters]);

  return circleRef.current;
};

// Draws a dotted line between two points (the vehicle and the merchant).
const drawIndicatorPolyline = (
  map: google.maps.Map,
  mapsApi: typeof google.maps,
  latitude: number,
  longitude: number,
  primaryPoint: VehicleLocationType,
): google.maps.Polyline => {
  return new mapsApi.Polyline({
    path: [
      { lat: latitude, lng: longitude },
      {
        lat: parseFloat(primaryPoint.latitude),
        lng: parseFloat(primaryPoint.longitude),
      },
    ],
    geodesic: true,
    strokeColor: '#9ca3af',
    strokeOpacity: 0,
    strokeWeight: 2,
    icons: [
      {
        icon: {
          path: 'M 0,-1 0,1',
          strokeOpacity: 1,
          scale: 4,
        },
        offset: '0',
        repeat: '20px',
      },
    ],
    map,
  });
};

export {
  drawIndicatorPolyline,
  drawMerchantRadiusCircle,
  panMapToBounds,
  setMenuToFixedPosition,
  truckIsIdle,
};
