import useWindowWidth from '@app/hooks/useWindowWidth';
import { faCheck, faX } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import classNames from 'classnames';
import { ReactElement } from 'react';

type Benefit = {
  description: string;
  included: boolean;
  emphasis: boolean;
};
const MonthlyBenefits: Benefit[] = [
  {
    description: 'Full service payroll with tax reporting',
    included: true,
    emphasis: false,
  },
  {
    description: 'Instant Payout option included',
    included: true,
    emphasis: false,
  },
  {
    description: 'Custom calculations & itemizations',
    included: true,
    emphasis: true,
  },
  {
    description: 'Unlimited ACH/Bank Payrolls',
    included: true,
    emphasis: true,
  },
  {
    description: 'Instant Payout Price: $5 for every $1000',
    included: true,
    emphasis: true,
  },
];
const PasYouGoBenefits: Benefit[] = [
  {
    description: 'Full service payroll with tax reporting',
    included: true,
    emphasis: false,
  },
  {
    description: 'Instant Payout option included',
    included: true,
    emphasis: false,
  },
  {
    description: 'No custom calculations & itemizations',
    included: false,
    emphasis: false,
  },
  {
    description: 'No ACH/Bank Payrolls',
    included: false,
    emphasis: false,
  },
  {
    description: 'No Discounted Instant Payout',
    included: false,
    emphasis: false,
  },
];

const SubscriptionPlan = ({
  primary = false,
  planName,
  tagLine,
  description,
  benefits,
  cta,
}: {
  primary?: boolean;
  planName: string;
  tagLine: string;
  description: string;
  benefits: Benefit[];
  cta: string;
}) => {
  const { isMobile } = useWindowWidth();
  const iconSize = isMobile ? '2x' : '1x';
  return (
    <div className="px-2  md:w-full">
      <div className="border-1 w-full rounded-lg border border-solid border-gray-300 pb-6 shadow-md">
        <div
          className={classNames('rounded-tl-lg rounded-tr-lg p-1 text-center md:p-2', {
            'bg-ds-green': primary,
            'bg-gray-300': !primary,
          })}
        >
          <div
            className={classNames('m-auto w-[200px] md:w-fit', {
              'font-bold text-white': primary,
              'text-gray-700': !primary,
            })}
          >
            {tagLine}
          </div>
        </div>
        <div className="m-auto mt-6 max-w-[250px] text-center md:w-fit md:max-w-none md:px-6">
          <div className="mb-6 text-left">
            <div className="mb-2 text-2xl font-bold text-gray-700">{planName}</div>
            <div className="text-green11 text-base font-semibold">{description}</div>
          </div>
          <div className="grid max-w-fit gap-y-6 md:max-w-none">
            {benefits.map(({ description, included, emphasis }) => (
              <div key={description} className="justify-left flex items-center">
                {included ? (
                  <FontAwesomeIcon icon={faCheck} size={iconSize} className="text-ds-green mr-3" />
                ) : (
                  <FontAwesomeIcon icon={faX} size={iconSize} className="text-red4 mr-3" />
                )}
                <div
                  className={classNames('text-left text-base', {
                    'font-bold text-gray-700': emphasis,
                    'text-green11': emphasis,
                  })}
                >
                  {description}
                </div>
              </div>
            ))}
          </div>
          <div className="m-auto mb-4 mt-8">
            <Button size="medium">{cta}</Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default function PayrollSubscription(): ReactElement {
  return (
    <div>
      <div className="mb-10">
        <div className="m-auto mb-4 flex max-w-[250px] flex-col items-center md:max-w-none">
          <h2 className="text-atob-green mb-2 text-center text-[32px] font-[700]">
            Your Free Trial Has Ended
          </h2>
          <div className="text-base text-gray-700">Choose your payroll plan:</div>
        </div>
        <div className="md:flex md:justify-between">
          <SubscriptionPlan
            primary
            tagLine="Perfect for running your entire payroll"
            planName="Monthly Subscription"
            description="For $25/month + $3/month per employee"
            benefits={MonthlyBenefits}
            cta="Continue Monthly"
          />
          <div className="mt-10 md:mt-0" />
          <SubscriptionPlan
            tagLine="Great for occasional reimbursements"
            planName="Pay-As-You-Go"
            description="No monthly fee, $10 per $1000 of Instant Payout"
            benefits={PasYouGoBenefits}
            cta="Start Now"
          />
        </div>
      </div>
    </div>
  );
}
