import axios from 'axios';
import { ReactElement, useState, ReactNode } from 'react';
import OneTimePassword from '../components/OneTimePassword/OneTimePassword';
import { isRetryableError } from '../utils/error/isRetryableError';

export default function OTPValidator({ children }: { children: ReactNode }): ReactElement {
  const [isOpen, setIsOpen] = useState(false);

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      if (isRetryableError(error)) {
        setIsOpen(true);
      }
      return Promise.reject(error);
    },
  );

  return (
    <>
      <OneTimePassword setIsOpen={setIsOpen} isOpen={isOpen} />
      {children}
    </>
  );
}
