import axios from 'axios';
import { useState } from 'react';
import useSWRImmutable from 'swr/immutable';
import { downloadFile } from './downloadFile';
import { ExportType } from './exports';

export type ExportJobStatus = 'completed' | 'error' | 'loading';

export const useExportJob = (
  exportType: ExportType,
  filters: Record<string, unknown>,
): {
  downloadLink: string | null;
  downloadState: ExportJobStatus;
  submitExportJob: () => Promise<void>;
} => {
  const [downloadLink, setDownloadLink] = useState(null);
  const [refreshInterval, setRefreshInterval] = useState(1000);
  const [jobId, setJobId] = useState(null);
  const [submitError, setSubmitError] = useState<Error | null>(null);

  const { data, error } = useSWRImmutable(
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    jobId ? `/customer_export_jobs/${jobId}` : null,
    // eslint-disable-next-line @typescript-eslint/unbound-method
    axios.get,
    {
      refreshInterval,
      onSuccess: (response) => {
        const { data } = response;

        if (data.status !== 'pending') {
          setDownloadLink(data.url);
          downloadFile(data.url);
          setRefreshInterval(0);
        }
      },
    },
  );

  const submitExportJob = async () => {
    try {
      const {
        data: { job_id },
      } = await axios.post(`/customer_export_jobs`, {
        type: exportType,
        ...filters,
        page: undefined,
      });

      setJobId(job_id);
      setSubmitError(null); // Clear any previous submit errors
    } catch (error) {
      setSubmitError(error as Error);
    }
  };

  let state: ExportJobStatus = 'loading';

  if (data?.data?.status === 'ok') {
    state = 'completed';
  } else if (error || submitError) {
    state = 'error';
  }

  return { downloadLink, downloadState: state, submitExportJob };
};
