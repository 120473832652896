import useThemeConfiguration from '@app/app/useThemeConfiguration';
import PayWithAtoBModal from '@app/components/Modals/OnboardingModals/PayWithAtoBModal';
import UberFreightFuelFinderUpsellModal from '@app/components/Modals/OnboardingModals/UberFreightFuelFinderUpsellModal';
import OnboardingStepper from '@app/components/Onboarding/OnboardingStepper';
import AppInfoPopup from '@app/components/popups/components/AppInfoPopup';
import useIsDeviceAppEligible from '@app/components/popups/components/useIsDeviceAppEligible';
import { useIsUberfreight } from '@app/hooks/partners/useIsUberfreight';
import {
  CustomerOnboardingEventName,
  useCreateCustomerOnboardingEvent,
} from '@app/hooks/query/useCustomerOnboardingEvents';
import useChannelPartner from '@app/hooks/useChannelPartner';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import usePlaidForOnboarding from '@app/utils/onboarding/usePlaidForOnboarding';
import useWelcomePopup from '@app/utils/onboarding/useWelcomePopup';
import { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function CreditAccountOnboarding(): ReactElement | null {
  const [deviceType] = useIsDeviceAppEligible();
  const { partnerName } = useChannelPartner();
  const [isUberFreightFuelFinderUpsellModalOpen, setIsUberFreightFuelFinderUpsellModalOpen] =
    useState(false);
  const [isPayWithAtoBModalOpen, setIsPayWithAtoBModalOpen] = useState(false);
  const { hideDismissableBanners } = useThemeConfiguration();
  const isUFCusomter = useIsUberfreight();
  const [shouldShow, welcomePopup] = useWelcomePopup(() => {
    if (hideDismissableBanners || isUFCusomter) {
      return;
    }
    setShowAppPopup(true);
  });
  const [showAppPopup, setShowAppPopup] = useState(false);
  const [isNewOnboardingEnabled] = useFeatureFlags('new_onboarding_enabled');

  const navigate = useNavigate();

  const { isPlaidReady, openPlaidModal } = usePlaidForOnboarding();
  const { trigger: createCustomerOnboardingEvent } = useCreateCustomerOnboardingEvent();

  const connectBankAccountStep = {
    title: 'Connect a bank account',
    onClick: () => {
      isPlaidReady && openPlaidModal();
    },
    customerOnboardingEvent: CustomerOnboardingEventName.PAYMENT_METHOD_CONNECTED,
  };

  const stepGroups = isUFCusomter
    ? [
        [connectBankAccountStep],
        [
          {
            title: 'View discounts in the Fuel Finder',
            onClick: () => {
              setIsUberFreightFuelFinderUpsellModalOpen(true);
            },
            customerOnboardingEvent:
              CustomerOnboardingEventName.UBER_FREIGHT_FUEL_FINDER_IMPRESSION,
          },
          {
            title: 'Pay with the Uber Freight Card',
            onClick: () => {
              setIsPayWithAtoBModalOpen(true);
            },
            customerOnboardingEvent: CustomerOnboardingEventName.FIRST_TRANSACTION_SETTLED,
          },
        ],
      ]
    : [
        [connectBankAccountStep],
        [
          {
            title: 'View discounts in the Fuel Map',
            onClick: () => {
              createCustomerOnboardingEvent({
                customer_onboarding_event: {
                  name: CustomerOnboardingEventName.CUSTOMER_PORTAL_DISCOUNT_AND_PERKS_IMPRESSION,
                },
              });
              navigate('/fuel-map');
            },
            customerOnboardingEvent:
              CustomerOnboardingEventName.CUSTOMER_PORTAL_DISCOUNT_AND_PERKS_IMPRESSION,
          },
          {
            title: `Pay with the ${partnerName} Card`,
            onClick: () => {
              setIsPayWithAtoBModalOpen(true);
            },
            customerOnboardingEvent: CustomerOnboardingEventName.FIRST_TRANSACTION_SETTLED,
          },
        ],
      ];

  if (!isNewOnboardingEnabled) {
    return null;
  }

  return (
    <>
      <PayWithAtoBModal
        open={isPayWithAtoBModalOpen}
        onClose={() => {
          setIsPayWithAtoBModalOpen(false);
        }}
      />
      <UberFreightFuelFinderUpsellModal
        open={isUberFreightFuelFinderUpsellModalOpen}
        onClose={() => {
          setIsUberFreightFuelFinderUpsellModalOpen(false);
        }}
      />
      {shouldShow && welcomePopup}
      {showAppPopup && (
        <AppInfoPopup deviceType={deviceType} isOpen={showAppPopup} setIsOpen={setShowAppPopup} />
      )}
      <OnboardingStepper
        stepGroups={stepGroups}
        completedPartnerText={isUFCusomter ? 'Uber Freight app and card' : 'AtoB app'}
      />
    </>
  );
}
