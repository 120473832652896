import { useSendAppInviteLinkText } from '@app/hooks/query/useSendAppInviteLinkText';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { ReactElement, useState } from 'react';
import InputMask from 'react-input-mask';

const AppUpsellModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}): ReactElement => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const {
    data,
    trigger: sendAppInviteLinkText,
    error: sendAppInviteLinkTextError,
    isMutating,
    reset,
  } = useSendAppInviteLinkText();

  const cleanNumber = (phoneNumber: string) => {
    return phoneNumber.replace(/\D/g, '');
  };

  const submitPhoneNumber = async () => {
    reset();
    sendAppInviteLinkText({ driver: { phone: phoneNumber } });
  };

  const closeButton = (
    <IconButton className="absolute right-4 top-4" onClick={onClose}>
      <FontAwesomeIcon icon={faXmark} className="h-5 w-5" />
    </IconButton>
  );

  return (
    <Dialog maxWidth="xs" open={open} onClose={onClose}>
      <div className="flex flex-row items-center justify-center">
        <div className="bg-onboarding-app-upsell-header h-[224px] w-[500px] bg-cover bg-center md:h-[300px]" />
        <div className="absolute right-0 top-0">{closeButton}</div>
      </div>
      <DialogTitle>
        <h3 className="text-3xl font-semibold leading-8">
          {!data ? 'Download the AtoB App' : 'Link sent to your phone!'}
        </h3>
      </DialogTitle>
      <DialogContent>
        {!data ? (
          <>
            <p className="text-base font-normal">
              Get the steepest fuel discounts –{' '}
              <span className="font-bold">only in the AtoB app</span>. Manage your account, view
              card balance, and much more.
            </p>
            <p className="pt-4 text-base font-semibold">
              Enter your phone number and we'll send you a link to download the AtoB App!
            </p>
            <div className="mt-4 flex h-[56px] items-center rounded-lg border-[1px] border-solid border-gray-200 bg-white">
              <InputMask
                disabled={isMutating}
                mask="(999) 999-9999"
                maskChar=""
                value={phoneNumber}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  reset();

                  const phoneNumber = cleanNumber(e.target.value);
                  setPhoneNumber(phoneNumber);
                }}
              >
                <input
                  value={phoneNumber}
                  className="w-full px-2 text-base placeholder-gray-500 focus-visible:outline-none"
                  placeholder="Phone number"
                />
              </InputMask>
            </div>
            {!isMutating && sendAppInviteLinkTextError && (
              <div className="text-sm text-red-500">
                We've had an error sending a link to this number. Please try again
              </div>
            )}
          </>
        ) : (
          <p className="text-base font-normal">
            Check your phone for an SMS with a link to download the AtoB app.
          </p>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={data ? false : isMutating || phoneNumber.length != 10}
          autoFocus
          fullWidth
          onClick={data ? onClose : submitPhoneNumber}
          size="medium"
        >
          {data != null ? 'Done' : isMutating ? 'Sending link...' : 'Receive download link'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AppUpsellModal;
