// Utility function to download a file from a URL
// https://stackoverflow.com/a/53101767
export function downloadFile(url: string): void {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'Transactions_Export.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
