import Header from '@app/components/Navigation/Header';
import { PageContentWrapper } from '@app/components/wrappers/PageContentWrapper';
import {
  ChargeEventQueryParams,
  ChargeEventsContext,
} from '@app/contexts/ChargeEventsContextComponent';
import { useSortReducer } from '@app/hooks/paging/useSortReducer';
import { Tooltip } from '@atob-developers/shared/src/components/Tooltip';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { debounce } from 'lodash-es';
import { ReactElement, useCallback, useContext, useRef } from 'react';
import SuspiciousActivitiesCardSearch from './SuspiciousActivitiesCardSearch';
import SuspiciousActivitiesPageContent from './SuspiciousActivitiesPageContent';

export default function SuspiciousActivitiesPage({
  resourceTagsEnabled,
}: {
  resourceTagsEnabled: boolean;
}): ReactElement {
  const { sort, toggleSort } = useSortReducer();
  const { queryParams, setQueryParams } = useContext(ChargeEventsContext);

  const setSelectedFilters = useCallback(
    (params: Partial<ChargeEventQueryParams>) => {
      setQueryParams((p) => ({ ...p, ...params, page: 1 }));
    },
    [setQueryParams],
  );

  return (
    <div className="flex h-full flex-col">
      <PageContentWrapper
        header={
          <SuspiciousActivitiesHeader
            cardLastFourSearch={queryParams.like_card_number_last_four ?? ''}
            onSearchByCardLastFour={(cardSearchLastFour) =>
              setSelectedFilters({
                like_card_number_last_four: cardSearchLastFour,
              })
            }
          />
        }
      >
        <SuspiciousActivitiesPageContent
          setCurrentPage={(page) => setQueryParams({ ...queryParams, page })}
          sort={sort}
          toggleSort={toggleSort}
          resourceTagsEnabled={resourceTagsEnabled}
          filters={queryParams}
          onSetFilters={setSelectedFilters}
        />
      </PageContentWrapper>
    </div>
  );
}

const pageContext =
  'These transactions have been flagged as potentially suspicious due to the location of where ' +
  'the charge was made in relation to the geolocation of all the trucks in your fleet.';

const SuspiciousActivitiesHeader = ({
  cardLastFourSearch,
  onSearchByCardLastFour,
}: {
  cardLastFourSearch: string;
  onSearchByCardLastFour: (key: string) => void;
}) => {
  const header = (
    <div className="flex flex-col space-y-4">
      <div className="flex items-center space-x-2">
        <div>Suspicious Activities</div>
        <Tooltip icon={faQuestionCircle} title={pageContext} arrow />
      </div>
    </div>
  );

  const debouncedCardLastFourSearch = useRef(
    debounce((search) => onSearchByCardLastFour(search), 250),
  );

  return (
    <Header
      title={header}
      rightContent={
        <div className="ml-auto flex items-center gap-4">
          <SuspiciousActivitiesCardSearch
            search={cardLastFourSearch}
            onSearch={debouncedCardLastFourSearch.current}
          />
        </div>
      }
    />
  );
};

export const Error = (): ReactElement => <div>There was an issue loading this data</div>;
