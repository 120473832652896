import { VehicleData } from '@app/@types/vehicle.types';
import DefaultExportButton from '@app/components/Exporting/Default/DefaultExportButton';
import Header from '@app/components/Navigation/Header';
import StickyButtonWrapper from '@app/components/wrappers/StickyButtonWrapper';
import { useExtendMobileHeaderContextMenu } from '@app/contexts/MobileNavHeaderMenuContextComponent';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import Searchbar from '@atob-developers/shared/src/components/Searchbar';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Button, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import axios from 'axios';
import { ReactElement, ChangeEvent, useCallback, useMemo, useState } from 'react';

interface VehiclesPageHeaderProps {
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  setShowVehicleModal: () => void;
  data: VehicleData[];
  refreshData: () => void;
}

const VehiclesPageHeader = ({
  handleChange,
  value,
  setShowVehicleModal,
  data,
  refreshData,
}: VehiclesPageHeaderProps): ReactElement => {
  const [ENABLE_SYNC_TELEMATICS] = useFeatureFlags('enable_sync_telematics');
  const [isSyncingTelematics, setIsSyncingTelematics] = useState(false);

  const syncTelematics = useCallback(() => {
    const params = { id: data.map((vehicle) => vehicle.id) };
    setIsSyncingTelematics(true);
    axios.post('vehicles/sync_telematics', params).then(() => {
      refreshData();
      setIsSyncingTelematics(false);
    });
  }, [data, refreshData]);

  const theme = useTheme();
  const fullWidth = useMediaQuery(theme.breakpoints.down('lg'));

  const menuItem = useMemo(
    () => ({
      value: 'Add a vehicle',
      onClick: setShowVehicleModal,
      disabled: () => false,
      order: 1,
    }),
    [setShowVehicleModal],
  );

  useExtendMobileHeaderContextMenu(menuItem);

  return (
    <Header
      title="Vehicles"
      mobileStickyButton={
        <StickyButtonWrapper>
          <Button
            size="small"
            onClick={setShowVehicleModal}
            fullWidth={fullWidth}
            startIcon={<FontAwesomeIcon icon={faPlus as IconProp} size="1x" />}
          >
            Add a vehicle
          </Button>
        </StickyButtonWrapper>
      }
      rightContent={
        <div className="xs:flex-row xs:w-auto flex w-full grow flex-col justify-end gap-2">
          <DefaultExportButton
            selectedFilters={{}} // There are no filters on this page
            exportType="vehicles"
            buttonText="Export Vehicles"
          />
          {ENABLE_SYNC_TELEMATICS && (
            <Tooltip title="AtoB only fetches data from ELD providers when the card is used. Click this to sync ELD data to verify the integration.">
              <LoadingButton
                color="secondary"
                onClick={syncTelematics}
                loading={isSyncingTelematics}
              >
                <p className="px-2">Sync Vehicle Status</p>
              </LoadingButton>
            </Tooltip>
          )}
          <Button
            size="small"
            onClick={setShowVehicleModal}
            startIcon={<FontAwesomeIcon icon={faPlus as IconProp} size="1x" className="w-[15px]" />}
          >
            Add a vehicle
          </Button>
        </div>
      }
      searchBar={
        <Searchbar
          placeholder="Search vehicles"
          name="search"
          onChange={handleChange}
          value={value}
        />
      }
    />
  );
};

export default VehiclesPageHeader;
