import SavingsLogo from '@app/assets/images/svg/atob_savings_no_border.svg';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import { ReactElement, useState } from 'react';
import AtoBSavingsPopup from '../popups/AtoBSavingsPopup';
import { NewFeatureBanner } from './Designs/NewFeatureBanner';

export default function AtoBSavingsBanner(): ReactElement | null {
  const [showAtoBSavingsBanner] = useFeatureFlags('atob_savings_banner');

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  if (!showAtoBSavingsBanner) {
    return null;
  }

  return (
    <>
      <AtoBSavingsPopup isOpen={isPopupOpen} setIsOpen={setIsPopupOpen} />
      <NewFeatureBanner
        onClick={() => setIsPopupOpen(true)}
        actionText="View Savings Nearby"
        bannerKey="atob-savings-banner"
        variant="no_title"
        iconImg={<img src={SavingsLogo} className="h-full w-full" />}
      >
        Introducing AtoB Savings: See when you saved, and when you missed. Use the Fuel Map to see
        savings nearby.
      </NewFeatureBanner>
    </>
  );
}
