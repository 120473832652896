import AppUpsellModal from '@app/components/Modals/OnboardingModals/AppUpsellModal';
import { ReactElement } from 'react';
import MWebToAppPopupView from './MWebToAppPopupView';

export default function AppInfoPopup({
  deviceType,
  isOpen,
  setIsOpen,
}: {
  deviceType: 'android' | 'ios' | 'desktop';
  isOpen: boolean;
  setIsOpen: (b: boolean) => void;
}): ReactElement {
  if (deviceType == 'desktop') {
    return <AppUpsellModal open={isOpen} onClose={() => setIsOpen(false)} />;
  }
  return <MWebToAppPopupView isOpen={isOpen} deviceType={deviceType} setIsOpen={setIsOpen} />;
}
