import { BankAccountPaymentMethodCombined } from '@app/@types/bankAccount.types';
import { DebitCardPaymentMethodCombined } from '@app/@types/debitCard.types';
import {
  CombinedPaymentMethodsResponse,
  TpayAccountPaymentMethod,
} from '@app/@types/payments.types';
import {
  handlePaymentMethodDelete,
  mapBankAndDebitCardData,
} from '@app/components/PaymentMethods/PaymentMethodUtils';
import PaymentMethodsList from '@app/components/PaymentMethods/PaymentMethodsList';
import { ErrorNotification, Loading } from '@app/components/layout';
import CustomerContext from '@app/contexts/customerContext';
import {
  AccountDetailsType,
  DefaultShowModalType,
  PaymentHistoryContext,
} from '@app/contexts/paymentHistoryContext';
import {
  PAYMENT_METHOD_QUERY_KEY,
  usePaymentMethodsQuery,
} from '@app/hooks/query/usePaymentMethodsQuery';
import useProduct from '@app/hooks/useProduct';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { mutate } from 'swr';

export type WalletPaymentMethodInfo = {
  id: string;
  is_primary: boolean;
  accountNumber: string;
  routingNumber: string;
};

const PaymentMethodsV2 = (): ReactElement => {
  const { customer } = useContext(CustomerContext);
  const [error, setError] = useState<Error | null>(null);
  const [bankAccounts, setBankAccounts] = useState<BankAccountPaymentMethodCombined[]>([]);
  const [debitCards, setDebitCards] = useState<DebitCardPaymentMethodCombined[]>([]);
  const [showLoader, setShowLoader] = useState(true);
  const [accountDetails, setAccountDetails] = useState<AccountDetailsType | null>(null);
  const [allowWallet] = useProduct('treasury');
  const customerEnrolledInWallet = customer.treasury?.status === 'enrolled';
  const showWallet = allowWallet && customerEnrolledInWallet;

  const [searchParams] = useSearchParams();
  const defaultShowModal = searchParams.get('default_show_modal') as DefaultShowModalType;

  const [walletData, setWalletData] = useState<WalletPaymentMethodInfo | null>(null);
  const [tpayAccount, setTpayAccount] = useState<TpayAccountPaymentMethod | null>(null);

  const setCombinedData = (response: CombinedPaymentMethodsResponse) => {
    setBankAccounts(response.bankAccounts);
    if (response.debitCards !== undefined) {
      setDebitCards(response.debitCards);
    }
    setShowLoader(false);
  };
  const customerOwnerEmail = customer.owner_email;

  const { error: paymentMethodsError, data: paymentMethodsData } = usePaymentMethodsQuery();

  useEffect(() => {
    if (paymentMethodsData) {
      const { combinedPaymentMethods } = mapBankAndDebitCardData(paymentMethodsData.data || []);
      if (showWallet) {
        const { wallet } = combinedPaymentMethods;

        if (wallet) {
          setWalletData({
            is_primary: wallet.is_primary,
            id: wallet?.id,
            accountNumber: wallet?.payment_method_detail?.financial_account?.last_4,
            routingNumber: wallet?.payment_method_detail?.financial_account?.routing_number,
          });
        }
      }
      if (combinedPaymentMethods.tpayAccount) {
        setTpayAccount(combinedPaymentMethods.tpayAccount);
      }
      setCombinedData(combinedPaymentMethods);
    }

    if (paymentMethodsError) {
      Sentry.captureException(paymentMethodsError, {
        user: {
          request_id: paymentMethodsError?.response?.headers?.['x-request-id'],
          owner_email: customerOwnerEmail,
        },
      });
      setError(paymentMethodsError);
    }
  }, [customerOwnerEmail, paymentMethodsData, paymentMethodsError, showWallet]);

  const onLinkingCallback = (phase: string) => setShowLoader(phase === 'START');

  const { addToast } = useToasts();

  const errorMessage = 'An error occurred removing your account. Please try again.';
  const successMessage = `Success! Your account ending in ${accountDetails?.accountMask} is now removed.`;

  const toast = useCallback(
    (message: string, status: 'success' | 'error') => {
      addToast(message, {
        appearance: status,
      });
    },
    [addToast],
  );

  const requestPaymentMethodDelete = useCallback(
    async (accountId: string) => {
      try {
        await handlePaymentMethodDelete(accountId);
        toast(successMessage, 'success');
      } catch (err) {
        const serverErrorMessage = axios.isAxiosError(err) && err.response?.data?.errors?.join();
        toast(serverErrorMessage || errorMessage, 'error');
      }
    },
    [successMessage, toast],
  );

  const removeAccountHandler = useCallback(async () => {
    if (accountDetails == null) {
      return;
    }
    if (accountDetails.accountType === 'ach_charge') {
      if (accountDetails.trigger !== undefined && accountDetails.trigger === 'delete') {
        await requestPaymentMethodDelete(accountDetails.id);
        const newBankAccounts = bankAccounts.filter((card) => card.id !== accountDetails.id);
        setBankAccounts(newBankAccounts);
      }
    } else {
      await requestPaymentMethodDelete(accountDetails.id);
      const newDebitCards = debitCards.filter((card) => card.id !== accountDetails.id);
      setDebitCards(newDebitCards);
    }
  }, [accountDetails, bankAccounts, debitCards, requestPaymentMethodDelete]);

  let view;
  if (error) {
    view = (
      <ErrorNotification error="We are having issues loading your payment methods. Please try back later." />
    );
  } else if (showLoader) {
    view = <Loading />;
  } else {
    view = (
      <PaymentMethodsList
        bankAccounts={bankAccounts}
        debitCards={debitCards}
        setError={setError}
        handleRefreshPaymentMethods={() => {
          mutate(PAYMENT_METHOD_QUERY_KEY);
        }}
        onLinkingCallback={onLinkingCallback}
        wallet={showWallet && walletData ? walletData : undefined}
        tpayAccount={tpayAccount}
      />
    );
  }

  const paymentHistoryContextValue = useMemo(
    () => ({
      type: accountDetails,
      setType: setAccountDetails,
      setError,
      handleDeletePaymentMethod: removeAccountHandler,
      bankAccounts,
      setBankAccounts,
      debitCards,
      setDebitCards,
      defaultShowModal,
    }),
    [accountDetails, bankAccounts, debitCards, defaultShowModal, removeAccountHandler],
  );

  return (
    <PaymentHistoryContext.Provider value={paymentHistoryContextValue}>
      {view}
    </PaymentHistoryContext.Provider>
  );
};

export default PaymentMethodsV2;
