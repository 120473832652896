import { convertCentsToDollars } from '@atob-developers/shared/src/utils/formatters/CurrencyFormat';
import currency from 'currency.js';
import { ReactElement } from 'react';

const FeeBreakdown = ({
  fee,
  amount,
  total,
}: {
  fee: string;
  amount: string;
  total: string;
}): ReactElement => {
  return (
    <div className="my-4">
      <div className="mb-2 flex items-center justify-between">
        <div className="text-sm font-medium text-gray-700">Amount</div>
        <div className="text-sm font-medium text-gray-700">{amount}</div>
      </div>
      <div className="mb-2 flex items-center justify-between">
        <div className="text-sm font-medium text-gray-700">Fee</div>
        <div className="text-sm font-medium text-gray-700">{fee}</div>
      </div>
      <div className="my-2 border border-gray-200" />
      <div className="mb-2 flex items-center justify-between">
        <div className="text-sm font-bold text-gray-700">Total</div>
        <div className="text-sm font-bold text-gray-700">{total}</div>
      </div>
    </div>
  );
};

const Confirmation = ({
  amount,
  financialInstitution,
  flow,
  fee,
  message,
}: {
  amount: string;
  financialInstitution: string;
  flow: 'deposit' | 'withdrawal';
  fee?: number;
  message?: string;
}): ReactElement => {
  const AtoBWallet = () => (
    <span>
      your <b>AtoB Wallet</b>
    </span>
  );
  const FinancialInstitution = () => <b>{financialInstitution}</b>;
  const isDeposit = flow === 'deposit';

  const formattedFee = fee ? convertCentsToDollars({ value: fee }).format() : '$0.00';
  return (
    <div>
      <div className="mb-8 text-[16px] font-normal">
        <p>
          We will move <b>{amount}</b> from {isDeposit ? <FinancialInstitution /> : <AtoBWallet />}{' '}
          to {isDeposit ? <AtoBWallet /> : <FinancialInstitution />}.
        </p>
        {fee && (
          <FeeBreakdown
            fee={formattedFee}
            amount={amount}
            total={currency(amount).add(currency(formattedFee)).format()}
          />
        )}
        {message && <div className="my-3 text-sm font-medium text-gray-700">{message}</div>}
      </div>
    </div>
  );
};

export default Confirmation;
