import { EndpointResponse } from '@app/@types/api.types';
import { getFetcher } from '@app/utils/data/fetchers';
import useSWR, { SWRResponse } from 'swr';

export type UnlimitedToFlexPromoData = {
  amount_to_charge: {
    cents: number;
    currency: string;
  };
  credit_line_amount: {
    cents: number;
    currency: string;
  };
};

export function useUnlimitedToFlexPromoQuery(): SWRResponse<
  EndpointResponse<UnlimitedToFlexPromoData>
> {
  return useSWR<EndpointResponse<UnlimitedToFlexPromoData>>(
    {
      url: `/treasury/account_promotions/unlimited_to_flex_promo`,
    },
    getFetcher,
  );
}
