import { CustomerData } from '@app/@types/customer.types';
import RadioGroup from '@atob-developers/shared/src/components/RadioButton';
import dayjs from 'dayjs';
import dayjsObjectSupport from 'dayjs/plugin/objectSupport';
import React, { ReactElement } from 'react';
import { NavigateFunction } from 'react-router-dom';

dayjs.extend(dayjsObjectSupport);

export type PropsType = {
  closeModal: () => void;
  customer?: CustomerData;
  timezone?: string;
  resourceTagsEnabled: boolean;
};

export type ErrorType = {
  message: string;
};

export const DateRangePickerData = [
  {
    id: 'today',
    name: 'today',
    label: 'Today',
    value: 'today',
    checked: false,
  },
  {
    id: 'month',
    name: 'month',
    label: 'Current Month',
    value: 'month',
    checked: false,
  },
  {
    id: 'custom',
    name: 'custom',
    label: 'Custom',
    value: 'custom',
    checked: false,
  },
];

export const DateRangePicker = ({
  handleTimerangeChange,
}: {
  handleTimerangeChange: (val: string) => void;
}): ReactElement => {
  return <RadioGroup data={DateRangePickerData} onSelect={(val) => handleTimerangeChange(val)} />;
};

export class ExportModal extends React.Component<
  PropsType & {
    navigate?: NavigateFunction;
  },
  {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
    error: ErrorType | null;
    loading: boolean | null;
    timezone: string;
    custom: boolean | null;
    type: string;
    card_security_enabled: boolean;
    resource_tags_enabled: boolean;
    includeAuthorization: boolean;
  }
> {
  constructor(props: Readonly<PropsType> | PropsType) {
    super(props);
    const card_security_enabled = props.customer?.card_security_enabled || false;

    const resource_tags_enabled = props.resourceTagsEnabled || false;

    const dayJsDate = dayjs();
    dayJsDate.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    const startDate = dayJsDate;
    const endDate = dayJsDate.clone().set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 99,
    });

    this.state = {
      startDate,
      endDate,
      error: null,
      loading: null,
      custom: null,
      timezone: props.timezone
        ? props.timezone
        : Intl.DateTimeFormat().resolvedOptions().timeZone.replace('_', ' '),
      type: 'generic',
      card_security_enabled,
      resource_tags_enabled,
      includeAuthorization: false,
    };
  }

  handleTimerangeChange = (value: string): void => {
    this.setState({ loading: null, error: null });
    if (value === 'today') {
      const dayJsDate = dayjs();
      dayJsDate.set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      const startDate = dayJsDate;
      const endDate = dayJsDate.clone().set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 99,
      });
      this.setState({
        startDate,
        endDate,
        custom: null,
      });
    } else if (value === 'month') {
      const startDate = dayjs().startOf('month');
      const endDate = dayjs().endOf('month');
      this.setState({
        startDate,
        endDate,
        custom: null,
      });
    } else {
      this.setState({
        custom: true,
      });
    }
  };

  closeModal = (): void => {
    const { closeModal } = this.props;
    closeModal();
  };
}
