import { Theme } from '@mui/material';
import defaultTheme from './Themes/defaultTheme';
import triumph from './Themes/triumph';
import uberFreight from './Themes/uberFreight';

export enum ThemeIdentifier {
  UBER_FREIGHT,
  TRIUMPH,
  DEFAULT,
}

export type ThemeConfiguration = {
  // Store theme specific variables here
  id: ThemeIdentifier;
  muiTheme: Theme;
  showAppNav: boolean;
  showFuelPrograms: boolean;
  showFuelProgramsOnDashboard: boolean;
  showSpendAnalysisOnDashboard: boolean;
  showZendesk: boolean;
  showPlans: boolean;
  showCompanyProfile: boolean;
  hideDismissableBanners: boolean;
  hideAtoBPremiumBanner: boolean;

  // Hides components that use local storage.
  supportsLocalStorage: boolean;

  showPremiumUpsell: boolean;

  // Money deposit is forbidden if a partner manages the back account we use for the customer
  allowMoneyDeposit: boolean;

  // Show a card with the total monthly spend on the dashboard page
  showTotalSpend: boolean;

  // If true, clicks on the app will be propagated to the host
  propagateClicksToHost: boolean;
  // If true, path changes will be propagated to the host
  propagatePathChangeToHost: boolean;

  // If true, the user will be able to see the wallet amount
  showPrepaidAvailableToSpend: boolean;

  // Shows the period savings on its own card
  extractPeriodSavingsTile: boolean;

  // Shows Loadpay-specific driver app onboading
  showDriverAppOnboarding: boolean;

  // Shows the onboarding stepper, replaced on Loadpay by the driver app onboarding
  showOnboardingStepper: boolean;

  showBackupPaymentMethodLabels: boolean;
};

export const THEME_CONFIGURATIONS: Record<ThemeIdentifier, ThemeConfiguration> = {
  [ThemeIdentifier.DEFAULT]: {
    id: ThemeIdentifier.DEFAULT,
    muiTheme: defaultTheme,
    showAppNav: true,
    showFuelPrograms: false,
    showFuelProgramsOnDashboard: false,
    showSpendAnalysisOnDashboard: true,
    allowMoneyDeposit: true,
    showTotalSpend: false,
    showZendesk: true,
    showPlans: true,
    showCompanyProfile: true,
    propagateClicksToHost: false,
    propagatePathChangeToHost: false,
    hideDismissableBanners: false,
    showPrepaidAvailableToSpend: true,
    extractPeriodSavingsTile: false,
    supportsLocalStorage: true,
    showPremiumUpsell: true,
    showDriverAppOnboarding: false,
    showOnboardingStepper: true,
    hideAtoBPremiumBanner: false,
    showBackupPaymentMethodLabels: false,
  },
  [ThemeIdentifier.UBER_FREIGHT]: {
    id: ThemeIdentifier.UBER_FREIGHT,
    muiTheme: uberFreight,
    showAppNav: true,
    showFuelPrograms: false,
    showFuelProgramsOnDashboard: false,
    showSpendAnalysisOnDashboard: true,
    allowMoneyDeposit: true,
    showTotalSpend: true,
    showZendesk: false,
    showPlans: false,
    showCompanyProfile: true,
    propagateClicksToHost: false,
    propagatePathChangeToHost: false,
    hideDismissableBanners: false,
    showPrepaidAvailableToSpend: true,
    extractPeriodSavingsTile: false,
    supportsLocalStorage: true,
    showPremiumUpsell: false,
    showDriverAppOnboarding: false,
    showOnboardingStepper: true,
    hideAtoBPremiumBanner: false,
    showBackupPaymentMethodLabels: false,
  },
  [ThemeIdentifier.TRIUMPH]: {
    id: ThemeIdentifier.TRIUMPH,
    muiTheme: triumph,
    showAppNav: false,
    showFuelPrograms: false,
    showFuelProgramsOnDashboard: true,
    showSpendAnalysisOnDashboard: false,
    allowMoneyDeposit: false,
    showTotalSpend: false,
    showZendesk: false,
    showPlans: true,
    showCompanyProfile: false,
    propagateClicksToHost: true,
    propagatePathChangeToHost: true,
    hideDismissableBanners: true,
    showPrepaidAvailableToSpend: false,
    extractPeriodSavingsTile: true,
    supportsLocalStorage: false,
    showPremiumUpsell: true,
    showDriverAppOnboarding: true,
    showOnboardingStepper: false,
    hideAtoBPremiumBanner: true,
    showBackupPaymentMethodLabels: true,
  },
};
