import { PaymentMethodCombined } from '@app/@types/payments.types';
import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import { usePaymentIntent } from '@app/components/StripeElements/PayWithPaymentMethod';
import StripeContext from '@app/contexts/stripeContext';
import Constants from '@app/utils/constants';
import logger from '@app/utils/datadog-logger';
import currency from 'currency.js';
import { ReactElement, useContext, useEffect } from 'react';
import DebitCardReviewPaymentDetails, {
  DebitCardReviewPaymentDetailsProps,
} from './DebitCardReviewPaymentDetails';

const { IntentTypes } = Constants;

const DebitCardReviewPaymentDetailsContainer = (
  props: DebitCardReviewPaymentDetailsProps,
): ReactElement | null => {
  const { setClientSecret } = useContext(StripeContext);
  const { paymentAmount, selectedDebitCard, setPaymentScreen } = props;
  const amountInCents = currency(paymentAmount).intValue;
  const { loading, error: paymentIntentError } = usePaymentIntent(
    true,
    amountInCents,
    null,
    IntentTypes.FUEL_CARD_PAYMENT,
    selectedDebitCard as PaymentMethodCombined,
  );

  // At this point, we have loaded the client secret.
  // In case the user goes back one screen, we need to reset it to null
  // so that we can reset the payment intent.
  // We only want to run this once on component load.
  useEffect(() => {
    setClientSecret(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    logger.info('Loading Debit Card payment intent');
    return (
      <div className="w-full">
        <SpinnerBoundary />
      </div>
    );
  }

  if (paymentIntentError) {
    logger.error('Error Loading Debit Card payment intent', {
      paymentIntentError,
      paymentAmount,
      selectedDebitCard,
    });

    setPaymentScreen('error', paymentIntentError);
    return null;
  }

  return (
    <div>
      <div className="hidden" data-testid="test-amount-cents">
        {amountInCents}
      </div>
      <DebitCardReviewPaymentDetails {...props} />;
    </div>
  );
};

export default DebitCardReviewPaymentDetailsContainer;
