export enum SettingsPageEntityType {
  EMAIL_ADDRESS = 'customer_email',
  PHONE_NUMBER = 'customer_phone_number',
  COMPANY_ADDRESS = 'company_address',
  CARD_SHIPPING_ADDRESS = 'card_shipping_address',
  TELEMATICS_NOTIFICATIONS_ENABLED = 'telematics_notifications_enabled',
  CUSTOMER_COMPANY_PREFERRED_NAME = 'customer_company_preferred_name',
  FLEET_TYPE = 'fleet_type',
  OTP_BACKUP_EMAIL = 'otp_backup_email',
}

export enum SettingsPageAddressType {
  COMPANY_ADDRESS = 'company_address',
  CARD_SHIPPING_ADDRESS = 'card_shipping_address',
}
