import { PayrollItemizationBackendEnums } from '@app/@types/payroll.types';
import { PaymentCalculation } from '@app/pages/Payroll/PaymentFlow/PaymentCalculation';
import {
  PayrollItemization,
  PayrollItemizationCategory,
  PayrollItemizationSubcategory,
} from '@app/pages/Payroll/PaymentFlow/PaymentItemization';

export const sumItemizationCategory = (items: PayrollItemization[]): number => {
  let sum = items.reduce((acc, cur) => acc + cur.amount_cents, 0);
  if (items[0].category === 'Deduction') {
    sum = sum * -1;
  }
  return sum;
};

export const sumPayrollPayment = (paymentDetails: PaymentCalculation): number => {
  return paymentDetails.itemizations.reduce((acc, item) => {
    if (item.category === 'Deduction') {
      return acc - item.amount_cents;
    }
    return acc + item.amount_cents;
  }, paymentDetails.amount_cents);
};

export const mapToBackendItemizationEnum = (
  subcategory: PayrollItemizationSubcategory,
  category: PayrollItemizationCategory,
): PayrollItemizationBackendEnums => {
  switch (subcategory) {
    case 'Bonus':
      return 'itemization_bonus';
    case 'Advance':
      return 'itemization_advance';
    case 'Maintenance':
      return 'itemization_deduction_maintenance';
    case 'Insurance':
      return 'itemization_deduction_insurance';
    case 'Dispatch Fee':
      return 'itemization_deduction_dispatch_fee';
    case 'Towing':
      return 'itemization_deduction_towing';
    case 'Fuel':
      return 'itemization_reimbursement_fuel';
    case 'Hotel & Meals':
      return 'itemization_reimbursement_hotel_meals';
    case 'Vendor':
      return 'itemization_reimbursement_vendor';
    case 'Transportation':
      // eslint-disable-next-line @cspell/spellchecker
      return 'itemization_reimbursement_transporation';
  }
  if (subcategory === 'Other') {
    if (category === 'Deduction') {
      return 'itemization_deduction_other';
    } else {
      return 'itemization_reimbursement_other';
    }
  }

  return 'itemization_reimbursement_other';
};

export function mapFromItemizationBackendEnum(
  calculation_method: PayrollItemizationBackendEnums,
): PayrollItemizationSubcategory {
  switch (calculation_method) {
    case 'itemization_deduction_maintenance':
      return 'Maintenance';
    case 'itemization_deduction_insurance':
      return 'Insurance';
    case 'itemization_deduction_dispatch_fee':
      return 'Dispatch Fee';
    case 'itemization_deduction_towing':
      return 'Towing';
    case 'itemization_deduction_other':
      return 'Other';
    case 'itemization_reimbursement_hotel_meals':
      return 'Hotel & Meals';
    case 'itemization_reimbursement_fuel':
      return 'Fuel';
    case 'itemization_reimbursement_vendor':
      return 'Vendor';
    // eslint-disable-next-line @cspell/spellchecker
    case 'itemization_reimbursement_transporation':
      return 'Transportation';
    case 'itemization_reimbursement_other':
      return 'Other';
    case 'itemization_bonus':
      return 'Bonus';
    case 'itemization_advance':
      return 'Advance';
  }
}
