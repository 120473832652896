import stripeErrorHandler from '@app/components/StripeElements/stripe-error-handler';
import logger from '@app/utils/datadog-logger';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { STRIPE_STATUS_SUCCEEDED } from './constants';

export const submitDebitCardPayment = ({
  stripe,
  elements,
  setPaymentScreen,
  clientSecret,
  setSubmitting,
}: {
  stripe: Stripe | null;
  elements: StripeElements | null;
  setPaymentScreen: (screen: 'edit' | 'review' | 'success' | 'error', message?: string) => void;
  clientSecret: string;
  setSubmitting: (value: boolean) => void;
}): void => {
  logger.info('Begin submitting debit card payment');
  setSubmitting(true);
  if (!stripe || !elements) {
    setPaymentScreen(
      'error',
      'We were unable to load your card details. Please try again. If the error persists, please contact our support team.',
    );
    logger.error('Debit card payment submission failed - no Stripe or no Elements present');
    return;
  }

  stripe
    .confirmCardPayment(clientSecret)
    .then(async (savedCardPaymentIntentResponse) => {
      const { paymentIntent, error: paymentError } = savedCardPaymentIntentResponse;
      const status = paymentIntent?.status;
      const hasStripeHandledError = stripeErrorHandler(
        (err: string) => setPaymentScreen('error', err),
        paymentError,
      );
      if (hasStripeHandledError) {
        return;
      }

      if (status !== STRIPE_STATUS_SUCCEEDED) {
        setPaymentScreen(
          'error',
          'We could not process your payment. Please try again. If the error persists, please contact our support team.',
        );
        logger.error(
          'Debit card payment submission failed: unsuccessful payment intent confirmation.',
          {
            stripeStatus: status,
            paymentIntent,
            paymentError,
          },
        );
      } else {
        setPaymentScreen('success');
        logger.info('Debit card payment submission succeeded');
      }
    })
    .catch((error) => {
      const hasStripeHandledError = stripeErrorHandler(
        (err: string) => setPaymentScreen('error', err),
        error,
      );

      logger.error('Debit card payment submission failed: catch-all error', {
        error,
        message: error?.message,
      });

      if (hasStripeHandledError) {
        return;
      }

      setPaymentScreen('error', 'There was a problem processing your payment. Please try again.');
    })
    .finally(() => setSubmitting(false));
};
