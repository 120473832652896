import useChannelPartner from '@app/hooks/useChannelPartner';
import { ReactElement } from 'react';

function UnverifiedUser(): ReactElement {
  const { partnerName } = useChannelPartner();
  return (
    <div className="content">
      <h1 className="title is-size-2">Welcome to {partnerName}!</h1>
      <div className="columns">
        <div className="column is-two-thirds">
          It looks like your email address is unverified. For security purposes, we require that all
          users have a verified email.
          <br />
          <br />
          Please check your email inbox and click on the verification link.
        </div>
      </div>
    </div>
  );
}

export default UnverifiedUser;
