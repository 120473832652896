import { DailyTransactionSummaryData } from '@app/@types/transaction.types';
import dayjs from 'dayjs';
import { ReactElement, useEffect, useState } from 'react';
import SpendChart from './SpendChart';
import { getTransactionSummary } from './fetch-overview-data';

const SpendAnalysis = ({
  cardId,
  allowViewTransactions,
}: {
  cardId?: number;
  allowViewTransactions: boolean;
}): ReactElement => {
  const [dailyTransactionSummary, setDailyTransactionSummary] = useState<
    DailyTransactionSummaryData[] | null
  >(null);
  const [periodLength, setPeriodLength] = useState(7);
  const [startAt, setStartAt] = useState<string | null>(null);
  const [endAt, setEndAt] = useState(
    dayjs().startOf('isoWeek').add(6, 'days').format('YYYY-MM-DD'),
  );

  useEffect(() => {
    setStartAt(
      dayjs(endAt)
        .subtract(periodLength - 1, 'days')
        .day(1)
        .format('YYYY-MM-DD'),
    );
  }, [endAt, periodLength]);

  useEffect(() => {
    if (!startAt || !endAt) {
      return;
    }

    const fetchTransactions = async () => {
      try {
        const transactionSummary = await getTransactionSummary({ startAt, endAt, cardId });
        setDailyTransactionSummary(transactionSummary);
      } catch (e) {
        // setError(e);
      }
    };

    fetchTransactions();
  }, [startAt, endAt, cardId]);

  return (
    <>
      {dailyTransactionSummary && startAt && (
        <SpendChart
          transactions={dailyTransactionSummary}
          allowViewTransactions={allowViewTransactions}
          startAt={startAt}
          setStartAt={setStartAt}
          endAt={endAt}
          setEndAt={setEndAt}
          periodLength={periodLength}
          setPeriodLength={setPeriodLength}
        />
      )}
    </>
  );
};

export default SpendAnalysis;
