import { PaginatedEndpointResponse } from '@app/@types/api.types';
import { PaymentMethodCombined } from '@app/@types/payments.types';
import {
  isActiveDebitCard,
  isBankAccount,
} from '@app/components/PaymentMethods/PaymentMethodUtils';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  CustomerOnboardingEventName,
  useCreateCustomerOnboardingEvent,
} from './query/useCustomerOnboardingEvents';
import { usePaymentMethodsQuery } from './query/usePaymentMethodsQuery';

const useNoPaymentMethods = (
  ownerEmail: string,
): {
  paymentMethods: PaginatedEndpointResponse<PaymentMethodCombined> | undefined;
  noDebitCards: boolean;
  noBankAccounts: boolean;
  isLoading: boolean;
  refreshPaymentMethods: () => void;
} => {
  const {
    error: paymentMethodsError,
    data: paymentMethods,
    isLoading,
    isValidating,
    mutate,
  } = usePaymentMethodsQuery();
  const { trigger: createCustomerOnboardingEvent } = useCreateCustomerOnboardingEvent();

  const noBankAccounts = isLoading || !paymentMethods?.data.find(isBankAccount);
  const noDebitCards = isLoading || !paymentMethods?.data.find(isActiveDebitCard);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (paymentMethodsError) {
      Sentry.captureException(paymentMethodsError, {
        user: {
          request_id: paymentMethodsError?.response?.headers?.['x-request-id'],
          owner_email: ownerEmail,
        },
      });
    }
    if (!noBankAccounts) {
      createCustomerOnboardingEvent({
        customer_onboarding_event: {
          name: CustomerOnboardingEventName.PAYMENT_METHOD_CONNECTED,
        },
      });
    }
  }, [
    createCustomerOnboardingEvent,
    isLoading,
    noBankAccounts,
    ownerEmail,
    paymentMethods,
    paymentMethodsError,
  ]);

  return {
    paymentMethods,
    noDebitCards,
    noBankAccounts,
    refreshPaymentMethods: mutate,
    isLoading: isLoading || isValidating,
  };
};

export default useNoPaymentMethods;
