import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { useEffect, useState } from 'react';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

interface DatePeriodControlProps {
  startAt: string | number | dayjs.Dayjs | Date;
  endAt: string | number | dayjs.Dayjs | Date;
  setStartAt: (newStartAt: string) => void;
  setEndAt: (newEndAt: string) => void;
  periodLength: number;
  setPeriodLength: (period: number) => void;
}

const DatePeriodControl = ({
  startAt,
  endAt,
  setStartAt,
  setEndAt,
  periodLength,
  setPeriodLength,
}: DatePeriodControlProps) => {
  const [forwardDisabled, setForwardDisabled] = useState(false);

  const handleBackClick = () => {
    const newStartAt = dayjs(startAt).subtract(periodLength, 'days');
    setStartAt(newStartAt.format('YYYY-MM-DD'));
    setEndAt(newStartAt.add(periodLength - 1, 'days').format('YYYY-MM-DD'));
  };

  const handleForwardClick = () => {
    const newStartAt = dayjs(startAt).add(periodLength, 'days');

    if (!newStartAt.isSameOrBefore(dayjs(), 'day')) return;

    setStartAt(newStartAt.format('YYYY-MM-DD'));
    setEndAt(newStartAt.add(periodLength - 1, 'days').format('YYYY-MM-DD'));
  };

  useEffect(() => setForwardDisabled(dayjs(endAt).isSameOrAfter(dayjs())), [endAt]);

  return (
    <div className="flex flex-col items-center">
      <div>
        <button
          type="button"
          className=" whitespace-no-wrap is-small mr-1 cursor-pointer justify-center rounded border border-gray-200 bg-white p-2 pl-4 pr-4 text-center text-base text-gray-800"
          onClick={handleBackClick}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <button
          type="button"
          className=" whitespace-no-wrap is-small mr-1 cursor-pointer justify-center rounded border border-gray-200 bg-white p-2 pl-4 pr-4 text-center text-base text-gray-800"
        >
          {dayjs(startAt).format('MMM D')}
          {' - '}
          {dayjs(endAt).format('MMM D')}
        </button>
        <button
          type="button"
          className=" whitespace-no-wrap is-small mr-1 cursor-pointer justify-center rounded border border-gray-200 bg-white p-2 pl-4 pr-4 text-center text-base text-gray-800"
          onClick={handleForwardClick}
          disabled={forwardDisabled}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
      <FormControl
        className="control mt-2"
        onChange={(e) => setPeriodLength(Number((e.target as HTMLInputElement).value))}
      >
        <RadioGroup defaultValue="7" className="flex flex-row">
          <FormControlLabel id="7-days" value="7" label="7-days" control={<Radio />} />
          <FormControlLabel id="14-days" value="14" label="14-days" control={<Radio />} />
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default DatePeriodControl;
