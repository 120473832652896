import { datadogLogs } from '@datadog/browser-logs';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import React from 'react';
import './setupi18n';
import { CookiesProvider } from 'react-cookie';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter as Router,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { Userpilot } from 'userpilot';
import App from './app/App';
import AuthProvider from './app/AuthProvider';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import './index.css';
import { isProduction } from './utils/environment';

const VITE_USERPILOT_API_KEY = import.meta.env.VITE_USERPILOT_API_KEY;

if (isProduction()) {
  Sentry.init({
    dsn: String(import.meta.env.VITE_SENTRY_DSN),
    release: import.meta.env.VITE_GIT_SHA as string,
    integrations: [
      new Sentry.Replay({
        maskAllText: false,
      }),
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  });

  datadogLogs.init({
    clientToken: String(import.meta.env.VITE_DATADOG_FRONTEND_LOGGING_CLIENT_TOKEN),
    site: String(import.meta.env.VITE_DATADOG_SITE_PARAMETER),
    forwardErrorsToLogs: true,
    service: 'atob-frontend-production',
    version: import.meta.env.VITE_GIT_SHA as string,
  });

  Userpilot.initialize(VITE_USERPILOT_API_KEY as string);
}

axios.defaults.baseURL = String(import.meta.env.VITE_API_URL);
// Enable cookies for XHR. https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/withCredentials
axios.defaults.withCredentials = true;

axios.interceptors.request.use(function (config) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  config.headers['Origin-Full-Path'] = window.location.toString();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  config.headers['Git-SHA'] = import.meta.env.VITE_GIT_SHA;
  return config;
});

const container = document.getElementById('app-root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <Router>
    <CookiesProvider>
      <AuthProvider>
        <React.StrictMode>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </React.StrictMode>
      </AuthProvider>
    </CookiesProvider>
  </Router>,
);
