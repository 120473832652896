import {
  CustomerOnboardingEventName,
  useCreateCustomerOnboardingEvent,
} from '@app/hooks/query/useCustomerOnboardingEvents';
import Constants from '@app/utils/constants';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { ReactElement } from 'react';

const UberFreightFuelFinderUpsellModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}): ReactElement => {
  const { trigger: createCustomerOnboardingEvent, reset: resetCustomerOnboardingEvent } =
    useCreateCustomerOnboardingEvent();

  const isMobile =
    /Android/i.test(navigator.userAgent) || /iPhone|iPod|iPad/i.test(navigator.userAgent);

  const onClickGoToCarrierTab = async () => {
    resetCustomerOnboardingEvent();
    if (isMobile) {
      createCustomerOnboardingEvent({
        customer_onboarding_event: {
          name: CustomerOnboardingEventName.UBER_FREIGHT_FUEL_FINDER_IMPRESSION,
        },
      });
    }

    window.open(Constants.ChannelPartners.UBER_FREIGHT_CARRIER_MANAGEMENT_DEEPLINK);
    onClose();
  };

  const closeButton = (
    <IconButton className="absolute right-4 top-4" onClick={onClose}>
      <FontAwesomeIcon icon={faXmark} className="h-5 w-5" />
    </IconButton>
  );

  return (
    <Dialog maxWidth="xs" open={open} onClose={onClose}>
      <div className="flex flex-row items-center justify-center">
        <div className="bg-onboarding-uber-freight-fuel-finder-upsell-header h-[224px] w-[500px] bg-cover bg-center md:h-[300px]" />
        <div className="absolute right-0 top-0">{closeButton}</div>
      </div>
      <DialogTitle>
        <h3 className="text-xl font-bold md:text-2xl">Open Fuel Finder</h3>
      </DialogTitle>
      <DialogContent>
        <div className="text-sm font-normal">
          Go to the Uber Freight app's <b>Carrier</b> tab and tap on <b>Fuel Finder</b> to see
          discounts.
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus fullWidth onClick={onClickGoToCarrierTab} color="primary">
          Go to Carrier Tab
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UberFreightFuelFinderUpsellModal;
