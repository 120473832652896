import { EndpointResponse } from '@app/@types/api.types';
import { ChargeEventData } from '@app/@types/charge_events.types';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import useSWR, { SWRResponse } from 'swr';

export const useChargeEventQuery = (
  id?: string,
): SWRResponse<EndpointResponse<ChargeEventData>, Error> => {
  return useSWR<EndpointResponse<ChargeEventData>>(
    id && { url: `/charge_events/${id}`, params: { include: 'tag', transactor_name: true } },
    apiGetFetcher,
  );
};
