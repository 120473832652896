import axios from 'axios';

const triggerResendPhoneVerification = (
  driverId: number,
  callback: (() => void) | null = null,
): void => {
  axios.put(`/drivers/${driverId}/resend_verification`).finally(() => {
    if (callback !== null) {
      callback();
    }
  });
};

export default triggerResendPhoneVerification;
