import { ReactNode } from 'react';

const WarningNotification = ({ children }: { children: ReactNode }) => {
  return (
    <section>
      <div className="container">
        <div className="mb-4 border-l-4 border-yellow-400 bg-yellow-50 p-4">
          <div className="flex">
            <div className="ml-3">
              <div className="text-sm text-yellow-700">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WarningNotification;
