import { useStripe } from '@stripe/react-stripe-js';
import { useSubmitStripePayment } from './use-submit-stripe-payment';

export default function useSubmitAccountPayment(
  onSuccess: () => void,
): [() => void, boolean, string | null] {
  const stripe = useStripe();
  return useSubmitStripePayment(
    onSuccess,
    (secret) => stripe?.confirmUsBankAccountPayment(secret),
    ['succeeded', 'processing'],
  );
}
