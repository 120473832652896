import { PaginatedEndpointResponse } from '@app/@types/api.types';
import ExperianLogo from '@app/assets/images/svg/experian-logo.svg';
import BusinessCreditReportingLearnMoreModal from '@app/components/Modals/AccountOverviewModals/BusinessCreditReportingLearnMoreModal';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import useCustomer from '@app/hooks/useCustomer';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import { formatDateNoDay } from '@app/utils/formatDate';
import { faLeaf } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip } from '@mui/material';
import { ReactElement, useState } from 'react';
import useSWR from 'swr';

type CreditReport = {
  id: string;
  customer_id: string;
  report_date: string;
  payment_terms: string;
  account_balance_cents: number;
  aging_current_percentage: number;
  aging_1_30_days_percentage: number;
  aging_31_60_days_percentage: number;
  aging_61_90_days_percentage: number;
  aging_91_120_days_percentage: number;
  aging_120_plus_days_percentage: number;
};

export default function BusinessCreditReporting(): ReactElement {
  const { data: creditReports, isLoading } = useSWR<PaginatedEndpointResponse<CreditReport>>(
    { url: `/credit_reports` },
    apiGetFetcher,
  );
  const [learnMoreModalOpen, setLearnMoreModalOpen] = useState(false);
  const customer = useCustomer();
  if (isLoading) {
    return <SpinnerBoundary />;
  }

  return (
    <>
      <div>
        <p className="text-sm text-gray-500">
          It takes Experian up to 4 weeks to process and display the trade line in your report.{' '}
          <a onClick={() => setLearnMoreModalOpen(true)} className="underline">
            Learn more
          </a>
        </p>
        {customer.activated_at && new Date(customer.activated_at).getFullYear() < 2023 && (
          <p className="text-sm text-gray-500">
            Only reports uploaded for 2023 shown here. For reporting before 2023, reach out to{' '}
            <a href="mailto:support@atob.com" className="underline">
              support@atob.com
            </a>
          </p>
        )}
        {creditReports?.data.length === 0 && (
          <div className="pt-24 text-center text-base text-gray-400">
            <FontAwesomeIcon icon={faLeaf} size="2x" />
            <p className="mt-3">Nothing to show yet...</p>
          </div>
        )}
        {creditReports && creditReports.data.length > 0 && (
          <div className="mt-6 flex flex-col gap-2">
            {creditReports.data.map((creditReport) => (
              <RoundedCard
                key={creditReport.id}
                className="triumph:flex-row triumph:flex-wrap triumph:flex-wrap-reverse triumph:items-end triumph:justify-between flex w-full flex-col gap-2 p-4 text-sm"
              >
                <img
                  alt="Experian Logo"
                  src={ExperianLogo}
                  className="triumph:order-last w-[100px]"
                />
                <div>
                  <p className="font-semibold">
                    {formatDateNoDay(new Date(creditReport.report_date))} Report
                  </p>
                  <p>Term: {creditReport.payment_terms}</p>
                  <p>Account Balance: ${creditReport.account_balance_cents / 100}</p>
                  <div className="flex flex-wrap items-center gap-2">
                    <p>Days Beyond Terms Percentage:</p>
                    {(
                      [
                        ['Current', creditReport.aging_current_percentage],
                        ['1-30', creditReport.aging_1_30_days_percentage],
                        ['31-60', creditReport.aging_31_60_days_percentage],
                        ['61-90', creditReport.aging_61_90_days_percentage],
                        ['91-120', creditReport.aging_91_120_days_percentage],
                        ['120+', creditReport.aging_120_plus_days_percentage],
                      ] as Array<[string, number]>
                    ).map(([label, percentage]) => {
                      const roundedPercentage = Math.round(percentage);
                      return (
                        <Chip
                          key={label}
                          label={`${label} (${roundedPercentage}%)`}
                          color="default"
                        />
                      );
                    })}
                  </div>
                </div>
              </RoundedCard>
            ))}
          </div>
        )}
      </div>
      <BusinessCreditReportingLearnMoreModal
        open={learnMoreModalOpen}
        onClose={() => {
          setLearnMoreModalOpen(false);
        }}
      />
    </>
  );
}
