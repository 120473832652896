import logger from '@app/utils/datadog-logger';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { ReactElement, useState } from 'react';

export enum CardWorkflowState {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PROVISIONING = 'provisioning',
}

interface ToggleCardActivationProps {
  cardWorkflowState: CardWorkflowState;
  cardId: number;
  onCardChange: () => void;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
const getToggleActivationEndpoint = (cardWorkflowState: CardWorkflowState): string => {
  switch (cardWorkflowState) {
    case CardWorkflowState.ACTIVE:
      return 'make_inactive';
    case CardWorkflowState.INACTIVE:
      return 'activate';
  }
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
const getNewWorkflowState = (cardWorkflowState: CardWorkflowState): CardWorkflowState => {
  switch (cardWorkflowState) {
    case CardWorkflowState.ACTIVE:
      return CardWorkflowState.INACTIVE;
    case CardWorkflowState.INACTIVE:
      return CardWorkflowState.ACTIVE;
  }
};

export const ToggleCardActivation = ({
  cardWorkflowState,
  cardId,
  onCardChange,
}: ToggleCardActivationProps): ReactElement => {
  const [workflowState, setWorkflowState] = useState(cardWorkflowState);
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToasts();

  const toggleCardActivation = () => {
    setIsLoading(true);
    const endpoint = getToggleActivationEndpoint(workflowState);

    logger.info('Toggling card activation', { cardId, endpoint });
    axios
      .put(`/cards/${cardId}/${endpoint}`)
      .then(() => {
        const newState = workflowState === CardWorkflowState.ACTIVE ? 'deactivated' : 'activated';
        logger.info('Toggled card activation', { cardId, endpoint });
        addToast(`Card has been ${newState}`, { appearance: 'success' });
        setWorkflowState(getNewWorkflowState(workflowState));
      })
      .catch((error) => {
        logger.error('Failed toggling card activation', { cardId, endpoint });
        const backendError =
          error?.response?.data?.errors.length > 0 ? error.response.data.errors[0] : null;
        let userFacingError = 'Your card could not be activated.';
        if (backendError === 'Customer has not met activation criteria') {
          userFacingError =
            "It looks like your account isn't ready to activate cards. Connect a bank account to proceed, or contact support for help.";
        }
        addToast(userFacingError, { appearance: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (workflowState === CardWorkflowState.ACTIVE) {
    return (
      <LoadingButton
        className="w-full"
        color="alert"
        size="small"
        loading={isLoading}
        onClick={() => {
          toggleCardActivation();
          onCardChange();
        }}
      >
        <span>Deactivate Card</span>
      </LoadingButton>
    );
  }

  return (
    <LoadingButton
      className="w-full"
      size="medium"
      loading={isLoading}
      disabled={workflowState === CardWorkflowState.PROVISIONING}
      onClick={() => {
        toggleCardActivation();
        onCardChange();
      }}
    >
      <span>Activate Card</span>
    </LoadingButton>
  );
};

export default ToggleCardActivation;
