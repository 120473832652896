import { VehicleData } from '@app/@types/vehicle.types';
import { useCallback, useReducer, useState } from 'react';

const SET_VEHICLE = 'set-vehicle' as const;
const SET_VEHICLE_PROPERTY = 'set-vehicle-property' as const;
const SET_ERRORS = 'set-errors' as const;

export type VehicleState = Partial<VehicleData> & {
  // If the ID is null, we're creating a new vehicle
  id: number | null;
  name: string;
  errors: string[];
};

type VehicleAction =
  | {
      type: typeof SET_VEHICLE_PROPERTY;
      payload: {
        key: keyof VehicleState;
        value: string;
      };
    }
  | {
      type: typeof SET_VEHICLE;
      payload: Partial<VehicleState>;
    }
  | {
      type: typeof SET_ERRORS;
      payload: string[];
    };

function vehicleReducer(state: VehicleState, action: VehicleAction): VehicleState {
  switch (action.type) {
    case SET_VEHICLE_PROPERTY: {
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    }
    case SET_VEHICLE: {
      return {
        ...state,
        ...action.payload,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        id: action.payload.id || null,
        errors: [],
      };
    }
    case SET_ERRORS: {
      return {
        ...state,
        errors: action.payload,
      };
    }
  }
}

export type VehicleReducer = {
  vehicle: VehicleState;
  setVehicle: (vehicle?: Partial<VehicleState>) => void;
  setVehicleProperty: (key: keyof VehicleData, value: string) => void;
  editing: boolean;
  toggleEditing: () => void;
  setEditing: (editing: boolean) => void;
  setErrors: (errors: string[]) => void;
};

export type VehicleReducerProps = Partial<
  VehicleState & {
    editing: boolean;
  }
>;

export function useVehicleFormReducer(props: VehicleReducerProps = {}): VehicleReducer {
  // props could be null and default argument values only work on undefined
  props ||= {};

  const { editing: initialEditing = false, ...vehicle } = props;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const [state, dispatch] = useReducer(vehicleReducer, {
    ...vehicle,
    id: vehicle.id || null,
    name: vehicle.name || '',
  });

  const [editing, setEditing] = useState(initialEditing);

  const setVehicle = useCallback((vehicle: Partial<VehicleState>) => {
    vehicle ||= {};
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    dispatch({ type: SET_VEHICLE, payload: vehicle });
  }, []);

  const setVehicleProperty = useCallback((key: keyof VehicleData, value: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    dispatch({ type: SET_VEHICLE_PROPERTY, payload: { key, value } });
  }, []);

  const setErrors = useCallback((errors: string[]) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    dispatch({ type: SET_ERRORS, payload: errors });
  }, []);

  const setEditingCallback = useCallback(
    (value: boolean) => {
      setEditing(value);
      // If we're no longer editing this vehicle, set the vehicle back to its original state
      const { editing: _, ...vehicle } = props;
      if (!value) setVehicle(vehicle);
    },
    [setVehicle, props],
  );

  const toggleEditing = useCallback(
    () => setEditingCallback(!editing),
    [setEditingCallback, editing],
  );

  return {
    vehicle: state,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    setVehicle,
    setVehicleProperty,
    editing,
    toggleEditing,
    setEditing: setEditingCallback,
    setErrors,
  };
}
