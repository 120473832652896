import { Button } from '@mui/material';
import { ReactElement } from 'react';

interface AccountingExportButtonProps {
  onClick: () => void;
}

const AccountingExportButton = ({ onClick }: AccountingExportButtonProps): ReactElement => {
  return (
    <Button size="small" onClick={onClick}>
      Export Now
    </Button>
  );
};

export default AccountingExportButton;
