import Skeleton from '@app/components/layout/Skeleton';
import StripeContext from '@app/contexts/stripeContext';
import * as Sentry from '@sentry/react';
import { Elements } from '@stripe/react-stripe-js';
import * as stripeJs from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { deserialize } from 'deserialize-json-api';
import React, { ReactElement, useContext } from 'react';

type theme = 'stripe' | 'night' | 'flat' | 'none';
type Appearance = {
  theme: theme;
};
type Loader = 'never' | 'auto' | 'always';

// Make sure the stripe promise is cached and only called once per page load
export function getCachedStripePromise(endpoint: string): Promise<stripeJs.Stripe> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const anyWindow = window as any;

  if (anyWindow.__stripePromise === undefined) {
    anyWindow.__stripePromise = {};
  }

  if (anyWindow.__stripePromise[endpoint] == undefined) {
    anyWindow.__stripePromise[endpoint] = axios
      .get(endpoint)
      .then((stripeKeyResponse) => {
        const stripeKeyData = deserialize(stripeKeyResponse).data;
        return loadStripe(stripeKeyData?.data?.attributes?.publishable_key);
      })
      .catch((e) => Sentry.captureException(e));
  }

  return anyWindow.__stripePromise[endpoint];
}

function StripeElements({
  children,
  endpoint,
  paymentElement,
}: {
  children: React.ReactNode;
  endpoint: string;
  paymentElement: boolean;
}) {
  const stripeContext = useContext(StripeContext);
  const { clientSecret } = stripeContext;

  const stripePromise = getCachedStripePromise(endpoint);

  const appearance: Appearance = {
    theme: 'stripe',
  };

  const options = {
    appearance,
    loader: 'always' as Loader,
    ...(paymentElement && { clientSecret }),
  };

  if (paymentElement && clientSecret === null) {
    return <Skeleton />;
  }

  return (
    <div className="">
      <Elements options={options} stripe={stripePromise} key={clientSecret}>
        {children}
      </Elements>
    </div>
  );
}
// eslint-disable-next-line @typescript-eslint/ban-types
export default function StripeElementsWrapper<P extends {}>(
  Component: React.FC<P>,
  integrationEndpoint: string,
  paymentElement = true,
) {
  return function StripeElement(props: P): ReactElement {
    return (
      <StripeElements endpoint={integrationEndpoint} paymentElement={paymentElement}>
        <Component {...props} />
      </StripeElements>
    );
  };
}
