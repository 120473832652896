import { DebitCardPaymentMethodCombined } from '@app/@types/debitCard.types';
import { DebitCardProps, PayWithUIRenderPropTypes } from '@app/@types/payments.types';
import { SubmitButton } from '@app/components/elements';
import CustomerContext from '@app/contexts/customerContext';
import StripeContext from '@app/contexts/stripeContext';
import ApiEndpoints from '@app/utils/data/apiEndpoints';
import { FormatCurrency } from '@atob-developers/shared/src/utils/formatters';
import * as Sentry from '@sentry/react';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import axios from 'axios';
import { capitalize } from 'lodash-es';
import { ReactElement, useContext, useState } from 'react';
import StripeElementsWrapper from './StripeElementsWrapper';
import stripeErrorHandler from './stripe-error-handler';
import useSubmitCardPayment from './use-submit-card-payment';

const STRIPE_STATUS_SUCCEEDED = 'succeeded';

type ConfirmHeaderProps = {
  amountIncludingFeesInCents: number;
  paymentCard: DebitCardPaymentMethodCombined;
};
const ConfirmHeader = (props: ConfirmHeaderProps): ReactElement => {
  const { amountIncludingFeesInCents, paymentCard } = props;
  return (
    <h1 className="mb-6">
      Pay
      <span className="ml-1 font-bold">
        {FormatCurrency({
          value: amountIncludingFeesInCents,
          options: { fromCents: true },
        })}
      </span>{' '}
      <>
        with {capitalize(paymentCard.brand)} debit card ending in{' '}
        <span className="font-mono font-bold">{paymentCard.last_four}</span>
      </>
    </h1>
  );
};

export const PayWithSavedCardUI = StripeElementsWrapper(
  ({ onSuccess, children }: { onSuccess: () => void; children: PayWithUIRenderPropTypes }) => {
    const [onSubmitPayment, loading, error] = useSubmitCardPayment(onSuccess);
    return children({ onSubmitPayment, loading, error });
  },
  ApiEndpoints.PAYMENTS_ENDPOINTS.PAYMENT_INTEGRATION_ENDPOINT,
);

function PayWithDebitCard({
  beforeFeesAmount,
  fees,
  onSuccess,
  closeModalAndReset,
  paymentCard,
  children,
}: DebitCardProps): ReactElement {
  const stripe = useStripe();
  const elements = useElements();
  const [submitting, setSubmitting] = useState(false);
  const { clientSecret: stripeClientSecret } = useContext(StripeContext);
  const { customer } = useContext(CustomerContext);
  const [error, setError] = useState<string | null>(null);

  const showDebitSuccess = () => {
    onSuccess();
  };

  const onSubmitDebitPayment = async () => {
    if (submitting) {
      return;
    }

    setError(null);
    setSubmitting(true);

    if (!stripe || !elements) {
      return;
    }

    stripe
      .confirmCardPayment(stripeClientSecret)
      .then(async (savedCardPaymentIntentResponse) => {
        const { paymentIntent, error: paymentError } = savedCardPaymentIntentResponse;
        const hasError = stripeErrorHandler(setError, paymentError);
        if (hasError) {
          return;
        }

        const status = paymentIntent?.status;
        if (status === STRIPE_STATUS_SUCCEEDED) {
          showDebitSuccess();
        } else {
          setError('Payment failed, please try again.');
        }
        setSubmitting(false);
      })
      .catch((e: unknown) => {
        setSubmitting(false);
        Sentry.captureException(e, {
          user: {
            request_id: axios.isAxiosError(e) ? e?.response?.headers?.['x-request-id'] : '',
            customer_email: customer?.owner_email ?? '',
          },
        });
      });
  };

  return (
    <>
      <div className="modal-content m-0">
        <div className="modal-card-body">
          {error && (
            <div className="mb-4 border-l-4 border-red-400 bg-red-50 p-4">
              <div className="flex">
                <div className="ml-3">
                  <p className="text-sm text-red-700">{error}</p>
                </div>
              </div>
            </div>
          )}
          <div>
            <ConfirmHeader
              amountIncludingFeesInCents={beforeFeesAmount + (fees?.amount_in_cents ?? 0)}
              paymentCard={paymentCard}
            />
          </div>
          {children}
        </div>
      </div>
      <footer className="modal-card-foot">
        <SubmitButton
          label="Confirm"
          loading={submitting}
          disabled={submitting}
          onClick={onSubmitDebitPayment}
        />
        <button type="button" className="button" onClick={() => closeModalAndReset()}>
          Cancel
        </button>
      </footer>
    </>
  );
}

export default StripeElementsWrapper(
  PayWithDebitCard,
  ApiEndpoints.PAYMENTS_ENDPOINTS.PAYMENT_INTEGRATION_ENDPOINT,
);
