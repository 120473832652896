import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';

export default function ConnectedCheckmark({
  isConnected,
}: {
  isConnected: boolean;
}): ReactElement | null {
  if (!isConnected) {
    return null;
  }

  return (
    <span className="ml-1 text-green-600">
      <FontAwesomeIcon className="fa-1x " icon={faCheckCircle} />
    </span>
  );
}
