import Constants from '@app/utils/constants';
import { LogoutOptions } from '@auth0/auth0-react';
import axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useSearchParams } from 'react-router-dom';

type OmniAuthHooks = {
  logout: (options?: LogoutOptions) => void;
  token: string;
  provider: string;
};

const { COOKIE, PROVIDER } = Constants.OmniAuthEnums;

export default function useOmniAuth(): OmniAuthHooks {
  const [cookies, , removeCookie] = useCookies([COOKIE, PROVIDER]);
  const [searchParams] = useSearchParams();
  // For both provider and token, support initialization from the URL parameter
  const [provider] = useState(searchParams.get('omni_auth_provider') || cookies[PROVIDER]);
  const [token, setToken] = useState(searchParams.get('omni_auth_jwt') || cookies[COOKIE]);

  const axiosInterceptorRef = useRef(false);
  useEffect(() => {
    if (axiosInterceptorRef.current === false && provider) {
      axiosInterceptorRef.current = true;
      axios.interceptors.response.use(function (response) {
        const jwt = response.headers['x-omni-auth_jwt'];
        if (jwt) {
          setToken(jwt);
          axios.defaults.headers.common.Authorization = `Bearer ${jwt}`;
        }
        return response;
      });
    }
  });

  const logout = useCallback(() => {
    removeCookie(PROVIDER);
    removeCookie(COOKIE);
    setToken(undefined);
  }, [removeCookie]);

  return {
    logout,
    token,
    provider,
  };
}
