import { Entity, PaginatedEndpointResponse } from '@app/@types/api.types';
import { UserData } from '@app/@types/users.types';
import { ErrorNotification } from '@app/components/layout';
import { WorkspaceProduct } from '@app/constants/customerProducts';
import axios from 'axios';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import UsersPage from './UsersPage';

export default function Users(props: {
  workspaceProducts: WorkspaceProduct[];
  rolesEnabled: boolean;
  addUsersEnabled: boolean;
  allowManageNotifications: boolean;
}): ReactElement {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<PaginatedEndpointResponse<Entity<UserData>> | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = useCallback(() => {
    setLoading(true);
    axios
      .get('/users?page=1&per=200&all=true')
      .then((response) => {
        setUsers(response.data);
      })
      .catch((e) => {
        setError(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  let view;

  if (error) {
    view = (
      <ErrorNotification error="We're having issues loading your user data. Please try again or if the issue persists, contact support." />
    );
  } else if (users === null || loading) {
    view = (
      <UsersPage
        workspaceProducts={props.workspaceProducts}
        allowManageNotifications={false}
        loading={true}
        data={[]}
        fetchData={() => {}}
        workspaceAddUpdateUsers={false}
        workspaceRoles={false}
      />
    );
  } else {
    const userData = users.data.map((user) => {
      return {
        ...user.attributes,
        key: String(user.id),
      };
    });
    view = (
      <UsersPage
        workspaceProducts={props.workspaceProducts}
        allowManageNotifications={props.allowManageNotifications}
        loading={false}
        data={userData}
        fetchData={fetchData}
        workspaceAddUpdateUsers={props.addUsersEnabled}
        workspaceRoles={props.rolesEnabled}
      />
    );
  }

  return view;
}
