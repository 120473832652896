import { Button, SIZE } from 'baseui/button';
import { Heading, HeadingLevel } from 'baseui/heading';
import { ParagraphMedium } from 'baseui/typography';
import { ReactElement } from 'react';

import { useNavigate } from 'react-router-dom';

const CongratulationsView = (): ReactElement => {
  const navigate = useNavigate();
  const onClickGoToDashboard = () => {
    navigate('/');
  };

  return (
    <div style={{ paddingLeft: '24px', paddingRight: '24px' }}>
      <HeadingLevel>
        <Heading className="mTFF" style={{ marginBottom: '32px' }}>
          Congrats!
        </Heading>
      </HeadingLevel>
      <ParagraphMedium className="mTFF" style={{ marginBottom: '32px' }}>
        You have successfully been transferred to an Uber Freight Carrier Card account!
      </ParagraphMedium>
      <ParagraphMedium className="mTFF" style={{ marginBottom: '40px' }}>
        Continue using your existing cards to receive deeper fuel discounts, and free same-day
        payouts.
      </ParagraphMedium>
      <Button
        onClick={onClickGoToDashboard}
        size={SIZE.large}
        className="mTFF"
        style={{ width: '100%', borderRadius: '0px' }}
      >
        View account dashboard
      </Button>
    </div>
  );
};

export default CongratulationsView;
