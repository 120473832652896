import { Loading } from '@app/components/layout';
import useProduct from '@app/hooks/useProduct';
import { getFetcher } from '@app/utils/data/fetchers';
import Modal from '@atob-developers/shared/src/components/Modal';
import { Button } from '@mui/material';
import { ReactElement, useState } from 'react';
import useSWR from 'swr';
import { CreditLimitIncreaseRequestCreate } from './CreditLimitIncreaseRequestCreate';
import { CreditLimitIncreaseRequestSuccess } from './CreditLimitIncreaseRequestSuccess';
import { CreditLimitIncreaseRequestUnavailable } from './CreditLimitIncreaseRequestUnavailable';

export type CliRequestConstraints = {
  pending_cli_request_id: number;
  previous_cli_request_count: number;
  max_credit_limit: number;
  current_credit_limit: number;
  need_bank_info: boolean;
  can_request: boolean;
};

export const CreditLimitIncreaseRequestModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}): ReactElement => {
  const {
    data: requestConstraints,
    isLoading,
    mutate,
  } = useSWR<CliRequestConstraints>({ url: '/cli_requests/new' }, getFetcher);

  type Screen = 'create' | 'success' | 'unavailable';

  let screen: Screen;
  if (requestConstraints?.pending_cli_request_id) {
    screen = 'success';
  } else if (
    (requestConstraints && requestConstraints?.previous_cli_request_count > 0) ||
    !requestConstraints?.can_request
  ) {
    screen = 'unavailable';
  } else {
    screen = 'create';
  }

  let child = null;

  if (isLoading || !requestConstraints) {
    child = <Loading />;
  } else if (screen === 'create') {
    child = (
      <CreditLimitIncreaseRequestCreate
        requestConstraints={requestConstraints}
        onNext={mutate}
        closeModal={onClose}
      />
    );
  } else if (screen === 'success') {
    child = <CreditLimitIncreaseRequestSuccess closeModal={onClose} />;
  } else if (screen === 'unavailable') {
    child = <CreditLimitIncreaseRequestUnavailable closeModal={onClose} />;
  }

  return (
    <Modal open={open} toggle={onClose}>
      {child}
    </Modal>
  );
};

export const CreditLimitIncreaseRequestButton = ({
  buttonText = 'Request a Credit Limit Increase',
}: {
  buttonText?: string;
}): ReactElement | null => {
  const [openModal, toggleModal] = useState(false);
  const [canRequestCli] = useProduct('cli_requests');

  if (!canRequestCli) return null;
  return (
    <>
      <Button size="small" onClick={() => toggleModal(true)}>
        {buttonText}
      </Button>
      <CreditLimitIncreaseRequestModal open={openModal} onClose={() => toggleModal(false)} />
    </>
  );
};
