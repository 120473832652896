import { ErrorNotification } from '@app/components/layout';
import StatusCodes from '@app/constants/http-status-codes';
import useAuth from '@app/hooks/useAuth';
import logger from '@app/utils/datadog-logger';
import { getEnvironment } from '@app/utils/environment';
import { Button, CircularProgress } from '@mui/material';
import * as Sentry from '@sentry/react';
import { loadVGSCollect } from '@vgs/collect-js';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import DebitSaveSuccessful from '../PaymentMethods/DebitSaveSuccessful';
import CardLegalTerms from '../StripeElements/CardLegalTerms';
import TermsAndConditions from '../StripeElements/TermsAndConditions';

const cssWithWidth = (width: string) => ({
  background: '#FFFFFF',
  border: '1px solid #CED7E6',
  boxSizing: 'border-box',
  borderRadius: '4px',
  height: '40px',
  padding: '4px 12px',
  fontSize: '16px',
  margin: '0 0 8px 0',
  width,
});

const SetupDebitCardWithVGS = ({
  payroll,
  onSuccess = () => {},
}: {
  payroll: boolean;
  onSuccess?: () => void;
}): ReactElement => {
  const [form, setForm] = useState(null);
  const { getAccessTokenSilently } = useAuth();
  const [response, setResponse] = useState('');
  const [errorMessage, setError] = useState<string | null>(null);
  const [showTerms, setShowTerms] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const { VITE_VGS_VAULT_ID, VITE_VGS_ENVIRONMENT } = getEnvironment();

  const initForm = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (vgsCollect: any) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const form = vgsCollect.init((_: any) => {});
      const cardNumber = form.field('#vgs-card-number', {
        type: 'card-number',
        name: 'card_number',
        successColor: '#4F8A10',
        errorColor: '#D8000C',
        placeholder: 'Card number',
        showCardIcon: false,
        validations: ['required', 'validCardNumber'],
        autoComplete: 'cc-number',
        css: cssWithWidth('100%'),
      });
      form.field('#vgs-card-expiry', {
        type: 'card-expiration-date',
        name: 'card_exp',
        successColor: '#4F8A10',
        errorColor: '#D8000C',
        placeholder: 'MM / YY',
        yearLength: '2',
        validations: ['required', 'validCardExpirationDate'],
        autoComplete: 'cc-exp',
        css: cssWithWidth('45%'),
      });
      const cvc = form.field('#vgs-card-cvc', {
        type: 'card-security-code',
        name: 'card_cvc',
        successColor: '#4F8A10',
        errorColor: '#D8000C',
        placeholder: 'CVC',
        validations: ['required', 'validCardSecurityCode'],
        css: cssWithWidth('50%'),
      });
      form.field('#vgs-card-zip', {
        type: 'zip-code',
        name: 'card_address_zip',
        successColor: '#4F8A10',
        errorColor: '#D8000C',
        placeholder: '90210',
        validations: ['required', 'postal_code/us'],
        autoComplete: 'shipping postal-code',
        css: cssWithWidth('100%'),
      });
      cardNumber.setCVCDependency(cvc);
      setForm(form);
    },
    [],
  );

  const hasLoadedForm = useRef(false);
  useEffect(() => {
    const loadForm = async () => {
      try {
        const vgsCollect = await loadVGSCollect({
          vaultId: VITE_VGS_VAULT_ID as string,
          environment: VITE_VGS_ENVIRONMENT as string,
          version: '2.18.1',
        });
        initForm(vgsCollect);
      } catch (e: unknown) {
        setError(
          'We were unable to load the card details form. Please try again. If the error persists, please contact our support team.',
        );
        Sentry.captureMessage('Error setting up VGS Collect');
      }
    };

    if (!hasLoadedForm.current) {
      hasLoadedForm.current = true;
      loadForm();
    }
  }, [VITE_VGS_VAULT_ID, VITE_VGS_ENVIRONMENT, initForm, showTerms]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsConfirming(true);
    const authToken = await getAccessTokenSilently();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    form.submit(
      '/vgs/payment_methods',
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (status: any, data: any) => {
        setIsConfirming(false);
        if (status !== StatusCodes.CREATED) {
          const defaultError =
            'There was a problem adding your debit card. Please try again later, or if the issue persists, contact support.';
          const [error] = data.errors || [defaultError];
          setError(error);
        } else {
          setResponse(JSON.stringify(data));
          onSuccess();
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (e: any) => {
        logger.error('Error submitting form to VGS', e);
        setIsConfirming(false);
        setError('The information you entered does not appear to be valid. Please try again.');
      },
    );
  };

  if (response) {
    return (
      <div>
        <DebitSaveSuccessful />
      </div>
    );
  }

  if (showTerms) {
    return <TermsAndConditions onClick={() => setShowTerms(false)} />;
  }

  return (
    <>
      <div>
        {errorMessage && <ErrorNotification error={errorMessage} />}
        <div className="mb-4 flex flex-col overflow-hidden">
          {isConfirming && (
            <div className="flex justify-center">
              <CircularProgress />
            </div>
          )}
          <form
            className="h-[200px] w-[300px]"
            id="vgs-collect-form"
            data-testid="vgs-collect-form"
          >
            <div className="flex flex-col">
              <label>
                <span className="text-sm font-medium">Card number</span>
                <div className="h-[40px]" id="vgs-card-number" />
              </label>
              <div className="flex w-full">
                <label className="w-1/2">
                  <span className="text-sm font-medium">Expiration</span>
                  <div className="h-[40px] w-1/2" id="vgs-card-expiry" />
                </label>
                <label className="w-1/4">
                  <span className="inline-block w-[50px] text-sm font-medium">CVC</span>
                  <div className="h-[40px] w-1/4" id="vgs-card-cvc" />
                </label>
              </div>
              <label className="w-1/4">
                <span className="text-sm font-medium">ZIP</span>
                <div className="h-[40px] w-1/4" id="vgs-card-zip" />
              </label>
            </div>
          </form>
          <CardLegalTerms payroll={payroll} setShowTerms={setShowTerms} />
        </div>
        <div className="flex justify-end pb-4">
          <div>
            <Button disabled={!form || isConfirming} onClick={(e) => handleSubmit(e)} size="small">
              {'Save Card'}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SetupDebitCardWithVGS;
