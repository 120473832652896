import { PaginatedEndpointResponse, Entity } from '@app/@types/api.types';
import { FlattenedPayrollData, PayrollTransactionData } from '@app/@types/payroll.types';
import { ErrorNotification } from '@app/components/layout';
import WarningNotification from '@app/components/layout/WarningNotification';
import useChannelPartner from '@app/hooks/useChannelPartner';
import useWindowWidth from '@app/hooks/useWindowWidth';
import Constants from '@app/utils/constants';
import SideBar, {
  SideBarBody,
  SideBarFooter,
} from '@atob-developers/shared/src/components/SideBar';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import { deserialize } from 'deserialize-json-api';
import { ReactElement, useEffect, useState, ReactNode } from 'react';
import AddDriverContactDetailsModal from '../Modals/AddDriverContactDetailsModal';
import DriverOptionsModal from '../Modals/DriverOptionsModal';
import { mapTransactionstoState } from '../payroll-context';
import ArchiveDriverModal from './ArchiveDriverModal';
import DriverSidebarButton from './DriverSidebarButton';
import EmployeeTypeDropdownSelector from './EmployeeTypeDropdownSelector';
import PayAmountInput from './PayAmountInput';
import StatusBadge from './StatusBadge';

const GridItem = ({ title, children }: { title?: string; children: ReactNode }) => (
  <div className="mb-8 font-bold">
    {title && <div className="mb-2 text-sm">{title}</div>}
    {children}
  </div>
);

export default function DriverSidebar({
  driver,
  open,
  toggle,
  noPaymentSource,
  updateDriverInState,
  setIsPayingDriver,
  amountToPayDriver,
  setAmountToPayDriver,
}: {
  driver: FlattenedPayrollData;
  open: boolean;
  toggle: () => void;
  noPaymentSource: boolean;
  updateDriverInState: (driverId: string, newValues: Partial<FlattenedPayrollData>) => void;
  setIsPayingDriver: (_: 'Pay now' | 'Calculate' | null) => void;
  amountToPayDriver: number;
  setAmountToPayDriver: (_: number) => void;
}): ReactElement {
  const { name, tax_classification, status, id, payout_account, email, phone } = driver;
  const [isInvitingDriver, setIsInvitingDriver] = useState(false);
  const [isDriverOptionsModalOpen, setIsDriverOptionsModalOpen] = useState(false);
  const [error, setError] = useState('');
  const [archiveModalActive, setArchiveModalActive] = useState(false);
  const [recentTransactions, setRecentTransactions] = useState<PayrollTransactionData[]>([]);
  const { isSmallScreen } = useWindowWidth();

  useEffect(() => {
    const fetchPayrollHistoryData = async () => {
      const yesterday = dayjs().subtract(1, 'day');
      const today = dayjs();

      const transactionData = await axios.get<
        PaginatedEndpointResponse<Entity<PayrollTransactionData>>
      >('/payroll/transactions', {
        params: {
          'driver_id': driver.id,
          'transaction_date[since]': yesterday,
          'transaction_date[until]': today,
        },
      });

      const transactions = deserialize(transactionData.data).data;
      const recentTransactions = mapTransactionstoState(transactions);

      setRecentTransactions(recentTransactions);
    };

    fetchPayrollHistoryData();
  }, [driver.id]);

  const toggleSidebar = () => {
    setAmountToPayDriver(0);
    setError('');
    toggle();
  };

  const driverNeedsInvite =
    driver.status === Constants.PayrollEnums.INVITED ||
    (driver.status === Constants.PayrollEnums.NOT_ENROLLED &&
      driver.tax_classification === Constants.PayrollEnums.TEN_NINETY_NINE);

  const canPayDriver = driver.status === Constants.PayrollEnums.ENROLLED;

  const driverCanReceiveResetLink =
    driver.status === Constants.PayrollEnums.ENROLLED ||
    driver.status === Constants.PayrollEnums.UPDATE_REQUIRED;

  const debit_card = payout_account?.payout_detail
    ? `${payout_account?.payout_detail.brand} .... ${payout_account?.payout_detail.last_four}`
    : 'Not added';

  const onArchiveSuccess = (driverId: string) => (archived_at: string) => {
    updateDriverInState(driverId, { archived_at });
  };

  return (
    <SideBar open={open} toggle={toggleSidebar} title={name ?? ''}>
      <SideBarBody>
        {error && <ErrorNotification error={error} />}
        {driver.archived_at && (
          <div className="mb-8 text-sm font-bold text-gray-500">
            Archived on {dayjs(driver.archived_at).format('M/D/YY')}
          </div>
        )}
        <section className="grid grid-cols-2">
          <GridItem title="Email">
            <div className="max-w-[190px] break-words text-left text-[13px] font-[500] leading-4">
              {email || 'Not added'}
            </div>
          </GridItem>
          <GridItem title="Phone number">
            <div className="break-words text-left text-[13px] font-[500] leading-4">
              {phone || 'Not added'}
            </div>
          </GridItem>
          <GridItem title="Status">
            <StatusBadge
              status={driver.archived_at !== null ? 'archived' : status}
              taxClassification={tax_classification}
            />
          </GridItem>
          <GridItem title="1099/W2">
            <EmployeeTypeDropdownSelector
              currentDriverId={id}
              value={tax_classification}
              status={status}
              driverId={id}
              disabled={driver.archived_at !== null}
            />
          </GridItem>
          {driverCanReceiveResetLink && (
            <GridItem title={`${driver.first_name}'s payout card`}>
              <div className="flex break-words text-left text-[13px] font-[500] leading-4">
                <div className="mr-2">{debit_card}</div>
                <button
                  onClick={() => {
                    setIsDriverOptionsModalOpen(true);
                  }}
                >
                  <FontAwesomeIcon icon={faPencil} size="1x" className="text-atob-green" />
                </button>
              </div>
            </GridItem>
          )}
          <GridItem title="">{null}</GridItem>
        </section>
        <div className="w-full">
          <div>
            <div className="flex w-full flex-col">
              <GridItem title={`${canPayDriver ? 'Quick Pay' : ''}`}>
                <>
                  <div className="md:flex md:items-center md:gap-x-2">
                    {canPayDriver && (
                      <div className="mb-4 md:mb-0">
                        <PayAmountInput
                          amountToPayDriver={amountToPayDriver}
                          setAmountToPayDriver={setAmountToPayDriver}
                        />
                      </div>
                    )}
                    <DriverSidebarButton
                      onClick={(driver) => {
                        if (driver.status === Constants.PayrollEnums.ENROLLED) {
                          setIsPayingDriver('Pay now');
                          return;
                        }

                        if (driverNeedsInvite) {
                          setIsInvitingDriver(true);
                        }
                      }}
                      driver={driver}
                      amountToPayDriver={amountToPayDriver}
                      noPaymentSource={noPaymentSource}
                    />
                  </div>
                  {canPayDriver && (
                    <PaymentNotification recentTransactions={recentTransactions} driver={driver} />
                  )}
                </>
              </GridItem>

              {canPayDriver && (
                <GridItem title="">
                  <Button
                    size="medium"
                    onClick={() => {
                      setAmountToPayDriver(0);
                      setIsPayingDriver('Calculate');
                    }}
                  >
                    {isSmallScreen ? 'Calculate' : 'Calculate & Itemize'}
                  </Button>
                </GridItem>
              )}
            </div>
          </div>
        </div>
      </SideBarBody>
      <SideBarFooter>
        {driver.archived_at === null && (
          <div className="mb-4 mt-4 flex w-full justify-center">
            <Button size="medium" color="secondary" onClick={() => setArchiveModalActive(true)}>
              Archive {driver.name ?? ''}
            </Button>
          </div>
        )}
      </SideBarFooter>
      <AddDriverContactDetailsModal
        driver={driver}
        isActive={isInvitingDriver}
        reset={() => {
          setIsInvitingDriver(false);
        }}
        updateInState={updateDriverInState}
      />
      <DriverOptionsModal
        isActive={isDriverOptionsModalOpen}
        reset={() => setIsDriverOptionsModalOpen(false)}
        driver={driver}
      />
      <ArchiveDriverModal
        isActive={archiveModalActive}
        reset={() => {
          setArchiveModalActive(false);
          toggleSidebar();
        }}
        driver={driver}
        onArchiveSuccess={(_updatedDriver) => onArchiveSuccess(driver.id)}
      />
    </SideBar>
  );
}

const PaymentNotification = ({
  recentTransactions,
  driver,
}: {
  recentTransactions: PayrollTransactionData[];
  driver: FlattenedPayrollData;
}): ReactElement | null => {
  const { supportPhoneNumber, supportEmailAddress } = useChannelPartner();
  const failedPayments = recentTransactions.filter((t) => t.payment_status === 'error');

  let view;

  if (failedPayments.length > 0) {
    view = (
      <div className="text-sm font-medium">
        <ErrorNotification
          error={failedPayments
            .map((payment) => payment.error_message)
            .filter((msg) => msg != null)
            .join(', ')}
        >
          <p>
            You have made a failed {failedPayments[0].amount} payment to {driver.name} today.
            Attempting an additional manual payment will delay refunding your original payment.
          </p>
          <br />
          <p>
            Please call{' '}
            <a href={`tel:${supportPhoneNumber}`} className="underline">
              {supportPhoneNumber}
            </a>{' '}
            or email{' '}
            <a className="underline" href={`mailto:${supportEmailAddress}`}>
              {supportEmailAddress}
            </a>{' '}
            for an automated retry of this payment.
          </p>
        </ErrorNotification>
      </div>
    );
  } else if (recentTransactions.length > 0) {
    view = (
      <div className="text-sm font-medium">
        <WarningNotification>
          <p>
            Just to confirm: You have already made a {recentTransactions[0].amount} payment to{' '}
            {driver.name} today.
          </p>
        </WarningNotification>
      </div>
    );
  } else {
    return null;
  }

  return (
    <div className="mt-4">
      <div className="text-sm font-medium">{view}</div>
    </div>
  );
};
