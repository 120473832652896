import Header from '@app/components/Navigation/Header';
import PageContentWrapper from '@app/components/wrappers/PageContentWrapper';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import CardDetailsPageContent from './CardDetailsPageContent';
import type { CardDataWithRelationships } from '@app/@types/card.types';

interface CardDetailsV2Props {
  card: CardDataWithRelationships;
  allowSetSpendLimit: boolean;
  smsUnlockEnabled: boolean;
  onCardChange: () => void;
  telematicsEnabled: boolean;
  resourceTagsEnabled: boolean;
}

export const CardDetailsPage = ({
  card,
  allowSetSpendLimit,
  smsUnlockEnabled,
  onCardChange,
  telematicsEnabled,
  resourceTagsEnabled,
}: CardDetailsV2Props): ReactElement => {
  const navigate = useNavigate();
  return (
    <PageContentWrapper header={<Header title="Card Details" onBack={() => navigate(-1)} />}>
      <CardDetailsPageContent
        data={card}
        allowSetSpendLimit={allowSetSpendLimit}
        smsUnlockEnabled={smsUnlockEnabled}
        onCardChange={onCardChange}
        telematicsEnabled={telematicsEnabled}
        resourceTagsEnabled={resourceTagsEnabled}
      />
    </PageContentWrapper>
  );
};

export default CardDetailsPage;
