import { PaymentMethodType } from '@app/components/PaymentMethods/PaymentMethodUtils';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBuildingColumns, faCreditCard, faWallet } from '@fortawesome/pro-regular-svg-icons';

export type PaymentDropdownType = {
  icon: IconProp;
  paymentType: string;
  paymentTime?: string;
  description?: string;
};

export const PAYMENT_TYPE_DROPDOWN_ITEMS: PaymentDropdownType[] = [
  {
    icon: faCreditCard,
    paymentType: PaymentMethodType.DEBIT_CARD,
  },
  {
    icon: faBuildingColumns,
    paymentType: PaymentMethodType.BANK_ACCOUNT,
  },
  {
    icon: faWallet,
    paymentType: PaymentMethodType.WALLET,
  },
];

export const OVER_INSTANT_THRESHOLD =
  'ACH payments take up to 3 business days to process, but funds will be immediately withdrawn from your bank.';

export const DEFAULT_AMOUNT = '$0.00';

export const STRIPE_STATUS_SUCCEEDED = 'succeeded';
