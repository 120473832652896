import DebitCardModal from '@app/components/PaymentMethods/DebitCardModal';
import useChannelPartner from '@app/hooks/useChannelPartner';
import useModal from '@app/hooks/useModal';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorBanner } from './Designs/ErrorBanner';

export const Step1ConnectDebitCardBanner = (): ReactElement => {
  const navigate = useNavigate();
  const [isModalActive, toggleModal] = useModal();
  const { supportPhoneNumber } = useChannelPartner();

  return (
    <ErrorBanner actionText="Connect Debit Card" onClick={() => toggleModal()}>
      <div className="font-semibold">STEP 1: Securely connect your debit card to activate</div>
      <div>Have questions? Call us 24/7 at {supportPhoneNumber}.</div>

      <DebitCardModal
        isActive={isModalActive}
        setIsActive={toggleModal}
        forcePrimary
        onSuccess={() => navigate('/billing/payment-methods')}
      />
    </ErrorBanner>
  );
};

export default Step1ConnectDebitCardBanner;
