import { Button } from '@mui/material';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

const AddWallet = (): ReactElement => {
  const navigate = useNavigate();
  return (
    <div className="w-full space-y-10 bg-white px-6 py-6 sm:px-8">
      <div className="flex h-full w-full flex-col items-center justify-center">
        <p className="text-black0 mb-4 text-2xl font-bold">No AtoB Wallet Found</p>
        <p className="text-center text-base font-medium text-gray-500">
          It looks like you haven't set up your AtoB Wallet yet! Click below to get started.
        </p>
        <div className="mb-6 mt-8 max-w-[162px]">
          <Button
            size="small"
            onClick={() => navigate('/wallet/overview')}
            data-testid="InfoModal-Button"
          >
            Set Up AtoB Wallet
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddWallet;
