import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import TableV2 from '@app/components/TableV2/TableV2';
import { ErrorNotification } from '@app/components/layout';
import generateCSV from '@app/utils/generate-csv';
import { Button } from '@mui/material';
import { Dayjs } from 'dayjs';
import { ReactElement, useState } from 'react';

const columns = [
  {
    field: 'name',
    headerName: 'Name',
    minWidth: 200,
    flex: 2,
    sortable: true,
  },
  {
    field: 'paid_last_week',
    headerName: 'Paid Last Week',
    flex: 1,
    sortable: true,
  },
  {
    field: 'paid_this_month',
    headerName: 'Paid This Month',
    flex: 1,
    sortable: true,
  },
  {
    field: 'paid_in_daterange',
    headerName: 'Paid in Date Range Selected',
    flex: 1,
    sortable: true,
  },
];

interface TransactionTableProps {
  transactions: PayrollSummaryData[];
  startDate: Dayjs;
  endDate: Dayjs;
}

type PayrollSummaryData = {
  name: string;
  paid_last_week: string;
  paid_this_month: string;
  paid_in_daterange: string;
};

export default function PayrollSummaryTable(props: TransactionTableProps): ReactElement {
  const { transactions, startDate, endDate } = props;
  const [error, setError] = useState<string | null>(null);

  const exportCSV = () => {
    const csvData = transactions.map((transaction) => {
      const start = startDate.format('YYYY-MM-DD');
      const end = endDate.format('YYYY-MM-DD');
      const selectedRangeHeader = `Paid between ${start} and ${end}`;
      return {
        'Driver Name': `'${transaction.name}'`,
        'Paid last week': transaction.paid_last_week,
        'Paid this month': transaction.paid_this_month,
        [selectedRangeHeader]: transaction.paid_in_daterange,
      };
    });

    generateCSV({
      csvData,
      title: 'Payroll Transactions Summary',
      onError: () => setError('No transactions found for these days.'),
    });
  };

  return (
    <>
      <div className="flex w-full justify-between">
        <h2 className="mb-4 text-2xl font-bold">Summary</h2>
        <div>
          <Button size="small" onClick={exportCSV}>
            Export
          </Button>
        </div>
      </div>
      {error && <ErrorNotification error={error} />}
      <RoundedCard>
        <TableV2
          columns={columns}
          data={transactions}
          loading={false}
          getRowId={(row) => row.name}
        />
      </RoundedCard>
    </>
  );
}
