import UnderlinedTabs from '@atob-developers/shared/src/components/UnderlinedTabs';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

type Tab = '/payroll/overview' | '/payroll/history';

const TABS: { tab: Tab; name: string }[] = [
  {
    tab: '/payroll/overview',
    name: 'Overview',
  },
  {
    tab: '/payroll/history',
    name: 'Pay History',
  },
];

export default function PayrollTabs({ tab }: { tab: Tab }): ReactElement {
  const navigate = useNavigate();
  return (
    <UnderlinedTabs
      selectedTab={tab}
      handleTabChange={(tab) => {
        navigate(tab);
      }}
      tabs={TABS}
    />
  );
}
