import { CustomerData } from '@app/@types/customer.types';
import * as Sentry from '@sentry/react';
import Cohere from 'cohere-js';
import { useEffect } from 'react';

export default function useCohere({
  customer,
  VITE_COHERE_API_KEY,
}: {
  customer: CustomerData | null;
  VITE_COHERE_API_KEY: string;
}): void {
  useEffect(() => {
    Cohere.init(VITE_COHERE_API_KEY);
    Cohere.widget('show');

    if (!customer) {
      // If the customer hasn't successfully loaded display the cohere code
      Cohere.showCode();
    } else {
      try {
        Cohere.identify(customer.id, {
          displayName: customer.owner_name,
          email: customer.owner_email,
        });
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  }, [customer, VITE_COHERE_API_KEY]);
}
