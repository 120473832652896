import CreditLimitIncrease from '@app/assets/images/svg/credit-limit-increase.svg';
import { CreditLimitIncreaseRequestButton } from '@app/components/Modals/CreditLimitIncreaseRequest/CreditLimitIncreaseRequestModal';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import { ReactElement } from 'react';

const BoostReminder = (): ReactElement => {
  const [isCliButtonEnabled] = useFeatureFlags('cli_requests_enabled');

  return (
    <div className="p-10">
      <div className="flex justify-center pb-6">
        <img src={CreditLimitIncrease} alt="" />
      </div>
      <p className="text-ds-cool-gray-700 pb-6 text-center font-bold">
        Boost your chance of a credit limit increase with on-time payments, and linking a bank with
        your AtoB account.
      </p>
      {isCliButtonEnabled && (
        <div className="flex justify-center">
          <CreditLimitIncreaseRequestButton />
        </div>
      )}
    </div>
  );
};

export default BoostReminder;
