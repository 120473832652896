import { EndpointResponse, Entity } from '@app/@types/api.types';
import { DriverData, GenerateUnlockIdResult, ValidationResult } from '@app/@types/driver.types';
import DefaultPrompt from '@app/components/Prompt/DefaultPrompt';
import { DRIVER_FORM_DATA, DriverFormDataType } from '@app/constants/drivers';
import useChannelPartner from '@app/hooks/useChannelPartner';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import useProduct from '@app/hooks/useProduct';
import logger from '@app/utils/datadog-logger';
import { validateFieldAndGetError, validateForm } from '@app/utils/validation/yup-validation';
import { DataItemType } from '@atob-developers/shared/src/components/DataItem';
import FormElement from '@atob-developers/shared/src/components/FormElement';
import { SideBarBody, SideBarFooter } from '@atob-developers/shared/src/components/SideBar';
import { faLink } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import axios from 'axios';
import { capitalize, debounce, throttle } from 'lodash-es';
import { ReactElement, useRef, useState } from 'react';

interface EditDriverSidebarProps {
  setOpen: (val: boolean) => void;
  driver: DriverData;
  onDriversUpdate: (updatedDriver: DriverData) => void;
  enableStreamlinedDriverAppOnboarding: boolean;
}

const editDriverSuccessMessage = 'Driver information successfully updated!';
const editDriverErrorMessage = 'There was an error editing the driver. Please try again.';

type DriverInfoUpdateStates = 'error' | 'successful' | 'none';

const DEBOUNCE_TIME_IN_MS = 400;

const EditDriverInfoPrompt = ({
  addDriverState,
  setAddDriverState,
}: {
  addDriverState: DriverInfoUpdateStates;
  setAddDriverState: (state: DriverInfoUpdateStates) => void;
}) => {
  if (addDriverState === 'none') {
    return null;
  } else if (addDriverState === 'error') {
    return (
      <DefaultPrompt
        clickHandler={() => setAddDriverState('none')}
        error={true}
        message={editDriverErrorMessage}
      />
    );
  }
  return (
    <DefaultPrompt
      clickHandler={() => setAddDriverState('none')}
      error={false}
      message={editDriverSuccessMessage}
    />
  );
};

const EditDriverSidebar = ({
  driver,
  setOpen,
  onDriversUpdate,
  enableStreamlinedDriverAppOnboarding,
}: EditDriverSidebarProps): ReactElement => {
  const [addDriverState, setAddDriverState] = useState<DriverInfoUpdateStates>('none');
  const [errors, setError] = useState<Record<string, string | null>>({});
  const [warnings, setWarning] = useState<Record<string, string | null>>({});
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState<Record<string, string | null>>(
    DRIVER_FORM_DATA.reduce((result: Record<string, string>, item) => {
      result[item.path] = item?.path ? (driver[item?.path] as string) : '';
      return result;
    }, {}),
  );
  const [disableAutoInvite] = useFeatureFlags('disable_new_phone_welcome_sms');
  const [allowSelfServePins] = useProduct('drivers_unlock_id');
  const { partnerName } = useChannelPartner();
  const originalPhone = useRef(values.phone);

  const verifyUnlockId = useRef(
    debounce(async (val: string) => {
      axios
        .get<ValidationResult>(`/drivers/validate_new_unlock_id`, {
          params: {
            unlock_id: val,
            driver_id: driver.id,
          },
        })
        .then((res) => {
          // We have to use the function version of setWarning, and can't rely on `warnings`,
          // because with `useRef`, we only get the initial value of `warnings`
          setWarning((currentWarnings) => {
            const { unlock_id: _, ...otherWarnings } = currentWarnings;
            if (res.data.warning) {
              return { ...otherWarnings, unlock_id: res.data.warning };
            } else {
              return { ...otherWarnings };
            }
          });
        })
        .catch((e) => {
          logger.error('Error trying to validate a new unlock ID', { error: e });
        });
    }, DEBOUNCE_TIME_IN_MS),
  );

  const generateUnlockId = useRef(
    throttle(async () => {
      axios
        .get<GenerateUnlockIdResult>(`/drivers/generate_new_unlock_id`, {})
        .then((res) => {
          // We have to use the function version of setWarning and setValues, and can't rely on `values`,
          // because with `useRef`, we only get the initial value of `values`
          setWarning((currentWarnings) => {
            const { unlock_id: _, ...otherWarnings } = currentWarnings;
            return { ...otherWarnings };
          });
          setValues((currentValues) => {
            return {
              ...currentValues,
              unlock_id: res.data.unlock_id || currentValues.unlock_id,
            };
          });
        })
        .catch((e) => {
          logger.error('Error trying to generate a new unlock ID', { error: e });
        });
    }, DEBOUNCE_TIME_IN_MS),
  );

  const onSave = async () => {
    setAddDriverState('none');
    const { formHasValidationErrors, validationErrors } = await validateForm(values, {
      form: DRIVER_FORM_DATA,
    });

    if (!formHasValidationErrors) {
      setLoading(true);
      const data = {
        ...values,
        first_name: values?.first_name && capitalize(values.first_name),
        last_name: values?.last_name && capitalize(values.last_name),
        email: values.email?.toLowerCase(),
      };
      axios
        .put<EndpointResponse<Entity<DriverData>>>(`/drivers/${driver.id}`, {
          driver: data,
        })
        .then((res) => {
          const data = {
            ...res.data.data.attributes,
            first_name: capitalize(res.data.data.attributes.first_name),
            last_name: capitalize(res.data.data.attributes.last_name),
            email: res.data.data.attributes.email?.toLowerCase() || null,
          };
          onDriversUpdate(data);
          setAddDriverState('successful');
          setOpen(false);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setAddDriverState('error');
        });
    } else {
      setError(validationErrors);
      setAddDriverState('error');
    }
  };

  const handleValueChange = async (val: string, item: DriverFormDataType) => {
    setValues({
      ...values,
      [item.path]: val ? val : null,
    });
    if (item.validation && item.validationOnChange && !!val) {
      const error = await validateFieldAndGetError(item, val);
      setError({ ...errors, [item.path]: error });
    } else if (errors[item.path]) {
      setError({ ...errors, [item.path]: null });
    }

    if (item.path === 'unlock_id') {
      verifyUnlockId.current(val);
    }
  };

  return (
    <>
      <SideBarBody>
        <div className="flex flex-col space-y-8 text-sm">
          {!!driver.synchronized && (
            <div className="bg-blue0 flex flex-row items-center gap-2 rounded-md p-3 italic">
              <FontAwesomeIcon icon={faLink} className="px-1 text-blue-500" />
              Synchronized via {capitalize(driver.source || '')}
            </div>
          )}
          {!!driver &&
            DRIVER_FORM_DATA.filter((item) => {
              return (
                item.path !== 'account_status' &&
                (allowSelfServePins || item.path !== 'unlock_id') &&
                (!!driver.external_username || item.path !== 'external_username')
              );
            }).map((item, idx) => (
              <div key={`driver-modal-element-${idx}`}>
                <FormElement
                  key={`sidebar-item-${idx}`}
                  element={{
                    ...item,
                    key: item.path,
                    type: DataItemType.TEXT_INPUT,
                    inputType: item.path === 'phone' ? 'tel' : 'text',
                  }}
                  value={values?.[item.path] || ''}
                  handleOnChange={async (val) =>
                    val !== null && (await handleValueChange(val, item))
                  }
                  required={item.required}
                  error={errors?.[item.path] || undefined}
                  warning={warnings?.[item.path] || undefined}
                  disabled={item.synchronized && !!driver.synchronized}
                />
                {item.path === 'unlock_id' && (
                  <button
                    type="button"
                    className="text-atob-green text-sm hover:underline"
                    onClick={generateUnlockId.current}
                  >
                    Generate Unique ID
                  </button>
                )}
              </div>
            ))}
        </div>
        <div className="mt-8">
          <EditDriverInfoPrompt
            setAddDriverState={setAddDriverState}
            addDriverState={addDriverState}
          />
        </div>
        {enableStreamlinedDriverAppOnboarding &&
          values.phone?.replaceAll(/[-( )]/g, '') != originalPhone.current &&
          !disableAutoInvite && (
            <div className="my-2 block text-sm font-medium text-gray-700">
              {`Your driver will automatically be eligible to use the ${partnerName} app`}
            </div>
          )}
      </SideBarBody>
      <SideBarFooter>
        <Button color="alert" size="small" onClick={() => setOpen(false)}>
          Discard Changes
        </Button>
        <LoadingButton size="small" onClick={onSave} loading={loading}>
          <span>Save Changes</span>
        </LoadingButton>
      </SideBarFooter>
    </>
  );
};

export default EditDriverSidebar;
