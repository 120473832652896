import { AccountLimitsData } from '@app/@types/account_limits.types';
import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import Divider from '@app/components/elements/Divider';
import axios from 'axios';
import { ReactElement, useEffect, useState } from 'react';
import BoostReminder from './BoostReminder';

const AccountLimitsSettings = ({ isPrepaid }: { isPrepaid: boolean }): ReactElement => {
  const [accountLimitsData, setAccountLimitsData] = useState<AccountLimitsData | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    axios
      .get('/account_limits')
      .then((response) => {
        setAccountLimitsData(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div>
      {loading && <SpinnerBoundary />}
      {!loading && (
        <div className="flex-row rounded-lg bg-white p-4">
          <h2 className="mb-1 text-[24px] font-bold">Fuel Card Limits</h2>
          <div className="flex h-full w-full flex-col">
            <div className="flex flex-col pt-4 md:flex-row">
              <div className="flex h-full w-full flex-col items-start pr-8">
                <div className="w-full flex-row pt-4">
                  {!isPrepaid && (
                    <>
                      <div className="flex w-full justify-between">
                        <div className="mr-8">Weekly Credit Limit</div>
                        <div>{accountLimitsData?.weekly_credit_limit}</div>
                      </div>
                      <Divider />
                      <div className="flex w-full justify-between">
                        <div className="mr-8">Net Term</div>
                        <div>{accountLimitsData?.net_terms}</div>
                      </div>
                      <Divider />
                    </>
                  )}
                  <div className="flex w-full justify-between">
                    <div className="mr-8">Spend Categories</div>
                    <div className="flex-row">
                      {accountLimitsData &&
                        accountLimitsData.spend_categories.map((category) => (
                          <div key={category} className="flex justify-end text-right">
                            {category}
                          </div>
                        ))}
                    </div>
                  </div>
                  <Divider />
                  <div className="flex w-full justify-between">
                    <div className="mr-8">Account Balance</div>
                    <div>{accountLimitsData?.account_balance}</div>
                  </div>
                </div>
              </div>
              {!isPrepaid && <BoostReminder />}
            </div>
            <span className="text-[14px]">All cards are issued by Celtic Bank</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountLimitsSettings;
