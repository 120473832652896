import { DebitCardPaymentMethodCombined } from '@app/@types/debitCard.types';
import { PaymentMethodCombined } from '@app/@types/payments.types';
import logger from '@app/utils/datadog-logger';
import { faXmarkCircle, faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { ReactElement, useState } from 'react';
import PaymentStatusContent from '../PaymentErrors/PaymentStatusContent';
import DebitCardEditPaymentDetails from './DebitCardEditPaymentDetails';
import DebitCardReviewPaymentDetailsContainer from './DebitCardReviewPaymentDetailsContainer';
import { DebitCardWrapperProps } from './DebitCardWrapper';

interface DebitCardControllerProps {
  paymentMethods: PaymentMethodCombined[];
}

const getDebitCardsFromPaymentMethods = (
  paymentMethods: PaymentMethodCombined[],
): DebitCardPaymentMethodCombined[] | [] => {
  return (paymentMethods as DebitCardPaymentMethodCombined[]).filter(
    (paymentMethod: DebitCardPaymentMethodCombined): DebitCardPaymentMethodCombined | undefined => {
      return paymentMethod.payment_method_detail.type === 'debit_card' ? paymentMethod : undefined;
    },
  );
};

const getSelectedPaymentMethod = (
  paymentMethods: DebitCardPaymentMethodCombined[],
): DebitCardPaymentMethodCombined | undefined => {
  return (
    paymentMethods.find((paymentMethod: DebitCardPaymentMethodCombined) => {
      return paymentMethod.is_primary;
    }) || paymentMethods[0]
  );
};

const DebitCardController = ({
  setShowPaymentSelection,
  closeModal,
  paymentMethods,
  paymentAmount,
  setPaymentAmount,
  showAmountDue,
  setShowAmountDue,
}: DebitCardControllerProps & DebitCardWrapperProps): ReactElement | null => {
  const debitCardPaymentMethods = getDebitCardsFromPaymentMethods(paymentMethods);
  const defaultPaymentMethod = getSelectedPaymentMethod(debitCardPaymentMethods);
  const [selectedDebitCard, setSelectedDebitCard] = useState<
    DebitCardPaymentMethodCombined | undefined
  >(defaultPaymentMethod);
  const [paymentScreenState, setPaymentScreenState] = useState<
    'edit' | 'review' | 'success' | 'error'
  >('edit');
  const [message, setMessage] = useState<string | null>(null);
  const setPaymentScreen = (
    screen: 'edit' | 'review' | 'success' | 'error',
    paymentMessage?: string,
  ) => {
    setPaymentScreenState(screen);
    setMessage(paymentMessage ?? null);
  };

  const onNext = () => {
    setPaymentScreen('review');
    setShowPaymentSelection(false);
  };

  const onBack = () => {
    setShowPaymentSelection(true);
    setPaymentScreen('edit');
  };

  switch (paymentScreenState) {
    case 'edit':
      logger.info('Loading Debit Card edit payment modal');
      return (
        <DebitCardEditPaymentDetails
          onNext={onNext}
          closeModal={closeModal}
          selectedDebitCard={selectedDebitCard}
          debitCards={debitCardPaymentMethods}
          setSelectedDebitCard={setSelectedDebitCard}
          paymentAmount={paymentAmount}
          setPaymentAmount={setPaymentAmount}
          showAmountDue={showAmountDue}
          setShowAmountDue={setShowAmountDue}
        />
      );
    case 'review':
      logger.info('Loading Debit Card review payment modal');
      return (
        <DebitCardReviewPaymentDetailsContainer
          selectedDebitCard={selectedDebitCard}
          paymentAmount={paymentAmount}
          onBack={onBack}
          setPaymentScreen={setPaymentScreen}
        />
      );
    case 'error':
      return (
        <PaymentStatusContent
          title="Error processing your Debit Card Payment"
          icon={faXmarkCircle}
          iconClassName="text-red4"
          message={message || 'There was an error processing your payment. Please contact support.'}
        />
      );
    case 'success':
      return (
        <PaymentStatusContent
          title="Success"
          icon={faCheckCircle}
          iconClassName="text-atob-green"
          message="We've received your payment request. Please allow 1-2 minutes for this payment to reflect on your account and show on your Payment History page."
          showButton
        />
      );
  }
};

export default DebitCardController;
