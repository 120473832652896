import CustomerContext from '@app/contexts/customerContext';
import { useContext } from 'react';

type IssuingDetails = {
  issuingOnboardingIncomplete: boolean;
};

export const useIssuingDetails = (): IssuingDetails => {
  const { customer } = useContext(CustomerContext);
  const incompleteStates = ['not_enrolled', 'started', 'update_required'];

  const onboardingIncomplete =
    !!customer.issuing && incompleteStates.includes(customer.issuing?.status);

  return {
    issuingOnboardingIncomplete: onboardingIncomplete,
  };
};
