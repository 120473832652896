import { ThemeOptions, createTheme } from '@mui/material/styles';
import tw, { theme as tailwindTheme } from 'twin.macro';
import defaultTheme, { ensureAlphaValue } from './defaultTheme';

const palette = createTheme(defaultTheme, {
  palette: {
    primary: {
      main: ensureAlphaValue(tailwindTheme`colors.primary.600`),
      dark: ensureAlphaValue(tailwindTheme`colors.primary.800`),
    },
    success: {
      main: ensureAlphaValue(tailwindTheme`colors.success.400`),
      dark: ensureAlphaValue(tailwindTheme`colors.success.400`),
      contrastText: ensureAlphaValue(tailwindTheme`colors.black`),
    },
  },
});

export default createTheme(palette, {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          ...tw`normal-case shadow-none hover:shadow-none rounded-lg font-bold leading-5 text-sm px-4 py-2.5`,
        },
        containedSecondary: {
          'boxShadow': `0px 0px 0px 2px ${palette.palette.primary.main} inset`,
          '&:hover': {
            boxShadow: `0px 0px 0px 2px ${palette.palette.primary.main} inset`,
          },
          ...tw`bg-transparent text-primary-600`,
        },
        containedError: {
          '&:hover': {
            ...tw`bg-red-400`,
          },
          ...tw`bg-red-600 text-white`,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          ...tw`rounded-lg`,
        },
        input: {
          ...tw`pr-3 py-2.5 text-sm h-fit`,
          '&:focus': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .odd': {
            ...tw`bg-brown-100`,
          },
          '& .MuiDataGrid-columnSeparator': {
            ...tw`hidden`,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          ...tw`font-bold`,
        },
      },
    },
  },
} as ThemeOptions);
