import logger from '@app/utils/datadog-logger';
import { faEngineWarning } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/react';
import React from 'react';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  { hasError: boolean; error: Error | null; reportedError: boolean }
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null, reportedError: false };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    const { componentStack } = errorInfo;
    Sentry.captureException(error, { contexts: { react: { componentStack } } });
    logger.error('User hit an error', {
      error: error,
    });
    this.setState({ error });
  }

  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true };
  }

  reportError(): void {
    this.setState({ reportedError: true });
    logger.error('User reported error in error boundary', {
      error: this.state.error,
    });
  }

  render(): React.ReactNode {
    if (!this.state.hasError) {
      return this.props.children;
    }
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="fixed top-[68px] origin-center">
          <img src="/atob.png" alt="AtoB Logo" width={200} height={67} />
        </div>
        <div className="max-w-[340px] md:max-w-[600px]">
          <div className="bg-dark-blue flex max-h-[440px] flex-col rounded-md px-10 py-8 text-center text-white md:max-h-[340px]">
            <div className="mb-[30px] flex flex-col">
              <FontAwesomeIcon icon={faEngineWarning} className="text-dark-orange" size="3x" />
              <span className="text-dark-orange font-bold">CHECK</span>
            </div>
            {!this.state.reportedError && (
              <>
                <h2 className="text-[22px] font-bold text-white">Something went wrong</h2>
                <span>
                  We couldn’t load the page. Help us improve your experience by reporting this
                  error.
                </span>{' '}
              </>
            )}
            {this.state.reportedError && <span>Thanks for reporting! Our team is on it.</span>}
            <div className="flex w-full justify-around">
              {!this.state.reportedError && (
                <button
                  className="bg-dark-orange mt-[30px] rounded p-2"
                  onClick={() => this.reportError()}
                >
                  Report Error
                </button>
              )}
              <button
                className="mt-[30px] rounded bg-white p-2 text-black"
                onClick={() => (window.location.href = '/')}
              >
                Go to Home
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorBoundary;
