import { Button, SIZE } from 'baseui/button';
import { Heading, HeadingLevel } from 'baseui/heading';
import { ParagraphMedium } from 'baseui/typography';
import { ReactElement } from 'react';

import { useNavigate } from 'react-router-dom';

const InterestedView = (): ReactElement => {
  const navigate = useNavigate();
  const onClickGoToDashboard = () => {
    navigate('/');
  };

  return (
    <div style={{ paddingLeft: '24px', paddingRight: '24px' }}>
      <HeadingLevel>
        <Heading className="mTFF" style={{ marginBottom: '32px' }}>
          Thanks for your interest!
        </Heading>
      </HeadingLevel>
      <ParagraphMedium className="mTFF" style={{ marginBottom: '32px' }}>
        An AtoB Team member will email you within one business day about next steps for transferring
        your AtoB account to an Uber Freight Carrier Card.
      </ParagraphMedium>
      <Button
        onClick={onClickGoToDashboard}
        size={SIZE.large}
        className="mTFF"
        style={{ width: '100%', borderRadius: '0px' }}
      >
        Close
      </Button>
    </div>
  );
};

export default InterestedView;
