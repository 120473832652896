import { ChargeEventData } from '@app/@types/charge_events.types';
import { LineItemDetails } from '@app/hooks/query/useLineItemsQuery';
import { FormatCurrency } from '@atob-developers/shared/src/utils/formatters';
import { ReactElement } from 'react';
import { DetailElement, DetailSection, DetailStatus } from './DetailSection';

export const ChargeEventPurchaseTypeDetails = ({
  chargeEvent,
  lineItemsData,
}: {
  chargeEvent: ChargeEventData;
  lineItemsData?: LineItemDetails;
}): ReactElement | null => {
  const transactionId = chargeEvent?.charge_eventable_id;

  if (!transactionId) {
    return null;
  }

  return (
    <DetailSection title="Purchase Type">
      <div className="grid lg:grid-cols-2 lg:gap-24">
        <div>
          <ChargeEventPurchaseTypeDetailsLineItems
            chargeEvent={chargeEvent}
            lineItemsData={lineItemsData}
          />
        </div>
        <div>{/* Empty second column */}</div>
      </div>
    </DetailSection>
  );
};

const ChargeEventPurchaseTypeDetailsLineItems = ({
  chargeEvent,
  lineItemsData,
}: {
  chargeEvent: ChargeEventData;
  lineItemsData?: LineItemDetails;
}): ReactElement => {
  // Closed ChargeEvents will not receive any more info. Do not show Pending, show n/a instead.
  const closed = chargeEvent.auth_status === 'closed';

  // Settled ChargeEvents are approved and will have a transaction.
  const settled = closed && chargeEvent.auth_approved;

  const status = closed ? (settled ? 'available' : 'unavailable') : 'pending';

  const nonFuel =
    lineItemsData?.purchase_type === 'non_fuel' ||
    lineItemsData?.purchase_type === 'fuel_and_non_fuel';

  const purchaseTypeUnavailable = lineItemsData?.purchase_type === 'unavailable';

  const label = purchaseTypeUnavailable
    ? 'Unavailable'
    : nonFuel
    ? 'Fuel and Non-Fuel'
    : 'Fuel Only';

  if (!chargeEvent || !lineItemsData) {
    return (
      <>
        <DetailElement name="Purchase Type" value={DetailStatus.NotAvailable} />
      </>
    );
  }

  if (status === 'pending') {
    return (
      <>
        <DetailElement name="Purchase Type" value={DetailStatus.Pending} />
        <div className="px-6 py-2 text-sm text-gray-500">
          If available, the line item breakdown of this transaction will appear within 36 hours of
          transaction date
        </div>
      </>
    );
  }

  if (
    status === 'unavailable' ||
    lineItemsData.line_items?.length === 0 ||
    !chargeEvent.auth_approved
  ) {
    return (
      <>
        <DetailElement name="Purchase Type" value={label} />
        <div className="px-6 py-2 text-sm text-gray-500">
          Line item breakdown not available for this transaction.
        </div>
      </>
    );
  }

  if (!lineItemsData.line_items_ready) {
    return (
      <>
        <DetailElement name="Purchase Type" value={label} />
        <div className="px-6 py-2 text-sm text-gray-500">
          If available, the line item breakdown of this transaction will appear within 36 hours of
          transaction date
        </div>
      </>
    );
  }

  const shouldShowPrice = lineItemsData.line_items.length > 1;

  return (
    <>
      <DetailElement name="Purchase Type" value={label} />
      {lineItemsData.line_items?.map((item, index) => (
        <DetailElement
          key={index}
          name="Product Item"
          value={`${item.description} ${
            shouldShowPrice ? `(${FormatCurrency({ value: item.amount_cents })})` : ''
          }`}
        />
      ))}
    </>
  );
};

export default ChargeEventPurchaseTypeDetails;
