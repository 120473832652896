import { TextField } from '@mui/material';
import { ReactElement, useState } from 'react';

type CardLastFourSearchProps = {
  search: string;
  onSearch: (search: string) => void;
};

export default function CardLastFourSearch({
  search,
  onSearch,
}: CardLastFourSearchProps): ReactElement {
  const [value, setValue] = useState(search || '');

  return (
    <TextField
      size="small"
      placeholder="Search by card number"
      onChange={(event) => {
        const newSearch = event.target.value;
        if (newSearch.length > 4) return;

        if (newSearch) {
          const number = parseInt(newSearch);
          if (isNaN(number)) return;
        }

        setValue(newSearch);
        onSearch(newSearch);
      }}
      value={value}
    />
  );
}
