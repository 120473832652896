import { formatDate } from '@app/utils/formatDate';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { ReactElement, useState } from 'react';
import type { SpendPromotion } from './PathToCredit';

export default function PathNotificationModal({
  spendPromotion,
  modalType,
  onClose,
}: {
  spendPromotion: SpendPromotion;
  modalType: 'progress' | 'reset' | null;
  onClose: () => void;
}): ReactElement {
  if (modalType === 'progress') {
    return <PathWeeklySuccessModal spendPromotion={spendPromotion} onClose={onClose} />;
  }

  if (modalType === 'reset') {
    return <PathResetModal spendPromotion={spendPromotion} onClose={onClose} />;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return null;
}

const PathWeeklySuccessModal = ({
  onClose,
  spendPromotion,
}: {
  onClose: () => void;
  spendPromotion: SpendPromotion;
}): ReactElement | null => {
  const [open, setOpen] = useState(true);
  const closeModal = () => {
    setOpen(false);
    onClose();
  };

  const closeIcon = (
    <IconButton onClick={closeModal}>
      <FontAwesomeIcon icon={faXmark} className="h-5 w-5" />
    </IconButton>
  );

  return (
    <Dialog maxWidth="xs" open={open} onClose={closeModal}>
      <>
        <div className="flex flex-row items-center">
          <img
            src="/images/path-to-credit-weekly-success.png"
            alt="popup-header"
            className="w-full"
          />
          <div className="absolute right-4 top-4">{closeIcon}</div>
        </div>
      </>
      <DialogTitle>
        <h3 className="text-2xl font-bold">Another week of spend target reached! Keep it up!</h3>
      </DialogTitle>
      <DialogContent>
        <div className="mb-16 text-sm">
          Continue earning toward a credit line by spending at least{' '}
          {spendPromotion.goal_spend_amount} this week to reach the target.
        </div>
      </DialogContent>
    </Dialog>
  );
};

const PathResetModal = ({
  onClose,
  spendPromotion,
}: {
  onClose: () => void;
  spendPromotion: SpendPromotion;
}): ReactElement | null => {
  const [open, setOpen] = useState(true);
  const closeModal = () => {
    setOpen(false);
    onClose();
  };
  const closeIcon = (
    <IconButton onClick={closeModal}>
      <FontAwesomeIcon icon={faXmark} className="h-5 w-5" />
    </IconButton>
  );

  const endDate = new Date();
  endDate.setDate(endDate.getDate() + spendPromotion.total_weeks_remaining * 7);

  return (
    <Dialog maxWidth="xs" open={open} onClose={closeModal}>
      <>
        <div className="flex flex-row items-center">
          <img src="/images/path-to-credit-reset.png" alt="popup-header" className="w-full" />
          <div className="absolute right-0 top-0">{closeIcon}</div>
        </div>
      </>
      <DialogTitle>
        <h3 className="text-2xl font-bold">Spent target tracker has been reset</h3>
      </DialogTitle>
      <DialogContent>
        <div className="mb-16 text-sm">
          You will not be able to complete spend targets of {spendPromotion.goal_spend_amount} for{' '}
          {spendPromotion.goal_target_weeks} weeks by {formatDate(endDate)}, and therefore will
          unfortunately have to start over. But hey, no better time for a fresh, strong start than
          this week!
        </div>
      </DialogContent>
    </Dialog>
  );
};
