import { EndpointResponse } from '@app/@types/api.types';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import { getFetcher } from '@app/utils/data/fetchers';
import { ReactElement, useState } from 'react';
import useSWRImmutable from 'swr/immutable';
import TosReacceptanceModal from '../Modals/TosReacceptanceModal';
import { WarningBanner } from './Designs/WarningBanner';

type TosReacceptanceRequiredResponse = {
  reacceptance_required: boolean;
};

export const IssuingTosAcceptanceBanner = (): ReactElement | null => {
  const [modalOpen, setModalOpen] = useState(true);
  const [tosAccepted, setTosAccepted] = useState(false);
  const [TOS_REACCEPTANCE_ROLLOUT] = useFeatureFlags('tos_reacceptance_rollout');

  const {
    data: data,
    isLoading: loading,
    error: error,
  } = useSWRImmutable<EndpointResponse<TosReacceptanceRequiredResponse>>(
    { url: `/byoc/tos_reacceptance_required` },
    getFetcher,
  );

  if (!TOS_REACCEPTANCE_ROLLOUT) return null;
  if (loading || error || !data || !data.data.reacceptance_required || tosAccepted) return null;

  return (
    <>
      <TosReacceptanceModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onAccept={() => setTosAccepted(true)}
      />
      <WarningBanner onClick={() => setModalOpen(true)} actionText="View Terms">
        <span className="font-semibold">Action Required: </span>
        Please review and accept our updated terms of service to ensure no disruption in using your
        cards.
      </WarningBanner>
    </>
  );
};

export default IssuingTosAcceptanceBanner;
