import { Spacer } from '@app/components/elements';
import useWindowWidth from '@app/hooks/useWindowWidth';
import Pagination from '@atob-developers/shared/src/components/Pagination';
import Table from '@atob-developers/shared/src/components/Table';
import { Chip } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState, ReactElement } from 'react';
import { WalletCredit } from '../Overview';

type WalletCreditColumn = {
  key: string;
  title: string;
};

const WALLET_CREDIT_COLUMNS = [
  {
    key: 'amount',
    title: 'Amount',
  },
  {
    key: 'date',
    title: 'Date',
  },
  {
    key: 'status',
    title: 'Status',
  },
];

const MobileRow = ({ data }: { data: WalletCredit }) => {
  const { amount, created_timestamp } = data;

  return (
    <div className="flex w-full flex-col justify-between">
      <div className="mb-2 flex w-full justify-between">
        <div className="text-sm font-light">
          Amount: <b>{amount}</b>
        </div>
        <div className="text-black0 text-sm font-medium">
          {dayjs.unix(created_timestamp).format('MMMM YYYY')}
        </div>
      </div>
      <div className="text-sm font-light">
        Status: <Chip label="Applied" color="success" />
      </div>
    </div>
  );
};

const DesktopRow = ({ data }: { data: WalletCredit }) => {
  const { amount, created_timestamp } = data;
  return (
    <div className="flex w-full flex-col">
      <div className="flex items-center">
        <div className="xs:basis-1/3 xs:mb-0 mb-4 flex flex-wrap items-center ">
          <p className="text-atob-green pr-3 text-sm font-medium">{amount}</p>
        </div>
        <p className="text-black0 xs:items-center xs:basis-1/3 flex flex-wrap items-start text-sm font-medium">
          {dayjs.unix(created_timestamp).format('MMMM YYYY')}
        </p>
        <p className="text-black0 xs:items-center xs:basis-1/3 flex flex-wrap items-start text-sm font-medium">
          <Chip label="Applied" color="success" />
        </p>
      </div>
    </div>
  );
};

const createRowsUI = (isMobile: boolean) =>
  function WalletCreditRow(data: WalletCredit) {
    return (
      <React.Fragment key={data.id}>
        {isMobile ? <MobileRow data={data} /> : <DesktopRow data={data} />}
      </React.Fragment>
    );
  };

const createColumnsUI = (isMobile: boolean) =>
  WALLET_CREDIT_COLUMNS.map((column: WalletCreditColumn): ReactElement | null => {
    if (isMobile) {
      return null;
    }

    return (
      <p
        className={'text-black1 flex basis-1/3 flex-wrap justify-between text-sm font-medium'}
        key={column.key}
      >
        {column.title}
      </p>
    );
  });

interface WalletCreditsTableProps {
  credits: WalletCredit[];
}

export default function WalletCreditsTable({ credits }: WalletCreditsTableProps): ReactElement {
  const [page, setPage] = useState(1);
  const { isMobile } = useWindowWidth();
  const rowsPerPage = isMobile ? 5 : 10;

  return (
    <>
      <Table
        data={credits}
        columns={createColumnsUI(isMobile)}
        rowDataToUIMapper={createRowsUI(isMobile)}
        hideArrow={true}
      />
      <Spacer />
      <Pagination
        key={Math.ceil(credits.length / rowsPerPage)}
        pages={Math.ceil(credits.length / rowsPerPage)}
        currentPage={page}
        onPageChange={setPage}
      />
    </>
  );
}
