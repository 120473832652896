import useCustomer from '@app/hooks/useCustomer';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import dayjsObjectSupport from 'dayjs/plugin/objectSupport';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import ExportTransactions from '../Exporting/Transactions/ExportTransactions';
import { ProductNudgeBanner } from './Designs/ProductNudgeBanner';

dayjs.extend(quarterOfYear);
dayjs.extend(dayjsObjectSupport);
dayjs.extend(advancedFormat);

const REPORT_DUE_MONTHS = [0, 3, 6, 9];

export default function IFTANudgeBanner({
  hideTransactionsBump = false,
}: {
  hideTransactionsBump?: boolean;
}): ReactElement | null {
  const [isOpen, setIsOpen] = useState(false);
  const today = dayjs();
  const customer = useCustomer();
  const isOTRCustomer = customer.fleet_type === 'heavy' || customer.fleet_type === 'mixed';

  if (
    !REPORT_DUE_MONTHS.some((month) => today.isSame(today.set('month', month), 'month')) ||
    (customer.fleet_type != null && !isOTRCustomer)
  ) {
    return null;
  }
  const dateInQuarter = today.subtract(1, 'month');
  return (
    <>
      <ProductNudgeBanner
        actionText="Download now"
        bannerKey={`ifta-${today.month()}-${today.year()}"`}
        variant="pro_tip"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        Download your Q{dateInQuarter.format('Q YYYY')} IFTA report now.{' '}
        {!hideTransactionsBump && (
          <>
            They're also always available from{' '}
            <Link to={'/transactions'} className="underline">
              Transactions
            </Link>
          </>
        )}
      </ProductNudgeBanner>
      <ExportTransactions
        open={isOpen}
        onClose={() => setIsOpen(false)}
        selectedFilters={{}}
        initialTransactionType="transactions_ifta"
        hideFiltersNotice={true}
        initialDateRange={[
          today.subtract(1, 'month').startOf('quarter'),
          today.subtract(1, 'month').endOf('quarter'),
        ]}
      />
    </>
  );
}
