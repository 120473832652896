import { CustomerData } from '@app/@types/customer.types';
import { CreditLimitIncreaseRequestModal } from '@app/components/Modals/CreditLimitIncreaseRequest/CreditLimitIncreaseRequestModal';
import { ULToCreditRequest } from '@app/components/Modals/ULToCreditRequest/ULToCreditRequest';

import { BaseProvider, LightTheme } from 'baseui';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';

const engine = new Styletron();

const RequestCredit = (props: { customer: CustomerData; isPrepaid: boolean }): ReactElement => {
  const navigate = useNavigate();

  const isPrepaid = props.isPrepaid;

  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={LightTheme}>
        {isPrepaid && <ULToCreditRequest open={true} toggleModal={() => navigate('/')} />}
        {!isPrepaid && (
          <CreditLimitIncreaseRequestModal open={true} onClose={() => navigate('/')} />
        )}
      </BaseProvider>
    </StyletronProvider>
  );
};

export default RequestCredit;
