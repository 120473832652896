import { TruckType } from '@app/@types/telematics.types';
import { panMapToBounds } from '@app/utils/Telematics/TelematicsMapsUtils';
import { getEnvironment } from '@app/utils/environment';
import GoogleMap from 'google-maps-react-markers';
import { useEffect, useState } from 'react';
import { DriverMarker } from './TelematicsMapsComponents/MapMarkers';
import './TrucksMap.scss';

interface TrucksMapProps {
  selectTruck: (truck: TruckType, setError: (e: unknown) => void) => void;
  trucks: TruckType[];
  activeTruck: TruckType;
}

const TrucksMap = ({ trucks, activeTruck, selectTruck }: TrucksMapProps) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const [googleMap, setGoogleMap] = useState<google.maps.Map>(null);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const [mapsApi, setMapsApi] = useState<typeof google.maps>(null);
  const { VITE_GOOGLE_API_KEY, VITE_MAP_ID_NORMAL } = getEnvironment();

  const apiIsLoaded = ({ map, maps }: { map: google.maps.Map; maps: typeof google.maps }) => {
    setGoogleMap(map);
    setMapsApi(maps);
  };

  const mapOptions = {
    zoomControlOptions: {
      position: mapsApi?.ControlPosition.RIGHT_CENTER,
      style: mapsApi?.ZoomControlStyle.SMALL,
    },
    mapId: String(VITE_MAP_ID_NORMAL),
    disableDefaultUI: true,
    scaleControl: true,
    zoomControl: true,
    fullscreenControl: true,
    clickableIcons: false,
  };
  useEffect(() => {
    let geodesicPolyline: google.maps.Polyline;
    if (googleMap && mapsApi && trucks?.length > 0) {
      let coords = [];
      if (activeTruck) {
        if (activeTruck.waypoints && activeTruck.waypoints.length > 10) {
          const markers = activeTruck.waypoints
            .sort((p1, p2) => Number(p1.SK1) - Number(p2.SK1))
            .map((point) => ({ lat: point.latitude, lng: point.longitude }));
          geodesicPolyline = new mapsApi.Polyline({
            path: markers,
            geodesic: true,
            strokeColor: 'green',
            strokeOpacity: 1.0,
            strokeWeight: 4,
          });
          geodesicPolyline.setMap(googleMap);
          coords = markers;
        } else {
          coords = [
            {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              lat: activeTruck.location.latitude,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              lng: activeTruck.location.longitude,
            },
          ];
        }
      } else {
        coords = trucks.map((truck) => ({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          lat: truck.location.latitude,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          lng: truck.location.longitude,
        }));
      }
      panMapToBounds(googleMap, mapsApi, coords);
    }

    return () => {
      if (geodesicPolyline !== undefined) geodesicPolyline.setMap(null);
    };
  }, [googleMap, mapsApi, trucks, activeTruck]);

  return (
    <GoogleMap
      apiKey={String(VITE_GOOGLE_API_KEY)}
      defaultCenter={{ lat: 37.0902, lng: -95.7129 }}
      defaultZoom={1}
      onGoogleApiLoaded={(api: { map: google.maps.Map; maps: typeof google.maps }) =>
        apiIsLoaded(api)
      }
      options={mapOptions}
    >
      {activeTruck ? (
        <DriverMarker
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          lat={activeTruck.location.latitude}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          lng={activeTruck.location.longitude}
          key={activeTruck.vin}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          selectTruck={selectTruck}
          truck={activeTruck}
        />
      ) : (
        trucks?.map((truck) => (
          <DriverMarker
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            lat={truck.location.latitude}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            lng={truck.location.longitude}
            key={truck.vin}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            selectTruck={selectTruck}
            truck={truck}
          />
        ))
      )}
    </GoogleMap>
  );
};

export default TrucksMap;
