import * as Sentry from '@sentry/react';
import { useCallback } from 'react';
import useForceUpdate from './useForceUpdate';

export default function useLocalStorageValues(): [
  () => Record<string, unknown>,
  (values: Record<string, unknown>) => void,
  number,
] {
  const [key, forceUpdate] = useForceUpdate();
  // eslint-disable-next-line no-explicit-atob-mentions
  let atobPropsItem: string | null = null;
  try {
    atobPropsItem = localStorage.getItem('atob-props');
  } catch (e: unknown) {
    Sentry.captureException(e);
  }

  const getValues = useCallback(
    () => (atobPropsItem && JSON.parse(atobPropsItem)) || {},
    [atobPropsItem],
  );

  const setValues = (values: Record<string, unknown>) => {
    let appProps: Record<string, unknown>;
    try {
      appProps = getValues();
    } catch {
      appProps = {};
    }

    localStorage.setItem(
      // eslint-disable-next-line no-explicit-atob-mentions
      'atob-props',
      JSON.stringify({
        ...appProps,
        ...values,
      }),
    );
    forceUpdate();
  };

  return [getValues, setValues, key];
}
