import { TruckDataResponseType, TruckType } from '@app/@types/telematics.types';
import TelematicsMapContext from '@app/contexts/telematicsMapContext';
import axios from 'axios';
import { deserialize } from 'deserialize-json-api';
import { ReactElement, useEffect, useState, useMemo } from 'react';
import MapContainer from './MapContainer';

const RELOAD_INTERVAL = 1000 * 60 * 5; // 5 minutes

const TelematicsMap = (): ReactElement => {
  const [trucks, setTrucks] = useState<TruckType[]>([]);
  const [activeTruck, setActiveTruck] = useState<TruckType | null>(null);

  useEffect(() => {
    const getTrucks = () =>
      axios
        .get<TruckDataResponseType>('/telematics/vehicles')
        .then(({ data }) => {
          setTrucks(
            deserialize(data).data.filter((v: TruckType) => {
              return v.location?.latitude !== null && v.location?.longitude !== null;
            }),
          );
        })
        .catch((e) => e.message);

    getTrucks();

    const interval = setInterval(() => getTrucks(), RELOAD_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  const telematicsContextValue = useMemo(
    () => ({
      trucks,
      activeTruck,
      setActiveTruck,
    }),
    [activeTruck, trucks],
  );

  return (
    <TelematicsMapContext.Provider value={telematicsContextValue}>
      <MapContainer />
    </TelematicsMapContext.Provider>
  );
};

export default TelematicsMap;
