import axios from 'axios';
import { useCallback, useState } from 'react';

type EndpointPayrollCalculationResult = {
  fees: [
    {
      message: string;
      amount_in_cents: number;
    },
  ];
  billing_policy: number;
};

type PayrollBillingFee = EndpointPayrollCalculationResult | 'billing-not-enabled' | null;

export default function usePayrollBillingCalculation(): readonly [
  PayrollBillingFee,
  (value: string) => void,
] {
  const [fees, setFees] = useState<PayrollBillingFee>(null);

  const setAmountInCents = useCallback(
    async (value: string) => {
      try {
        const { data } = await axios.get<EndpointPayrollCalculationResult>(
          '/payroll/billing_fees/calculate?amount_in_cents=' + value,
        );
        setFees(data);
      } catch (e: unknown) {
        setFees('billing-not-enabled');
      }
    },
    [setFees],
  );

  return [fees, setAmountInCents] as const;
}
