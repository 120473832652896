import { ELDProvider } from '@app/@types/eld-connect.types';
import { useTelematicsProvider } from '@app/hooks/query/useConnectTelematics';
import { getHeaderMessage } from '@app/utils/Telematics/TelematicsSharedUtils';
import { Alert } from '@mui/material';
import { ReactElement, useEffect, useState } from 'react';
import TelematicsConnectionModal from './ConnectionModal';
import ProviderDetailView from './ProviderDetailView';
import TelematicsIntegrationOptions from './TelematicsIntegrationOptions';

const TelematicsIntegration = (): ReactElement => {
  const [selectedProvider, setSelectedProvider] = useState<ELDProvider | null>(null);
  const [connectedProvider, setConnectedProvider] = useState<ELDProvider[]>([]);
  const { data, isLoading } = useTelematicsProvider();
  const [connectModalOpen, setConnectModalOpen] = useState(false);
  const headerMessage = getHeaderMessage();

  const providers = data?.data.providers;
  const purchaseUploadingStatus = data?.data.purchase_uploading;

  const handleConnect = (provider: ELDProvider) => {
    setSelectedProvider(provider);
    setConnectModalOpen(true);
  };

  const handleConnectedClick = (provider: ELDProvider) => {
    handleConnect(provider);
  };

  useEffect(() => {
    if (providers) {
      const connectedProvider = providers.filter((provider) => provider.connected);
      if (connectedProvider) {
        setConnectedProvider(connectedProvider);
      }
    }
  }, [providers]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="mb-8">
      <h2 className="mb-4 font-semibold">Telematics Integrations</h2>
      {headerMessage && (
        <Alert severity="error" className="mb-4">
          {headerMessage}
        </Alert>
      )}
      {connectedProvider.length === 0 ? (
        <TelematicsIntegrationOptions onConnect={handleConnect} providers={providers} />
      ) : (
        <ProviderDetailView
          connectedProviders={connectedProvider}
          onClick={handleConnectedClick}
          purchaseUploadingStatus={purchaseUploadingStatus || 'unavailable'}
        />
      )}
      {connectModalOpen && (
        <TelematicsConnectionModal
          provider={selectedProvider}
          closeModal={() => setConnectModalOpen(false)}
        />
      )}
    </div>
  );
};

export default TelematicsIntegration;
