import logger from '@app/utils/datadog-logger';
import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import React, { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import UnverifiedUser from '../components/UnverifiedUser/UnverifiedUser';
import { ErrorNotification, Loading } from '../components/layout';
import { guardError } from '../utils/error/guards';

const Auth = ({ children }: { children: React.ReactNode }): ReactElement => {
  const {
    getAccessTokenSilently,
    loginWithRedirect,
    isAuthenticated: isAuth0Authenticated,
    isLoading: isAuth0Loading,
    logout,
  } = useAuth0();
  const [error, setError] = useState(null);
  const [authToken, setAuthToken] = useState(null);
  const [emailVerificationRequired, setEmailVerificationRequired] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    if (isAuth0Loading) return;
    const getAuthToken = async () => {
      try {
        const authToken = await getAccessTokenSilently();
        axios.defaults.headers.common.Authorization = `Bearer ${authToken}`;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        setAuthToken(authToken);
      } catch (e: unknown) {
        if (!guardError(e)) {
          return;
        }

        logger.error('Error while authenticating: ', {
          error: e,
          error_message: e.message,
        });

        if (
          e.message === 'Login required' ||
          e.message === 'Consent required' ||
          // catch refresh token related error,
          // i.e. if error contains "refresh token" in case insensitive manner
          /refresh token/i.test(e.message)
        ) {
          !isAuth0Authenticated &&
            loginWithRedirect({
              appState: {
                returnTo: pathname,
              },
            });
        } else if (e.message === 'email_verification_required') {
          setEmailVerificationRequired(true);
          // eslint-disable-next-line @cspell/spellchecker
        } else if (e.message === 'Multifactor authentication required') {
          // In this case, the user is stuck in the MFA verification process. Log out to clear out the authentication.
          // It is also possible to do loginWithRedirect, but that means if the user is stuck, there is no way
          // to start from scratch.
          logout();
        } else {
          Sentry.captureException(e);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          setError(e);
        }
      }
    };

    getAuthToken();
  }, [
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
    isAuth0Authenticated,
    isAuth0Loading,
    pathname,
  ]);

  if (emailVerificationRequired) {
    return <UnverifiedUser />;
  } else if (error) {
    return (
      <ErrorNotification error={error}>
        We&apos;ve had an unexpected issue with your request and are looking into it. Please{' '}
        <a href="#" className="underline" onClick={() => logout()}>
          log out
        </a>{' '}
        and try again.
      </ErrorNotification>
    );
  } else if (isAuth0Loading || !authToken) {
    return <Loading />;
  }

  return <>{children}</>;
};

export default Auth;
