import { PaymentIntentResponse } from '@app/@types/payments.types';
import StripeContext from '@app/contexts/stripeContext';
import ApiEndpoints from '@app/utils/data/apiEndpoints';
import logger from '@app/utils/datadog-logger';
import { guardAxiosError } from '@app/utils/error/guards';
import { isRetryableError } from '@app/utils/error/isRetryableError';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { PaymentIntentOptions } from './PayWithPaymentMethod';

export default function useStripeIntent(
  shouldReturnEarly: boolean,
  payload: {
    intent: PaymentIntentOptions;
  },
): {
  clientSecret: string;
  error: string | null;
} {
  const { clientSecret, setClientSecret, setLoadingIntent, loadingIntent } =
    useContext(StripeContext);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (shouldReturnEarly || clientSecret || error || loadingIntent) {
      return;
    }

    const generateClientSecret = async () => {
      setLoadingIntent(true);
      try {
        const result = await axios.post<PaymentIntentResponse>(
          ApiEndpoints.PAYMENTS_ENDPOINTS.STRIPE_INTENTS_ENDPOINT,
          payload,
        );

        const { data } = result;
        const newClientSecret = data?.data?.attributes?.client_secret;

        setClientSecret(newClientSecret);
      } catch (error) {
        const thrownError = guardAxiosError(error) ? error.response?.data : error;
        const errorToLog = thrownError?.errors?.[0] ?? thrownError;

        logger.error('Error generating Stripe client secret', {
          error: errorToLog,
        });

        let errorMessage =
          'There was a problem loading the payment method. Please try again or contact support';
        if (isRetryableError(error)) {
          errorMessage = 'Verification required. Please retry after completing the verification';
          setLoadingIntent(false);
          setError(errorMessage);
          return;
        }

        if (guardAxiosError(error)) {
          const [axiosMessage = ''] = error.response?.data?.errors || [];
          errorMessage = axiosMessage || errorMessage;

          // Translate BE errors to human readable messages.
          if (errorMessage === 'Payment amount too large') {
            errorMessage =
              'The entered payment amount is too large. Please try again with a smaller amount to prevent overpayment.';
          }
        }
        setError(errorMessage);
      }

      setLoadingIntent(false);
    };

    generateClientSecret();
  }, [
    shouldReturnEarly,
    payload,
    setClientSecret,
    error,
    loadingIntent,
    setLoadingIntent,
    clientSecret,
  ]);

  return {
    clientSecret: clientSecret,
    error: error,
  };
}
