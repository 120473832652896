import { ELDProvider } from '@app/@types/eld-connect.types';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ConnectionModalFooter from './ConnectionModalFooter';

interface TelematicsConnectFormModalProps {
  provider: ELDProvider;
  closeModal: () => void;
}

const TelematicsConnectFormModal = ({
  provider,
  closeModal,
}: TelematicsConnectFormModalProps): ReactElement => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const clickHandler = () => {
    setIsLoading(true);
    // Some providers need to be connected via a form for users to input login details.
    navigate(`/telematics/${provider.id}/connect`);
  };

  return (
    <Dialog open={true} onClose={closeModal}>
      <DialogTitle>{`Connect to ${provider.displayName}`}</DialogTitle>
      <DialogContent>You'll be asked for more details to connect your account.</DialogContent>
      <ConnectionModalFooter
        clickHandler={clickHandler}
        isLoading={isLoading}
        closeModal={closeModal}
      />
    </Dialog>
  );
};

export default TelematicsConnectFormModal;
