import { PayrollEmployeeType } from '@app/@types/payroll.types';
import TooltipMessage from '@app/components/elements/TooltipMessage';
import Constants from '@app/utils/constants';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { MenuItem, Select } from '@mui/material';
import axios from 'axios';
import { ReactElement, useContext, useState } from 'react';
import PayrollContext from '../payroll-context';

type EmployeeType = {
  id: string;
  name: PayrollEmployeeType | '';
};

const EMPLOYEE_TYPES: EmployeeType[] = [
  { name: Constants.PayrollEnums.W2, id: '0' },
  { name: Constants.PayrollEnums.TEN_NINETY_NINE, id: '1' },
  { name: '', id: '3' },
];

const EmployeeTypeDropdownSelector = ({
  value,
  driverId,
  status,
  currentDriverId,
  disabled,
}: {
  value: PayrollEmployeeType | undefined;
  driverId: string;
  status: string | undefined | null;
  currentDriverId?: string;
  disabled?: boolean;
}): ReactElement => {
  const selectedEmployeeType = value
    ? EMPLOYEE_TYPES.filter(({ name }) => name === value)[0]
    : EMPLOYEE_TYPES[2];
  const [taxClassification, setEmployeeType] = useState(selectedEmployeeType);
  const { updateDriverInState } = useContext(PayrollContext);
  const { addToast } = useToasts();
  const renderCardItem = (type: EmployeeType) => {
    const isW2 = type.name === Constants.PayrollEnums.W2;

    if (isW2) {
      return <div data-testid="dropdown-card-w2">W2</div>;
    }

    return <div data-testid="dropdown-card">{type.name}</div>;
  };
  const showTooltip = taxClassification.name === '' && currentDriverId === driverId;

  const updateEmployeeType = (type: EmployeeType | null) => {
    if (!type) {
      return;
    }
    setEmployeeType({ name: 'Saving...', id: '4' });
    const isTenNinetyNineEmployee = type.name === Constants.PayrollEnums.TEN_NINETY_NINE;
    const successCopy = isTenNinetyNineEmployee
      ? `Driver saved! Please send a confirmation by pressing the 'Invite' button.`
      : `We've saved this driver record, but only support 1099 employees for instant payroll at this time.`;

    const classification = type.name === '' ? null : type.name;

    axios
      .put(`/drivers/${driverId}`, {
        driver: { payroll: { tax_classification: classification } },
      })
      .then(() => {
        updateDriverInState(driverId, { tax_classification: classification });
        setEmployeeType(type);
        addToast(successCopy, {
          appearance: 'success',
        });
      })
      .catch(() => {
        addToast('There was an error saving the employee type. Please try again.', {
          appearance: 'error',
        });
        setEmployeeType(taxClassification);
      });
  };

  return (
    <div
      className={`relative w-20 text-left ${
        showTooltip ? `rounded border-2 border-green-500` : ''
      }`}
    >
      <Select
        value={taxClassification.id}
        onChange={(e) => {
          const type = EMPLOYEE_TYPES.find((item) => item.id === e.target.value);
          type && updateEmployeeType(type);
        }}
        fullWidth
        disabled={disabled || status === Constants.PayrollEnums.ENROLLED}
        data-testid="employee-type-dropdown"
      >
        {EMPLOYEE_TYPES.slice(0, 2).map((item) => (
          <MenuItem key={item.id} value={item.id} classes={{ root: 'flex flex-col items-start' }}>
            {renderCardItem(item)}
          </MenuItem>
        ))}
      </Select>
      {showTooltip && <TooltipMessage>Required</TooltipMessage>}
    </div>
  );
};

export default EmployeeTypeDropdownSelector;
