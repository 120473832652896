import { ChargeEventData } from '@app/@types/charge_events.types';
import {
  GLOBAL_CUSTOMER_RESOURCE_TYPE,
  TAG_CUSTOMER_RESOURCE_TYPE,
} from '@app/constants/spendRestrictions';
import { AddToast } from '@atob-developers/shared/src/components/ToastProvider';
import { SnackbarContent } from '@mui/material';
import axios from 'axios';
import { Link } from 'react-router-dom';

export const GenerateMerchantRestrictionBulkAction = (
  {
    text,
    customerResourceType,
    selectedTransaction,
    ruleType,
    successToastMessage,
    successToastLink,
    errorToastMessage,
  }: {
    text: string;
    customerResourceType: string;
    selectedTransaction: ChargeEventData;
    ruleType: string;
    successToastMessage: string;
    successToastLink: string;
    errorToastMessage: string;
  },
  addToast: AddToast,
): {
  text: string;
  onClick: () => void;
} => {
  return {
    text: text,
    onClick: () => {
      const fullState = {
        merchant_restriction: {
          customer_resource_type: customerResourceType,
          customer_resource_id: selectedTransaction?.tag?.id,
          merchant_id: selectedTransaction?.auth_merchant_id,
          rule_type: ruleType,
        },
      };
      try {
        axios.post('/spend_restrictions/merchant_restriction', fullState);
        addToast(
          <SnackbarContent
            message={
              <>
                {successToastMessage} Click{' '}
                <Link to={successToastLink} className="underline">
                  here
                </Link>{' '}
                to view
              </>
            }
          />,
          {
            appearance: 'success',
          },
        );
      } catch {
        addToast(errorToastMessage, {
          appearance: 'error',
        });
      }
    },
  };
};

export const generateBulkActionList = (
  selectedTransaction: ChargeEventData,
  allowMerchantRestrictions: boolean,
  addToast: AddToast,
): {
  text: string;
  onClick: () => void;
}[] => {
  const bulkWhitelistMerchantsGlobal = GenerateMerchantRestrictionBulkAction(
    {
      text: 'Whitelist Merchant',
      customerResourceType: GLOBAL_CUSTOMER_RESOURCE_TYPE,
      selectedTransaction: selectedTransaction,
      ruleType: 'allow',
      successToastMessage: `Successfully whitelisted merchant.`,
      successToastLink: '/policies/default',
      errorToastMessage: 'Unable to whitelist merchant',
    },
    addToast,
  );

  const bulkBlacklistMerchantsGlobal = GenerateMerchantRestrictionBulkAction(
    {
      text: `Blacklist Merchant`,
      customerResourceType: GLOBAL_CUSTOMER_RESOURCE_TYPE,
      selectedTransaction: selectedTransaction,
      ruleType: 'block',
      successToastMessage: `Successfully blacklisted merchant.`,
      successToastLink: '/policies/default',
      errorToastMessage: 'Unable to blacklist merchant',
    },
    addToast,
  );

  const bulkWhitelistMerchantsTags =
    selectedTransaction?.tag &&
    GenerateMerchantRestrictionBulkAction(
      {
        text: `Whitelist Merchant by ${selectedTransaction?.tag.name} Tag`,
        customerResourceType: TAG_CUSTOMER_RESOURCE_TYPE,
        selectedTransaction: selectedTransaction,
        ruleType: 'allow',
        successToastMessage: `Successfully whitelisted merchant by ${selectedTransaction?.tag?.name} tag.`,
        successToastLink: `/tags/${selectedTransaction?.tag?.id}`,
        errorToastMessage: `Unable to whitelist merchant by ${selectedTransaction?.tag?.name} tag`,
      },
      addToast,
    );

  const bulkBlacklistMerchantsTags =
    selectedTransaction?.tag &&
    GenerateMerchantRestrictionBulkAction(
      {
        text: `Blacklist Merchant by ${selectedTransaction?.tag?.name} Tag`,
        customerResourceType: TAG_CUSTOMER_RESOURCE_TYPE,
        selectedTransaction: selectedTransaction,
        ruleType: 'block',
        successToastMessage: `Successfully blacklisted merchant by ${selectedTransaction?.tag?.name} tag.`,
        successToastLink: `/tags/${selectedTransaction?.tag?.id}`,
        errorToastMessage: `Unable to blacklist merchant by ${selectedTransaction?.tag?.name} tag`,
      },
      addToast,
    );

  if (!selectedTransaction?.auth_merchant_id) {
    // The allowlist / blocklist should only be on the auth_merchant_id.
    // Do not provide those options if the transaction is a force capture.
    return [];
  }

  if (!selectedTransaction?.tag) {
    if (allowMerchantRestrictions) {
      return [bulkWhitelistMerchantsGlobal, bulkBlacklistMerchantsGlobal];
    } else {
      return [bulkBlacklistMerchantsGlobal];
    }
  } else {
    if (allowMerchantRestrictions) {
      return [
        bulkWhitelistMerchantsGlobal,
        bulkBlacklistMerchantsGlobal,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        bulkWhitelistMerchantsTags,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        bulkBlacklistMerchantsTags,
      ];
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      return [bulkBlacklistMerchantsGlobal, bulkBlacklistMerchantsTags];
    }
  }
};
