import { PayWithUIRenderPropTypes } from '@app/@types/payments.types';
import ApiEndpoints from '@app/utils/data/apiEndpoints';
import StripeElementsWrapper from './StripeElementsWrapper';
import useSubmitAccountPayment from './use-submit-account-payment';

export const PayWithAccountUI = StripeElementsWrapper(
  ({ onSuccess, children }: { onSuccess: () => void; children: PayWithUIRenderPropTypes }) => {
    const [onSubmitPayment, loading, error] = useSubmitAccountPayment(onSuccess);

    return children({ onSubmitPayment, loading, error });
  },
  ApiEndpoints.PAYMENTS_ENDPOINTS.PAYMENT_INTEGRATION_ENDPOINT,
);
