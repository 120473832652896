import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import {
  BUTTON_BG_COLORS,
  BUTTON_BORDER_COLORS,
  BUTTON_HOVER_BG_COLORS,
  BUTTON_HOVER_TEXT_COLORS,
  BUTTON_TEXT_COLORS,
  BUTTON_TYPES,
} from './constants';

const RADIUS_SIZE = {
  s: 'rounded',
  m: 'rounded-lg',
  l: 'rounded-full',
};

type ButtonProps = {
  type?: 'button' | 'submit';
  form?: string;
  rounded?: 's' | 'm' | 'l';
  dataTestId?: string;
} & {
  [key in BUTTON_TYPES]?: boolean;
} & HTMLAttributes<HTMLButtonElement>;

export default ({
  className,
  children,
  onClick,
  base = false,
  primary = false,
  secondary = false,
  dark = false,
  warning = false,
  danger = false,
  disabled = false,
  outline = false,
  silent = false,
  type = 'button',
  loading = false,
  rounded,
  form,
  dataTestId,
}: ButtonProps) => {
  const colors: { [key in BUTTON_TYPES]: boolean } = {
    base,
    primary,
    secondary,
    dark,
    warning,
    danger,
    disabled,
    outline,
    silent,
    loading,
  };

  const [buttonColorType] = Object.entries(colors).find(([, value]) => value) || ['base'];

  let view = children;
  if (loading) {
    view = <FontAwesomeIcon icon={faSpinner} className="animate-spin" />;
  }

  return (
    <button
      data-testid={dataTestId}
      type={type}
      disabled={disabled || loading}
      className={classNames(
        'inline-flex w-full cursor-pointer items-center justify-center border px-4 py-3 text-center text-sm font-medium leading-4 focus:outline-none focus:ring-2 focus:ring-offset-2',
        { 'shadow-md ': buttonColorType !== 'silent' },
        'disabled:cursor-not-allowed',
        BUTTON_TEXT_COLORS[buttonColorType],
        BUTTON_HOVER_TEXT_COLORS[buttonColorType],
        BUTTON_BG_COLORS[buttonColorType],
        BUTTON_HOVER_BG_COLORS[buttonColorType],
        BUTTON_BORDER_COLORS[buttonColorType],
        RADIUS_SIZE[rounded || 'l'],
        className,
      )}
      onClick={(e) => onClick && onClick(e)}
      form={form}
    >
      {view}
    </button>
  );
};
