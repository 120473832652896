import { faCreditCard, faCube } from '@fortawesome/pro-regular-svg-icons';
import { ReactElement } from 'react';
import { StatusBanner } from '../Banner/Designs/StatusBanner';

export const CardShipmentStatus = ({
  shippingAddress,
  cardsOrdered,
  cardsDelivered,
}: {
  shippingAddress: string;
  cardsOrdered: boolean;
  cardsDelivered: boolean;
}): ReactElement | null => {
  if (!cardsOrdered) {
    return null;
  }

  if (!cardsDelivered) {
    return (
      <StatusBanner
        icon={faCube}
        bannerKey="card_status_shipped_notification"
        nonDismissable
        badge="pending"
      >
        <b className="font-semibold text-gray-700">Your cards are on the way: </b>
        They will be shipped to {shippingAddress}
      </StatusBanner>
    );
  }

  return (
    <StatusBanner
      icon={faCreditCard}
      bannerKey="card_status_delivered_notification"
      badge="successful"
    >
      <b className="font-semibold text-gray-700">Your cards have been delivered to: </b>
      {shippingAddress}
    </StatusBanner>
  );
};
