import { usePaymentIntentQueryParams } from '@app/components/StripeElements/Handle3DS';
import useWelcomePopup from '@app/utils/onboarding/useWelcomePopup';
import { ReactElement, useState } from 'react';
import MWebToAppPopupView from './MWebToAppPopupView';
import useIsDeviceAppEligible from './useIsDeviceAppEligible';

export default function MWebToAppPopup(): ReactElement | null {
  const [shouldShowWelcomePopupFirst] = useWelcomePopup();

  const [isOpen, setIsOpen] = useState(false);
  const [deviceType, isEligibleToShow] = useIsDeviceAppEligible();
  const paymentIntentClientSecret = usePaymentIntentQueryParams();

  if (shouldShowWelcomePopupFirst || !isEligibleToShow || paymentIntentClientSecret != null) {
    return null;
  }

  return <MWebToAppPopupView isOpen={isOpen} setIsOpen={setIsOpen} deviceType={deviceType} />;
}
