export function basicFormatDate(date: Date): string {
  return date.toLocaleDateString('en-US');
}

export function formatDate(date: Date): string {
  return date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
}

export function formatDateNoYear(date: Date): string {
  return date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
  });
}

export function formatDateTimeNoYear(date: Date): string {
  return date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
}

export function formatDateNoDay(date: Date): string {
  return date.toLocaleString('en-US', {
    month: 'long',
    year: 'numeric',
  });
}

export function formatTime(date: Date): string {
  return date.toLocaleString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
}
const DAYS_OF_WEEK = Object.freeze([
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]);

export function formatDayOfWeek(date: Date): string {
  return DAYS_OF_WEEK[date.getDay()];
}
