import useThemeConfiguration from '@app/app/useThemeConfiguration';
import useDisplayUntilConfirmed from '@app/hooks/useDisplayUntilConfirmed';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import useWindowWidth from '@app/hooks/useWindowWidth';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';

export default function PayrollFeeBanner(): ReactElement | null {
  const { isMobile } = useWindowWidth();
  const [PAYROLL_BILLING_NONZERO_CHARGE_ENABLED] = useFeatureFlags(
    'payroll_billing_nonzero_charge',
  );

  const feeEnabled = PAYROLL_BILLING_NONZERO_CHARGE_ENABLED;
  const [checkIfConfirmed, handleConfirm] = useDisplayUntilConfirmed('hidePayrollBanner');
  const { hideDismissableBanners } = useThemeConfiguration();
  const shouldNotShowFee = !feeEnabled || checkIfConfirmed() || hideDismissableBanners;

  if (shouldNotShowFee) {
    return null;
  }

  const headerText = 'Your free plan for the first month has ended.';
  const subheaderText =
    'From now on there will be a small fee of 0.50% for every payroll transaction.';

  const MobileBanner = () => (
    <div className="column is-two-fifths-widescreen tracking-tight drop-shadow-lg">
      <div className="mb-6 flex w-full items-center justify-around rounded border p-6">
        <div className="text-[14px] font-[500] sm:max-w-[300px] lg:max-w-[400px]">
          <div className="text-atob-green mr-1 font-[700]">{headerText}</div>
          <div>{subheaderText}</div>
        </div>
        <div className="ml-3 cursor-pointer">
          <FontAwesomeIcon
            icon={faXmark}
            size="1x"
            className="text-grey2"
            onClick={handleConfirm}
          />
        </div>
      </div>
    </div>
  );

  const DesktopBanner = () => (
    <div className="column is-three-fifths-widescreen mb-6 flex w-full items-center justify-between rounded border p-8 shadow-lg">
      <div className="flex w-full items-center justify-between ">
        <div className="flex flex-col items-start">
          <h2 className="text-atob-green text-[18px] font-[700]">{headerText}</h2>
          <p className="text-[14px] font-[500] sm:max-w-[300px] lg:max-w-[400px]">
            {subheaderText}
          </p>
        </div>
        <FontAwesomeIcon
          icon={faXmark}
          size="1x"
          className="text-grey2 cursor-pointer"
          onClick={handleConfirm}
        />
      </div>
    </div>
  );

  return isMobile ? <MobileBanner /> : <DesktopBanner />;
}
