import { ReactElement } from 'react';

const TagTooltip = (): ReactElement => (
  <p>
    When a Card has a Tag, the following properties can only be edited on the Tag itself.
    <ul className="flex list-disc flex-col pl-5">
      <li>Daily, weekly and per transaction spend limits</li>
      <li>Card Unlock</li>
      <li>Telematics Setting</li>
    </ul>
    To do this, go to the Cards page and find the Tag in the Tags tab.
  </p>
);

export default TagTooltip;
