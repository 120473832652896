type getBodyForPlaidRequestParams = {
  updateBankAccountId?: string | null;
  pendingBankAccountId?: string | null;
};

type getBodyForPlaidRequestResponse = {
  bank_account_id?: string;
};

const getBodyForPlaidRequest = ({
  updateBankAccountId,
  pendingBankAccountId,
}: getBodyForPlaidRequestParams): getBodyForPlaidRequestResponse | null => {
  if (updateBankAccountId !== undefined && updateBankAccountId !== null) {
    return {
      bank_account_id: updateBankAccountId,
    };
  }
  if (pendingBankAccountId !== undefined && pendingBankAccountId !== null) {
    return {
      bank_account_id: pendingBankAccountId,
    };
  }
  return null;
};

export default getBodyForPlaidRequest;
