import ErrorMessage from '@atob-developers/shared/src/components/ErrorMessage';
import { faTruck, faTruckContainer, faTruckPickup } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { capitalize } from 'lodash-es';
import { ReactElement, useEffect } from 'react';

type fleetTypes = 'heavy' | 'mixed' | 'light';

const fleetTypeDescriptions = {
  heavy: 'Trucking fleet, diesel only',
  mixed: 'Fleet operating both light and heavy vehicles',
  light: 'Fleet operating local routes, unleaded and/or diesel',
};

const fleetTypeIcons = {
  heavy: faTruckContainer,
  mixed: faTruck,
  light: faTruckPickup,
};

export const FleetTypeForm = ({
  values,
  errors,
  setErrors,
  setValues,
  currentValue,
}: {
  values: Record<string, string | null>;
  setValues: (values: Record<string, string | null>) => void;
  errors: Record<string, string | undefined>;
  setErrors: (errors: Record<string, string | undefined>) => void;
  currentValue: string;
}): ReactElement => {
  useEffect(() => {
    if (values.fleet_type === undefined) {
      setValues({ ...values, fleet_type: currentValue });
    }
  }, [currentValue, setValues, values]);

  return (
    <>
      <div className="flex flex-col">
        <p className="mb-2 text-sm text-gray-500">
          Knowing the type of vehicles in your fleet helps us filter the best fuel discounts for
          you.
        </p>
        {Object.entries(fleetTypeDescriptions).map(([fleetType, description]) => (
          <button
            key={fleetType}
            onClick={(e) => {
              e.preventDefault();
              setErrors({ ...errors, fleet_type: undefined });
              setValues({ ...values, fleet_type: fleetType });
            }}
            className={classNames(
              'my-2 flex flex-col rounded-lg border-2 p-4 text-left text-black transition-colors',
              fleetType === values.fleet_type
                ? 'border-black hover:border-black'
                : 'border-gray-100 hover:border-gray-300',
            )}
          >
            <div className="flex items-center">
              <FontAwesomeIcon
                icon={fleetTypeIcons[fleetType as fleetTypes]}
                data-testid="vehicle-marker"
                className="text-xl text-black"
              />
              <h4 className="ml-4 font-semibold">{capitalize(fleetType)}</h4>
            </div>
            <p className="ml-[41px] text-sm text-gray-500">{description}</p>
          </button>
        ))}
        <ErrorMessage error={errors['fleet_type']} />
      </div>
    </>
  );
};
