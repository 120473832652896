import { ChargeEventData } from '@app/@types/charge_events.types';
import useThemeConfiguration from '@app/app/useThemeConfiguration';
import { formatDate } from '@app/utils/formatDate';
import { FormatCurrency } from '@atob-developers/shared/src/utils/formatters';
import { GridRowModel } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import { ReactElement } from 'react';
import { MobileListItem } from '../TableV2/MobileListItem';
import TransactionCardDeclineOrDiscount from './TransactionCardDeclineOrDiscount';

export default function TransactionsMobileCard({
  row,
}: {
  row: GridRowModel<ChargeEventData>;
}): ReactElement {
  const { showFuelPrograms } = useThemeConfiguration();

  return (
    <MobileListItem>
      <div className="flex justify-between">
        <div className="flex flex-col justify-between">
          <div>
            <div className="text-sm font-semibold">{row.merchant_name}</div>
            <div className="text-sm font-normal">
              {formatDate(dayjs(row.authorized_at).toDate())} •{' '}
              {dayjs(row.authorized_at).format('hh:mm A')}
            </div>
            <div className="text-sm font-normal">
              <span className="select-none">••••</span> <span>{row.card_last4}</span>
            </div>
            {showFuelPrograms && row.fuel_program_name && (
              <div className="text-sm font-normal">{row.fuel_program_name} Fuel Program</div>
            )}
          </div>
        </div>
        <div className="flex flex-col items-end">
          <div className="mb-1 font-semibold">
            {FormatCurrency({
              value:
                row.auth_approved && row.auth_status === 'closed'
                  ? row.net_amount_cents
                  : row.gross_amount_cents,
            })}
          </div>
        </div>
      </div>
      <TransactionCardDeclineOrDiscount row={row} className="mt-1" />
    </MobileListItem>
  );
}
