import { faCircleCheck, faClock, faWarning } from '@fortawesome/pro-regular-svg-icons';
import dayjs from 'dayjs';
import { ReactElement, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { StatusBanner } from '../Banner/Designs/StatusBanner';
import { AutoTopUpState } from '../Prepaid/Prepaid.types';
import { TopUpTransaction } from './RecentTopUps';
import { isTopUpWithinOneMinute } from './topUpUtils';

export const AutoTopUpDangerNotification = ({
  topUp,
  setAutoTopUpSidebarOpen,
}: {
  topUp: AutoTopUpState | TopUpTransaction;
  setAutoTopUpSidebarOpen: (open: boolean) => void;
}): ReactElement => {
  const errorMessage =
    topUp.error_message || `$${topUp.amount} was unable to be added to your account.`;

  return (
    <StatusBanner
      icon={faWarning}
      bannerKey="top_up_update_failed"
      nonDismissable
      badge="failed"
      actionText="Update details"
      onClick={() => {
        setAutoTopUpSidebarOpen(true);
      }}
    >
      <b className="font-semibold">Auto-Deposit failed: </b>
      {errorMessage}
    </StatusBanner>
  );
};

export const TopUpPendingNotification = ({
  topUp,
}: {
  topUp: TopUpTransaction;
  dismissTopUp: () => void;
}): ReactElement => {
  return (
    <StatusBanner icon={faClock} bannerKey={`top_up_pending_${topUp.id}`} badge="pending">
      <b className="font-semibold text-gray-700">
        {topUp.initiation_type === 'auto' ? 'Auto' : 'Manual'} Deposit:{' '}
      </b>
      {topUp.amount} is being processed and is expected to arrive in your Wallet on{' '}
      {topUp.expected_arrival_at && dayjs.unix(topUp.expected_arrival_at).format('MM/DD')}
    </StatusBanner>
  );
};

export const MANUAL_TOP_UP_NOTIFICATION = 'manual_topup_initiated';

export const TopUpImmediatelyPendingNotification = ({
  topUpsToShow,
}: {
  topUpsToShow: TopUpTransaction[];
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const shouldShow = searchParams.has(MANUAL_TOP_UP_NOTIFICATION);

  useEffect(() => {
    searchParams.delete(MANUAL_TOP_UP_NOTIFICATION);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const mostRecentTopUp = topUpsToShow[0] ?? null;

  // Do not show if the most recent topup was made less than a minute ago.
  const topupAlreadyInitiated = isTopUpWithinOneMinute(mostRecentTopUp);

  if (!shouldShow || topupAlreadyInitiated) {
    return null;
  }

  return (
    <StatusBanner
      icon={faClock}
      bannerKey={`top_up_pending_immediately_${mostRecentTopUp && mostRecentTopUp.id}`}
      badge="pending"
    >
      <b className="font-semibold text-gray-700">New deposit initiated: </b>
      Your Wallet deposit is being processed.
    </StatusBanner>
  );
};

export const TopUpSuccessNotification = ({
  topUp,
}: {
  topUp: TopUpTransaction;
  dismissTopUp: () => void;
}): ReactElement => {
  return (
    <StatusBanner
      icon={faCircleCheck}
      iconColor="green"
      bannerKey={`top_up_success${topUp.id}`}
      badge="successful"
    >
      <b className="font-semibold text-gray-700">
        {topUp.initiation_type === 'auto' ? 'Auto' : 'Manual'} Deposit:{' '}
      </b>
      {topUp.amount} arrived in your account on{' '}
      {topUp.posted_at && dayjs.unix(topUp.posted_at).format('MM/DD, h:mm A')}
    </StatusBanner>
  );
};
