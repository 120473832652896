import useFeatureFlags from '@app/hooks/useFeatureFlags';
import useProduct from '@app/hooks/useProduct';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReccuringNewFeatureBanner } from './Designs/ReccuringNewFeatureBanner';

const TAPetroBanner = (): ReactElement | null => {
  const navigate = useNavigate();
  const [showTABanner] = useFeatureFlags('ta_petro_marketing');
  const [perks] = useProduct('perks');

  return showTABanner && perks ? (
    <ReccuringNewFeatureBanner
      onClick={() => {
        navigate('/perks');
      }}
      actionText="Find a TA Petro Near Me"
      bannerKey="ta-petro-banner"
      variant="new_discount"
      icon={<img src="/images/ta_petro_logo.png" className="h-11 w-11"></img>}
    >
      <div>An average of 42¢ off per gallon at every TA Petro truck stop</div>
    </ReccuringNewFeatureBanner>
  ) : null;
};

export default TAPetroBanner;
