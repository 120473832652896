import { SettingsCurrentValue } from '@app/components/Cards/Settings/SettingsCurrentValue';
import { SettingsPageEntityType } from '@app/constants/settings';
import { SETTINGS_FORM_DATA, SETTINGS_ROW_OPTIONS } from '@app/constants/settingsRow';
import { InputFormElement } from '@atob-developers/shared/src/@types/form.types';
import FormElement from '@atob-developers/shared/src/components/FormElement';
import { ReactElement } from 'react';

export const EmailPhoneForm = ({
  type,
  values,
  errors,
  setErrors,
  setValues,
  currentValue,
}: {
  type:
    | SettingsPageEntityType.OTP_BACKUP_EMAIL
    | SettingsPageEntityType.PHONE_NUMBER
    | SettingsPageEntityType.EMAIL_ADDRESS
    | SettingsPageEntityType.CUSTOMER_COMPANY_PREFERRED_NAME;
  values: Record<string, string | null>;
  setValues: (values: Record<string, string | null>) => void;
  errors: Record<string, string | undefined>;
  setErrors: (errors: Record<string, string | undefined>) => void;
  currentValue: string;
}): ReactElement => {
  return (
    <>
      <div className="order-1 my-6  flex-none flex-grow-0 space-y-1.5 self-stretch rounded-lg bg-gray-100 p-5">
        <p className="flex items-center text-xs font-normal uppercase not-italic leading-6 text-gray-600">
          {`current ${SETTINGS_ROW_OPTIONS[type].title}`}
        </p>
        <div className="flex items-center text-base font-semibold not-italic leading-6 text-gray-900">
          <SettingsCurrentValue type={type} value={currentValue} />
        </div>
      </div>

      <div className="flex flex-col">
        {SETTINGS_FORM_DATA[type].map((item, idx) => (
          <FormElement
            key={`modal-element-${idx}`}
            element={{ ...item } as InputFormElement}
            value={values?.[item.key] || ''}
            handleOnChange={(val) => {
              setErrors({ ...errors, [item.key]: undefined });
              setValues({
                ...values,
                [item.key]: val,
              });
            }}
            error={errors[item.key]}
          />
        ))}
      </div>
    </>
  );
};
