import { useState } from 'react';

const useForceUpdate = (): [number, () => void] => {
  const [key, setKey] = useState(0);

  const forceUpdate = () => setKey((k) => k + 1);

  return [key, forceUpdate];
};

export default useForceUpdate;
