import classNames from 'classnames';

interface CheckboxProps {
  checked: boolean;
  label: string;
  name: string;
  setChecked: () => void;
  size?: 'sm' | 'md' | 'lg';
  htmlFor?: string;
  disabled?: boolean;
}

const Checkbox = ({
  name,
  label,
  setChecked,
  checked,
  htmlFor,
  disabled,
  size = 'md',
}: CheckboxProps) => {
  const CHECKBOX_BUTTON_SIZE = {
    sm: 'w-3 h-3',
    md: 'h-4 w-4',
    lg: 'w-7 h-7',
  };

  const CHECKBOX_BORDER_RADIUS = {
    sm: 'rounded-sm',
    md: 'rounded',
    lg: 'rounded-md',
  };

  return (
    <div
      className={classNames(
        'flex items-center',
        disabled ? 'cursor-not-allowed' : 'cursor-pointer',
      )}
      onClick={disabled ? undefined : setChecked}
    >
      <input
        name={name}
        id={htmlFor}
        type="checkbox"
        className={classNames(
          'text-green3 border-grey11 focus:ring-0 focus:ring-offset-0',
          CHECKBOX_BUTTON_SIZE[size || 'md'],
          CHECKBOX_BORDER_RADIUS[size || 'md'],
          disabled ? 'bg-grey5 cursor-not-allowed' : 'cursor-pointer',
        )}
        checked={checked}
        disabled={disabled}
      />
      <label
        htmlFor={htmlFor}
        className={classNames(
          'text-grey3 ml-2 text-sm font-normal',
          disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        )}
      >
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
