import { Plan, PlanResponse } from '@app/@types/subscriptions.types';
import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import { ATOB_SUPPORT_EMAIL } from '@app/constants/support';
import { useSingleUrlState } from '@app/hooks/useUrlState';
import { Button } from '@mui/material';
import axios from 'axios';
import currency from 'currency.js';
import { deserialize } from 'deserialize-json-api';
import { capitalize } from 'lodash-es';
import { ReactElement, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ConfirmPlan from './ConfirmPlan';
import PlanOption from './PlanOption';

const mapPlanDetails = (planResponse: PlanResponse[], currentType: string): Plan[] => {
  return planResponse.map((planItem: PlanResponse) => {
    const {
      product,
      plan,
      details: planDetails,
      payment_method_details,
    } = planItem.data.attributes;
    const hasBaseCost = planDetails.base_cost !== 0;
    const formattedBaseCost = currency(planDetails.base_cost, { fromCents: true }).format();
    const formattedUsageCost = currency(planDetails.usage_cost, { fromCents: true }).format();
    const amount = hasBaseCost ? formattedBaseCost : formattedUsageCost;

    const amountDetails = `${hasBaseCost ? formattedUsageCost : ''} / ${planDetails.usage_unit}`;

    return {
      current: currentType === plan,
      type: product,
      name: plan,
      amount: amount,
      amountCadence: 'per month',
      amountDetails: amountDetails,
      valueProps: planDetails?.value_props?.join(', '),
      trialPeriodDays: planDetails.trial_period_days,
      billingStartDate: planDetails.billing_start_date,
      firstPaymentDate: planDetails.first_payment_date,
      payment_method_details: payment_method_details,
    };
  });
};

export default function ChangePlan({ currentPlan }: { currentPlan: Plan }): ReactElement {
  const [plans, setPlans] = useState<Plan[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const confirming = location.pathname.includes('summary');
  const [planToConfirm, setPlanToConfirm] = useState<Plan | null>(null);
  const [selectedPlanName, setSelectedPlanName] = useSingleUrlState<string>(
    'selectedPlan',
    currentPlan.name,
  );
  const selectedPlan = plans.find((p) => p.name === selectedPlanName) || currentPlan;
  const [loading, setLoading] = useState(true);
  const isSelectingAnotherPlan = selectedPlan.name && !selectedPlan.current;
  const isUpgrading = isSelectingAnotherPlan && selectedPlan.name !== 'basic';
  const isDowngrading = isSelectingAnotherPlan && selectedPlan.name === 'basic';

  useEffect(() => {
    axios
      .get('/subscriptions/plans')
      .then((res) => {
        const { data } = deserialize(res.data);
        const plansData = data.filter(
          (datum: PlanResponse) => datum.data.attributes.product === currentPlan.type,
        );
        const plansToShow = mapPlanDetails(plansData, currentPlan.name);
        setPlans(plansToShow);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentPlan.name, currentPlan.type]);

  if (confirming && planToConfirm) {
    return (
      <ConfirmPlan
        plan={planToConfirm}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        paymentMethodDetails={`${capitalize(
          planToConfirm?.payment_method_details?.name,
        )} ••••${planToConfirm?.payment_method_details?.last4}`}
      />
    );
  }
  const allPlans = plans.map((plan) => ({ ...plan, selected: selectedPlan?.name === plan.name }));

  return (
    <div className="flex w-full flex-col items-center">
      <h1 className="mb-5 text-[32px] font-bold md:mb-12">Choose a Plan</h1>
      {loading && <SpinnerBoundary />}
      {allPlans.map((plan) => (
        <div key={plan.type + plan.name} className="mb-4 w-[90vw] sm:w-[370px] md:w-[500px]">
          <PlanOption plan={plan} onSelect={(plan) => setSelectedPlanName(plan.name)} />
        </div>
      ))}
      {isDowngrading && (
        <div className="font-medium">
          To downgrade, please{' '}
          <a className="text-atob-green underline" href={`mailto:${ATOB_SUPPORT_EMAIL}`}>
            contact support
          </a>
          .
        </div>
      )}
      {isUpgrading && (
        <div className="mt-5 md:mt-12">
          <Button
            size="medium"
            onClick={() => {
              setPlanToConfirm(selectedPlan);
              navigate(`/settings/plans/${selectedPlan.type}/summary`);
            }}
          >
            Continue
          </Button>
        </div>
      )}
    </div>
  );
}
