import useWindowWidth from '@app/hooks/useWindowWidth';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { ReactElement, useState } from 'react';
import AddDriverModal from './Modals/AddDriverModal';

export default function AddDriver({
  shouldHideOnMobile,
  showTextOnMobile,
}: {
  shouldHideOnMobile?: boolean;
  showTextOnMobile?: boolean;
}): ReactElement | null {
  const [driverModalActive, setDriverModalActive] = useState(false);
  const openDriverModal = () => setDriverModalActive(true);
  const { isMobile } = useWindowWidth();
  if (isMobile && shouldHideOnMobile) {
    return null;
  }

  const showText = !isMobile || showTextOnMobile;

  return (
    <div>
      <Button
        size="small"
        startIcon={<FontAwesomeIcon icon={faPlus as IconProp} size="1x" />}
        onClick={openDriverModal}
      >
        {showText && <span>Add Driver</span>}
      </Button>
      <AddDriverModal isActive={driverModalActive} setIsActive={setDriverModalActive} />
    </div>
  );
}
