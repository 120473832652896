import { createContext } from 'react';

export type CardSpendingLimitsContextType = {
  perTransactionLimit?: string;
  dailyTransactionLimit?: string;
  weeklyTransactionLimit?: string;
  setPerTransactionLimit: (perTransactionLimit: string) => void;
  setDailyTransactionLimit: (dailyTransactionLimit: string) => void;
  setWeeklyTransactionLimit: (weeklyTransactionLimit: string) => void;
};

const CardSpendingLimitsContext = createContext<CardSpendingLimitsContextType>({
  perTransactionLimit: undefined,
  dailyTransactionLimit: undefined,
  weeklyTransactionLimit: undefined,
  setPerTransactionLimit: () => {},
  setDailyTransactionLimit: () => {},
  setWeeklyTransactionLimit: () => {},
});

export default CardSpendingLimitsContext;
