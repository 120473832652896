import { matchPath, resolvePath, useLocation } from 'react-router-dom';

export default function useMatcher(...paths: string[]): boolean {
  const { pathname } = useLocation();

  return paths.some((to) => {
    const resolved = resolvePath(to);
    const elements = resolved.pathname.split('/');
    const pattern = `${elements[1]}${
      elements[2] ? `/${elements[2]}/*` : `${elements[1] ? '/*' : '/'}`
    }`;

    return !!matchPath(
      {
        path: pattern,
        end: true,
      },
      pathname,
    );
  });
}
