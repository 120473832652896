import { ProductSubscriptionResponse } from '@app/@types/subscriptions.types';
import { Loading } from '@app/components/layout';
import { ATOB_SUPPORT_EMAIL } from '@app/constants/support';
import usePlans from '@app/hooks/usePlans';
import useSubscriptions from '@app/hooks/useSubscriptions';
import { CurrencyFormat } from '@atob-developers/shared/src/utils/formatters';
import { convertCentsToDollars } from '@atob-developers/shared/src/utils/formatters/CurrencyFormat';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { capitalize } from 'lodash-es';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

export default function ComparePlans(): ReactElement {
  const navigate = useNavigate();
  const onNavigateBack = () => navigate('/premium');

  const valueProps = {
    'Discounts': [
      {
        feature: 'AtoB Discount Network via the AtoB App',
        basic: true,
        premium: true,
      },
    ],
    'Security Features': [
      {
        feature: 'Daily and weekly spend limits per card',
        basic: true,
        premium: true,
      },
      {
        feature: 'Payment authorization through Card Unlock',
        basic: false,
        premium: true,
      },
      {
        feature: 'Telematics-enabled Suspicious Activity Reports & Notifications',
        basic: false,
        premium: true,
      },
    ],
    'Card Controls': [
      {
        feature: 'Deactivate cards with one click',
        basic: true,
        premium: true,
      },
      {
        feature: 'Set operating hours for card use',
        basic: false,
        premium: true,
      },
      {
        feature: 'Manage allowed merchants categories & merchants',
        basic: false,
        premium: true,
      },
    ],
    'Admin Tools': [
      {
        feature: 'IFTA reporting',
        basic: true,
        premium: true,
      },
      {
        feature: 'Accounting reports with easy Quickbooks integration',
        basic: false,
        premium: true,
      },
      {
        feature: 'Shared dashboard access for multiple admins',
        basic: false,
        premium: true,
      },
    ],
    'Support': [
      {
        feature: '24/7 call & text support',
        basic: true,
        premium: true,
      },
      {
        feature: 'Priority Support (reduced wait times)',
        basic: false,
        premium: true,
      },
    ],
    'Other Products': [
      {
        feature: 'Instant Payroll',
        basic: true,
        premium: true,
      },
    ],
  };

  return (
    <div className="bg-ds-lp-base p-[20px] text-base text-white sm:p-[40px]">
      <a className="underline" onClick={onNavigateBack}>
        Back
      </a>
      <div className="relative mt-[100px] flex flex-col items-center text-center">
        <p className="z-base mb-[35px] text-5xl font-bold">Compare Plans</p>

        <div className="flex w-full gap-2 sm:px-[100px]">
          <div className="xs:mb-0 border-ds-cool-gray-600 mb-4 flex basis-3/5 flex-col flex-wrap justify-end border-b-[1px] pb-5 text-left">
            <span className="text-ds-cool-gray-400">Need help?</span>
            <a className="underline" href={`mailto:${ATOB_SUPPORT_EMAIL}`}>
              Contact support
            </a>
          </div>
          {<PricingSection />}
        </div>
        {Object.entries(valueProps).map(([category, values]) => {
          return <CategorySection key={category} category={category} values={values} />;
        })}
      </div>
    </div>
  );
}

function PricingSection(): ReactElement {
  const navigate = useNavigate();
  const plans = usePlans();
  const subscriptions = useSubscriptions();
  const onNavigateToPremium = () =>
    navigate('/settings/plans/atob_plus_premium?selectedPlan=monthly');

  if (plans.isLoading || subscriptions.isLoading) {
    return <Loading />;
  } else {
    return (
      <>
        {plans.data?.data.map((planResponse) => {
          const { attributes } = planResponse.data;
          const { minimum_subscription_amount } = attributes;

          let max_cards_for_minimum_amount;
          if (minimum_subscription_amount) {
            max_cards_for_minimum_amount =
              minimum_subscription_amount.cents / attributes.details.usage_cost;
          }

          const current = subscriptions.data?.data.some(
            (subscription: ProductSubscriptionResponse) =>
              planResponse.data.attributes.product == subscription.data.attributes.product &&
              planResponse.data.attributes.plan == subscription.data.attributes.plan,
          );

          return (
            <div
              key={planResponse.data.attributes.plan}
              className="xs:mb-0 border-ds-cool-gray-600 mb-4 flex basis-1/5 flex-col flex-wrap justify-between gap-6 border-b-[1px] pb-5 text-left"
            >
              <div>
                <p className="mb-4 flex-1 text-2xl font-bold">{capitalize(attributes.plan)}</p>
                <div className="flex flex-1 flex-col gap-2">
                  {minimum_subscription_amount && (
                    <>
                      <span className="text-3xl font-normal">
                        {CurrencyFormat({
                          value: convertCentsToDollars({
                            value: minimum_subscription_amount.cents,
                          }),
                          onlyWholeNumber: true,
                        })}
                      </span>
                      <div className="text-ds-cool-gray-400 text-xs">
                        {max_cards_for_minimum_amount == 1 && (
                          <p>
                            per {attributes.details.base_unit} for 1 {attributes.details.usage_unit}
                            , then{' '}
                            {CurrencyFormat({
                              value: convertCentsToDollars({
                                value: attributes.details.usage_cost,
                              }),
                              onlyWholeNumber: true,
                            })}{' '}
                            per month for each additional active card
                          </p>
                        )}
                        {max_cards_for_minimum_amount && max_cards_for_minimum_amount > 1 && (
                          <p>
                            per {attributes.details.base_unit} for 1-{max_cards_for_minimum_amount}{' '}
                            {attributes.details.usage_unit}s, then{' '}
                            {CurrencyFormat({
                              value: convertCentsToDollars({
                                value: attributes.details.usage_cost,
                              }),
                              onlyWholeNumber: true,
                            })}{' '}
                            per month for each additional active card
                          </p>
                        )}
                      </div>
                    </>
                  )}
                  {!minimum_subscription_amount && (
                    <>
                      <span className="text-3xl font-normal">
                        {CurrencyFormat({
                          value: convertCentsToDollars({ value: attributes.details.usage_cost }),
                          onlyWholeNumber: true,
                        })}
                      </span>
                      <div className="text-ds-cool-gray-400 text-xs">
                        <p>
                          per {attributes.details.base_unit} per active{' '}
                          {attributes.details.usage_unit}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {current && <p className="text-ds-cool-gray-400 mb-1 flex">Current plan</p>}
              {!current && (
                <div>
                  <button
                    className="bg-ds-bright-green text-dark-blue z-base flex cursor-pointer items-center justify-center rounded-full px-4 py-2 text-sm font-medium"
                    onClick={onNavigateToPremium}
                  >
                    Upgrade
                  </button>
                </div>
              )}
            </div>
          );
        })}{' '}
      </>
    );
  }
}

function CategorySection({
  category,
  values,
}: {
  category: string;
  values: { feature: string; basic: boolean; premium: boolean }[];
}): ReactElement {
  return (
    <div key={category} className="my-[30px] flex w-full flex-col sm:px-[100px]">
      <div className="text-left">{category}</div>
      {values.map(({ feature, basic, premium }, i) => {
        return (
          <div
            key={feature + String(basic) + String(premium)}
            className="text-ds-cool-gray-400 mt-[10px] flex"
          >
            <div
              className={classNames(
                'border-ds-cool-gray-600 flex basis-3/5 flex-col flex-wrap justify-end pb-2 pr-4 text-left sm:pr-0',
                {
                  'border-b-[1px]': i !== values.length - 1,
                },
              )}
            >
              {feature}
            </div>
            <div
              className={classNames(
                'border-ds-cool-gray-600 flex basis-1/5 flex-col flex-wrap justify-end pb-2 text-left',
                {
                  'border-b-[1px]': i !== values.length - 1,
                },
              )}
            >
              {basic && (
                <FontAwesomeIcon
                  icon={faCheck}
                  size="1x"
                  fill="white"
                  className="h-[20px] w-[20px]"
                />
              )}
            </div>
            <div
              className={classNames(
                'border-ds-cool-gray-600 flex basis-1/5 flex-col flex-wrap justify-end pb-2 text-left',
                {
                  'border-b-[1px]': i !== values.length - 1,
                },
              )}
            >
              {premium && (
                <FontAwesomeIcon
                  icon={faCheck}
                  size="1x"
                  fill="white"
                  className="h-[20px] w-[20px]"
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
