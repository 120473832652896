import useThemeConfiguration from '@app/app/useThemeConfiguration';
import useLocalStorageValues from '@app/hooks/useLocalStorageValues';
import { ReactElement } from 'react';
import {
  TopUpImmediatelyPendingNotification,
  TopUpPendingNotification,
  TopUpSuccessNotification,
} from './TopUpNotification';

export type TopUpTransaction = {
  amount: string;
  created_timestamp: number;
  description: string | null;
  destination: string | null;
  direction: string;
  error_message: string | null;
  expected_arrival_at: number | null;
  fee: string | null;
  id: string;
  initiation_type: 'auto' | 'manual';
  posted_at: number | null;
  receipt_url: string;
  source: string;
  status: string;
  type: string;
};

export default function RecentTopUps({ data }: { data: TopUpTransaction[] }): ReactElement {
  const [getLocalStorageValues, setLocalStorageValues] = useLocalStorageValues();
  const { hideDismissableBanners } = useThemeConfiguration();

  if (hideDismissableBanners) {
    return <></>;
  }

  const localStorageValues = getLocalStorageValues();
  const dismissedTopUps = (localStorageValues.dismissedTopUps || []) as string[];

  const dismissTopUp = (id: string) => {
    setLocalStorageValues({
      ...localStorageValues,
      dismissedTopUps: [...dismissedTopUps, id],
    });
  };

  const recentTopUps = data || [];
  const topUpsToShow = recentTopUps.filter((topUp) => !dismissedTopUps.includes(topUp.id));

  return (
    <>
      <TopUpImmediatelyPendingNotification topUpsToShow={topUpsToShow} />
      {topUpsToShow.map((topUp) => {
        if (topUp.error_message) {
          return null;
        }

        if (topUp.posted_at) {
          return (
            <TopUpSuccessNotification
              key={`top_up_success${topUp.id}`}
              topUp={topUp}
              dismissTopUp={() => dismissTopUp(topUp.id)}
            />
          );
        }

        if (topUp.expected_arrival_at) {
          return (
            <TopUpPendingNotification
              key={`top_up_pending${topUp.id}`}
              topUp={topUp}
              dismissTopUp={() => dismissTopUp(topUp.id)}
            />
          );
        }

        return null;
      })}
    </>
  );
}
