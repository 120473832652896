import { TruckType } from '@app/@types/telematics.types';
import { createContext } from 'react';

export type TelematicsMapContextType = {
  trucks: TruckType[];
  activeTruck: TruckType | null;
  setActiveTruck: (selectedTruck: TruckType | null) => void;
};

const TelematicsMapContext = createContext<TelematicsMapContextType>({
  trucks: [],
  activeTruck: null,
  setActiveTruck: () => {},
});

export default TelematicsMapContext;
