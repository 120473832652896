import { ClickAwayListener, Tooltip, TooltipProps, useMediaQuery, useTheme } from '@mui/material';
import classNames from 'classnames';
import { useState } from 'react';

export function MobileFriendlyTooltip(props: TooltipProps & { description?: string }) {
  const [open, setOpen] = useState(props.open || false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const theme = useTheme();
  const showClickable = useMediaQuery(theme.breakpoints.down('md'));

  return showClickable ? (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        {...props}
        onClick={handleTooltipClick}
        open={open}
        classes={{
          ...props.classes,
          tooltip: classNames('md:hidden', props.classes?.tooltip),
        }}
      >
        {props.children}
      </Tooltip>
    </ClickAwayListener>
  ) : (
    <Tooltip {...props}>{props.children}</Tooltip>
  );
}
