/* eslint-disable @cspell/spellchecker */

import { faGasPump } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';

const BORDER_IMAGE_RATIO = 0.75;

const logosMap: { [key: string]: string } = {
  '7-eleven': '7-eleven',
  '76': '76',
  'allsups': 'allsups',
  'alltown_markets': 'alltown_markets',
  'alltownmarkets': 'alltown_markets',
  'amoco': 'amoco',
  'ampm': 'ampm',
  'aplus': 'aplus',
  'arco': 'arco',
  'bp': 'bp',
  'caseys': 'caseys',
  "casey's": 'caseys',
  'cenex': 'cenex',
  'certified': 'certified',
  'chevron': 'chevron',
  'circlek': 'circlek',
  'citgo': 'citgo',
  'clark': 'clark',
  'conoco': 'conoco',
  'costco': 'costco',
  'cumberland': 'cumberland',
  'delta': 'delta',
  'dk': 'dk',
  'e-zmart': 'e-zmart',
  'extramile': 'extramile',
  'exxon': 'exxon',
  'exxonmobil': 'exxon',
  'flyingj': 'flyingj',
  'getgo': 'getgo',
  'global': 'global',
  'gulf': 'gulf',
  'h-e-b': 'h-e-b',
  'holiday': 'holiday',
  'jacksonsfoodstores': 'jacksonsfoodstores',
  'kroger': 'kroger',
  'kum&go': 'kum_and_go',
  'kumandgo': 'kum_and_go',
  'kwik_shop': 'kwik_shop',
  'kwik_star': 'kwik_star',
  'kwikfill': 'kwikfill',
  'kwikshop': 'kwik_shop',
  'kwikstar': 'kwik_star',
  'kwiktrip': 'kwiktrip',
  'liberty': 'liberty',
  'loaf_and_jug': 'loaf_and_jug',
  'loafandjug': 'loaf_and_jug',
  'loves': 'loves',
  "love's": 'loves',
  'mapco': 'mapco',
  'marathon': 'marathon',
  'maverik': 'maverik',
  'meijer': 'meijer',
  'minitmart': 'minit_mart',
  'mobil': 'mobil',
  'murphyexpress': 'murphyusa',
  'murphyusa': 'murphyusa',
  'onestop': 'one_stop',
  'phillips66': 'phillips66',
  'pilot': 'pilot',
  'quikstop': 'quik_stop',
  'quiktrip': 'quiktrip',
  'racetrac': 'racetrac',
  'raceway': 'raceway',
  'rocket': 'rocket',
  'royalfarms': 'royalfarms',
  'rutters': 'rutters',
  "rutter's": 'rutters',
  'safeway': 'safeway',
  'samsclub': 'samsclub',
  'sheetz': 'sheetz',
  'shell': 'shell',
  'sinclair': 'sinclair',
  'speedway': 'speedway',
  'sprintfoods': 'sprintfoods',
  'stewarts': 'stewarts',
  'stripes': 'stripes',
  'sunoco': 'sunoco',
  'ta': 'ta',
  'terribleherbst': 'terribleherbst',
  'texaco': 'texaco',
  'thorntons': 'thorntons',
  'timewisefoodstore': 'timewisefoodstore',
  'tomthumb': 'tom_thumb',
  'travelcentersofamerica': 'ta',
  'truenorth': 'truenorth',
  'turkeyhill': 'turkey_hill',
  'valero': 'valero',
  'walmart': 'walmart',
  'wawa': 'wawa',
  'yesway': 'yesway',
};

interface Props {
  brand?: string;
  rounded?: boolean;
  border?: boolean;
  logoURL: string | null;
  size:
    | number
    | {
        width: number;
        height: number;
      };
}

function simplifiedBrandName(brand?: string): string {
  return brand?.toLowerCase().split(' ').join('') ?? '';
}

export function isRecognizedBrandName(brand?: string): boolean {
  return simplifiedBrandName(brand) in logosMap;
}

export function BrandLogo({ brand, size, rounded, border, logoURL }: Props) {
  const logoImage = useMemo(() => {
    if (logoURL && logoURL.length > 0) {
      return logoURL;
    }
    const simplified = simplifiedBrandName(brand);
    if (simplified in logosMap) {
      return `assets/logos/${logosMap[simplified]}.png`;
    }

    return null;
  }, [brand, logoURL]);

  const wrapperStyle = useMemo(
    () => (typeof size === 'number' ? { width: size, height: size } : size),
    [size],
  );

  const imageStyle = useMemo(
    () =>
      rounded === true
        ? {
            width: wrapperStyle.width * BORDER_IMAGE_RATIO,
            height: wrapperStyle.height * BORDER_IMAGE_RATIO,
          }
        : wrapperStyle,
    [rounded, wrapperStyle],
  );

  return (
    <div
      className={`min-w-6 flex items-center justify-center ${
        rounded && 'rounded-full object-fill'
      } ${border && 'border-level-2 border bg-white'}`}
      style={{ width: wrapperStyle.width, height: wrapperStyle.height }}
    >
      {logoImage ? (
        <img
          src={logoImage}
          width={imageStyle.width}
          height={imageStyle.height}
          alt={`${brand}'s logo`}
          style={{
            maxHeight: imageStyle.height - (border ? 3 : 0),
            maxWidth: imageStyle.width - (border ? 3 : 0),
          }}
        />
      ) : (
        <div className={`flex flex-row justify-center`}>
          <FontAwesomeIcon
            style={{
              width: imageStyle.width,
              height: imageStyle.height,
              maxHeight: imageStyle.height - (border ? 6 : 0),
              maxWidth: imageStyle.width - (border ? 6 : 0),
            }}
            icon={faGasPump}
          />
        </div>
      )}
    </div>
  );
}
