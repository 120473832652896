import { InAppSelectedCardContext } from '@app/contexts/InAppSelectedCardContext';
import useIsNativeApp from '@app/hooks/useIsNativeApp';
import { Button } from '@mui/material';
import { ReactElement, useContext } from 'react';
import MobileNavHeader from './MobileNavHeader';

export default function MobileNavHomepageHeader(): ReactElement {
  const nativeAppInfo = useIsNativeApp();
  const selectedCardState = useContext(InAppSelectedCardContext);

  let button = null;
  if (nativeAppInfo.isNativeApp && selectedCardState.hasSelectedCard === false) {
    button = (
      <Button
        variant="secondary"
        sx={{ color: '#2563EB' }}
        onClick={() => {
          nativeAppInfo.postMessage('select-card');
        }}
      >
        Unlock Card
      </Button>
    );
  }
  if (nativeAppInfo.isNativeApp && selectedCardState.hasSelectedCard) {
    button = (
      <Button
        variant="secondary"
        sx={{ color: '#2563EB' }}
        onClick={() => {
          nativeAppInfo.postMessage('unlock-card');
        }}
      >
        Unlock Card
      </Button>
    );
  }
  return (
    <MobileNavHeader title={nativeAppInfo.isNativeApp ? 'Program' : 'Home'} unlockButton={button} />
  );
}
