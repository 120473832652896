import {
  NotificationTypeNamesList,
  NotificationTypesList,
} from '@app/@types/notification_setting.types';

export const NotificationsList = [
  {
    id: NotificationTypesList[0],
    shortName: NotificationTypeNamesList[0],
    name: 'Card Declines',
    description: 'Get notified whenever a card is declined',
    channels: ['Email', 'SMS'],
    globalOnly: false,
    allowedRoles: ['owner', 'card_admin', 'card_manager', 'notifications_only'],
    product: 'notification_settings',
  },
  {
    id: NotificationTypesList[1],
    shortName: NotificationTypeNamesList[1],
    name: 'Suspicious Activity Alerts',
    description:
      "Receive an alert whenever AtoB detects a transaction is attempted but your fleet's vehicle is not nearby.",
    channels: ['SMS'],
    allowedRoles: ['owner', 'card_admin', 'card_manager', 'notifications_only'],
    globalOnly: false,
    product: 'notification_settings',
  },
  {
    id: NotificationTypesList[3],
    shortName: NotificationTypeNamesList[3],
    name: 'Suspicious Activity Daily Summary',
    description:
      'Receive a summary of transactions where suspicious activity is detected (vehicle not nearby, excess gallons purchased, non-fuel purchased)',
    channels: ['Email'],
    allowedRoles: ['owner', 'card_admin', 'card_manager', 'notifications_only'],
    globalOnly: false,
    product: 'notification_settings',
  },
  {
    id: NotificationTypesList[2],
    shortName: NotificationTypeNamesList[2],
    name: 'Billing Statements',
    description: 'Get notified whenever a new statement is available',
    channels: ['Email'],
    allowedRoles: ['owner', 'bookkeeper', 'notifications_only'],
    globalOnly: true,
    product: 'bookkeeper_notification_settings',
  },
];

export const RoleNotificationsList = [
  {
    customer_role: 'driver',
    id: NotificationTypesList[0],
    name: 'Decline Notifications',
    notificationTypes: NotificationTypesList,
    product: 'driver_notification_settings',
    description: 'Send drivers SMS notifications for card declines',
  },
  {
    customer_role: 'driver',
    id: NotificationTypesList[1],
    name: 'Suspicious Activity Notifications',
    notificationTypes: NotificationTypesList,
    product: 'driver_notification_settings',
    description: 'Send drivers SMS notifications for suspicious activities',
  },
];
