import { CustomerData } from '@app/@types/customer.types';
import { PrimaryPaymentMethod } from '@app/components/PaymentModal/@types/paymentMethod.types';
import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import logger from '@app/utils/datadog-logger';
import { faXmarkCircle } from '@fortawesome/pro-regular-svg-icons';
import { ReactElement } from 'react';
import PaymentStatusContent from '../PaymentErrors/PaymentStatusContent';
import { AmountDueProps } from '../PaymentModalWithEstimatesController/amountDueConverter';
import PaymentModalWithEstimatesContainer from './PaymentModalWithEstimatesContainer';

interface PaymentModalWithEstimatesWrapperProps {
  closeModal: () => void;
  isLoading: boolean;
  hasError: boolean;
  customer: CustomerData;
  upcomingPayment: AmountDueProps['upcomingPayment'];
  walletAvailable: boolean;
  primaryPaymentMethod?: PrimaryPaymentMethod;
}

const PaymentModalWithEstimatesWrapper = ({
  closeModal,
  isLoading,
  hasError,
  customer,
  upcomingPayment,
  walletAvailable,
  primaryPaymentMethod,
}: PaymentModalWithEstimatesWrapperProps): ReactElement => {
  if (isLoading) {
    logger.info('Initiate payment modal load');
    return (
      <div className="w-full">
        <SpinnerBoundary />
      </div>
    );
  }

  if (hasError || !primaryPaymentMethod) {
    logger.error('Loading payment modal failed');
    return (
      <PaymentStatusContent
        title="Error loading your payment methods"
        icon={faXmarkCircle}
        message="There was an error loading your payment methods. Please try again or, if the issue persists, contact support."
      />
    );
  }

  const { payment_method_detail: primaryPaymentMethodDetail } =
    primaryPaymentMethod.data.relationships;

  return (
    <PaymentModalWithEstimatesContainer
      closeModal={closeModal}
      defaultPaymentSelectionType={primaryPaymentMethodDetail.data.type}
      customer={customer}
      upcomingPayment={upcomingPayment}
      walletAvailable={walletAvailable}
    />
  );
};

export default PaymentModalWithEstimatesWrapper;
