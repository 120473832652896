import { DateRange } from '@mui/lab';
import { PickersShortcutsItem } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { ReactElement } from 'react';

dayjs.extend(quarterOfYear);

export const shortcutsItems: PickersShortcutsItem<DateRange<dayjs.Dayjs>>[] = [
  {
    label: `Q${dayjs().quarter()} ${dayjs().year()}`,
    getValue: () => {
      const today = dayjs();
      return [today.startOf('quarter'), today.endOf('quarter')];
    },
  },
  {
    label: `Q${dayjs().subtract(3, 'months').quarter()} ${dayjs().subtract(3, 'months').year()}`,
    getValue: () => {
      const today = dayjs();
      return [
        today.subtract(3, 'months').startOf('quarter'),
        today.subtract(3, 'months').endOf('quarter'),
      ];
    },
  },
  {
    label: 'Last 7 Days',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, 'day'), today];
    },
  },
  {
    label: 'Last 30 Days',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(30, 'day'), today];
    },
  },
  {
    label: 'Today',
    getValue: () => {
      const today = dayjs();
      return [today, today];
    },
  },
];

export const ExportTransactionsDatePicker = ({
  value,
  onChange,
}: {
  value: DateRange<dayjs.Dayjs>;
  onChange: (value: DateRange<dayjs.Dayjs>) => void;
}): ReactElement => (
  <DateRangePicker
    slotProps={{
      actionBar: {
        actions: [],
      },
      shortcuts: {
        items: shortcutsItems,
      },
    }}
    value={value}
    onChange={(newValue) => {
      // We need to set the start and end of the day to make sure we get all the transactions
      const [start, end] = newValue;
      onChange([start?.startOf('day') || null, end?.endOf('day') || null]);
    }}
    disableFuture={true}
  />
);
