import { Loading } from '@app/components/layout';
import Constants from '@app/utils/constants';
import * as Sentry from '@sentry/react';
import axios, { AxiosError } from 'axios';
import React, { ReactElement, useEffect } from 'react';

const getParams = () => {
  return new URLSearchParams(window.location.search);
};

const SettingsPagePatchCallback: React.FC = (): ReactElement => {
  const params = getParams();
  useEffect(() => {
    axios
      .patch('/customer/change_request_finalize', {
        change_request: {
          token: params.get('token'),
        },
      })
      .then(() => {
        window.location.href = `/?status=${Constants.SettingsPageEnums.SUCCESS}`;
        Sentry.captureMessage('ChangeRequest finalized successfully', {
          user: {
            params,
          },
        });
      })
      .catch((e: AxiosError) => {
        Sentry.captureException(e, {
          user: {
            request_id: e?.response?.headers?.['x-request-id'],
            params,
          },
        });
        window.location.href = `/?status=${Constants.SettingsPageEnums.FAIL}`;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loading />;
};

export default SettingsPagePatchCallback;
