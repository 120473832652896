import AtobAppIcon from '@app/assets/images/svg/card_unlock_modal_atob.svg';
import SmsIcon from '@app/assets/images/svg/card_unlock_modal_sms.svg';
import Ellipsis from '@app/components/elements/Ellipsis';
import { useIsUberfreight } from '@app/hooks/partners/useIsUberfreight';
import Divider from '@atob-developers/shared/src/components/Divider';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { ReactElement } from 'react';

const CardUnlockSecurityModalBody = ({
  cardSecurityEnabled,
  loading,
  confirm,
  reset,
}: {
  cardSecurityEnabled: boolean;
  loading: boolean;
  confirm: () => void;
  reset: () => void;
}): ReactElement => {
  const shouldMarketDriverApp = !useIsUberfreight();

  return (
    <div className="flex-col px-3 text-gray-900">
      <div className="py-4">
        <div className="pb-4">
          <div>
            By enabling, only verified drivers will be able to use this card, so{' '}
            <b>make sure they're added on the</b>{' '}
            <a className="font-bold underline" href="/drivers">
              Drivers
            </a>{' '}
            <b>page</b>.
          </div>
        </div>
        {shouldMarketDriverApp && (
          <>
            <div>
              <b>
                Before using the card, drivers must verify their identity in one of two ways to
                unlock this card:
              </b>
            </div>
            <div className="my-4 flex text-sm">
              <div className="mr-4 flex h-[50px] w-[50px] min-w-[50px] items-center">
                <img alt="AtoB App" src={AtobAppIcon} />
              </div>
              <div className="mt-1 flex-col">
                <b>Unlock via AtoB app</b>
                <p className="font-medium text-gray-500">One-tap unlock (Easiest)</p>
              </div>
            </div>
            <Divider />
          </>
        )}
        <div className="my-4 flex text-sm">
          <div className="mr-4 flex h-[50px] w-[50px] min-w-[50px] items-center">
            <img alt="SMS" src={SmsIcon} />
          </div>
          <div className="mt-1 flex-col">
            <b>Unlock via SMS</b>
            <p className=" font-medium text-gray-500">
              Text the last 4 digits of the card to 650-360-0027.
            </p>
          </div>
        </div>
        {shouldMarketDriverApp && (
          <div className="flex font-medium text-gray-700">
            <a
              href="https://atobco.zendesk.com/hc/en-us/articles/5197645573019-How-to-use-Card-unlock-for-card-security"
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
            >
              Learn more in Help Center
            </a>
          </div>
        )}
      </div>
      <div className="mb-4 mt-4 flex w-full justify-end gap-2">
        <div
          className="w-1/4 items-center py-3 text-center align-middle text-base font-medium text-gray-800"
          onClick={reset}
        >
          {'Cancel'}
        </div>
        {!cardSecurityEnabled && (
          <div className="w-1/4 justify-around">
            <Button className="h-full w-full" size="small" disabled={loading} onClick={confirm}>
              {loading ? <Ellipsis /> : 'Enable'}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

const CardUnlockSecurityModal = ({
  cardSecurityEnabled,
  open,
  loading,
  confirm,
  reset,
}: {
  cardSecurityEnabled: boolean;
  open: boolean;
  loading: boolean;
  confirm: () => void;
  reset: () => void;
}): ReactElement => {
  return (
    <Dialog className="p-3 " open={open} onClose={reset}>
      <DialogTitle className="p-3">
        <div className="px-3 pt-4 text-xl font-bold md:text-2xl">Card Unlock Security</div>
      </DialogTitle>
      <DialogContent>
        <CardUnlockSecurityModalBody
          cardSecurityEnabled={cardSecurityEnabled}
          loading={loading}
          confirm={confirm}
          reset={reset}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CardUnlockSecurityModal;
