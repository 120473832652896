import { ErrorNotification } from '@app/components/layout';
import Skeleton from '@app/components/layout/Skeleton';
import { PageContentWrapper } from '@app/components/wrappers/PageContentWrapper';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios, { AxiosError } from 'axios';
import { deserialize } from 'deserialize-json-api';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ManageRecipients from './ManageRecipients';
import { addRecipient } from './WalletOverview/TransferFunds/WalletPaymentMethods';
import {
  RecipientToCreate,
  RecipientData,
  RecipientToEdit,
} from './WalletOverview/TransferFunds/transfer.types';

export default function Overview(): ReactElement {
  const [recipients, setRecipients] = useState<RecipientData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchRecipients = useCallback(async () => {
    const recipientsResponse = await axios.get('/treasury/recipients', {});
    const { data: recipientsData } = deserialize(recipientsResponse.data);
    setRecipients(recipientsData);
  }, []);

  const addRecipientAccount = async (recipient: RecipientToCreate) => {
    const recipientData = await addRecipient(recipient);
    setRecipients([...recipients, recipientData]);
  };

  const editRecipient = async (recipient: RecipientToEdit) => {
    await axios.patch(`/treasury/recipients/${recipient.id}`, { ...recipient });

    await fetchRecipients();
  };

  const removeRecipient = async (recipient: RecipientData) => {
    await axios.delete(`/treasury/recipients/${recipient.id}`);
    await fetchRecipients();
  };

  useEffect(() => {
    async function fetchTreasuryDetails() {
      setError(null);
      try {
        await Promise.all([fetchRecipients()]);
      } catch (e) {
        setError((e as AxiosError).message);
      }

      setLoading(false);
    }

    fetchTreasuryDetails();
  }, [fetchRecipients]);

  if (loading) {
    return (
      <PageContentWrapper>
        <Skeleton />
      </PageContentWrapper>
    );
  }

  return (
    <>
      <Link to="/wallet/overview">
        <div className="text-md cursor-pointer p-5 font-[400] text-gray-700">
          <FontAwesomeIcon icon={faChevronLeft} /> Back
        </div>
      </Link>
      <PageContentWrapper>
        {error && <ErrorNotification error={error} />}
        <ManageRecipients
          recipients={recipients}
          addRecipient={addRecipientAccount}
          editRecipient={editRecipient}
          removeRecipient={removeRecipient}
        />
      </PageContentWrapper>
    </>
  );
}
