import { ELDProvider } from '@app/@types/eld-connect.types';
import {
  useConnectTelematicsOAuth,
  useTelematicsProvider,
} from '@app/hooks/query/useConnectTelematics';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { ReactElement } from 'react';
import ConnectionModalFooter from './ConnectionModalFooter';

interface TelematicsConnectELDModalProps {
  provider: ELDProvider;
  closeModal: () => void;
}

const TelematicsConnectOauthModal = ({
  provider,
  closeModal,
}: TelematicsConnectELDModalProps): ReactElement => {
  const { mutate } = useTelematicsProvider();
  const { trigger, isMutating } = useConnectTelematicsOAuth(provider.id, mutate);

  return (
    <Dialog open={true} onClose={closeModal}>
      <DialogTitle>{`Connect to ${provider.displayName}`}</DialogTitle>
      <DialogContent>You'll be asked for more details to connect your account.</DialogContent>
      <ConnectionModalFooter
        clickHandler={trigger}
        isLoading={isMutating}
        closeModal={closeModal}
      />
    </Dialog>
  );
};

export default TelematicsConnectOauthModal;
