import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import cx from 'classnames';
import { ReactElement, PropsWithChildren, ReactNode } from 'react';
interface ErrorBannersProps {
  icon?: IconProp;
  onClick?: () => void;
  actionText?: string;
  customActionButton?: ReactNode;
  rounded?: boolean;
}

export const ErrorBanner = ({
  icon = faExclamationCircle,
  onClick,
  actionText,
  customActionButton,
  rounded = false,
  children,
}: PropsWithChildren<ErrorBannersProps>): ReactElement => {
  return (
    <div
      className={cx(
        'z-top-level-banner bg-red4 top-0 w-full items-center justify-items-start px-6 py-5 text-sm font-normal',
        { 'rounded-md': rounded },
      )}
      data-testid="error-banner"
    >
      <div className="flex flex-row justify-start gap-6 md:w-full md:items-center">
        <div className="flex">
          <FontAwesomeIcon icon={icon} className="h-6 w-6 text-white" />
        </div>
        <div className="flex flex-col justify-between sm:flex-row md:w-full md:items-center">
          <div className=" text-left text-sm text-white">{children}</div>
          {actionText && onClick && (
            <Button size="small" onClick={onClick}>
              {actionText}
            </Button>
          )}
          {customActionButton && <div className="mt-2 md:mt-0">{customActionButton}</div>}
        </div>
      </div>
    </div>
  );
};
