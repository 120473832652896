import { BankAccountWithEstimateData } from '@app/@types/payments.types';
import PaymentStatusContent from '@app/components/PaymentModal/PaymentErrors/PaymentStatusContent';
import logger from '@app/utils/datadog-logger';
import { faXmarkCircle, faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { ReactElement, useState } from 'react';
import BankTransferEditPaymentDetails from './BankTransferEditPaymentDetails';
import BankTransferReviewPaymentDetails from './BankTransferReviewPaymentDetails';
import { BankTransferWrapperProps } from './BankTransferWrapper';

export interface BankTransferControllerProps {
  bankAccountsWithEstimates: BankAccountWithEstimateData[];
}

const getDefaultBankAccount = (
  bankAccountsWithEstimates: BankAccountWithEstimateData[],
): BankAccountWithEstimateData | undefined => {
  return (
    bankAccountsWithEstimates.find(
      (
        bankAccountsWithEstimate: BankAccountWithEstimateData,
      ): BankAccountWithEstimateData | undefined => {
        if (bankAccountsWithEstimate.bank_account.data.attributes.is_primary) {
          return bankAccountsWithEstimate;
        }
        return undefined;
      },
    ) || bankAccountsWithEstimates[0]
  );
};

const BankTransferController = ({
  bankAccountsWithEstimates,
  closeModal,
  setShowPaymentSelection,
  paymentAmount,
  setPaymentAmount,
  showAmountDue,
  setShowAmountDue,
}: BankTransferControllerProps & BankTransferWrapperProps): ReactElement | null => {
  const defaultBankAccount = getDefaultBankAccount(bankAccountsWithEstimates);
  const [selectedBankAccount, setSelectedBankAccount] = useState<
    BankAccountWithEstimateData | undefined
  >(defaultBankAccount);
  const [paymentScreen, setPaymentScreen] = useState<'edit' | 'review' | 'success' | 'error'>(
    'edit',
  );
  const [paymentScreenMessage, setPaymentScreenMessage] = useState<string | null>(null);

  const onBack = () => {
    setShowPaymentSelection(true);
    setPaymentScreen('edit');
  };

  const onNext = () => {
    setShowPaymentSelection(false);
    setPaymentScreen('review');
  };

  const setPaymentStatus = (paymentStatus: 'success' | 'error', message?: string) => {
    setPaymentScreen(paymentStatus);
    message && setPaymentScreenMessage(message);
  };

  switch (paymentScreen) {
    case 'edit':
      logger.info('Loading Edit Bank Transfer payment modal', {
        paymentAmount,
        selectedBankAccount,
      });
      return (
        <BankTransferEditPaymentDetails
          selectedBankAccount={selectedBankAccount}
          closeModal={closeModal}
          onNext={onNext}
          paymentAmount={paymentAmount}
          setPaymentAmount={setPaymentAmount}
          setSelectedBankAccount={setSelectedBankAccount}
          bankAccountsWithEstimates={bankAccountsWithEstimates}
          showAmountDue={showAmountDue}
          setShowAmountDue={setShowAmountDue}
        />
      );
    case 'review':
      logger.info('Loading Review Bank Transfer payment modal', {
        paymentAmount,
        selectedBankAccount,
      });
      return (
        <BankTransferReviewPaymentDetails
          selectedBankAccount={selectedBankAccount}
          paymentAmount={paymentAmount}
          onBack={onBack}
          setPaymentStatus={setPaymentStatus}
        />
      );
    case 'error':
      return (
        <PaymentStatusContent
          title="Error processing your ACH Payment"
          icon={faXmarkCircle}
          iconClassName="text-red4"
          message={
            paymentScreenMessage ||
            'There was an error processing your payment. Please contact support.'
          }
        />
      );
    case 'success':
      return (
        <PaymentStatusContent
          title="Success"
          icon={faCheckCircle}
          iconClassName="text-atob-green"
          message="We've received your payment request. Please allow 1-2 minutes for this payment to reflect on your account and show on your Payment History page."
          showButton
        />
      );
  }
};

export default BankTransferController;
