import { PaginatedEndpointResponse } from '@app/@types/api.types';
import { VehicleData } from '@app/@types/vehicle.types';
import AddVehicleModal from '@app/components/Modals/VehicleModals/AddVehicleModal';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import { ErrorNotification, Loading } from '@app/components/layout';
import { DEFAULT_PAGE_SIZE, SortBy } from '@app/hooks/paging/types';
import VehiclesList from '@app/pages/Vehicles/VehiclesList';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, InputAdornment, TextField } from '@mui/material';
import { GridSortModel } from '@mui/x-data-grid-pro';
import { ReactElement, useState } from 'react';
import useSWR from 'swr';

export const VehiclesTab = (): ReactElement => {
  const [page, setPage] = useState(1);
  const [inputValue, setInputValue] = useState('');
  const [sort, setSort] = useState<SortBy | null>(null);
  const { data, error, isLoading, mutate } = useSWR<PaginatedEndpointResponse<VehicleData>>(
    {
      url: '/vehicles',
      params: {
        page: page,
        per: DEFAULT_PAGE_SIZE,
        all: false,
        sort: sort ? [`${sort.field}:${sort.order}`] : undefined,
        like: inputValue.length > 0 ? inputValue : null,
        archived_at: 'none',
      },
    },
    apiGetFetcher,
  );

  const [showVehicleModal, setShowVehicleModal] = useState<boolean>(false);
  const toggleVehicleModal = () => setShowVehicleModal(!showVehicleModal);

  if (error) {
    return (
      <ErrorNotification error="We're having issues loading your vehicle data. Please try again or if the issue persists, contact support." />
    );
  } else if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <AddVehicleModal open={showVehicleModal} toggle={toggleVehicleModal} refreshData={mutate} />
      <RoundedCard breakpoint="sm">
        <div className="my-4 flex items-center justify-between sm:mx-6">
          <Button color="primary" onClick={toggleVehicleModal}>
            Add New Vehicle
          </Button>
          <TextField
            variant="outlined"
            onChange={(e) => setInputValue(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faMagnifyingGlass}></FontAwesomeIcon>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <VehiclesList
          rows={data?.data ?? []}
          paginationMeta={data?.meta}
          refreshData={mutate}
          loading={isLoading}
          onPageIndexChange={setPage}
          onSortModelChange={(model: GridSortModel) => {
            const sortByModel = model && model[0];
            if (!sortByModel) {
              setSort(null);
              return;
            }
            const { field, sort: order } = sortByModel;
            setSort({ field, order });
          }}
        />
      </RoundedCard>
    </>
  );
};
