import { ReactElement, ReactNode } from 'react';

export default function OnboardingLayout({
  header,
  leftContent,
  rightContent,
}: {
  header: ReactNode;
  leftContent: ReactNode;
  rightContent: ReactNode;
}): ReactElement {
  return (
    <div className="flex h-screen flex-col overflow-auto bg-white">
      {header}
      <div className="flex grow flex-row">
        {leftContent}
        {rightContent}
      </div>
    </div>
  );
}
