import { ReactElement } from 'react';
import ConnectedCheckmark from './ConnectedCheckmark';
import Ellipsis from './Ellipsis';

export default function Status({
  isConnected,
  isLoading,
}: {
  isConnected: boolean;
  isLoading: boolean;
}): ReactElement {
  if (isLoading) {
    return (
      <>
        Status:{' '}
        <b>
          Checking
          <Ellipsis />
        </b>
      </>
    );
  }

  return (
    <>
      Status: <b>{isConnected ? 'Connected' : 'Not connected'}</b>
      <ConnectedCheckmark isConnected={isConnected} />
    </>
  );
}
