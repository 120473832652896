import type { PaginationMeta } from '@app/@types/api.types';

export type PageInfo<T> = {
  getPage: (page: number) => T[];
  getItemStart: (page: number) => number;
  getItemEnd: (page: number) => number;
  getTotalPages: () => number;
  getTotalItems: () => number;
  getPaginationInfo?: () => PaginationMeta;
};

export function virtualizedPage<T>(data: T[], rowsPerPage: number): PageInfo<T> {
  const getPage = (page: number) => {
    return data.slice((page - 1) * rowsPerPage, page * rowsPerPage);
  };
  const getTotalPages = () => {
    return Math.ceil(data.length / rowsPerPage);
  };
  const getTotalItems = () => {
    return data.length;
  };
  const getItemStart = (page: number) => {
    return (page - 1) * rowsPerPage + 1;
  };
  const getItemEnd = (page: number) => {
    return Math.min(page * rowsPerPage, data.length);
  };
  return {
    getPage,
    getItemStart,
    getItemEnd,
    getTotalPages,
    getTotalItems,
  };
}

export function backendPage<T>(
  data: T[],
  rowsPerPage: number,
  paginationMeta: PaginationMeta,
): PageInfo<T> {
  const getPage = (_page: number) => {
    return data;
  };
  const getTotalPages = () => {
    return paginationMeta.pagination.total_pages;
  };
  const getTotalItems = () => {
    return paginationMeta.pagination.total_count;
  };
  const getItemStart = (page: number) => {
    return rowsPerPage * (page - 1) + 1;
  };
  const getItemEnd = (page: number) => {
    return getItemStart(page) - 1 + data.length;
  };
  const getPaginationInfo = () => {
    return paginationMeta;
  };
  return {
    getPage,
    getItemStart,
    getItemEnd,
    getTotalPages,
    getTotalItems,
    getPaginationInfo,
  };
}
