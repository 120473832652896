import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';

export default function DebitSaveSuccessful(): ReactElement {
  return (
    <div className="flex flex-col items-center p-6">
      <div className="mb-3 text-green-600">
        <FontAwesomeIcon className="fa-4x" icon={faCheckCircle} />
      </div>
      <h1 className="mb-1 text-xl font-bold">Debit Card Successfully Saved</h1>
      <p className="text-md">Your card is now saved as one of your payment methods.</p>
    </div>
  );
}
