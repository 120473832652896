import Header from '@app/components/Navigation/Header';
import PageContentWrapper from '@app/components/wrappers/PageContentWrapper';
import { ReactElement } from 'react';
import { useEmbeddedDashboardUrl } from './useEmbeddedDashboardUrl';

const ReportingPageHeader = (): ReactElement => {
  return <Header title="Reporting" />;
};

export const ReportingPage = (): ReactElement => {
  const { data, isLoading } = useEmbeddedDashboardUrl('558');

  return (
    <PageContentWrapper header={<ReportingPageHeader />}>
      {isLoading && <div>Loading...</div>}
      {data && <iframe src={data.url} width="100%" height="2000px"></iframe>}
    </PageContentWrapper>
  );
};
