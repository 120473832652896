import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import { ProductSubscription } from '@app/pages/Settings/Plans/PlansOverview';
import { Button } from '@mui/material';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

const getTitle = (type: string): string => {
  if (type === 'atob_plus_premium') {
    return 'Fuel Card';
  }
  if (type === 'payroll') {
    return 'Payroll';
  }

  return '';
};

const getPlan = (plan: ProductSubscription['plan']): string => {
  if (plan === 'basic') {
    return 'Basic Plan';
  }
  if (plan === 'monthly') {
    return 'Monthly Plan';
  }
  if (plan === 'premium') {
    return 'Premium Plan';
  }

  return '';
};

const Detail = ({ type, value }: { type: string; value: string | number }): ReactElement => (
  <div className="flex text-[16px] font-medium">
    <div className="mr-[2px] text-gray-700">{type}:</div>
    <div className="text-black">{value}</div>
  </div>
);

export default function ProductCard({
  product,
  editable,
  children,
}: {
  product: ProductSubscription;
  editable: boolean;
  children: (
    Detail: ({ type, value }: { type: string; value: string | number }) => ReactElement,
  ) => ReactElement;
}): ReactElement {
  const navigate = useNavigate();

  return (
    <RoundedCard className="p-6">
      <div className="flex w-full">
        <div className="flex w-full flex-col">
          <div className="flex w-full flex-col gap-4 md:flex-row md:justify-between">
            <div className="flex flex-col">
              <h2 className="mb-4 text-[24px] font-bold">{getTitle(product.type)}</h2>
              <div className="flex flex-col gap-2">
                <b>{getPlan(product.plan)}</b>
                <div>{product.cost}</div>
                <div>{children(Detail)}</div>
              </div>
            </div>
            {editable && (
              <div>
                <Button
                  color="secondary"
                  onClick={() => navigate(`/settings/plans/${product.type}`)}
                >
                  Change Plan
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </RoundedCard>
  );
}
