import { EndpointResponse, Entity } from '@app/@types/api.types';
import {
  CustomerResourceType,
  MerchantData,
  RestrictionInfo,
  SpendRestriction,
  SpendRestrictionConfiguration,
  SpendRestrictionRuleType,
} from '@app/@types/spend_restriction.types';
import {
  GLOBAL_CUSTOMER_RESOURCE_TYPE,
  MERCHANT_CATEGORY_GROUP_NAME,
  MERCHANT_CATEGORY_NAME,
  MERCHANT_GROUP_NAME,
  TAG_CUSTOMER_RESOURCE_TYPE,
} from '@app/constants/spendRestrictions';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import {
  AUTOMATED_FUEL_DISPENSERS,
  FUEL_CATEGORY_IDS,
  generateExpenseCategory,
} from '@app/utils/expenseCategories';
import axios from 'axios';
import { useCallback, useState } from 'react';
import useSWR, { SWRResponse, useSWRConfig } from 'swr';

export type SpendRestrictionEntity = Entity<SpendRestriction, 'spend_restriction'>;

type SpendRestrictionsResponse = {
  data: SpendRestriction[];
} & SWRResponse<SpendRestrictionEntity>;

export function useRestrictions(tagId?: string): SWRResponse<EndpointResponse<SpendRestriction[]>> {
  const params = tagId
    ? {
        'active_only': 'true',
        'by_tag_ids[]': tagId,
      }
    : {
        active_only: 'true',
        global_only: 'true',
      };

  return useSWR<EndpointResponse<SpendRestriction[]>>(
    {
      url: `/spend_restrictions`,
      params,
    },
    apiGetFetcher,
  );
}

type SpendRestrictionParam = {
  spend_restrictions: {
    data: SpendRestriction[];
    customer_resource_type: string;
    customer_resource_id?: number;
  };
};

type UpdateRestrictionsResult = {
  updateRestrictions: (params: SpendRestrictionParam) => void;
  error?: Error;
};

export function useUpdateRestrictions(): UpdateRestrictionsResult {
  const { mutate } = useSWRConfig();
  const [error, setError] = useState<Error>();

  const updateRestrictions = useCallback(
    async (params?: SpendRestrictionParam) => {
      const { customer_resource_type: resourceType, customer_resource_id: resourceId } =
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        params.spend_restrictions;
      const urlParams = `active_only=true&${
        resourceType === 'Customer' ? 'global_only=true' : `by_tag_ids[]=${resourceId}`
      }`;
      await mutate<SpendRestrictionsResponse>(`/spend_restrictions?${urlParams}`, async () => {
        try {
          const result = await axios.post('/spend_restrictions/save', params);
          return result.data;
        } catch (e) {
          if (axios.isAxiosError(e)) {
            setError(e);
          } else {
            setError(new Error('Unknown error'));
          }
          return undefined;
        }
      });
    },
    [mutate],
  );

  return {
    updateRestrictions,
    error,
  };
}

export function getUpdateRestrictionParams(
  restriction: RestrictionInfo,
  resource: CustomerResourceType,
  spendTier: number,
): SpendRestrictionParam {
  const data = [
    ...generateExpenseCategory(restriction.selectedCategoryIds, spendTier).flatMap((ec) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      ec.categories.map((c) => createMerchantCategoryGroupRule(c)),
    ),
    ...restriction.merchantWhitelist.map((m) =>
      createMerchantRule(m, SpendRestrictionRuleType.ALLOW),
    ),
    ...restriction.merchantBlacklist.map((m) =>
      createMerchantRule(m, SpendRestrictionRuleType.BLOCK),
    ),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    ...createAfdOnlyRule(restriction.afdOnly),
  ].filter((d) => d);

  const resourceData =
    resource === 'Customer'
      ? {
          customer_resource_type: GLOBAL_CUSTOMER_RESOURCE_TYPE,
        }
      : {
          customer_resource_type: TAG_CUSTOMER_RESOURCE_TYPE,
          customer_resource_id: resource.id,
        };
  return {
    spend_restrictions: {
      ...resourceData,
      data,
    },
  };
}

function createMerchantCategoryGroupRule({
  id,
  selected,
}: {
  id: string;
  name: string;
  selected: boolean;
}): SpendRestriction {
  return {
    restricted_resource_type: MERCHANT_CATEGORY_GROUP_NAME,
    restricted_resource_id: id,
    enabled: selected,
    rule_type: SpendRestrictionRuleType.ALLOW,
    configuration: {} as SpendRestrictionConfiguration,
  };
}

function createAfdOnlyRule(afdOnly: boolean): SpendRestriction[] {
  return FUEL_CATEGORY_IDS.map((c) => {
    return {
      restricted_resource_type: MERCHANT_CATEGORY_NAME,
      restricted_resource_id: c,
      enabled: afdOnly,
      rule_type:
        c === AUTOMATED_FUEL_DISPENSERS.id
          ? SpendRestrictionRuleType.ALLOW
          : SpendRestrictionRuleType.BLOCK,
      configuration: {} as SpendRestrictionConfiguration,
    };
  });
}

function createMerchantRule(
  merchant: MerchantData,
  rule_type: SpendRestrictionRuleType,
): SpendRestriction {
  return {
    restricted_resource_type: MERCHANT_GROUP_NAME,
    rule_type,
    enabled: true,
    restricted_resource_id: merchant.id,
    configuration: {} as SpendRestrictionConfiguration,
  };
}

export function parseRestrictionResponse(restrictionData: SpendRestriction[]): RestrictionInfo {
  return {
    selectedCategoryIds: restrictionData
      .filter((sr: SpendRestriction) => isAllowedMerchantCategoryGroup(sr))
      .map((sr: SpendRestriction) => sr.restricted_resource_id),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    merchantWhitelist: restrictionData
      .filter((sr: SpendRestriction) => isAllowedMerchant(sr))
      .map((sr: SpendRestriction) => getMerchant(sr)),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    merchantBlacklist: restrictionData
      .filter((sr: SpendRestriction) => isBlockedMerchant(sr))
      .map((sr: SpendRestriction) => getMerchant(sr)),
    afdOnly: (function (): boolean {
      const fuelRestrictions = restrictionData.filter((sr: SpendRestriction) =>
        FUEL_CATEGORY_IDS.includes(sr.restricted_resource_id),
      );
      return (
        fuelRestrictions.length > 0 &&
        fuelRestrictions.every(
          (sr: SpendRestriction) =>
            sr.restricted_resource_id === AUTOMATED_FUEL_DISPENSERS.id ||
            sr.rule_type === SpendRestrictionRuleType.BLOCK,
        )
      );
    })(),
  };
}

function getMerchant(restriction: SpendRestriction) {
  return {
    id: restriction.restricted_resource_id,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    name: restriction.merchant_details.name || null,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    display_address: restriction.merchant_details.display_address || null,
  };
}

function isAllowedMerchant(restriction: SpendRestriction) {
  return isMerchantRule(restriction) && restriction.rule_type === SpendRestrictionRuleType.ALLOW;
}

function isBlockedMerchant(restriction: SpendRestriction) {
  return isMerchantRule(restriction) && restriction.rule_type === SpendRestrictionRuleType.BLOCK;
}

function isAllowedMerchantCategoryGroup(restriction: SpendRestriction) {
  return (
    (isMerchantCategoryGroupRule(restriction) || isMerchantCategoryRule(restriction)) &&
    restriction.rule_type === SpendRestrictionRuleType.ALLOW
  );
}

function isMerchantRule(restriction: SpendRestriction) {
  return restriction.restricted_resource_type === MERCHANT_GROUP_NAME;
}

function isMerchantCategoryRule(restriction: SpendRestriction) {
  return restriction.restricted_resource_type === MERCHANT_CATEGORY_NAME;
}

function isMerchantCategoryGroupRule(restriction: SpendRestriction) {
  return restriction.restricted_resource_type === MERCHANT_CATEGORY_GROUP_NAME;
}
