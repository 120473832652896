import { UserData } from '@app/@types/users.types';
import PreemptiveOTPValidator from '@app/app/PreemptiveOTPValidator';
import Header from '@app/components/Navigation/Header';
import { NewUserSidebar } from '@app/components/Sidebars/UserSidebar/NewUserSidebar';
import { Loading } from '@app/components/layout';
import PageContentWrapper from '@app/components/wrappers/PageContentWrapper';
import StickyButtonWrapper from '@app/components/wrappers/StickyButtonWrapper';
import { WorkspaceProduct } from '@app/constants/customerProducts';
import { useExtendMobileHeaderContextMenu } from '@app/contexts/MobileNavHeaderMenuContextComponent';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import { Button } from '@mui/material';
import { ReactElement, useMemo, useState } from 'react';
import { getUserRoleKeyToName } from './RoleKeyToName';
import UsersList from './UsersList';

interface UsersPageProps {
  workspaceProducts: WorkspaceProduct[];
  data: UserData[];
  fetchData: () => void;
  loading: boolean;
  workspaceAddUpdateUsers: boolean;
  workspaceRoles: boolean;
  allowManageNotifications: boolean;
}

const UsersPage = ({
  workspaceProducts,
  allowManageNotifications,
  data,
  fetchData,
  loading,
  workspaceAddUpdateUsers,
  workspaceRoles,
}: UsersPageProps): ReactElement => {
  const [showSidebar, setShowSidebar] = useState(false);
  const userRoles = getUserRoleKeyToName(workspaceProducts);
  const [skipMfaForAddingUser] = useFeatureFlags('skip_mfa_for_adding_user');

  const menuItem = useMemo(
    () => ({
      value: 'Add User',
      onClick: () => setShowSidebar(true),
      disabled: () => false,
      order: 1,
    }),
    [],
  );

  useExtendMobileHeaderContextMenu(menuItem);

  const newUserSidebar = (
    <NewUserSidebar
      open={showSidebar}
      setOpen={setShowSidebar}
      rolesEntry={userRoles}
      fetchData={fetchData}
      workspaceRoles={workspaceRoles}
      allowManageNotifications={allowManageNotifications}
    />
  ) as ReactElement;

  const sideBar = skipMfaForAddingUser ? (
    newUserSidebar
  ) : (
    <PreemptiveOTPValidator onCloseWithoutVerification={() => setShowSidebar(false)}>
      {newUserSidebar}
    </PreemptiveOTPValidator>
  );

  return (
    <div>
      <PageContentWrapper
        header={
          <Header
            title="Users"
            mobileStickyButton={
              <StickyButtonWrapper>
                <Button onClick={() => setShowSidebar(!showSidebar)} fullWidth={true} size="small">
                  Add User
                </Button>
              </StickyButtonWrapper>
            }
          />
        }
      >
        {workspaceAddUpdateUsers && (
          <div className="flex flex-col justify-between gap-5">
            <div>
              Add additional users to this account or click on a row below to update a user.
            </div>
            <div>
              {showSidebar && sideBar}
              <Button
                className="hidden md:block"
                onClick={() => setShowSidebar(!showSidebar)}
                size="small"
              >
                Add User
              </Button>
            </div>
          </div>
        )}
        <h1 className="font-bold">Users</h1>
        {loading ? (
          <Loading />
        ) : (
          <UsersList
            rolesEntry={userRoles}
            data={data}
            fetchData={fetchData}
            workspaceAddUpdateUsers={workspaceAddUpdateUsers}
            workspaceRoles={workspaceRoles}
            allowManageNotifications={allowManageNotifications}
          />
        )}
      </PageContentWrapper>
    </div>
  );
};

export default UsersPage;
