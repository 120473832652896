import { CustomerData } from '@app/@types/customer.types';
import { ErrorNotification } from '@app/components/layout';
import { ACCOUNT_SETUP_FEE_DESCRIPTION } from '@app/constants/unlimited';
import useChannelPartner from '@app/hooks/useChannelPartner';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { ReactElement, useCallback, useState } from 'react';
import useScheduledBillingPayments from '../../pages/AccountOverview/useScheduledBillingPayments';
import { getStripeLink } from '../../pages/Wallet/Onboarding';
import AddFunds, {
  AddFundsModalDataWrapper,
} from '../../pages/Wallet/WalletOverview/TransferFunds/AddFunds';
import PaymentModalWithEstimates from '../PaymentModal/PaymentModalWithEstimates/PaymentModalWithEstimates';
import { MANUAL_TOP_UP_NOTIFICATION } from '../PrepaidAccountOverview/TopUpNotification';
import OptInButton from './PrepaidOptIn/OptInButton';
import { CardTypeSelectionModal } from './SetupActivation/CardTypeSelectionModal';
import { PrepaidDetails } from './use-prepaid-details';

type StepConfig = {
  [key in PrepaidState]: {
    state: string;
    description: string;
    ctaKey?:
      | 'SetupAccountButton'
      | 'AddFundsButton'
      | 'MakeAPaymentButton'
      | 'PayNowButton'
      | 'OptInButton'
      | 'OrderCardsButton';
  }[];
};

type PrepaidState =
  | 'pending'
  | 'migrating'
  | 'optIn'
  | 'churned'
  | 'netNew'
  | 'zeroBalance'
  | 'suspended';

const defaultStepConfig = (supportPhoneNumber: string): StepConfig => ({
  pending: [
    {
      state: 'pending',
      ctaKey: 'SetupAccountButton',
      description: `Enroll in AtoB Wallet. It's super simple and only takes 2 minutes.`,
    },
    {
      state: 'onboarded',
      ctaKey: 'AddFundsButton',
      description: 'Add funds to your AtoB Wallet.',
    },
    {
      state: 'toppedUp',
      ctaKey: 'PayNowButton',
      description:
        'Pay off all outstanding balance now, or wait to pay when the current statement ends.',
    },
  ],
  migrating: [
    {
      state: 'migrated',
      ctaKey: 'SetupAccountButton',
      description: 'Finish your enrollment into AtoB Wallet.',
    },
    {
      state: 'onboarded',
      ctaKey: 'AddFundsButton',
      description: 'Add funds to your AtoB Wallet to use your existing fuel cards.',
    },
    {
      state: 'statementDue',
      ctaKey: 'PayNowButton',
      description:
        'Pay off your remaining account balance from past credit statements to avoid deactivation.',
    },
  ],
  churned: [
    {
      state: 'migrated',
      ctaKey: 'SetupAccountButton',
      description: 'Set up your AtoB Wallet account.',
    },
    {
      state: 'onboarded',
      ctaKey: 'AddFundsButton',
      description: 'Add funds to your AtoB Wallet.',
    },
    {
      state: 'complete',
      description: 'Onboard your account to unlock 20¢ discount over the next 3 months.',
    },
  ],
  netNew: [
    {
      state: 'optedIn',
      ctaKey: 'SetupAccountButton',
      description: 'Sign the Terms of Service.',
    },
    {
      state: 'onboarded',
      ctaKey: 'AddFundsButton',
      description: 'Fund your Wallet to start using your prepaid cards toward purchases.',
    },
  ],
  optIn: [
    {
      state: 'optedIn',
      ctaKey: 'SetupAccountButton',
      description: 'Fill out a short questionnaire to get started.',
    },
    {
      state: 'onboarded',
      ctaKey: 'AddFundsButton',
      description: 'Fund your Wallet to start using your prepaid cards toward purchases.',
    },
    {
      state: 'toppedUp',
      ctaKey: 'OptInButton',
      description: "Once you've added funds to your Wallet, confirm your switch to Unlimited.",
    },
    {
      state: 'statementDue',
      ctaKey: 'PayNowButton',
      description: 'Pay off outstanding balance in full to complete transition to Unlimited.',
    },
  ],
  zeroBalance: [
    {
      state: 'migrated',
      ctaKey: 'SetupAccountButton',
      description: 'Set up your AtoB Wallet account.',
    },
    {
      state: 'onboarded',
      ctaKey: 'AddFundsButton',
      description: 'Add funds to your AtoB Wallet.',
    },
  ],
  suspended: [
    {
      state: 'suspended',
      description: `Your account has been suspended. If this is not expected, please reach out to our support team at ${supportPhoneNumber}.`,
    },
  ],
});

const getStepConfig = ({
  virtualCardsEnabled,
  activationFeeEnabled,
  supportPhoneNumber,
}: {
  virtualCardsEnabled: boolean;
  activationFeeEnabled: boolean;
  supportPhoneNumber: string;
}): StepConfig => {
  const config = defaultStepConfig(supportPhoneNumber);
  if (virtualCardsEnabled) {
    return {
      ...config,
      netNew: [
        // Remove the default onboarded step, and replace with new steps for ordering your cards.
        ...config['netNew'].filter((step) => step.state !== 'onboarded'),
        {
          state: 'onboarded',
          ctaKey: 'OrderCardsButton',
          description: 'Order your cards to start using your AtoB Wallet.',
        },
        {
          state: 'cardsOrdered',
          ctaKey: 'AddFundsButton',
          description: 'Fund your Wallet to start using your prepaid cards toward purchases.',
        },
      ],
    };
  } else if (activationFeeEnabled) {
    return {
      ...config,
      netNew: [
        // Remove the default onboarded step, and replace with new steps for account setup fee payment.
        ...config['netNew'].filter((step) => step.state !== 'onboarded'),
        {
          state: 'onboarded',
          ctaKey: 'MakeAPaymentButton',
          description: 'Get your AtoB fuel cards shipped by paying a one-time account setup fee.',
        },
        {
          state: 'accountSetupFeePaid',
          ctaKey: 'AddFundsButton',
          description: 'Fund your Wallet to start using your prepaid cards toward purchases.',
        },
      ],
    };
  }
  return config;
};

export type PrepaidStep = {
  description: string;
  status: 'upcoming' | 'current' | 'complete';
  CTA?: () => ReactElement;
  error?: { code: string; reason: string };
};

const titleConfig = {
  pending: 'Keep your account active',
  migrating: 'Keep your account active',
  churned: 'Keep your account active',
  optIn: 'Switch to AtoB Unlimited',
  netNew: 'Activate your account',
  zeroBalance: 'Keep your account active',
  suspended: 'Account suspended',
};

const generateSteps = (
  config: StepConfig,
  type: PrepaidState,
  currentStep: string,
  error: { code: string; reason: string },
  CTAS: {
    SetupAccountButton: () => ReactElement;
    AddFundsButton: () => ReactElement;
    MakeAPaymentButton: () => ReactElement;
    PayNowButton: () => ReactElement;
    UpdateAccountButton: () => ReactElement;
    OptInButton: () => ReactElement;
    OrderCardsButton: () => ReactElement;
  },
): {
  steps: PrepaidStep[];
  title: string;
} => {
  let foundStep = false;

  if (!type) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return { steps: null, title: '' };
  }

  const steps: PrepaidStep[] = config[type].map((step) => {
    if (foundStep) {
      return { ...step, status: 'upcoming', error };
    }

    if (step.state === currentStep) {
      foundStep = true;
      return {
        ...step,
        status: 'current',
        error,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        CTA: error?.code ? CTAS.UpdateAccountButton : CTAS[step.ctaKey],
      };
    }

    return { ...step, status: 'complete', error };
  });

  if (foundStep) {
    return { steps, title: titleConfig[type] };
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return { steps: null, title: '' };
};

export default function usePrepaidOnboardingSteps({
  prepaidDetails,
  companyName,
  customer,
}: {
  prepaidDetails: PrepaidDetails;
  companyName: string;
  customer: CustomerData;
}): { steps: PrepaidStep[] | null; title: string } {
  const { step, onboardingState, onboardingErrors } = prepaidDetails;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [paymentModalActive, setPaymentModalActive] = useState(false);
  const [accountSetupFeeModalActive, setAccountSetupFeeModalActive] = useState(false);
  const [cardTypeSelectionModalActive, setCardTypeSelectionModalActive] = useState(false);

  const { supportPhoneNumber } = useChannelPartner();
  const [UNLIMITED_PRESHIPMENT_ACTIVATION_FEE, VIRTUAL_CARDS_PILOT] = useFeatureFlags(
    'unlimited_preshipment_activation_fee',
    'virtual_cards_pilot',
  );

  const { data: upcomingPayment } = useScheduledBillingPayments();

  const enroll = async () => {
    setLoading(true);
    setError(null);

    try {
      await axios.post('/treasury/onboarding/enroll');
      const stripeLink = await getStripeLink();
      window.location.href = stripeLink;
    } catch (e) {
      setError((e as AxiosError).message);
    }

    setLoading(false);
  };

  const SetupAccountButton = () => (
    <>
      {error && <ErrorNotification error={error} />}
      <LoadingButton loading={loading} onClick={enroll}>
        <span>Set Up Account</span>
      </LoadingButton>
    </>
  );

  const UpdateAccountButton = () => (
    <LoadingButton loading={loading} onClick={enroll}>
      <span>Update account</span>
    </LoadingButton>
  );

  const MakeAPaymentButton = useCallback(
    () => (
      <>
        <div className="flex flex-col items-start justify-center lg:items-center">
          <Button
            color="secondary"
            disabled={prepaidDetails.customerTreasuryDetails?.status !== 'enrolled'}
            onClick={() => setAccountSetupFeeModalActive(true)}
          >
            Make Payment
          </Button>
          <AddFundsModalDataWrapper
            customerCompanyName={companyName}
            customerCompanyAddress={customer.company_address}
            onAddFunds={() => (window.location.href = '/')}
            modalActive={accountSetupFeeModalActive}
            setModalActive={setAccountSetupFeeModalActive}
            minimumDepositAmountCents={customer?.treasury?.minimum_deposit_amount?.cents}
            initialAmount={customer?.treasury?.minimum_deposit_amount?.cents}
            initialDescription={ACCOUNT_SETUP_FEE_DESCRIPTION}
            scenario={'account_setup_fee'}
          />
        </div>
      </>
    ),
    [
      prepaidDetails.customerTreasuryDetails?.status,
      customer?.treasury?.minimum_deposit_amount?.cents,
      companyName,
      customer.company_address,
      accountSetupFeeModalActive,
    ],
  );

  const AddFundsButton = useCallback(
    () => (
      <>
        <div className="flex flex-col items-start justify-center lg:items-center">
          <AddFunds
            buttonProps={{
              disabled: prepaidDetails.customerTreasuryDetails?.status !== 'enrolled',
              text: 'Add Funds',
            }}
            customerCompanyName={companyName}
            customerCompanyAddress={customer.company_address}
            onAddFunds={() => {
              window.location.href = `/?${MANUAL_TOP_UP_NOTIFICATION}=true`;
            }}
          />
        </div>
      </>
    ),
    [prepaidDetails.customerTreasuryDetails?.status, companyName, customer.company_address],
  );

  const PayNowButton = useCallback(
    () => (
      <>
        <div className="w-[160px]">
          <Button onClick={() => setPaymentModalActive(true)}>Pay now</Button>
        </div>

        {paymentModalActive && (
          <PaymentModalWithEstimates
            isModalActive={paymentModalActive}
            customer={customer}
            upcomingPayment={upcomingPayment}
            walletAvailable
            closeModal={() => setPaymentModalActive(false)}
          />
        )}
      </>
    ),
    [paymentModalActive, upcomingPayment, customer],
  );

  const OrderCardsButton = useCallback(
    () => (
      <>
        <div className="w-[160px]">
          <Button onClick={() => setCardTypeSelectionModalActive(true)}>Order Cards</Button>
        </div>

        {cardTypeSelectionModalActive && (
          <CardTypeSelectionModal
            open={cardTypeSelectionModalActive}
            setOpen={setCardTypeSelectionModalActive}
            companyName={companyName}
          />
        )}
      </>
    ),
    [cardTypeSelectionModalActive, companyName],
  );

  const stepConfig = getStepConfig({
    virtualCardsEnabled: VIRTUAL_CARDS_PILOT,
    activationFeeEnabled: UNLIMITED_PRESHIPMENT_ACTIVATION_FEE,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    supportPhoneNumber,
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const { steps, title } = generateSteps(stepConfig, onboardingState, step, onboardingErrors[0], {
    SetupAccountButton,
    AddFundsButton,
    MakeAPaymentButton,
    PayNowButton,
    UpdateAccountButton,
    OptInButton,
    OrderCardsButton,
  });

  return { steps, title };
}
