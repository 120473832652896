import { ELDProvider } from '@app/@types/eld-connect.types';
import React from 'react';
import ProviderCard from '../ProviderCard';

interface TelematicsCardProps {
  provider: ELDProvider;
  onClick: (provider: ELDProvider) => void;
  DetailPane?: React.ComponentType | null;
}

const TelematicsCard = ({ provider, onClick, DetailPane }: TelematicsCardProps) => {
  const handleAction = () => {
    onClick(provider);
  };

  const getActionLabelText = () => {
    if (provider.connected) {
      return provider.total_connections > 1 ? 'Disconnect All' : 'Disconnect';
    }
    return 'Connect';
  };

  return (
    <ProviderCard
      logo={provider.logo}
      displayName={provider.displayName}
      connectionStatus={provider.connection_status || 'disconnected'}
      connected={provider.connected}
      onAction={handleAction}
      actionLabel={getActionLabelText()}
      numberOfConnections={provider.total_connections}
      DetailPane={DetailPane}
    />
  );
};

export default TelematicsCard;
