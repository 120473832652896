import PaymentDetailItem from '@app/components/PaymentModal/PaymentDetailSection/PaymentDetailItem/PaymentDetailItem';
import PaymentDetailsHeaderText from '@app/components/PaymentModal/components/PaymentDetailsHeaderText';
import ReviewPaymentFooter from '@app/components/PaymentModal/components/ReviewPaymentFooter';
import logger from '@app/utils/datadog-logger';
import Divider from '@atob-developers/shared/src/components/Divider';
import { convertNumbersWithCurrency } from '@atob-developers/shared/src/utils/formatters/CurrencyFormat';
import axios from 'axios';
import { ReactElement, useState } from 'react';
interface DebitCardEditPaymentDetailsProps {
  onBack: () => void;
  paymentAmount: string;
  setPaymentScreen: (paymentScreen: 'edit' | 'review' | 'success' | 'error') => void;
}

const DebitCardEditPaymentDetails = ({
  onBack,
  paymentAmount,
  setPaymentScreen,
}: DebitCardEditPaymentDetailsProps): ReactElement | null => {
  const [submittingPayment, setSubmittingPayment] = useState(false);
  const submitPayment = async () => {
    setSubmittingPayment(true);
    try {
      await axios.post('/ach_charges?include=customer', {
        ach_charge: { amount: convertNumbersWithCurrency(paymentAmount), use_wallet: true },
      });
      setPaymentScreen('success');
      logger.info('Wallet payment successfully submitted', {
        paymentAmount,
      });
    } catch (error: unknown) {
      setPaymentScreen('error');
      logger.error('Error submitting Wallet payment', {
        paymentAmount,
        error,
      });
    }
    setSubmittingPayment(false);
  };
  return (
    <>
      <div className="w-full space-y-10 bg-white px-6 py-6 sm:px-8">
        <div className="flex flex-col gap-4">
          <PaymentDetailsHeaderText>Review Payment Details</PaymentDetailsHeaderText>
          <div className="flex flex-col gap-4">
            <Divider className="bg-gray-200" />
            <PaymentDetailItem text="Wallet Account">AtoB Wallet</PaymentDetailItem>
            <Divider className="bg-gray-200" />
            <PaymentDetailItem text="Amount">{paymentAmount}</PaymentDetailItem>
            <Divider className="bg-gray-200" />
            <PaymentDetailItem text="Est. Processing Date">Instant</PaymentDetailItem>
          </div>
        </div>
      </div>
      <ReviewPaymentFooter
        onBack={onBack}
        payNowHandler={submitPayment}
        paymentAmount={paymentAmount}
        loading={submittingPayment}
      />
    </>
  );
};

export default DebitCardEditPaymentDetails;
