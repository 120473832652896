/* eslint-disable no-unused-vars */
import { PlaidResponse } from '@app/@types/plaid.types';
import PlaidContext, { PlaidContextType } from '@app/contexts/plaidContext';
import { noArgPostFetcher } from '@app/utils/data/fetchers';
import { useMemo } from 'react';
import useSWRImmutable from 'swr/immutable';
import PlaidErrorButton from './PlaidErrorButton';
import PlaidLink, { PlaidLinkProps } from './PlaidLink';
import getBodyForPlaidRequest from './PlaidUtils/PlaidUtils';

type PlaidProps = Omit<PlaidLinkProps, 'isLoading'> & {
  showPlaidErrorButton?: boolean;
  onSuccessCallback: () => void;
  onLinkingCallback: (phase: string) => void;
};

const Plaid = ({
  showPlaidErrorButton = false,
  onSuccessCallback,
  onLinkingCallback,
  flow,
  pendingBankAccountId,
  updateBankAccountId,
  ...rest
}: PlaidProps) => {
  const body = getBodyForPlaidRequest({ pendingBankAccountId, updateBankAccountId });
  const key = { plaid: 'link', ...(body ?? {}) };
  const { data, error, isLoading, isValidating } = useSWRImmutable<PlaidResponse>(
    { url: '/plaid/create_link_token', params: { params: body }, key },
    noArgPostFetcher,
  );

  const plaidContextValue = useMemo<PlaidContextType>(
    () => ({
      linkToken: data ? data.link_token : null,
      plaidError: error,
      setError: rest.setError,
      onSuccessCallback,
      onLinkingCallback,
      flow,
    }),
    [data, error, flow, onLinkingCallback, onSuccessCallback, rest.setError],
  );

  if (error && !showPlaidErrorButton) {
    return null;
  }

  if (error && showPlaidErrorButton) {
    return <PlaidErrorButton className={rest.buttonProps?.className} />;
  }

  return (
    <PlaidContext.Provider value={plaidContextValue}>
      <PlaidLink {...rest} isLoading={isLoading || isValidating} />
    </PlaidContext.Provider>
  );
};

export default Plaid;
