import { Button } from '@mui/material';
import { ReactElement } from 'react';
import PaymentModalFooter from './PaymentModalFooter';

interface EditPaymentFooterProps {
  onCancel: () => void;
  onNext: () => void;
  disableNext?: boolean;
}

const EditPaymentFooter = ({
  onCancel,
  onNext,
  disableNext = false,
}: EditPaymentFooterProps): ReactElement => {
  return (
    <PaymentModalFooter>
      <Button
        color="secondary"
        size="medium"
        onClick={onCancel}
        data-testid="ModalFooter-Cancel-Button"
      >
        Cancel
      </Button>
      <Button
        size="medium"
        onClick={onNext}
        disabled={disableNext}
        data-testid="ModalFooter-Next-Button"
      >
        Next
      </Button>
    </PaymentModalFooter>
  );
};

export default EditPaymentFooter;
