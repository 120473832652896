import WelcomeToAtoBPopup from '@app/components/popups/WelcomeToAtoBPopup';
import useCustomerOnboardingEvents, {
  CustomerOnboardingEventName,
  useCreateCustomerOnboardingEvent,
} from '@app/hooks/query/useCustomerOnboardingEvents';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useShouldBeInOnboarding } from './useHasCompletedOnboarding';

export default function useWelcomePopup(onNext?: () => void): [boolean, ReactElement] {
  const [hasDismissed, setHasDismissed] = useState(false);
  const { trigger: createCustomerOnboardingEvent } = useCreateCustomerOnboardingEvent();
  const customerOnboardingEvents = useCustomerOnboardingEvents();

  const hasCheckedForLoginRef = useRef(false);

  const shouldBeInOnboarding = useShouldBeInOnboarding();

  const addLoginOnboardingEvent = useCallback(async () => {
    await createCustomerOnboardingEvent({
      customer_onboarding_event: {
        name: CustomerOnboardingEventName.CUSTOMER_PORTAL_LOGIN,
      },
    });
  }, [createCustomerOnboardingEvent]);

  useEffect(() => {
    if (hasCheckedForLoginRef.current) {
      return;
    }
    const login = async () => {
      if (
        !shouldBeInOnboarding &&
        !customerOnboardingEvents.has(CustomerOnboardingEventName.CUSTOMER_PORTAL_LOGIN)
      ) {
        addLoginOnboardingEvent();
      }
    };
    hasCheckedForLoginRef.current = true;
    login();
  }, [
    addLoginOnboardingEvent,
    createCustomerOnboardingEvent,
    customerOnboardingEvents,
    shouldBeInOnboarding,
  ]);

  const shouldShow =
    shouldBeInOnboarding &&
    !customerOnboardingEvents.has(CustomerOnboardingEventName.CUSTOMER_PORTAL_LOGIN) &&
    !hasDismissed;

  return [
    shouldShow,
    <WelcomeToAtoBPopup
      key="welcome"
      onDismiss={async () => {
        setHasDismissed(true);
        onNext?.();
        addLoginOnboardingEvent();
      }}
    />,
  ];
}
