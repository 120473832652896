import { TruckType } from '@app/@types/telematics.types';
import TelematicsMapContext from '@app/contexts/telematicsMapContext';
import { Button } from '@mui/material';
import React, { useContext } from 'react';
import Dropdown from './Dropdown';
import TruckDetailContainer from './TruckDetailContainer';

export interface TruckSelectorContainerProps {
  selectTruck: (truck: TruckType, setError: (e: unknown) => void) => void;
  setError: (e: unknown) => void;
}

const TruckSelectorContainer: React.FC<TruckSelectorContainerProps> = ({
  selectTruck,
  setError,
}) => {
  const { trucks, activeTruck, setActiveTruck } = useContext(TelematicsMapContext);
  // Should be on the top left of this container
  return (
    <div className="absolute left-4 top-4 z-10 w-64 rounded-md bg-white">
      <Dropdown
        selectTruck={selectTruck}
        trucks={trucks}
        selectedTruck={activeTruck}
        setError={setError}
      />
      <div className={activeTruck == null ? 'hidden' : undefined}>
        <div className="m-4">
          {activeTruck !== null && (
            <Button size="small" color="secondary" onClick={() => setActiveTruck(null)}>
              Clear Selection
            </Button>
          )}
        </div>
        {activeTruck !== null && <TruckDetailContainer />}
      </div>
    </div>
  );
};

export default TruckSelectorContainer;
