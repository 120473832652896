import { PaginatedEndpointResponse } from '@app/@types/api.types';
import { CardData } from '@app/@types/card.types';
import { DEFAULT_PAGE_SIZE } from '@app/hooks/paging/types';
import { MenuItem, Checkbox, ListItemText } from '@mui/material';
import { ReactElement, useCallback } from 'react';
import { SWRInfiniteKeyLoader } from 'swr/infinite';
import { FilterDropdownInfiniteList } from '../FilterDropdownInfiniteList';

export const CardsFilter = ({
  onCardsSelect,
  selectedCardIDs,
}: {
  onCardsSelect: (v: string[]) => void;
  selectedCardIDs: string[];
}): ReactElement => {
  const keyFunc: (
    searchString: string,
  ) => SWRInfiniteKeyLoader<PaginatedEndpointResponse<CardData>> = useCallback(
    (searchString) => (index, previousPage) => {
      if (
        previousPage &&
        previousPage.meta?.pagination.current_page == previousPage.meta?.pagination.total_pages
      ) {
        return null;
      }
      return {
        url: '/cards',
        params: {
          page: index + 1,
          per: DEFAULT_PAGE_SIZE,
          like: searchString !== '' ? searchString : undefined,
          all: false,
          sort: ['card_number_last_four:asc'],
          archived_at: 'none',
        },
      };
    },
    [],
  );
  return (
    <FilterDropdownInfiniteList
      name="Card"
      keyFunc={keyFunc}
      onSelect={onCardsSelect}
      selectedIds={selectedCardIDs}
      renderValue={() => (selectedCardIDs.length + ' Card').replace('0', '').trim()}
      renderNoneItem={(item, isSelected) => {
        return (
          <MenuItem
            autoFocus={false}
            key={item.id}
            value={item.id.toString()}
            selected={isSelected}
            className="gap-1 px-2"
          >
            <Checkbox checked={isSelected} size="small" />
            <ListItemText>None</ListItemText>
          </MenuItem>
        );
      }}
      renderListItem={(item, isSelected) => {
        return (
          <MenuItem
            autoFocus={false}
            key={item.id}
            value={item.id.toString()}
            selected={isSelected}
            className="gap-1 px-2"
          >
            <Checkbox checked={isSelected} size="small" />
            <ListItemText>
              <>{item.card_number_last_four ?? ''}</>
            </ListItemText>
          </MenuItem>
        );
      }}
    />
  );
};
